import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { FooterModule } from '../shared/footer/footer.module'
@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    RouterModule,HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NguiAutoCompleteModule,
    HomeRoutingModule,
    FooterModule
    
  ],
  providers:[MedecinService,ArticleService]
})
export class HomeModule { }
