<div class="container-fluid p-sm-5 bg-light">
  <div class="row">
    <div class="col-lg-12">
      <!-- <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" ><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item"><a class="color-blue" >
                        Patients</a>
                </li>
                <li class="breadcrumb-item active">Ajouter un patient</li>
            </ul> -->
      <br><br><br>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-lg-12">
      <div class="card p-4" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
        <div class="card-body">
          <form #f0="ngForm" [formGroup]="form" (ngSubmit)="onRegisterPatient()">
            <div class="form-row mb-4">
              <div class="col d-flex align-items-center">
                <!-- Ajout de l'image avec une classe de Bootstrap pour la taille -->
                <img src="assets/user.png" class="img-fluid" alt="Image" style="width: 130px; height: 130px;">
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">N° Fiche:<b style="color: red;"> *</b></label>
                  <input type="text" class="form-control" formControlName="numFiche" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" onkeypress="return /[a-zA-Z0-9]/i.test(event.key)">
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.numFiche">{{champsObligatoire}}</control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Nom:<b style="color: red;"> *</b></label>
                  <input type="text" class="form-control" formControlName="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.nom">{{champsObligatoire}}</control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Prénom:<b style="color: red;"> *</b></label>
                  <input type="text" class="form-control" formControlName="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.prenom"></control-messages>
                </div>
              </div>

            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Date de naissance:<b style="color: red;"> *</b></label>
                  <input type="date" max="{{today |date:'yyyy-MM-dd'}}" class="form-control" formControlName="dateAnniversaire" [ngClass]="{ 'is-invalid': submitted && f.dateAnniversaire.errors }">
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.dateAnniversaire"></control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Email:</label>
                  <input type="text" class="form-control" formControlName="email">
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.email"></control-messages>
                  <div class="row" style="position: relative;color: red;left: 17px;">

                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">N° Téléphone:<b style="color: red;"> *</b></label> <br>
                  <ngx-intl-tel-input class="'form-control'" [enableAutoCountrySelect]="false" formControlName="tel" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Tunisia" [maxLength]="8" [phoneValidation]="true" [ngClass]="{ 'is-invalid': submitted && f.tel.errors }"></ngx-intl-tel-input>
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.tel">{{champsObligatoire}}</control-messages>
                  <div *ngIf="form.value.tel && !f0.form.controls['tel'].invalid == false" class="mt-2" style="color: red;">
                    Ce numéro de téléphone est invalide. <i class="bi bi-x"></i>
                  </div>
                  <div *ngIf=" form.value.tel && !f0.form.controls['tel'].invalid == true" style="color: green;">
                    Numéro de téléphone validé <i class="bi bi-check"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Profession:</label>
                  <input type="text" class="form-control" formControlName="profession" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.profession"></control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Groupe Sanguin:</label> <br>
                  <select class=" select-pop3 " formControlName="groupeSanguin" name="goupeSanguin">
                    <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                    <option>A+</option>
                    <option>A-</option>
                    <option>B+</option>
                    <option>B-</option>
                    <option>AB+</option>
                    <option>AB-</option>
                    <option>O+</option>
                    <option>O-</option>
                  </select>

                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Sexe:<b style="color: red;"> *</b></label> <br>
                  <select class=" select-pop3 " formControlName="sexe" id="statusFamilial" name="sexe">
                    <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                    <option>Homme</option>
                    <option>Femme</option>
                  </select>
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.sexe">{{champsObligatoire}}</control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Statut familial:</label> <br>
                  <select class=" select-pop3 " formControlName="statusFamilial" name="statusFamilial">
                    <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                    <option>Célibataire</option>
                    <option>Marié(e)</option>
                    <option>Divorcée</option>
                    <option>Veuf(ve)</option>
                  </select>
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.statusFamilial"></control-messages>
                </div>
              </div>
            </div>
            <div class="form-row" formGroupName="adresse">
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Pays:</label> <br>
                  <select class="select-pop3" id="pays" [(ngModel)]="selectedpays" (ngModelChange)="onSelectpays(selectedpays)" formControlName="pays" name="pays">
                    <option value="" selected>Tunisie</option>
                    <option *ngFor="let py of pays">{{py.name}}</option>
                  </select>
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.adresse.controls.pays"></control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Ville:</label> <br>
                  <input type="text" formControlName="ville" name="ville" class="form-control" *ngIf="!isTunisie" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                  <select class="select-pop3" id="specialite" formControlName="ville" name="ville" [(ngModel)]="selectedville" (ngModelChange)="onSelectville(selectedville)" *ngIf="isTunisie">
                    <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                    <option *ngFor="let ville of listefiltreville">{{ville}}</option>
                  </select>
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.adresse.controls.ville"></control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Code postal:</label> <br>
                  <input type="text" formControlName="codePostal" name="codePostal" class="form-control" id="codePostal" *ngIf="!isTunisie" onkeypress="return /[0-9]/i.test(event.key)">
                  <select class="select-pop3" formControlName="codePostal" name="codePostal" [(ngModel)]="selectedpostal" *ngIf="isTunisie">
                    <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                    <option *ngFor="let postal of listefiltrecode">{{postal}}</option>
                  </select>
                  <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="form.controls.adresse.controls.codePostal"></control-messages>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Adresse:</label>
                  <input type="text" class="form-control" formControlName="adresse" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ, 0-9]/i.test(event.key)">
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">APCI:</label>
                  <ng-select [items]="apcis" formControlName="apci" class="form-control input-custom" [closeOnSelect]="true" multiple="true" [maxSelectedItems]="5">
                  </ng-select>
                  <p *ngIf="form && form.value && form.value.apci && form.value.apci.length > 4 " style="    font-size: .875em;
                    color: red;margin-top: .25rem;">
                    Vous ne pouvez sélectionner que jusqu'à 5 éléments.
                  </p>

                </div>
              </div>
            </div>
            <div class="form-row" formGroupName="orgSante">
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Type d'organisme:</label>
                  <select class=" select-pop3 " formControlName="typeOrg" name="typeOrg">
                    <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                    <option>CNAM</option>
                    <option>CNSS</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="fieldsTitle">Nom de l'établissement:</label>
                  <input class="form-control" formControlName="nomEtablissement" name="nomEtablissement" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                  <control-messages style="color: red;margin: 0px!important;position: relative;top: 25px;left: 13px;" [control]="form.controls.orgSante.controls.nomEtablissement"></control-messages>
                </div>
              </div>
            </div>
            <div class="form-group text-right">
              <button type="submit" class="btn btn-primary">Enregistrer</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>