<br><br><br><br>
<div class="wrapper" src>
  <div class="header-cont pt-5 mb-3">
    <div class="co-2">
      <img class="logo" src="assets/images/medivisto-blanc.png" alt="Logo" />
    </div>
    <div class="col-10">
      <h2 class="title font-text">Liste des rendez-vous du jour</h2>
    </div>
  </div>

  <div class="running-text-container">
    <p class="running-text font-text">{{ runText }}</p>
  </div>

  <div class="container main-cont">
    <div class="date date-text mb-2">
      <button type="button" class="btn btn-custom" (click)="toggleFullScreen()" title="Plein écran"><i
          class="fa-solid fa-expand"></i></button>
      {{ currentDate }}
    </div>
    <table class="table">
      <thead>
        <tr class="text-center color-row">
          <th scope="col" class="sm-font-text"></th>
          <th scope="col" class="sm-font-text">Id</th>
          <th scope="col" class="sm-font-text">Nom</th>
          <th scope="col" class="sm-font-text">Heure du Rendez-vous</th>
          <th scope="col" class="sm-font-text">Temps restant</th>
          <th scope="col" class="sm-font-text">Patients en attente</th>
          <th scope="col" class="sm-font-text">Status</th>
          <!-- <th scope="col" class="sm-font-text">Actions</th> -->
        </tr>
      </thead>
      <tbody class="table-body">
        <tr *ngFor="let patient of patients | orderBy: 'tempsRV'" class="text-center table-row"
          [class.critical-time]="isCriticalTime(patient.resTime)" [class.done-time]="isDoneTime(patient.resTime)">
          <td>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#589590" class="bi bi-person-circle"
              viewBox="0 0 17 17">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
              <path fill-rule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
            </svg>
          </td>
          <td>{{ patient.id }}</td>
          <td>{{ patient.prenom }} {{ patient.nom }}</td>
          <td>{{ patient.tempsRV }}</td>
          <td>{{ patient.resTime }}</td>
          <td>{{ patient.patLine }}</td>
          <td>
            <span *ngIf="patient.status === 1" class="badge badge-warning rounded-pill d-inline">En attente</span>
            <span *ngIf="patient.status === 2" class="badge badge-primary rounded-pill d-inline">En cours</span>
            <span *ngIf="patient.status === 3" class="badge badge-success rounded-pill d-inline">Terminé</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>