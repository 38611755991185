<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Iris" (click)="displayAndHideIris()">IRIS</h4>
            <div class="arrow arrow-up btnIris" *ngIf="displayIris" title="Cacher IRis" (click)="displayAndHideIris()">▲</div>
            <div class="arrow arrow-up btnIris" *ngIf="!displayIris" title="Afficher Iris" (click)="displayAndHideIris()">▼</div>
        </div>

        <div *ngIf="displayIris" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi: examLaf?.laf.irisNormale.oielDroit.normale.present==true }" (click)="addRemoveFromIrisRight('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielDroit.atrophieirienne.present==true}" (click)="addRemoveFromIrisRight('Atrophie irienne')">
                                    Atrophie irienne
                                </li>
                                <div *ngIf="showMoreIris">
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielDroit.convexe.present==true}" (click)="addRemoveFromIrisRight('Convexe')">
                                        Convexe
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielDroit.cristauxirien.present==true}" (click)="addRemoveFromIrisRight('Cristaux irien')">
                                        Cristaux irien
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.irisNormale.oielDroit.granulomeirien.present==true}" (click)="addRemoveFromIrisRight('Granulome irien')">
                                        Granulome irien
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielDroit.heterochromie.present==true}" (click)="addRemoveFromIrisRight('Hétérochromie')">
                                        Hétérochromie
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielDroit.irisconcave.present==true}" (click)="addRemoveFromIrisRight('Iris concave')">
                                        Iris concave
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielDroit.melanomeMassesuspecte.present==true}" (click)=" addRemoveFromIrisRight('Mélanome/masse suspecte') ">
                                        Mélanome/masse suspecte
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi: examLaf?.laf.irisNormale.oielDroit.naevusirien.present==true}" (click)="addRemoveFromIrisRight('Naevus irien')">
                                    Naevus irien
                                </li>
                                <li [ngClass]="{  selectedLi:examLaf?.laf.irisNormale.oielDroit.noduleirien.present==true}" (click)="addRemoveFromIrisRight('Nodule irien')">
                                    Nodule irien
                                </li>
                                <div *ngIf="showMoreIris">
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.irisNormale.oielDroit.plaieirienne.present==true}" (click)="addRemoveFromIrisRight('Plaie irienne')">
                                        Plaie irienne
                                    </li>
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.irisNormale.oielDroit.rubeose.present==true}" (click)="addRemoveFromIrisRight('Rubéose')">
                                        Rubéose
                                    </li>
                                    <li [ngClass]="{  selectedLi:  examLaf?.laf.irisNormale.oielDroit.SPEirien.present==true}" (click)="addRemoveFromIrisRight('SPE irien')">
                                        SPE irien
                                    </li>
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.irisNormale.oielDroit.synechieiridocristalienne.present==true}" (click)=" addRemoveFromIrisRight( 'Synéchie irido-cristallienne' ) ">
                                        Synéchie irido-cristallienne
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorIris" [ngClass]="{'selectedLi': checkExistAnomalieIrisRight(anomaly.id)}">
                                        <span (click)="addRemoveFromIrisRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieIrisRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-iris')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieIris" (keyup.enter)="addNewAnomalyIris($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreIrisSection() *ngIf="!showMoreIris">Voir plus</span>
                            <span (click)=showMoreIrisSection() *ngIf="showMoreIris">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>

                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.normale.present==true }" (click)="addRemoveFromIrisLeft('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.atrophieirienne.present==true}" (click)="addRemoveFromIrisLeft('Atrophie irienne')">
                                    Atrophie irienne
                                </li>
                                <div *ngIf="showMoreIris">
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.convexe.present==true}" (click)="addRemoveFromIrisLeft('Convexe')">
                                        Convexe
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.cristauxirien.present==true}" (click)="addRemoveFromIrisLeft('Cristaux irien')">
                                        Cristaux irien
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.irisNormale.oielGauche.granulomeirien.present==true}" (click)="addRemoveFromIrisLeft('Granulome irien')">
                                        Granulome irien
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.heterochromie.present==true}" (click)="addRemoveFromIrisLeft('Hétérochromie')">
                                        Hétérochromie
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.irisconcave.present==true}" (click)="addRemoveFromIrisLeft('Iris concave')">
                                        Iris concave
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.melanomeMassesuspecte.present==true}" (click)=" addRemoveFromIrisLeft('Mélanome/masse suspecte') ">
                                        Mélanome/masse suspecte
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.naevusirien.present==true}" (click)="addRemoveFromIrisLeft('Naevus irien')">
                                    Naevus irien
                                </li>
                                <li [ngClass]="{  selectedLi:examLaf?.laf.irisNormale.oielGauche.noduleirien.present==true}" (click)="addRemoveFromIrisLeft('Nodule irien')">
                                    Nodule irien
                                </li>
                                <div *ngIf="showMoreIris">
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.plaieirienne.present==true}" (click)="addRemoveFromIrisLeft('Plaie irienne')">
                                        Plaie irienne
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.rubeose.present==true}" (click)="addRemoveFromIrisLeft('Rubéose')">
                                        Rubéose
                                    </li>
                                    <li [ngClass]="{  selectedLi:examLaf?.laf.irisNormale.oielGauche.SPEirien.present==true}" (click)="addRemoveFromIrisLeft('SPE irien')">
                                        SPE irien
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.irisNormale.oielGauche.synechieiridocristalienne.present==true}" (click)=" addRemoveFromIrisLeft( 'Synéchie irido-cristallienne' ) ">
                                        Synéchie irido-cristallienne
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorIris" [ngClass]="{'selectedLi': checkExistAnomalieIrisLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromIrisLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieIrisLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-iris')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieIris" (keyup.enter)="addNewAnomalyIris($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreIrisSection() *ngIf="!showMoreIris">Voir plus</span>
                            <span (click)=showMoreIrisSection() *ngIf="showMoreIris">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.irisNormale.oielDroit.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.noduleirien.present==true">
                        <h5>Nodule Irien</h5>
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.noduleirien.type">
                                    <option value="Nodule de Koeppe">Nodule de Koeppe</option>
                                    <option value="Nodule de Busacca">Nodule de Busacca</option>
                                </select>
                            </div>

                            <!-- description -->

                            <div class="col-md-6 col-lg-6">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.noduleirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.plaieirienne.present==true">
                        <h5>Plaie irienne</h5>
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <label>Nombre</label>
                                <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.plaieirienne.nombre" (change)="checkSign($event)" />
                            </div>

                            <div class="col-md-6 col-lg-6">
                                <label>Localisation</label>
                                <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.plaieirienne.localisation" />
                            </div>
                        </div>
                        <!-- description -->
                        <div class="row">
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.plaieirienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.heterochromie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hétérochromie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.heterochromie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.rubeose.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Rubéose </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.rubeose.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.SPEirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">SPE irien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.SPEirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.melanomeMassesuspecte.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mélanome/masse suspecte </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.melanomeMassesuspecte.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.granulomeirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Granulome irien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.granulomeirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.irisconcave.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Iris concave </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.irisconcave.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.convexe.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Convexe </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.convexe.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.cristauxirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Cristaux irien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.cristauxirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.atrophieirienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Atrophie irienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.atrophieirienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.synechieiridocristalienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Synéchie irido-cristallienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.synechieiridocristalienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielDroit.naevusirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Naevus irien</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielDroit.naevusirien.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.irisNormale.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="margin-t-10" class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.irisNormale.oielGauche.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.noduleirien.present==true">
                        <h5>Nodule Irien</h5>
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.noduleirien.type">
                                    <option value="Nodule de Koeppe">Nodule de Koeppe</option>
                                    <option value="Nodule de Busacca">Nodule de Busacca</option>
                                </select>
                            </div>

                            <!-- description -->

                            <div class="col-md-6 col-lg-6">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.noduleirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.plaieirienne.present==true">
                        <h5>Plaie irienne</h5>
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <label>Nombre</label>
                                <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.plaieirienne.nombre" (change)="checkSign($event)" />
                            </div>

                            <div class="col-md-6 col-lg-6">
                                <label>Localisation</label>
                                <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.plaieirienne.localisation" />
                            </div>
                        </div>
                        <!-- description -->
                        <div class="row">
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.plaieirienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.heterochromie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hétérochromie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.heterochromie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.rubeose.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Rubéose </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.rubeose.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.SPEirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">SPE irien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.SPEirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.melanomeMassesuspecte.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mélanome/masse suspecte </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.melanomeMassesuspecte.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.granulomeirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Granulome irien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.granulomeirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.irisconcave.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Iris concave </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.irisconcave.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.convexe.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Convexe </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.convexe.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.cristauxirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Cristaux irien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.cristauxirien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.atrophieirienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Atrophie irienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.atrophieirienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.synechieiridocristalienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Synéchie irido-cristallienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.synechieiridocristalienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.irisNormale.oielGauche.naevusirien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Naevus irien</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.irisNormale.oielGauche.naevusirien.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.irisNormale.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="margin-t-10" class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>