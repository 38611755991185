import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable} from 'rxjs';

import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class OphtalmoAuthGuard implements CanActivate {
  medecin: any;
  constructor(private router: Router, private authService: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(!localStorage.getItem('medecin') || !localStorage.getItem('id_token')){
        this.router.navigate(["loginMedecin"]);
        localStorage.clear()
        return false;
    }
    this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin) {
        this.router.navigate(["loginMedecin"]);
        localStorage.clear()
        return false;
        }
        // if (this.medecin && this.medecin.specialite != 'Ophtalmologiste') 
        if (this.medecin && !this.medecin.specialite ) {
            this.router.navigate(["medecin/patients-liste"]);
            return false;
        }
        return true
      },
        err => {
            return true
        });
    return true
  }
}
//to protect routers