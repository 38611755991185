import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-rpm',
  templateUrl: './rpm.component.html',
  styleUrls: ['./rpm.component.css', '../laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class RpmComponent implements OnInit {
  //---------------------------------------------------------------- RPM
  displayRpm = true;
  rpmListLeft = [
    "Présent et symétrique",
    "Myosis",
    "Mydriase ",
    "Semi mydriase",
    "Marcus gun +",
  ];
  rpmListRight = [
    "Présent et symétrique",
    "Myosis",
    "Mydriase ",
    "Semi mydriase",
    "Marcus gun +",
  ];

  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  //!----- anomalies by a doctor
  // RPM : Présent et symétrique
  anomaliesByDoctorRpm: any[] = []
  oldlistAnomaliesRpm = this.rpmListLeft
  newAnomalieRpm = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreRpm = false
  //end of Show More Variables
  // send object
  objecttoSend = {
    rpmOD: {},
    rpmOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false

  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-rpm').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-rpmpresentetsymetrique", 'value': this.examLaf.laf.rpmpresentetsymetrique });
  }
  showMoreRpmSection() {
    this.showMoreRpm = !this.showMoreRpm
  }

  configureDataExamen(idPatient) {
    // !Historique LAF
    this.examenService.getInspectionHistoriques(idPatient, "laf").subscribe((data: any) => {
      this.oldListConsultations = data
    },
      err => {
        return false;
      });
    // !RPM : Présent et symétrique
    this.anomalieService.getAnomalieByDoctor("laf-rpm").subscribe(data => {
      this.anomaliesByDoctorRpm = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }

  displayAndHideRpm() {
    this.displayRpm = !this.displayRpm
  }
  //------------------------------------------------------------------------------ RPM
  showRpm() {
    this.examLaf.laf.rpmpresentetsymetrique.present = false;
  }

  addRemoveFromRpmRight(value) {
    this.examLaf.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present = false
    switch (value) {
      case 'Présent et symétrique': this.setAllValueToFlase(this.examLaf.laf.rpmpresentetsymetrique.oielDroit); this.examLaf.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present = true
        break
      case 'Myosis': this.examLaf.laf.rpmpresentetsymetrique.oielDroit.myosis.present = !this.examLaf.laf.rpmpresentetsymetrique.oielDroit.myosis.present
        break
      case 'Mydriase': this.examLaf.laf.rpmpresentetsymetrique.oielDroit.mydriase.present = !this.examLaf.laf.rpmpresentetsymetrique.oielDroit.mydriase.present
        break
      case 'Semi mydriase': this.examLaf.laf.rpmpresentetsymetrique.oielDroit.semimydriase.present = !this.examLaf.laf.rpmpresentetsymetrique.oielDroit.semimydriase.present
        break
      case 'Marcus gun +': this.examLaf.laf.rpmpresentetsymetrique.oielDroit.marcusgun.present = !this.examLaf.laf.rpmpresentetsymetrique.oielDroit.marcusgun.present
        break
    }
    this.setRPMToTrue(this.examLaf.laf.rpmpresentetsymetrique.oielDroit)
    this.objecttoSend.rpmOD = this.examLaf.laf.rpmpresentetsymetrique.oielDroit
    this.objecttoSend.source = "rpmOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromRpmLeft(value) {
    this.examLaf.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present = false
    switch (value) {
      case 'Présent et symétrique': this.setAllValueToFlase(this.examLaf.laf.rpmpresentetsymetrique.oielGauche); this.examLaf.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present = true
        break
      case 'Myosis': this.examLaf.laf.rpmpresentetsymetrique.oielGauche.myosis.present = !this.examLaf.laf.rpmpresentetsymetrique.oielGauche.myosis.present
        break
      case 'Mydriase': this.examLaf.laf.rpmpresentetsymetrique.oielGauche.mydriase.present = !this.examLaf.laf.rpmpresentetsymetrique.oielGauche.mydriase.present
        break
      case 'Semi mydriase': this.examLaf.laf.rpmpresentetsymetrique.oielGauche.semimydriase.present = !this.examLaf.laf.rpmpresentetsymetrique.oielGauche.semimydriase.present
        break
      case 'Marcus gun +': this.examLaf.laf.rpmpresentetsymetrique.oielGauche.marcusgun.present = !this.examLaf.laf.rpmpresentetsymetrique.oielGauche.marcusgun.present
        break
    }
    this.setRPMToTrue(this.examLaf.laf.rpmpresentetsymetrique.oielGauche)
    this.objecttoSend.rpmOG = this.examLaf.laf.rpmpresentetsymetrique.oielGauche
    this.objecttoSend.source = "rpmOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }


  //!--------------------RPM : Présent et symétrique
  addNewAnomalyRpm(event) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorRpm && this.anomaliesByDoctorRpm.length > 4) {
      showToast("Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieRpm = "";
      return false;
    }

    const value = event?.target?.value;
    if (value && value.length > 1 && value.length < 100) {
      const anomalyExists = this.anomaliesByDoctorRpm.some(x => x.titre?.toLowerCase() === value.toLowerCase());
      const oldAnomalyExists = this.oldlistAnomaliesRpm.some(x => x.toLowerCase() === value.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          "titre": value.charAt(0).toUpperCase() + value.slice(1),
          "description": "",
          "zone": "laf-rpm",
        }).subscribe((data) => {
          this.newAnomalieRpm = "";
          this.anomaliesByDoctorRpm.push(data);
          showToast('Bien enregistré', '#386641', '#386641', '#6a994e', '#dde5b6');
        });
        return true;
      } else {
        showToast("L'anomalie existe dans la liste", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
        this.newAnomalieRpm = "";
        return false;
      }
    } else {
      showToast('Merci de saisir au moins 2 caractères', '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieRpm = "";
      return false;
    }
  }

  // Right Rpm
  addRemoveFromRpmRightNew(value, id) {
    this.examLaf.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present = false
    let truefalseexist = this.examLaf.laf.rpmpresentetsymetrique.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.rpmpresentetsymetrique.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.rpmOD = this.examLaf.laf.rpmpresentetsymetrique.oielDroit
      this.objecttoSend.source = "rpmOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.rpmpresentetsymetrique.oielDroit.allAnomalies = this.examLaf.laf.rpmpresentetsymetrique.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.rpmOD = this.examLaf.laf.rpmpresentetsymetrique.oielDroit
      this.objecttoSend.source = "rpmOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setRPMToTrue(this.examLaf.laf.rpmpresentetsymetrique.oielDroit)
      return true;
    }

  }
  checkExistAnomalieRpmRight(id) {
    return this.examLaf.laf.rpmpresentetsymetrique.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Rpm dyna
  addRemoveFromRpmLeftNew(value, id) {
    this.examLaf.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present = false
    let truefalseexist = this.examLaf.laf.rpmpresentetsymetrique.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.rpmpresentetsymetrique.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.rpmOG = this.examLaf.laf.rpmpresentetsymetrique.oielGauche
      this.objecttoSend.source = "rpmOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.rpmpresentetsymetrique.oielGauche.allAnomalies = this.examLaf.laf.rpmpresentetsymetrique.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.rpmOG = this.examLaf.laf.rpmpresentetsymetrique.oielGauche
      this.objecttoSend.source = "rpmOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setRPMToTrue(this.examLaf.laf.rpmpresentetsymetrique.oielGauche)
      return true;
    }

  }
  checkExistAnomalieRpmLeft(id) {
    return this.examLaf.laf.rpmpresentetsymetrique.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of RPM : Présent et symétrique
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-rpm') {
        this.anomaliesByDoctorRpm.splice(this.anomaliesByDoctorRpm.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }

  // RPM
  setRPMToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "presentEtSymetrique") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.presentEtSymetrique.present = true;
    }
    return myObject
  }

}
