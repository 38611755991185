<section class="fond">
  <section class=" container2 ">
    <div class="searchPat ">
      <div class=" search4">
        <h4 id="patientsidposition">
          LISTE DES CONSULTATIONS

        </h4>
        <br>
        <div class="row cover-search-row2">
          <div class="col-5" style="margin-right: 4%">
            <div class="row rowSearch2">
              <div class="col-10 name-col-10">
                <input type="text" name="search" [(ngModel)]="search" placeholder="Recherche ...">
              </div>
              <div class="col-1 name-col-1">
                <img id="name_img" src="assets/images/search_3.png" alt="">
              </div>
            </div>
          </div>

          <div class="col-2 buttons">
            <div class="row ">

              <div class="col-5 butt1">
                <button class="btn btn-search2" title="Nouveau" [routerLink]="['/medecin/ajout/interrogatoire/659512598df127126320ff1a']"><i class="fas fa-search" style="visibility: hidden;"></i></button>
              </div>
              <div class="col-5">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <section style="padding-top: 115px">
    <div class="div-tab">
      <div class="row" style="overflow-x:auto;">
        <table class="table  ">
          <thead>
            <th class="text-center"><b>PATIENT</b></th>
            <th class="text-center"><b>MOTIF</b></th>
            <th class="text-center"><b>EMAIL</b></th>
            <th class="text-center"><b>TÉLÉPHONE</b></th>
            <th class="text-center"><b>DATE</b></th>
            <th class="  gauche" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let examen of examens | globalSearch : search.toString().toLowerCase()|paginate: { itemsPerPage: 25, currentPage: p }" style="cursor: pointer;">
              <td [routerLink]="['/medecin/examens', examen._id]" class="text-center">{{examen.nom_patient}}&nbsp;{{examen.prenom_patient}}</td>
              <td [routerLink]="['/medecin/examens', examen._id]" class="text-center">{{examen.motif}}</td>
              <td [routerLink]="['/medecin/examens', examen._id]" class="text-center">{{examen.email}}</td>
              <td [routerLink]="['/medecin/examens', examen._id]" class="text-center">
                <p *ngIf="examen.tel?.length > 4">{{examen.tel}}</p>
              </td>
              <td [routerLink]="['/medecin/examens', examen._id]" class="text-center">{{examen.date | date: 'dd/MM/yyyy, à H:mm'}}</td>
              <td class="row gauche" style="border-bottom-style: none;">
                <div class="col-1" style="margin-right: 5%" [routerLink]="['/medecin/examens',examen._id]" title="Modifier">
                  <img src="assets/images/edit.png" class="iconParametres">
                </div>
                <div class="col-1" title="Supprimer">
                  <a (click)="setVariabletoDelet(examen)" title="Supprimer" data-toggle="modal" data-target="#myModal6"><img src="assets/images/delete.png" class="iconParametres"></a>
                </div>
              </td>
              <!-- modal suppression patient -->
              <div class="modal fade" id="myModal6" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content modal-content-scan">
                    <div class="modal-header d-flex justify-content-center">
                      <h3 class="titre-modal-statut">
                        SUPPRIMER RENDEZ-VOUS
                      </h3>
                    </div>
                    <div class="modal-body">
                      <div class="row" style="margin-left: 6%;">
                        <div class="col-1" style="   margin-right: 4%;"><img src="assets/images/doc1.png" class="imgPop"></div>
                        <div class="col-10">
                          <h4 class="textContenu">VOULEZ-VOUS SUPPRIMER CE RENDEZ-VOUS?</h4>
                        </div>
                      </div>
                      <div class="modal-footer d-flex justify-content-center">
                        <div class=" col d-flex justify-content-center buttonsPopup">
                          <div class="col-auto nbr-dossier ">
                            <table>
                              <tr>
                                <td class="tdButt1 ">
                                  <button class=" close btn-valider" data-dismiss="modal" (click)="supprimerExamen()"><img src="assets/images/delete2.png" class="iconBoutton">Supprimer </button>
                                </td>
                                <td class="tdButt2 ">
                                  <button class="close btn-annule" data-dismiss="modal"><img src="assets/images/annuler.png" class="iconBoutton">Annuler </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- modal suppression patient -->
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="pagination">
      <div class="row justify-content-center">
        <div class="col-12 " style="padding-top: 10px; padding-left: 35px">
          <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>

        </div>
      </div>
    </div>
    <br>
  </section>
</section>