import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import * as moment from 'moment';
import * as  uuid from 'uuid';
import { API_CONFIG } from 'config/API_CONFIG';
import { _getURL } from 'config/API_CONFIG';
import 'moment-timezone';


@Component({
  selector: 'app-document-examen',
  templateUrl: './document-examen.component.html',
  styleUrls: ['./document-examen.component.css', '../cat/cat.component.css']
})
export class DocumentExamenComponent implements OnInit {
  myExamen: any;

  files: any[] = []
  listFiles: any[] = []
  myUUID: any
  filesCnam = [{ "filename": "AP1", "filepath": "AP1.pdf" },
  { "filename": "AP2", "filepath": "AP2.pdf" },
  { "filename": "AP3", "filepath": "AP3.pdf" },
  { "filename": "AP4", "filepath": "AP4.pdf" }, {
    "filename": "Demande d'examen par imagerie médicale", "filepath": "demande-examen-imagerie-medicale.pdf"
  },
  { "filename": "Demande de choix de filière", "filepath": "dde_changement_filiere.pdf" },
  { "filename": "Demande de choix de médecin de famille", "filepath": "medecin_famille.pdf" },
  { "filename": "Demande de prise en charge de soins et explorations soumis à l'accord préalable", "filepath": "demande-prise-charge-soins.pdf" },
  { "filename": "Demande d'indemnité de maladie ou de couches", "filepath": "demande-maladie-couches.pdf" },
  { "filename": "Certificat médical d'indemnité de maladie ou de couches", "filepath": "certificat-medical-maladie-couche.pdf" },
  ]

  isActiveConduiteAtenir = false
  isActiveExploration = false
  isActiveCNAM = true
  constructor(private examenService: ExamenService, private route: ActivatedRoute, public iziToast: Ng2IzitoastService) {

  }

  ngOnInit() {
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getExamenByIdForCat(idExamen).subscribe((data: any) => {
        this.myExamen = data
        if (this.myExamen && this.myExamen.cat) {
          if (!this.myExamen.key) {
            this.myExamen.key = uuid.v4();
          }
          this.configureDataExamen()
          return true;
        }
      },
        err => {
          return false;
        });
    }

  }

  configureDataExamen() {
    this.myUUID = this.myExamen.key
  }
  // controles added files
  droppedFile(files) {
    this.files = files.target.files;
    if (!this.files || (this.files && this.files.length == 0)) {
      return false;
    }
    if ((this.myExamen.documents.length + this.files.length) > 10) {
      this.iziToast.show({
        message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      this.files = []
      return false;
    }
    const validExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'txt'];
    for (var j = 0; this.files.length; j++) {
      if (this.files[j] && this.files[j] != undefined) {
        const fileExtension = this.files[j].name.split('.').pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          this.iziToast.show({
            message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          this.listFiles = []
          return false;
        }
        if (this.files[j].size / (1024 * 1024) > 50) {
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 50 Mo.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          this.listFiles = []
          return false;
        }
        this.listFiles.push(this.files[j])
      } else {
        return false;
      }


    }
  }
  // delete files

  deleteFiles(files) {
    this.listFiles.splice(this.listFiles.indexOf(files), 1);
    return false;
  }
  // save documents 
  async saveDocuments() {
    var filesList = []
    if (this.listFiles.length != 0) {
      for (let file of this.listFiles) {
        //const newFileName = 'hello' +'.'+this.getFileExtension(file.name);; // Change this to the new file name
        const originalFileName = file.name;
        let fileExtension = this.getFileExtension(originalFileName);
        fileExtension = '.' + fileExtension
        const timestamp = new Date().toISOString().replace(/[-T:]/g, '').split('.')[0] + this.listFiles.indexOf(file);
        const newFileName = `file_${timestamp}${fileExtension}`
        const newFile: File = new File([file], encodeURIComponent(newFileName), { type: file.type });
        const formData: FormData = new FormData();
        formData.append('file', newFile);
        await this.examenService.uploadFiles(formData, this.myUUID).subscribe(e => {
        })

        filesList.push({
          "label": newFileName,
          "file": originalFileName,
          "createdAt": moment().tz('Africa/Tunis').format(),
        })
      }
      this.myExamen.documents.push(...filesList)
      this.updateExamen(this.myExamen)
      return true;
    } else {
      this.updateExamen(this.myExamen)
      return true;
    }

  }
  getFileExtension(fileName: string): string {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  // delete old files 

  deleteOldFiles(doc) {
    this.myExamen.documents.splice(this.myExamen.documents.indexOf(doc), 1)
    return true;
  }
  viewDoc(doc) {
    window.open(`${_getURL("")}data/files/examens/${this.myExamen.key}/${doc.label}`, '_blank')
  }
  viewPrescirption(idFile) {
    window.open(`medecin/impression/${this.myExamen.id}/${idFile}`, '_blank')
  }

  // update examen
  updateExamen(myExamen) {
    this.listFiles = []
    while (this.listFiles.length > 0) {
      this.listFiles.pop();
    }
    this.examenService.updateExamen(this.myExamen).subscribe((data: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });

    });
    return true;
  }
  openFileCnam(filecnam) {
    window.open(`${_getURL("")}data/files/cnam/${filecnam.filepath}`, '_blank')
  }

  // bloc of menu
  blocConduiteAtenir() {
    this.isActiveConduiteAtenir = true
    this.isActiveExploration = false
    this.isActiveCNAM = false
  }
  blocExploration() {
    this.isActiveConduiteAtenir = false
    this.isActiveExploration = true
    this.isActiveCNAM = false
  }

  blocCNAM() {
    this.isActiveConduiteAtenir = false
    this.isActiveExploration = false
    this.isActiveCNAM = true
  }
}
