import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Output, QueryList, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';
import { LafComponent } from './laf/laf.component';


@Component({
  selector: 'app-examen',
  templateUrl: './examen.component.html',
  styleUrls: ['./examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})

export class ExamenComponent implements OnInit {
  @Output() navigateToConclusion: EventEmitter<void> = new EventEmitter<void>();
  // history varaibalbe
  expandedIndex = -1;
  selectedHistorique: any;
  // history varaibalbe
  ng2slectwidh: any;
  screenWidth: any;
  isActiveInspection = true
  isActiveRefraction = false;
  isActiveRefractionsubjective = false;
  isActiveacuite = false;
  isActiveAnnexe = false;
  isActiveLaf = false;
  inspectionNormal = true;
  inspectionAnomalie = false
  inspectionNormallistSelectedLeft = []
  inspectionNormallistSelectedRight = []
  options: any;
  attitudeMalVoyance = false;
  //-------------------autres
  todayExamenRef = true
  showRefSubjective = true
  showAcuite = true
  todayExamen = true
  myExamen: any
  examRefraction: any
  examAnexe: any
  examLaf: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  subMenu = false
  // send data 
  objecttoSend = {
    inspectionOD: {},
    inspectionOG: {},
    annexeOD: {},
    annexeOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""

  };
  // anomalies by a doctor
  anomaliesByDoctor: any[] = []
  inscpectionoldlistAnomalies = ["Normal", "Attitude de malvoyance", "Buphtalmie", "Exphtalmie", "Énophtalmie", "Microphtalmie", "Leucocorie", "Prothèse oculaire", "Plaies", "Paralysie faciale", "Ptosis", "Strabisme"]
  newAnomalie = ""
  newAnomalieGauche = ""
  anomalieOeilRightListNew: any[] = []
  anomaliesByDoctorAnnexe: any[] = []
  annexeoldlistAnomalies = ["Normale", "Paralysie occulomotrice", "Ophtalmologie", "Insuffisance de convergence", "Réflet cornéen décentré", "Présence de strabisme", "Plaie", "Dermabrasion", "Ptosis", "Chalazion", "Orgelet", "Entropion", "Ectropion", "Diplopie", "Distichoasis", "Meeibomite", "DGM", "Telangiectasie", "Tumeur paplepbrale", "DCA", "DCC", "Sténose lacrymal", "Masse de la loge lacrymal", "Fistule", "Écoulement lacrymal", "Sonde de DCR en place"]
  // end anomalies by a doctor
  oldListConsultations: any[] = []
  p = 1
  displayHistorique = false;
  showMore = false
  paginatedList: any[] = [];
  itemsPerPage: number = 1; // Number of items per page
  paginationId: string = 'datePagination'; // ID for pagination

  isToastVisible = false


  constructor(private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
    window.addEventListener('scroll', function () {
      var menu = document.getElementById('menu');
      var content = document.getElementById('content');
      if (menu && content) {
        var rect = menu.getBoundingClientRect();

        if (window.scrollY > rect.top) {
          menu.style.transition = 'margin-top 0.3s ease';
          menu.style.marginTop = window.scrollY - 50 + "px";
        } else {
          menu.style.transition = 'none';
          menu.style.marginTop = content.style.marginTop
        }
      }

    });
  }

  ngOnInit(): void {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // get inspection
    if (idExamen && idExamen != undefined && idExamen != null && idExamen != "undefined" && idPatient && idPatient != undefined && idPatient != null && idPatient != "undefined") {
      this.examenService.getInspectionById(idExamen).subscribe((data: any) => {
        this.myExamen = data
        //this.myExamen.laf = {}
        if (this.myExamen) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }

    this.options = {
      multiple: true,
      maximumSelectionLength: 20
    }
    this.screenWidth = window.innerWidth;
    // Subscribe to screen width changes
    this.responsiveService.getScreenWidth().subscribe((width) => {
      this.screenWidth = width;
      if (this.screenWidth < 768) {
        // Handle small screens
        this.ng2slectwidh = 500
        return true;
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        // Handle medium screens
        this.ng2slectwidh = 700
        return true;
      } else {
        // Handle large screens
        this.ng2slectwidh = 700
        return true;
      }


    });
    // get data
    this.sharedDataService.dataBtnLaf$.subscribe((data) => {
      if (data && data.type && data.type == 'laf-sclere') {
        this.myExamen.laf.sclere = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-conj') {
        this.myExamen.laf.conj = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-corneetransparente') {
        this.myExamen.laf.corneetransparente = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-chambreanterieurealmeetprofonde') {
        this.myExamen.laf.chambreanterieurealmeetprofonde = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-irisNormale') {
        this.myExamen.laf.irisNormale = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-rpmpresentetsymetrique') {
        this.myExamen.laf.rpmpresentetsymetrique = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-dilatation') {
        this.myExamen.laf.dilatation = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-cristallinTransparent') {
        this.myExamen.laf.cristallinTransparent = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-angleiridocornee') {
        this.myExamen.laf.angleiridocornee = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-vitre') {
        this.myExamen.laf.vitre = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-papille') {
        this.myExamen.laf.papille = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-macule') {
        this.myExamen.laf.macule = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-anomalieVasculaire') {
        this.myExamen.laf.anomalieVasculaire = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-retine') {
        this.myExamen.laf.retine = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-Pachymetrie') {
        this.myExamen.laf.pachymtrie = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-butShirmer') {
        this.myExamen.laf.butShirmer = data.value
        return true
      }
      if (data && data.type && data.type == 'laf-toAic') {
        this.myExamen.laf.toAic = data.value
        return true
      }
    });
  }

  displayAndHidetodayExamen() {
    this.todayExamen = !this.todayExamen
  }

  configureDataExamen(idPatient) {
    // get historiques inscpection 

    this.examenService.getInspectionHistoriques(idPatient, "inspection").subscribe((data: any) => {
      this.oldListConsultations = data.reverse()
    },
      err => {
        return false;
      });

    this.anomalieService.getAnomalieByDoctor("inspection").subscribe(data => {
      this.anomaliesByDoctor = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.myExamen.motif = this.selectedMotif
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }
  receiveTodayExamFromChild(todayExamenRef: boolean) {
    this.todayExamenRef = todayExamenRef;
  }
  receiveAcuiteValuesFromChild(showAcuite: boolean) {
    // Assign values received from the child
    this.showAcuite = showAcuite;
  }
  receiveRefSubValuesFromChild(showRefSubjective: boolean) {
    this.showRefSubjective = showRefSubjective;
  }

  blocInspection() {
    this.isActiveInspection = true
    this.isActiveRefraction = false;
    this.isActiveAnnexe = false;
    this.isActiveLaf = false;
    this.isActiveRefractionsubjective = false;
    this.isActiveacuite = false;
    window.scrollTo(0, 0)
  }
  blocRefraction() {
    this.subMenu = !this.subMenu
    this.isActiveInspection = false
    this.isActiveRefraction = true;
    this.isActiveAnnexe = false;
    this.isActiveLaf = false;
    this.isActiveRefractionsubjective = false;
    this.isActiveacuite = false;
    window.scrollTo(0, 0)
  }

  blocAcuiteVis() {
    this.isActiveInspection = false
    this.isActiveRefraction = true;
    this.isActiveAnnexe = false;
    this.isActiveLaf = false;
    this.isActiveRefractionsubjective = false;
    this.isActiveacuite = true;
    this.todayExamenRef = true
    this.showAcuite = true;
    setTimeout(() => {
      let el = document.getElementById('acuiteVisuelle')
      if (el) {
        const offset = 100; // Adjust this value to add an offset if needed
        const rect = el.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.scrollY - offset,
          behavior: 'smooth'
        });
      }
    }, 100);
  }
  blocRefractionSubjective() {
    this.isActiveInspection = false
    this.isActiveRefraction = true;
    this.isActiveAnnexe = false;
    this.isActiveLaf = false;
    this.isActiveRefractionsubjective = true;
    this.isActiveacuite = false;
    this.todayExamenRef = true
    this.showRefSubjective = true
    setTimeout(() => {
      let el = document.getElementById('refractionSubj')
      if (el) {
        const offset = 100; // Adjust this value to add an offset if needed
        const rect = el.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.scrollY - offset,
          behavior: 'smooth'
        });
      }
    }, 100);
  }

  blocAnnexe() {
    this.isActiveInspection = false
    this.isActiveRefraction = false;
    this.isActiveAnnexe = true;
    this.isActiveLaf = false;
    this.isActiveRefractionsubjective = false;
    this.isActiveacuite = false;
    window.scrollTo(0, 0)


  }
  blocLaf() {
    this.isActiveInspection = false
    this.isActiveRefraction = false;
    this.isActiveRefractionsubjective = false;
    this.isActiveacuite = false;
    this.isActiveAnnexe = false;
    this.isActiveLaf = true;
    window.scrollTo(0, 0)

  }
  // change toggle inscpection normal
  updateInscpectionNormal() {
    this.myExamen.inspection.anomaly.present = false;
  }
  // change toggle inspection anaomalie
  updateInscpectionAnomalie() {
    this.myExamen.inspection.normal.present = false
  }
  //select right insecption normal recherche 
  onSelectedinspectionNormallistRight(value) {
    if (this.inspectionNormallistSelectedRight.indexOf(value.target.value) < 0) {
      this.inspectionNormallistSelectedRight.push(value.target.value)

    }
  }
  //select right insecption left recherche 
  onSelectedinspectionNormallistLeft(value) {
    if (this.inspectionNormallistSelectedLeft.indexOf(value.target.value) < 0) {
      this.inspectionNormallistSelectedLeft.push(value.target.value)

    }
  }
  deleteinspectionNormallistSelectedRight(element) {
    this.inspectionNormallistSelectedRight.splice(this.inspectionNormallistSelectedRight.indexOf(element), 1)
  }
  deleteinspectionNormallistSelectedLeft(element) {
    this.inspectionNormallistSelectedLeft.splice(this.inspectionNormallistSelectedLeft.indexOf(element), 1)
  }
  addRemoveFromAnomalieLef(value) {
    this.myExamen.inspection.anomaly.oielGauche.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.myExamen.inspection.anomaly.oielGauche); this.myExamen.inspection.anomaly.oielGauche.normale.present = true
        break
      case 'Exphtalmie': this.myExamen.inspection.anomaly.oielGauche.exphtalmie.present = !this.myExamen.inspection.anomaly.oielGauche.exphtalmie.present
        break
      case 'Énophtalmie': this.myExamen.inspection.anomaly.oielGauche.enophtalmie.present = !this.myExamen.inspection.anomaly.oielGauche.enophtalmie.present
        break
      case 'Strabisme': this.myExamen.inspection.anomaly.oielGauche.strabisme.present = !this.myExamen.inspection.anomaly.oielGauche.strabisme.present
        break
      case 'Leucocorie': this.myExamen.inspection.anomaly.oielGauche.leucocorie.present = !this.myExamen.inspection.anomaly.oielGauche.leucocorie.present
        break
      case 'Microphtalmie': this.myExamen.inspection.anomaly.oielGauche.microphtalmie.present = !this.myExamen.inspection.anomaly.oielGauche.microphtalmie.present
        break
      case 'Attitude de malvoyance': this.myExamen.inspection.anomaly.oielGauche.attitudedemalvoyance.present = !this.myExamen.inspection.anomaly.oielGauche.attitudedemalvoyance.present
        break
      case 'Prothèse oculaire': this.myExamen.inspection.anomaly.oielGauche.protheseoculaire.present = !this.myExamen.inspection.anomaly.oielGauche.protheseoculaire.present
        break
      case 'Buphtalmie': this.myExamen.inspection.anomaly.oielGauche.buphtalmie.present = !this.myExamen.inspection.anomaly.oielGauche.buphtalmie.present
        break
      case 'Plaies': this.myExamen.inspection.anomaly.oielGauche.plaies.present = !this.myExamen.inspection.anomaly.oielGauche.plaies.present
        break
      case 'Paralysie faciale': this.myExamen.inspection.anomaly.oielGauche.paralysiefaciale.present = !this.myExamen.inspection.anomaly.oielGauche.paralysiefaciale.present
        break
      case 'Ptosis': this.myExamen.inspection.anomaly.oielGauche.ptosis.present = !this.myExamen.inspection.anomaly.oielGauche.ptosis.present
        break
    }
    this.setToTrue(this.myExamen.inspection.anomaly.oielGauche)
    this.objecttoSend.inspectionOG = this.myExamen.inspection.anomaly.oielGauche
    this.objecttoSend.source = "inspectionOG"

    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromAnomalieRight(value) {
    this.myExamen.inspection.anomaly.oielDroit.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.myExamen.inspection.anomaly.oielDroit); this.myExamen.inspection.anomaly.oielDroit.normale.present = true
        break
      case 'Exphtalmie': { this.myExamen.inspection.anomaly.oielDroit.exphtalmie.present = !this.myExamen.inspection.anomaly.oielDroit.exphtalmie.present; }
        break
      case 'Énophtalmie': this.myExamen.inspection.anomaly.oielDroit.enophtalmie.present = !this.myExamen.inspection.anomaly.oielDroit.enophtalmie.present
        break
      case 'Strabisme': this.myExamen.inspection.anomaly.oielDroit.strabisme.present = !this.myExamen.inspection.anomaly.oielDroit.strabisme.present
        break
      case 'Leucocorie': this.myExamen.inspection.anomaly.oielDroit.leucocorie.present = !this.myExamen.inspection.anomaly.oielDroit.leucocorie.present
        break
      case 'Microphtalmie': this.myExamen.inspection.anomaly.oielDroit.microphtalmie.present = !this.myExamen.inspection.anomaly.oielDroit.microphtalmie.present
        break
      case 'Attitude de malvoyance': this.myExamen.inspection.anomaly.oielDroit.attitudedemalvoyance.present = !this.myExamen.inspection.anomaly.oielDroit.attitudedemalvoyance.present
        break
      case 'Prothèse oculaire': this.myExamen.inspection.anomaly.oielDroit.protheseoculaire.present = !this.myExamen.inspection.anomaly.oielDroit.protheseoculaire.present
        break
      case 'Buphtalmie': this.myExamen.inspection.anomaly.oielDroit.buphtalmie.present = !this.myExamen.inspection.anomaly.oielDroit.buphtalmie.present
        break
      case 'Plaies': this.myExamen.inspection.anomaly.oielDroit.plaies.present = !this.myExamen.inspection.anomaly.oielDroit.plaies.present
        break
      case 'Paralysie faciale': this.myExamen.inspection.anomaly.oielDroit.paralysiefaciale.present = !this.myExamen.inspection.anomaly.oielDroit.paralysiefaciale.present
        break
      case 'Ptosis': this.myExamen.inspection.anomaly.oielDroit.ptosis.present = !this.myExamen.inspection.anomaly.oielDroit.ptosis.present
        break
    }
    this.setToTrue(this.myExamen.inspection.anomaly.oielDroit)
    this.objecttoSend.inspectionOD = this.myExamen.inspection.anomaly.oielDroit
    this.objecttoSend.source = "inspectionOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  //  Navigation section
  clicktbnsuivantInspection(myExamen) {
    this.blocAnnexe();
    this.updateExamen(myExamen)
  }
  clicktbnEnregitrerInspection(myExamen) {
    this.updateExamen(myExamen)
  }

  clicktbnpredentAnnexe() {
    this.blocInspection()
  }
  clicktbnsuivantAnnexe() {

    this.blocRefraction()
    this.sharedDataService.updateDataBtn({ "type": 'annexe' });

    return false
  }


  clicktbnpredentRefraction() {
    this.blocAnnexe()
  }
  clicktbnsuivantRefraction() {

    this.blocLaf()
    this.sharedDataService.updateDataBtn({ "type": 'refraction' });

    return false
  }
  clicktbnEnregitrerRefraction() {
    this.sharedDataService.updateDataBtn({ "type": 'refraction' });
  }
  clicktbnpredentLaf() {
    this.blocRefraction()
  }
  clicktbnEnregitrerLaf() {
    this.updateExamen(this.myExamen)
    setTimeout(() => {
      this.navigateToConclusion.emit();
    }, 500);
    return true;
  }



  // add New Anomaly
  addNewAnomaly(event) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctor && this.anomaliesByDoctor.length > 4) {
      showToast("Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalie = "";
      this.newAnomalieGauche = "";
      return false;
    }

    const value = event?.target?.value;
    if (value && value.length > 1 && value.length < 100) {
      const anomalyExists = this.anomaliesByDoctor.some(x => x.titre?.toLowerCase() === value.toLowerCase());
      const oldAnomalyExists = this.inscpectionoldlistAnomalies.some(x => x.toLowerCase() === value.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          "titre": value.charAt(0).toUpperCase() + value.slice(1),
          "description": "",
          "zone": "inspection",
        }).subscribe((data) => {
          this.newAnomalie = "";
          this.newAnomalieGauche = "";
          this.anomaliesByDoctor.push(data);
          showToast('Bien enregistré', '#386641', '#386641', '#6a994e', '#dde5b6');
        });
        return true;
      } else {
        showToast("L'anomalie existe dans la liste", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
        this.newAnomalie = "";
        this.newAnomalieGauche = "";
        return false;
      }
    } else {
      showToast('Merci de saisir au moins 2 caractères', '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalie = "";
      this.newAnomalieGauche = "";
      return false;
    }
  }

  // right inscpection dyna
  addRemoveFromAnomalieRightNew(value, id) {
    this.myExamen.inspection.anomaly.oielDroit.normale.present = false
    let truefalseexist = this.myExamen.inspection.anomaly.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    this.objecttoSend.inspectionOD = this.myExamen.inspection.anomaly.oielDroit
    this.objecttoSend.source = "inspectionOD"
    this.sharedDataService.updateData(this.objecttoSend);
    if (!truefalseexist) {
      this.myExamen.inspection.anomaly.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      return true;
    }
    if (truefalseexist) {
      this.myExamen.inspection.anomaly.oielDroit.allAnomalies = this.myExamen.inspection.anomaly.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.inspectionOD = this.myExamen.inspection.anomaly.oielDroit
      this.objecttoSend.source = "inspectionOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.myExamen.inspection.anomaly.oielDroit)
      return true;
    }

  }
  checkExistAnmalieRight(id) {
    return this.myExamen.inspection.anomaly.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left inspection dyna
  addRemoveFromAnomalieLeftNew(value, id) {
    this.myExamen.inspection.anomaly.oielGauche.normale.present = false
    let truefalseexist = this.myExamen.inspection.anomaly.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.myExamen.inspection.anomaly.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.inspectionOG = this.myExamen.inspection.anomaly.oielGauche
      this.objecttoSend.source = "inspectionOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.myExamen.inspection.anomaly.oielGauche.allAnomalies = this.myExamen.inspection.anomaly.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.inspectionOG = this.myExamen.inspection.anomaly.oielGauche
      this.objecttoSend.source = "inspectionOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.myExamen.inspection.anomaly.oielGauche)
      return true;
    }

  }
  checkExistAnmalieLeft(id) {
    return this.myExamen.inspection.anomaly.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }

  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'annexe') {
        this.anomaliesByDoctorAnnexe.splice(this.anomaliesByDoctorAnnexe.indexOf(anomaly), 1)
      }
      if (zone === 'inspection') {
        this.anomaliesByDoctor.splice(this.anomaliesByDoctor.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }
  openCons(historique) {
    window.open(`medecin/ajout/interrogatoire/${this.patient._id}/${historique.id}`, '_blank')

  }
  displayAndHideHistorique() {
    this.displayHistorique = !this.displayHistorique
    this.setPage(1)
  }
  showMoreAnomalies() {
    this.showMore = !this.showMore
  }
  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = [];
    });

  }
  setToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "normale") {
        allPresentFalse = false
      }

    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.normale.present = true;
    }
    return myObject
  }

  // hisotric functions
  onDateSelect(date: string) {
    this.selectedHistorique = this.oldListConsultations.find(consultation => consultation.start === date);
  }
  pageChanged(event: any): void {
    this.setPage(event);
  }

  setPage(page: number): void {
    this.p = page;
    const index = (page - 1) * this.itemsPerPage;
    this.paginatedList = [this.oldListConsultations[index]]; // Get the single item for the current page
    this.onDateSelect(this.oldListConsultations[index].start)
  }


  previousPage(): void {
    if (this.p > 1) {
      this.p--;
      this.setPage(this.p);
    }
  }

  nextPage(): void {
    const totalPages = this.oldListConsultations.length;
    if (this.p < totalPages) {
      this.p++;
      this.setPage(this.p);
    }
  }



}
