import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-anomalie-vasculaire',
  templateUrl: './anomalie-vasculaire.component.html',
  styleUrls: ['./anomalie-vasculaire.component.css', '../../laf/laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class AnomalieVasculaireComponent implements OnInit {
  displayAnomalieVasculaire = true
  //-------------------------------- anomalaie vascualire
  anomaliVascul = false;
  anomaliVasculLeftList = [
    "Normale",
    "Arteriosclerose",
    "Signe de rétinopathie hypertensive",
    "Signe de rétinopathie diabétique",
    "Signe d’Occlusion Veineuse",
    "Signe d’Occlusion ARTÉRIELLE",
    "Vasculite",
  ];
  anomaliVasculRightList = [
    "Normale",
    "Arteriosclerose",
    "Signe de rétinopathie hypertensive",
    "Signe de rétinopathie diabétique",
    "Signe d’Occlusion Veineuse",
    "Signe d’Occlusion ARTÉRIELLE",
    "Vasculite",
  ];
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  //!----- anomalies by a doctor
  //Anomalie vasculaire
  anomaliesByDoctorAnomalie: any[] = []
  oldlistAnomaliesAnomalie = this.anomaliVasculLeftList
  newAnomalieAnomalie = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreAnomalieVasculaire = false
  showMoreRetine = false
  //end of Show More Variables
  // send object
  objecttoSend = {
    anomalieVasculaireOD: {},
    anomalieVasculaireOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false

  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, "laf-anomalieVasculaire").subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-anomalieVasculaire", 'value': this.examLaf.laf.anomalieVasculaire });
  }
  configureDataExamen(idPatient) {
    // !Historique LAF
    this.examenService.getInspectionHistoriques(idPatient, "laf").subscribe((data: any) => {
      this.oldListConsultations = data
    },
      err => {
        return false;
      });
    //! Anomalie vasculaire
    this.anomalieService.getAnomalieByDoctor("laf-anomalieVasculaire").subscribe(data => {
      this.anomaliesByDoctorAnomalie = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });


  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }
  showMoreAnomalieVasculaireSection() {
    this.showMoreAnomalieVasculaire = !this.showMoreAnomalieVasculaire
  }
  displayAndHideAnomalieVasculaire() {
    this.displayAnomalieVasculaire = !this.displayAnomalieVasculaire
  }
  //---------------------------------------------------- anomalie vascualire
  showanomaliVascul() {
    this.examLaf.laf.anomalieVasculaire.present = false;
  }

  addRemoveFromanomaliVasculRight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.anomalieVasculaire.oielDroit); this.examLaf.laf.anomalieVasculaire.oielDroit.normale.present = true
        break
      case 'Artériosclérose': this.examLaf.laf.anomalieVasculaire.oielDroit.arteriosclerose.present = !this.examLaf.laf.anomalieVasculaire.oielDroit.arteriosclerose.present
        break
      case 'Signe de rétinopathie hypertensive': this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRH.present = !this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRH.present
        break
      case 'Signe de rétinopathie diabétique': this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.present = !this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.present
        break
      case 'Signe d’Occlusion Veineuse': this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.present = !this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.present
        break
      case 'Signe d’Occlusion Artérielle': this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.present = !this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.present
        break
      case 'Vasculite': this.examLaf.laf.anomalieVasculaire.oielDroit.vasculite.present = !this.examLaf.laf.anomalieVasculaire.oielDroit.vasculite.present
        break
    }
    this.setToTrue(this.examLaf.laf.anomalieVasculaire.oielDroit)
    this.objecttoSend.anomalieVasculaireOD = this.examLaf.laf.anomalieVasculaire.oielDroit
    this.objecttoSend.source = "anomalieVasculaireOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromanomaliVasculLeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.anomalieVasculaire.oielGauche); this.examLaf.laf.anomalieVasculaire.oielGauche.normale.present = true
        break
      case 'Artériosclérose': this.examLaf.laf.anomalieVasculaire.oielGauche.arteriosclerose.present = !this.examLaf.laf.anomalieVasculaire.oielGauche.arteriosclerose.present
        break
      case 'Signe de rétinopathie hypertensive': this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRH.present = !this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRH.present
        break
      case 'Signe de rétinopathie diabétique': this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.present = !this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.present
        break
      case 'Signe d’Occlusion Veineuse': this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.present = !this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.present
        break
      case 'Signe d’Occlusion Artérielle': this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.present = !this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.present
        break
      case 'Vasculite': this.examLaf.laf.anomalieVasculaire.oielGauche.vasculite.present = !this.examLaf.laf.anomalieVasculaire.oielGauche.vasculite.present
        break
    }
    this.setToTrue(this.examLaf.laf.anomalieVasculaire.oielGauche)
    this.objecttoSend.anomalieVasculaireOG = this.examLaf.laf.anomalieVasculaire.oielGauche
    this.objecttoSend.source = "anomalieVasculaireOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  // Artériosclérose
  selectarterioscleroseRight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.arteriosclerose.valeur = value
  }
  selectarterioscleroseLeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.arteriosclerose.valeur = value
  }
  // signe rh
  selectsignedeRHRight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRH.valeur = value
  }
  selectsignedeRHLeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRH.valeur = value
  }
  // signe rd
  selectsignedeRDRight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.valeur = value
    if (value != "RD proliférante") {
      this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.type = undefined
      this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.RDcompliquee = undefined
    }
  }
  selectsignedeRDLeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.valeur = value
    if (value != "RD proliférante") {
      this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.type = undefined
      this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.RDcompliquee = undefined
    }
  }
  selectsignedeRDCompliqueRight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.RDcompliquee = value
    if (value == "Non") {
      this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.valeur = "RD proliférante"
      this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.type = undefined
    } else {
      this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.valeur = "RD proliférante"
    }
  }
  selectsignedeRDCompliqueLeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.RDcompliquee = value
    if (value == "Non") {
      this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.valeur = "RD proliférante"
      this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.type = undefined
    } else {
      this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.valeur = "RD proliférante"
    }
  }
  selectsignedeRDTypeRight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.type = value
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.RDcompliquee = "Oui"
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedeRD.valeur = "RD proliférante"
  }
  selectsignedeRDTypeLeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.type = value
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.RDcompliquee = "Oui"
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedeRD.valeur = "RD proliférante"
  }
  // Signe d’Occlusion Veineuse
  selectsignedOcclusionVeineuseRight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.valeur = value
    switch (value) {
      case 'OVCR':
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.typeOBVR = undefined;
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.typeOcclusionveineusehemiretinienne = undefined;
        break
      case 'OBVR':
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.typeOcclusionveineusehemiretinienne = undefined;
        break
      case 'Occlusion veineuse hemirétinienne':
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.typeOBVR = undefined;
        break
    }
  }
  selectsignedOcclusionVeineuseLeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.valeur = value
    switch (value) {
      case 'OVCR':
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.typeOBVR = undefined;
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.typeOcclusionveineusehemiretinienne = undefined;
        break
      case 'OBVR':
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.typeOcclusionveineusehemiretinienne = undefined;
        break
      case 'Occlusion veineuse hemirétinienne':
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.typeOBVR = undefined;
        break
    }
  }
  // Signe d’Occlusion Veineuse
  selectsignedOcclusionARTERIELLERight(value) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.valeur = value
    switch (value) {
      case 'OACR isolé':
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.typeOBAR = undefined;
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.typeOcclusionarteriellehemiretinienne = undefined;
        break
      case 'Occlusion de branche artérielle':
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.typeOcclusionarteriellehemiretinienne = undefined;
        break
      case 'Occlusion artérielle hemoretinienne':
        this.examLaf.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.typeOBAR = undefined;
        break
    }
  }
  selectsignedOcclusionARTERIELLELeft(value) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.valeur = value
    switch (value) {
      case 'OACR isolé':
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.typeOBAR = undefined;
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.typeOcclusionarteriellehemiretinienne = undefined;
        break
      case 'Occlusion de branche artérielle':
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.typeOcclusionarteriellehemiretinienne = undefined;
        break
      case 'Occlusion artérielle hemoretinienne':
        this.examLaf.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.typeOBAR = undefined;
        break
    }
  }

  //!--------------------Anomalie vasculaire
  addNewAnomalyAnomalie(event) {
    const maxAnomalies = 5;
    const inputValue = event?.target?.value;

    const showToast = (message, messageColor, backgroundColor, progressBarColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        const self = this;
        this.iziToast.show({
          message,
          messageColor,
          titleColor: messageColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: function () {
            self.isToastVisible = false;
          },
        });
      }
    };

    if (this.anomaliesByDoctorAnomalie && this.anomaliesByDoctorAnomalie.length >= maxAnomalies) {
      showToast(
        "Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies",
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieAnomalie = "";
      return false;
    }

    if (inputValue && inputValue.length > 1 && inputValue.length < 100) {
      const anomalyExisted = this.anomaliesByDoctorAnomalie.some(x => x.titre?.toLowerCase() === inputValue.toLowerCase());
      const anomalyExistedOld = this.oldlistAnomaliesAnomalie.some(x => x.toLowerCase() === inputValue.toLowerCase());

      if (!anomalyExisted && !anomalyExistedOld) {
        this.anomalieService.ajoutAnomalie({
          titre: inputValue[0].toUpperCase() + inputValue.slice(1),
          description: "",
          zone: "laf-anomalieVasculaire",
        }).subscribe((data) => {
          this.newAnomalieAnomalie = "";
          this.anomaliesByDoctorAnomalie.push(data);
          showToast(
            'Bien enregistré',
            '#386641',
            '#dde5b6',
            '#6a994e',
          );
        });
        return true;
      } else {
        showToast(
          "L'anomalie existe dans la liste",
          '#800f2f',
          '#ff8fa3',
          '#c9184a'
        );
        this.newAnomalieAnomalie = "";
        return false;
      }
    } else {
      showToast(
        'Merci de saisir au moins 2 caractères',
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieAnomalie = "";
      return false;
    }
  }

  // Right Anomalie
  addRemoveFromAnomalieRightNew(value, id) {
    this.examLaf.laf.anomalieVasculaire.oielDroit.normale.present = false
    let truefalseexist = this.examLaf.laf.anomalieVasculaire.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.anomalieVasculaire.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.anomalieVasculaireOD = this.examLaf.laf.anomalieVasculaire.oielDroit
      this.objecttoSend.source = "anomalieVasculaireOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.anomalieVasculaire.oielDroit.allAnomalies = this.examLaf.laf.anomalieVasculaire.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.anomalieVasculaireOD = this.examLaf.laf.anomalieVasculaire.oielDroit
      this.objecttoSend.source = "anomalieVasculaireOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examLaf.laf.anomalieVasculaire.oielDroit)
      return true;
    }

  }
  checkExistAnomalieRight(id) {
    return this.examLaf.laf.anomalieVasculaire.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Anomalie dyna
  addRemoveFromAnomalieLeftNew(value, id) {
    this.examLaf.laf.anomalieVasculaire.oielGauche.normale.present = false
    let truefalseexist = this.examLaf.laf.anomalieVasculaire.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.anomalieVasculaire.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.anomalieVasculaireOG = this.examLaf.laf.anomalieVasculaire.oielGauche
      this.objecttoSend.source = "anomalieVasculaireOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.anomalieVasculaire.oielGauche.allAnomalies = this.examLaf.laf.anomalieVasculaire.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.anomalieVasculaireOG = this.examLaf.laf.anomalieVasculaire.oielGauche
      this.objecttoSend.source = "anomalieVasculaireOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examLaf.laf.anomalieVasculaire.oielGauche)
      return true;
    }

  }
  checkExistAnomalieLeft(id) {
    return this.examLaf.laf.anomalieVasculaire.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of Anomalie vasculaire

  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-anomalieVasculaire') {
        this.anomaliesByDoctorAnomalie.splice(this.anomaliesByDoctorAnomalie.indexOf(anomaly), 1)
      }

      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  setToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "normale") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.normale.present = true;
    }
    return myObject
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }



}
