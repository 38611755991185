<section class="container" style="position:relative;top:160px;left: 6%;">
    <div id="demo">
   
    </div>
     <div id="demo" *ngIf="consultation">
       <form class="form-horizontal" role="form" [formGroup]="form">
         <fieldset>
           <flash-messages></flash-messages>
           <legend class="primary bold"> Modifier une consultation</legend>
   
           <div class="col-12 p-0">
             <label for="titre">Titre</label> <br>
             <select id="titre" formControlName="titre" [(ngModel)]="consultation.titre" name="consultation.titre" (change)="selectTitre()">
              <option selected>{{consultation.titre}}</option> 
              <option *ngFor="let titre of titres">{{titre}}</option>
             </select>
             <control-messages [control]="form.controls.titre"></control-messages>
           </div>
           <div class="col-12 p-0" *ngIf="showautre">
             <label for="descriptif">Autre</label><br>
             <input type="text" formControlName="autre" [(ngModel)]="consultation.autre" name="consultation.autre" value={{consultation.autre}} id="date" placeholder="Autre">
             <control-messages [control]="form.controls.autre"></control-messages>
           </div>
           <div class="col-12 p-0">
             <label for="date">Date*</label><br>
             <input type="date" name="consultation.date" formControlName="date" [(ngModel)]="consultation.date" value="{{consultation.date}}" placeholder="Date">
             <control-messages [control]="form.controls.date"></control-messages>
           </div>
           <div class="col-12 p-0">
             <label>Descriptif</label>
             <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style="margin: 0px;width: 1037px;background-color: rgb(232, 246, 245);height: 163px;border-color: #e8f6f5;" name="consultation.descriptif" formControlName="descriptif" [(ngModel)]="consultation.descriptif"
               value="{{consultation.descriptif}}" placeholder="Descriptif"></textarea>
             <control-messages [control]="form.controls.descriptif"></control-messages>
           </div>
           <div class="col-12 p-0">
             <label for="medicaments">Médicaments</label><br>
             <ng-select2 [data]="medicamentsData" [value]="startValue" [allowClear]="true" [(ngModel)]="consultation.medicaments" formControlName="medicaments" name="consultation.medicaments" [options]="options" [width]="ng2slectwidh" ></ng-select2>
   
           </div>
           <br><br>
           <div class="modal-footer d-flex justify-content-center" style="border:none">
             <div class=" col d-flex justify-content-center buttonsPopup">
               <div class="col-auto nbr-dossier ">
                 <table>
                   <tr>
                     <td class="tdButt1 ">
                       <button class="btn-valider" (click)="onUpdateconsultation(consultation)" [disabled]="!form.valid"><img src="assets/images/listePatsEspaceMed/valider.png" class="iconBoutton">Enregistrer </button>
                     </td>
                     <td class="tdButt2 ">
                       <button class="btn-annule" [routerLink]="['/medecin/consultation-liste',consultation.patientId]"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                     </td>
                   </tr>
                 </table>
                 <br><br><br>
               </div>
             </div>
           </div>
         </fieldset>
       </form>
   
     </div>
   
   </section>
   