import { Component, OnInit } from '@angular/core';
import {redirectNotAuthenticated } from 'config/API_CONFIG';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  constructor() {}
  ngOnInit() {
    window.location.href = redirectNotAuthenticated
    return false;
  }
}


