import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ImageService } from '../../services/image/image.service';
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Subscription } from 'rxjs';
import { FullscreenService } from 'src/app/services/shared/fullscreen.service';

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.css']
})
export class BackofficeComponent implements OnInit,OnDestroy {

  medecin: any;
  secretaire:any;
  image: any;
  urlImage: any;
  private fullscreenSubscription: Subscription;
  isFullscreen = false;
  constructor(private router: Router,private authService: AuthService, private imageService: ImageService,private sharedDataService:SharedDataService,private fullscreenService: FullscreenService) {

  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.fullscreenChange();
    this.isFullscreen = this.fullscreenService.isFullscreen();
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe((profile:any) => {
        this.medecin = profile.medecin;
        if(this.router && this.router.url && this.router.url === "/medecin"){
          this.router.navigate(['/medecin/accueil']);
          return false;
        }
      },
        err => {
          localStorage.clear();
          this.router.navigate(['/']);
          return false;
        });
    }else{
      this.authService.getProfileSecretaire().subscribe((profile:any) => {
        this.secretaire = profile.secretaire;
        if(this.router && this.router.url && this.router.url === "/medecin"){
          this.router.navigate(['/medecin/accueil']);
          return false;
        }
      },
        err => {
          localStorage.clear();
          this.router.navigate(['/']);
          return false;
        });
    }
    

  }

setSpecialite(speicialiteSelected){
  localStorage.setItem("searchedSpecialite",speicialiteSelected)
  this.router.navigate(['/medecin/recherche']);
}
fullscreenChange() {
  this.fullscreenSubscription = this.fullscreenService.fullscreenChange.subscribe((isFullscreen: boolean) => {
    this.isFullscreen = isFullscreen;
    if(this.isFullscreen){
      window.scrollTo({
        top: 100, // Adjust this value to scroll more or less
        behavior: 'smooth'
      });
    }
  });
}

ngOnDestroy(): void {
  if (this.fullscreenSubscription) {
    this.fullscreenSubscription.unsubscribe();
  }
}
}
