<div class="container demo">
    <div class="text-center" style="display: none;">
        <button type="button" class="btn btn-siderbar-left" id="btn-siderbar-left" data-toggle="modal" data-target="#exampleModalleft">
            Lancer
        </button>
    </div>
    <div class="modal left fade" id="exampleModalleft" tabindex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="min-width: 425px;left:0 !important;right: auto;" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <!-- Historiques des consultations générale -->
                    <div *ngIf="myExamen">
                        <h6 id="left-side-titel">Anomalie à l'interrogatoire</h6>
                        <div *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux?.length>0 
                || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux?.length>0 
                  ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser
                  || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser
                  ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux?.length>0 
                  ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux?.length>0 
                  ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux?.length>0
                  || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux?.length>0
                  || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique?.length>0 
                  || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique?.length>0 
                  ||myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present==true 
                  || myExamen.interrogation.antecedentsGeneraux.terrainatopique.present ==true
                  ||myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present==true
                  || myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present ==true
                  ||myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present==true
                  ||myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present ==true
                  ||myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present ==true
                  ||myExamen.interrogation.antecedentsGeneraux.traitementencours.present ==true
                  ||myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 ">
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux.length>0 || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.length>0">
                                <li>Médicaux</li>
                                <ul>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux">{{value.name}} (OD)</li>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux">{{value.name}} (OG)</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser ||  myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser">
                                <li>Antécédents de traitement par laser</li>
                                <ul>
                                    <li>{{myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name}} (OD)</li>
                                    <li>{{myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name}} (OG)</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.length>0  || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.length>0 ">
                                <li>Chirurgicaux</li>
                                <ul>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux">{{value.name}} (OD)</li>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux">{{value.name}} (OG)</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0  || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0 ">
                                <li>Correction optique</li>
                                <ul>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique">{{value.name}} (OD)</li>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique">{{value.name}} (OG)</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux.grossesseenCours && myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present==true">
                                <li>Grossesse en cours</li>
                            </ul>
                            <ul *ngIf="myExamen.interrogation  && myExamen.interrogation.antecedentsGeneraux.terrainatopique && myExamen.interrogation.antecedentsGeneraux.terrainatopique.present==true">
                                <li>Terrain atopique</li>
                                <ul>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs">{{value.name}}</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier && myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present==true">
                                <li>Terrain vasculaire particulier</li>
                                <ul>
                                    <li *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.present">Diabète</li>
                                    <li *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.present">HTA</li>
                                    <li *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present">Autre</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present==true">
                                <li>Terrain d'immunodepression</li>
                                <ul>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs">{{value.name}}</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present==true">
                                <li>Maladie dysimmunitaire</li>
                                <ul>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs">{{value.name}}</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladieneurologique && myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present==true">
                                <li>Maladie neurologique</li>
                                <ul>
                                    <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs">{{value.name}}</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present==true">
                                <li>Notion de vaccination récente</li>
                                <ul>
                                    <li>Type : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.type}}</li>
                                    <li>Date : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.date}}</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present==true">
                                <li>Notion d'anesthésie récente</li>
                                <ul>
                                    <li>Type : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.type}}</li>
                                    <li>Date : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.date}}</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.traitementencours && myExamen.interrogation.antecedentsGeneraux.traitementencours.present==true">
                                <li>Traitement général en cours</li>
                                <ul>
                                    <li>Type : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.type}}</li>
                                    <li>Dose : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.dose}}</li>
                                </ul>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht ">
                                <li *ngFor="let antecedent of myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">{{antecedent.name}}</li>
                            </ul>
                            <ul *ngIf="selectedMedecinn">
                                <li> Adressé par Dr {{selectedMedecinn.nom}} {{selectedMedecinn.prenom}} {{selectedMedecinn.specialite}}</li>
                            </ul>
                            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie ">
                                <li *ngFor="let antecedent of myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie">{{antecedent.name}}</li>
                            </ul>
                        </div>

                        <!-- inspection -->
                        <h6 id="left-side-titel">Inspection</h6>
                        <ul *ngIf="receivedData">
                            <div *ngIf="receivedData.inspectionOD">
                                <li *ngIf="receivedData.inspectionOD?.normale?.present">Normale (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.attitudedemalvoyance?.present">Attitude de malvoyance (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.buphtalmie?.present">Buphtalmie (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.exphtalmie?.present">Exphtalmie (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.enophtalmie?.present">Énophtalmie (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.microphtalmie?.present">Microphtalmie (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.leucocorie?.present">Leucocorie (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.protheseoculaire?.present">Prothèse oculaire (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.plaies?.present">Plaies (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.paralysiefaciale?.present">Paralysie faciale (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.ptosis?.present">Ptosis (OD)</li>
                                <li *ngIf="receivedData.inspectionOD?.strabisme?.present">Strabisme (OD)</li>
                                <li *ngFor="let anomaly of receivedData.inspectionOD?.allAnomalies">{{anomaly.titre}} (OD)</li>
                            </div>
                            <div *ngIf="receivedData.inspectionOG">
                                <li *ngIf="receivedData.inspectionOG?.normale?.present">Normale (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.attitudedemalvoyance?.present">Attitude de malvoyance (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.buphtalmie?.present">Buphtalmie (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.exphtalmie?.present">Exphtalmie (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.enophtalmie?.present">Énophtalmie (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.microphtalmie?.present">Microphtalmie (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.leucocorie?.present">Leucocorie (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.protheseoculaire?.present">Prothèse oculaire (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.plaies?.present">Plaies (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.paralysiefaciale?.present">Paralysie faciale (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.ptosis?.present">Ptosis (OG)</li>
                                <li *ngIf="receivedData.inspectionOG?.strabisme?.present">Strabisme (OG)</li>
                                <li *ngFor="let anomaly of receivedData.inspectionOG?.allAnomalies">{{anomaly.titre}} (OG)</li>
                            </div>
                        </ul>
                        <!-- Annexe -->
                        <h6 id="left-side-titel">Annexe</h6>
                        <ul *ngIf="receivedData">
                            <div *ngIf="receivedData.annexeOD">
                                <li *ngIf="receivedData.annexeOD?.normale?.present">Normale (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.chalazion?.present">Chalazion (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.dca?.present">DCA (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.dcc?.present">DCC (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.dermabrasion?.present">Dermabrasion (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.diplopie?.present">Diplopie (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.distichoasis?.present">Distichoasis (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.dgm?.present">DGM (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.ecoulementlacrymal?.present">Écoulement lacrymal (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.entropion?.present">Entropion (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.ectropion?.present">Ectropion (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.fistule?.present">Fistule (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.insuffisancedeconvergence?.present">Insuffisance de convergence (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.massedelalogelacrymal?.present">Masse de la loge lacrymal (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.meeibomite?.present">Meeibomite (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.ophtalmologie?.present">Ophtalmologie (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.orgelet?.present">Orgelet (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.paralysieocculomotrice?.present">Paralysie occulomotrice (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.plaie?.present">Plaie (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.presencedestrabisme?.present">Présence de strabisme (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.ptosis?.present">Ptosis (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.refletcorneendecentre?.present">Réflet cornéen décentré (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.stenoselacrymal?.present">Sténose lacrymal (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.sondedeDCRenplace?.present">Sonde de DCR en place (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.telangiectasie?.present">Telangiectasie (OD)</li>
                                <li *ngIf="receivedData.annexeOD?.tumeurpaplepbrale?.present">Tumeur paplepbrale (OD)</li>
                                <li *ngFor="let anomaly of receivedData.annexeOD?.allAnomalies">{{anomaly.titre}} (OD)</li>
                            </div>
                            <div *ngIf="receivedData.annexeOG">
                                <li *ngIf="receivedData.annexeOG?.normale?.present">Normale (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.chalazion?.present">Chalazion (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.dca?.present">DCA (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.dcc?.present">DCC (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.dermabrasion?.present">Dermabrasion (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.diplopie?.present">Diplopie (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.distichoasis?.present">Distichoasis (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.dgm?.present">DGM (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.ecoulementlacrymal?.present">Écoulement lacrymal (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.entropion?.present">Entropion (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.ectropion?.present">Ectropion (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.fistule?.present">Fistule (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.insuffisancedeconvergence?.present">Insuffisance de convergence (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.massedelalogelacrymal?.present">Masse de la loge lacrymal (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.meeibomite?.present">Meeibomite (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.ophtalmologie?.present">Ophtalmologie (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.orgelet?.present">Orgelet (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.paralysieocculomotrice?.present">Paralysie occulomotrice (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.plaie?.present">Plaie (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.presencedestrabisme?.present">Présence de strabisme (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.ptosis?.present">Ptosis (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.refletcorneendecentre?.present">Réflet cornéen décentré (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.stenoselacrymal?.present">Sténose lacrymal (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.sondedeDCRenplace?.present">Sonde de DCR en place (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.telangiectasie?.present">Telangiectasie (OG)</li>
                                <li *ngIf="receivedData.annexeOG?.tumeurpaplepbrale?.present">Tumeur paplepbrale (OG)</li>
                                <li *ngFor="let anomaly of receivedData.annexeOG?.allAnomalies">{{anomaly.titre}} (OG)</li>
                            </div>

                        </ul>
                        <!-- LAF  -->
                        <h6 id="left-side-titel">Tonus </h6>
                        <ul *ngIf="receivedData">
                            <li *ngIf="receivedData && receivedData.TOOD && receivedData.TOOD > 21">Hypertonie oculaire à l'oeil droit : {{receivedData.TOOD}} mm</li>
                            <li *ngIf="receivedData && receivedData.TOOG && receivedData.TOOG > 21">Hypertonie oculaire à l'oeil gauche : {{receivedData.TOOG}} mm</li>
                        </ul>
                        <h6 id="left-side-titel">BUT </h6>
                        <ul *ngIf="receivedData">
                            <li *ngIf="receivedData && receivedData.butOD && receivedData.butOD > 10">Instabilité lacrymale à l'oeil droit : {{receivedData.butOD}} S</li>
                            <li *ngIf="receivedData && receivedData.butOG && receivedData.butOG > 10">Instabilité lacrymale à l'oeil gauche : {{receivedData.butOG}} S</li>
                        </ul>
                        <h6 id="left-side-titel">SHIRMER </h6>
                        <ul *ngIf="receivedData">
                            <li *ngIf="receivedData && receivedData.shirmerOD && receivedData.shirmerOD > 5">Sècheresse oculaire à l'oeil droit : {{receivedData.shirmerOD}} mm</li>
                            <li *ngIf="receivedData && receivedData.shirmerOG && receivedData.shirmerOG > 5">Sècheresse oculaire à l'oeil gauche : {{receivedData.shirmerOG}} mm</li>
                        </ul>
                        <h6 id="left-side-titel">Réfraction </h6>
                        <ul *ngIf="receivedData">
                            <!-- sans cyclo-->
                            <li *ngIf="receivedData && receivedData.sansCycloSphereDroit && receivedData.sansCycloSphereDroit < 0 && !receivedData.avecCycloSphereDroit">
                                Myopie à l'oeil droit : {{receivedData.sansCycloSphereDroit}}
                            </li>
                            <li *ngIf="receivedData && receivedData.sansCycloSphereGauche && receivedData.sansCycloSphereGauche < 0 && !receivedData.avecCycloSphereGauche">
                                Myopie à l'oeil gauche : {{receivedData.sansCycloSphereGauche}}
                            </li>
                            <li *ngIf="receivedData && receivedData.sansCycloSphereDroit && receivedData.sansCycloSphereDroit > 0 && !receivedData.avecCycloSphereDroit">
                                Hypermétropie à l'oeil droit : {{receivedData.sansCycloSphereDroit}}
                            </li>
                            <li *ngIf="receivedData && receivedData.sansCycloSphereGauche && receivedData.sansCycloSphereGauche > 0 && !receivedData.avecCycloSphereGauche">
                                Hypermétropie à l'oeil gauche : {{receivedData.sansCycloSphereGauche}}
                            </li>
                            <li *ngIf="receivedData && receivedData.sansCycloCylindreDroit &&  !receivedData.avecCycloCylindreDroit">
                                Astigmatisme à l'oeil droit : {{receivedData.sansCycloCylindreDroit}}
                            </li>
                            <li *ngIf="receivedData && receivedData.sansCycloCylindreGauche &&  !receivedData.avecCycloCylindreGauche">
                                Astigmatisme à l'oeil gauche : {{receivedData.sansCycloCylindreGauche}}
                            </li>
                            <!-- sans cyclo-->
                            <!-- avec cyclo-->
                            <li *ngIf="receivedData && receivedData.avecCycloSphereDroit && receivedData.avecCycloSphereDroit < 0">
                                Myopie à l'oeil droit : {{receivedData.avecCycloSphereDroit}}
                            </li>
                            <li *ngIf="receivedData && receivedData.avecCycloSphereGauche && receivedData.avecCycloSphereGauche < 0">
                                Myopie à l'oeil gauche : {{receivedData.avecCycloSphereGauche}}
                            </li>
                            <li *ngIf="receivedData && receivedData.avecCycloSphereDroit && receivedData.avecCycloSphereDroit > 0">
                                Hypermétropie à l'oeil droit : {{receivedData.avecCycloSphereDroit}}
                            </li>
                            <li *ngIf="receivedData && receivedData.avecCycloSphereGauche && receivedData.avecCycloSphereGauche > 0">
                                Hypermétropie à l'oeil gauche : {{receivedData.avecCycloSphereGauche}}
                            </li>
                            <li *ngIf="receivedData && receivedData.avecCycloCylindreDroit">
                                Astigmatisme à l'oeil droit : {{receivedData.avecCycloCylindreDroit}}
                            </li>
                            <li *ngIf="receivedData && receivedData.avecCycloCylindreGauche">
                                Astigmatisme à l'oeil gauche : {{receivedData.avecCycloCylindreGauche}}
                            </li>
                            <li *ngIf="receivedData && receivedData.addOD">
                                Presbytie à l'oeil droit : {{receivedData.addOD}}
                            </li>
                            <li *ngIf="receivedData && receivedData.addOG">
                                Presbytie à l'oeil gauche : {{receivedData.addOG}}
                            </li>
                            <!-- avec cyclo-->
                        </ul>
                        <h6 id="left-side-titel">Conduite à tenir</h6>
                        <ul *ngIf="receivedData && receivedData.cat && receivedData.cat.length >0">
                            <li *ngFor="let cat of receivedData.cat">{{cat}}</li>
                        </ul>
                        <h6 id="left-side-titel">Diagnostic spécifique</h6>
                        <ul *ngIf="receivedData && receivedData.haveSpecificDiagnostic">
                            <li>{{receivedData.additionalConclusion}}</li>
                        </ul>
                        <h6 id="left-side-titel">Prochaine rendez vous</h6>
                        <ul *ngIf="receivedData">
                            <li>Motif <ul>
                                <li *ngFor="let mot of receivedData.nextMotif">{{mot}}</li>
                            </ul></li>
                            <li>Date {{receivedData.nextAppointmentDuration}} <p *ngIf="receivedData.nextAppointmentDuration" style="display:contents;">==></p> {{receivedData.nextAppointment | date:"dd/MM/yyyy, à HH:mm"}}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style="background-color: #00958a; border: 1px solid #00958a; color: white;" id="btn-siderbar-close" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</div>