
<section id="cours" style="width: 85%;margin: 0 auto;margin-top: 8%;">
  <!--<ng-template #customLoadingTemplate></ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>-->
  <div id="demo" *ngIf="patient && mydocumentPatient">
    <form [formGroup]="form" class="form-horizontal" role="form">
      <fieldset>
        <!-- Form Name -->
        <div class="form-group col-md-12 col-sm-6">
          <legend style="color: #ef783e;">Modifier des documents</legend>
        </div>
        <!-- Text input-->
        <div class="row">
          <div class="col-md-6">
            <div class=" textContenu2">
              <h4 class="h4MEd2">Type* :</h4>
              <select class=" select-pop3 " formControlName="type" name="type" [(ngModel)]="mydocumentPatient.type">
                <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                <option >Analyse</option>
                <option >Echographie</option>
                <option >IRM</option>
                <option >Scanner</option>
                <option>Vaccin</option>
              </select>
            </div>
            <control-messages style="color:red;position: relative;top: 35px;" [control]="form.controls.type"></control-messages>
            <br>
          </div>
          <div class="col-md-6">
            <div class="title textContenu2">
              <h4>Date* :</h4>
              <input class="font-weight-bold" style="color: #1b1e21;margin-top: 3px;" type="datetime-local" name="date" formControlName="date" [(ngModel)]="mydocumentPatient.date" value="{{mydocumentPatient.date}}">
            </div>
            <control-messages style="color:red;position: relative;top: 35px;" [control]="form.controls.date"></control-messages>
            <br>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class=" textContenu2">
              <h4 class="h4MEd2">Statut* :</h4>
              <select class=" select-pop3 " formControlName="statut" name="statut" [(ngModel)]="mydocumentPatient.statut" >
                <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                <option >En cours</option>
                <option >Prêt</option>
              </select>
            </div>
            <control-messages style="color:red;position: relative;top: 35px;" [control]="form.controls.statut"></control-messages>
            <br>
          </div>
          <div class="col-md-6">
            <div class="title textContenu2" style="margin-top: 8px;">
              <h4>Résultats :</h4>
              <input type="file" formControlName="resultat"  [(ngModel)]="mesesultats" name="resultat" class="input-btn-file" id="fileDropCssnew" (change)="fileChange($event)" multiple accept="image/*,.pdf" placeholder="Drag & Drop">
              <p style="color:red" *ngIf="errofile">Merci de choisir un fichier</p>
            </div>
            <control-messages style="color:red;position: relative;top: 35px;" [control]="form.controls.resultat"></control-messages>
            <br>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="title textContenu2">
              <h4 class="h4MEd2">Demandé par :</h4>
              <input type="text" class="font-weight-bold" style="background:none" formControlName="demandePar" name="demandePar"  [(ngModel)]="showedValue" (valueChanged)="myCallback($event)" autocomplete="off" ngui-auto-complete [source]="medecins" [list-formatter]="autocompleListFormatterPrenom" min-chars="4" max-num-list="10" no-match-found-text="Aucun élément" select-value-of="selectedMedecin" display-property-name="selectedMedecin">
            </div>
            <control-messages style="color:red;position: relative;top: 35px;" [control]="form.controls.demandePar"></control-messages>
            <br>
          </div>
          <div class="col-md-6">
            <div class=" textContenu2">
              <h4 class="h4MEd2">Service :</h4>
              <select class=" select-pop3 " formControlName="service" name="service" [(ngModel)]="mydocumentPatient.service">
                <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                <option >Service 1</option>
                <option >Service 2</option>
                <option >Service 3</option>
                <option >Service 4</option>
              </select>
            </div>
            <control-messages style="color:red;position: relative;top: 35px;" [control]="form.controls.service"></control-messages>
            <br>
          </div>
        </div>
        <div class="row" *ngIf="mydocumentPatient.auteur.idMedecin">
          <div class="col-md-6">
            <div class=" textContenu2">
              <div class="custom-control custom-switch" style="position: absolute;">
                <input type="checkbox" [(ngModel)]="mydocumentPatient.autoriserAcces" formControlName="autoriserAcces" name="autoriserAcces" class="custom-control-input" id="customSwitch1">
                <label class="custom-control-label" for="customSwitch1"><h4 class="h4MEd2" style="position: relative;top: -19px;">Autoriser l’accès au patient*</h4></label>
              </div>
              <br><br>
            </div>
            <control-messages style="color:red;position: relative;top: 35px;" [control]="form.controls.autoriserAcces"></control-messages>
            <br>
          </div>
        </div>
        <div class="row">
          <p style="color:red">{{errorObligatoire}}</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider-add-docuemnets" [disabled]="editmode" (click)="updateDocuments(mydocumentPatient)"><img src="assets/images/listePatsEspaceMed/valider.png" class="iconBoutton">Modifier </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule-add-documents" [routerLink]="['/medecin/consultation-liste',patient._id]"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
    <br><br><br><br><br><br><br><br>
  </div>
</section>
