<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Cornee" (click)="displayAndHideCornee()">Cornée</h4>
            <div class="arrow arrow-up btnCornee" *ngIf="displayCornee" title="Cacher Cornee" (click)="displayAndHideCornee()">▲</div>
            <div class="arrow arrow-up btnCornee" *ngIf="!displayCornee" title="Afficher Cornee" (click)="displayAndHideCornee()">▼</div>
        </div>

        <div *ngIf="displayCornee" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.corneetransparente.oielDroit.transparente.present==true}" (click)="addRemoveFromCorneeRight('Transparente')">Transparente</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.abces.present==true}" (click)="addRemoveFromCorneeRight('Abcès')">Abcès</li>
                                <div *ngIf="showMoreCornee">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.corneetransparente.oielDroit.anneuximmunitaire.present==true}" (click)="addRemoveFromCorneeRight('Anneux immunitaire')">Anneux immunitaire</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.corneetransparente.oielDroit.bueeEpitheliale.present==true}" (click)="addRemoveFromCorneeRight('Buée épithéliale')">Buée épithéliale</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.corneetransparente.oielDroit.bullesousepitheliale.present==true}" (click)="addRemoveFromCorneeRight('Bulle sous épithéliale')">Bulle sous épithéliale</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.buphtalmie.present==true}" (click)="addRemoveFromCorneeRight('Buphtalmie')">Buphtalmie</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.corneaguttata.present==true}" (click)="addRemoveFromCorneeRight('Cornea guttata')">Cornea guttata </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.endothelite.present==true}" (click)="addRemoveFromCorneeRight('Endothélite')">Endothélite</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.present==true}" (click)="addRemoveFromCorneeRight('Épaississement épithéliale/stromale')">Épaississement
                                        épithéliale/stromale</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.gerontoxon.present==true}" (click)="addRemoveFromCorneeRight('Gérontoxon')">Gérontoxon</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.hematocornee.present==true}" (click)="addRemoveFromCorneeRight('Hématocornée')">Hématocornée</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.hydrops.present==true}" (click)="addRemoveFromCorneeRight('Hydrops')">Hydrops</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.hypoesthesie.present==true}" (click)="addRemoveFromCorneeRight('Hypoesthésie')">Hypoesthésie</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.present==true}" (click)="addRemoveFromCorneeRight('Hypoesthésie cornéenne')">Hypoesthésie cornéenne</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.present==true}" (click)="addRemoveFromCorneeRight('Irrégularité/plis de la descemet')">Irrégularité/plis de la
                                        descemet
                                    </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.KB.present==true}" (click)="addRemoveFromCorneeRight('KB')">KB</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.Keratopathieenbandelette.present==true}" (click)="addRemoveFromCorneeRight('Kératopathie en bandelette')">Kératopathie en bandelette
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.KPS.present==true}" (click)="addRemoveFromCorneeRight('KPS')">KPS</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.leucome.present==true}" (click)="addRemoveFromCorneeRight('Leucome')">Leucome</li>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.microkysteEpitheliaux.present==true}" (click)="addRemoveFromCorneeRight('Microkyste épithéliaux')">Microkyste épithéliaux</li>
                                <div *ngIf="showMoreCornee">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.microkystique.present==true}" (click)="addRemoveFromCorneeRight('Microkystique')">Microkystique</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.neovascularisationcorneenne.present==true}" (click)="addRemoveFromCorneeRight('Néovascularisation cornéenne')">Neovascularisation
                                        corneenne
                                    </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.NSE.present==true}" (click)="addRemoveFromCorneeRight('NSE')">NSE</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.oedemecorneen.present==true  }" (click)="addRemoveFromCorneeRight('Oedème cornéen')">Oedème cornéen</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.opaque.present==true}" (click)="addRemoveFromCorneeRight('Opaque')">Opaque</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.opacite.present==true}" (click)="addRemoveFromCorneeRight('Opacité')">Opacité</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielDroit.perforationcorneenne.present==true}" (click)="addRemoveFromCorneeRight('Perforation cornéenne')">Perforation cornéenne</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.plaiescorneenne.present==true}" (click)="addRemoveFromCorneeRight('Plaie cornéenne')">Plaie cornéenne</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.plaquevernale.present==true}" (click)="addRemoveFromCorneeRight('Plaque vernale')">Plaque vernale</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.pointcorneen.present==true}" (click)="addRemoveFromCorneeRight('Point cornéen')">Point cornéen</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.PRD.present==true}" (click)="addRemoveFromCorneeRight('PRD')">PRD</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.striesdevogt.present==true}" (click)="addRemoveFromCorneeRight('Stries de vogt')">Stries de vogt</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.present==true}" (click)="addRemoveFromCorneeRight('Ulcération cornéenne')">Ulcération cornéenne </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.present==true}" (click)="addRemoveFromCorneeRight('Visibilité anormale des nerfs cornéen')">Visibilité
                                        anormale des nerfs cornéen</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.ulcerevernal.present==true}" (click)="addRemoveFromCorneeRight('Ulcère vernal')">Ulcère vernal</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielDroit.noduledetrantas.present==true}" (click)="addRemoveFromCorneeRight('Nodule de trantas')">Nodule de trantas</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorCornee" [ngClass]="{'selectedLi': checkExistAnomalieCorneeRight(anomaly.id)}">
                                        <span (click)="addRemoveFromCorneeRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieCorneeRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-cornee')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieCornee" (keyup.enter)="addNewAnomalyCornee($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>

                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreCorneeSection() *ngIf="!showMoreCornee">Voir plus</span>
                            <span (click)=showMoreCorneeSection() *ngIf="showMoreCornee">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil gauche</h5>
                    </div>

                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.transparente.present==true}" (click)="addRemoveFromCorneeLeft('Transparente')">Transparente</li>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.abces.present==true}" (click)="addRemoveFromCorneeLeft('Abcès')">Abcès</li>
                                <div *ngIf="showMoreCornee">
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.anneuximmunitaire.present==true}" (click)="addRemoveFromCorneeLeft('Anneux immunitaire')">Anneux immunitaire</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.bueeEpitheliale.present==true}" (click)="addRemoveFromCorneeLeft('Buée épithéliale')">Buée épithéliale</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.bullesousepitheliale.present==true}" (click)="addRemoveFromCorneeLeft('Bulle sous épithéliale')">Bulle sous épithéliale</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.buphtalmie.present==true}" (click)="addRemoveFromCorneeLeft('Buphtalmie')">Buphtalmie</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.corneaguttata.present==true}" (click)="addRemoveFromCorneeLeft('Cornea guttata')">Cornea guttata </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.endothelite.present==true}" (click)="addRemoveFromCorneeLeft('Endothélite')">Endothélite</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.present==true}" (click)="addRemoveFromCorneeLeft('Épaississement épithéliale/stromale')">Épaississement
                                        épithéliale/stromale</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.gerontoxon.present==true}" (click)="addRemoveFromCorneeLeft('Gérontoxon')">Gérontoxon</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.hematocornee.present==true}" (click)="addRemoveFromCorneeLeft('Hématocornée')">Hématocornée</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.hydrops.present==true}" (click)="addRemoveFromCorneeLeft('Hydrops')">Hydrops</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.hypoesthesie.present==true}" (click)="addRemoveFromCorneeLeft('Hypoesthésie')">Hypoesthésie</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.present==true}" (click)="addRemoveFromCorneeLeft('Hypoesthésie cornéenne')">Hypoesthésie cornéenne</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.present==true}" (click)="addRemoveFromCorneeLeft('Irrégularité/plis de la descemet')">Irrégularité/plis de la
                                        descemet
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.KB.present==true}" (click)="addRemoveFromCorneeLeft('KB')">KB</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.Keratopathieenbandelette.present==true}" (click)="addRemoveFromCorneeLeft('Kératopathie en bandelette')">Kératopathie en bandelette
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.KPS.present==true}" (click)="addRemoveFromCorneeLeft('KPS')">KPS</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.leucome.present==true}" (click)="addRemoveFromCorneeLeft('Leucome')">Leucome</li>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.microkysteEpitheliaux.present==true}" (click)="addRemoveFromCorneeLeft('Microkyste épithéliaux')">Microkyste épithéliaux</li>
                                <div *ngIf="showMoreCornee">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.microkystique.present==true}" (click)="addRemoveFromCorneeLeft('Microkystique')">Microkystique</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.neovascularisationcorneenne.present==true}" (click)="addRemoveFromCorneeLeft('Néovascularisation cornéenne')">Neovascularisation
                                        corneenne
                                    </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.NSE.present==true}" (click)="addRemoveFromCorneeLeft('NSE')">NSE</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.oedemecorneen.present==true  }" (click)="addRemoveFromCorneeLeft('Oedème cornéen')">Oedème cornéen</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.opaque.present==true}" (click)="addRemoveFromCorneeLeft('Opaque')">Opaque</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.opacite.present==true}" (click)="addRemoveFromCorneeLeft('Opacité')">Opacité</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.perforationcorneenne.present==true}" (click)="addRemoveFromCorneeLeft('Perforation cornéenne')">Perforation cornéenne</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.plaiescorneenne.present==true}" (click)="addRemoveFromCorneeLeft('Plaie cornéenne')">Plaie cornéenne</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.plaquevernale.present==true}" (click)="addRemoveFromCorneeLeft('Plaque vernale')">Plaque vernale</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.pointcorneen.present==true}" (click)="addRemoveFromCorneeLeft('Point cornéen')">Point cornéen</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.PRD.present==true}" (click)="addRemoveFromCorneeLeft('PRD')">PRD</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.corneetransparente.oielGauche.striesdevogt.present==true}" (click)="addRemoveFromCorneeLeft('Stries de vogt')">Stries de vogt</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.present==true}" (click)="addRemoveFromCorneeLeft('Ulcération cornéenne')">Ulcération cornéenne </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.ulcerevernal.present==true}" (click)="addRemoveFromCorneeLeft('Ulcère vernal')">Ulcère vernal</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.present==true}" (click)="addRemoveFromCorneeLeft('Visibilité anormale des nerfs cornéen')">Visibilité
                                        anormale des nerfs cornéen</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.corneetransparente.oielGauche.noduledetrantas.present==true}" (click)="addRemoveFromCorneeLeft('Nodule de trantas')">Nodule de trantas</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorCornee" [ngClass]="{'selectedLi': checkExistAnomalieCorneeLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromCorneeLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieCorneeLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-cornee')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieCornee" (keyup.enter)="addNewAnomalyCornee($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreCorneeSection() *ngIf="!showMoreCornee">Voir plus</span>
                            <span (click)=showMoreCorneeSection() *ngIf="showMoreCornee">Voir moins</span>
                        </div>
                    </div>
                </div>

            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <!-- !-------------------------------- -->
                    <div class="row" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.transparente.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Transparente</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.transparente.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.opacite.present==true">
                        <h5 class="titleDetails">Opacité</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.opacite.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.opacite.taille" (change)="onChangecorneOpaciteTailleRight()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Profondeur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.opacite.profondeur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.opacite.nombre" (change)="onChangecorneOpaciteNombreRight()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Test fluo</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.opacite.testfluo=='Positif'" (change)="onChangecorneOpaciteTestFluoRight('Positif')">Positif
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.opacite.testfluo=='Négatif'" (change)="onChangecorneOpaciteTestFluoRight('Négatif')">Négatif
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.opacite.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.present==true">
                        <h5 class="titleDetails">Ulcération cornéenne</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.taille" (change)="onChangecorneUlcerationTailleRight()"><label id="pos-mm">mm</label>
                        </div>

                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Profondeur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.profondeur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.nombre" (change)="onChangecorneUlcerationNombreRight()">
                        </div>

                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Test fluo</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.testfluo=='Positif'" (change)="onChangecorneUlcerationTestFluoRight('Positif')">Positif
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.testfluo=='Négatif'" (change)="onChangecorneUlcerationTestFluoRight('Négatif')">Négatif
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.ulcerationcorneenne.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielDroit.abces.present==true">
                        <h5 class="titleDetails">Abcès</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.abces.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.abces.taille" (change)="onChangecorneAbcesTailleRight()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Profondeur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.abces.profondeur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.abces.nombre" (change)="onChangecorneAbcesNombreRight()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Test fluo</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.abces.testfluo=='Positif'" (change)="onChangecorneAbcesTestFluoRight('Positif')">Positif
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.abces.testfluo=='Négatif'" (change)="onChangecorneAbcesTestFluoRight('Négatif')">Négatif
                            </div>

                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.abces.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielDroit.plaiescorneenne.present==true">
                        <h5 class="titleDetails">Plaie cornéenne</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.plaiescorneenne.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.plaiescorneenne.taille" (change)="onChangecornePlaieCorneTailleRight()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-12">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.plaiescorneenne.description">
                        </div>

                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.pointcorneen.present==true">
                        <h5 class="titleDetails">Point cornéen</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.pointcorneen.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.pointcorneen.taille" (change)="onChangecornePointCorneTailleRight()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.pointcorneen.nombre" (change)="onChangecornePointCorneNombreRight()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Aspect</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.pointcorneen.aspect">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Infiltration stromale</label>
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.pointcorneen.infiltrationstromale">Oui
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examLaf?.laf.corneetransparente.oielDroit.pointcorneen.infiltrationstromale" (change)="onChangecornePointCorneInfiltrationstromaleRight()">Non
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Seidel</label>
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.pointcorneen.seidel">Oui
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examLaf?.laf.corneetransparente.oielDroit.pointcorneen.seidel" (change)="onChangecornePointCorneSeidelRight()">Non
                        </div>
                        <div class="col-12 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.pointcorneen.description">
                        </div>

                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielDroit.PRD.present==true">
                        <h5 class="titleDetails">PRD</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.PRD.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.PRD.taille" (change)="onChangecorneRpdTailleRight()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.PRD.nombre" (change)="onChangecorneRpdNombreRight()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Type</label>
                            <select name="" id="" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.PRD.type">
                                <option value="En graisse de mouton">En graisse de mouton</option>
                                <option value="Stellaire">stellaire</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Couleur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.PRD.couleur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Pigmenté</label>
                            <br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.PRD.pigmente=='Oui'" (change)="selectPRDpigmenteRight('Oui')" />
                            <label for="roundedCheckbox5">Oui</label>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielDroit.PRD.pigmente=='Non'" (change)="selectPRDpigmenteRight('Non')" />
                            <label for="roundedCheckbox6">Non</label>
                        </div>
                        <div class="col-12 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.PRD.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.oedemecorneen.present==true">
                        <h5 class="titleDetails">Oedème cornéen</h5>
                        <div class="col-12">
                            <input type="checkbox" class="radiotypeinput" (change)="chooseOedemecorneenDroit('Grade 1 : Oedème cornéen minime, détail irien visible')" [checked]="examLaf?.laf.corneetransparente.oielDroit.oedemecorneen.grade==='Grade 1 : Oedème cornéen minime, détail irien visible'">Grade 1 : Oedème cornéen minime, détail irien visible
                            <br>
                            <input type="checkbox" class="radiotypeinput" (change)="chooseOedemecorneenDroit('Grade 2 : Oedème cornéen modéré, détail irien non')" [checked]="examLaf?.laf.corneetransparente.oielDroit.oedemecorneen.grade=== 'Grade 2 : Oedème cornéen modéré, détail irien non'">Grade 2 : Oedème cornéen modéré, détail irien non
                            visible <br>
                            <input type="checkbox" class="radiotypeinput" (change)="chooseOedemecorneenDroit('Grade 3 : Oedème cornéen sévère, structure de chambre')" [checked]="examLaf?.laf.corneetransparente.oielDroit.oedemecorneen.grade==='Grade 3 : Oedème cornéen sévère, structure de chambre'">Grade 3 : Oedème cornéen sévère, structure de chambre
                            antérieur non visible <br>
                        </div>
                        <div class="col-12">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.oedemecorneen.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielDroit.KPS.present==true">
                        <h5 class="titleDetails">KPS</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.KPS.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.KPS.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.perforationcorneenne.present==true">
                        <h5 class="titleDetails">Perforation cornéenne</h5>

                        <div class="col-lg-6 col-md-6">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.perforationcorneenne.nombre" (change)="onChangecornePerforationCorneNombreRight()">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.perforationcorneenne.localisation">
                        </div>
                        <div class="col-12">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.perforationcorneenne.description">
                        </div>
                    </div>
                    <!-- !------------------------------- -->
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.opaque.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Opaque</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.opaque.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.corneaguttata.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Cornea guttata</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.corneaguttata.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.hydrops.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hydrops</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.hydrops.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.hematocornee.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hématocornée</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.hematocornee.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.microkystique.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Microkystique</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.microkystique.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielDroit.NSE.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">NSE</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.NSE.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielDroit.endothelite.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Endothélite</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.endothelite.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielDroit.bueeEpitheliale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Buée épithéliale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.bueeEpitheliale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.bullesousepitheliale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Bulle sous épithéliale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.bullesousepitheliale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.microkysteEpitheliaux.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Microkyste épithéliaux</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.microkysteEpitheliaux.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.Keratopathieenbandelette.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Kératopathie en bandelette</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.Keratopathieenbandelette.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.KB.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">KB</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.KB.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielDroit.striesdevogt.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Stries de vogt</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.striesdevogt.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Visibilité anormale des nerfs cornéen</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Irrégularité/plis de la descemet</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hypoesthésie cornéenne</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Épaississement épithéliale/stromale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.hypoesthesie.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hypoesthésie</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.hypoesthesie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.ulcerevernal.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Ulcère vernal</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.ulcerevernal.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.plaquevernale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Plaque vernale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.plaquevernale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.noduledetrantas.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Nodule de trantas</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.noduledetrantas.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.neovascularisationcorneenne.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Néovascularisation cornéenne</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.neovascularisationcorneenne.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.leucome.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Leucome</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.leucome.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.gerontoxon.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Gérontoxon</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.gerontoxon.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.buphtalmie.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Buphtalmie</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.buphtalmie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielDroit.anneuximmunitaire.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Anneux immunitaire</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielDroit.anneuximmunitaire.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.corneetransparente.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->

                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div class="row" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.transparente.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Transparente</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.transparente.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.opacite.present==true">
                        <h5 class="titleDetails">Opacité</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.opacite.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.opacite.taille" (change)="onChangecorneOpaciteTailleLeft()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Profondeur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.opacite.profondeur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.opacite.nombre" (change)="onChangecorneOpaciteNombreLeft()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Test fluo</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.opacite.testfluo=='Positif'" (change)="onChangecorneOpaciteTestFluoLeft('Positif')">Positif
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.opacite.testfluo=='Négatif'" (change)="onChangecorneOpaciteTestFluoLeft('Négatif')">Négatif
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.opacite.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.present==true">
                        <h5 class="titleDetails">Ulcération cornéenne</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.taille" (change)="onChangecorneUlcerationTailleLeft()"><label id="pos-mm">mm</label>
                        </div>

                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Profondeur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.profondeur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.nombre" (change)="onChangecorneUlcerationNombreLeft()">
                        </div>

                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Test fluo</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.testfluo=='Positif'" (change)="onChangecorneUlcerationTestFluoLeft('Positif')">Positif
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.testfluo=='Négatif'" (change)="onChangecorneUlcerationTestFluoLeft('Négatif')">Négatif
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.ulcerationcorneenne.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielGauche.abces.present==true">
                        <h5 class="titleDetails">Abcès</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.abces.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.abces.taille" (change)="onChangecorneAbcesTailleLeft()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Profondeur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.abces.profondeur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.abces.nombre" (change)="onChangecorneAbcesNombreLeft()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Test fluo</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.abces.testfluo=='Positif'" (change)="onChangecorneAbcesTestFluoLeft('Positif')">Positif
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.abces.testfluo=='Négatif'" (change)="onChangecorneAbcesTestFluoLeft('Négatif')">Négatif
                            </div>

                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.abces.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielGauche.plaiescorneenne.present==true">
                        <h5 class="titleDetails">Plaie cornéenne</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.plaiescorneenne.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.plaiescorneenne.taille" (change)="onChangecornePlaieCorneTailleLeft()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-12">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.plaiescorneenne.description">
                        </div>

                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.pointcorneen.present==true">
                        <h5 class="titleDetails">Point cornéen</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.pointcorneen.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.pointcorneen.taille" (change)="onChangecornePointCorneTailleLeft()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.pointcorneen.nombre" (change)="onChangecornePointCorneNombreLeft()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Aspect</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.pointcorneen.aspect">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Infiltration stromale</label>
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.pointcorneen.infiltrationstromale">Oui
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examLaf?.laf.corneetransparente.oielGauche.pointcorneen.infiltrationstromale" (change)="onChangecornePointCorneInfiltrationstromaleLeft()">Non
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Seidel</label>
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.pointcorneen.seidel">Oui
                            <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examLaf?.laf.corneetransparente.oielGauche.pointcorneen.seidel" (change)="onChangecornePointCorneSeidelLeft()">Non
                        </div>
                        <div class="col-12 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.pointcorneen.description">
                        </div>

                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielGauche.PRD.present==true">
                        <h5 class="titleDetails">PRD</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.PRD.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.PRD.taille" (change)="onChangecorneRpdTailleLeft()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.PRD.nombre" (change)="onChangecorneRpdNombreLeft()">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Type</label>
                            <select name="" id="" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.PRD.type">
                                <option value="En graisse de mouton">En graisse de mouton</option>
                                <option value="Stellaire">stellaire</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Couleur</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.PRD.couleur">
                        </div>
                        <div class="col-lg-6 col-md-6 mt-3">
                            <label for="">Pigmenté</label>
                            <br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.PRD.pigmente=='Oui'" (change)="selectPRDpigmenteLeft('Oui')" />
                            <label for="roundedCheckbox5">Oui</label>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.corneetransparente.oielGauche.PRD.pigmente=='Non'" (change)="selectPRDpigmenteLeft('Non')" />
                            <label for="roundedCheckbox6">Non</label>
                        </div>
                        <div class="col-12 mt-3">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.PRD.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.oedemecorneen.present==true">
                        <h5 class="titleDetails">Oedème cornéen</h5>
                        <div class="col-12">
                            <input type="checkbox" class="radiotypeinput" (change)="chooseOedemecorneenGauche('Grade 1 : Oedème cornéen minime, détail irien visible')" [checked]="examLaf?.laf.corneetransparente.oielGauche.oedemecorneen.grade==='Grade 1 : Oedème cornéen minime, détail irien visible'">Grade 1 : Oedème cornéen minime, détail irien visible
                            <br>
                            <input type="checkbox" class="radiotypeinput" (change)="chooseOedemecorneenGauche('Grade 2 : Oedème cornéen modéré, détail irien non')" [checked]="examLaf?.laf.corneetransparente.oielGauche.oedemecorneen.grade=== 'Grade 2 : Oedème cornéen modéré, détail irien non'">Grade 2 : Oedème cornéen modéré, détail irien non
                            visible <br>
                            <input type="checkbox" class="radiotypeinput" (change)="chooseOedemecorneenGauche('Grade 3 : Oedème cornéen sévère, structure de chambre')" [checked]="examLaf?.laf.corneetransparente.oielGauche.oedemecorneen.grade==='Grade 3 : Oedème cornéen sévère, structure de chambre'">Grade 3 : Oedème cornéen sévère, structure de chambre
                            antérieur non visible <br>
                        </div>
                        <div class="col-12">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.oedemecorneen.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="  examLaf?.laf.corneetransparente.oielGauche.KPS.present==true">
                        <h5 class="titleDetails">KPS</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.KPS.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.KPS.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.perforationcorneenne.present==true">
                        <h5 class="titleDetails">Perforation cornéenne</h5>

                        <div class="col-lg-6 col-md-6">
                            <label for="">Nombre</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.perforationcorneenne.nombre" (change)="onChangecornePerforationCorneNombreLeft()">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.perforationcorneenne.localisation">
                        </div>
                        <div class="col-12">
                            <label for="">Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.perforationcorneenne.description">
                        </div>
                    </div>
                    <!-- !------------------------------------ -->
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.opaque.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Opaque</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.opaque.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.corneaguttata.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Cornea guttata</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.corneaguttata.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.hydrops.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hydrops</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.hydrops.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.hematocornee.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hématocornée</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.hematocornee.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.microkystique.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Microkystique</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.microkystique.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielGauche.NSE.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">NSE</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.NSE.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielGauche.endothelite.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Endothélite</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.endothelite.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielGauche.bueeEpitheliale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Buée épithéliale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.bueeEpitheliale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.bullesousepitheliale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Bulle sous épithéliale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.bullesousepitheliale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.microkysteEpitheliaux.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Microkyste épithéliaux</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.microkysteEpitheliaux.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.Keratopathieenbandelette.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Kératopathie en bandelette</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.Keratopathieenbandelette.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.KB.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">KB</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.KB.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielGauche.striesdevogt.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Stries de vogt</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.striesdevogt.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Visibilité anormale des nerfs cornéen</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Irrégularité/plis de la descemet</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hypoesthésie cornéenne</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Épaississement épithéliale/stromale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.hypoesthesie.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hypoesthésie</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.hypoesthesie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.ulcerevernal.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Ulcère vernal</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.ulcerevernal.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.plaquevernale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Plaque vernale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.plaquevernale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.noduledetrantas.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Nodule de trantas</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.noduledetrantas.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.neovascularisationcorneenne.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Néovascularisation cornéenne</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.neovascularisationcorneenne.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.leucome.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Leucome</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.leucome.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.gerontoxon.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Gérontoxon</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.gerontoxon.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.buphtalmie.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Buphtalmie</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.buphtalmie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.corneetransparente.oielGauche.anneuximmunitaire.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Anneux immunitaire</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.corneetransparente.oielGauche.anneuximmunitaire.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.corneetransparente.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>