import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-macula',
  templateUrl: './macula.component.html',
  styleUrls: ['./macula.component.css', '../../laf/laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class MaculaComponent implements OnInit {
  displayMacula = true

  //--------------------------------------- Macula
  macula = false;
  maculaLeftList = [
    "Normale",
    "Perte du reflet fovéolaire",
    "Hémorragie maculaire",
    "Hématome maculaire",
    "Hémorragie rétrohyaloïdienne",
    "Trous maculaire",
    "Membrane épimaculaire",
    "Dreusen maculaire",
    "Etoile maculaire",
    "Plage d’atrophie",
    "Logette maculaire",
    "Aspect en oeil de boeuf",
    "Pigmentation anormale",
    "Macula rouge cerise",
    "Aspect poivrw et sel",
    "Couronne exsudative",
    "Macroanévrisme",
    "Remaniement micorkystique",
    "DEP",
    "DSR",
    "Aspect jaune d’oeuf au plat",
  ];
  maculaRightList = [
    "Normale",
    "Perte du reflet fovéolaire",
    "Hémorragie maculaire",
    "Hématome maculaire",
    "Hémorragie rétrohyaloïdienne",
    "Trous maculaire",
    "Membrane épimaculaire",
    "Dreusen maculaire",
    "Etoile maculaire",
    "Plage d’atrophie",
    "Logette maculaire",
    "Aspect en oeil de boeuf",
    "Pigmentation anormale",
    "Macula rouge cerise",
    "Aspect poivrw et sel",
    "Couronne exsudative",
    "Macroanévrisme",
    "Remaniement micorkystique",
    "DEP",
    "DSR",
    "Aspect jaune d’oeuf au plat",
  ];
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  //!----- anomalies by a doctor

  //Macula
  anomaliesByDoctorMacula: any[] = []
  oldlistAnomaliesMacula = this.maculaLeftList
  newAnomalieMacula = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreMacula = false
  //end of Show More Variables
  // send object
  objecttoSend = {
    maculaOD: {},
    maculaOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }

  isToastVisible = false

  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, "laf-macula").subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-macule", 'value': this.examLaf.laf.macule });
  }

  displayAndHideMacula() {
    this.displayMacula = !this.displayMacula
  }
  showMoreMaculaSection() {
    this.showMoreMacula = !this.showMoreMacula
  }
  configureDataExamen(idPatient) {
    // !Historique LAF
    this.examenService.getInspectionHistoriques(idPatient, "laf").subscribe((data: any) => {
      this.oldListConsultations = data
    },
      err => {
        return false;
      });
    //! Macula
    this.anomalieService.getAnomalieByDoctor("laf-macula").subscribe(data => {
      this.anomaliesByDoctorMacula = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }
  //------------------------------------------------------------------ macula

  addRemoveFromMaculaRight(value) {
    this.examLaf.laf.macule.oielDroit.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.macule.oielDroit); this.examLaf.laf.macule.oielDroit.normale.present = true
        break
      case 'Perte du reflet fovéolaire': this.examLaf.laf.macule.oielDroit.perterefletfoveolaire.present = !this.examLaf.laf.macule.oielDroit.perterefletfoveolaire.present
        break
      case 'Hémorragie maculaire': this.examLaf.laf.macule.oielDroit.hemorragiemaculaire.present = !this.examLaf.laf.macule.oielDroit.hemorragiemaculaire.present
        break
      case 'Hématome maculaire': this.examLaf.laf.macule.oielDroit.hematomemaculaire.present = !this.examLaf.laf.macule.oielDroit.hematomemaculaire.present
        break
      case 'Hémorragie rétrohyaloïdienne': this.examLaf.laf.macule.oielDroit.hemorragieretrohyaloidienne.present = !this.examLaf.laf.macule.oielDroit.hemorragieretrohyaloidienne.present
        break
      case 'Trous maculaire': this.examLaf.laf.macule.oielDroit.trousmaculaire.present = !this.examLaf.laf.macule.oielDroit.trousmaculaire.present
        break
      case 'Membrane épimaculaire': this.examLaf.laf.macule.oielDroit.membraneepimaculairen.present = !this.examLaf.laf.macule.oielDroit.membraneepimaculairen.present
        break
      case 'Dreusen maculaire': this.examLaf.laf.macule.oielDroit.dreusenmaculaire.present = !this.examLaf.laf.macule.oielDroit.dreusenmaculaire.present
        break
      case 'Etoile maculaire': this.examLaf.laf.macule.oielDroit.etoilemaculaire.present = !this.examLaf.laf.macule.oielDroit.etoilemaculaire.present
        break
      case 'Plage d’atrophie': this.examLaf.laf.macule.oielDroit.plagedatrophie.present = !this.examLaf.laf.macule.oielDroit.plagedatrophie.present
        break
      case 'Logette maculaire': this.examLaf.laf.macule.oielDroit.eLogettemaculaire.present = !this.examLaf.laf.macule.oielDroit.eLogettemaculaire.present
        break
      case 'Aspect en oeil de boeuf': this.examLaf.laf.macule.oielDroit.aspectenoeildeboeuf.present = !this.examLaf.laf.macule.oielDroit.aspectenoeildeboeuf.present
        break
      case 'Pigmentation anormale': this.examLaf.laf.macule.oielDroit.pigmentationanormale.present = !this.examLaf.laf.macule.oielDroit.pigmentationanormale.present
        break
      case 'Macula rouge cerise': this.examLaf.laf.macule.oielDroit.macularougecerise.present = !this.examLaf.laf.macule.oielDroit.macularougecerise.present
        break
      case 'Aspect poivre et sel': this.examLaf.laf.macule.oielDroit.aspectpoivreetsel.present = !this.examLaf.laf.macule.oielDroit.aspectpoivreetsel.present
        break
      case 'Couronne exsudative': this.examLaf.laf.macule.oielDroit.couronneexcsudatif.present = !this.examLaf.laf.macule.oielDroit.couronneexcsudatif.present
        break
      case 'Macroanévrisme': this.examLaf.laf.macule.oielDroit.macroanevrysme.present = !this.examLaf.laf.macule.oielDroit.macroanevrysme.present
        break
      case 'Remaniement micorkystique': this.examLaf.laf.macule.oielDroit.remaniementmicorkystique.present = !this.examLaf.laf.macule.oielDroit.remaniementmicorkystique.present
        break
      case 'DEP': this.examLaf.laf.macule.oielDroit.DEP.present = !this.examLaf.laf.macule.oielDroit.DEP.present
        break
      case 'DSR': this.examLaf.laf.macule.oielDroit.DSR.present = !this.examLaf.laf.macule.oielDroit.DSR.present
        break
      case 'Aspect jaune d’oeuf au plat': this.examLaf.laf.macule.oielDroit.aspectjaunedoeufauplat.present = !this.examLaf.laf.macule.oielDroit.aspectjaunedoeufauplat.present
        break
    }
    this.setToTrue(this.examLaf.laf.macule.oielDroit)
    this.objecttoSend.maculaOD = this.examLaf.laf.macule.oielDroit
    this.objecttoSend.source = "maculaOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromMaculaLeft(value) {
    this.examLaf.laf.macule.oielGauche.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.macule.oielGauche); this.examLaf.laf.macule.oielGauche.normale.present = true
        break
      case 'Perte du reflet fovéolaire': this.examLaf.laf.macule.oielGauche.perterefletfoveolaire.present = !this.examLaf.laf.macule.oielGauche.perterefletfoveolaire.present
        break
      case 'Hémorragie maculaire': this.examLaf.laf.macule.oielGauche.hemorragiemaculaire.present = !this.examLaf.laf.macule.oielGauche.hemorragiemaculaire.present
        break
      case 'Hématome maculaire': this.examLaf.laf.macule.oielGauche.hematomemaculaire.present = !this.examLaf.laf.macule.oielGauche.hematomemaculaire.present
        break
      case 'Hémorragie rétrohyaloïdienne': this.examLaf.laf.macule.oielGauche.hemorragieretrohyaloidienne.present = !this.examLaf.laf.macule.oielGauche.hemorragieretrohyaloidienne.present
        break
      case 'Trous maculaire': this.examLaf.laf.macule.oielGauche.trousmaculaire.present = !this.examLaf.laf.macule.oielGauche.trousmaculaire.present
        break
      case 'Membrane épimaculaire': this.examLaf.laf.macule.oielGauche.membraneepimaculairen.present = !this.examLaf.laf.macule.oielGauche.membraneepimaculairen.present
        break
      case 'Dreusen maculaire': this.examLaf.laf.macule.oielGauche.dreusenmaculaire.present = !this.examLaf.laf.macule.oielGauche.dreusenmaculaire.present
        break
      case 'Etoile maculaire': this.examLaf.laf.macule.oielGauche.etoilemaculaire.present = !this.examLaf.laf.macule.oielGauche.etoilemaculaire.present
        break
      case 'Plage d’atrophie': this.examLaf.laf.macule.oielGauche.plagedatrophie.present = !this.examLaf.laf.macule.oielGauche.plagedatrophie.present
        break
      case 'Logette maculaire': this.examLaf.laf.macule.oielGauche.eLogettemaculaire.present = !this.examLaf.laf.macule.oielGauche.eLogettemaculaire.present
        break
      case 'Aspect en oeil de boeuf': this.examLaf.laf.macule.oielGauche.aspectenoeildeboeuf.present = !this.examLaf.laf.macule.oielGauche.aspectenoeildeboeuf.present
        break
      case 'Pigmentation anormale': this.examLaf.laf.macule.oielGauche.pigmentationanormale.present = !this.examLaf.laf.macule.oielGauche.pigmentationanormale.present
        break
      case 'Macula rouge cerise': this.examLaf.laf.macule.oielGauche.macularougecerise.present = !this.examLaf.laf.macule.oielGauche.macularougecerise.present
        break
      case 'Aspect poivre et sel': this.examLaf.laf.macule.oielGauche.aspectpoivreetsel.present = !this.examLaf.laf.macule.oielGauche.aspectpoivreetsel.present
        break
      case 'Couronne exsudative': this.examLaf.laf.macule.oielGauche.couronneexcsudatif.present = !this.examLaf.laf.macule.oielGauche.couronneexcsudatif.present
        break
      case 'Macroanévrisme': this.examLaf.laf.macule.oielGauche.macroanevrysme.present = !this.examLaf.laf.macule.oielGauche.macroanevrysme.present
        break
      case 'Remaniement micorkystique': this.examLaf.laf.macule.oielGauche.remaniementmicorkystique.present = !this.examLaf.laf.macule.oielGauche.remaniementmicorkystique.present
        break
      case 'DEP': this.examLaf.laf.macule.oielGauche.DEP.present = !this.examLaf.laf.macule.oielGauche.DEP.present
        break
      case 'DSR': this.examLaf.laf.macule.oielGauche.DSR.present = !this.examLaf.laf.macule.oielGauche.DSR.present
        break
      case 'Aspect jaune d’oeuf au plat': this.examLaf.laf.macule.oielGauche.aspectjaunedoeufauplat.present = !this.examLaf.laf.macule.oielGauche.aspectjaunedoeufauplat.present
        break
    }
    this.setToTrue(this.examLaf.laf.macule.oielGauche)
    this.objecttoSend.maculaOG = this.examLaf.laf.macule.oielGauche
    this.objecttoSend.source = "maculaOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }


  //!--------------------Macula
  addNewAnomalyMacula(event) {
    const maxAnomalies = 5;
    const inputValue = event?.target?.value;

    const showToast = (message, messageColor, backgroundColor, progressBarColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        const self = this;
        this.iziToast.show({
          message,
          messageColor,
          titleColor: messageColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: function () {
            self.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorMacula && this.anomaliesByDoctorMacula.length >= maxAnomalies) {
      showToast(
        "Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies",
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieMacula = "";
      return false;
    }

    if (inputValue && inputValue.length > 1 && inputValue.length < 100) {
      const anomalyExisted = this.anomaliesByDoctorMacula.some(x => x.titre?.toLowerCase() === inputValue.toLowerCase());
      const anomalyExistedOld = this.oldlistAnomaliesMacula.some(x => x.toLowerCase() === inputValue.toLowerCase());

      if (!anomalyExisted && !anomalyExistedOld) {
        this.anomalieService.ajoutAnomalie({
          titre: inputValue[0].toUpperCase() + inputValue.slice(1),
          description: "",
          zone: "laf-macula",
        }).subscribe((data) => {
          this.newAnomalieMacula = "";
          this.anomaliesByDoctorMacula.push(data);
          showToast(
            'Bien enregistré',
            '#386641',
            '#dde5b6',
            '#6a994e',
          );
        });
        return true;
      } else {
        showToast(
          "L'anomalie existe dans la liste",
          '#800f2f',
          '#ff8fa3',
          '#c9184a'
        );
        this.newAnomalieMacula = "";
        return false;
      }
    } else {
      showToast(
        'Merci de saisir au moins 2 caractères',
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieMacula = "";
      return false;
    }
  }

  // Right Macula
  addRemoveFromMaculaRightNew(value, id) {
    this.examLaf.laf.macule.oielDroit.normale.present = false
    let truefalseexist = this.examLaf.laf.macule.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.macule.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.maculaOD = this.examLaf.laf.macule.oielDroit
      this.objecttoSend.source = "maculaOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.macule.oielDroit.allAnomalies = this.examLaf.laf.macule.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.maculaOD = this.examLaf.laf.macule.oielDroit
      this.objecttoSend.source = "maculaOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examLaf.laf.macule.oielDroit)
      return true;
    }

  }
  checkExistAnomalieMaculaRight(id) {
    return this.examLaf.laf.macule.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Macula dyna
  addRemoveFromMaculaLeftNew(value, id) {
    this.examLaf.laf.macule.oielGauche.normale.present = false
    let truefalseexist = this.examLaf.laf.macule.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.macule.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.maculaOG = this.examLaf.laf.macule.oielGauche
      this.objecttoSend.source = "maculaOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.macule.oielGauche.allAnomalies = this.examLaf.laf.macule.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.maculaOG = this.examLaf.laf.macule.oielGauche
      this.objecttoSend.source = "maculaOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examLaf.laf.macule.oielGauche)
      return true;
    }

  }
  checkExistAnomalieMaculaLeft(id) {
    return this.examLaf.laf.macule.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of Macula


  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-macula') {
        this.anomaliesByDoctorMacula.splice(this.anomaliesByDoctorMacula.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }
  setToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "normale") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.normale.present = true;
    }
    return myObject
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }
}
