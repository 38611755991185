import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import { ConsultationService } from '../../../../../services/consultation/consultation.service';
import { Router, ActivatedRoute } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../../config/API_CONFIG';
import { AuthService } from '../../../../../services/auth/auth.service';
declare let jsPDF;
import { CertificatService } from '../../../../../services/certificat/certificat.service';
import { OrdonnanceService } from '../../../../../services/ordonnance/ordonnance.service';
import { uploadFolderRootDocument } from '../../../../../../../config/API_CONFIG';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-listdocumentconsultation',
  templateUrl: './listdocumentconsultation.component.html',
  styleUrls: ['../../listconsultation/listconsultation.component.scss']
})
export class ListdocumentconsultationComponent implements OnInit {

  documents: any[] = [];
  medecin: any;
  interval: any;
  messages: any[] = [];
  secretaire: any;
  ordonnances: any[] = [];
  certificats: any[] = [];
  @Input() imgPath: string = uploadFolderRootDocument;
  selectedDocument: any;
  selectedOrdonnace: any;
  selectedCertificat: any;
  d:any;
  dd:any;
  p:any;
  constructor(private router: Router, private consultationService: ConsultationService, public http: HttpClient,
    private activatedRoute: ActivatedRoute, private authService: AuthService, private ordonnanceService: OrdonnanceService,
    private certificatService: CertificatService
  ) {
  }
  ngOnInit() {
    let el = document.getElementById('demo');
    el.scrollIntoView({ behavior: "smooth" });
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile:any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;

      this.authService.getProfileSecretaire().subscribe((profile:any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    let params: any = this.activatedRoute.snapshot.params;
    this.consultationService.getAllDocumentsByConsultation(params.id)
      .subscribe((documents:any[]) => {
        this.documents = documents;
      });
    this.ordonnanceService.getAllOrdonnancesByConsultation(params.id)
      .subscribe((ordonnances:any[]) => {
        this.ordonnances = ordonnances;
      });
    this.certificatService.getAllCerificatsByConsultation(params.id)
      .subscribe((certificats:any[]) => {
        this.certificats = certificats;
      });


  }
  deleteDocument(document) {
    this.documents.splice(this.documents.indexOf(document), 1);
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.document) + '/patientConsultation/' + document._id, { headers: headers }).subscribe((res: any) => {
      this.documents.splice(this.documents.indexOf(document), 1);
      Swal.fire({
        imageUrl: 'assets/images/patsDetails/delete-2.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'BIEN SUPPRIMÉ',
        timer: 1500,
        showConfirmButton: false
      }
      );
    })

  }
  //supprimer une certificat
  removeCertificat(certificat) {
    certificat =  this.selectedCertificat
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.certificat) + '/' + certificat._id, { headers: headers }).subscribe((res: any) => {
      this.certificats.splice(this.certificats.indexOf(certificat), 1);
      Swal.fire({
        imageUrl: 'assets/images/patsDetails/delete-2.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'BIEN SUPPRIMÉ',
        timer: 1500,
        showConfirmButton: false
      }
      );
      //location.reload();
    })
  }
  removeOrdonnance(ordonnance) {
    ordonnance =  this.selectedOrdonnace
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.ordonnance) + '/' + ordonnance._id, { headers: headers }).subscribe((res: any) => {
      this.ordonnances.splice(this.ordonnances.indexOf(ordonnance), 1);
      Swal.fire({
        imageUrl: 'assets/images/patsDetails/delete-2.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'BIEN SUPPRIMÉ',
        timer: 1500,
        showConfirmButton: false
      }
      );
      //location.reload();
    })
  }
  removeDocumentPatient(document) {
    document =  this.selectedDocument
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    var index = this.documents.indexOf(document);
    this.http.delete(_getURL(API_CONFIG.document) + '/' + document.titre + '/' + document._id, { headers: headers }).subscribe((res: any) => {
      this.documents.splice(this.documents.indexOf(document), 1)
      Swal.fire({
        imageUrl: 'assets/images/patsDetails/delete-2.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'BIEN SUPPRIMÉ',
        timer: 1500,
        showConfirmButton: false
      }
      );
      //location.reload();
    })
  }
  selectedDocumentFunction(mesdocument) {
    this.selectedDocument = this.documents.find(x => x._id == mesdocument._id);
  }
  selectedCertificatFunction(certificat) {
    this.selectedCertificat = this.certificats.find(x => x._id == certificat._id);
  }
  selectedOrdonnaceFunction(ordonnance) {
    this.selectedOrdonnace = this.ordonnances.find(x => x._id == ordonnance._id);
  }
}

