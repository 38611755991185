import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
import { Observable } from 'rxjs';

@Injectable()
export class AnomalieService {


constructor(private http: HttpClient) { }

ajoutAnomalie(anomalie) {
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.anomalie), anomalie, { headers: headers })
}
getAnomalieById(id){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.anomalie) + "/get-anomalie/" + id, { headers: headers });
}
getAnomalieByDoctor(zone){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.anomalie) + "/get-anomalies-by-doctor/"+zone, { headers: headers });
}
deleteAnomalie(id){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.delete<any>(_getURL(API_CONFIG.anomalie) + "/delete-anomalie/" + id, { headers: headers });
}
}