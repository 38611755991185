import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { ConsultationService } from '../../../../../services/consultation/consultation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { API_CONFIG } from '../../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../../config/API_CONFIG';
import { AuthService } from '../../../../../services/auth/auth.service';
import { PatientService } from '../../../../../services/profils/patient/patient.service';
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-ajout-document-consultation',
  templateUrl: './ajout-document-consultation.component.html',
  styleUrls: ['./ajout-document-consultation.component.css','../../listconsultation/listconsultation.component.scss']
})
export class AjoutDocumentConsultationComponent implements OnInit {

  documents: any[] = [];
  medecin: any;
  interval: any;
  messages: any[] = [];
  secretaire: any;
  ordonnances: any[] = [];
  certificats: any[] = [];
  patient:any;
  sizeLimitOne = 10000000; //10MB
  public loading = false;
  selectedFiles: any;
  errofile = false;
  myId:any;
  consultation:any;
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.document) + '/medecin/patient/consultation' });
  constructor(private router: Router, private consultationService: ConsultationService, 
    private activatedRoute: ActivatedRoute, private authService: AuthService,private patientService:PatientService,
  ) {
  }
  ngOnInit() {
    let el = document.getElementById('cours');
    el.scrollIntoView({ behavior: "smooth" });
    this.uploader.onBeforeUploadItem = (item) => {
        item.withCredentials = false;
      }
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.myId = medecinId
      this.authService.getProfileMedecin().subscribe((profile:any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;
        this.myId = medecinId
      this.authService.getProfileSecretaire().subscribe((profile:any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste'])
        return false;
      });

      this.consultationService.geetConsultationById(params.idConsultation).subscribe(consultation => {
        this.consultation = consultation;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste'])
          return false;
        });

  }


  selectFile(event) {
      const file = this.uploader.queue
      this.uploader.options.additionalParameter = {
        medecinId: this.myId,
        patientId:this.patient._id,
        consultationId:this.consultation._id,
        dateConsultation:this.consultation.date
        };
      if (file) {
        for(var j =0;j<file.length;j++){
          if(file[j]){
            if (!file[j].file.type.match('image.*|application/pdf')) {
              event = undefined
              this.uploader.queue.length =0
              alert("Impossible de télécharger ce fichier.")
              return false;
            }
            if (file[j].file.size > this.sizeLimitOne) {
              event = undefined
              this.uploader.queue.length =0
              alert("Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.")
              return false;
            }
            var extension = file[j].file.name.split('.').pop()
            var token = this.generate_token(20)
            file[j].file.name = this.patient._id+token+'.'+extension
          }
        }
        if(file.length >3){
          event = undefined
          this.uploader.queue.length =0
          alert("Séléctionner seulement 3 fichiers ")
          return false;
        }

      } else {
        return false;
      }
  }

  generate_token(length){
    //edit the token allowed characters
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];
    for (var i=0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}
refresh(){
var  me = this;
this.loading = true;
setTimeout(function() { me.router.navigate(['/medecin/consultation-liste',me.patient._id]);this.loading = false;
Swal.fire( {
    imageUrl: 'assets/images/patsDetails/plus-3.png',
    imageWidth: 100,
    imageHeight: 100,
    imageAlt: 'Custom image',
    width: 600,
    title: 'BIEN AJOUTÉ',
    timer: 1500,
    showConfirmButton: false
  }
);
},5000);
}
}

