import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-impression-cat',
  templateUrl: './impression-cat.component.html',
  styleUrls: ['./impression-cat.component.css']
})
export class ImpressionCatComponent implements OnInit {
  myExamen: any;
  today = new Date().toISOString().slice(0, 10)
  constructor(private examenService: ExamenService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.today = new Date().toISOString().slice(0, 10)
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    const idcat = this.route.snapshot.paramMap.get('idCat');
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getExamenByIdForCatImpression(idExamen, idcat).subscribe((data: any) => {
        this.myExamen = data
        if (!this.myExamen || (this.myExamen && !this.myExamen.foundImpression) || (this.myExamen && this.myExamen.foundImpression && !this.myExamen.foundImpression._id)) {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        }
        if (this.myExamen.foundImpression && !this.myExamen.foundImpression.titrePrecrire) {
          this.myExamen.foundImpression.titrePrecrire = "ordonnance"
        }
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    }
  }


  imprimer() {
    const headerElement = document.getElementById('header-cont');
    const mainElement = document.getElementById('main-cont');
    const footerElement = document.getElementById('footer-cont');

    const extractInputValues = (element) => {
      const inputs = element.querySelectorAll('input, textarea, select');
      inputs.forEach(input => {
        if (input.type === 'checkbox' || input.type === 'radio') {
          if (input.checked) {
            input.setAttribute('checked', 'checked');
          } else {
            input.removeAttribute('checked');
          }
        } else {
          input.setAttribute('value', input.value);
        }
      });
    };

    extractInputValues(mainElement);

    const headerContent = headerElement.innerHTML;
    const mainContent = mainElement.innerHTML;
    const footerContent = footerElement.innerHTML;

    const styledHeaderContent = `<div style="margin-right: 30px; height: 170px; margin-top: 30px;">${headerContent}</div>`;
    const styledFooterContent = `<div style="height: 130px;">${footerContent}</div>`;
    const combinedContent = `${styledHeaderContent}<div style="height: 770px; padding-left: 2rem; padding-right: 2rem">${mainContent}</div>${styledFooterContent}`;

    let nameFileSaved = "your_file_name.pdf";
    if (this.myExamen && this.myExamen.foundImpression && this.myExamen.patient && this.myExamen.foundImpression.titrePrecrire && this.myExamen.patient.nom && this.myExamen.patient.prenom) {
      nameFileSaved = this.myExamen.foundImpression.titrePrecrire.replace(/\s/g, "-") + "-" + this.myExamen.patient.nom.replace(/\s/g, "-") + "-" + this.myExamen.patient.prenom.replace(/\s/g, "-") + "-" + new Date().toISOString().slice(0, 10) + ".pdf";
    }

    const options = {
      filename: nameFileSaved,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true, scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().set(options).from(combinedContent).save();
  }

}
