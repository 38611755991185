

  <div class="container"  style="margin-top:90px">
    <div class="row">
        <!-- <div class="container-fluid" *ngIf="showScreen">
            <div class="row">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <div class="user-list-card">
                  <h4>Discussions</h4>
                  <div class="form-group">
                    <input type="search" placeholder="Rechercher..." class="form-control" [(ngModel)]="searchTerm" (input)="searchUser()" />
                  </div>
                  <div class="user-card"
                       [ngClass]="{'active': user?.phone === selectedUser?.phone}"
                       *ngFor="let user of userList"
                       (click)="selectUserHandler(user?.phone)">
                    <img [src]="user?.image" [alt]="user?.name" class="img-fluid avatar" />
                    <div class="user-info">
                      <p class="username m-0">{{user?.nom}}</p>
                      <div class="messages">
                        <div *ngFor="let msg of user.lastMsgs">
                          <div *ngIf="msg[currentUser.roomId[user.id]]">
                            <p class="m-0" [ngStyle]="{
                              'font-weight': msg[currentUser.roomId[user.id]].hasNewMessage ? 'bolder' : 'normal',
                              'color': '#979797',
                              'font-size': msg[currentUser.roomId[user.id]].hasNewMessage ? '0.9rem' : '1rem'
                            }">
                            {{ truncateMessage(msg[currentUser.roomId[user.id]].message) }}
                            <span *ngIf="msg[currentUser.roomId[user.id]].hasNewMessage" class="badge"></span>
                              <span *ngIf="msg[currentUser.roomId[user.id]].timestamp" class="timestamp" style="font-weight: 400;font-size: 0.7rem;">
                                {{ msg[currentUser.roomId[user.id]].timestamp | date: 'dd/MM HH:mm'}}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <div class="chat-container">
                  <ng-container *ngIf="selectedUser">
                    <div class="chat-header p-3">
                      <img [src]="selectedUser?.image" [alt]="selectedUser?.name" class="avatar img-fluid" />
                      <p class="username">{{selectedUser?.nom}}</p>
                     
                    </div>
                    <div class="chat-body">
                      <div *ngFor="let item of messageArray" [ngClass]="{'same-user': item?.user === currentUser?.name, 'other-user': item?.user !== currentUser?.name}">
                        <p class="message-container">
                          {{item?.message}}
                          <i (click)="changeStatutFavorite(item)" *ngIf="!item.favorite" class="fa fa-heart-o"></i> 
                          <i (click)="changeStatutFavorite(item)" *ngIf="item.favorite" class="fa fa-heart"></i>
                          <br>
                        <span style="color: #666; font-size: 0.7rem;">{{ item?.timestamp | date: 'dd/MM HH:mm' }}</span>
                        
                        </p>
                      </div>
                    </div>
                    
                    
                    
                    <div class="chat-footer">
                      <div class="row">
                        <div class="col-9 mb-2 mb-md-0">
                          <div class="form-group mb-0">
                            <input type="text" placeholder=" Message" class="form-control" [(ngModel)]="messageText" (keyup)="$event.keyCode === 13 && sendMessage()" />
                          </div>
                        </div>
                        <div *ngIf="messageText" class="col-3 text-center">
                          <button class="btn btn-primary btn-sm px-3" (click)="sendMessage()">
                            <i class="fa fa-paper-plane"></i>
                          </button>
                        </div>
                        
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div> -->
          
          <!-- <ng-template #popup let-d="dismiss">
            <div class="modal-header">
              <h4 class="modal-title">Login</h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input type="number" placeholder="Enter your phone number" class="form-control" [(ngModel)]="phone" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-sm btn-primary" (click)="login(d)">Login</button>
            </div>
          </ng-template> -->
          
    </div>
</div>   