<div *ngIf="myExamen">
    <h6 id="color-red">Réfraction subjective</h6>
    <p id="textswitchtoggle">Sans Cycloplégie</p>
    <div>
        <br />
        <div class="col-12 sansCyclo">
            <div class="cadreOeilDroit">
                <div class="oeilTitreSection">
                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                    <h5 id="OeilTitle">Oeil droit</h5>
                </div>
                <div id="suboeilTitreSection">
                    <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielDroit.sphere==undefined?'':myExamen?.refraction.sansCycloplegie.oielDroit.sphere" disabled />
                    </div>
                    <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielDroit.cylindre==undefined?'':myExamen?.refraction.sansCycloplegie.oielDroit.cylindre" disabled />
                    </div>
                    <div class="axe blocCyclop">
                        <h6>Axe</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielDroit.axe==undefined?'':myExamen?.refraction.sansCycloplegie.oielDroit.axe" disabled />
                    </div>
                    <div class="kératométrie blocCyclop">
                        <h6>kératométrie</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielDroit.keratometrie==undefined?'':myExamen?.refraction.sansCycloplegie.oielDroit.keratometrie" disabled />
                    </div>
                </div>
            </div>
            <div class="cadreOeilGauche">
                <div class="oeilTitreSection">
                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                    <h5 id="OeilTitle">Oeil gauche</h5>
                </div>
                <div id="suboeilTitreSection">
                    <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielGauche.sphere==undefined?'':myExamen?.refraction.sansCycloplegie.oielGauche.sphere" disabled />
                    </div>
                    <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielGauche.cylindre==undefined?'':myExamen?.refraction.sansCycloplegie.oielGauche.cylindre" disabled />
                    </div>
                    <div class="axe blocCyclop">
                        <h6>Axe</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielGauche.axe==undefined?'':myExamen?.refraction.sansCycloplegie.oielGauche.axe" disabled />
                    </div>
                    <div class="kératométrie blocCyclop">
                        <h6>kératométrie</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.sansCycloplegie.oielGauche.keratometrie==undefined?'':myExamen?.refraction.sansCycloplegie.oielGauche.keratometrie" disabled />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <p id="textswitchtoggle">Avec Cycloplégie</p>
    <br />
    <div>
        <div id="avecCyclopCheckboxex">
            <div class="checkBoxbloc" *ngIf="myExamen?.refraction.avecCycloplegie.type==='atropine'">
                <p class="ACcheckboxLabel">Atropine</p>
            </div>
            <div class="checkBoxbloc" *ngIf="myExamen?.refraction.avecCycloplegie.type==='cyclocol'">
                <p class="ACcheckboxLabel">Cyclocol</p>
            </div>
            <div class="checkBoxbloc" *ngIf="myExamen?.refraction.avecCycloplegie.type==='tropicamide'">
                <p class="ACcheckboxLabel">Tropicamide</p>
            </div>
        </div>
        <div class="col-12 sansCyclo">
            <div class="cadreOeilDroit">
                <div class="oeilTitreSection">
                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                    <h5 id="OeilTitle">Oeil droit</h5>
                </div>
                <div id="suboeilTitreSection">
                    <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielDroit.sphere==undefined?'':myExamen?.refraction.avecCycloplegie.oielDroit.sphere" disabled />
                    </div>
                    <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielDroit.cylindre==undefined?'':myExamen?.refraction.avecCycloplegie.oielDroit.cylindre" disabled />
                    </div>
                    <div class="axe blocCyclop">
                        <h6>Axe</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielDroit.axe==undefined?'':myExamen?.refraction.avecCycloplegie.oielDroit.axe" disabled />
                    </div>
                    <div class="kératométrie blocCyclop">
                        <h6>kératométrie</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielDroit.keratometrie==undefined?'':myExamen?.refraction.avecCycloplegie.oielDroit.keratometrie" disabled />
                    </div>
                </div>
            </div>
            <div class="cadreOeilGauche">
                <div class="oeilTitreSection">
                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                    <h5 id="OeilTitle">Oeil gauche</h5>
                </div>
                <div id="suboeilTitreSection">
                    <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielGauche.sphere==undefined?'':myExamen?.refraction.avecCycloplegie.oielGauche.sphere" disabled />
                    </div>
                    <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielGauche.cylindre==undefined?'':myExamen?.refraction.avecCycloplegie.oielGauche.cylindre" disabled />
                    </div>
                    <div class="axe blocCyclop">
                        <h6>Axe</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielGauche.axe==undefined?'':myExamen?.refraction.avecCycloplegie.oielGauche.axe" disabled />
                    </div>
                    <div class="kératométrie blocCyclop">
                        <h6>kératométrie</h6>
                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.avecCycloplegie.oielGauche.keratometrie==undefined?'':myExamen?.refraction.avecCycloplegie.oielGauche.keratometrie" disabled />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <br />
    <h6 id="color-red">Acuité Visuel</h6>
    <div>
        <p id="textswitchtoggle">Acuité visuel de loin</p>
        <div>
            <div id="acuiteVisLoin">
                <br />
                <div id="headerAcuiteLoin">
                    <h6>Échelle :</h6>
                    <div *ngIf="myExamen?.refraction.acuitevisueldeloin.echelle==='monoyer'">
                        <label>Monoyer</label>
                    </div>
                    <div *ngIf="myExamen?.refraction.acuitevisueldeloin.echelle==='snellen'">
                        <label>Snellen</label>
                    </div>
                </div>
                <!-- echelle Monoyer -->
                <div>
                    <div id="noteAVSC">
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldeloin.oielDroit.avsc" disabled>
                                <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                            </select>
                        </div>
                        <h4 id="colorBlack">AVSC</h4>
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldeloin.oielGauche.avsc" disabled>
                                <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                            </select>
                        </div>
                    </div>
                    <div id="noteAVSC">
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldeloin.oielDroit.avac" disabled>
                                <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                            </select>
                        </div>
                        <h4 id="colorBlack">AVAC</h4>
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldeloin.oielGauche.avac" disabled>
                                <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                            </select>
                        </div>
                    </div>
                    <!-- Réfraction objective -->
                    <div>
                        <h4 id="colorBlack" class="text-center">Réfraction objective</h4>
                        <div class="col-12 sansCyclo">
                            <div class="cadreAcuiteOeilDroit">
                                <div class="oeilTitreSection">
                                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                                    <h5 id="OeilTitle1">Oeil droit</h5>
                                </div>
                                <div id="suboeilTitreSection">
                                    <div class="Sphère blocCyclop m-l-6">
                                        <h6>Sphère</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldeloin.oielDroit.sphere==undefined?'':myExamen?.refraction.acuitevisueldeloin.oielDroit.sphere" disabled />
                                    </div>
                                    <div class="cylindre blocCyclop m-l-6">
                                        <h6>Cylindre</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldeloin.oielDroit.cylindre==undefined?'':myExamen?.refraction.acuitevisueldeloin.oielDroit.cylindre" disabled />
                                    </div>
                                    <div class="axe blocCyclop m-l-6">
                                        <h6>Axe</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldeloin.oielDroit.axe==undefined?'':myExamen?.refraction.acuitevisueldeloin.oielDroit.axe" disabled />
                                    </div>
                                </div>
                            </div>
                            <div class="cadreAcuiteOeilGauche">
                                <div class="oeilTitreSection">
                                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                                    <h5 id="OeilTitle1">Oeil gauche</h5>
                                </div>
                                <div id="suboeilTitreSection">
                                    <div class="Sphère blocCyclop m-l-6">
                                        <h6>Sphère</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldeloin.oielGauche.sphere==undefined?'':myExamen?.refraction.acuitevisueldeloin.oielGauche.sphere" disabled />
                                    </div>
                                    <div class="cylindre blocCyclop m-l-6">
                                        <h6>Cylindre</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldeloin.oielGauche.cylindre==undefined?'':myExamen?.refraction.acuitevisueldeloin.oielGauche.cylindre" disabled />
                                    </div>
                                    <div class="axe blocCyclop m-l-6">
                                        <h6>Axe</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldeloin.oielGauche.axe==undefined?'':myExamen?.refraction.acuitevisueldeloin.oielGauche.axe" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-t-20">
                <div>
                    <label>Oeil directeur de loin</label>
                    <br />
                    <div class="checkBoxbloc" *ngIf="myExamen?.refraction.acuitevisueldeloin.oeilDirecteur==='oielDroit'">

                        <p class="ACcheckboxLabel">Oeil Droit</p>
                    </div>
                    <div class="checkBoxbloc1" *ngIf="myExamen?.refraction.acuitevisueldeloin.oeilDirecteur==='oielGauche'">
                        <p class="ACcheckboxLabel">Oeil Gauche</p>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <p id="textswitchtoggle">Acuité visuel de près</p>
        <div>
            <div id="acuiteVisLoin">
                <br />
                <div id="headerAcuiteLoin">
                    <h6>Échelle :</h6>
                    <div>
                        <label>Parinaud</label>
                    </div>
                </div>
                <div>
                    <div id="noteAVSC">
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldepres.oielDroit.avsc" disabled>
                                <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                            </select>
                        </div>
                        <h4 id="colorBlack">AVSC</h4>
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldepres.oielGauche.avsc" disabled>
                                <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                            </select>
                        </div>
                    </div>
                    <div id="noteAVSC">
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldepres.oielDroit.avac" disabled>
                                <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                            </select>
                        </div>
                        <h4 id="colorBlack">AVAC</h4>
                        <div class="noteDroit">
                            <select class="form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldepres.oielGauche.avac" disabled>
                                <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 acuitePres">
                            <div id="addbloc1" style="position: relative;left: 20px;">
                                <label id="labelAcuitePre">Add</label>
                                <div class="noteDroit">
                                    <input type="text" id="numberInput9" style="width: 20%;" class="numberInput form-control input-sm" [(ngModel)]="myExamen?.refraction.acuitevisueldepres.oielDroit.add" disabled />

                                </div>
                            </div>
                        </div>
                        <div class="col-6 acuitePres">
                            <div id="addbloc2" style="position: relative;left: 90px;">
                                <label id="labelAcuitePre">Add</label>
                                <div class="noteDroit">
                                    <input type="text" id="numberInput10" style="width: 20%;" class="numberInput form-control input-sm" value="0" [(ngModel)]="myExamen?.refraction.acuitevisueldepres.oielGauche.add" disabled />

                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4 id="colorBlack" class="text-center">Réfraction objective</h4>
                        <div class="col-12 sansCyclo">
                            <div class="cadreAcuiteOeilDroit">
                                <div class="oeilTitreSection">
                                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                                    <h5 id="OeilTitle1">Oeil droit</h5>
                                </div>
                                <div id="suboeilTitreSection">
                                    <div class="Sphère blocCyclop m-l-6">
                                        <h6>Sphère</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldepres.oielDroit.sphere==undefined?'':myExamen?.refraction.acuitevisueldepres.oielDroit.sphere" disabled />
                                    </div>
                                    <div class="cylindre blocCyclop m-l-6">
                                        <h6>Cylindre</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldepres.oielDroit.cylindre==undefined?'':myExamen?.refraction.acuitevisueldepres.oielDroit.cylindre" disabled />
                                    </div>
                                    <div class="axe blocCyclop m-l-6">
                                        <h6>Axe</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldepres.oielDroit.axe==undefined?'':myExamen?.refraction.acuitevisueldepres.oielDroit.axe" disabled />
                                    </div>
                                </div>
                            </div>
                            <div class="cadreAcuiteOeilGauche">
                                <div class="oeilTitreSection">
                                    <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                                    <h5 id="OeilTitle1">Oeil gauche</h5>
                                </div>
                                <div id="suboeilTitreSection">
                                    <div class="Sphère blocCyclop m-l-6">
                                        <h6>Sphère</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldepres.oielGauche.sphere==undefined?'':myExamen?.refraction.acuitevisueldepres.oielGauche.sphere" disabled />
                                    </div>
                                    <div class="cylindre blocCyclop m-l-6">
                                        <h6>Cylindre</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldepres.oielGauche.cylindre==undefined?'':myExamen?.refraction.acuitevisueldepres.oielGauche.cylindre" disabled />
                                    </div>
                                    <div class="axe blocCyclop m-l-6">
                                        <h6>Axe</h6>
                                        <input type="text" class="inputCyclop form-control input-sm" [value]="myExamen?.refraction.acuitevisueldepres.oielGauche.axe==undefined?'':myExamen?.refraction.acuitevisueldepres.oielGauche.axe" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-t-20">
                <div>
                    <label>Oeil directeur de près</label>
                    <br />
                    <div class="checkBoxbloc" *ngIf="myExamen?.refraction.acuitevisueldepres.oeilDirecteur==='oielDroit'">
                        <p class="ACcheckboxLabel">Oeil Droit</p>
                    </div>
                    <div class="checkBoxbloc1" *ngIf="myExamen?.refraction.acuitevisueldepres.oeilDirecteur==='oielGauche'">
                        <p class="ACcheckboxLabel">Oeil Gauche</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>