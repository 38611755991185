<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Cristallin" (click)="displayAndHideCristallin()">Cristallin</h4>
            <div class="arrow arrow-up btnCristallin" *ngIf="displayCristallin" title="Cacher Cristallin" (click)="displayAndHideCristallin()">▲</div>
            <div class="arrow arrow-up btnCristallin" *ngIf="!displayCristallin" title="Afficher Cristallin" (click)="displayAndHideCristallin()">▼</div>
        </div>

        <div *ngIf="displayCristallin" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielDroit.transparent.present==true}" (click)="addRemoveFromCristalinRight('Transparent')">Transparent</li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielDroit.aphaquie.present==true}" (click)="addRemoveFromCristalinRight('Aphaquie')">Aphaquie</li>
                                <div *ngIf="showMoreCristalin">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.present==true}" (click)="addRemoveFromCristalinRight('Capsule antérieure calcifiée')">Capsule antérieure calcifiée</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielDroit.capsuleantrompu.present==true}" (click)="addRemoveFromCristalinRight('Capsule antérieure rompue')">Capsule antérieure rompue</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.cataracte.present==true}" (click)="addRemoveFromCristalinRight('Cataracte')">Cataracte</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.corpsetranger.present==true}" (click)="addRemoveFromCristalinRight('Corps étranger')">Corps étranger</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielDroit.ectopie.present==true}" (click)="addRemoveFromCristalinRight('Ectopie')">Ectopie</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielDroit.fragilitezonulaire.present==true}" (click)="addRemoveFromCristalinRight('Fragilité zonulaire')">Fragilité zonulaire</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.glaucomeflecken.present==true}" (click)="addRemoveFromCristalinRight('Glaucome flecken')">Glaucome flecken</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.luxationSubluxation.present==true}" (click)="addRemoveFromCristalinRight('Luxation/subluxation')">Luxation/subluxation
                                </li>
                                <div *ngIf="showMoreCristalin">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.microspheiohaquie.present==true}" (click)="addRemoveFromCristalinRight('Microspheiohaquie')">Microspheiohaquie</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.phacosclerose.present==true}" (click)="addRemoveFromCristalinRight('Phacosclérose')">Phacosclérose</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.phacodonesis.present==true}" (click)="addRemoveFromCristalinRight('Phacodonésis')">Phacodonésis</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.pseudophaque.present==true}" (click)="addRemoveFromCristalinRight('Pseudophaque')">Pseudophaque</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielDroit.SPE.present==true}" (click)="addRemoveFromCristalinRight('SPE')">SPE</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorCristallin" [ngClass]="{'selectedLi': checkExistAnomalieCristallinRight(anomaly.id)}">
                                        <span (click)="addRemoveFromCristallinRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieCristallinRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-cristallin')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieCristallin" (keyup.enter)="addNewAnomalyCristallin($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>

                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreCristalinSection() *ngIf="!showMoreCristalin">Voir plus</span>
                            <span (click)=showMoreCristalinSection() *ngIf="showMoreCristalin">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil gauche</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.transparent.present==true}" (click)="addRemoveFromCristalinLeft('Transparent')">Transparent</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.aphaquie.present==true}" (click)="addRemoveFromCristalinLeft('Aphaquie')">Aphaquie</li>
                                <div *ngIf="showMoreCristalin">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.present==true}" (click)="addRemoveFromCristalinLeft('Capsule antérieure calcifiée')">Capsule antérieure calcifiée</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.capsuleantrompu.present==true}" (click)="addRemoveFromCristalinLeft('Capsule antérieure rompue')">Capsule antérieure rompue</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielGauche.cataracte.present==true}" (click)="addRemoveFromCristalinLeft('Cataracte')">Cataracte</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.corpsetranger.present==true}" (click)="addRemoveFromCristalinLeft('Corps étranger')">Corps étranger</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.ectopie.present==true}" (click)="addRemoveFromCristalinLeft('Ectopie')">Ectopie</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.fragilitezonulaire.present==true}" (click)="addRemoveFromCristalinLeft('Fragilité zonulaire')">Fragilité zonulaire</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.glaucomeflecken.present==true}" (click)="addRemoveFromCristalinLeft('Glaucome flecken')">Glaucome flecken</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.luxationSubluxation.present==true}" (click)="addRemoveFromCristalinLeft('Luxation/subluxation')">Luxation/subluxation
                                </li>
                                <div *ngIf="showMoreCristalin">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.microspheiohaquie.present==true}" (click)="addRemoveFromCristalinLeft('Microspheiohaquie')">Microspheiohaquie</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielGauche.phacosclerose.present==true}" (click)="addRemoveFromCristalinLeft('Phacosclérose')">Phacosclérose</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.phacodonesis.present==true}" (click)="addRemoveFromCristalinLeft('Phacodonésis')">Phacodonésis</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.cristallinTransparent.oielGauche.pseudophaque.present==true}" (click)="addRemoveFromCristalinLeft('Pseudophaque')">Pseudophaque</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.cristallinTransparent.oielGauche.SPE.present==true}" (click)="addRemoveFromCristalinLeft('SPE')">SPE</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorCristallin" [ngClass]="{'selectedLi': checkExistAnomalieCristallinLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromCristallinLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieCristallinLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-cristallin')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieCristallin" (keyup.enter)="addNewAnomalyCristallin($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreCristalinSection() *ngIf="!showMoreCristalin">Voir plus</span>
                            <span (click)=showMoreCristalinSection() *ngIf="showMoreCristalin">Voir moins</span>
                        </div>
                    </div>
                </div>

            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.transparent.present==true">
                        <div class="row">
                            <div class="col-md-8">
                                <h5>Transparent</h5>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.transparent.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10 " *ngIf=" examLaf?.laf.cristallinTransparent.oielDroit.pseudophaque.present==true">
                        <h5>Pseudophaque</h5>
                        <div class="row">
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.cristallinTransparent.oielDroit.pseudophaque.valeur=='Impact clair et en place'" (change)="selectpseudophaqueRight('Impact clair et en place')">
                                Impact clair et en place
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.cristallinTransparent.oielDroit.pseudophaque.valeur=='Cataracte secondaire'" (change)="selectpseudophaqueRight('Cataracte secondaire')">
                                Cataracte secondaire
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.cristallinTransparent.oielDroit.pseudophaque.valeur=='Implant décentré'" (change)="selectpseudophaqueRight('Implant décentré')">
                                Implant décentré
                            </div>

                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.pseudophaque.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10 row " *ngIf=" examLaf?.laf.cristallinTransparent.oielDroit.cataracte.present==true">
                        <h5>Cataracte</h5>
                        <div class="col-md-6 col-lg-6">
                            <label for="">Type</label>
                            <select name="" id="" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.cataracte.type">
                                <option value="type1">type 1</option>
                                <option value="type2">type 2</option>
                            </select>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <label for="">Grade</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.cataracte.grade">
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.cataracte.description">
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.aphaquie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aphaquie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.aphaquie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.microspheiohaquie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Microspheiohaquie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.microspheiohaquie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.SPE.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">SPE </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.SPE.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Capsule antérieure calcifiée </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.fragilitezonulaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fragilité zonulaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.fragilitezonulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.glaucomeflecken.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Glaucome flecken </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.glaucomeflecken.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.phacodonesis.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Phacodonésis </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.phacodonesis.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.luxationSubluxation.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Luxation/subluxation </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.luxationSubluxation.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.ectopie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Ectopie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.ectopie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf=" examLaf?.laf.cristallinTransparent.oielDroit.corpsetranger.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps étranger </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.corpsetranger.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.capsuleantrompu.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Capsule antérieure rompue </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.capsuleantrompu.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielDroit.phacosclerose.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Phacosclérose </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielDroit.phacosclerose.description">
                            </div>
                        </div>
                    </div>

                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.cristallinTransparent.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">
                                <h5>{{anomaly.titre}}</h5>
                            </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.transparent.present==true">
                        <div class="row">
                            <div class="col-md-8">
                                <h5>Transparent</h5>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.transparent.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10 " *ngIf=" examLaf?.laf.cristallinTransparent.oielGauche.pseudophaque.present==true">
                        <h5>Pseudophaque</h5>
                        <div class="row">
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.cristallinTransparent.oielGauche.pseudophaque.valeur=='Impact clair et en place'" (change)="selectpseudophaqueLeft('Impact clair et en place')">
                                Impact clair et en place
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.cristallinTransparent.oielGauche.pseudophaque.valeur=='Cataracte secondaire'" (change)="selectpseudophaqueLeft('Cataracte secondaire')">
                                Cataracte secondaire
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.cristallinTransparent.oielGauche.pseudophaque.valeur=='Implant décentré'" (change)="selectpseudophaqueLeft('Implant décentré')">
                                Implant décentré
                            </div>

                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.pseudophaque.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10 row " *ngIf=" examLaf?.laf.cristallinTransparent.oielGauche.cataracte.present==true">
                        <h5>Cataracte</h5>
                        <div class="col-md-6 col-lg-6">
                            <label for="">Type</label>
                            <select name="" id="" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.cataracte.type">
                                <option value="type1">type 1</option>
                                <option value="type2">type 2</option>
                            </select>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <label for="">Grade</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.cataracte.grade">
                        </div>
                        <div class="col-12 mt-2">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.cataracte.description">
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.aphaquie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aphaquie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.aphaquie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.microspheiohaquie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Microspheiohaquie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.microspheiohaquie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.SPE.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">SPE </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.SPE.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Capsule antérieure calcifiée </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.fragilitezonulaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fragilité zonulaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.fragilitezonulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.glaucomeflecken.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Glaucome flecken </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.glaucomeflecken.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.phacodonesis.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Phacodonésis </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.phacodonesis.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.luxationSubluxation.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Luxation/subluxation </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.luxationSubluxation.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.ectopie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Ectopie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.ectopie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf=" examLaf?.laf.cristallinTransparent.oielGauche.corpsetranger.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps étranger </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.corpsetranger.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.capsuleantrompu.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Capsule antérieure rompue </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.capsuleantrompu.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.cristallinTransparent.oielGauche.phacosclerose.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Phacosclérose </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.cristallinTransparent.oielGauche.phacosclerose.description">
                            </div>
                        </div>
                    </div>

                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.cristallinTransparent.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">
                                <h5>{{anomaly.titre}}</h5>
                            </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>