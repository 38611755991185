import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { ConsultationService } from '../../../../../services/consultation/consultation.service';
import { Router, ActivatedRoute } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../../config/API_CONFIG';
import { AuthService } from '../../../../../services/auth/auth.service';
import { PatientService } from '../../../../../services/profils/patient/patient.service';
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { MedecinService } from '../../../../../services/profils/medecin/medecin.service';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { DocumentService } from '../../../../../services/document/document.service';
@Component({
  selector: 'app-update-document-patient',
  templateUrl: './update-document-patient.component.html',
  styleUrls: ['../../../../login/login-medecin/login-medecin.component.css','../../../../register/register/register.component.scss', '../ajout-document-patient/ajout-document-patient.component.scss'],
})
export class UpdateDocumentPatientComponent implements OnInit {

  documents: any[] = [];
  medecin: any;
  interval: any;
  messages: any[] = [];
  secretaire: any;
  ordonnances: any[] = [];
  certificats: any[] = [];
  medecins: any[] = []
  patient: any;
  sizeLimitOne = 10000000; //10MB
  public loading = false;
  selectedFiles: any;
  errofile = false;
  myId: any;
  form: any;
  showedValue: any;
  selectedMedecinn: any;
  uploadedFiles:any;
  mydocumentPatient:any;
  editmode = false
  errorObligatoire = ""
  mesesultats:any;
  lengthMedecin: any;
  pageNumber = 0
  staticMedecinscraped: any[] = [];
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.document) + '/medecin/patient' });
  constructor(private documentService:DocumentService,private notificationService:NotificationService,private _sanitizer: DomSanitizer,private medecinService:MedecinService,private formBuilder: FormBuilder, private router: Router, private consultationService: ConsultationService, public http: HttpClient,
    private activatedRoute: ActivatedRoute, private authService: AuthService, private patientService: PatientService,
  ) {
    this.form = this.formBuilder.group({
      type: ['', [Validators.required]],
      date: ['', [Validators.required]],
      statut: ['', [Validators.required]],
      resultat: [''],
      demandePar: [''],
      service: [''],
      autoriserAcces: [''],
    })
  }
  ngOnInit() {
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.myId = medecinId
      this.authService.getProfileMedecin().subscribe((profile:any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;
      this.myId = medecinId
      this.authService.getProfileSecretaire().subscribe((profile:any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste'])
        return false;
      });

      this.medecinService.getMedecinScapedcount().subscribe(length => {
      this.lengthMedecin = length;
    },
      err => {
        return false;
      });
      this.medecinService.getMedecinAccepted().subscribe((medecins:any[]) => {
        this.medecinService.getMedecinScapedBypage(this.pageNumber, 2000).subscribe((medecinsss:any[]) => {
          this.medecins = medecins.concat(medecinsss);
          this.staticMedecinscraped = medecinsss
          this.suivreMedecin(this.pageNumber + 1)
          //console.log(this.medecins.length)
        },
          err => {
            return false;
          });

      },
        err => {
          console.log(err);
          return false;
        });

        this.documentService.getByPatientDocuement(params.idDocument)
          .subscribe(data => {
            this.mydocumentPatient = data
            if (this.mydocumentPatient.demandePar && this.mydocumentPatient.demandePar.idMedecin) {
              if (!this.mydocumentPatient.demandePar.prenom) {
                this.showedValue = this.mydocumentPatient.demandePar.nom + ' ' + this.mydocumentPatient.demandePar.specialite
              }
              if (this.mydocumentPatient.demandePar.prenom) {
                this.showedValue = this.mydocumentPatient.demandePar.nom + ' ' + this.mydocumentPatient.demandePar.prenom + ' ' + this.mydocumentPatient.demandePar.specialite
              }
            }
          });
  }

  suivreMedecin(counter) {
    if (this.lengthMedecin === this.staticMedecinscraped.length) {
      return true;
    }
    this.medecinService.getMedecinScapedBypage(counter, 2000).subscribe((medecinsss:any[]) => {
      this.medecins.push(...medecinsss)
      this.staticMedecinscraped.push(...medecinsss)
      //this.mesMedecins.push(...medecinsss);
      counter++
      setTimeout(() => {    //<<<---    using ()=> syntax
        this.suivreMedecin(counter);
      }, 1000);
      return true;
    },
      err => {
        return false;
      });
  }

  selectFile(event) {
    const file = this.uploader.queue
    this.uploader.options.additionalParameter = {
      medecinId: this.myId,
      patientId: this.patient._id
    };
    if (file) {
      for (var j = 0; j < file.length; j++) {
        if (file[j]) {
          if (!file[j].file.type.match('image.*|application/pdf')) {
            event = undefined
            this.uploader.queue.length = 0
            alert("Impossible de télécharger ce fichier.")
            return false;
          }
          if (file[j].file.size > this.sizeLimitOne) {
            event = undefined
            this.uploader.queue.length = 0
            alert("Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo.")
            return false;
          }
          var extension = file[j].file.name.split('.').pop()
          var token = this.generate_token(20)
          file[j].file.name = this.patient._id + token + '.' + extension
        }
      }
      if (file.length > 3) {
        event = undefined
        this.uploader.queue.length = 0
        alert("Séléctionner seulement 3 fichiers ")
        return false;
      }

    } else {
      return false;
    }
  }

  generate_token(length) {
    //edit the token allowed characters
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];
    for (var i = 0; i < length; i++) {
      var j = (Math.random() * (a.length - 1)).toFixed(0);
      b[i] = a[j];
    }
    return b.join("");
  }
  refresh() {
    var me = this;
    this.loading = true;
    setTimeout(function() {
      me.router.navigate(['/medecin/consultation-liste', me.patient._id]); this.loading = false;
      Swal.fire({
        imageUrl: 'assets/images/patsDetails/plus-3.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 600,
        title: 'BIEN AJOUTÉ',
        timer: 1500,
        showConfirmButton: false
      }
      );
    }, 5000);
  }
  updateDocuments(mydocumentPatient){
    if(!mydocumentPatient.type || mydocumentPatient.type == "" || !mydocumentPatient.date || mydocumentPatient.date == "" || !mydocumentPatient.statut || mydocumentPatient.statut == ""){
      this.errorObligatoire = "* Des champs obligatoires"
      return false;
    }
    this.errorObligatoire = ""
    if(mydocumentPatient.autoriserAcces == ""){
      mydocumentPatient.autoriserAcces = false
    }
    if(this.selectedMedecinn){
      mydocumentPatient.demandePar = {
        "nom":this.selectedMedecinn.nom,
        "prenom":this.selectedMedecinn.prenom,
        "specialite":this.selectedMedecinn.specialite,
        "idMedecin":this.selectedMedecinn._id
      }
    }
    this.editmode = true
    if(this.uploadedFiles && this.uploadedFiles.length >0){
      mydocumentPatient.statut = "Prêt"
      mydocumentPatient.ajoutele = new Date().toISOString()
      let formData = new FormData();
      for (var i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
          if(i == 0){
            formData.append("infopatientmedecin", JSON.stringify(mydocumentPatient));
          }

      }
      this.http.post(_getURL(API_CONFIG.document) + '/medecin/patient/upload/files/update', formData)
      .subscribe((response) => {
        var notification = {
          texte: 'Le résultat de votre ' + mydocumentPatient.type.toLowerCase() + " effectué le "+ mydocumentPatient.date.substr(8, 2)+ "/"+mydocumentPatient.date.substr(5, 2)+"/"+mydocumentPatient.date.substr(0, 4) +" chez le médecin " +mydocumentPatient.auteur.nom + " " + mydocumentPatient.auteur.prenom + " est " + mydocumentPatient.statut.toLowerCase() ,
          lien: 'documents',
          patientId: this.patient._id
        }
        if(mydocumentPatient.demandePar && mydocumentPatient.demandePar.idMedecin){
        var notificationMedecin = {
          texte: 'Le résultat de ' + mydocumentPatient.type.toLowerCase() + " de votre patient " + this.patient.nom + " " + this.patient.prenom + " effectué le "+ mydocumentPatient.date.substr(8, 2)+ "/"+mydocumentPatient.date.substr(5, 2)+"/"+mydocumentPatient.date.substr(0, 4) +" chez le médecin " +mydocumentPatient.auteur.nom + " " + mydocumentPatient.auteur.prenom + " est " + mydocumentPatient.statut.toLowerCase() ,
          lien: 'medecin/patients-liste',
          medecinId: mydocumentPatient.demandePar.idMedecin
        }
        this.notificationService.addNotification(notificationMedecin).subscribe(notification => {
        });
        }
        this.notificationService.addNotification(notification).subscribe(notification => {
          Swal.fire( {
              imageUrl: 'assets/images/patsDetails/plus-3.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 600,
              title: 'BIEN MODIFIÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.form.reset()
          this.router.navigate(['/medecin/consultation-liste', this.patient._id]);
        });

      })
    }
    if(!this.uploadedFiles||(this.uploadedFiles && this.uploadedFiles.length <1)){
      this.documentService.onUpdateDocuementPatient(mydocumentPatient)
        .subscribe(data => {
          var notification = {
            texte: 'Le résultat de votre ' + mydocumentPatient.type.toLowerCase() + " effectué le "+ mydocumentPatient.date.substr(8, 2)+ "/"+mydocumentPatient.date.substr(5, 2)+"/"+mydocumentPatient.date.substr(0, 4) +" chez le médecin " +mydocumentPatient.auteur.nom + " " + mydocumentPatient.auteur.prenom + " est " + mydocumentPatient.statut.toLowerCase() ,
            lien: 'documents',
            patientId: this.patient._id
          }
          if(mydocumentPatient.demandePar && mydocumentPatient.demandePar.idMedecin){
          var notificationMedecin = {
            texte: 'Le résultat de ' + mydocumentPatient.type.toLowerCase() + " de votre patient " + this.patient.nom + " " + this.patient.prenom + " effectué le "+ mydocumentPatient.date.substr(8, 2)+ "/"+mydocumentPatient.date.substr(5, 2)+"/"+mydocumentPatient.date.substr(0, 4) +" chez le médecin " +mydocumentPatient.auteur.nom + " " + mydocumentPatient.auteur.prenom + " est " + mydocumentPatient.statut.toLowerCase() ,
            lien: 'medecin/patients-liste',
            medecinId: mydocumentPatient.demandePar.idMedecin
          }
          this.notificationService.addNotification(notificationMedecin).subscribe(notification => {
          });
          }
          this.notificationService.addNotification(notification).subscribe(notification => {
            Swal.fire( {
                imageUrl: 'assets/images/patsDetails/plus-3.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 600,
                title: 'BIEN MODIFIÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.form.reset()
            this.router.navigate(['/medecin/consultation-liste', this.patient._id]);
          });
        });
    }
  }
  myCallback(event) {
    if (event._id) {
      this.selectedMedecinn = event
      if (!event.prenom) {
        this.showedValue = event.nom + ' ' + event.specialite
      }
      if (event.prenom) {
        this.showedValue = event.nom + ' ' + event.prenom + ' ' + event.specialite
      }
    }
  }
  autocompleListFormatterPrenom = (data: any): SafeHtml => {
    if (data.nom != undefined && data.prenom != undefined) {
      var html = `<span>${data.nom} ${data.prenom}</span>`;
    }
    if (data.prenom == undefined) {
      var html = `<span>${data.nom}</span>`;
    }
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  fileChange(element) {
    if(element.target.files){
      for (var j = 0; j < element.target.files.length; j++) {
        if (element.target.files[j]) {
          if (!element.target.files[j].type.match('image.*|application/pdf')) {
            this.uploadedFiles = undefined
            this.mesesultats = undefined
            Swal.fire({
              imageUrl: 'assets/images/patsDetails/error.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'Impossible de télécharger ce fichier !',
              timer: 3000,
              showConfirmButton: false
            }
            );
            return false;
          }
          if (element.target.files[j].size > this.sizeLimitOne) {
            this.uploadedFiles = undefined
            this.mesesultats = undefined
            Swal.fire({
              imageUrl: 'assets/images/patsDetails/error.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 800,
              title: 'Un fichier sélectionné est trop volumineux.La taille maximale est 10 Mo !',
              timer: 3000,
              showConfirmButton: false
            }
            );
            return false;
          }
        }
      }
      if (element.target.files.length > 5) {
        this.uploadedFiles = undefined
        this.mesesultats = undefined
        Swal.fire({
          imageUrl: 'assets/images/patsDetails/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'Séléctionnez seulement 5 fichiers !',
          timer: 3000,
          showConfirmButton: false
        }
        );
        return false;
      }
    }
      this.uploadedFiles = element.target.files;
  }
}

