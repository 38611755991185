import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';

@Injectable()
export class MedicamentsService {

  constructor(private http: HttpClient) {
  }

  getAlluniqueNomMedicaments(idMedecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medicament) + '/get/all/distinct/'+idMedecin, { headers: headers })
      
  }
  getConseilByID(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medicament) + '/' + id, { headers: headers })
      
  }
  getMedicamentByNom(nom){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
      return this.http.post(_getURL(API_CONFIG.medicament)+'/by/nom/search/sensetive', (nom), { headers: headers })
        
  }
  enregistrerMed(med){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medicament), (med), { headers: headers })
      
  }
  getAllKnowMedicaments(){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
  return this.http.get(_getURL(API_CONFIG.medicament)+'/display/allKnown', {headers: headers})
      
  }
}
