import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { _getURL } from 'config/API_CONFIG';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExamenService } from 'src/app/services/examen/examen.service';

@Component({
  selector: 'app-lentille',
  templateUrl: './lentille.component.html',
  styleUrls: ['./lentille.component.css', '../impression-cat.component.css']
})
export class LentilleComponent implements OnInit {
  myExamen: any;
  today = new Date().toISOString().slice(0, 10)
  medcin: any;
  myId: any
  unSpec: boolean = false
  pathImageLogo: any
  constructor(private examenService: ExamenService, private router: Router, private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit(): void {
    this.today = new Date().toISOString().slice(0, 10)
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    const idcat = this.route.snapshot.paramMap.get('idCat');
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getExamenByIdForCatImpression(idExamen, idcat).subscribe((data: any) => {
        this.myExamen = data
        if (!this.myExamen || (this.myExamen && !this.myExamen.foundImpression) || (this.myExamen && this.myExamen.foundImpression && !this.myExamen.foundImpression._id)) {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        }
        if (this.myExamen.foundImpression && !this.myExamen.foundImpression.titrePrecrire) {
          this.myExamen.foundImpression.titrePrecrire = "ordonnance"
        }
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    }
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.myId = medecinId
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medcin = profile.medecin;
        if (this.medcin.prenom.trim() === "Omar" && this.medcin.nom.trim() === "Ben Haj Said" && this.medcin.specialite == "Ophtalmologiste") {
          this.unSpec = true
        } else {
          this.unSpec = false
        }
        if (this.medcin.logo) {
          this.pathImageLogo = _getURL("") + "data/files/cabinet/" + this.medcin.logo
        }
        if (!this.medcin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
  }
}
