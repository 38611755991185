<div id="navbarSupportedContent">
</div>

<div class="row p-r-t-40">
  <br><br>
  <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12" id="menu">
    <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveInspection}" (click)="blocInspection()">Inspection</p>
    <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveAnnexe}" (click)="blocAnnexe()">Annexe</p>
    <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveRefraction}" *ngIf="subMenu" (click)="blocRefraction()">Réfraction <Span style="font-size: 0.8rem;"> ▲</Span> </p>
    <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveRefraction}" *ngIf="!subMenu" (click)="blocRefraction()">Réfraction <Span style="font-size: 0.8rem;"> ▼</Span></p>
    <div *ngIf="subMenu">
      <p id="menu-title" [ngClass]="{'menu-title': isActiveRefractionsubjective}" (click)="blocRefractionSubjective()">Réfraction subjective</p>
      <p id="menu-title" [ngClass]="{'menu-title': isActiveacuite}" (click)="blocAcuiteVis()">Acuité Visuel / Réfraction finale</p>
    </div>
    <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveLaf}" (click)="blocLaf()">Lampe à fente</p>
  </div>
  <!--start Inspection-->
  <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 questions-zone" id="content" *ngIf="isActiveInspection && myExamen">
    <!-- historique -->
    <div class="card">
      <div class="card-body-historique ">
        <h3 class="card-title-historique">Historique inspection</h3>
        <div class="arrow arrowBlack btnhistoriquesInspection mb-3" *ngIf="displayHistorique" title="Cacher la liste des historiques" (click)="displayAndHideHistorique()">▲</div>
        <div class="arrow arrowBlack btnhistoriquesInspection mb-3" *ngIf="!displayHistorique" title="Afficher la liste des historiques" (click)="displayAndHideHistorique()">▼</div>

        <div class="menu1 " *ngIf="displayHistorique">
          <div *ngIf="oldListConsultations.length==0">
            <h6 class="badge-info-dates mt-2">Aucun historique n'est trouvé</h6>
          </div>
          <div class="pagination-container" *ngIf="oldListConsultations.length>0">

            <button class="pagination-arrow" (click)="previousPage()" [disabled]="p === 1">&#8249;</button>
            <ul class="date-list">
              <li class="badge-info-dates" (click)=" openCons(paginatedList[0])" title="Voir détaille de la consultation  {{  paginatedList[0]?.start | date:'dd/MM/yyyy' }}">
                {{ paginatedList[0]?.start | date:'dd/MM/yyyy' }}
              </li>
            </ul>
            <button class="pagination-arrow" (click)="nextPage()" [disabled]="p === oldListConsultations.length">&#8250;</button>
          </div>

          <div class="info mb-3" *ngIf="selectedHistorique">
            <div class="card1">
              <div class="card-body-historique">

                <div *ngIf="selectedHistorique.inspection && selectedHistorique.inspection.anomaly && selectedHistorique.inspection.anomaly.present" class="mb-3">
                  <div class="row">
                    <div class="col-md-6 ">
                      <div class="cadreOeilDroitHistory">
                        <div class="oeilTitreSection">
                          <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                          <h5 id="OeilTitle">Oeil droit</h5>
                        </div>
                        <ul id="suboeilHistorySection" *ngIf="selectedHistorique.inspection && selectedHistorique.inspection.anomaly && selectedHistorique.inspection.anomaly.present">
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.normale.present">Normale</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.attitudedemalvoyance.present">Attitude de malvoyance</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.buphtalmie.present">Buphtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.exphtalmie.present">Exphtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.enophtalmie.present">Énophtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.microphtalmie.present">Microphtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.leucocorie.present">Leucocorie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.protheseoculaire.present">Prothèse oculaire</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.plaies.present">Plaies</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.paralysiefaciale.present">Paralysie faciale</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.ptosis.present">Ptosis</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielDroit.strabisme.present">Strabisme</li>
                          <li class="badge badge-info-anomalies mb-1" *ngFor="let anomaly of selectedHistorique.inspection.anomaly.oielDroit.allAnomalies">{{anomaly.titre}}</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6 ">
                      <div class="cadreOeilGaucheHistory">
                        <div class="oeilTitreSection">
                          <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                          <h5 id="OeilTitle">Oeil gauche</h5>
                        </div>
                        <ul id="suboeilHistorySection" *ngIf="selectedHistorique.inspection && selectedHistorique.inspection.anomaly && selectedHistorique.inspection.anomaly.present">
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.normale.present">Normale</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.attitudedemalvoyance.present">Attitude de malvoyance</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.buphtalmie.present">Buphtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.exphtalmie.present">Exphtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.enophtalmie.present">Énophtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.microphtalmie.present">Microphtalmie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.leucocorie.present">Leucocorie</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.protheseoculaire.present">Prothèse oculaire</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.plaies.present">Plaies</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.paralysiefaciale.present">Paralysie faciale</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.ptosis.present">Ptosis</li>
                          <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.inspection.anomaly.oielGauche.strabisme.present">Strabisme</li>
                          <li class="badge badge-info-anomalies mb-1" *ngFor="let anomaly of selectedHistorique.inspection.anomaly.oielGauche.allAnomalies">{{anomaly.titre}}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- end of historique -->
    <br>

    <div class="card">
      <div class="card-body">
        <div *ngIf="todayExamen">
          <h3 class="card-title-historique">Examen d'aujourd'hui (par défaut normal)</h3>
          <div class="arrow arrowBlack btnhistoriquesRefraction-open mb-3" *ngIf="todayExamen" title="Cacher examen d'aujourd'hui" (click)="displayAndHidetodayExamen()">▲</div>
        </div>
        <div *ngIf="!todayExamen">
          <h3 class="card-title-historique">Examen d'aujourd'hui</h3>
          <div class="arrow arrowBlack btnhistoriquesRefraction mb-3" *ngIf="!todayExamen" title="Afficher examen d'aujourd'hui" (click)="displayAndHidetodayExamen()">▼</div>
        </div>
        <br />
        <div *ngIf="todayExamen">
          <!-- <h3 class="card-title-historique">Inspection</h3> -->
          <div class="col-12 sansCyclo">
            <div class="cadreOeilDroit">
              <div class="oeilTitreSection">
                <img src="assets/images/oeil.png" style="position: relative;top: 1px;" alt="" class="img-oeil">
                <h5 id="OeilTitle">Oeil droit</h5>
              </div>
              <div class="row" style="margin-top: 8px;">
                <br>
                <div class="col-md-6">
                  <ul>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.normale.present==true}" (click)="addRemoveFromAnomalieRight('Normale')">Normale</li>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.attitudedemalvoyance.present==true}" (click)="addRemoveFromAnomalieRight('Attitude de malvoyance')">Attitude de malvoyance</li>
                    <div *ngIf="showMore">
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.buphtalmie.present==true}" (click)="addRemoveFromAnomalieRight('Buphtalmie')">Buphtalmie</li>
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.exphtalmie.present==true}" (click)="addRemoveFromAnomalieRight('Exphtalmie')">Exphtalmie</li>

                      <li [ngClass]="{'selectedLi': myExamen.inspection.anomaly.oielDroit.enophtalmie.present==true}" (click)="addRemoveFromAnomalieRight('Énophtalmie')">Énophtalmie</li>
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.microphtalmie.present==true}" (click)="addRemoveFromAnomalieRight('Microphtalmie')">Microphtalmie</li>
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.leucocorie.present==true}" (click)="addRemoveFromAnomalieRight('Leucocorie')">Leucocorie</li>
                    </div>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.protheseoculaire.present==true}" (click)="addRemoveFromAnomalieRight('Prothèse oculaire')">Prothèse oculaire</li>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.plaies.present==true}" (click)="addRemoveFromAnomalieRight('Plaies')">Plaies</li>
                    <div *ngIf="showMore">
                      <li [ngClass]="{'selectedLi': myExamen.inspection.anomaly.oielDroit.paralysiefaciale.present==true}" (click)="addRemoveFromAnomalieRight('Paralysie faciale')">Paralysie faciale</li>
                      <li [ngClass]="{'selectedLi': myExamen.inspection.anomaly.oielDroit.ptosis.present==true}" (click)="addRemoveFromAnomalieRight('Ptosis')">Ptosis</li>

                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielDroit.strabisme.present==true}" (click)="addRemoveFromAnomalieRight('Strabisme')">Strabisme</li>
                      <!-- dynamic anamaly -->
                      <li *ngFor="let anomaly of anomaliesByDoctor" [ngClass]="{'selectedLi': checkExistAnmalieRight(anomaly.id)}">
                        <span (click)="addRemoveFromAnomalieRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                        <img src="assets/images/delete.png" *ngIf="!checkExistAnmalieRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'inspection')" title="Supprimer l'anomalie" class="del-anomalie">
                      </li>
                      <div>
                        <input type="text" placeholder="Ajouter une autre anomalie" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalie" (keyup.enter)="addNewAnomaly($event)" [disabled]="isToastVisible">
                      </div>
                      <!-- dynamic anamaly -->
                    </div>
                  </ul>
                </div>
                <br><br>
              </div>
              <div class="showmore">
                <span (click)=showMoreAnomalies() *ngIf="!showMore">Voir plus</span>
                <span (click)=showMoreAnomalies() *ngIf="showMore">Voir moins</span>
              </div>
            </div>
            <div class="cadreOeilGauche">
              <div class="oeilTitreSection">
                <img src="assets/images/oeil.png" style="position: relative;top: 1px;" class="img-oeil">
                <h5 id="OeilTitle">Oeil gauche</h5><br>
              </div>
              <div class="row" style="margin-top: 8px;">
                <br>
                <div class="col-md-6">
                  <ul>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.normale.present==true}" (click)="addRemoveFromAnomalieLef('Normale')">Normale</li>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.attitudedemalvoyance.present==true}" (click)="addRemoveFromAnomalieLef('Attitude de malvoyance')">Attitude de malvoyance</li>
                    <div *ngIf="showMore">
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.buphtalmie.present==true}" (click)="addRemoveFromAnomalieLef('Buphtalmie')">Buphtalmie</li>
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.exphtalmie.present==true}" (click)="addRemoveFromAnomalieLef('Exphtalmie')">Exphtalmie</li>

                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.enophtalmie.present==true}" (click)="addRemoveFromAnomalieLef('Énophtalmie')">Énophtalmie</li>
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.microphtalmie.present==true}" (click)="addRemoveFromAnomalieLef('Microphtalmie')">Microphtalmie</li>
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.leucocorie.present==true}" (click)="addRemoveFromAnomalieLef('Leucocorie')">Leucocorie</li>
                    </div>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.protheseoculaire.present==true}" (click)="addRemoveFromAnomalieLef('Prothèse oculaire')">Prothèse oculaire</li>
                    <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.plaies.present==true}" (click)="addRemoveFromAnomalieLef('Plaies')">Plaies</li>
                    <div *ngIf="showMore">
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.paralysiefaciale.present==true}" (click)="addRemoveFromAnomalieLef('Paralysie faciale')">Paralysie faciale</li>
                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.ptosis.present==true}" (click)="addRemoveFromAnomalieLef('Ptosis')">Ptosis</li>

                      <li [ngClass]="{'selectedLi':  myExamen.inspection.anomaly.oielGauche.strabisme.present==true}" (click)="addRemoveFromAnomalieLef('Strabisme')">Strabisme</li>
                      <!-- dynamic anamaly -->
                      <li *ngFor="let anomaly of anomaliesByDoctor" [ngClass]="{'selectedLi': checkExistAnmalieLeft(anomaly.id)}">
                        <span (click)="addRemoveFromAnomalieLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                        <img src="assets/images/delete.png" *ngIf="!checkExistAnmalieLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'inspection')" title="Supprimer l'anomalie" class="del-anomalie">
                      </li>
                      <div>
                        <input type="text" placeholder="Ajouter une autre anomalie" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieGauche" (keyup.enter)="addNewAnomaly($event)" [disabled]="isToastVisible">
                      </div>
                      <!-- dynamic anamaly -->
                    </div>
                  </ul>
                </div>
                <br><br>
              </div>
              <div class="showmore">
                <span (click)=showMoreAnomalies() *ngIf="!showMore">Voir plus</span>
                <span (click)=showMoreAnomalies() *ngIf="showMore">Voir moins</span>
              </div>
            </div>
          </div>
          <br>
          <div class="row blocinputs" *ngIf="myExamen.inspection.anomaly.present" @fade>
            <div class="col-md-6">
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.normale.present==true">
                <div class="col-lg-4 col-md-4">
                  <h5 class="titleDetails">Normale</h5>
                </div>
                <div class="col-lg-8 col-md-8">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.normale.description" />
                </div>
              </div>
              <div class="row  margin-t-10" *ngIf="myExamen.inspection.anomaly.oielDroit.attitudedemalvoyance.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Attitude de malvoyance</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type=" text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.attitudedemalvoyance.description" />
                </div>
              </div>
              <div class="row  margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.buphtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Buphtalmie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.buphtalmie.description" />
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.exphtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Exphtalmie</h5>
                </div>

                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.exphtalmie.description" />
                </div>

              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.enophtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Énophtalmie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.enophtalmie.description">
                </div>

              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.microphtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Microphtalmie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.microphtalmie.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf="myExamen.inspection.anomaly.oielDroit.leucocorie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Leucocorie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.leucocorie.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.protheseoculaire.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Prothèse oculaire</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.protheseoculaire.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.plaies.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Plaies</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.plaies.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielDroit.paralysiefaciale.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Paralysie faciale</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.paralysiefaciale.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf="myExamen.inspection.anomaly.oielDroit.ptosis.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Ptosis</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.ptosis.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf="myExamen.inspection.anomaly.oielDroit.strabisme.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Strabisme</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielDroit.strabisme.description">
                </div>

              </div>
              <!-- dynamique anomalies-->
              <div class="row margin-t-10" *ngFor="let anomaly of myExamen.inspection.anomaly.oielDroit.allAnomalies">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">{{anomaly.titre}}</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                </div>
              </div>
              <!-- dynamique anomalies-->
              <div class="row emptyBloc">
              </div>
            </div>
            <div class="col-md-6">
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.normale.present==true">
                <div class="col-lg-4 col-md-4">
                  <h5 class="titleDetails">Normale</h5>
                </div>
                <div class="col-lg-8 col-md-8">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.normale.description" />
                </div>
              </div>
              <div class="row  margin-t-10" *ngIf="myExamen.inspection.anomaly.oielGauche.attitudedemalvoyance.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Attitude de malvoyance</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type=" text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.attitudedemalvoyance.description" />
                </div>
              </div>
              <div class="row  margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.buphtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Buphtalmie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.buphtalmie.description" />
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.exphtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Exphtalmie</h5>
                </div>

                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.exphtalmie.description" />
                </div>

              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.enophtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Énophtalmie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.enophtalmie.description">
                </div>

              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.microphtalmie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Microphtalmie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.microphtalmie.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf="myExamen.inspection.anomaly.oielGauche.leucocorie.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Leucocorie</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.leucocorie.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.protheseoculaire.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Prothèse oculaire</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.protheseoculaire.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.plaies.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Plaies</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.plaies.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf=" myExamen.inspection.anomaly.oielGauche.paralysiefaciale.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Paralysie faciale</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.paralysiefaciale.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf="myExamen.inspection.anomaly.oielGauche.ptosis.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Ptosis</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.ptosis.description">
                </div>
              </div>
              <div class="row margin-t-10" *ngIf="myExamen.inspection.anomaly.oielGauche.strabisme.present==true">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">Strabisme</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new  " placeholder="Ajouter une description..." [(ngModel)]="myExamen.inspection.anomaly.oielGauche.strabisme.description">
                </div>

              </div>
              <!-- dynamique anomalies-->
              <div class="row margin-t-10" *ngFor="let anomaly of myExamen.inspection.anomaly.oielGauche.allAnomalies">
                <div class="col-lg-4 col-md-12">
                  <h5 class="titleDetails">{{anomaly.titre}}</h5>
                </div>
                <div class="col-lg-8 col-md-12">
                  <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                </div>
              </div>
              <!-- dynamique anomalies-->
              <div class="row emptyBloc">
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-center" style="border:none;">
      <div class=" col d-flex justify-content-center buttonsPopup">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td class="tdButt1 ">
                <button class="btn-next" (click)="clicktbnsuivantInspection(myExamen)"><img src="assets/images/next.png" class="iconBoutton1"></button>
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div>

  </div>

  <!-- END Inspection-->
  <!-- start Réfraction-->
  <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 questions-zone " id="content" *ngIf="isActiveRefraction">

    <app-refraction [todayExamenRef]="todayExamenRef" [showRefSubjective]="showRefSubjective" [showAcuite]="showAcuite" (sendAcuitValues)="receiveAcuiteValuesFromChild($event)" (sendRefValues)="receiveRefSubValuesFromChild($event)" (sendTodayExam)="receiveTodayExamFromChild($event)"></app-refraction>
    <div class="modal-footer d-flex justify-content-center" style="border:none;">
      <div class=" col d-flex justify-content-center buttonsPopup">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td class="tdButt2">
                <button class="btn-previous" (click)="clicktbnpredentRefraction()"><img src="assets/images/retour.png" class="iconBoutton1"></button>
              </td>
              <td class="tdButt1 ">
                <button class="btn-next" (click)="clicktbnsuivantRefraction()"><img src="assets/images/next.png" class="iconBoutton1"></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>
  <!-- END Réfraction-->
  <!-- start Annexe-->
  <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 questions-zone" id="content" *ngIf="isActiveAnnexe">
    <app-annexe></app-annexe>
    <div class="modal-footer d-flex justify-content-center" style="border:none;">
      <div class=" col d-flex justify-content-center buttonsPopup">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>
              <td class="tdButt2">
                <button class="btn-previous" (click)="clicktbnpredentAnnexe()"><img src="assets/images/retour.png" class="iconBoutton1"></button>
              </td>
              <td class="tdButt1">
                <button class="btn-next" (click)="clicktbnsuivantAnnexe()"><img src="assets/images/next.png" class="iconBoutton1"></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- END Annexe-->
  <!--start Lampe à fente-->
  <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 questions-zone" id="content" *ngIf="isActiveLaf">
    <app-laf></app-laf>
    <div class="modal-footer d-flex justify-content-center" style="border:none;">
      <div class=" col d-flex justify-content-center buttonsPopup">
        <div class="col-auto nbr-dossier ">
          <table>
            <tr>

              <td class="tdButt2">
                <button class="btn-previous" (click)="clicktbnpredentLaf()"><img src="assets/images/retour.png" class="iconBoutton1"></button>
              </td>
              <td class="tdButt1 ">
                <button class="btn-next" (click)="clicktbnEnregitrerLaf()"><img src="assets/images/save.png" class="iconBoutton1"></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- END Lampe à fente-->
</div>