import {  AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ChatService } from 'src/app/services/chat/chat.service';

@Component({
  selector: 'app-messagerie',
  templateUrl: './messagerie.component.html',
  styleUrls: ['./messagerie.component.css']
})
export class MessagerieComponent {
// export class MessagerieComponent implements OnInit, AfterViewInit {

  // @ViewChild('popup', { static: false }) popup: any;
  // @ViewChild('chatBody', { static: false }) chatBodyRef: ElementRef;

  // public roomId: string;
  // public messageText: any;
  // public messageArray: { user: string, message: string }[] = [];
  // private storageArray = [];

  // public showScreen = false;
  // public phone: string;
  // public currentUser;
  // public selectedUser;
  // notif;

  // public userList: any[] = [];
  // searchResults: any;
  // searchTerm: any;
  // copyListUsers: any;
  // notifications: any[];

  // constructor(
  //   private modalService: NgbModal,
  //   private chatService: ChatService,
  //   public iziToast: Ng2IzitoastService
  // ) { }

  // ngOnInit(): void {
  //   this.chatService.getlistUsers().subscribe(users => {
  //     this.userList = users;
  //     this.copyListUsers = this.userList;
    
  //     // Vérifiez si `userList` n'est pas vide avant de procéder
  //     if (this.userList && this.userList.length > 0) {
  //       let userCourant = this.userList.find(user => user && user.id && user.id.toString() === "1");
    
  //       // Continuez avec le reste de votre logique
  //       this.chatService.getMessage().subscribe((data: { user: string, room: string, message: string }) => {
  //         setTimeout(() => {
  //           const chatBody = document.querySelector('.chat-body');
  //           if (chatBody) {
  //             chatBody.scrollTop = chatBody.scrollHeight;
  //           }
  //         }, 1000);
  //         if (this.roomId) {
  //           setTimeout(() => {
  //             this.chatService.getMessagesByRomm(this.roomId).subscribe(
  //               (data) => {
  //                 this.messageArray = data;
  //                 if (this.messageArray && this.messageArray.length > 0 && this.messageArray[this.messageArray.length - 1].user != this.currentUser.nom) {
  //                   this.chatService.changeStatutHasNewMessageToSeen(this.roomId).subscribe();
  //                 }
  //               },
  //               (error) => {
  //                 console.error('Erreur lors du chargement des messages:', error);
  //               }
  //             );
  //           }, 500);
  //         }
  //       });
    
  //       this.chatService.getNotification().subscribe((data: { user: string, message: string }) => {
  //         console.log("this.roomId", this.roomId);
  //         console.log("data", data);
  //         this.notif = data;
  //         if (this.roomId) {
  //           let displayMessage = data.message.length > 8 ? `${data.message.substring(0, 10)}...` : data.message;
    
  //           this.iziToast.show({
  //             title: 'Nouveau message',
  //             message: `de ${data.user}: ${displayMessage}`,
  //             position: 'bottomRight',
  //             timeout: 6000,
  //             color: 'white',
  //             icon: 'fa fa-envelope',
  //             close: true,
  //           });
  //         }
  //       });
    
  //       if (!localStorage.getItem('user')) {
  //         this.openPopup(this.popup);
  //       } else {
  //         this.currentUser = this.userList.find(user => user._id.toString() === localStorage.getItem('user'));
  //         const currentUserID = localStorage.getItem('user');
    
  //         this.userList = this.userList.filter((user) => user._id.toString() !== currentUserID);
  //         this.selectUserHandler(this.userList[0].phone);
    
  //         this.userList.forEach(async (user) => {
  //           for (let key in user.roomId) {
  //             try {
  //               const data = await this.chatService.firstMessageSended(user.roomId[key]).toPromise();
  //               if (data) {
  //                 const roomId = user.roomId[key];
  //                 const lastMsgIndex = user.lastMsgs.findIndex(msg => msg.hasOwnProperty(roomId));
  //                 if (lastMsgIndex !== -1 && this.currentUser.nom != data.user) {
  //                   user.lastMsgs[lastMsgIndex][roomId] = data;
  //                 }
  //               }
  //             } catch (error) {
  //               console.error('Error fetching last message:', error);
  //             }
  //           }
  //         });
    
  //         setTimeout(() => {
  //           this.showScreen = true;
  //         });
  //       }
  //     } else {
  //       console.error('La liste des utilisateurs est vide ou non définie.');
  //     }
  //   });
    

  // }

  // ngAfterViewInit(): void { }

  // openPopup(content: any): void {
  //   this.modalService.open(content, { backdrop: 'static', centered: true });
  // }

  // login(dismiss: any): void {
  //   this.currentUser = this.userList.find(user => user.nom === "Jemai");
  //   this.userList = this.userList.filter((user) => user.nom !== "Jemai");
  //   console.log("this.currentUser", this.currentUser);

  //   if (this.currentUser) {
  //     this.showScreen = true;
  //     localStorage.setItem("user", this.currentUser._id);
  //     dismiss();
  //   }
  //   this.chatService.getUnreadNotifications(this.currentUser.nom).subscribe((notifications: any[]) => {
  //     notifications.forEach(notification => {
  //       this.chatService['notificationSubject'].next(notification);
  //     });
  //     this.chatService.markNotificationsAsRead(this.currentUser.nom).subscribe();
  //   });
  // }

  // selectUserHandler(phone: string): void {
  //   console.log(this.currentUser);
  //   this.selectedUser = this.userList.find(user => user.nom === "Trabelsi");
  //   console.log(this.selectedUser);
  //   this.roomId = this.selectedUser.roomId[this.currentUser._id];
  //     console.log("Room ID:", this.roomId);
  
  //     this.messageArray = [];
  //     this.chatService.getMessagesByRomm(this.roomId).subscribe(
  //       (data) => {
  //         this.messageArray = data; // Supposons que la réponse du backend est au format { chats: [] }
  //         if (this.messageArray.length > 0 && this.messageArray[this.messageArray.length - 1].user != this.currentUser.nom) {
  //           this.chatService.changeStatutHasNewMessageToSeen(this.roomId).subscribe(
  //             (data) => {
  //               console.log("Statut changé:", data);
  //             }
  //           );
  //         }
  
  //         // Scroll to bottom after loading messages
  //         setTimeout(() => {
  //           const chatBody = document.querySelector('.chat-body');
  //           if (chatBody) {
  //             chatBody.scrollTop = chatBody.scrollHeight;
  //           }
  //         }, 50); 
  //       },
  //       (error) => {
  //         console.error('Erreur lors du chargement des messages:', error);
  //       }
  //     );
  //     this.join(this.currentUser.nom, this.roomId);
  //  // }
  //   //  else {
  //   //   console.error('Erreur: Utilisateur sélectionné ou utilisateur courant non défini.');
  //   // }
  // }
  
  
  

  // join(username: string, roomId: string): void {
  //   this.chatService.joinRoom({ user: username, room: roomId });
  // }

  // sendMessage(): void {
  //   // Vérifiez que currentUser, roomId, et messageText sont définis
  //   if (this.currentUser && this.roomId && this.messageText) {
  //     let messagesToSend = [];
  
  //     if (Array.isArray(this.messageText)) {
  //       // Si this.messageText est déjà un tableau, pas besoin de le modifier
  //       messagesToSend = this.messageText;
  //     } else {
  //       // Si this.messageText est une chaîne de caractères, crée un tableau avec cette chaîne comme seul élément
  //       messagesToSend.push(this.messageText);
  //     }
  
  //     // Envoyer les messages au service de chat via WebSocket
  //     this.chatService.sendMessage({
  //       room: this.roomId,
  //       chats: messagesToSend.map(message => ({
  //         user: this.currentUser.nom,
  //         message: message
  //       }))
  //     });
  
  //     // Ajouter les messages à storageArray
  //     const newMessages = messagesToSend.map(message => ({
  //       user: this.currentUser.nom,
  //       message: message
  //     }));
      
  //     // Sauvegarder les messages dans la base de données
  //     this.chatService.createMessage({
  //       room: this.roomId,
  //       chats: messagesToSend.map(message => ({
  //         user: this.currentUser.nom,
  //         message: message
  //       }))
  //     }).subscribe(
  //       (response) => {
  //         // Gérer la réponse si nécessaire
  //       },
  //       (error) => {
  //         console.error('Erreur lors de l\'envoi du message:', error);
  //         // Gérer l'erreur si nécessaire
  //       }
  //     );
  
  //     // Réinitialiser messageText
  //     this.messageText = '';
  //     setTimeout(() => {
  //       const chatBody = document.querySelector('.chat-body');
  //       if (chatBody) {
  //         chatBody.scrollTop = chatBody.scrollHeight;
  //       }
  //     }, 1000);
  //   } else {
  //     // Logique de vérification si les valeurs nécessaires ne sont pas définies
  //     if (!this.currentUser) {
  //       console.error('Utilisateur courant non sélectionné.');
  //     } else if (!this.roomId) {
  //       console.error('ID de la salle non défini.');
  //     } else if (!this.messageText) {
  //       console.error('Veuillez saisir un message.');
  //     }
  //   }
  // }
  

  // searchUser(): void {
  //   this.searchResults = this.copyListUsers.filter(user => {
  //     return user.nom.toLowerCase().includes(this.searchTerm.toLowerCase());
  //   });
  //   console.log(" this.searchResults", this.searchResults);
  //   this.userList = this.searchResults.filter(x => x.id.toString() !== localStorage.getItem("user"));
  // }

  // truncateMessage(message: string): string {
  //   if (message.length > 45) {
  //     return message.substring(0, 45) + '...';
  //   }
  //   return message;
  // }

  // changeStatutFavorite(item) {
  //   console.log("item", item);
  //   this.chatService.changeStatutfavorite(item._id).subscribe(
  //     (data) => {
  //       item.favorite = !item.favorite;
  //     }
  //   );
  // }
}
