import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-retine',
  templateUrl: './retine.component.html',
  styleUrls: ['./retine.component.css', '../../laf/laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class RetineComponent implements OnInit {
  displayRetine = true
  //---------------------------------------------------------- retine
  retine = false;
  retineLeftList = [
    "Normale",
    "Décollement de rétine",
    "Déhiscence rétinienne",
    "Rétinoschisis",
    "Décollement choroïdien",
    "Foyer",
    "MASSE RETINO /CHOROIDIENNE",
    "DEP",
    "DSR",
    "Palissade",
    "Givre",
    "Blanc sans pression",
    "Blanc avec pression",
    "Hémorragie rétinienne",
    "Nodule cotonneux",
    "Microanévrisme",
    "Macroanévrisme",
    "Exsudat",
    "Pigmentation",
    "Tâche de rétinite pigmentaire",
    "Dégénérescence lattice"
  ];
  retineRightList = [
    "Normale",
    "Décollement de rétine",
    "Déhiscence rétinienne",
    "Rétinoschisis",
    "Décollement choroïdien",
    "Foyer",
    "MASSE RETINO /CHOROIDIENNE",
    "DEP",
    "DSR",
    "Palissade",
    "Givre",
    "Blanc sans pression",
    "Blanc avec pression",
    "Hémorragie rétinienne",
    "Nodule cotonneux",
    "Microanévrisme",
    "Macroanévrisme",
    "Exsudat",
    "Pigmentation",
    "Tâche de rétinite pigmentaire",
  ];
  localisation = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  siege = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  //!----- anomalies by a doctor
  //Rétine
  anomaliesByDoctorRetine = []
  oldlistRetine = this.retineLeftList
  newAnomalieRetine = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreRetine = false
  //end of Show More Variables

  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  numberOfdaysRight: any
  numberOfdaysLeft: any
  today: string;
  // send object
  objecttoSend = {
    retineOD: {},
    retineOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false
  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
    // to block the input of anciennetéé~Décollement de rétine~Rétine with the date of today
    const currentDate = new Date();
    this.today = currentDate.toISOString().split('T')[0];
    // to block the input of anciennetéé~Décollement de rétine~Rétine with the date of today
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-retine').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
          this.calculAncienneteDaysLeft()
          this.calculAncienneteDaysRight()
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }

    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-retine", 'value': this.examLaf.laf.retine });
  }
  configureDataExamen(idPatient) {
    // !Historique LAF
    this.examenService.getInspectionHistoriques(idPatient, "laf").subscribe((data: any) => {
      this.oldListConsultations = data
    },
      err => {
        return false;
      });
    //! Rétine
    this.anomalieService.getAnomalieByDoctor("laf-retine").subscribe(data => {
      this.anomaliesByDoctorRetine = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });

  }

  showMoreRetineSection() {
    this.showMoreRetine = !this.showMoreRetine
  }
  displayAndHideRetine() {
    this.displayRetine = !this.displayRetine
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }

  //--------------------------------------------------------------- retine

  addRemoveFromretineRight(value) {
    this.examLaf.laf.retine.oielDroit.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.retine.oielDroit); this.examLaf.laf.retine.oielDroit.normale.present = true
        break
      case 'Décollement de rétine': this.examLaf.laf.retine.oielDroit.decollementretine.present = !this.examLaf.laf.retine.oielDroit.decollementretine.present
        break
      case 'Déhiscence rétinienne': this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.present = !this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.present
        break
      case 'Décollement choroïdien': this.examLaf.laf.retine.oielDroit.decollementchorodien.present = !this.examLaf.laf.retine.oielDroit.decollementchorodien.present
        break
      case 'Dégénérescence lattice': this.examLaf.laf.retine.oielDroit.degenerescencelattice.present = !this.examLaf.laf.retine.oielDroit.degenerescencelattice.present
        break
      case 'Foyer': this.examLaf.laf.retine.oielDroit.foyer.present = !this.examLaf.laf.retine.oielDroit.foyer.present
        break
      case 'Rétinoschisis': this.examLaf.laf.retine.oielDroit.retinoschisis.present = !this.examLaf.laf.retine.oielDroit.retinoschisis.present
        break
      case 'Masse rétino/choroïdienne': this.examLaf.laf.retine.oielDroit.masseretinochoroidienne.present = !this.examLaf.laf.retine.oielDroit.masseretinochoroidienne.present
        break
      case 'DEP': this.examLaf.laf.retine.oielDroit.DEP.present = !this.examLaf.laf.retine.oielDroit.DEP.present
        break
      case 'DSR': this.examLaf.laf.retine.oielDroit.DSR.present = !this.examLaf.laf.retine.oielDroit.DSR.present
        break
      case 'Palissade': this.examLaf.laf.retine.oielDroit.palissade.present = !this.examLaf.laf.retine.oielDroit.palissade.present
        break
      case 'Givre': this.examLaf.laf.retine.oielDroit.givre.present = !this.examLaf.laf.retine.oielDroit.givre.present
        break
      case 'Blanc sans pression': this.examLaf.laf.retine.oielDroit.blancsanspression.present = !this.examLaf.laf.retine.oielDroit.blancsanspression.present
        break
      case 'Blanc avec pression': this.examLaf.laf.retine.oielDroit.blancavecpression.present = !this.examLaf.laf.retine.oielDroit.blancavecpression.present
        break
      case 'Hémorragie rétinienne': this.examLaf.laf.retine.oielDroit.hemorragieretinienne.present = !this.examLaf.laf.retine.oielDroit.hemorragieretinienne.present
        break
      case 'Nodule cotonneux': this.examLaf.laf.retine.oielDroit.nodulecotonneux.present = !this.examLaf.laf.retine.oielDroit.nodulecotonneux.present
        break
      case 'Microanévrisme': this.examLaf.laf.retine.oielDroit.microanevrisme.present = !this.examLaf.laf.retine.oielDroit.microanevrisme.present
        break
      case 'Macroanévrisme': this.examLaf.laf.retine.oielDroit.macroanevrisme.present = !this.examLaf.laf.retine.oielDroit.macroanevrisme.present
        break
      case 'Exsudat': this.examLaf.laf.retine.oielDroit.exsudat.present = !this.examLaf.laf.retine.oielDroit.exsudat.present
        break
      case 'Pigmentation': this.examLaf.laf.retine.oielDroit.pigmentation.present = !this.examLaf.laf.retine.oielDroit.pigmentation.present
        break
      case 'Tâche de rétinite pigmentaire': this.examLaf.laf.retine.oielDroit.tachederp.present = !this.examLaf.laf.retine.oielDroit.tachederp.present
        break
    }
    this.setToTrue(this.examLaf.laf.retine.oielDroit)
    this.objecttoSend.retineOD = this.examLaf.laf.retine.oielDroit
    this.objecttoSend.source = "retineOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromretineLeft(value) {
    this.examLaf.laf.retine.oielGauche.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.retine.oielGauche); this.examLaf.laf.retine.oielGauche.normale.present = true
        break
      case 'Décollement de rétine': this.examLaf.laf.retine.oielGauche.decollementretine.present = !this.examLaf.laf.retine.oielGauche.decollementretine.present
        break
      case 'Déhiscence rétinienne': this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.present = !this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.present
        break
      case 'Décollement choroïdien': this.examLaf.laf.retine.oielGauche.decollementchorodien.present = !this.examLaf.laf.retine.oielGauche.decollementchorodien.present
        break
      case 'Dégénérescence lattice': this.examLaf.laf.retine.oielGauche.degenerescencelattice.present = !this.examLaf.laf.retine.oielGauche.degenerescencelattice.present
        break
      case 'Foyer': this.examLaf.laf.retine.oielGauche.foyer.present = !this.examLaf.laf.retine.oielGauche.foyer.present
        break
      case 'Rétinoschisis': this.examLaf.laf.retine.oielGauche.retinoschisis.present = !this.examLaf.laf.retine.oielGauche.retinoschisis.present
        break
      case 'Masse rétino/choroïdienne': this.examLaf.laf.retine.oielGauche.masseretinochoroidienne.present = !this.examLaf.laf.retine.oielGauche.masseretinochoroidienne.present
        break
      case 'DEP': this.examLaf.laf.retine.oielGauche.DEP.present = !this.examLaf.laf.retine.oielGauche.DEP.present
        break
      case 'DSR': this.examLaf.laf.retine.oielGauche.DSR.present = !this.examLaf.laf.retine.oielGauche.DSR.present
        break
      case 'Palissade': this.examLaf.laf.retine.oielGauche.palissade.present = !this.examLaf.laf.retine.oielGauche.palissade.present
        break
      case 'Givre': this.examLaf.laf.retine.oielGauche.givre.present = !this.examLaf.laf.retine.oielGauche.givre.present
        break
      case 'Blanc sans pression': this.examLaf.laf.retine.oielGauche.blancsanspression.present = !this.examLaf.laf.retine.oielGauche.blancsanspression.present
        break
      case 'Blanc avec pression': this.examLaf.laf.retine.oielGauche.blancavecpression.present = !this.examLaf.laf.retine.oielGauche.blancavecpression.present
        break
      case 'Hémorragie rétinienne': this.examLaf.laf.retine.oielGauche.hemorragieretinienne.present = !this.examLaf.laf.retine.oielGauche.hemorragieretinienne.present
        break
      case 'Nodule cotonneux': this.examLaf.laf.retine.oielGauche.nodulecotonneux.present = !this.examLaf.laf.retine.oielGauche.nodulecotonneux.present
        break
      case 'Microanévrisme': this.examLaf.laf.retine.oielGauche.microanevrisme.present = !this.examLaf.laf.retine.oielGauche.microanevrisme.present
        break
      case 'Macroanévrisme': this.examLaf.laf.retine.oielGauche.macroanevrisme.present = !this.examLaf.laf.retine.oielGauche.macroanevrisme.present
        break
      case 'Exsudat': this.examLaf.laf.retine.oielGauche.exsudat.present = !this.examLaf.laf.retine.oielGauche.exsudat.present
        break
      case 'Pigmentation': this.examLaf.laf.retine.oielGauche.pigmentation.present = !this.examLaf.laf.retine.oielGauche.pigmentation.present
        break
      case 'Tâche de rétinite pigmentaire': this.examLaf.laf.retine.oielGauche.tachederp.present = !this.examLaf.laf.retine.oielGauche.tachederp.present
        break
    }
    this.setToTrue(this.examLaf.laf.retine.oielGauche)
    this.objecttoSend.retineOG = this.examLaf.laf.retine.oielGauche
    this.objecttoSend.source = "retineOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  //-------- Décollement de rétine
  // etat Maculaire
  selectDecollementRetrineetatmaculaireRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.etatmaculaire = value
  }
  selectDecollementRetrineetatmaculaireLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.etatmaculaire = value
  }
  // bords
  selectDecollementRetrinebordsRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.bords = value
  }
  selectDecollementRetrinebordsLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.bords = value
  }
  // mobilite
  selectDecollementRetrinemobiliteRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.mobilite = value
  }
  selectDecollementRetrinemobiliteLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.mobilite = value
  }
  // hauteur
  selectDecollementRetrinehauteurRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.hauteur = value
  }
  selectDecollementRetrinehauteurLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.hauteur = value
  }
  // vaisseau en pont
  selectDecollementRetrinevaisseauxenpontRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.vaisseauxenpont = value
  }
  selectDecollementRetrinevaisseauxenpontLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.vaisseauxenpont = value
  }
  // Classification PVR
  selectDecollementRetrineclassificationPVRRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.classificationPVR = value
  }
  selectDecollementRetrineclassificationPVRLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.classificationPVR = value
  }
  //-------- Retino schisis
  selectretinoschisisEtatmaculaireRight(value) {
    this.examLaf.laf.retine.oielDroit.retinoschisis.Etatmaculaire = value
  }
  selectretinoschisisEtatmaculaireLeft(value) {
    this.examLaf.laf.retine.oielGauche.retinoschisis.Etatmaculaire = value
  }
  // ----------- Déhiscence decollement retine 

  // Trous
  selectdecollementretineTrousRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.trous = value
    this.examLaf.laf.retine.oielDroit.decollementretine.Siege = undefined
    this.examLaf.laf.retine.oielDroit.decollementretine.Taille = undefined
    this.examLaf.laf.retine.oielDroit.decollementretine.dechirure = undefined

  }
  selectdecollementretineTrousLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.trous = value
    this.examLaf.laf.retine.oielGauche.decollementretine.Siege = undefined
    this.examLaf.laf.retine.oielGauche.decollementretine.Taille = undefined
    this.examLaf.laf.retine.oielGauche.decollementretine.dechirure = undefined
  }
  // dechirure
  selecteddecollementretineTypeRight() {

    if (this.examLaf.laf.retine.oielDroit.decollementretine.dehiscenceType === 'Dialyse à l’aura') {
      this.examLaf.laf.retine.oielDroit.decollementretine.localisation = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Bords = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Siege = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Taille = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.clapet = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.dechirure = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.trous = undefined
    }
  }
  selecteddecollementretineTypeLeft() {
    if (this.examLaf.laf.retine.oielGauche.decollementretine.dehiscenceType === 'Dialyse à l’aura') {
      this.examLaf.laf.retine.oielGauche.decollementretine.localisation = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Bords = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Siege = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Taille = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.clapet = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.dechirure = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.trous = undefined
    }
  }
  selectdecollementretineDechirureRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.dechirure = value
    if (value == "Déchirure en fer a cheval" || value == "Déchirure géante") {
      this.examLaf.laf.retine.oielDroit.decollementretine.localisation = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Bords = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Siege = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.Taille = undefined
      this.examLaf.laf.retine.oielDroit.decollementretine.clapet = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.trous = undefined
    }
  }
  selectdecollementretineDechirureLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.dechirure = value
    if (value == "Déchirure en fer a cheval" || value == "Déchirure géante") {
      this.examLaf.laf.retine.oielGauche.decollementretine.localisation = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Bords = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Siege = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.Taille = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.clapet = undefined
      this.examLaf.laf.retine.oielGauche.decollementretine.trous = undefined
    }
  }

  // clapet
  selectdecollementretineclapetRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.clapet = value
  }
  selectdecollementretineclapetLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.clapet = value
  }
  // Bords
  selectdecollementretineBordsRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.Bords = value
  }
  selectdecollementretineBordsLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.Bords = value
  }
  // Vaisseau en pont
  selectdecollementretineVaisseauxenpontRight(value) {
    this.examLaf.laf.retine.oielDroit.decollementretine.Vaisseauxenpont = value
  }
  selectdecollementretineVaisseauxenpontLeft(value) {
    this.examLaf.laf.retine.oielGauche.decollementretine.Vaisseauxenpont = value
  }

  // Déhichirure multiple
  // ***/ nombre

  checkdecollementretineNombreRight() {

    if (this.examLaf.laf.retine.oielDroit.decollementretine.nombre <= 0 || this.examLaf.laf.retine.oielDroit.decollementretine.nombre == undefined) {
      this.examLaf.laf.retine.oielDroit.decollementretine.nombre = 0
    } else if (this.examLaf.laf.retine.oielDroit.decollementretine.nombre > 5 || this.examLaf.laf.retine.oielDroit.decollementretine.nombre == undefined) {
      this.examLaf.laf.retine.oielDroit.decollementretine.nombre = 5
      this.iziToast.show({
        message: "Vous avez la possibilité d'ajouter seulement 5 déchirures",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });

    } else {
      let length = this.examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple.length
      let nombre = this.examLaf.laf.retine.oielDroit.decollementretine.nombre
      if (nombre > length) {
        for (let i = length; i < nombre; i++) {
          this.examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple.push({
            Bords: "",
            Vaisseauxenpont: "",
            multipleSiege: [],
            multipleTaille: [],
            multiplelocalisation: []
          })
        }

      } else if (nombre < length) {
        this.examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple.splice(nombre);
      }
    }
  }
  checkdecollementretineNombreLeft() {
    if (this.examLaf.laf.retine.oielGauche.decollementretine.nombre <= 0 || this.examLaf.laf.retine.oielGauche.decollementretine.nombre == undefined) {
      this.examLaf.laf.retine.oielGauche.decollementretine.nombre = 0
    } else if (this.examLaf.laf.retine.oielGauche.decollementretine.nombre > 5 || this.examLaf.laf.retine.oielGauche.decollementretine.nombre == undefined) {
      this.examLaf.laf.retine.oielGauche.decollementretine.nombre = 5
      this.iziToast.show({
        message: "Vous avez la possibilité d'ajouter seulement 5 déchirures",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
    } else {
      let length = this.examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple.length
      let nombre = this.examLaf.laf.retine.oielGauche.decollementretine.nombre
      if (nombre > length) {
        for (let i = length; i < nombre; i++) {
          this.examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple.push({
            Bords: "",
            Vaisseauxenpont: "",
            multipleSiege: [],
            multipleTaille: [],
            multiplelocalisation: []
          })
        }

      } else if (nombre < length) {
        this.examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple.splice(nombre);
      }
    }
  }


  // Delete  dechirigue
  removedecollementretineDechrirureRight(i) {
    let dechrigueMultiple = this.examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple
    dechrigueMultiple.splice(dechrigueMultiple.indexOf(dechrigueMultiple[i]), 1)
    this.examLaf.laf.retine.oielDroit.decollementretine.nombre--

  }
  removedecollementretineDechrirureLeft(i) {
    let dechrigueMultiple = this.examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple
    dechrigueMultiple.splice(dechrigueMultiple.indexOf(dechrigueMultiple[i]), 1)
    this.examLaf.laf.retine.oielGauche.decollementretine.nombre--

  }
  // Bords
  selectdecollementretineBordsDechirureMultiRight(value, i) {
    this.examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple[i].Bords = value
  }
  selectdecollementretineBordsDechirureMultiLeft(value, i) {
    this.examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple[i].Bords = value
  }
  // Vaisseau en pont
  selectdecollementretineVaisseauxenpontDechirureMultiRight(value, i) {
    this.examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple[i].Vaisseauxenpont = value
  }
  selectdecollementretineVaisseauxenpontDechirureMultiLeft(value, i) {
    this.examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple[i].Vaisseauxenpont = value
  }
  // -----------end of Déhiscence decollement retine 
  // -----------Déhiscence rétinienne

  // Trous
  selectdehiscenceretinienneTrousRight(value) {
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.trous = value
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Siege = undefined
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Taille = undefined
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechirure = undefined

  }
  selectdehiscenceretinienneTrousLeft(value) {
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.trous = value
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Siege = undefined
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Taille = undefined
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechirure = undefined
  }
  // dechirure
  seletedTypeRight() {

    if (this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Type === 'Dialyse à l’aura') {
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.localisation = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Bords = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Siege = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Taille = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.clapet = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechirure = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.trous = undefined
    }
  }
  seletedTypeLeft() {
    if (this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Type === 'Dialyse à l’aura') {
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.localisation = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Bords = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Siege = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Taille = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.clapet = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechirure = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.trous = undefined
    }
  }
  selectdehiscenceretinienneDechirureRight(value) {
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechirure = value
    if (value == "Déchirure en fer a cheval" || value == "Déchirure géante") {
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.localisation = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Bords = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Siege = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Taille = undefined
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.clapet = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.trous = undefined
    }
  }
  selectdehiscenceretinienneDechirureLeft(value) {
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechirure = value
    if (value == "Déchirure en fer a cheval" || value == "Déchirure géante") {
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.localisation = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Bords = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Vaisseauxenpont = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Siege = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Taille = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.clapet = undefined
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.trous = undefined
    }
  }

  // clapet
  selectdehiscenceretinienneclapetRight(value) {
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.clapet = value
  }
  selectdehiscenceretinienneclapetLeft(value) {
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.clapet = value
  }
  // Bords
  selectdehiscenceretinienneBordsRight(value) {
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Bords = value
  }
  selectdehiscenceretinienneBordsLeft(value) {
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Bords = value
  }
  // Vaisseau en pont
  selectdehiscenceretinienneVaisseauxenpontRight(value) {
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.Vaisseauxenpont = value
  }
  selectdehiscenceretinienneVaisseauxenpontLeft(value) {
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.Vaisseauxenpont = value
  }

  // Déhichirure multiple
  // ***/ nombre

  checkDehiscenceretinienneNombreRight() {

    if (this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre <= 0 || this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre == undefined) {
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre = 0
    } else if (this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre > 5 || this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre == undefined) {
      this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre = 5
      this.iziToast.show({
        message: "Vous avez la possibilité d'ajouter seulement 5 déchirures",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });

    } else {
      let length = this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple.length
      let nombre = this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre
      if (nombre > length) {
        for (let i = length; i < nombre; i++) {
          this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple.push({
            Bords: "",
            Vaisseauxenpont: "",
            multipleSiege: [],
            multipleTaille: [],
            multiplelocalisation: []
          })
        }

      } else if (nombre < length) {
        this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple.splice(nombre);
      }
    }
  }
  checkDehiscenceretinienneNombreLeft() {
    if (this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre <= 0 || this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre == undefined) {
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre = 0
    } else if (this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre > 5 || this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre == undefined) {
      this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre = 5
      this.iziToast.show({
        message: "Vous avez la possibilité d'ajouter seulement 5 déchirures",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
    } else {
      let length = this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple.length
      let nombre = this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre
      if (nombre > length) {
        for (let i = length; i < nombre; i++) {
          this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple.push({
            Bords: "",
            Vaisseauxenpont: "",
            multipleSiege: [],
            multipleTaille: [],
            multiplelocalisation: []
          })
        }

      } else if (nombre < length) {
        this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple.splice(nombre);
      }
    }
  }


  // Delete  dechirigue
  removeDechrirureRight(i) {
    let dechrigueMultiple = this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple
    dechrigueMultiple.splice(dechrigueMultiple.indexOf(dechrigueMultiple[i]), 1)
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.nombre--

  }
  removeDechrirureLeft(i) {
    let dechrigueMultiple = this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple
    dechrigueMultiple.splice(dechrigueMultiple.indexOf(dechrigueMultiple[i]), 1)
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.nombre--

  }
  // Bords
  selectdehiscenceretinienneBordsDechirureMultiRight(value, i) {
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple[i].Bords = value
  }
  selectdehiscenceretinienneBordsDechirureMultiLeft(value, i) {
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple[i].Bords = value
  }
  // Vaisseau en pont
  selectdehiscenceretinienneVaisseauxenpontDechirureMultiRight(value, i) {
    this.examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple[i].Vaisseauxenpont = value
  }
  selectdehiscenceretinienneVaisseauxenpontDechirureMultiLeft(value, i) {
    this.examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple[i].Vaisseauxenpont = value
  }

  // ------------Foyer
  selectRetinefoyerSiegeRight(value) {
    this.examLaf.laf.retine.oielDroit.foyer.Siege = value
  }
  selectRetinefoyerSiegeLeft(value) {
    this.examLaf.laf.retine.oielGauche.foyer.Siege = value
  }
  // ------------Masse rétino/choroïdienne
  selectRetinemasseretinochoroidienneSiegeRight(value) {
    this.examLaf.laf.retine.oielDroit.masseretinochoroidienne.Siege = value
  }
  selectRetinemasseretinochoroidienneSiegeLeft(value) {
    this.examLaf.laf.retine.oielGauche.masseretinochoroidienne.Siege = value
  }


  checkSign(event) {
    if (event.target.value <= 0) {
      event.target.value = 0
    }
  }
  calculAncienneteDaysRight() {
    if (this.examLaf.laf.retine.oielDroit.decollementretine.anciennete) {
      const enteredDate: Date = new Date(this.examLaf.laf.retine.oielDroit.decollementretine.anciennete);
      const currentDate: Date = new Date();
      // Calculate the difference in milliseconds
      const differenceInMs: number = currentDate.getTime() - enteredDate.getTime();
      // Convert milliseconds to days
      this.numberOfdaysRight = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    }
  }
  calculAncienneteDaysLeft() {
    if (this.examLaf.laf.retine.oielGauche.decollementretine.anciennete) {
      const enteredDate: Date = new Date(this.examLaf.laf.retine.oielGauche.decollementretine.anciennete);
      const currentDate: Date = new Date();
      // Calculate the difference in milliseconds
      const differenceInMs: number = currentDate.getTime() - enteredDate.getTime();
      // Convert milliseconds to days
      this.numberOfdaysLeft = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    }
  }

  addNewAnomalyRetine(event) {
    const maxAnomalies = 5;
    const inputValue = event?.target?.value;

    const showToast = (message, messageColor, backgroundColor, progressBarColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        const self = this;
        this.iziToast.show({
          message,
          messageColor,
          titleColor: messageColor,
          progressBarColor,

          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: function () {
            self.isToastVisible = false;
          },
        });
      }
    };

    if (this.anomaliesByDoctorRetine && this.anomaliesByDoctorRetine.length >= maxAnomalies) {
      showToast(
        "Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies",
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieRetine = "";
      return false;
    }

    if (inputValue && inputValue.length > 1 && inputValue.length < 100) {
      const anomalyExisted = this.anomaliesByDoctorRetine.some(x => x.titre?.toLowerCase() === inputValue.toLowerCase());
      const anomalyExistedOld = this.oldlistRetine.some(x => x.toLowerCase() === inputValue.toLowerCase());

      if (!anomalyExisted && !anomalyExistedOld) {
        this.anomalieService.ajoutAnomalie({
          titre: inputValue[0].toUpperCase() + inputValue.slice(1),
          description: "",
          zone: "laf-retine",
        }).subscribe((data) => {
          this.newAnomalieRetine = "";
          this.anomaliesByDoctorRetine.push(data);
          showToast(
            'Bien enregistré',
            '#386641',
            '#dde5b6',
            '#6a994e',
          );
        });
        return true;
      } else {
        showToast(
          "L'anomalie existe dans la liste",
          '#800f2f',
          '#ff8fa3',
          '#c9184a'
        );
        this.newAnomalieRetine = "";
        return false;
      }
    } else {
      showToast(
        'Merci de saisir au moins 2 caractères',
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieRetine = "";
      return false;
    }
  }

  // Right Retine
  addRemoveFromRetineRightNew(value, id) {
    this.examLaf.laf.retine.oielDroit.normale.present = false
    let truefalseexist = this.examLaf.laf.retine.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.retine.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.retineOD = this.examLaf.laf.retine.oielDroit
      this.objecttoSend.source = "retineOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.retine.oielDroit.allAnomalies = this.examLaf.laf.retine.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.retineOD = this.examLaf.laf.retine.oielDroit
      this.objecttoSend.source = "retineOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examLaf.laf.retine.oielDroit)
      return true;
    }

  }

  checkExistRetineRight(id) {
    return this.examLaf.laf.retine.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Retine dyna
  addRemoveFromRetineLeftNew(value, id) {
    this.examLaf.laf.retine.oielGauche.normale.present = false
    let truefalseexist = this.examLaf.laf.retine.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.retine.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.retineOG = this.examLaf.laf.retine.oielGauche
      this.objecttoSend.source = "retineOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.retine.oielGauche.allAnomalies = this.examLaf.laf.retine.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.retineOG = this.examLaf.laf.retine.oielGauche
      this.objecttoSend.source = "retineOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examLaf.laf.retine.oielGauche)
      return true;
    }

  }
  checkExistRetineLeft(id) {
    return this.examLaf.laf.retine.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of Rétine


  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-retine') {
        this.anomaliesByDoctorRetine.splice(this.anomaliesByDoctorRetine.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  setToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "normale") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.normale.present = true;
    }
    return myObject
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }


}
