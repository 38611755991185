import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searching'
})
export class SearchPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.motif.some(m => m.toLowerCase().includes(args))).length > 0) {
    return value.filter((item) => item.motif.some(m => m.toLowerCase().includes(args)));
    }
     if (value.filter((item) => item.start.toLowerCase().includes(args)) != '') {
       return value.filter((item) => item.start.toLowerCase().includes(args));}
     
 }}
