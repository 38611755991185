import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
import { Observable } from 'rxjs';

@Injectable()
export class ExamenService {


constructor(private http: HttpClient) { }
getPreviousConsultation(id: String): Observable<any> {
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-previousconsultation/" + id, { headers: headers });
}
ajoutExamen(examen) {
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.examen), examen, { headers: headers })
}
getExamenById(id){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-consultation/" + id, { headers: headers });
}
// get examen details for consuite à tenir zone 
getExamenByIdForCat(idExamen){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-consultation/zone/cat/" + idExamen, { headers: headers });
}
getExamenByIdForCatImpression(idExamen,idCat){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-consultation/zone/cat/imression/" + idExamen+"/cat/"+idCat, { headers: headers });
}
getExamenByIdForConclusion(idExamen){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-consultation/zone/conclusion/" + idExamen, { headers: headers });
}
updateExamen(examen){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
      });
      return this.http.put(_getURL(API_CONFIG.examen) + '/update-exam/' + examen.id, examen, { headers: headers })
        
}
hasOldConsultation(id,idExamen){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
      });
      return this.http.get(_getURL(API_CONFIG.examen) + '/check-number-of-old-consultation/' + id+'/'+idExamen, { headers: headers })
      
}
 uploadFiles(files,key):Observable <any> {
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
      });
      return this.http.post(_getURL(API_CONFIG.examen) + '/upload/file/' + key, files, { headers: headers })
 }
 // get all examens by patient 
 getAllExamnByPatient(idPatient){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-all-consultations/" + idPatient, { headers: headers });
}

// get all examens by doctor 
 getAllExamnByDoctor(){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-all-consultations-doctor", { headers: headers });
}
// get last 10 examn by patient 
getAllExamnByPatientLastTen(idPatient){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-all-consultations/last/ten/" + idPatient, { headers: headers });
}
// inspection
getInspectionById(id){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-inspection/" + id, { headers: headers });
}
// refraction
getRefractionById(id){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-refraction/" + id, { headers: headers });
}
// anexe
getAnexeById(id){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-anexe/" + id, { headers: headers });
}
// LAF
getLAFById(id){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-laf/" + id, { headers: headers });
}
getLAFByZone(id,zone){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-laf/" + id+'/'+zone, { headers: headers });
}
getInspectionHistoriques(idPatient,zone){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/get-inspection-historique/"+idPatient+"/"+zone, { headers: headers });
}
getRefractionDatafromFile(){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.connectRestApi) + "/reload/refraction", { headers: headers });
}
getExamenForToday(){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/today", { headers: headers });
}
getstatistiquesExamen(){
    const headers = new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('id_token')),
        'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.examen) + "/statistiquesExamen", { headers: headers });
}
}