import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class EventService {

  constructor(private http: HttpClient) {
  }

  getAllEvents() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.event), { headers: headers })
      
  }
  getAllEventsByMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.event) + '/medecin/' + id, { headers: headers })
      
  }
  // get all events libre of medecin
  getAllEventsLibresByMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.event) + '/medecin/eventslibres/' + id, { headers: headers })
      
  }

  getAllEventsLibresByMedecinNew(id){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.event) + '/medecin/eventslibres/module/recherche/' + id, { headers: headers })
  }

  // get all events libre of medecin BY DATE
  getAllEventsLibresByMedecinByDate(id,date) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.event) + '/medecin/eventslibres/' + id+'/'+date, { headers: headers })   
  }
  getAllEventsByMedecinByDateBetweenTwoDates(id, startDate, endDate){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.event) + '/medecin/events/all/' + id+'/'+startDate+'/'+endDate, { headers: headers })   
  }
  deleteEvent(eventID) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.delete(_getURL(API_CONFIG.event) + '/' + eventID, { headers: headers })
      
  }
  registerEvent(newEvent) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.event), (newEvent), { headers: headers })
      
  }
  registerEventProposition(rdvObject){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.event)+'/send/mail', (rdvObject), { headers: headers })
      
  }
  editEvent(event) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.event) + '/' + event._id, (event), { headers: headers })
      
  }
  getSingleEvent(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.event) + '/' + id,  { headers: headers })
      
  }
}
