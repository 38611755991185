<section id="cours" style="width: 85%;margin: 0 auto;margin-top: 15%;">
    <!--<ng-template #customLoadingTemplate></ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>-->
    <div id="demo" *ngIf="patient">
      <form class="form-horizontal" role="form">
        <fieldset>
          <!-- Form Name -->
          <div class="form-group col-md-12 col-sm-6">
            <legend>Ajouter des documents pour une consultation</legend>
          </div>
          <!-- Text input-->
          <div class="">
            <div class="title textContenu2">
              <input type="file" style="width:100%"  name="selectedFichier"  class="input-btn-file" id="fileDropCssnew" ng2FileSelect [uploader]="uploader" (change)="selectFile($event)" multiple  accept="image/*,.pdf" placeholder="Drag & Drop">
              <p style="color:red" *ngIf="errofile">Merci de choisir un fichier</p>
            </div>
  
          </div>
          <br>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider"  (click)="uploader.uploadAll();refresh()" [disabled]="!uploader.getNotUploadedItems().length" data-target="#added"><img src="assets/images/listePatsEspaceMed/valider.png" class="iconBoutton">Ajouter </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" [routerLink]="['/medecin/consultation-liste',patient._id]"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <br><br><br><br><br><br><br><br>
    </div>
  </section>
  