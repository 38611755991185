<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Chambre antérieure" (click)="displayAndHideChambre()">Chambre antérieure</h4>
            <div class="arrow arrow-up btnChambre" *ngIf="displayChambre" title="Cacher Chambre antérieure" (click)="displayAndHideChambre()">▲</div>
            <div class="arrow arrow-up btnChambre" *ngIf="!displayChambre" title="Afficher Chambre antérieure" (click)="displayAndHideChambre()">▼</div>
        </div>

        <div *ngIf="displayChambre" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present==true}" (click)=" addRemoveFromchambreAntRight('Calme et profonde')">
                                    Calme et profonde
                                </li>
                                <li [ngClass]="{ selectedLi:  examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.present==true}" (click)="addRemoveFromchambreAntRight('CA étroite')">
                                    CA étroite
                                </li>
                                <div *ngIf="showMoreChambre">
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.present==true}" (click)=" addRemoveFromchambreAntRight('Corps étranger')">
                                        Corps étranger
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.present==true}" (click)="addRemoveFromchambreAntRight('Cyclodialyse')">
                                        Cyclodialyse
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.present==true}" (click)="addRemoveFromchambreAntRight('Hémorragie sous conjonctivale')">
                                        Hémorragie sous conjonctivale
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.present==true}" (click)="addRemoveFromchambreAntRight('Hypopion')">
                                        Hypopion
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.present==true}" (click)="addRemoveFromchambreAntRight('Hyphema')">
                                        Hyphema
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.present==true}" (click)="addRemoveFromchambreAntRight('ICA')">
                                        ICA
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{  selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.IP.present==true}" (click)="addRemoveFromchambreAntRight('IP')">
                                    IP
                                </li>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.present==true}" (click)=" addRemoveFromchambreAntRight( 'Masse cristallinienne' )">
                                    Masse cristallinienne
                                </li>
                                <div *ngIf="showMoreChambre">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.present==true }" (click)=" addRemoveFromchambreAntRight('Mèche de vitre') ">
                                        Mèche de vitre
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.present==true }" (click)="addRemoveFromchambreAntRight('Pigments')">
                                        Pigments
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.recession.present==true}" (click)="addRemoveFromchambreAntRight('Récession')">
                                        Récession
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.present==true}" (click)="addRemoveFromchambreAntRight('Signe du limbe +')">
                                        Signe du limbe +
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.present==true}" (click)=" addRemoveFromchambreAntRight('Test de van herick')">
                                        Test de van herick
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.present==true}" (click)="addRemoveFromchambreAntRight('Tyndall cellulaire')">
                                        Tyndall cellulaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.present==true}" (click)="addRemoveFromchambreAntRight('Tyndall proteique')">
                                        Tyndall proteique
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorChambre" [ngClass]="{'selectedLi': checkExistAnomalieChambreRight(anomaly.id)}">
                                        <span (click)="addRemoveFromChambreRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieChambreRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-chambreAnt')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieChambre" (keyup.enter)="addNewAnomalyChambre($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreChambreSection() *ngIf="!showMoreChambre">Voir plus</span>
                            <span (click)=showMoreChambreSection() *ngIf="showMoreChambre">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present==true}" (click)=" addRemoveFromchambreAntLeft('Calme et profonde')">
                                    Calme et profonde
                                </li>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.present==true}" (click)="addRemoveFromchambreAntLeft('CA étroite')">
                                    CA étroite
                                </li>
                                <div *ngIf="showMoreChambre">
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.present==true}" (click)=" addRemoveFromchambreAntLeft('Corps étranger')">
                                        Corps étranger
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.present==true}" (click)="addRemoveFromchambreAntLeft('Cyclodialyse')">
                                        Cyclodialyse
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.present==true}" (click)="addRemoveFromchambreAntLeft('Hémorragie sous conjonctivale')">
                                        Hémorragie sous conjonctivale
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.present==true}" (click)="addRemoveFromchambreAntLeft('Hypopion')">
                                        Hypopion
                                    </li>
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.present==true}" (click)="addRemoveFromchambreAntLeft('Hyphema')">
                                        Hyphema
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.present==true}" (click)="addRemoveFromchambreAntLeft('ICA')">
                                        ICA
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{  selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.IP.present==true}" (click)="addRemoveFromchambreAntLeft('IP')">
                                    IP
                                </li>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.present==true}" (click)=" addRemoveFromchambreAntLeft( 'Masse cristallinienne' )">
                                    Masse cristallinienne
                                </li>
                                <div *ngIf="showMoreChambre">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.present==true }" (click)=" addRemoveFromchambreAntLeft('Mèche de vitre') ">
                                        Mèche de vitre
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.present==true }" (click)="addRemoveFromchambreAntLeft('Pigments')">
                                        Pigments
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.recession.present==true}" (click)="addRemoveFromchambreAntLeft('Récession')">
                                        Récession
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.present==true}" (click)="addRemoveFromchambreAntLeft('Signe du limbe +')">
                                        Signe du limbe +
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.present==true}" (click)=" addRemoveFromchambreAntLeft('Test de van herick')">
                                        Test de van herick
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.present==true}" (click)="addRemoveFromchambreAntLeft('Tyndall cellulaire')">
                                        Tyndall cellulaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.present==true}" (click)="addRemoveFromchambreAntLeft('Tyndall proteique')">
                                        Tyndall proteique
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorChambre" [ngClass]="{'selectedLi': checkExistAnomalieChambreLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromChambreLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieChambreLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-chambreAnt')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieChambre" (keyup.enter)="addNewAnomalyChambre($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreChambreSection() *ngIf="!showMoreChambre">Voir plus</span>
                            <span (click)=showMoreChambreSection() *ngIf="showMoreChambre">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf=" examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present==true ">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Calme et profonde</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.present==true ">
                        <h5>Tyndall cellulaire</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.valeur=='0,5 : Trace (1-5 cellules)'" (change)="selecttyndallcellulaireRight('0,5 : Trace (1-5 cellules)')" />
                                    <label>0,5 : Trace (1-5 cellules)</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.valeur=='1+ : Minime (6-15 cellules )'" (change)="selecttyndallcellulaireRight('1+ : Minime (6-15 cellules )')" />
                                    <label>1+ : Minime (6-15 cellules )</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.valeur=='2+ : Modéré (16-25 cellules )'" (change)="selecttyndallcellulaireRight('2+ : Modéré (16-25 cellules )')" />
                                    <label for="roundedCheckbox2">2+ : Modéré (16-25 cellules )
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.valeur=='3+ : Marqué (26-25 cellules )'" (change)="selecttyndallcellulaireRight('3+ : Marqué (26-25 cellules )')" />
                                    <label for="roundedCheckbox">3+ : Marqué (26-25 cellules )
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.valeur=='4+ : Intense (> 50 cellules )'" (change)="selecttyndallcellulaireRight('4+ : Intense (> 50 cellules )')" />
                                    <label for="roundedCheckbox4">4+ : Intense (> 50 cellules )
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.present==true">
                        <h5>Tyndall proteique</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.valeur=='1+ : Minime'" (change)="selecttyndallproteiqueRight('1+ : Minime')" />
                                    <label for="roundedCheckbox5">1+ : Minime </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.valeur=='2+ : Modéré (détails irien identifiable)'" (change)="selecttyndallproteiqueRight('2+ : Modéré (détails irien identifiable)')" />
                                    <label for="roundedCheckbox6">2+ : Modéré (détails irien identifiable)
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.valeur=='4+ : Fibrine en chambre antérieur'" (change)="selecttyndallcellulaireRight('4+ : Fibrine en chambre antérieur')" />
                                    <label for="roundedCheckbox7">4+ : Fibrine en chambre antérieur
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.present==true">
                        <h5>Hypopion</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.valeur=='Grade 1'" (change)="selecthypopionRight('Grade 1')" />
                                    <label for="roundedCheckbox8">Grade 1</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.valeur=='Grade 2'" (change)="selecthypopionRight('Grade 2')" />
                                    <label for="roundedCheckbox9">Grade 2 </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.valeur=='Grade 3'" (change)="selecthypopionRight('Grade 3')" />
                                    <label for="roundedCheckbox10">Grade 3 </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.present==true">
                        <h5>CA étroite</h5>
                        <div class="row">
                            <label>Test de van herick</label>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.testdevanherick=='Grade 1: < 1/4'" (change)="selectCAetroiteRight('Grade 1: < 1/4')" />
                                    <label for="roundedCheckbox12">Grade 1: < 1/4</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.testdevanherick=='Grade 2 : <1 /4>1/2'" (change)="selectCAetroiteRight('Grade 2 : <1 /4>1/2')" />
                                    <label for="roundedCheckbox13">Grade 2 : <1 /4>1/2</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.testdevanherick=='Grade 3 : >1/2'" (change)="selectCAetroiteRight('Grade 3 : >1/2')" />
                                    <label for="roundedCheckbox14">Grade 3 : >1/2 </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.IP.present==true">
                        <h5>IP</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.IP.valeur=='Transfixiante'" (change)="selectIPRight('Transfixiante')" />
                                    <label for="roundedCheckbox15">Transfixiante</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.IP.valeur=='Non transition'" (change)="selectIPRight('Non transition')" />
                                    <label for="roundedCheckbox16">Non transition</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.IP.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.present==true">
                        <h5>Hyphema</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.valeur=='Grade 1'" (change)="selecthyphemaRight('Grade 1')" />
                                    <label for="roundedCheckbox11">Grade 1</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.valeur=='Grade 2'" (change)="selecthyphemaRight('Grade 2')" />
                                    <label for="roundedCheckbox12">Grade 2 </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.valeur=='Grade 3'" (change)="selecthyphemaRight('Grade 3')" />
                                    <label for="roundedCheckbox13">Grade 3 </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie sous conjonctivale</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Masse cristallinienne</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.recession.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Récession</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.recession.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Cyclodialyse</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Signe du limbe +</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mèche de vitre</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigments</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">ICA</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps étranger</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Test de van herick</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf=" examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present==true ">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Calme et profonde</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.present==true ">
                        <h5>Tyndall cellulaire</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.valeur=='0,5 : Trace (1-5 cellules)'" (change)="selecttyndallcellulaireLeft('0,5 : Trace (1-5 cellules)')" />
                                    <label>0,5 : Trace (1-5 cellules)</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.valeur=='1+ : Minime (6-15 cellules )'" (change)="selecttyndallcellulaireLeft('1+ : Minime (6-15 cellules )')" />
                                    <label>1+ : Minime (6-15 cellules )</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.valeur=='2+ : Modéré (16-25 cellules )'" (change)="selecttyndallcellulaireLeft('2+ : Modéré (16-25 cellules )')" />
                                    <label for="roundedCheckbox2">2+ : Modéré (16-25 cellules )
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.valeur=='3+ : Marqué (26-25 cellules )'" (change)="selecttyndallcellulaireLeft('3+ : Marqué (26-25 cellules )')" />
                                    <label for="roundedCheckbox">3+ : Marqué (26-25 cellules )
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.valeur=='4+ : Intense (> 50 cellules )'" (change)="selecttyndallcellulaireLeft('4+ : Intense (> 50 cellules )')" />
                                    <label for="roundedCheckbox4">4+ : Intense (> 50 cellules )
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.present==true">
                        <h5>Tyndall proteique</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.valeur=='1+ : Minime'" (change)="selecttyndallproteiqueLeft('1+ : Minime')" />
                                    <label for="roundedCheckbox5">1+ : Minime </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.valeur=='2+ : Modéré (détails irien identifiable)'" (change)="selecttyndallproteiqueLeft('2+ : Modéré (détails irien identifiable)')" />
                                    <label for="roundedCheckbox6">2+ : Modéré (détails irien identifiable)
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.valeur=='4+ : Fibrine en chambre antérieur'" (change)="selecttyndallcellulaireLeft('4+ : Fibrine en chambre antérieur')" />
                                    <label for="roundedCheckbox7">4+ : Fibrine en chambre antérieur
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.present==true">
                        <h5>Hypopion</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.valeur=='Grade 1'" (change)="selecthypopionLeft('Grade 1')" />
                                    <label for="roundedCheckbox8">Grade 1</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.valeur=='Grade 2'" (change)="selecthypopionLeft('Grade 2')" />
                                    <label for="roundedCheckbox9">Grade 2 </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.valeur=='Grade 3'" (change)="selecthypopionLeft('Grade 3')" />
                                    <label for="roundedCheckbox10">Grade 3 </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.present==true">
                        <h5>CA étroite</h5>
                        <div class="row">
                            <label>Test de van herick</label>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.testdevanherick=='Grade 1: < 1/4'" (change)="selectCAetroiteLeft('Grade 1: < 1/4')" />
                                    <label for="roundedCheckbox12">Grade 1: < 1/4</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.testdevanherick=='Grade 2 : <1 /4>1/2'" (change)="selectCAetroiteLeft('Grade 2 : <1 /4>1/2')" />
                                    <label for="roundedCheckbox13">Grade 2 : <1 /4>1/2</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.testdevanherick=='Grade 3 : >1/2'" (change)="selectCAetroiteLeft('Grade 3 : >1/2')" />
                                    <label for="roundedCheckbox14">Grade 3 : >1/2 </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.IP.present==true">
                        <h5>IP</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.IP.valeur=='Transfixiante'" (change)="selectIPLeft('Transfixiante')" />
                                    <label for="roundedCheckbox15">Transfixiante</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.IP.valeur=='Non transition'" (change)="selectIPLeft('Non transition')" />
                                    <label for="roundedCheckbox16">Non transition</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.IP.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.present==true">
                        <h5>Hyphema</h5>
                        <div class="row">
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.valeur=='Grade 1'" (change)="selecthyphemaLeft('Grade 1')" />
                                    <label for="roundedCheckbox11">Grade 1</label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.valeur=='Grade 2'" (change)="selecthyphemaLeft('Grade 2')" />
                                    <label for="roundedCheckbox12">Grade 2 </label>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-container">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.valeur=='Grade 3'" (change)="selecthyphemaLeft('Grade 3')" />
                                    <label for="roundedCheckbox13">Grade 3 </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie sous conjonctivale</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Masse cristallinienne</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.recession.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Récession</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.recession.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Cyclodialyse</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Signe du limbe +</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mèche de vitre</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigments</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">ICA</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps étranger</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Test de van herick</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>

                </div>
            </div>
        </div>
    </div>