<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.6.1/fullcalendar.min.css">
<style media="screen">
  #updateButton {
    background-color: transparent;
    color: #9af0cb;
    border-color: #9af0cb;
  }

  .fc {
    text-transform: capitalize !important;
  }

  .btn-margin {
    margin-bottom: 15px;
  }

  .btn-righ {
    margin-right: 10px;
  }
  .dot {
  height: 15px;
  width: 15px;

  border-radius: 50%;
  display: inline-block;
}
#spanColor{
  margin: 0 0 0 5px;
  color:black;
}
</style>
<section style="margin-top:120px">
  <div id="demo">
    <!--     <div class="row">
      <div class="col-12">
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #039be5"></span> <span id="spanColor">Les rendez-vous personnels</span>
        </div>
        <div class="col-3 float-left">
            <span class="dot" style="background-color: #23b1a5"></span> <span id="spanColor">Les créneaux libres</span>
        </div>
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #008000"></span> <span id="spanColor">Les rendez-vous professionnels</span>
        </div>
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #f48a54"></span> <span id="spanColor">Les rendez-vous en attente</span>
        </div>
      </div>
    </div> -->
    <br>
    <div class="row">
      <div class="col-12" style="margin-left: 15px;width: 97%;">
        <ng-fullcalendar id="calendar" [options]="calendarOptions" style="color:black" (eventClick)="eventClick($event.detail.event)" (dayClick)="dayClick($event.detail)"></ng-fullcalendar>
      </div>
    </div>
  </div>
  <br><br><br><br>
</section>
<div id="createEventModal" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content" style="width: 100%;">
      <div class="modal-header">
        <h4 style="color:black;margin: auto;">Créer un examen</h4>
      </div>
      <div id="modalBody" class="modal-body" style="position: relative;padding: 0px;">
        <form [formGroup]="form" class="form-horizontal" role="form" style="position: relative;left: 9%;">
          <div class="col-12">
            <label for="Type">Motif*</label>
            <input type="text" name="type" list="typename" style="background:#e8f7f5;width:80%" class="form-control input-sm inputMotif" formControlName="motif" (change)="addInputMotif($event)" [(ngModel)]="selectedMotif">
            <datalist id="typename">
              <option *ngFor="let motif of motifList" [value]="motif">
            </datalist>
            <div><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;" (click)="removeMotif(mot)">X</div> <br></span></div> 
             <control-messages [control]="form.controls.motif" style="color: red;margin: -10px!important;position: relative;top: 5px;left: 10px;"></control-messages>
          </div>
          <div class="col-12">
            <label for="">Patient*</label>
            <select name="patient" formControlName="patient" class="form-control input-sm">
              <option></option>
              <option *ngFor="let patient of patients" [ngValue]="patient">{{patient.nom}} {{patient.prenom}}</option>
            </select>
            <control-messages [control]="form.controls.patient" style="color: red;margin: -10px!important;position: relative;top: 5px;left: 10px;"></control-messages>
          </div>
          <div class="modal-footer d-flex justify-content-center">
        <div class=" col d-flex justify-content-center buttonsPopup">
          <div class="col-auto nbr-dossier " style="position: relative;left: -45px;">
            <table>
              <tr>
                <td class="tdButt1 ">
                  <button (click)="createConsultation()" [disabled]="!form.valid" class=" close btn-valider"><img src="assets/images/valider.png" class="iconBoutton">Enregistrer</button>
                </td>
                <td class="tdButt2 ">
                  <button class="close btn-annule" data-dismiss="modal"><img src="assets/images/annuler.png" class="iconBoutton">Fermer </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
        </form>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<div id="detailsEventModal" class="modal fade" *ngIf="eventClicked">
  <div class="modal-dialog">
    <div class="modal-content" style="width:100%">
      <div class="modal-header">
        <h4 style="color: #04b3a5;margin: auto;font-weight: bolder;margin-bottom: 15px;">Détails d'un rendez-vous</h4>
      </div>
      <div class="row" style="margin-left: 6%;">
        <div class="col-1" style="   margin-right: 4%;"><img src="assets/images/doc3.png" class="imgPop"></div>
        <div class="col-10" *ngIf="!eventClicked.type">
          <div class="col-12">
            <ul>
              <li>Patient : {{eventClicked.patient.nom}} {{eventClicked.patient.prenom}}</li>
              <li>Age : {{eventClicked.patient.age}}</li>
              <li>Profession : {{eventClicked.patient.profession}}</li>
              <li>Motif : <ul>
                <li *ngFor="let mot of eventClicked.motif">{{mot}}</li>    
              </ul>
            </li>
              <li>Duration : {{eventClicked.duration}}</li>
              <li>De {{eventClicked.start._i | date: 'dd/MM/yyyy , HH:mm'}}</li>
              <li *ngIf="eventClicked && eventClicked.end && eventClicked.end._i">À {{eventClicked.end._i | date: 'dd/MM/yyyy , HH:mm'}}</li>
              <li *ngFor="let tag of eventClicked.tags">
                {{tag}}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-10" *ngIf="eventClicked.type === 'nextAppointment'">
          <div class="col-12">
            <ul>
              <li>Patient : {{eventClicked.patient.nom}} {{eventClicked.patient.prenom}}</li>
              <li>Age : {{eventClicked.patient.age}}</li>
              <li>Profession : {{eventClicked.patient.profession}}</li>
              <li>Motif : <ul>
                <li *ngFor="let mot of eventClicked.nextMotif">{{mot}}</li>    
              </ul>
            </li>
              <li>À {{eventClicked.start._i | date: 'dd/MM/yyyy , HH:mm'}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <div class=" col d-flex justify-content-center buttonsPopup">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td class="tdButt1 ">
                  <button (click)="geToUpdateConsultation(eventClicked)" class=" close btn-valider"><img src="assets/images/valider.png" class="iconBoutton">Modifier</button>
                </td>
                <td class="tdButt2 ">
                  <button class="close btn-annule" data-dismiss="modal"><img src="assets/images/annuler.png" class="iconBoutton">Fermer </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
