import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-annexe',
  templateUrl: './annexe.component.html',
  styleUrls: ['./annexe.component.css', '../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class AnnexeComponent implements OnInit, OnDestroy {
  selectedHistorique: any
  //Anenexe
  annexeNormal = false
  annexeExamen = true
  alternanceRight = true
  alternanceLeft = true
  NistagmusRight = true
  NistagmusLeft = true
  //annexe
  //-------------------autres
  myExamen: any
  examRefraction: any
  examAnexe: any
  examLaf: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  // send data 
  objecttoSend = {
    annexeOD: {},
    annexeOG: {},
    inspectionOD: {},
    inspectionOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""

  };
  // anomalies by a doctor
  anomaliesByDoctor: any[] = []
  inscpectionoldlistAnomalies = ["Normal", "Attitude de malvoyance", "Buphtalmie", "Exphtalmie", "Énophtalmie", "Microphtalmie", "Leucocorie", "Prothèse oculaire", "Plaies", "Paralysie faciale", "Ptosis", "Strabisme"]

  anomalieOeilRightListNew: any[] = []
  anomaliesByDoctorAnnexe: any[] = []
  annexeoldlistAnomalies = ["Normale", "Paralysie occulomotrice", "Ophtalmologie", "Insuffisance de convergence", "Réflet cornéen décentré", "Présence de strabisme", "Plaie", "Dermabrasion", "Ptosis", "Chalazion", "Orgelet", "Entropion", "Ectropion", "Diplopie", "Distichoasis", "Meeibomite", "DGM", "Telangiectasie", "Tumeur paplepbrale", "DCA", "DCC", "Sténose lacrymal", "Masse de la loge lacrymal", "Fistule", "Écoulement lacrymal", "Sonde de DCR en place"]
  newAnomalieAnnexe = ""
  newAnomalieAnnexeGauche = ""
  // end anomalies by a doctor
  p = 1
  oldListConsultations: any[] = []
  displayHistorique = false
  showMore = false
  paginatedList: any[] = [];
  itemsPerPage: number = 1; // Number of items per page
  paginationId: string = 'datePagination'; // ID for pagination
  todayExamen = true
  dataSubscription: Subscription;
  isToastVisible = false
  constructor(private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }

  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // *------------anexe
    if (idExamen && idExamen != undefined && idExamen != null && idPatient && idPatient != undefined && idPatient != null && idPatient != "undefined") {
      this.examenService.getAnexeById(idExamen).subscribe((data: any) => {
        this.examAnexe = data
        if (this.examAnexe) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    // get data
    this.dataSubscription = this.sharedDataService.dataBtn$.subscribe((data) => {
      if (data && data.type && data.type == 'annexe') {
        this.updateExamen(this.examAnexe);
        // setTimeout(() => {

        // }, 3000);
        return true
      }

    });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
  configureDataExamen(idPatient) {
    this.anomalieService.getAnomalieByDoctor("annexe").subscribe(data => {
      this.anomaliesByDoctorAnnexe = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
    this.examenService.getInspectionHistoriques(idPatient, "annexe").subscribe((data: any) => {
      this.oldListConsultations = data.reverse()
    },
      err => {
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }
  displayAndHidetodayExamen() {
    this.todayExamen = !this.todayExamen
  }
  // Start annexe
  updateAnnexeNormal() {
    this.examAnexe.annexe.anomaly.present = false
  }
  updateAnnexeExamen() {
    this.examAnexe.annexe.normal.present = false
  }
  addRemoveFromAnnexeExamenRight(value) {

    this.examAnexe.annexe.anomaly.oielDroit.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examAnexe.annexe.anomaly.oielDroit); this.examAnexe.annexe.anomaly.oielDroit.normale.present = true
        break
      case 'Paralysie occulomotrice': this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.present = !this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.present
        break
      case 'Ophtalmologie': this.examAnexe.annexe.anomaly.oielDroit.ophtalmologie.present = !this.examAnexe.annexe.anomaly.oielDroit.ophtalmologie.present
        break
      case 'Insuffisance de convergence': this.examAnexe.annexe.anomaly.oielDroit.insuffisancedeconvergence.present = !this.examAnexe.annexe.anomaly.oielDroit.insuffisancedeconvergence.present
        break
      case 'Réflet cornéen décentré': this.examAnexe.annexe.anomaly.oielDroit.refletcorneendecentre.present = !this.examAnexe.annexe.anomaly.oielDroit.refletcorneendecentre.present
        break
      case 'Présence de strabisme': this.examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.present = !this.examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.present
        break
      case 'Plaie': this.examAnexe.annexe.anomaly.oielDroit.plaie.present = !this.examAnexe.annexe.anomaly.oielDroit.plaie.present
        break
      case 'Dermabrasion': this.examAnexe.annexe.anomaly.oielDroit.dermabrasion.present = !this.examAnexe.annexe.anomaly.oielDroit.dermabrasion.present
        break
      case 'Ptosis': this.examAnexe.annexe.anomaly.oielDroit.ptosis.present = !this.examAnexe.annexe.anomaly.oielDroit.ptosis.present
        break
      case 'Chalazion': this.examAnexe.annexe.anomaly.oielDroit.chalazion.present = !this.examAnexe.annexe.anomaly.oielDroit.chalazion.present
        break
      case 'Orgelet': this.examAnexe.annexe.anomaly.oielDroit.orgelet.present = !this.examAnexe.annexe.anomaly.oielDroit.orgelet.present
        break
      case 'Entropion': this.examAnexe.annexe.anomaly.oielDroit.entropion.present = !this.examAnexe.annexe.anomaly.oielDroit.entropion.present
        break
      case 'Ectropion': this.examAnexe.annexe.anomaly.oielDroit.ectropion.present = !this.examAnexe.annexe.anomaly.oielDroit.ectropion.present
        break
      case 'Diplopie': this.examAnexe.annexe.anomaly.oielDroit.diplopie.present = !this.examAnexe.annexe.anomaly.oielDroit.diplopie.present
        break
      case 'Distichoasis': this.examAnexe.annexe.anomaly.oielDroit.distichoasis.present = !this.examAnexe.annexe.anomaly.oielDroit.distichoasis.present
        break
      case 'Meeibomite': this.examAnexe.annexe.anomaly.oielDroit.meeibomite.present = !this.examAnexe.annexe.anomaly.oielDroit.meeibomite.present
        break
      case 'DGM': this.examAnexe.annexe.anomaly.oielDroit.dgm.present = !this.examAnexe.annexe.anomaly.oielDroit.dgm.present
        break
      case 'Telangiectasie': this.examAnexe.annexe.anomaly.oielDroit.telangiectasie.present = !this.examAnexe.annexe.anomaly.oielDroit.telangiectasie.present
        break
      case 'Tumeur paplepbrale': this.examAnexe.annexe.anomaly.oielDroit.tumeurpaplepbrale.present = !this.examAnexe.annexe.anomaly.oielDroit.tumeurpaplepbrale.present
        break
      case 'DCA': this.examAnexe.annexe.anomaly.oielDroit.dca.present = !this.examAnexe.annexe.anomaly.oielDroit.dca.present
        break
      case 'DCC': this.examAnexe.annexe.anomaly.oielDroit.dcc.present = !this.examAnexe.annexe.anomaly.oielDroit.dcc.present
        break
      case 'Sténose lacrymal': this.examAnexe.annexe.anomaly.oielDroit.stenoselacrymal.present = !this.examAnexe.annexe.anomaly.oielDroit.stenoselacrymal.present
        break
      case 'Masse de la loge lacrymal': this.examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.present = !this.examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.present
        break
      case 'Fistule': this.examAnexe.annexe.anomaly.oielDroit.fistule.present = !this.examAnexe.annexe.anomaly.oielDroit.fistule.present
        break
      case 'Écoulement lacrymal': this.examAnexe.annexe.anomaly.oielDroit.ecoulementlacrymal.present = !this.examAnexe.annexe.anomaly.oielDroit.ecoulementlacrymal.present
        break
      case 'Sonde de DCR en place': this.examAnexe.annexe.anomaly.oielDroit.sondedeDCRenplace.present = !this.examAnexe.annexe.anomaly.oielDroit.sondedeDCRenplace.present
        break
    }
    this.setToTrue(this.examAnexe.annexe.anomaly.oielDroit)
    this.objecttoSend.annexeOD = this.examAnexe.annexe.anomaly.oielDroit
    this.objecttoSend.source = "annexeOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromAnnexeExamenLeft(value) {
    this.examAnexe.annexe.anomaly.oielGauche.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examAnexe.annexe.anomaly.oielGauche); this.examAnexe.annexe.anomaly.oielGauche.normale.present = true
        break
      case 'Paralysie occulomotrice': this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.present = !this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.present
        break
      case 'Ophtalmologie': this.examAnexe.annexe.anomaly.oielGauche.ophtalmologie.present = !this.examAnexe.annexe.anomaly.oielGauche.ophtalmologie.present
        break
      case 'Insuffisance de convergence': this.examAnexe.annexe.anomaly.oielGauche.insuffisancedeconvergence.present = !this.examAnexe.annexe.anomaly.oielGauche.insuffisancedeconvergence.present
        break
      case 'Réflet cornéen décentré': this.examAnexe.annexe.anomaly.oielGauche.refletcorneendecentre.present = !this.examAnexe.annexe.anomaly.oielGauche.refletcorneendecentre.present
        break
      case 'Présence de strabisme': this.examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.present = !this.examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.present
        break
      case 'Plaie': this.examAnexe.annexe.anomaly.oielGauche.plaie.present = !this.examAnexe.annexe.anomaly.oielGauche.plaie.present
        break
      case 'Dermabrasion': this.examAnexe.annexe.anomaly.oielGauche.dermabrasion.present = !this.examAnexe.annexe.anomaly.oielGauche.dermabrasion.present
        break
      case 'Ptosis': this.examAnexe.annexe.anomaly.oielGauche.ptosis.present = !this.examAnexe.annexe.anomaly.oielGauche.ptosis.present
        break
      case 'Chalazion': this.examAnexe.annexe.anomaly.oielGauche.chalazion.present = !this.examAnexe.annexe.anomaly.oielGauche.chalazion.present
        break
      case 'Orgelet': this.examAnexe.annexe.anomaly.oielGauche.orgelet.present = !this.examAnexe.annexe.anomaly.oielGauche.orgelet.present
        break
      case 'Entropion': this.examAnexe.annexe.anomaly.oielGauche.entropion.present = !this.examAnexe.annexe.anomaly.oielGauche.entropion.present
        break
      case 'Ectropion': this.examAnexe.annexe.anomaly.oielGauche.ectropion.present = !this.examAnexe.annexe.anomaly.oielGauche.ectropion.present
        break
      case 'Diplopie': this.examAnexe.annexe.anomaly.oielGauche.diplopie.present = !this.examAnexe.annexe.anomaly.oielGauche.diplopie.present
        break
      case 'Distichoasis': this.examAnexe.annexe.anomaly.oielGauche.distichoasis.present = !this.examAnexe.annexe.anomaly.oielGauche.distichoasis.present
        break
      case 'Meeibomite': this.examAnexe.annexe.anomaly.oielGauche.meeibomite.present = !this.examAnexe.annexe.anomaly.oielGauche.meeibomite.present
        break
      case 'DGM': this.examAnexe.annexe.anomaly.oielGauche.dgm.present = !this.examAnexe.annexe.anomaly.oielGauche.dgm.present
        break
      case 'Telangiectasie': this.examAnexe.annexe.anomaly.oielGauche.telangiectasie.present = !this.examAnexe.annexe.anomaly.oielGauche.telangiectasie.present
        break
      case 'Tumeur paplepbrale': this.examAnexe.annexe.anomaly.oielGauche.tumeurpaplepbrale.present = !this.examAnexe.annexe.anomaly.oielGauche.tumeurpaplepbrale.present
        break
      case 'DCA': this.examAnexe.annexe.anomaly.oielGauche.dca.present = !this.examAnexe.annexe.anomaly.oielGauche.dca.present
        break
      case 'DCC': this.examAnexe.annexe.anomaly.oielGauche.dcc.present = !this.examAnexe.annexe.anomaly.oielGauche.dcc.present
        break
      case 'Sténose lacrymal': this.examAnexe.annexe.anomaly.oielGauche.stenoselacrymal.present = !this.examAnexe.annexe.anomaly.oielGauche.stenoselacrymal.present
        break
      case 'Masse de la loge lacrymal': this.examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.present = !this.examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.present
        break
      case 'Fistule': this.examAnexe.annexe.anomaly.oielGauche.fistule.present = !this.examAnexe.annexe.anomaly.oielGauche.fistule.present
        break
      case 'Écoulement lacrymal': this.examAnexe.annexe.anomaly.oielGauche.ecoulementlacrymal.present = !this.examAnexe.annexe.anomaly.oielGauche.ecoulementlacrymal.present
        break
      case 'Sonde de DCR en place': this.examAnexe.annexe.anomaly.oielGauche.sondedeDCRenplace.present = !this.examAnexe.annexe.anomaly.oielGauche.sondedeDCRenplace.present
        break
    }
    this.setToTrue(this.examAnexe.annexe.anomaly.oielGauche)
    this.objecttoSend.annexeOG = this.examAnexe.annexe.anomaly.oielGauche
    this.objecttoSend.source = "annexeOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  selectAlternanceRight() {
    this.examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.alternance = false
  }
  selectAlternanceLeft() {
    this.examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.alternance = false
  }
  selectNistagmusRight() {
    this.examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.nistagmus = false
  }
  selectNistagmusLeft() {
    this.examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.nistagmus = false
  }
  selectparalysieOcculomotriceMuscleDroit(valeur) {
    let elementIndex = this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.indexOf(valeur)
    if (elementIndex < 0) {
      this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.push(valeur)
    } else {
      this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.splice(elementIndex, 1)
    }
  }
  selectparalysieOcculomotriceMuscleGauche(valeur) {
    let elementIndex = this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.indexOf(valeur)
    if (elementIndex < 0) {
      this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.push(valeur)
    } else {
      this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.splice(elementIndex, 1)
    }
  }
  selectparalysieOcculomotriceNerfDroit(valeur) {
    let elementIndex = this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.nerf.indexOf(valeur)
    if (elementIndex < 0) {
      this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.nerf.push(valeur)
    } else {
      this.examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.nerf.splice(elementIndex, 1)
    }
  }
  selectparalysieOcculomotriceNerfGauche(valeur) {
    let elementIndex = this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.nerf.indexOf(valeur)
    if (elementIndex < 0) {
      this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.nerf.push(valeur)
    } else {
      this.examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.nerf.splice(elementIndex, 1)
    }
  }
  selectOeilFixateurGauche(value) {
    this.examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.oielFixateurDroit = value
  }
  selectOeilFixateurDroit(value) {
    this.examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.oielFixateurDroit = value
  }
  selectanexeDiploieDroit(value) {
    this.examAnexe.annexe.anomaly.oielDroit.diplopie.type = value
  }
  selectanexeDiploieGauche(value) {
    this.examAnexe.annexe.anomaly.oielGauche.diplopie.type = value
  }
  //!-------- Vérification des signes postives
  // Chalazion
  checkAnnexechalaziontailleRight() {
    if (this.examAnexe.annexe.anomaly.oielDroit.chalazion.taille <= 0) {
      this.examAnexe.annexe.anomaly.oielDroit.chalazion.taille = 0
    }
  }
  checkAnnexechalaziontailleLeft() {
    if (this.examAnexe.annexe.anomaly.oielGauche.chalazion.taille <= 0) {
      this.examAnexe.annexe.anomaly.oielGauche.chalazion.taille = 0
    }
  }
  // Masse de la loge lacrymal
  checkAnnexeMassetailleRight() {
    if (this.examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.taille <= 0) {
      this.examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.taille = 0
    }
  }
  checkAnnexeMassetailleLeft() {
    if (this.examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.taille <= 0) {
      this.examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.taille = 0
    }
  }
  // Plaie
  checkAnnexePlaielongueurRight() {
    if (this.examAnexe.annexe.anomaly.oielDroit.plaie.longueur <= 0) {
      this.examAnexe.annexe.anomaly.oielDroit.plaie.longueur = 0
    }
  }
  checkAnnexePlaielongueurLeft() {
    if (this.examAnexe.annexe.anomaly.oielGauche.plaie.longueur <= 0) {
      this.examAnexe.annexe.anomaly.oielGauche.plaie.longueur = 0
    }
  }
  // annexe dynamic anomaly
  addNewAnomalyAnnexe(event) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorAnnexe && this.anomaliesByDoctorAnnexe.length > 4) {
      showToast("Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieAnnexe = "";
      this.newAnomalieAnnexeGauche = "";
      return false;
    }

    const value = event?.target?.value;
    if (value && value.length > 1 && value.length < 100) {
      const anomalyExists = this.anomaliesByDoctorAnnexe.some(x => x.titre?.toLowerCase() === value.toLowerCase());
      const oldAnomalyExists = this.annexeoldlistAnomalies.some(x => x.toLowerCase() === value.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          "titre": value.charAt(0).toUpperCase() + value.slice(1),
          "description": "",
          "zone": "annexe",
        }).subscribe((data) => {
          this.newAnomalieAnnexe = "";
          this.newAnomalieAnnexeGauche = "";
          this.anomaliesByDoctorAnnexe.push(data);
          showToast('Bien enregistré', '#386641', '#386641', '#6a994e', '#dde5b6');
        });
        return true;
      } else {
        showToast("L'anomalie existe dans la liste", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
        this.newAnomalieAnnexe = "";
        this.newAnomalieAnnexeGauche = "";
        return false;
      }
    } else {
      showToast('Merci de saisir au moins 2 caractères', '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieAnnexe = "";
      this.newAnomalieAnnexeGauche = "";
      return false;
    }
  }

  addRemoveFromAnnexeExamenRightNew(value, id) {
    this.examAnexe.annexe.anomaly.oielDroit.normale.present = false
    let truefalseexist = this.examAnexe.annexe.anomaly.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examAnexe.annexe.anomaly.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.annexeOD = this.examAnexe.annexe.anomaly.oielDroit
      this.objecttoSend.source = "annexeOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examAnexe.annexe.anomaly.oielDroit.allAnomalies = this.examAnexe.annexe.anomaly.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.annexeOD = this.examAnexe.annexe.anomaly.oielDroit
      this.objecttoSend.source = "annexeOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examAnexe.annexe.anomaly.oielDroit)
      return true;
    }

  }
  checkExistAnmalieAnnexeRight(id) {
    return this.examAnexe.annexe.anomaly.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left inspection dyna
  addRemoveFromAnnexeExamenLeftNew(value, id) {
    this.examAnexe.annexe.anomaly.oielGauche.normale.present = false
    let truefalseexist = this.examAnexe.annexe.anomaly.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examAnexe.annexe.anomaly.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.annexeOG = this.examAnexe.annexe.anomaly.oielGauche
      this.objecttoSend.source = "annexeOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examAnexe.annexe.anomaly.oielGauche.allAnomalies = this.examAnexe.annexe.anomaly.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.annexeOG = this.examAnexe.annexe.anomaly.oielGauche
      this.objecttoSend.source = "annexeOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setToTrue(this.examAnexe.annexe.anomaly.oielGauche)
      return true;
    }

  }
  checkExistAnmalieAnnexeLeft(id) {
    return this.examAnexe.annexe.anomaly.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'annexe') {
        this.anomaliesByDoctorAnnexe.splice(this.anomaliesByDoctorAnnexe.indexOf(anomaly), 1)
      }
      if (zone === 'inspection') {
        this.anomaliesByDoctor.splice(this.anomaliesByDoctor.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }
  openCons(historique) {
    window.open(`medecin/ajout/interrogatoire/${this.patient._id}/${historique.id}`, '_blank')

  }
  displayAndHideHistorique() {
    this.displayHistorique = !this.displayHistorique
    this.setPage(1)
  }
  showMoreAnomalies() {
    this.showMore = !this.showMore
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }
  setToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "normale") {
        allPresentFalse = false
      }

    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.normale.present = true;
    }
    return myObject
  }

  // hisotric functions
  onDateSelect(date: string) {
    this.selectedHistorique = this.oldListConsultations.find(consultation => consultation.start === date);
  }
  pageChanged(event: any): void {
    this.setPage(event);
  }

  setPage(page: number): void {
    this.p = page;
    const index = (page - 1) * this.itemsPerPage;
    this.paginatedList = [this.oldListConsultations[index]]; // Get the single item for the current page
    this.onDateSelect(this.oldListConsultations[index].start)
  }


  previousPage(): void {
    if (this.p > 1) {
      this.p--;
      this.setPage(this.p);
    }
  }

  nextPage(): void {
    const totalPages = this.oldListConsultations.length;
    if (this.p < totalPages) {
      this.p++;
      this.setPage(this.p);
    }
  }




}
