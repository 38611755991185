import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,  Validators } from '@angular/forms'
import { ConsultationService } from '../../../../services/consultation/consultation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidateService } from '../../../../services/validate/validate.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { ValidationService } from '../../../../services/validate/validateError.service';
import { MedecinService } from '../../../../services/profils/medecin/medecin.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { PatientService } from '../../../../services/profils/patient/patient.service';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NotificationService } from '../../../../services/notification/notification.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-ajout-medecin-traitant',
  templateUrl: './ajout-medecin-traitant.component.html',
  styleUrls: ['../../../login/login-medecin/login-medecin.component.css','../../../register/register/register.component.scss','../../consultation/update-consultation/update-consultation.component.css']

})
export class AjoutMedecinTraitantComponent implements OnInit {
  form: any;
  medecin: any;
  pays: any[] = []
  secretaire: any;
  patientList = false;
  patient: any;
  medecins: any[] = []
  staticMedecinscraped: any[] = []
  myMedecin: any;
  showedValue: any;
  selectedMedecinn: any;
  formm: any;
  lengthMedecin:any;
  pageNumber = 0
  errorPatient:any;
  successRegister:any;
  errorNumeroInscription:any
  constructor(private router: Router, private consultationService: ConsultationService, private medecinService: MedecinService, private activatedRoute: ActivatedRoute, private _sanitizer: DomSanitizer,
    private formBuilder: FormBuilder, private validateService: ValidateService, private patientService: PatientService,private notificationService:NotificationService,
    private authService: AuthService) {

  }


  ngOnInit() {
    this.pays = pays.sort(tri);
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, ValidationService.emailValidator, Validators.maxLength(50)]],
      tel: ['+216', [Validators.required, ValidationService.phonenumber]],
      telFixe: ['+216'],
      telPerso: ['+216'],
      numInscription: ['', [ ValidationService.numberValidator, Validators.maxLength(25)]],
      specialite: ['', Validators.required],
      dateAnniversaire: ['', [ ValidationService.dateValidator]],
      sexe: ['Masculin'],
      adresseCabinet: this.formBuilder.group({
        adresse: ['', [ Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [ Validators.minLength(3), Validators.maxLength(25)]],
        pays: ['Tunisie', ],
        codePostal: ['', [ValidationService.numberValidator, Validators.maxLength(4)]],
      }),
      diplome: ['', [ Validators.minLength(3), Validators.maxLength(25)]],
      codeCNAM: ['', [ValidationService.numberValidator, Validators.maxLength(25)]],
      centreCNAM: ['', Validators.maxLength(25)],
      dateDebutFonction: [''],
      patientList: [''],

    })
    this.formm = this.formBuilder.group({
      selectedMedecin: ['', Validators.required],
    })
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
    },
      err => {
        console.log(err);
        return false;
      });
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile:any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;
      this.medecinService.getMedecin(medecinId).subscribe((medecin:any) => {
        this.medecin = medecin;
      },
        err => {
          return false;
        });
      this.authService.getProfileSecretaire().subscribe((profile:any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    this.medecinService.getMedecinScapedcount().subscribe(length => {
      this.lengthMedecin = length;
    },
      err => {
        return false;
      });
    this.medecinService.getMedecinAccepted().subscribe((medecins:any[]) => {
      this.medecinService.getMedecinScapedBypage(this.pageNumber, 2000).subscribe((medecinsss:any[]) => {
        this.medecins = medecins.concat(medecinsss);
        this.staticMedecinscraped = medecinsss
        this.suivreMedecin(this.pageNumber + 1)
        //console.log(this.medecins.length)
      },
        err => {
          return false;
        });

    },
      err => {
        console.log(err);
        return false;
      });
  }
  suivreMedecin(counter) {
    if (this.lengthMedecin === this.staticMedecinscraped.length) {
      return true;
    }
    this.medecinService.getMedecinScapedBypage(counter, 2000).subscribe((medecinsss:any[]) => {
      this.medecins.push(...medecinsss)
      this.staticMedecinscraped.push(...medecinsss)
      //this.mesMedecins.push(...medecinsss);
      counter++
      setTimeout(() => {    //<<<---    using ()=> syntax
        this.suivreMedecin(counter);
      }, 1000);
      return true;
    },
      err => {
        return false;
      });
  }
  private scrollToBottom(elem) {
    elem.scrollTop = elem.scrollHeight;
  }

  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  myCallback(event) {
    if (event._id) {
      this.selectedMedecinn = event
      if (!event.prenom) {
        this.showedValue = event.nom + ' ' + event.specialite
      }
      if (event.prenom) {
        this.showedValue = event.nom + ' ' + event.prenom + ' ' + event.specialite
      }
    }
  }
  autocompleListFormatterPrenom = (data: any): SafeHtml => {
    if (data.nom != undefined && data.prenom != undefined) {
      var html = `<span>${data.nom} ${data.prenom}</span>`;
    }
    if (data.prenom == undefined) {
      var html = `<span>${data.nom}</span>`;
    }
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  onRegisterMedecinn() {
    if (this.selectedMedecinn && this.selectedMedecinn._id) {
      const found = this.patient.listMedecinsAddByMedecin.some(item => item._id === this.selectedMedecinn._id);
      const foundd = this.patient.listMedecins.some(item => item._id === this.selectedMedecinn._id);
      if (!found) {
        var newMed = {
          "_id": this.selectedMedecinn._id,
          "nom": this.selectedMedecinn.nom,
          "prenom": this.selectedMedecinn.prenom,
          "specialite": this.selectedMedecinn.specialite,
        }
        this.patient.listMedecinsAddByMedecin.push(newMed)
        if (!foundd) {
          this.patient.listMedecins.push(newMed)
        }
        var newPatient = {
          _id: this.patient._id,
          listMedecinsAddByMedecin: this.patient.listMedecinsAddByMedecin,
          listMedecins: this.patient.listMedecins
        }
        this.patientService.editProfil(newPatient).subscribe(_patient => {
        });
        setTimeout(() => {
          Swal.fire( {
              imageUrl: 'assets/images/patsDetails/plus-3.png',
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: 'Custom image',
              width: 600,
              title: 'BIEN AJOUTÉ',
              timer: 1500,
              showConfirmButton: false
            }
          );
          this.router.navigate(['/medecin/consultation-liste', this.patient._id]);
        }, 2000);
      }
      if (found) {
        Swal.fire( {
            imageUrl: 'assets/images/patsDetails/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'MÉDECIN EXISTE !',
            timer: 1500,
            showConfirmButton: false
          }
        );
      }
    }
  }


  onRegisterMedecin() {
    this.form.value.accepted = false;

    this.medecinService.registerMedecinIntegre(this.form.value).subscribe((medecin:any) => {
      if(medecin){
        var notification = {
          texte: this.medecin.nom + ' ' + this.medecin.prenom + ' a ajouté un médecin traitant pour le patient '+this.patient.nom + ' '+this.patient.prenom,
          lien: 'medecins',
          notifAdmin: true
        }
        this.notificationService.addNotification(notification).subscribe(notification => {
        });

        var newMed = {
          "_id": medecin._id,
          "nom": medecin.nom,
          "prenom": medecin.prenom,
          "specialite": medecin.specialite,
        }
        this.patient.listMedecinsAddByMedecin.push(newMed)
        this.patient.listMedecins.push(newMed)
        var newPatient = {
          _id: this.patient._id,
          listMedecinsAddByMedecin: this.patient.listMedecinsAddByMedecin,
          listMedecins: this.patient.listMedecins
        }
        this.patientService.editProfil(newPatient).subscribe(_patient => {
        });
        Swal.fire( {
            imageUrl: 'assets/images/patsDetails/plus-3.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'BIEN AJOUTÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
        setTimeout(() => {
          this.router.navigate(['/consultation-liste', this.patient._id]);
        }, 2000);
      }
    });
  }

}

