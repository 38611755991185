import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-cristallin',
  templateUrl: './cristallin.component.html',
  styleUrls: ['./cristallin.component.css', '../laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class CristallinComponent implements OnInit {
  //---------------------------------- cristalin
  cristallinTransparentAE = false;
  displayCristallin = true
  cristallinListLeft = []
  cristallinListRight = []
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  //!----- anomalies by a doctor

  //Cristallin : Transparent
  anomaliesByDoctorCristallin: any[] = []
  oldlistAnomaliesCristallin = ["Transparente", "Aphaquie", "Capsule antérieure calcifiée", "Capsule antérieure rompue", "Cataracte", "Corps étranger", "Ectopie", "Fragilité zonulaire", "Glaucome flecken", "Luxation/subluxation", "Microspheiohaquie", "Phacosclérose", "Phacodonésis", "Pseudophaque", "SPE",]
  newAnomalieCristallin = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreCristalin = false
  //end of Show More Variables

  // send object
  objecttoSend = {
    cristallinOD: {},
    cristallinOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }
  isToastVisible = false

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-cristallin').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-cristallinTransparent", 'value': this.examLaf.laf.cristallinTransparent });
  }

  showMoreCristalinSection() {
    this.showMoreCristalin = !this.showMoreCristalin
  }
  configureDataExamen(idPatient) {
    // !Historique LAF
    // this.examenService.getInspectionHistoriques(idPatient,"laf").subscribe((data:any) => {
    //  this.oldListConsultations=data
    //  },
    //    err => {
    //      return false;
    //    });
    // !Cristallin : Transparent
    this.anomalieService.getAnomalieByDoctor("laf-cristallin").subscribe(data => {
      this.anomaliesByDoctorCristallin = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',

        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }

  displayAndHideCristallin() {
    this.displayCristallin = !this.displayCristallin
  }
  // ------------------------------------------------------------------ Cristallin : Transparent

  clickcristallinTransparentAE() {
    this.examLaf.laf.cristallinTransparent.present = false
  }
  addRemoveFromCristalinRight(value) {
    this.examLaf.laf.cristallinTransparent.oielDroit.transparent.present = false
    switch (value) {
      case 'Transparent': this.setAllValueToFlase(this.examLaf.laf.cristallinTransparent.oielDroit); this.examLaf.laf.cristallinTransparent.oielDroit.transparent.present = true
        break
      case 'Aphaquie': this.examLaf.laf.cristallinTransparent.oielDroit.aphaquie.present = !this.examLaf.laf.cristallinTransparent.oielDroit.aphaquie.present
        break
      case 'Pseudophaque': this.examLaf.laf.cristallinTransparent.oielDroit.pseudophaque.present = !this.examLaf.laf.cristallinTransparent.oielDroit.pseudophaque.present
        break
      case 'Microspheiohaquie': this.examLaf.laf.cristallinTransparent.oielDroit.microspheiohaquie.present = !this.examLaf.laf.cristallinTransparent.oielDroit.microspheiohaquie.present
        break
      case 'SPE': this.examLaf.laf.cristallinTransparent.oielDroit.SPE.present = !this.examLaf.laf.cristallinTransparent.oielDroit.SPE.present
        break
      case 'Capsule antérieure calcifiée': this.examLaf.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.present = !this.examLaf.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.present
        break
      case 'Fragilité zonulaire': this.examLaf.laf.cristallinTransparent.oielDroit.fragilitezonulaire.present = !this.examLaf.laf.cristallinTransparent.oielDroit.fragilitezonulaire.present
        break
      case 'Glaucome flecken': this.examLaf.laf.cristallinTransparent.oielDroit.glaucomeflecken.present = !this.examLaf.laf.cristallinTransparent.oielDroit.glaucomeflecken.present
        break
      case 'Phacodonésis': this.examLaf.laf.cristallinTransparent.oielDroit.phacodonesis.present = !this.examLaf.laf.cristallinTransparent.oielDroit.phacodonesis.present
        break
      case 'Luxation/subluxation': this.examLaf.laf.cristallinTransparent.oielDroit.luxationSubluxation.present = !this.examLaf.laf.cristallinTransparent.oielDroit.luxationSubluxation.present
        break
      case 'Ectopie': this.examLaf.laf.cristallinTransparent.oielDroit.ectopie.present = !this.examLaf.laf.cristallinTransparent.oielDroit.ectopie.present
        break
      case 'Corps étranger': this.examLaf.laf.cristallinTransparent.oielDroit.corpsetranger.present = !this.examLaf.laf.cristallinTransparent.oielDroit.corpsetranger.present
        break
      case 'Capsule antérieure rompue': this.examLaf.laf.cristallinTransparent.oielDroit.capsuleantrompu.present = !this.examLaf.laf.cristallinTransparent.oielDroit.capsuleantrompu.present
        break
      case 'Phacosclérose': this.examLaf.laf.cristallinTransparent.oielDroit.phacosclerose.present = !this.examLaf.laf.cristallinTransparent.oielDroit.phacosclerose.present
        break
      case 'Cataracte': this.examLaf.laf.cristallinTransparent.oielDroit.cataracte.present = !this.examLaf.laf.cristallinTransparent.oielDroit.cataracte.present
        break
    }
    this.setCristallinToTrue(this.examLaf.laf.cristallinTransparent.oielDroit)
    this.objecttoSend.cristallinOD = this.examLaf.laf.cristallinTransparent.oielDroit
    this.objecttoSend.source = "cristallinOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromCristalinLeft(value) {
    this.examLaf.laf.cristallinTransparent.oielGauche.transparent.present = false
    switch (value) {
      case 'Transparent': this.setAllValueToFlase(this.examLaf.laf.cristallinTransparent.oielGauche); this.examLaf.laf.cristallinTransparent.oielGauche.transparent.present = true
        break
      case 'Aphaquie': this.examLaf.laf.cristallinTransparent.oielGauche.aphaquie.present = !this.examLaf.laf.cristallinTransparent.oielGauche.aphaquie.present
        break
      case 'Pseudophaque': this.examLaf.laf.cristallinTransparent.oielGauche.pseudophaque.present = !this.examLaf.laf.cristallinTransparent.oielGauche.pseudophaque.present
        break
      case 'Microspheiohaquie': this.examLaf.laf.cristallinTransparent.oielGauche.microspheiohaquie.present = !this.examLaf.laf.cristallinTransparent.oielGauche.microspheiohaquie.present
        break
      case 'SPE': this.examLaf.laf.cristallinTransparent.oielGauche.SPE.present = !this.examLaf.laf.cristallinTransparent.oielGauche.SPE.present
        break
      case 'Capsule antérieure calcifiée': this.examLaf.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.present = !this.examLaf.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.present
        break
      case 'Fragilité zonulaire': this.examLaf.laf.cristallinTransparent.oielGauche.fragilitezonulaire.present = !this.examLaf.laf.cristallinTransparent.oielGauche.fragilitezonulaire.present
        break
      case 'Glaucome flecken': this.examLaf.laf.cristallinTransparent.oielGauche.glaucomeflecken.present = !this.examLaf.laf.cristallinTransparent.oielGauche.glaucomeflecken.present
        break
      case 'Phacodonésis': this.examLaf.laf.cristallinTransparent.oielGauche.phacodonesis.present = !this.examLaf.laf.cristallinTransparent.oielGauche.phacodonesis.present
        break
      case 'Luxation/subluxation': this.examLaf.laf.cristallinTransparent.oielGauche.luxationSubluxation.present = !this.examLaf.laf.cristallinTransparent.oielGauche.luxationSubluxation.present
        break
      case 'Ectopie': this.examLaf.laf.cristallinTransparent.oielGauche.ectopie.present = !this.examLaf.laf.cristallinTransparent.oielGauche.ectopie.present
        break
      case 'Corps étranger': this.examLaf.laf.cristallinTransparent.oielGauche.corpsetranger.present = !this.examLaf.laf.cristallinTransparent.oielGauche.corpsetranger.present
        break
      case 'Capsule antérieure rompue': this.examLaf.laf.cristallinTransparent.oielGauche.capsuleantrompu.present = !this.examLaf.laf.cristallinTransparent.oielGauche.capsuleantrompu.present
        break
      case 'Phacosclérose': this.examLaf.laf.cristallinTransparent.oielGauche.phacosclerose.present = !this.examLaf.laf.cristallinTransparent.oielGauche.phacosclerose.present
        break
      case 'Cataracte': this.examLaf.laf.cristallinTransparent.oielGauche.cataracte.present = !this.examLaf.laf.cristallinTransparent.oielGauche.cataracte.present
        break
    }
    this.setCristallinToTrue(this.examLaf.laf.cristallinTransparent.oielGauche)
    this.objecttoSend.cristallinOG = this.examLaf.laf.cristallinTransparent.oielGauche
    this.objecttoSend.source = "cristallinOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;

  }
  selectpseudophaqueRight(value) {
    this.examLaf.laf.cristallinTransparent.oielDroit.pseudophaque.valeur = value
  }
  selectpseudophaqueLeft(value) {
    this.examLaf.laf.cristallinTransparent.oielGauche.pseudophaque.valeur = value
  }


  //!--------------------Cristallin : Transparent
  addNewAnomalyCristallin(event) {
    const maxAnomalies = 5;
    const inputValue = event?.target?.value;

    const showToast = (message, messageColor, backgroundColor, progressBarColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        const self = this;
        this.iziToast.show({
          message,
          messageColor,
          titleColor: messageColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: function () {
            self.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorCristallin && this.anomaliesByDoctorCristallin.length >= maxAnomalies) {
      showToast(
        "Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies",
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieCristallin = ""
      return false;
    }

    if (inputValue && inputValue.length > 1 && inputValue.length < 100) {
      const anomalyExists = this.anomaliesByDoctorCristallin.some(x => x.titre?.toLowerCase() === inputValue.toLowerCase());
      const oldAnomalyExists = this.oldlistAnomaliesCristallin.some(x => x.toLowerCase() === inputValue.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          titre: inputValue[0].toUpperCase() + inputValue.slice(1),
          description: "",
          zone: "laf-cristallin",
        }).subscribe((data) => {
          this.newAnomalieCristallin = "";
          this.anomaliesByDoctorCristallin.push(data);
          showToast(
            'Bien enregistré',
            '#386641',
            '#dde5b6',
            '#6a994e',
          );
        });
        return true;
      } else {
        showToast(
          "L'anomalie existe dans la liste",
          '#800f2f',
          '#ff8fa3',
          '#c9184a'
        );
        this.newAnomalieCristallin = ""
        return false;

      }
    } else {
      showToast(
        'Merci de saisir au moins 2 caractères',
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieCristallin = ""
      return false;
    }
  }


  // Right Cristallin
  addRemoveFromCristallinRightNew(value, id) {
    this.examLaf.laf.cristallinTransparent.oielDroit.transparent.present = false
    let truefalseexist = this.examLaf.laf.cristallinTransparent.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.cristallinTransparent.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.cristallinOD = this.examLaf.laf.cristallinTransparent.oielDroit
      this.objecttoSend.source = "cristallinOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.cristallinTransparent.oielDroit.allAnomalies = this.examLaf.laf.cristallinTransparent.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.cristallinOD = this.examLaf.laf.cristallinTransparent.oielDroit
      this.objecttoSend.source = "cristallinOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setCristallinToTrue(this.examLaf.laf.cristallinTransparent.oielDroit)
      return true;
    }

  }
  checkExistAnomalieCristallinRight(id) {
    return this.examLaf.laf.cristallinTransparent.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Cristallin dyna
  addRemoveFromCristallinLeftNew(value, id) {
    this.examLaf.laf.cristallinTransparent.oielGauche.transparent.present = false
    let truefalseexist = this.examLaf.laf.cristallinTransparent.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.cristallinTransparent.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.cristallinOG = this.examLaf.laf.cristallinTransparent.oielGauche
      this.objecttoSend.source = "cristallinOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.cristallinTransparent.oielGauche.allAnomalies = this.examLaf.laf.cristallinTransparent.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.cristallinOG = this.examLaf.laf.cristallinTransparent.oielGauche
      this.objecttoSend.source = "cristallinOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setCristallinToTrue(this.examLaf.laf.cristallinTransparent.oielGauche)
      return true;
    }

  }
  checkExistAnomalieCristallinLeft(id) {
    return this.examLaf.laf.cristallinTransparent.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of Cristallin : Transparent
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-cristallin') {
        this.anomaliesByDoctorCristallin.splice(this.anomaliesByDoctorCristallin.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }
  // Cristalin true
  setCristallinToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "transparent") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.transparent.present = true;
    }
    return myObject
  }


}
