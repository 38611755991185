import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-sclere',
  templateUrl: './sclere.component.html',
  styleUrls: ['./sclere.component.css', '../../laf/laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class SclereComponent implements OnInit {
  //---------------------------------------- sclere
  sclere = false;
  sclereListLeft = [
    "Blanche et intacte",
    "Plaie",
    "Scléromalacie",
    "Episclérite",
    "Sclérite",
  ];
  sclereListRight = [
    "Blanche et intacte",
    "Plaie",
    "Scléromalacie",
    "Episclérite",
    "Sclérite",
  ];
  findedScler: any
  taillePlaieLeft: number
  taillePlaieRight: number
  locationPlaieLeft: any
  locationPlaieRight: any
  locationScleriteLeft: any
  locationScleriteRight: any
  nodulaireScleriteLeft: any
  nodulaireScleriteRight: any

  necrosanteScleriteRight: any
  necrosanteScleriteLeft: any

  //-------------------autres
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  oldListConsultations: any[] = []
  //!----- anomalies by a doctor
  //  sclere
  anomaliesByDoctorSclere: any[] = []
  oldlistAnomaliesSclere = ["Blanche et intacte", "Episclérite", "Plaie", "Scléromalacie", "Sclérite"]
  newAnomalieSclere = "";

  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreSclere = false
  //end of Show More Variables
  displaySclere = true
  // send data 
  objecttoSend = {
    annexeOD: {},
    annexeOG: {},
    inspectionOD: {},
    inspectionOG: {},
    sclereOD: {},
    sclereOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false
  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-sclere').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }


    this.setupEventListeners();
  }

  setupEventListeners(): void {
    // Add event listener for clicks on any element in the component
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    // Add event listener for updates (example: input value change)
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-sclere", 'value': this.examLaf.laf.sclere });
  }

  displayAndHideSclere() {
    this.displaySclere = !this.displaySclere
  }
  // show More
  showMoreSclereSection() {
    this.showMoreSclere = !this.showMoreSclere
  }

  configureDataExamen(idPatient) {
    // !Historique LAF
    // this.examenService.getInspectionHistoriques(idPatient,"laf").subscribe((data:any) => {
    //  this.oldListConsultations=data
    //  },
    //    err => {
    //      return false;
    //    });
    //! Sclère : Blanche et intacte
    this.anomalieService.getAnomalieByDoctor("laf-sclere").subscribe(data => {
      this.anomaliesByDoctorSclere = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });

  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }


  showSclere() {
    this.examLaf.laf.sclere.present = false;
  }


  addRemoveFromSclereRight(value) {
    this.examLaf.laf.sclere.oielDroit.blancheEtIntacte.present = false
    switch (value) {
      case 'Blanche et intacte': this.setAllValueToFlase(this.examLaf.laf.sclere.oielDroit); this.examLaf.laf.sclere.oielDroit.blancheEtIntacte.present = true
        break
      case 'Plaie': this.examLaf.laf.sclere.oielDroit.plaie.present = !this.examLaf.laf.sclere.oielDroit.plaie.present
        break
      case 'Scléromalacie': this.examLaf.laf.sclere.oielDroit.scleromalacie.present = !this.examLaf.laf.sclere.oielDroit.scleromalacie.present
        break
      case 'Episclérite': this.examLaf.laf.sclere.oielDroit.episclerite.present = !this.examLaf.laf.sclere.oielDroit.episclerite.present
        break
      case 'Sclérite': this.examLaf.laf.sclere.oielDroit.sclerite.present = !this.examLaf.laf.sclere.oielDroit.sclerite.present
        break
    }
    this.setSclereToTrue(this.examLaf.laf.sclere.oielDroit)
    this.objecttoSend.sclereOD = this.examLaf.laf.sclere.oielDroit
    this.objecttoSend.source = "sclereOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromSclereLeft(value) {
    this.examLaf.laf.sclere.oielGauche.blancheEtIntacte.present = false
    switch (value) {
      case 'Blanche et intacte': this.setAllValueToFlase(this.examLaf.laf.sclere.oielGauche); this.examLaf.laf.sclere.oielGauche.blancheEtIntacte.present = true
        break
      case 'Plaie': this.examLaf.laf.sclere.oielGauche.plaie.present = !this.examLaf.laf.sclere.oielGauche.plaie.present
        break
      case 'Scléromalacie': this.examLaf.laf.sclere.oielGauche.scleromalacie.present = !this.examLaf.laf.sclere.oielGauche.scleromalacie.present
        break
      case 'Episclérite': this.examLaf.laf.sclere.oielGauche.episclerite.present = !this.examLaf.laf.sclere.oielGauche.episclerite.present
        break
      case 'Sclérite': this.examLaf.laf.sclere.oielGauche.sclerite.present = !this.examLaf.laf.sclere.oielGauche.sclerite.present
        break
    }
    this.setSclereToTrue(this.examLaf.laf.sclere.oielGauche)
    this.objecttoSend.sclereOG = this.examLaf.laf.sclere.oielGauche
    this.objecttoSend.source = "sclereOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;

  }
  onChangetaillePlaieLeft() {
    if (this.examLaf.laf.sclere.oielGauche.plaie.taille <= 0) {
      this.examLaf.laf.sclere.oielGauche.plaie.taille = 0
    }

  }
  onChangetaillePlaieRight() {
    if (this.examLaf.laf.sclere.oielDroit.plaie.taille <= 0) {
      this.examLaf.laf.sclere.oielDroit.plaie.taille = 0
    }
  }
  selectSclerescleritenodulaireRight(value) {
    this.examLaf.laf.sclere.oielDroit.sclerite.nodulaire = value
  }
  selectSclerescleritenodulaireLeft(value) {
    this.examLaf.laf.sclere.oielGauche.sclerite.nodulaire = value
  }
  selectSclerescleritenecrosanteRight(value) {
    this.examLaf.laf.sclere.oielDroit.sclerite.necrosante = value
  }
  selectSclerescleritenecrosanteLeft(value) {
    this.examLaf.laf.sclere.oielGauche.sclerite.necrosante = value
  }

  //!--------------------Sclère : Blanche et intacte
  // sclere dynamic anomaly
  addNewAnomalySclere(event) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorSclere?.length > 4) {
      showToast("Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieSclere = "";
      return false;
    }

    const value = event?.target?.value;
    if (value && value.length > 1 && value.length < 100) {
      const anomalyExists = this.anomaliesByDoctorSclere.some(x => x.titre?.toLowerCase() === value.toLowerCase());
      const oldAnomalyExists = this.oldlistAnomaliesSclere.some(x => x.toLowerCase() === value.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          "titre": value[0].toUpperCase() + value.slice(1),
          "description": "",
          "zone": "laf-sclere"
        }).subscribe((data) => {
          this.newAnomalieSclere = "";
          this.anomaliesByDoctorSclere.push(data);
          showToast('Bien enregistré', '#386641', '#386641', '#6a994e', '#dde5b6');
        });
        return true;
      } else {
        showToast("L'anomalie existe dans la liste", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
        this.newAnomalieSclere = "";
        return false;
      }
    } else {
      showToast('Merci de saisir au moins 2 caractères', '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieSclere = "";
      return false;
    }
  }

  // Right sclere 
  addRemoveFromSclereRightNew(value, id) {
    this.examLaf.laf.sclere.oielDroit.blancheEtIntacte.present = false
    let truefalseexist = this.examLaf.laf.sclere.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.sclere.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.sclereOD = this.examLaf.laf.sclere.oielDroit
      this.objecttoSend.source = "sclereOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.sclere.oielDroit.allAnomalies = this.examLaf.laf.sclere.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.sclereOD = this.examLaf.laf.sclere.oielDroit
      this.objecttoSend.source = "sclereOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setSclereToTrue(this.examLaf.laf.sclere.oielDroit)
      return true;
    }

  }
  checkExistAnomalieSclereRight(id) {
    return this.examLaf.laf.sclere.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left sclere dyna
  addRemoveFromSclereLeftNew(value, id) {
    this.examLaf.laf.sclere.oielGauche.blancheEtIntacte.present = false
    let truefalseexist = this.examLaf.laf.sclere.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.sclere.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.sclereOG = this.examLaf.laf.sclere.oielGauche
      this.objecttoSend.source = "sclereOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.sclere.oielGauche.allAnomalies = this.examLaf.laf.sclere.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.sclereOG = this.examLaf.laf.sclere.oielGauche
      this.objecttoSend.source = "sclereOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setSclereToTrue(this.examLaf.laf.sclere.oielGauche)
      return true;
    }

  }
  checkExistAnomalieSclereLeft(id) {
    return this.examLaf.laf.sclere.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !------------------end of Sclère : Blanche et intacte

  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-sclere') {
        this.anomaliesByDoctorSclere.splice(this.anomaliesByDoctorSclere.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }
  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }
  // sclere
  setSclereToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "blancheEtIntacte") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.blancheEtIntacte.present = true;
    }
    return myObject
  }

}
