<div id="patientsidposition">

</div>
<section class="fond">
  <section class=" container2 ">
    <div class="searchPat ">
      <div class=" search4">
        <h4>
          TROUVER UN PATIENT SUR MEDIVISTO

        </h4>
        <br>
        <form [formGroup]="form">
          <div class="row">

            <div class="col-3">
              <input type="text" formControlName="identifiant" name="identifiant" placeholder="ID Mediv">
              <control-messages [control]="form.controls.identifiant" style="position: relative;top: 25px;"></control-messages>
              <p *ngIf="erridentifiant" style="color:red">Champ invalide (minimum 4 caractères, maximum 25 caratéres)</p>
            </div>
            <div class="col-3">
              <input type="date" formControlName="dateAnniversaire" name="dateAnniversaire" placeholder="Date de naissance" id="serachBydateNais">
              <control-messages [control]="form.controls.dateAnniversaire" style="position: relative;top: 25px;"></control-messages>
              <p *ngIf="errDate" style="color:red">Champ invalide</p>
            </div>
            <div class="col-4">
              <button class="btn btn-search3" (click)="recherche()" title="Trouver un patient sur MEDIVISTO" style="background-image:url('assets/images/search_2.png') !important"><i class="fas fa-search" style="visibility: hidden;"></i></button>
            </div>
          </div>
        </form>
        <br>
        <h4>
          RÉSULTATS DE RECHERCHE
        </h4>
        <br>
      </div>

    </div>
  </section>

  <section style="padding-top: 115px">
    <div class="div-tab">
      <div class="row" style="overflow-x:auto;">
        <table class="table  ">
          <thead>
            <th id="firstThTable"></th>
            <th class="text-center"><b>ID MEDIV</b></th>
            <th class="text-center"><b>NOM</b></th>
            <th class="text-center"><b>PRÉNOM</b></th>
            <th class="text-center"><b>EMAIL</b></th>
            <th class="text-center"><b>N° TEL</b></th>
            <th class="  gauche" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let patient of patients |paginate: { itemsPerPage: 5, currentPage: p }" style="cursor: pointer;">
              <td> <img src="assets/images/med1.png" style="height: 35px"></td>
              <td class="text-center">{{patient.identifiantMedivisto}}</td>
              <td class="text-center">{{patient.nom}}</td>
              <td class="text-center">{{patient.prenom}}</td>
              <td class="text-center">{{patient.email}}</td>
              <td class="text-center">{{patient.tel}}</td>
              <td class="row gauche" style="border-bottom-style: none;position: relative;left: 40px;">
                <div class="col-1" style="margin-right: 5%" title="Détails" (click)="ajouterPatient(patient)">
                  <img src="assets/images/plus-5.png" class="iconParametres">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="pagination">
      <div class="row justify-content-center">
        <div class="col-12 " style="padding-top: 10px; padding-left: 35px">
          <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
        </div>
      </div>
    </div>
    <br>
  </section>
</section>