import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { API_CONFIG } from '../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../config/API_CONFIG';
import { Observable } from 'rxjs';
@Injectable()
export class PatientService {
  constructor(private http: HttpClient) {
  }
  //save patient
  registerPatient(newPatient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.register), (newPatient), { headers: headers })
      
  }

  getPatient() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients),{ headers: headers })
      
  }
  editProfil(patient) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.patients), (patient), { headers: headers })
      
  }
  editlistMedecinsPatient(patient) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.patients) + '/listMedecinsId', (patient), { headers: headers })
      
  }
  getPatientByID(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/' + id,{ headers: headers })
      
  }
  getPatientByIDNewFunction(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/new/function/' + id,{ headers: headers })
      
  }
  getAllPatientsByRecherche(params) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/' + params.nom  + '/' + params.email + '/' + params.tel,{ headers: headers })
      
  }
  getAllComptePatientsByRecherche(params) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/searched/comptes/' + params.identifiant  + '/' + params.dateAnniversaire,{ headers: headers })
      
  }
  verifyEmailPatient(patient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.patients) + '/mobile/verifiedByEmail', (patient), { headers: headers })
      
  }

  getPatientsByDoctor(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/withoutExamen/' + id,{ headers: headers })
      
  }
  // !----------fonctinalité pour Medecin : id est statique
  updatePhoto(id,data){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
    });
    return this.http.put(_getURL("") + 'api/users/addImagetoPatient/'+id, data, { headers: headers })
  }
  // get last twenty medecins by medecin
  getLastTwentyPatient(){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/get/last/twenty/patient',{ headers: headers })
  }
}
