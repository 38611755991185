import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cat-lispatient',
  templateUrl: './cat-lispatient.component.html',
  styleUrls: ['./cat-lispatient.component.css', '../list-patients.component.css']
})
export class CatLispatientComponent implements OnInit {
  @Input() myExamen: any
  constructor() { }

  ngOnInit(): void {
  }

}
