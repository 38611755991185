import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class ImageService {

  constructor(private http: HttpClient) {
  }
  //get image of a doctor
  getImageMedecin(medecinId) {
    return this.http.get(_getURL(API_CONFIG.image) + '/medecin/' + medecinId)
      
  }
  getImagePatient(patientId) {
    return this.http.get(_getURL(API_CONFIG.image) + '/patient/' + patientId)
      
  }
  //get image of a secretary
  getImageSecretaire(secretaireId) {
    return this.http.get(_getURL(API_CONFIG.image) + '/secretaire/' + secretaireId)
      
  }
}
