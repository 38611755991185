import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searchMedecins'
})
export class SearchMedecinsPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.identifiantMedivisto.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.identifiantMedivisto.toLowerCase().includes(args));}
     if (value.filter((item) => item.nom.toLowerCase().includes(args)) != '') {
       return value.filter((item) => item.nom.toLowerCase().includes(args));}
       if (value.filter((item) => item.prenom.toLowerCase().includes(args)) != '') {
        return value.filter((item) => item.prenom.toLowerCase().includes(args));}
        if (value.filter((item) => item.specialite.toLowerCase().includes(args)) != '') {
            return value.filter((item) => item.specialite.toLowerCase().includes(args));}
 }
}
