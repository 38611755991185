<h3>Angle irido cornéen</h3>
<div class="arrow arrow-up btnAngle mb-3" *ngIf="displayAngle" title="Cacher Angle irido cornéen" (click)="displayAndHideAngle()">▲</div>
<div class="arrow arrow-up btnAngle mb-3" *ngIf="!displayAngle" title="Afficher Angle irido cornéen" (click)="displayAndHideAngle()">▼</div>
<div *ngIf="displayAngle" @fade>
    <div class="card">
        <div class="card-body">
            <div>
                <div class="col-12 sansCyclo">
                    <div class="cadreOeilDroit2">
                        <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                            <h5 id="OeilTitle" style="cursor: pointer;">Oeil droit</h5>
                        </div>
                        <div class="row" style="margin-top: 8px">
                            <br />
                            <div class="col-md-6">
                                <ul>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielDroit.ouvertSur360.present==true }" (click)="addRemoveFromAngleIridoRight('Ouvert sur 360 degrés')">Ouvert sur 360 degrés</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielDroit.apposition.present==true}" (click)="addRemoveFromAngleIridoRight('Apposition')">Apposition</li>
                                    <div *ngIf="showMoreAngle">
                                        <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielDroit.corpsetranger.present==true}" (click)="addRemoveFromAngleIridoRight('corps étranger')">Corps étranger</li>
                                        <li [ngClass]="{selectedLi:examLaf?.laf.angleiridocornee.oielDroit.ferme.present==true }" (click)="addRemoveFromAngleIridoRight('Fermé')">Fermé</li>
                                        <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielDroit.hyphema.present==true}" (click)="addRemoveFromAngleIridoRight('Hyphema')">Hyphema</li>
                                        <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielDroit.hypopion.present==true}" (click)="addRemoveFromAngleIridoRight('Hypopion')">Hypopion</li>
                                        <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielDroit.occludable.present==true}" (click)="addRemoveFromAngleIridoRight('Occludable')">Occludable</li>
                                    </div>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <ul>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielDroit.pigmente.present==true}" (click)="addRemoveFromAngleIridoRight('Pigmenté')">Pigmenté</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielDroit.rubeose.present==true}" (click)="addRemoveFromAngleIridoRight('Rubéose')">Rubéose</li>
                                    <div *ngIf="showMoreAngle">
                                        <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielDroit.synechie.present==true}" (click)="addRemoveFromAngleIridoRight('Synéchie')">Synéchie</li>
                                        <!-- dynamic anamaly -->
                                        <li *ngFor="let anomaly of anomaliesByDoctorAngle" [ngClass]="{'selectedLi': checkExistAnomalieAngleRight(anomaly.id)}">
                                            <span (click)="addRemoveFromAngleRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                            <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieAngleRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-angleIridoOuvert')" title="Supprimer l'anomalie" class="del-anomalie">
                                        </li>
                                        <!-- dynamic anamaly -->
                                        <div>
                                            <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieAngle" (keyup.enter)="addNewAnomalyAngle($event)" [disabled]="isToastVisible">
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <br /><br />
                            <div class="showmore">
                                <span (click)=showMoreAngleSection() *ngIf="!showMoreAngle">Voir plus</span>
                                <span (click)=showMoreAngleSection() *ngIf="showMoreAngle">Voir moins</span>
                            </div>
                        </div>
                    </div>
                    <div class="cadreOeilGauche2">
                        <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                            <h5 id="OeilTitle" style="cursor: pointer;">Oeil gauche</h5>
                        </div>
                        <div class="row" style="margin-top: 8px">
                            <br />
                            <div class="col-md-6">
                                <ul>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielGauche.ouvertSur360.present==true }" (click)="addRemoveFromAngleIridoLeft('Ouvert sur 360 degrés')">Ouvert sur 360 degrés</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielGauche.apposition.present==true}" (click)="addRemoveFromAngleIridoLeft('Apposition')">Apposition</li>
                                    <div *ngIf="showMoreAngle">
                                        <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielGauche.corpsetranger.present==true}" (click)="addRemoveFromAngleIridoLeft('corps étranger')">Corps étranger</li>
                                        <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielGauche.ferme.present==true }" (click)="addRemoveFromAngleIridoLeft('Fermé')">Fermé</li>
                                        <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielGauche.hyphema.present==true}" (click)="addRemoveFromAngleIridoLeft('Hyphema')">Hyphema</li>
                                        <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielGauche.hypopion.present==true}" (click)="addRemoveFromAngleIridoLeft('Hypopion')">Hypopion</li>
                                        <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielGauche.occludable.present==true}" (click)="addRemoveFromAngleIridoLeft('Occludable')">Occludable</li>
                                    </div>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <ul>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.angleiridocornee.oielGauche.pigmente.present==true}" (click)="addRemoveFromAngleIridoLeft('Pigmenté')">Pigmenté</li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielGauche.rubeose.present==true}" (click)="addRemoveFromAngleIridoLeft('Rubéose')">Rubéose</li>
                                    <div *ngIf="showMoreAngle">
                                        <li [ngClass]="{selectedLi:  examLaf?.laf.angleiridocornee.oielGauche.synechie.present==true}" (click)="addRemoveFromAngleIridoLeft('Synéchie')">Synéchie</li>
                                        <!-- dynamic anamaly -->
                                        <li *ngFor="let anomaly of anomaliesByDoctorAngle" [ngClass]="{'selectedLi': checkExistAnomalieAngleLeft(anomaly.id)}">
                                            <span (click)="addRemoveFromAngleLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                            <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieAngleLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-angleIridoOuvert')" title="Supprimer l'anomalie" class="del-anomalie">
                                        </li>
                                        <!-- dynamic anamaly -->
                                        <div>
                                            <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieAngle" (keyup.enter)="addNewAnomalyAngle($event)" [disabled]="isToastVisible">
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <br /><br />
                            <div class="showmore">
                                <span (click)=showMoreAngleSection() *ngIf="!showMoreAngle">Voir plus</span>
                                <span (click)=showMoreAngleSection() *ngIf="showMoreAngle">Voir moins</span>
                            </div>
                        </div>
                    </div>

                </div>
                <br />
                <div class="row blocinputs">
                    <div class="col-md-6">
                        <div *ngIf=" examLaf?.laf.angleiridocornee.oielDroit.ouvertSur360.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Ouvert sur 360 degrés </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.ouvertSur360.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.occludable.present==true">
                            <h5>Occludable</h5>
                            <div class="row">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.angleiridocornee.oielDroit.occludable.valeur=='Grade 1: (10 dégré) seul l anneau de Schwalbe estvisible'" (change)="selectangleiridocorneeRight('Grade 1: (10 dégré) seul l anneau de Schwalbe estvisible')">Grade 1: (10 dégré) seul l'anneau de Schwalbe estvisible
                                </div>
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.angleiridocornee.oielDroit.occludable.valeur=='Grade 2: (20 dégré) le trabéculume est visible'" (change)="selectangleiridocorneeRight('Grade 2: (20 dégré) le trabéculume est visible')">Grade 2: (20 dégré) le trabéculume est visible
                                </div>
                                <!-- description -->
                                <div class="col-12">
                                    <label>Description</label>
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.occludable.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.ferme.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Fermé </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.ferme.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.pigmente.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Pigmenté</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.pigmente.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.synechie.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Synéchie</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.synechie.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.hyphema.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Hyphema</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.hyphema.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.rubeose.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Rubéose </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.rubeose.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf=" examLaf?.laf.angleiridocornee.oielDroit.corpsetranger.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Corps étranger</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.corpsetranger.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.hypopion.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Hypopion </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.hypopion.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielDroit.apposition.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Apposition</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielDroit.apposition.description">
                                </div>
                            </div>
                        </div>
                        <!-- dynamique anomalies-->
                        <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.angleiridocornee.oielDroit.allAnomalies">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">{{anomaly.titre}} </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                            </div>
                        </div>
                        <!-- dynamique anomalies-->

                        <div class="row emptyBloc"></div>
                    </div>
                    <div class="col-md-6">
                        <div *ngIf=" examLaf?.laf.angleiridocornee.oielGauche.ouvertSur360.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Ouvert sur 360 degrés </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.ouvertSur360.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.occludable.present==true">
                            <h5>Occludable</h5>
                            <div class="row">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.angleiridocornee.oielGauche.occludable.valeur=='Grade 1: (10 dégré) seul l anneau de Schwalbe estvisible'" (change)="selectangleiridocorneeLeft('Grade 1: (10 dégré) seul l anneau de Schwalbe estvisible')">Grade 1: (10 dégré) seul l'anneau de Schwalbe estvisible
                                </div>
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.angleiridocornee.oielGauche.occludable.valeur=='Grade 2: (20 dégré) le trabéculume est visible'" (change)="selectangleiridocorneeLeft('Grade 2: (20 dégré) le trabéculume est visible')">Grade 2: (20 dégré) le trabéculume est visible
                                </div>
                                <!-- description -->
                                <div class="col-12">
                                    <label>Description</label>
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.occludable.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.ferme.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Fermé </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.ferme.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.pigmente.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Pigmenté</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.pigmente.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.synechie.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Synéchie</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.synechie.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.hyphema.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Hyphema</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.hyphema.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.rubeose.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails">Rubéose </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.rubeose.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf=" examLaf?.laf.angleiridocornee.oielGauche.corpsetranger.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Corps étranger</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.corpsetranger.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.hypopion.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Hypopion </h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.hypopion.description">
                                </div>
                            </div>
                        </div>
                        <div class="margin-t-10" *ngIf="examLaf?.laf.angleiridocornee.oielGauche.apposition.present==true">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <h5 class="titleDetails"> Apposition</h5>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.angleiridocornee.oielGauche.apposition.description">
                                </div>
                            </div>
                        </div>
                        <!-- dynamique anomalies-->
                        <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.angleiridocornee.oielGauche.allAnomalies">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">{{anomaly.titre}} </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                            </div>
                        </div>
                        <!-- dynamique anomalies-->

                        <div class="row emptyBloc"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="text-center mt-2" *ngIf="displayAngle">
        <h6 class="showmore" title="Cacher Angle irido cornéen" (click)="displayAndHideAngle()">Cacher Angle irido cornéen ▲</h6>
    </div>
</div>