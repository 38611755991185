import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginMedecinComponent } from './login-medecin/login-medecin.component';
import { LogoutComponent } from './logout/logout.component';
import { FlashMessagesModule } from '../../../module/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { FooterModule } from '../shared/footer/footer.module';


@NgModule({
  declarations: [LoginMedecinComponent, LogoutComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,FlashMessagesModule,FormsModule, ReactiveFormsModule ,RecaptchaV3Module,FooterModule
  ],
  providers: [{
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
}, ],
})
export class LoginModule { }
