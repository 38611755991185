<div id="navbarSupportedContent">
</div>
<div class="row m-t-40  m-r-40 m-b-40">
  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" *ngIf="myExamen">
    <!-- <p id="p-cat" [ngClass]="{'p-cat': isActiveTraitement}" (click)="showTraitement()">Traitement en cours</p> -->
    <p id="p-cat" [ngClass]="{'p-cat': isActiveConclusion}" (click)="showConclusion()">Conclusion</p>
    <p id="p-cat" [ngClass]="{'p-cat': isActiveCatetProchainConsul}" (click)="showCatetProchainConsul()">Conduite à tenir et prochaine consultation</p>
  </div>
  <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 questions-zone">
    <!-- ------------------------------------------------start-- conclusion -->
    <div class="row" id="divToExport" *ngIf="isActiveConclusion && myExamen" @fade>
      <div class="header">
        <h4 class="title-section m-l-10 m-t-10">Conclusion</h4>
        <!-- <div id="buttons">
          <button class="btn button-save">Enregister</button>
          <button class="btn  button-print">Imprimer</button>
        </div> -->
      </div>
      <div class="row">
        <div class="generalInformation" *ngIf="myExamen && myExamen.patient">
          <div class="">
            <label><b>Nom:</b> </label>
            <p class="m-l-10">{{myExamen.patient.nom}}</p>
            <label><b>Prénom:</b> </label>
            <p class="m-l-10">{{myExamen.patient.prenom}}</p>
          </div>
          <div class="">
            <label><b> Age:</b></label>
            <p class="m-l-10"> {{myExamen.patient.age}}</p>
            <label><b>Profession:</b> </label>
            <p class="m-l-10">{{myExamen.patient.profession}}</p>
          </div>
          <div class="d-flex">
            <label><b>Date:</b> </label>
            <p class="m-l-10">{{myExamen.start | date :"dd/MM/yyyy"}}</p>
          </div>
          <div class="d-flex">
            <label><b>Motif:</b> </label>
            <ul>
              <li *ngFor="let mot of motif">{{mot}}</li>
            </ul>
          </div>
        </div>
      </div>
      <!--! Anomalie à l'interrogatoire  -->
      <div class="row">
        <div class="m-t-20">
          <div *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux?.length>0 
            || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux?.length>0 
          ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours?.length>0 
            || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours?.length>0 
              ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.nom
              || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.nom
              ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux?.length>0 
              ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux?.length>0 
              ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux?.length>0
              || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux?.length>0
              || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique?.length>0 
              || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique?.length>0 
              ||myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present==true 
              || myExamen.interrogation.antecedentsGeneraux.terrainatopique.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present==true
              || myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present==true
              ||myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.traitementencours.present ==true
              ||myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0
              ||myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0 ">
            <h6 class="subTitle">Anomalie à l'interrogatoire</h6>
            <div class="intero">
              <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux?.length>0 || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux?.length>0">
                <ul class="row">
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux?.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux?.length>0">
                    <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}} </li>
                  </div>
                  <h6 class="col-md-4 titleConclusionCenter">Médicaux</h6>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux?.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux?.length>0">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}} </li>
                  </div>
                </ul>
              </ul>
              <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours?.length>0 || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours?.length>0">
                <ul class="row">
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours?.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours?.length>0">
                    <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}} </li>
                  </div>
                  <h6 class="col-md-4 titleConclusionCenter">Traitement en cours</h6>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours?.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours?.length>0">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}} </li>
                  </div>
                </ul>
              </ul>
              <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name">
                <ul class="row">
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name ">
                    <li [ngClass]="{'second-color': myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name != 'None'}">{{myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name}} </li>
                  </div>
                  <h6 class=" col-md-4 titleConclusionCenter">Antécédents de traitement par laser</h6>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name ">
                    <li [ngClass]="{'second-color': myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name != 'None'}">{{myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name}} </li>
                  </div>
                </ul>
              </ul>
              <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.length>0 || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.length>0">
                <ul class="row">
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.length>0">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}}</li>
                  </div>
                  <h6 class="col-md-4 titleConclusionCenter">Chirurgicaux</h6>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.length>0">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}}</li>
                  </div>
                </ul>
              </ul>
              <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0 || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
                <ul class="row">
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}}</li>
                  </div>
                  <h6 class="col-md-4 titleConclusionCenter">Correction optique</h6>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length==0">
                    <li>None</li>
                  </div>
                  <div class="col-md-4 text-center" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}}</li>
                  </div>
                </ul>
              </ul>
              <!--Antécédents généraux-->
              <div class="row mt-3" *ngIf=" myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present==true 
              || myExamen.interrogation.antecedentsGeneraux.terrainatopique.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present==true
              || myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present==true
              ||myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present ==true
              ||myExamen.interrogation.antecedentsGeneraux.traitementencours.present ==true">
                <div class="col-md-4"></div>
                <h6 class=" col-md-4 titleConclusionCenter">Antécédents généraux</h6>
                <div class="col-md-4"></div>
                <div class="col-md-6">
                  <!--! Grossesse en cours-->
                  <ul class="text-center second-color" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux  && myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present">
                    <li>Grossesse en cours </li>
                  </ul>
                  <!--! end Grossesse en cours-->

                  <!--! Terrain vasculaire particulier  -->

                  <ul class="text-center second-color" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present">
                    <li *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.present">Terrain vasculaire particulier : Diabète</li>
                    <li *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.present">Terrain vasculaire particulier : HTA</li>
                    <li *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present">Terrain vasculaire particulier : Autre</li>
                  </ul>
                  <!--! end of Terrain vasculaire particulier  -->

                  <!--! Terrain atopique-->
                  <ul class="text-center second-color" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux  && myExamen.interrogation.antecedentsGeneraux.terrainatopique.present">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs">Terrain atopique : {{value.name}}</li>
                  </ul>
                  <!--! end of Terrain atopique-->

                  <!-- ! Terrain d'immunodépression-->
                  <ul class="text-center second-color" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs">Terrain d'immunodépression : {{value.name}}</li>
                  </ul>
                  <!-- ! end of Terrain d'immunodépression-->

                  <!-- ! Maladie dysimmunitaire-->
                  <ul class="text-center second-color" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present">
                    <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs">Maladie dysimmunitaire : {{value.name}}</li>
                  </ul>
                  <!-- ! end of Maladie dysimmunitaire-->

                  <!-- ! Maladie neurologique-->
                  <ul class="text-center second-color" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladieneurologique && myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present">
                    <li class="text-center second-color" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs">Maladie neurologique : {{value.name}}</li>
                  </ul>
                  <!-- ! end of Maladie neurologique-->
                </div>
                <div class="col-md-6">
                  <!-- ! Notion de vaccination récente-->
                  <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present">
                    <li class="text-center second-color">Notion de vaccination récente</li>
                    <ul class="text-center second-color">
                      <li>Type : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.type}}</li>
                      <li>Date : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.date}}</li>
                    </ul>
                  </ul>
                  <!-- ! end of Notion de vaccination récente-->

                  <!-- ! Notion d'anesthésie récente-->
                  <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present">
                    <li class="text-center second-color">Notion d'anesthésie récente</li>
                    <ul class="text-center second-color">
                      <li>Type : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.type}}</li>
                      <li>Date : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.date}}</li>
                    </ul>
                  </ul>
                  <!-- ! end of Notion d'anesthésie récente-->

                  <!-- ! Traitement général en cours-->
                  <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.traitementencours && myExamen.interrogation.antecedentsGeneraux.traitementencours.present">
                    <li class="text-center second-color">Traitement général en cours</li>
                    <ul class="text-center second-color">
                      <li>Type : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.type}}</li>
                      <li>Dose : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.dose}}</li>
                    </ul>
                  </ul>
                  <!-- ! end of Traitement général en cours-->

                  <!--!Antécédents familiaux ophtalmologiqu-->
                  <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 ">
                    <li class="text-center second-color" *ngFor="let antecedent of myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">Antécédents familiaux ophtalmologique : {{antecedent.name}}</li>
                  </ul>
                  <!--!end of Antécédents familiaux ophtalmologiqu-->
                </div>
              </div>
              <!--Antécédents familiaux ophtalmologique-->
              <div class="row mt-3" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 ||myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0 ">
                <div class="col-md-4"></div>
                <h6 class=" col-md-4 titleConclusionCenter">Antécédents familiaux ophtalmologiques</h6>
                <div class="col-md-4"></div>
                <div class="row">
                  <div class="col-md-6">
                    <!--!Antécédents familiaux ophtalmologiqu-->
                    <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 ">
                      <li class="text-center second-color" *ngFor="let antecedent of myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">Type : {{antecedent.name}}</li>
                    </ul>
                    <!--!end of Antécédents familiaux ophtalmologiqu-->
                  </div>
                  <div class="col-md-6">

                    <!--!Antécédents familiaux ophtalmologiqu-->
                    <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0 ">
                      <li class="text-center second-color" *ngFor="let antecedent of myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie">Habitudes de vie : {{antecedent.name}}</li>
                    </ul>
                    <!--!end of Antécédents familiaux ophtalmologiqu-->
                  </div>
                </div>

              </div>
            </div>
            <h6 class="subTitle">Anomalie à l'examen</h6>
          </div>
        </div>
      </div>
      <!--! end of Anomalie à l'interrogatoire  -->
      <!-- !examen conclusion -->
      <div>
        <!-- Inspection -->
        <div class="row">
          <!-- Oeil Droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.inspection && myExamen.inspection.anomaly && myExamen.inspection.anomaly.present">
            <p *ngIf="myExamen.inspection.anomaly.oielDroit.normale.present">Normale</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.attitudedemalvoyance.present">Attitude de malvoyance</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.buphtalmie.present">Buphtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.exphtalmie.present">Exphtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.enophtalmie.present">Énophtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.microphtalmie.present">Microphtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.leucocorie.present">Leucocorie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.protheseoculaire.present">Prothèse oculaire</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.plaies.present">Plaies</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.paralysiefaciale.present">Paralysie faciale</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.ptosis.present">Ptosis</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.strabisme.present">Strabisme</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.inspection.anomaly.oielDroit.allAnomalies">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Inspection</h6>
          </div>
          <!-- Oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.inspection && myExamen.inspection.anomaly && myExamen.inspection.anomaly.present">
            <p *ngIf="myExamen.inspection.anomaly.oielGauche.normale.present">Normale</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.attitudedemalvoyance.present">Attitude de malvoyance</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.buphtalmie.present">Buphtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.exphtalmie.present">Exphtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.enophtalmie.present">Énophtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.microphtalmie.present">Microphtalmie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.leucocorie.present">Leucocorie</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.protheseoculaire.present">Prothèse oculaire</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.plaies.present">Plaies</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.paralysiefaciale.present">Paralysie faciale</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.ptosis.present">Ptosis</p>
            <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.strabisme.present">Strabisme</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.inspection.anomaly.oielGauche.allAnomalies">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end Inspection -->
        <!-- Réfraction sans cycloplégie -->
        <div class="row">
          <div class="col-md-4">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph: {{ myExamen.refraction.sansCycloplegie.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl: {{myExamen.refraction.sansCycloplegie.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe: {{myExamen.refraction.sansCycloplegie.oielDroit.axe}}" class=" form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
          <div class="col titleConclusionCenter">
            <h6>Réfraction sans cycloplégie</h6>
          </div>
          <div class="col-md-4">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph: {{ myExamen.refraction.sansCycloplegie.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl: {{myExamen.refraction.sansCycloplegie.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe: {{myExamen.refraction.sansCycloplegie.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
        </div>
        <!-- end Réfraction sans cycloplégie -->
        <!-- Réfraction avec cycloplégie -->
        <div class="row">
          <div class="col-md-4">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph: {{ myExamen.refraction.avecCycloplegie.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl: {{myExamen.refraction.avecCycloplegie.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe: {{myExamen.refraction.avecCycloplegie.oielDroit.axe}}" class="form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Réfraction avec cycloplégie</h6>
          </div>
          <div class="col-md-4">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph: {{ myExamen.refraction.avecCycloplegie.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl: {{myExamen.refraction.avecCycloplegie.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe: {{myExamen.refraction.avecCycloplegie.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
        </div>
        <!-- end Réfraction avec cycloplégie -->
        <!-- Annexes -->
        <div class="row">
          <!-- Oeil Droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.annexe && myExamen.annexe.anomaly && myExamen.annexe.anomaly.present">
            <p *ngIf="myExamen.annexe.anomaly.oielDroit.normale.present">Normale</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.chalazion.present">Chalazion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dca.present">DCA</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dcc.present">DCC</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dermabrasion.present">Dermabrasion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.diplopie.present">Diplopie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.distichoasis.present">Distichoasis</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dgm.present">DGM</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ecoulementlacrymal.present">Écoulement lacrymal</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.entropion.present">Entropion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ectropion.present">Ectropion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.fistule.present">Fistule</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.insuffisancedeconvergence.present">Insuffisance de convergence</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.massedelalogelacrymal.present">Masse de la loge lacrymal</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.meeibomite.present">Meeibomite</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ophtalmologie.present">Ophtalmologie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.orgelet.present">Orgelet</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.paralysieocculomotrice.present">Paralysie occulomotrice</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.plaie.present">Plaie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.presencedestrabisme.present">Présence de strabisme</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ptosis.present">Ptosis</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.refletcorneendecentre.present">Réflet cornéen décentré</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.stenoselacrymal.present">Sténose lacrymal</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.sondedeDCRenplace.present">Sonde de DCR en place</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.telangiectasie.present">Telangiectasie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.tumeurpaplepbrale.present">Tumeur paplepbrale</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.annexe.anomaly.oielDroit.allAnomapes">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Annexes</h6>
          </div>
          <!-- Oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.annexe && myExamen.annexe.anomaly && myExamen.annexe.anomaly.present">
            <p *ngIf="myExamen.annexe.anomaly.oielGauche.normale.present">Normale</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.chalazion.present">Chalazion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dca.present">DCA</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dcc.present">DCC</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dermabrasion.present">Dermabrasion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.diplopie.present">Diplopie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.distichoasis.present">Distichoasis</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dgm.present">DGM</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ecoulementlacrymal.present">Écoulement lacrymal</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.entropion.present">Entropion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ectropion.present">Ectropion</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.fistule.present">Fistule</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.insuffisancedeconvergence.present">Insuffisance de convergence</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.massedelalogelacrymal.present">Masse de la loge lacrymal</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.meeibomite.present">Meeibomite</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ophtalmologie.present">Ophtalmologie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.orgelet.present">Orgelet</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.paralysieocculomotrice.present">Paralysie occulomotrice</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.plaie.present">Plaie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.presencedestrabisme.present">Présence de strabisme</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ptosis.present">Ptosis</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.refletcorneendecentre.present">Réflet cornéen décentré</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.stenoselacrymal.present">Sténose lacrymal</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.sondedeDCRenplace.present">Sonde de DCR en place</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.telangiectasie.present">Telangiectasie</p>
            <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.tumeurpaplepbrale.present">Tumeur paplepbrale</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.annexe.anomaly.oielGauche.allAnomapes">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end Annexes -->
        <!-- AVSC de loin -->
        <div class="row">
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldeloin.oielDroit.avsc}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>AVSC de loin</h6>
          </div>
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldeloin.oielGauche.avsc}}</p>
          </div>
        </div>
        <!-- end of AVSC de loin -->
        <!-- AVAC de loin -->
        <div class="row">
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldeloin.oielDroit.avac}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>AVAC de loin</h6>
          </div>
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldeloin.oielGauche.avac}}</p>
          </div>
        </div>
        <!-- end of AVAC de loin -->
        <!-- Réfraction finale de loin -->
        <div class="row">
          <div class="col-md-4 text-center">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph :{{ myExamen.refraction.acuitevisueldeloin.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl :{{myExamen.refraction.acuitevisueldeloin.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe :{{myExamen.refraction.acuitevisueldeloin.oielDroit.axe}}" class="form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Réfraction finale de loin</h6>
          </div>
          <div class="col-md-4 text-center">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph :{{ myExamen.refraction.acuitevisueldeloin.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl :{{myExamen.refraction.acuitevisueldeloin.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe :{{myExamen.refraction.acuitevisueldeloin.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
        </div>
        <!-- end of Réfraction finale de loin -->
        <!-- AVSC de près -->
        <div class="row">
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldepres.oielDroit.avsc}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>AVSC de près</h6>
          </div>
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldepres.oielGauche.avsc}}</p>
          </div>
        </div>
        <!-- end of AVSC de près -->
        <!-- AVAC de près -->
        <div class="row">
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldepres.oielDroit.avac}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>AVAC de près</h6>
          </div>
          <div class="col-md-4 text-center">
            <p>{{myExamen.refraction.acuitevisueldepres.oielGauche.avac}}</p>
          </div>
        </div>
        <!-- end of AVAC de près -->
        <!-- Réfraction finale de près -->
        <div class="row">
          <div class="col-md-4 text-center">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph :{{ myExamen.refraction.acuitevisueldepres.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl :{{myExamen.refraction.acuitevisueldepres.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe :{{myExamen.refraction.acuitevisueldepres.oielDroit.axe}}" class="form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Réfraction finale de près</h6>
          </div>
          <div class="col-md-4 text-center">
            <div id="suboeilTitreSection">
              <div class="Sphère blocCyclop">
                <input type="text" value="Sph :{{ myExamen.refraction.acuitevisueldepres.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="cylindre blocCyclop">
                <input type="text" value="Cyl :{{myExamen.refraction.acuitevisueldepres.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
              </div>
              <div class="axe blocCyclop">
                <input type="text" value="Axe :{{myExamen.refraction.acuitevisueldepres.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
              </div>
            </div>
          </div>
        </div>
        <!-- end of Réfraction finale de près -->
        <!-- Sclére -->
        <div class="row">
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.sclere">
            <p *ngIf="myExamen.laf.sclere.oielDroit.blancheEtIntacte.present"> Blanche et intacte </p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.episclerite.present"> Episclérite</p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.plaie.present"> Plaie</p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.sclerite.present"> Sclérite</p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.scleromalacie.present"> Scléromalacie</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.sclere.oielDroit.allAnomapes"> {{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Sclére</h6>
          </div>
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.sclere">
            <p *ngIf="myExamen.laf.sclere.oielGauche.blancheEtIntacte.present"> Blanche et intacte </p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.episclerite.present"> Episclérite</p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.plaie.present"> Plaie</p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.sclerite.present"> Sclérite</p>
            <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.scleromalacie.present"> Scléromalacie</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.sclere.oielGauche.allAnomapes"> {{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Sclére -->
        <!-- Conjonctive -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.conj">
            <p *ngIf="myExamen.laf.conj.oielDroit.normocoloreEtIntacte.present">Normocolore et intacte </p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.ankyloblepharon.present"> Ankyloblepharon</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.cercleperikeratique.present"> Cercle périkératique</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.Chemosis.present">Chémosis</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.faussemembrane.present"> Fausse membrane</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.fibroseconjonctival.present">Fibrose conjonctival</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.follicule.present">Follicule</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.granulomeconjonctivale.present">Granulome conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.hemorragiesousconjonctival.present">Hémorragie sous conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.hyperhemieconj.present">Hyperhémie conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.icterique.present">Ictérique</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.micropapille.present ">Micro-papille</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.macropapille.present "> Macro-papille</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.papillegeante.present "> Papille géante</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.Pemphigoide.present">( Conj) Pemphigoïde</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.plaie.present ">Plaie</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.ptgStade.present ">PTG(stade)</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.secretion.present ">Sécrétion</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.symblepharon.present "> Symblépharon</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.tumeurconjonctivale.present"> Tumeur conjonctivale</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.conj.oielDroit.allAnomalies">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Conjonctive</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.conj">
            <p *ngIf="myExamen.laf.conj.oielGauche.normocoloreEtIntacte.present">Normocolore et intacte </p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.ankyloblepharon.present"> Ankyloblepharon</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.cercleperikeratique.present"> Cercle périkératique</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.Chemosis.present">Chémosis</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.faussemembrane.present"> Fausse membrane</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.fibroseconjonctival.present">Fibrose conjonctival</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.follicule.present">Follicule</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.granulomeconjonctivale.present">Granulome conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.hemorragiesousconjonctival.present">Hémorragie sous conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.hyperhemieconj.present">Hyperhémie conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.icterique.present">Ictérique</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.micropapille.present ">Micro-papille</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.macropapille.present "> Macro-papille</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.papillegeante.present "> Papille géante</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.Pemphigoide.present">( Conj) Pemphigoïde</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.plaie.present ">Plaie</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.ptgStade.present ">PTG(stade)</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.secretion.present ">Sécrétion</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.symblepharon.present "> Symblépharon</p>
            <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.tumeurconjonctivale.present"> Tumeur conjonctivale</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.conj.oielGauche.allAnomalies">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Conjonctive -->
        <!-- Cornée -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.corneetransparente">
            <p *ngIf="myExamen.laf.corneetransparente.oielDroit.transparente.present==true"> Transparente</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.abces.present==true"> Abcès</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.anneuximmunitaire.present==true"> Anneux immunitaire</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.bueeEpitheliale.present==true"> Buée épithéliale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.bullesousepitheliale.present==true"> Bulle sous épithéliale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.buphtalmie.present==true"> Buphtalmie</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.corneaguttata.present==true"> Cornea guttata </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.endothelite.present==true"> Endothélite</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.present==true"> Épaississement épithéliale/stromale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.gerontoxon.present==true"> Gérontoxon</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hematocornee.present==true"> Hématocornée</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hydrops.present==true"> Hydrops</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hypoesthesie.present==true"> Hypoesthésie</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.present==true"> Hypoesthésie cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.present==true"> Irrégularité/plis de la descemet </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.KB.present==true">KB</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.Keratopathieenbandelette.present==true"> Kératopathie en bandelette </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.KPS.present==true"> KPS</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.leucome.present==true"> Leucome</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.microkysteEpitheliaux.present==true"> Microkyste épithéliaux</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.microkystique.present==true"> Microkystique</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.neovascularisationcorneenne.present==true"> Néovascularisation cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.NSE.present==true"> NSE</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.oedemecorneen.present==true"> Oedème cornéen</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.opaque.present==true"> Opaque</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.opacite.present==true"> Opacité</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.perforationcorneenne.present==true"> Perforation cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.plaiescorneenne.present==true"> Plaie cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.plaquevernale.present==true"> Plaque vernale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.pointcorneen.present==true"> Point cornéen</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.PRD.present==true"> PRD</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.striesdevogt.present==true"> Stries de vogt</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.ulcerationcorneenne.present==true"> Ulcération cornéenne </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.present==true"> Visibilitéanormale des nerfs cornéen</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.ulcerevernal.present==true"> Ulcère vernal</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.noduledetrantas.present==true"> Nodule de trantas</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.corneetransparente.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Cornée</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.corneetransparente">
            <p *ngIf="myExamen.laf.corneetransparente.oielGauche.transparente.present==true"> Transparente</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.abces.present==true"> Abcès</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.anneuximmunitaire.present==true"> Anneux immunitaire</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.bueeEpitheliale.present==true"> Buée épithéliale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.bullesousepitheliale.present==true"> Bulle sous épithéliale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.buphtalmie.present==true"> Buphtalmie</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.corneaguttata.present==true"> Cornea guttata </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.endothelite.present==true"> Endothélite</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.present==true"> Épaississement épithéliale/stromale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.gerontoxon.present==true"> Gérontoxon</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hematocornee.present==true"> Hématocornée</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hydrops.present==true"> Hydrops</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hypoesthesie.present==true"> Hypoesthésie</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.present==true"> Hypoesthésie cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.present==true"> Irrégularité/plis de la descemet </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.KB.present==true">KB</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.Keratopathieenbandelette.present==true"> Kératopathie en bandelette </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.KPS.present==true"> KPS</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.leucome.present==true"> Leucome</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.microkysteEpitheliaux.present==true"> Microkyste épithéliaux</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.microkystique.present==true"> Microkystique</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.neovascularisationcorneenne.present==true"> Néovascularisation cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.NSE.present==true"> NSE</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.oedemecorneen.present==true"> Oedème cornéen</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.opaque.present==true"> Opaque</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.opacite.present==true"> Opacité</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.perforationcorneenne.present==true"> Perforation cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.plaiescorneenne.present==true"> Plaie cornéenne</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.plaquevernale.present==true"> Plaque vernale</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.pointcorneen.present==true"> Point cornéen</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.PRD.present==true"> PRD</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.striesdevogt.present==true"> Stries de vogt</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.ulcerationcorneenne.present==true"> Ulcération cornéenne </p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.present==true"> Visibilitéanormale des nerfs cornéen</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.ulcerevernal.present==true"> Ulcère vernal</p>
            <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.noduledetrantas.present==true"> Nodule de trantas</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.corneetransparente.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Cornée -->
        <!-- Pachymétrie -->
        <div class="row mt-3">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen?.laf && myExamen?.laf.pachymtrie">
            <p *ngIf="myExamen?.laf.pachymtrie.oielDroit.value">{{myExamen?.laf.pachymtrie.oielDroit.value}} μm </p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Pachymétrie</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen?.laf && myExamen?.laf.pachymtrie">
            <p *ngIf="myExamen?.laf.pachymtrie.oielGauche.value">{{myExamen?.laf.pachymtrie.oielGauche.value}} μm </p>
          </div>
        </div>
        <!-- end of Pachymétrie -->
        <!-- BUT -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.butShirmer">
            <p *ngIf="myExamen.laf.butShirmer.oielDroit.secondes">{{myExamen.laf.butShirmer.oielDroit.secondes}} s </p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>BUT</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.conj">
            <p *ngIf="myExamen.laf.butShirmer.oielGauche.secondes">{{myExamen.laf.butShirmer.oielGauche.secondes}} s </p>
          </div>
        </div>
        <!-- end of BUT -->
        <!-- Schirmer -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.butShirmer">
            <p *ngIf="myExamen.laf.butShirmer.oielDroit.secondes">{{myExamen.laf.butShirmer.oielDroit.mm}} mm </p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Schirmer</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.butShirmer">
            <p *ngIf="myExamen.laf.butShirmer.oielDroit.secondes">{{myExamen.laf.butShirmer.oielGauche.mm}} mm </p>
          </div>
        </div>
        <!-- end of Schirmer -->
        <!-- Chambre antérieure -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.chambreanterieurealmeetprofonde">
            <p *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present==true">Calme et profonde</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.present==true">CA étroite</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.present==true">Corps étranger</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.present==true">Cyclodialyse</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.present==true">Hémorragie sous conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.present==true">Hypopion</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.present==true">Hyphema</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.present==true">ICA</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.IP.present==true">IP</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.present==true">Masse cristallinienne</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.present==true ">Mèche de vitre</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.present==true ">Pigments</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.recession.present==true">Récession</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.present==true">Signe du limbe +</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.present==true">Test de van herick</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.present==true">Tyndall cellulaire </p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.present==true">Tyndall proteique</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Chambre antérieure</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.chambreanterieurealmeetprofonde">
            <p *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present==true">Calme et profonde</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.present==true">CA étroite</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.present==true">Corps étranger</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.present==true">Cyclodialyse</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.present==true">Hémorragie sous conjonctivale</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.present==true">Hypopion</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.present==true">Hyphema</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.present==true">ICA</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.IP.present==true">IP</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.present==true">Masse cristallinienne</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.present==true ">Mèche de vitre</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.present==true ">Pigments</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.recession.present==true">Récession</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.present==true">Signe du limbe +</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.present==true">Test de van herick</p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.present==true">Tyndall cellulaire </p>
            <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.present==true">Tyndall proteique</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Chambre antérieure -->
        <!-- Iris -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.irisNormale ">
            <p *ngIf="myExamen.laf.irisNormale.oielDroit.normale.present==true">Normale</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.atrophieirienne.present==true">Atrophie irienne</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.convexe.present==true">Convexe</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.cristauxirien.present==true">Cristaux irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.granulomeirien.present==true">Granulome irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.heterochromie.present==true">Hétérochromie</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.irisconcave.present==true">Iris concave</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.melanomeMassesuspecte.present==true">Mélanome/masse suspecte</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.naevusirien.present==true">Naevus irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.noduleirien.present==true">Nodule irien </p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.plaieirienne.present==true">Plaie irienne</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.rubeose.present==true">Rubéose </p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.SPEirien.present==true">SPE irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.synechieiridocristalienne.present==true">Synéchie irido-cristallienne</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.irisNormale.oielDroit.allAnomalies">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Iris</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.irisNormale ">
            <p *ngIf="myExamen.laf.irisNormale.oielGauche.normale.present==true">Normale</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.atrophieirienne.present==true">Atrophie irienne</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.convexe.present==true">Convexe</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.cristauxirien.present==true">Cristaux irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.granulomeirien.present==true">Granulome irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.heterochromie.present==true">Hétérochromie</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.irisconcave.present==true">Iris concave</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.melanomeMassesuspecte.present==true">Mélanome/masse suspecte</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.naevusirien.present==true">Naevus irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.noduleirien.present==true">Nodule irien </p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.plaieirienne.present==true">Plaie irienne</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.rubeose.present==true">Rubéose </p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.SPEirien.present==true">SPE irien</p>
            <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.synechieiridocristalienne.present==true">Synéchie irido-cristallienne</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.irisNormale.oielGauche.allAnomalies">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Iris -->
        <!-- Tonus -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.toAic ">
            <p *ngIf="myExamen.laf.toAic.oielDroit.mm">{{myExamen.laf.toAic.oielDroit.mm}} mmHg</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Tonus</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.toAic ">
            <p *ngIf="myExamen.laf.toAic.oielGauche.mm">{{myExamen.laf.toAic.oielGauche.mm}} mmHg</p>
          </div>
        </div>
        <!-- end of Tonus -->
        <!-- Angle irido cornéen -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.angleiridocornee">
            <p *ngIf="myExamen.laf.angleiridocornee.oielDroit.ouvertSur360.present==true"> Ouvert sur 360 degrés</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.apposition.present==true"> Apposition</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.corpsetranger.present==true"> Corps étranger</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.ferme.present==true "> Fermé</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.hyphema.present==true"> Hyphema</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.hypopion.present==true">Hypopion</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.occludable.present==true"> Occludable</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.pigmente.present==true"> Pigmenté</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.rubeose.present==true"> Rubéose</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.synechie.present==true"> Synéchie</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.angleiridocornee.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Angle irido cornéen</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.angleiridocornee">
            <p *ngIf="myExamen.laf.angleiridocornee.oielGauche.ouvertSur360.present==true"> Ouvert sur 360 degrés</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.apposition.present==true"> Apposition</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.corpsetranger.present==true"> Corps étranger</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.ferme.present==true "> Fermé</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.hyphema.present==true"> Hyphema</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.hypopion.present==true">Hypopion</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.occludable.present==true"> Occludable</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.pigmente.present==true"> Pigmenté</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.rubeose.present==true"> Rubéose</p>
            <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.synechie.present==true"> Synéchie</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.angleiridocornee.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Angle irido cornéen -->
        <!-- RPM -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.rpmpresentetsymetrique">
            <p *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present==true"> Présent et symétrique</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.marcusgun.present==true"> Marcus gun +</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.mydriase.present==true"> Mydriase</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.myosis.present==true"> Myosis</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.semimydriase.present==true">Semi mydriase</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.rpmpresentetsymetrique.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>RPM</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.rpmpresentetsymetrique">
            <p *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present==true"> Présent et symétrique</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.marcusgun.present==true"> Marcus gun +</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.mydriase.present==true"> Mydriase</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.myosis.present==true"> Myosis</p>
            <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.semimydriase.present==true">Semi mydriase</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.rpmpresentetsymetrique.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of RPM -->
        <!-- Dilatation -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.dilatation">
            <p *ngIf="myExamen.laf.dilatation.oielDroit.bonne.present==true">Bonne</p>
            <p class="second-color" *ngIf="myExamen.laf.dilatation.oielDroit.mauvaise.present==true ">Mauvaise</p>
            <p class="second-color" *ngIf="myExamen.laf.dilatation.oielDroit.moyenne.present==true">Moyenne </p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.dilatation.oielDroit.allAnomalies">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Dilatation</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.dilatation">
            <p *ngIf="myExamen.laf.dilatation.oielGauche.bonne.present==true">Bonne</p>
            <p class="second-color" *ngIf="myExamen.laf.dilatation.oielGauche.mauvaise.present==true ">Mauvaise</p>
            <p class="second-color" *ngIf="myExamen.laf.dilatation.oielGauche.moyenne.present==true">Moyenne </p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.dilatation.oielGauche.allAnomalies">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Dilatation -->
        <!-- Cristallin -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.cristallinTransparent">
            <p *ngIf="myExamen.laf.cristallinTransparent.oielDroit.transparent.present==true">Transparent</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.aphaquie.present==true">Aphaquie</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.present==true">Capsule antérieure calcifiée</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.capsuleantrompu.present==true">Capsule antérieure rompue</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.cataracte.present==true">Cataracte</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.corpsetranger.present==true">Corps étranger</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.ectopie.present==true">Ectopie</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.fragilitezonulaire.present==true">Fragilité zonulaire</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.glaucomeflecken.present==true">Glaucome flecken</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.luxationSubluxation.present==true">Luxation/subluxation</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.microspheiohaquie.present==true">Microspheiohaquie</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.phacosclerose.present==true">Phacosclérose</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.phacodonesis.present==true">Phacodonésis</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.pseudophaque.present==true">Pseudophaque</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.SPE.present==true">SPE</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.cristallinTransparent.oielDroit.allAnomalies">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Cristallin</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.cristallinTransparent">
            <p *ngIf="myExamen.laf.cristallinTransparent.oielGauche.transparent.present==true">Transparent</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.aphaquie.present==true">Aphaquie</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.present==true">Capsule antérieure calcifiée</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.capsuleantrompu.present==true">Capsule antérieure rompue</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.cataracte.present==true">Cataracte</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.corpsetranger.present==true">Corps étranger</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.ectopie.present==true">Ectopie</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.fragilitezonulaire.present==true">Fragilité zonulaire</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.glaucomeflecken.present==true">Glaucome flecken</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.luxationSubluxation.present==true">Luxation/subluxation</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.microspheiohaquie.present==true">Microspheiohaquie</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.phacosclerose.present==true">Phacosclérose</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.phacodonesis.present==true">Phacodonésis</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.pseudophaque.present==true">Pseudophaque</p>
            <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.SPE.present==true">SPE</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.cristallinTransparent.oielGauche.allAnomalies">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Cristallin -->
        <!-- Vitré -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.vitre">
            <p *ngIf="myExamen.laf.vitre.oielDroit.calmeEtAcellulaire.present==true">Calme et acellulaire</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.banquise.present==true">Banquise</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.brides.present==true">Brides</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.cordage.present==true">Cordage</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.corpsasterode.present==true">Corps astéroïde</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.corpsetrangers.present==true">Corps étrangers</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.DPV.present==true">DPV</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.essaimagetumoral.present==true">Essaimage tumoral</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.fragmentcristallinien.present==true">Fragment cristallinien</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.hematique.present==true">Hématique</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.hemorragiepreretinienne.present==true">Hémorragie prérétinienne</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.hemorragievitreene.present==true">Hémorragie vitréene</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.Lacune.present==true">Lacune</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.liquefaction.present==true">Liquéfaction</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.massecristallinienne.present==true">Masse cristallinienne</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.oeufdefourmis.present==true">Oeuf de fourmis</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.persistanceduvitreprimitif.present==true">Persistance du vitré primitif</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.pigmentaire.present==true">Pigmentaire</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.precipiteencolierdeperle.present==true">Précipités en colier de perles</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.proteique.present==true">Protéique</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.synchesisetincelant.present==true">Synchisis étincelant</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.tyndallcellulaire.present==true">Tyndall cellulaire</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.voilevitreen.present==true">Voile vitréen</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.vitre.oielDroit.allAnomalies">{{anomaly.titre}}</p>
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Vitre</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center" *ngIf="myExamen.laf && myExamen.laf.vitre">
            <p *ngIf="myExamen.laf.vitre.oielGauche.calmeEtAcellulaire.present==true">Calme et acellulaire</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.banquise.present==true">Banquise</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.brides.present==true">Brides</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.cordage.present==true">Cordage</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.corpsasterode.present==true">Corps astéroïde</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.corpsetrangers.present==true">Corps étrangers</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.DPV.present==true">DPV</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.essaimagetumoral.present==true">Essaimage tumoral</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.fragmentcristallinien.present==true">Fragment cristallinien</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.hematique.present==true">Hématique</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.hemorragiepreretinienne.present==true">Hémorragie prérétinienne</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.hemorragievitreene.present==true">Hémorragie vitréene</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.Lacune.present==true">Lacune</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.liquefaction.present==true">Liquéfaction</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.massecristallinienne.present==true">Masse cristallinienne</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.oeufdefourmis.present==true">Oeuf de fourmis</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.persistanceduvitreprimitif.present==true">Persistance du vitré primitif</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.pigmentaire.present==true">Pigmentaire</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.precipiteencolierdeperle.present==true">Précipités en colier de perles</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.proteique.present==true">Protéique</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.synchesisetincelant.present==true">Synchisis étincelant</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.tyndallcellulaire.present==true">Tyndall cellulaire</p>
            <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.voilevitreen.present==true">Voile vitréen</p>
            <p class="second-color" *ngFor="let anomaly of myExamen.laf.vitre.oielGauche.allAnomalies">{{anomaly.titre}}</p>
          </div>
        </div>
        <!-- end of Vitré -->
        <!-- Fond de l'œil -->
        <div class="row">
          <!-- oeil droit -->
          <div class="col-md-4 text-center">
            <!-- ! Normale -->
            <div *ngIf="
            myExamen.laf.papille.oielDroit.normaleRapport.present==true && myExamen.laf.papille.oielGauche.normaleRapport.present==true 
            && myExamen.laf.macule.oielDroit.normale.present==true&& myExamen.laf.macule.oielGauche.normale.present==true
            &&myExamen.laf.anomalieVasculaire.oielDroit.normale.present==true&&myExamen.laf.anomalieVasculaire.oielGauche.normale.present==true
            && myExamen.laf.retine.oielDroit.normale.present==true&&myExamen.laf.retine.oielGauche.normale.present==true
            ">
              Normale
            </div>
            <!-- !end of Normale -->
            <!-- ! Papille -->
            <div *ngIf="myExamen.laf && myExamen.laf.papille && myExamen.laf.papille.oielDroit.normaleRapport.present==false||myExamen.laf.papille.oielGauche.normaleRapport.present==false">
              <p *ngIf="myExamen.laf.papille.oielDroit.normaleRapport.present==true">( Papille ) Normale rapport C/D < 3/10 </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.atrophiepupillaire.present==true">( Papille ) Atrophie pupillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.bouclesasculairesprepapillaires.present==true">( Papille ) Boucles vasculaires prépapillaires</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.drusen.present==true">( Papille ) Drusen</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.dysversion.present==true">( Papille ) Dysversion</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.ElargissementdelazoneB.present==true">( Papille ) Élargissement de la zone B </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.Excavationpathologique.present==true">( Papille ) Excavation pathologique</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.ExclusiondUnvaisseauxcircumlineaire.present==true">( Papille ) Exclusion d’un vaisseaux circum linéaire </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.fibreamyeline.present==true">( Papille ) Fibre à myéline</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.fossettecolobomateuse.present==true">( Papille ) Fossette colobomateuse</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.hemorragieperipapillaire.present==true">( Papille ) Hémorragie peripapillaire </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.isntnonrespecte.present==true">( Papille ) ISNT non respecté</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.Morningglory.present==true">( Papille ) Morning glory</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.nevoxpapillaire.present==true">( Papille ) Néo-vaisseaux papillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.oedemepapillaire.present==true">( Papille ) Oedème papillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.papilleenfleurdeliseron.present==true">( Papille ) Papille en fleur de liseron</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.paleurpapillaire.present==true">( Papille ) Paleur papillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.sriesangoide.present==true">( Papille ) Stries angioïdes</p>
                  <p class="second-color" *ngFor="let anomaly of myExamen.laf.papille.oielDroit.allAnomalies">( Papille ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Papille -->
            <!-- ! Macula-->
            <div *ngIf="myExamen.laf && myExamen.laf.macule && myExamen.laf.macule.oielDroit.normale.present==false||myExamen.laf.macule.oielGauche.normale.present==false">
              <p *ngIf="myExamen.laf.macule.oielDroit.normale.present==true">( Macula ) Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.aspectenoeildeboeuf.present==true">( Macula ) Aspect en oeil de boeuf</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.aspectjaunedoeufauplat.present==true"> ( Macula ) Aspect jaune d’oeuf au plat</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.aspectpoivreetsel.present==true"> ( Macula ) Aspect poivre et sel</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.couronneexcsudatif.present==true"> ( Macula ) Couronne exsudative </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.DEP.present==true">( Macula ) DEP </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.dreusenmaculaire.present==true"> ( Macula ) Dreusen maculaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.DSR.present==true"> ( Macula ) DSR</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.eLogettemaculaire.present==true">( Macula ) Logette maculaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.etoilemaculaire.present==true">( Macula ) Etoile maculaire </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.hematomemaculaire.present==true">( Macula ) Hématome maculaire </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.hemorragiemaculaire.present==true">( Macula ) Hémorragie maculaire </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.hemorragieretrohyaloidienne.present==true"> ( Macula ) Hémorragie rétrohyaloïdienne </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.macroanevrysme.present==true"> ( Macula ) Macroanévrisme</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.macularougecerise.present==true"> ( Macula ) Macula rouge cerise</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.membraneepimaculairen.present==true"> ( Macula ) Membrane épimaculaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.perterefletfoveolaire.present==true"> ( Macula ) Perte du reflet fovéolaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.pigmentationanormale.present==true"> ( Macula ) Pigmentation anormale</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.plagedatrophie.present==true">( Macula ) Plage d’atrophie</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.remaniementmicorkystique.present==true">( Macula ) Remaniement micorkystique </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.trousmaculaire.present==true">( Macula ) Trous maculaire </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.macule.oielDroit.allAnomalies">( Macula ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Macula-->
            <!-- ! Anomalie vasculaire   -->
            <div *ngIf="myExamen.laf && myExamen.laf.anomalieVasculaire && myExamen.laf.anomalieVasculaire.oielDroit.normale.present==false||myExamen.laf.anomalieVasculaire.oielGauche.normale.present==false">
              <p *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.normale.present==true">( Anomalie vasculaire ) Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.arteriosclerose.present==true">( Anomalie vasculaire ) Artériosclérose </p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedeRH.present==true"> ( Anomalie vasculaire ) Signe de rétinopathie hypertensive</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedeRD.present==true">( Anomalie vasculaire ) Signe de rétinopathie diabétique</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.present==true"> ( Anomalie vasculaire ) Signe d’Occlusion Veineuse</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.present==true">( Anomalie vasculaire ) Signe d’Occlusion Artérielle</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.vasculite.present==true"> ( Anomalie vasculaire ) Vasculite </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.anomalieVasculaire.oielDroit.allAnomalies">( Anomalie vasculaire ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Anomalie vasculaire   -->
            <!-- ! Rétine -->
            <div *ngIf="myExamen.laf && myExamen.laf.retine && myExamen.laf.retine.oielDroit.normale.present==false||myExamen.laf.retine.oielGauche.normale.present==false">
              <p *ngIf="myExamen.laf.retine.oielDroit.normale.present==true">( Rétine ) Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.blancavecpression.present==true"> ( Rétine ) Blanc avec pression</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.blancsanspression.present==true"> ( Rétine ) Blanc sans pression</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.decollementchorodien.present==true"> ( Rétine ) Décollement choroïdien</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.decollementretine.present==true">( Rétine ) Décollement de rétine</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.degenerescencelattice.present==true"> ( Rétine ) Dégénérescence lattice</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.dehiscenceretinienne.present==true"> ( Rétine ) Déhiscence rétinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.DEP.present==true"> ( Rétine ) DEP</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.DSR.present==true"> ( Rétine ) DSR </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.exsudat.present==true">( Rétine ) Exsudat </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.foyer.present==true">( Rétine ) Foyer </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.masseretinochoroidienne.present==true"> ( Rétine ) Masse rétino/choroïdienne</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.givre.present==true">( Rétine ) Givre</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.hemorragieretinienne.present==true"> ( Rétine ) Hémorragie rétinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.macroanevrisme.present==true"> ( Rétine ) Macroanévrisme</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.microanevrisme.present==true"> ( Rétine ) Microanévrisme</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.nodulecotonneux.present==true"> ( Rétine ) Nodule cotonneux </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.palissade.present==true"> ( Rétine ) Palissade</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.pigmentation.present==true">( Rétine ) Pigmentation</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.retinoschisis.present==true"> ( Rétine ) Rétinoschisis</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.tachederp.present==true">( Rétine ) Tâche de rétinite pigmentaire </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.retine.oielDroit.allAnomalies">( Rétine ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Rétine -->
          </div>
          <div class="col-md-4 titleConclusionCenter">
            <h6>Fond de l'œil</h6>
          </div>
          <!-- oeil gauche -->
          <div class="col-md-4 text-center">
            <!-- ! Normale -->
            <div *ngIf="
            myExamen.laf.papille.oielDroit.normaleRapport.present==true && myExamen.laf.papille.oielGauche.normaleRapport.present==true 
            && myExamen.laf.macule.oielDroit.normale.present==true&& myExamen.laf.macule.oielGauche.normale.present==true
            &&myExamen.laf.anomalieVasculaire.oielDroit.normale.present==true&&myExamen.laf.anomalieVasculaire.oielGauche.normale.present==true
            && myExamen.laf.retine.oielDroit.normale.present==true&&myExamen.laf.retine.oielGauche.normale.present==true
            ">
              Normale
            </div>
            <!-- !end of Normale -->
            <!-- ! Papille -->
            <div *ngIf="myExamen.laf && myExamen.laf.papille && myExamen.laf.papille.oielDroit.normaleRapport.present==false||myExamen.laf.papille.oielGauche.normaleRapport.present==false">
              <p *ngIf="myExamen.laf.papille.oielGauche.normaleRapport.present==true"> ( Papille ) Normale rapport C/D < 3/10 </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.atrophiepupillaire.present==true">( Papille ) Atrophie pupillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.bouclesasculairesprepapillaires.present==true">( Papille ) Boucles vasculaires prépapillaires</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.drusen.present==true">( Papille ) Drusen</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.dysversion.present==true">( Papille ) Dysversion</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.ElargissementdelazoneB.present==true">( Papille ) Élargissement de la zone B </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.Excavationpathologique.present==true">( Papille ) Excavation pathologique</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.ExclusiondUnvaisseauxcircumlineaire.present==true">( Papille ) Exclusion d’un vaisseaux circum linéaire </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.fibreamyeline.present==true">( Papille ) Fibre à myéline</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.fossettecolobomateuse.present==true">( Papille ) Fossette colobomateuse</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.hemorragieperipapillaire.present==true">( Papille ) Hémorragie peripapillaire </p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.isntnonrespecte.present==true">( Papille ) ISNT non respecté</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.Morningglory.present==true">( Papille ) Morning glory</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.nevoxpapillaire.present==true">( Papille ) Néo-vaisseaux papillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.oedemepapillaire.present==true">( Papille ) Oedème papillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.papilleenfleurdeliseron.present==true">( Papille ) Papille en fleur de liseron</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.paleurpapillaire.present==true">( Papille ) Paleur papillaire</p>
                  <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.sriesangoide.present==true">( Papille ) Stries angioïdes</p>
                  <p class="second-color" *ngFor="let anomaly of myExamen.laf.papille.oielGauche.allAnomalies">( Papille ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Papille -->
            <!-- ! Macula-->
            <div *ngIf="myExamen.laf && myExamen.laf.macule && myExamen.laf.macule.oielDroit.normale.present==false||myExamen.laf.macule.oielGauche.normale.present==false">
              <p *ngIf="myExamen.laf.macule.oielGauche.normale.present==true">( Macula ) Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.aspectenoeildeboeuf.present==true">( Macula ) Aspect en oeil de boeuf</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.aspectjaunedoeufauplat.present==true"> ( Macula ) Aspect jaune d’oeuf au plat</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.aspectpoivreetsel.present==true"> ( Macula ) Aspect poivre et sel</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.couronneexcsudatif.present==true"> ( Macula ) Couronne exsudative </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.DEP.present==true">( Macula ) DEP </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.dreusenmaculaire.present==true"> ( Macula ) Dreusen maculaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.DSR.present==true"> ( Macula ) DSR</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.eLogettemaculaire.present==true">( Macula ) Logette maculaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.etoilemaculaire.present==true">( Macula ) Etoile maculaire </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.hematomemaculaire.present==true">( Macula ) Hématome maculaire </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.hemorragiemaculaire.present==true">( Macula ) Hémorragie maculaire </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.hemorragieretrohyaloidienne.present==true"> ( Macula ) Hémorragie rétrohyaloïdienne </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.macroanevrysme.present==true"> ( Macula ) Macroanévrisme</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.macularougecerise.present==true"> ( Macula ) Macula rouge cerise</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.membraneepimaculairen.present==true"> ( Macula ) Membrane épimaculaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.perterefletfoveolaire.present==true">( Macula ) Perte du reflet fovéolaire</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.pigmentationanormale.present==true"> ( Macula ) Pigmentation anormale</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.plagedatrophie.present==true">( Macula ) Plage d’atrophie</p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.remaniementmicorkystique.present==true">( Macula ) Remaniement micorkystique </p>
              <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.trousmaculaire.present==true">( Macula ) Trous maculaire </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.macule.oielGauche.allAnomalies">( Macula ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Macula-->
            <!-- ! Anomalie vasculaire   -->
            <div *ngIf="myExamen.laf && myExamen.laf.anomalieVasculaire && myExamen.laf.anomalieVasculaire.oielDroit.normale.present==false||myExamen.laf.anomalieVasculaire.oielGauche.normale.present==false">
              <p *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.normale.present==true">( Anomalie vasculaire ) Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.arteriosclerose.present==true">( Anomalie vasculaire ) Artériosclérose </p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedeRH.present==true"> ( Anomalie vasculaire ) Signe de rétinopathie hypertensive</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedeRD.present==true">( Anomalie vasculaire ) Signe de rétinopathie diabétique</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.present==true"> ( Anomalie vasculaire ) Signe d’Occlusion Veineuse</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.present==true">( Anomalie vasculaire ) Signe d’Occlusion Artérielle</p>
              <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.vasculite.present==true"> ( Anomalie vasculaire ) Vasculite </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.anomalieVasculaire.oielGauche.allAnomalies">( Anomalie vasculaire ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Anomalie vasculaire   -->
            <!-- ! Rétine -->
            <div *ngIf="myExamen.laf && myExamen.laf.retine && myExamen.laf.retine.oielDroit.normale.present==false||myExamen.laf.retine.oielGauche.normale.present==false">
              <p *ngIf="myExamen.laf.retine.oielGauche.normale.present==true">( Rétine ) Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.blancavecpression.present==true"> ( Rétine ) Blanc avec pression</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.blancsanspression.present==true"> ( Rétine ) Blanc sans pression</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.decollementchorodien.present==true"> ( Rétine ) Décollement choroïdien</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.decollementretine.present==true">( Rétine ) Décollement de rétine</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.degenerescencelattice.present==true"> ( Rétine ) Dégénérescence lattice</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.dehiscenceretinienne.present==true"> ( Rétine ) Déhiscence rétinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.DEP.present==true"> ( Rétine ) DEP</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.DSR.present==true"> ( Rétine ) DSR </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.exsudat.present==true">( Rétine ) Exsudat </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.foyer.present==true">( Rétine ) Foyer </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.masseretinochoroidienne.present==true"> ( Rétine ) Masse rétino/choroïdienne</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.givre.present==true"> ( Rétine ) Givre</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.hemorragieretinienne.present==true"> ( Rétine ) Hémorragie rétinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.macroanevrisme.present==true"> ( Rétine ) Macroanévrisme</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.microanevrisme.present==true"> ( Rétine ) Microanévrisme</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.nodulecotonneux.present==true"> ( Rétine ) Nodule cotonneux </p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.palissade.present==true"> ( Rétine ) Palissade</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.pigmentation.present==true">( Rétine ) Pigmentation</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.retinoschisis.present==true"> ( Rétine ) Rétinoschisis</p>
              <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.tachederp.present==true">( Rétine ) Tâche de rétinite pigmentaire </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.retine.oielGauche.allAnomalies">( Rétine ) {{anomaly.titre}}</p>
            </div>
            <!-- ! end of Rétine -->
          </div>
        </div>
        <!-- end of Fond de l'œil -->
      </div>
      <!-- !end of examen conclusion -->
      <!-- diagnostic spécifique -->
      <div class="col-md-12 m-t-20">
        <h6>Voulez-vous ajouter un diagnostic spécifique ? <input type="checkbox" class="radiotypeinput" id="checkbox" (change)="updateSpecificDiagnostic($event)" [(ngModel)]="myExamen.haveSpecificDiagnostic" />
        </h6>
        <div class="col-md-6" *ngIf="myExamen.haveSpecificDiagnostic">
          <textarea name="titre" class="form-control input-sm-new m-b-10" maxlength="20" placeholder="Appuyez sur Entrée pour ajouter du texte" (keyup)="updateSpecificDiagnostic($event)" id="titre" cols="50" rows="2" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9]/i.test(event.key)"></textarea>
          <div *ngIf="myExamen.additionalConclusion.length > 0" class="all-options  m-t-10">
            <span class="select-options" *ngFor="let type of myExamen.additionalConclusion"><span>{{type}}</span>
              <div class="deleted-btn" title="Supprimer" (click)="removeDiagnostic(type)">X</div>
            </span>
          </div>
        </div>
      </div>
      <!-- footer  -->
      <div class="modal-footer d-flex justify-content-center" style="border:none">
        <div class=" col d-flex justify-content-center buttonsPopup">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td class="tdButt1 ">
                  <button class="btn-next" (click)="showCatetProchainConsul()"><img src="assets/images/next.png" class="iconBoutton1"></button>
                </td>
                <td class="tdButt1 ">
                  <!-- <button class="btn button-print update-print" (click)="imprimerConclusion()" title="Imprimer la conclusion">Imprimer</button> -->
                  <div class="dropdown">
                    <button class="btn button-print update-print" title="Imprimer la conclusion">
                      Imprimer
                    </button>
                    <div class="dropdown-content">
                      <a (click)="imprimerConclusion()">Page actuelle</a>
                      <a (click)="redirectionNouvelleConclusion()">Nouvelle version avec template</a>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- ------------------------------------------------end-- Conclusion -->
    <!-- ------------------------------------------------start-- Cat et prochaine consulation -->
    <div class="row" *ngIf="isActiveCatetProchainConsul" @fade>
      <div class="header">
        <h4 class="title-section m-l-10 m-t-10">Conduite à tenir et prochaine consultation</h4>
        <!-- <div id="buttons">
          <button class="btn button-save">Enregister</button>
          <button class="btn  button-print">Imprimer</button>
        </div> -->
      </div>
      <!-- Conduite à tenir -->
      <div class="col-md-12 m-t-20">
        <h6>Conduite à tenir</h6>
        <div class="col-md-6">
          <select class="form-control input-sm m-b-10" [(ngModel)]="selectedCat" (change)="onCatChange()">
            <option *ngFor="let type of listCat" [value]="type">{{type}}</option>
          </select>
        </div>
        <div *ngIf="myExamen.tags.length > 0" class="all-options  m-t-10 col-md-9 ">
          <span class="select-options" *ngFor="let type of myExamen.tags"><span title="Afficher/Cacher détails" style="cursor: pointer;" (click)="showAndHidePrescire(type)">{{type}}</span>
            <div class="deleted-btn" title="Supprimer" (click)="removeCat(type)">X</div>
          </span>
        </div>
        <!-- Prescrire des verres -->
        <div class="row" *ngIf="isActivePrescrireDesVerres && prescrireVerre" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Prescrire des lunettes</h4>
          </div>
          <!-- --------------de loin ----------- -->
          <div>
            <label class="switch m-l-10">
              <input type="checkbox" [(ngModel)]="prescrireVerre.deLoin.present" />
              <span class="slider round"></span>
            </label>
            <p id="textswitchtoggle">De loin</p>
          </div>
          <div class="row" id="presVerreLoin" *ngIf="prescrireVerre && prescrireVerre.deLoin.present">
            <div class="col-4 col-md-4 col-sm-12 ">
              <div class="acuiteLoin">
                <div class="blocCyclop">
                  <p>Sphère</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.deLoin.oielDroit.sphere" (change)="changeLoinODSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementLoinODSph()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementLoinODSph()">▼</div>
                    </div>
                  </div>
                </div>
                <div class=" blocCyclop">
                  <p>Cylindre</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.deLoin.oielDroit.cylindre" (change)="changeLoinODCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementLoinODCy()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementLoinODCy()">▼</div>
                    </div>
                  </div>
                </div>
                <div class="blocCyclop">
                  <p>Axe</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.deLoin.oielDroit.axe" (change)="changeLoinODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementLoinODAxe()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementLoinODAxe()">▼</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-4 col-sm-12">
              <div class="acuiteLoin">
                <div class="blocCyclop p-r-l-18">
                  <p>DP (mm)</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="4" class="inputCyclop form-control input-sm input-black" [(ngModel)]="prescrireVerre.deLoin.dp" (change)="onchangeLoinDp($event.target.value)" onkeypress="return /[0-9+-.]/i.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementLoinDp()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementLoinDp()">▼</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-4 col-sm-12">
              <div class="acuiteLoin">
                <div class="blocCyclop">
                  <p>Sphère</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.deLoin.oielGauche.sphere" (change)="changeLoinOGSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementLoinOGSph()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementLoinOGSph()">▼</div>
                    </div>
                  </div>
                </div>
                <div class=" blocCyclop">
                  <p>Cylindre</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.deLoin.oielGauche.cylindre" (change)="changeLoinOGCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementLoinOGCy()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementLoinOGCy()">▼</div>
                    </div>
                  </div>
                </div>
                <div class="blocCyclop">
                  <p>Axe</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.deLoin.oielGauche.axe" (change)="changeLoinOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementLoinOGAxe()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementLoinOGAxe()">▼</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- --------------de pres ----------- -->
          <div>
            <label class="switch m-l-10  m-t-20">
              <input type="checkbox" [(ngModel)]="prescrireVerre && prescrireVerre.dePres.present" />
              <span class="slider round"></span>
            </label>
            <p id="textswitchtoggle">De prés</p>
          </div>
          <div class="row" id="presVerreLoin" *ngIf="prescrireVerre && prescrireVerre.dePres.present">
            <div class="col-4 col-md-4 col-sm-12">
              <div class="acuiteLoin">
                <div class="blocCyclop">
                  <p>Sphère</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielDroit.sphere" (change)="changePresODSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input mr-1">
                      <div class="arrow arrow-up" (click)="incrementPresODSph()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementPresODSph()">▼</div>
                    </div>
                  </div>
                </div>
                <div class=" blocCyclop">
                  <p>Cylindre</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielDroit.cylindre" (change)="changePresODCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input">
                      <div class="arrow arrow-up" (click)="incrementPresODCy()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementPresODCy()">▼</div>
                    </div>
                  </div>
                </div>
                <div class="blocCyclop">
                  <p>Axe</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielDroit.axe" (change)="changePresODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)" />
                    <div class="number-input">
                      <div class="arrow arrow-up" (click)="incrementPresODAxe()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementPresODAxe()">▼</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="noteDroit m-l-20">
                <input type="text" class="numberInput form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielDroit.add" (change)="onchangeAddDroit($event.target.value)" onkeypress="return /[0-9+]/i.test(event.key)" />
                <div class="number-input">
                  <div class="arrow arrow-up" (click)="incrementDroit()">▲</div>
                  <br />
                  <div class="arrow arrow-down" (click)="decrementDroit()">▼</div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-4 col-sm-12">
              <div class="acuiteLoin">
                <div class="blocCyclop p-r-l-18">
                  <p>DP (mm)</p>
                  <div class="noteDroit">
                    <input type="text" class="inputCyclop form-control input-sm input-black" [(ngModel)]="prescrireVerre.dePres.dp" (change)="onchangePresDp($event.target.value)" onkeypress="return /[0-9+-.]/i.test(event.key)" />
                    <div class="number-input">
                      <div class="arrow arrow-up" (click)="incrementPresDp()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementPresDp()">▼</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-4 col-sm-12">
              <div class="acuiteLoin">
                <div class="blocCyclop">
                  <p>Sphère</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielGauche.sphere" (change)="changePresPresOGSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input">
                      <div class="arrow arrow-up" (click)="incrementPresOGSph()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementPresOGSph()">▼</div>
                    </div>
                  </div>
                </div>
                <div class=" blocCyclop">
                  <p>Cylindre</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielGauche.cylindre" (change)="changePresOGCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" />
                    <div class="number-input">
                      <div class="arrow arrow-up" (click)="incrementPresOGCy()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementPresOGCy()">▼</div>
                    </div>
                  </div>
                </div>
                <div class="blocCyclop">
                  <p>Axe</p>
                  <div class="noteDroit">
                    <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielGauche.axe" (change)="changePresOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)" />
                    <div class="number-input">
                      <div class="arrow arrow-up" (click)="incrementPresOGAxe()">▲</div>
                      <br />
                      <div class="arrow arrow-down" (click)="decrementPresOGAxe()">▼</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="noteDroit m-l-20">
                <input type="text" class="numberInput form-control input-sm" [(ngModel)]="prescrireVerre.dePres.oielGauche.add" (change)="onchangeAddGauche($event.target.value)" onkeypress="return /[0-9+]/i.test(event.key)" />
                <div class="number-input">
                  <div class="arrow arrow-up" (click)="incrementGauche()">▲</div>
                  <br />
                  <div class="arrow arrow-down" (click)="decrementGauche()">▼</div>
                </div>
              </div>
            </div>
            <div class="selectSectionPrescVerr m-t-40 m-b-30 row col-12 col-md-12 m-l-10">
              <div class="col-4 col-md-4 col-sm-12">
                <label>Marque des verres</label>
                <select class="form-control input-sm " [(ngModel)]="prescrireVerre.marqueVerre">
                  <option *ngFor="let marque of listMarque" [value]="marque">{{marque}}</option>
                </select>
              </div>
              <div class="col-4 col-md-4 col-sm-12">
                <label>Type de verre</label>
                <select class="form-control input-sm" [(ngModel)]="selectedVerre" (change)="onVerreChange()">
                  <option *ngFor="let verre of listVerre" [value]="verre">{{verre}}</option>
                </select>
                <div *ngIf="prescrireVerre.typeVerre.length > 0" class="all-options m-r-10 m-t-10  p-r-10 ">
                  <span class="select-options" *ngFor="let verre of prescrireVerre.typeVerre">{{verre}} <div class="deleted-btn" title="Supprimer" (click)="removeVerre(verre)">X</div></span>
                </div>
              </div>
              <div class="col-4 col-md-4 col-sm-12">
                <label>Filtre</label>
                <select class="form-control input-sm " [(ngModel)]="selectedFiltre" (change)="onFiltreChange()">
                  <option *ngFor="let filtre of listFiltre" [value]="filtre">{{filtre}}</option>
                </select>
                <div *ngIf="prescrireVerre.filtre.length > 0" class="all-options  m-t-10 ">
                  <span class="select-options" *ngFor="let filtre of prescrireVerre.filtre">{{filtre}} <div class="deleted-btn" title="Supprimer" (click)="removeFiltre(filtre)">X</div></span>
                </div>
              </div>

            </div>
          </div>

          <!-- footer  -->
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="savePrescrire()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(prescrireVerre._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Prescrire des verres -->
        <!-- ------------------------------------------------start-- Prescrire des lentilles -->
        <div class="row" *ngIf="isActivePrescrireDesLentilles && prescrireLentille" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Prescrire des lentilles</h4>
          </div>
          <div class="col-4 col-md-4 m-l-10">
            <label>Type De Lentilles</label>
            <select class="form-control input-sm" [(ngModel)]="prescrireLentille.type">
              <option *ngFor="let lentille of typeLentilles" [value]="lentille">{{lentille}}</option>
            </select>
          </div>
          <br />
          <div>
            <div class="col-4 col-md-4 col-sm-12 m-t-20">
              <label>Torique</label>
              <br />
              <input type="checkbox" class="radiotypeinput" [(ngModel)]="prescrireLentille.torique" (change)="showTorique()"><label>Oui</label>
              <input type="checkbox" class="radiotypeinput" [(ngModel)]="!prescrireLentille.torique" (change)="hideTorique()"> <label>Non</label>
            </div>
          </div>
          <div *ngIf="prescrireLentille.torique">
            <div class="row">
              <div class="col-4 col-md-4 ">
                <div class="acuiteLoin">
                  <div class=" blocCyclop">
                    <p>Sphère</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielDroit.sphere" (change)="changeODSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                      <div class="number-input mr-1">
                        <div class="arrow arrow-up" (click)="incrementODSph()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementODSph()">▼</div>
                      </div>
                    </div>
                  </div>
                  <div class=" blocCyclop">
                    <p>Cylindre</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielDroit.cylindre" (change)="changeODCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                      <div class="number-input">
                        <div class="arrow arrow-up" (click)="incrementODCy()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementODCy()">▼</div>
                      </div>
                    </div>
                  </div>
                  <div class="blocCyclop">
                    <p>Axe</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielDroit.axe" (change)="changeODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                      <div class="number-input">
                        <div class="arrow arrow-up" (click)="incrementODAxe()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementODAxe()">▼</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 ">
                <div class="acuiteLoin">
                  <div class=" blocCyclop">
                    <p>Sphère</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielGauche.sphere" (change)="changeOGSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                      <div class="number-input mr-1">
                        <div class="arrow arrow-up" (click)="incrementOGSph()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementOGSph()">▼</div>
                      </div>
                    </div>
                  </div>
                  <div class=" blocCyclop">
                    <p>Cylindre</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielGauche.cylindre" (change)="changeOGCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                      <div class="number-input">
                        <div class="arrow arrow-up" (click)="incrementOGCy()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementOGCy()">▼</div>
                      </div>
                    </div>
                  </div>
                  <div class="blocCyclop">
                    <p>Axe</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielGauche.axe" (change)="changeOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                      <div class="number-input">
                        <div class="arrow arrow-up" (click)="incrementOGAxe()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementOGAxe()">▼</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-4 m-t-20 m-b-20">
              <label>Mode de renouvellement</label>
              <select class="form-control input-sm" [(ngModel)]="prescrireLentille.modeDeRenouvellement">
                <option *ngFor="let mode of modeRenouvellemnt" [value]="mode">{{mode}}</option>
              </select>
            </div>
            <div class="col-8 col-md-8 col-sm-12  m-t-20 m-b-40 ">
              <label>Solution d’entretien</label>
              <div id="solutionEntreitrienBloc">
                <div id="solutionEntreitrien">
                  <input type="checkbox" class="m-l-10 m-r-10 radiotypeinput" (click)="choisirSolutionMultifonction()" [checked]="prescrireLentille.solution==='Solution multifonction' ">
                  <label class="m-r-10">Solution multifonction</label>
                </div>
                <div id="solutionEntreitrien">
                  <input type="checkbox" class="m-l-10 m-r-10 radiotypeinput" (click)="choisirSolutionSaline()" [checked]="prescrireLentille.solution==='Solution saline' ">
                  <label class="m-r-10">Solution saline</label>
                </div>
                <div id="solutionEntreitrien">
                  <input type="checkbox" class="m-l-10 m-r-10 radiotypeinput" (click)="choisirSolutionOxygene()" [checked]="prescrireLentille.solution==='Solution oxygéné' ">
                  <label class="m-r-10">Solution oxygéné</label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!prescrireLentille.torique">
            <div class="row">
              <div class="col-4 col-md-4 ">
                <div class="acuiteLoin">
                  <div class=" blocCyclop">
                    <p>Sphère</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielDroit.sphere" (change)="changeODSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                      <div class="number-input mr-1">
                        <div class="arrow arrow-up" (click)="incrementODSph()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementODSph()">▼</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 ">
                <div class="acuiteLoin">
                  <div class=" blocCyclop">
                    <p>Sphère</p>
                    <div class="noteDroit">
                      <input type="text" maxlength="10" class="inputCyclop form-control input-sm" [(ngModel)]="prescrireLentille.oielGauche.sphere" (change)="changeOGSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                      <div class="number-input mr-1">
                        <div class="arrow arrow-up" (click)="incrementOGSph()">▲</div>
                        <br />
                        <div class="arrow arrow-down" (click)="decrementOGSph()">▼</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-4 m-t-20 m-b-20">
              <label>Mode de renouvellement</label>
              <select class="form-control input-sm" [(ngModel)]="prescrireLentille.modeDeRenouvellement">
                <option *ngFor="let mode of modeRenouvellemnt" [value]="mode">{{mode}}</option>
              </select>
            </div>
            <div class="col-8 col-md-8 col-sm-12  m-t-20 m-b-40 ">
              <label>Solution d’entretien</label>
              <div id="solutionEntreitrienBloc">
                <div id="solutionEntreitrien">
                  <input type="checkbox" class="m-l-10 m-r-10 radiotypeinput" (click)="choisirSolutionMultifonction()" [checked]="prescrireLentille.solution==='Solution multifonction' ">
                  <label class="m-r-10">Solution multifonction</label>
                </div>
                <div id="solutionEntreitrien">
                  <input type="checkbox" class="m-l-10 m-r-10 radiotypeinput" (click)="choisirSolutionSaline()" [checked]="prescrireLentille.solution==='Solution saline' ">
                  <label class="m-r-10">Solution saline</label>
                </div>
                <div id="solutionEntreitrien">
                  <input type="checkbox" class="m-l-10 m-r-10 radiotypeinput" (click)="choisirSolutionOxygene()" [checked]="prescrireLentille.solution==='Solution oxygéné' ">
                  <label class="m-r-10">Solution oxygéné</label>
                </div>
              </div>
            </div>
          </div>
          <!-- footer  -->
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="savePrescrirelentilles()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(prescrireLentille._id)">Imprimer</button>
              </div>
            </div>
          </div>

        </div>
        <!-- ------------------------------------------------end-- Prescrire des lentilles -->
        <!-- ------------------------------------------------start-- Prescrire Ordo -->
        <div class="row" *ngIf="isActivePrescrireOrdo && prescrireOrdo" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Prescrire une ordonnance</h4>
          </div>
          <h5 class="m-l-10"><b>Liste des médicaments</b> <button *ngIf="listDrugs && (prescrireOrdo.listDrugs.length+listDrugs.controls.length) < 10" type="button" style="margin-left: 2px;position: relative;border: none;background: no-repeat;bottom: 2px;" title="Ajouter plus des médicaments" (click)="addDrug()"><img style="width:19px;" src="assets/images/plus-5.png"></button></h5>
          <div *ngFor="let oldDrug of prescrireOrdo.listDrugs; let j =index">
            <div class="row">
              <div class="col-6 col-md-6 col-sm-12 m-b-40 ">
                <div class="m-l-10">
                  <label>Nom <button type="button" style="margin-left: -3px;position: relative;border: none;background: no-repeat;bottom: 1px;" title="Supprimer" (click)="removeOldDrug(j)"><img style="width:19px;" src="assets/images/patsDetails/error.png"></button></label>
                  <input class="form-control input-sm inputCyclop" value={{oldDrug.nom}} disabled />
                </div>
              </div>
              <div class="col-6 col-md-6 col-sm-12 m-b-40 ">
                <div class="m-l-10">
                  <label>Durée</label>
                  <input class="form-control input-sm inputCyclop" [(ngModel)]="oldDrug.duree" />
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="drugsForm">
            <div formArrayName="listDrugs">
              <div *ngFor="let drugontrol of listDrugs.controls; let i = index">
                <div class="row" [formGroup]="drugontrol">
                  <div class="col-6 col-md-6 col-sm-12 m-b-40 ">
                    <div class="m-l-10">
                      <label>Nom <button *ngIf="listDrugs && listDrugs.controls.length > 1" type="button" style="margin-left: -3px;position: relative;border: none;background: no-repeat;bottom: 1px;" title="Supprimer" (click)="removeDrug(i)"><img style="width:19px;" src="assets/images/patsDetails/error.png"></button></label> <br>
                      <!-- <input class="form-control input-sm inputCyclop" formControlName="nom" /> -->
                      <ng-select2 [data]="medicamentsData" [width]="ng2slectwidh" formControlName="nom" [allowClear]="true" [options]="options"></ng-select2>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-sm-12 m-b-40 ">
                    <div class="m-l-10">
                      <label>Durée</label>
                      <input class="form-control input-sm inputCyclop" formControlName="duree" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-6 col-sm-12  m-b-40">
                <div class="m-l-10">
                  <label>Consigne</label>
                  <input class="form-control input-sm inputCyclop" [(ngModel)]="prescrireOrdo.consigne" value="{{prescrireOrdo.consigne}}" formControlName="consigne" />
                </div>
                <div class="m-l-10">
                  <label>Information additionnel</label>
                  <textarea name="titre" class="form-control input-sm m-b-10" [(ngModel)]="prescrireOrdo.informationAdditionnel" value="{{prescrireOrdo.informationAdditionnel}}" formControlName="informationAdditionnel" id="titre" placeholder="Information additionnel" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
          </form>
          <!-- footer  -->
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="saveOrdo()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(prescrireOrdo._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------------------end-- Prescrire Ordo -->
        <!-- ------------------------------------------------start--Chirurgie -->
        <div class="row" *ngIf="isActiveChirurgie && chirurgie" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Chirurgie
            </h4>
          </div>
          <div class="row">
            <div class="col-6 col-md-6 col-sm-12" style="margin-top: -35px;">
              <div class=" m-l-10 m-t-20">
                <label>Type</label>
                <select class="form-control input-sm m-b-10" [(ngModel)]="chirurgie.types" (change)="onChirurgieTypeChange()">
                  <option *ngFor="let type of listChurigieType" [value]="type">{{type}}</option>
                </select>
              </div>
              <br>
              <div>
                <div class="m-l-10">
                  <label for="titre">Oeil</label>
                  <br>
                  <input type="checkbox" class="radiotypeinput" [checked]="chirurgie.oeil==='Oeil droit'" (change)="setOeilDroitChirugie()"><label>Oeil droit</label>
                  <input type="checkbox" class="radiotypeinput" [checked]="chirurgie.oeil==='Oeil gauche'" (change)="setOeilGaucheChirugie()"> <label>Oeil gauche</label>
                </div>
              </div>
              <div class="m-t-20 col-5 col-md-5">
                <label>Date opération</label>
                <input type="datetime-local" value="{{chirurgie.dateOperation}}" [(ngModel)]="chirurgie.dateOperation" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Clinique</label>
                  <input type="text" [(ngModel)]="chirurgie.clinique" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Forfait</label>
                  <input type="text" [(ngModel)]="chirurgie.forfait" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div class="m-l-10 m-t-20 m-b-40">
                <label>Précaution</label>
                <select class="form-control input-sm  inputCyclop m-b-10" [(ngModel)]="chirurgie.precaution">
                  <option *ngFor="let pre of precaution" [value]="pre">{{pre}}</option>
                </select>
              </div>
            </div>
            <div class="col-6 col-md-6 col-sm-12">
              <div class="m-l-10 m-t-20">
                <label>Opérateur</label>
                <input type="text" [(ngModel)]="chirurgie.operateur" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Information additionnel</label>
                  <textarea name="titre" [(ngModel)]="chirurgie.informationAdditionnel" class="form-control input-sm m-b-10" id="titre" placeholder="Information additionnel" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="saveChirurgie()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(chirurgie._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------------------end--Chirurgie -->
        <!-- ------------------------------------------------start--Prescrire Lasers -->
        <div class="row" *ngIf="isActivePrescrireLasers && prescrireLaser" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Prescrire lasers
            </h4>
          </div>
          <div class="row">
            <div class="col-6 col-md-6 col-sm-12" style="margin-top: -35px;">
              <div class=" m-l-10 m-t-20">
                <label>Type</label>
                <select class="form-control input-sm m-b-10" [(ngModel)]="prescrireLaser.types">
                  <option *ngFor="let type of listPrescLaserType" [value]="type">{{type}}</option>
                </select>
              </div>
              <br>
              <div>
                <div class="m-l-10">
                  <label for="titre">Oeil</label>
                  <br>
                  <input type="checkbox" class="radiotypeinput" [checked]="prescrireLaser.oeil==='Oeil droit'" (change)="setOeilDroitPrescLaser()"><label>Oeil droit</label>
                  <input type="checkbox" class="radiotypeinput" [checked]="prescrireLaser.oeil==='Oeil gauche'" (change)="setOeilGauchPrescLaser()"> <label>Oeil gauche</label>
                </div>
              </div>
              <div class="m-t-20 col-5 col-md-5">
                <label>Date opération</label>
                <input type="datetime-local" [(ngModel)]="prescrireLaser.dateOperation" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Clinique</label>
                  <input type="text" [(ngModel)]="prescrireLaser.clinique" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Forfait</label>
                  <input type="text" [(ngModel)]="prescrireLaser.forfait" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div class="m-l-10 m-t-20 m-b-40">
                <label>Précaution</label>
                <select class="form-control input-sm  inputCyclop m-b-10" [(ngModel)]="prescrireLaser.precaution">
                  <option *ngFor="let pre of precautionPrescLaser" [value]="pre">{{pre}}</option>
                </select>
              </div>
            </div>
            <div class="col-6 col-md-6 col-sm-12">
              <div class="m-l-10 m-t-20">
                <label>Opérateur</label>
                <input type="text" class="form-control input-sm  inputCyclop m-b-10" [(ngModel)]="prescrireLaser.operateur" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Information additionnel</label>
                  <textarea name="titre" class="form-control input-sm m-b-10" [(ngModel)]="prescrireLaser.informationAdditionnel" id="titre" placeholder="Information additionnels" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="savePrescrireLaser()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(prescrireLaser._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------------------end--Prescrire Lasers -->
        <!-- ------------------------------------------------start-- Prescrire: BO bilan orthoptique -->
        <div class="row" *ngIf="isActivePrescrireBObilanorthoptique && prescrireBoBilanOrtho" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Prescrire: BO bilan orthoptique
            </h4>
            <br>
          </div>
          <div class="row">
            <div class="col-6 col-md-6 col-sm-12 m-l-10" style="margin-top: -35px;">
              <label>Motif</label>
              <select class="form-control input-sm m-b-10" [(ngModel)]="selectedMotifBilan" (change)="onBilanMotifChange()">
                <option *ngFor="let motif of listMotifBlian" [value]="motif">{{motif}}</option>
              </select>
              <div *ngIf="prescrireBoBilanOrtho.motifs.length > 0" class="all-options  m-t-10">
                <span class="select-options" *ngFor="let motif of prescrireBoBilanOrtho.motifs">{{motif}} <div class="deleted-btn" title="Supprimer" (click)="removeBilanMotif(motif)">X</div></span>
              </div>
            </div>
          </div>
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="savePrescrireBOBilanOrthoptique()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(prescrireBoBilanOrtho._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------------------end--Prescrire: BO bilan orthoptique -->
        <!-- ------------------------------------------------start--Certificat Médical -->
        <div class="row" *ngIf="isActiveCertificatMedical && certificatMedical" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Certificat médical
            </h4>
            <br>
          </div>
          <div class="col-6 col-md-6 col-sm-12">
            <div class=" m-l-10 m-t-20" style="margin-top: -30px;">
              <label>Type</label>
              <select class="form-control input-sm m-b-10" [(ngModel)]="certificatMedical.types" (change)="reinitialiseCertificatTypes($event.target.value)">
                <option *ngFor="let type of listCertificatMedTypes" [value]="type">{{type}}</option>
              </select>
            </div>
          </div>
          <div *ngIf="certificatMedical.types==='Certificat médical de repos'" class="m-l-10">
            <div class="row">
              <div class="col-3 col-md-4 col-sm-12">
                <div class="m-l-10 m-t-20">
                  <label>Nombre de jours repos</label>
                  <input type="number" maxlength="4" [(ngModel)]="certificatMedical.nombre" min="0" class="form-control input-sm  inputCyclop m-b-10" (change)="checksigne($event.target.value)" />
                </div>
              </div>
              <div class="col-4 col-md-4 col-sm-12">
                <div class="m-l-10 m-t-20">
                  <label>A compter de</label>
                  <input type="datetime-local" [(ngModel)]="certificatMedical.compterDe" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="certificatMedical.types=='Certificat d’accompagnement'">
            <div class="row">
              <div class="col-3 col-md-4 col-sm-12">
                <div class="m-l-10 m-t-20">
                  <label>Nombre de jours repos</label>
                  <input type="number" maxlength="4" [(ngModel)]="certificatMedical.nombre" min="0" class="form-control input-sm  inputCyclop m-b-10" (change)="checksigne($event.target.value)" />
                </div>
              </div>
              <div class="col-4 col-md-4 col-sm-12">
                <div class="m-l-10 m-t-20">
                  <label>A compter de</label>
                  <input type="datetime-local" [(ngModel)]="certificatMedical.compterDe" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="certificatMedical.types!=='Certificat médical de repos'&& certificatMedical.types!=='Certificat d’accompagnement'">
            <div class="col-4 col-md-4 col-sm-12">
              <div class="m-t-20">
                <label>Date</label>
                <input type="datetime-local" [(ngModel)]="certificatMedical.dateCertificat" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
            </div>
          </div>
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="saveCertificatMecical()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(certificatMedical._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------------------end--Certificat Médical -->
        <!-- ------------------------------------------------start--Certificat aptitude Permis -->
        <div class="row" *ngIf="isActiveCertificataptitudePermis && certificataptitudepermis" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Certificat aptitude permis
            </h4>
          </div>
          <div class="row">
            <div class="col-6 col-md-6 col-sm-12">
              <h5 class="m-l-10">Oeil droit</h5>
              <div class="m-l-10 m-t-20">
                <label>Acuité visuel sans correction</label>
                <input type="text" [(ngModel)]="certificataptitudepermis.oeilDroit.acuiteVisuelSansCorrection" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Acuité visuel avec correction </label>
                  <input type="text" [(ngModel)]="certificataptitudepermis.oeilDroit.acuiteVisuelAvecCorrection" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Acuité visuel binoculaire</label>
                  <input type="text" [(ngModel)]="certificataptitudepermis.oeilDroit.acuiteVisuelBinoculaire" class="form-control input-sm  inputCyclop m-b-40" />
                </div>
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Information additionnel</label>
                  <textarea name="titre" [(ngModel)]="certificataptitudepermis.informationAdditionnel" class="form-control input-sm m-b-10" id="titre" placeholder="Information additionnels" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-6 col-sm-12">
              <h5 class="m-l-10">Oeil gauche</h5>
              <div class="m-l-10 m-t-20">
                <label>Acuité visuel sans correction</label>
                <input type="text" [(ngModel)]="certificataptitudepermis.oeilGauche.acuiteVisuelSansCorrection" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Acuité visuel avec correction </label>
                  <input type="text" [(ngModel)]="certificataptitudepermis.oeilGauche.acuiteVisuelAvecCorrection" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Acuité visuel binoculaire</label>
                  <input type="text" [(ngModel)]="certificataptitudepermis.oeilGauche.acuiteVisuelBinoculaire" class="form-control input-sm  inputCyclop m-b-40" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="savecertificataptitudepermis()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(certificataptitudepermis._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------------------end--Certificat aptitude Permis -->
        <!-- lettre confrer-->
        <div class="row" *ngIf="isActiveLettreConfrere && lettreConfrere" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Lettre confrère
            </h4>
            <br>
          </div>
          <div class="col-6 col-md-6 col-sm-12">
            <div class="m-l-10 m-t-20" style="margin-top: -30px;">
              <label>Nom du médecin</label>
              <input type="text" class="form-control input-sm" placeholder="Médecin" name="medecin" autocomplete="off" [(ngModel)]="nom" (keyup)="onKey($event)" (valueChanged)="myCallback($event)" ngui-auto-complete [source]="mesMedecins" [list-formatter]="autocompleListFormatterPrenom" min-chars="4" max-num-list="10" no-match-found-text="Aucun élément">
            </div>
            <div>
              <div class="m-t-20 m-l-10">
                <label>Lettre confrère</label>
                <textarea name="titre" class="form-control input-sm m-b-10" [(ngModel)]="lettreConfrere.contenue" id="titre" placeholder="Lettre confrère" cols="30" rows="10"></textarea>
              </div>
            </div>
          </div>
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="saveLettreconfrere()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(lettreConfrere._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- end lettre confrere-->
        <!-- ------------------------------------------------start--Examen Complémentaire -->
        <div class="row" *ngIf="isActiveExamenComplementaire && examenComplementaire" @fade>
          <div class="header">
            <h4 class="title-section m-l-10 m-t-10">Examen complémentaire
            </h4>
          </div>
          <div class="row">
            <div class="col-6 col-md-6 col-sm-12">
              <div class=" m-l-10 m-t-20">
                <label>Type</label>
                <select class="form-control input-sm m-b-10" [(ngModel)]="selectedExamComp" (change)="onExamCompChange()">
                  <option *ngFor="let type of listExamCompType" [value]="type">{{type}}</option>
                </select>
                <div *ngIf="examenComplementaire.types.length > 0" class="all-options  m-t-10">
                  <span class="select-options" *ngFor="let type of examenComplementaire.types">{{type}} <div class="deleted-btn" title="Supprimer" (click)="removeExamCompType(type)">X</div></span>
                </div>
              </div>
              <br>
              <div>
                <div class="m-l-10">
                  <label for="titre">Oeil</label>
                  <br>
                  <input type="checkbox" class="radiotypeinput" [checked]="examenComplementaire.oeil==='Oeil droit'" (change)="setOeilDroitExamComp()"><label>Oeil droit</label>
                  <input type="checkbox" class="radiotypeinput" [checked]="examenComplementaire.oeil==='Oeil gauche'" (change)="setOeilGauchExamComp()"> <label>Oeil gauche</label>
                </div>
              </div>
              <div class="m-t-20 col-5 col-md-5">
                <label>Date opération</label>
                <input type="datetime-local" [(ngModel)]="examenComplementaire.dateOperation" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Clinique</label>
                  <input type="text" [(ngModel)]="examenComplementaire.clinique" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Forfait</label>
                  <input type="text" [(ngModel)]="examenComplementaire.forfait" class="form-control input-sm  inputCyclop m-b-10" />
                </div>
              </div>
              <div class="m-l-10 m-t-20 m-b-40">
                <label>Précaution</label>
                <select class="form-control input-sm  inputCyclop m-b-10" [(ngModel)]="examenComplementaire.precaution">
                  <option *ngFor="let pre of precautionExamComp" [value]="pre">{{pre}}</option>
                </select>
              </div>
            </div>
            <div class="col-6 col-md-6 col-sm-12">
              <div class="m-l-10 m-t-20">
                <label>Opérateur</label>
                <input type="text" [(ngModel)]="examenComplementaire.operateur" class="form-control input-sm  inputCyclop m-b-10" />
              </div>
              <div>
                <div class="m-t-20 m-l-10">
                  <label>Information additionnel</label>
                  <textarea name="titre" [(ngModel)]="examenComplementaire.informationAdditionnel" class="form-control input-sm m-b-10" id="titre" placeholder="Information additionnels" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="" class="row row-center">
              <div class="col-md-4 mx-auto">
                <button class="btn button-save" (click)="saveExamenComp()">Enregister</button>
                <button class="btn button-print" (click)="imprimerCat(examenComplementaire._id)">Imprimer</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------------------end--Examen Complémentaire -->

      </div>
      <!-- Date + motif -->
      <div class="row m-t-20">
        <h6>Prochaine consultation</h6>
        <div class="row">
          <div class="col-md-3 ">
            <div>
              <input type="radio" class="radiotypeinput" name="duree" (change)="updateProgrammeRdv()" [(ngModel)]="myExamen.nextAppointmentDuration" value="Après 15 jours">Après 15 jours
            </div>
            <div>
              <input type="radio" class="radiotypeinput" name="duree" (change)="updateProgrammeRdv()" [(ngModel)]="myExamen.nextAppointmentDuration" value="Après 1 mois">Après 1 mois <br>
            </div>
            <div>
              <input type="radio" class="radiotypeinput" name="duree" (change)="updateProgrammeRdv()" [(ngModel)]="myExamen.nextAppointmentDuration" value="Après 6 mois">Après 6 mois
            </div>
            <div>
              <input type="radio" class="radiotypeinput" name="duree" (change)="updateProgrammeRdv()" [(ngModel)]="myExamen.nextAppointmentDuration" value="Après 1 an">Après 1 an <br>
            </div>
            <div>
              <input type="radio" class="radiotypeinput" name="duree" (change)="updateProgrammeRdv()" [(ngModel)]="myExamen.nextAppointmentDuration" value="Date libre">Date libre
            </div>
          </div>
          <div class="col-md-3 m-r-t-33" *ngIf="myExamen && myExamen.nextAppointmentDuration === 'Date libre' ">
            <label>Date</label>
            <input type="datetime-local" [min]="minDate" (change)="updateProgrammeRdv()" [(ngModel)]="myExamen.nextAppointment" class="form-control input-sm-new">
          </div>
          <div class="col-md-3 m-r-t-33" *ngIf="myExamen && myExamen.nextAppointmentDuration === 'Après 15 jours' ">
            <label>Date</label>
            <input type="datetime-local" (change)="updateProgrammeRdvDateManuel()" [(ngModel)]="myExamen.nextAppointment" [value]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:mm'" [min]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:MM'" class="form-control input-sm-new">
          </div>
          <div class="col-md-3 m-r-t-33" *ngIf="myExamen && myExamen.nextAppointmentDuration === 'Après 1 mois' ">
            <label>Date</label>
            <input type="datetime-local" (change)="updateProgrammeRdvDateManuel()" [(ngModel)]="myExamen.nextAppointment" [value]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:mm'" [min]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:MM'" class="form-control input-sm-new">
          </div>
          <div class="col-md-3 m-r-t-33" *ngIf="myExamen && myExamen.nextAppointmentDuration === 'Après 6 mois' ">
            <label>Date</label>
            <input type="datetime-local" (change)="updateProgrammeRdvDateManuel()" [(ngModel)]="myExamen.nextAppointment" [value]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:mm'" [min]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:MM'" class="form-control input-sm-new">
          </div>
          <div class="col-md-3 m-r-t-33" *ngIf="myExamen && myExamen.nextAppointmentDuration === 'Après 1 an' ">
            <label>Date</label>
            <input type="datetime-local" (change)="updateProgrammeRdvDateManuel()" [(ngModel)]="myExamen.nextAppointment" [value]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:mm'" [min]="myExamen.nextAppointment | date:'yyyy-MM-ddTHH:MM'" class="form-control input-sm-new">
          </div>
          <div class="col-md-4 m-r-t-33">
            <form [formGroup]="formMotif">
              <label>Motif</label>
              <ng-select [(ngModel)]="nextMotif" [items]="motifList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" formControlName="motif" [addTag]="true" (change)="addInputMotif($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)"></ng-select>
              <!-- <input type="text" name="type" list="typename" (change)="addInputMotif($event)" class="form-control input-sm-new" [(ngModel)]="nextMotif" formControlName="motif" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
              <datalist id="typename">
                <option *ngFor="let motif of motifList" [value]="motif">
              </datalist> -->
              <div *ngIf="formMotif.controls.motif?.invalid&& (formMotif.controls.motif?.dirty || formMotif.controls.motif?.touched)&& formMotif.controls.motif?.errors">
                <div *ngIf="formMotif.controls.motif?.errors.minlength" style="color: red;font-size: 15px;">{{motifMinLength}}</div>
                <div *ngIf="formMotif.controls.motif?.errors.maxlength" style="color: red;font-size: 15px;">{{motifMaxLength}}</div>
                <div *ngIf="formMotif.controls.motif?.errors.invalidMotif" style="color: red;font-size: 15px;">{{champSpecialCaracters}}</div>
              </div>
              <div class="mt-2"><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;" (click)="removeMotif(mot)">X</div> <br></span></div>
            </form>
          </div>
        </div>
      </div>
      <!-- footer  -->
      <div class="modal-footer d-flex justify-content-center" style="border:none">
        <div class=" col d-flex justify-content-center buttonsPopup">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td class="tdButt2">
                  <button class="btn-previous" (click)="showConclusion()"><img src="assets/images/retour.png" class="iconBoutton1"></button>
                </td>
                <td class="tdButt1 ">
                  <button class="btn-next" (click)="updateExamen()"><img src="assets/images/save.png" class="iconBoutton1"></button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- ------------------------------------------------end-- Cat et prochaine consulation -->
  </div>
</div>