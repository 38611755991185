import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private screenWidthSubject: BehaviorSubject<number> = new BehaviorSubject<number>(window.innerWidth);

  constructor() {
    // Listen for window resize events
    this.setScreenWidth(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setScreenWidth(event.target.innerWidth);
  }

  getScreenWidth(): BehaviorSubject<number> {
    return this.screenWidthSubject;
  }

  private setScreenWidth(width: number) {
    this.screenWidthSubject.next(width);
  }
}
