import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class NotificationService {
  constructor(private http: HttpClient) {

  }
  //get last 5  Notifications by medecin
  getNotificationsByMedecin(medecinId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.notification) + '/medecin/' + medecinId, { headers: headers })
      
  }
  //get ALl Notifications by medecin

  getAllNotificationsByMedecin(medecinId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.notification) + '/medecin/' + medecinId + '/all', { headers: headers })
      
  }
  addNotification(newNotification) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.notification), (newNotification), { headers: headers })
      
  }
  editNotification(notification) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.notification) + '/' + notification._id, (notification), { headers: headers })
      
  }
  addNotificationEmail(patient) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.notification) + '/notificationEmail', (patient), { headers: headers })
      
  }
  makeItAllReaded(notifications){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.notification) + '/make/it/readed', (notifications), { headers: headers })
      
  }
}
