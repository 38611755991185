import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray,Validators } from '@angular/forms'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from '../../../../../module/index';
import { AuthService } from '../../../../services/auth/auth.service';
import { PatientService } from '../../../../services/profils/patient/patient.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { NotificationService } from '../../../../services/notification/notification.service';
@Component({
  selector: 'app-recherche-patients',
  templateUrl: './recherche-patients.component.html',
  styleUrls: ['../list-patients/list-patients.component.css']
})
export class RecherchePatientsComponent implements OnInit {
  patients: any[] = [];
  public search: any = '';
  medecin: any;
  form: any;
  params: any;
  patient: any;
  interval: any;
  messages: any[] = [];
  secretaire: any;
  errDate = false;
  today = new Date().toISOString().split('T')[0]
  erridentifiant = false;
  p:any
  constructor(private formBuilder: FormBuilder, private router: Router, 
    private flashMessage: FlashMessagesService, private authService: AuthService,
    private activatedRoute: ActivatedRoute, private patientService: PatientService, private notificationService:NotificationService,
  ) { }
  ngOnInit() {
    let el = document.getElementById('patientsidposition');
    el.scrollIntoView({ behavior: "smooth" });
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile:any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;
      this.authService.getProfileSecretaire().subscribe((profile:any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.params = params;

    });
    this.patientService.getAllComptePatientsByRecherche(this.params).subscribe((patients:any[]) => {
      this.patients = patients;
    },
      err => {
        console.log(err);
        return false;
      });
      this.form = this.formBuilder.group({
        identifiant: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
        dateAnniversaire :['', [Validators.required]]
      })
  }
  ajouterPatient(patient) {

    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;
    }
    var foundMedecin = patient.listMedecinsIdEnattente.find(x => x._id == this.medecin._id)
    if (foundMedecin) {
      Swal.fire({
        imageUrl: 'assets/images/patsDetails/error.png',
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Custom image',
        width: 800,
        title: 'Ce patient existe dans votre liste !',
        timer: 3000,
        showConfirmButton: false
      }
      );
      return false;
    } if(!foundMedecin) {
      var foundMedecinSearch = patient.listMedecinsId.find(x => x._id == this.medecin._id)
      if(foundMedecinSearch){
        Swal.fire({
          imageUrl: 'assets/images/patsDetails/error.png',
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: 'Custom image',
          width: 800,
          title: 'Ce patient existe dans votre liste !',
          timer: 3000,
          showConfirmButton: false
        }
        );
        return false;
      }
      if(!foundMedecinSearch){
        var foundMedecinSearchsecond = patient.listMedecins.find(x => x._id == this.medecin._id)
        if(foundMedecinSearchsecond){
          Swal.fire({
            imageUrl: 'assets/images/patsDetails/error.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 800,
            title: 'Ce patient existe dans votre liste !',
            timer: 3000,
            showConfirmButton: false
          }
          );
          return false;
        }
        if(!foundMedecinSearchsecond){
          patient.listMedecinsIdEnattente.push({
            "specialite" : this.medecin.specialite,
          "prenom" : this.medecin.prenom,
          "nom" : this.medecin.nom,
          "_id" : this.medecin._id,
          });
          this.patientService.editlistMedecinsPatient(patient).subscribe((patient:any) => {
            this.patient = patient;
            var notificationPatient = {
              texte: 'Le médecin ' + this.medecin.nom +' '+ this.medecin.prenom + ' vient de vous ajouter dans sa liste des patients',
              lien: 'mesMedecins',
              patientId: patient._id
            }
            this.notificationService.addNotification(notificationPatient).subscribe(notification => {
            });
            Swal.fire( {
                imageUrl: 'assets/images/patsDetails/plus-3.png',
                imageWidth: 100,
                imageHeight: 100,
                imageAlt: 'Custom image',
                width: 600,
                title: 'BIEN AJOUTÉ',
                timer: 1500,
                showConfirmButton: false
              }
            );
            this.router.navigate(['/medecin/patients-liste']);
          });
        }
      }
    }
  }
recherche() {
  if(!this.form.value.dateAnniversaire || this.form.value.dateAnniversaire == "" ||(this.form.value.dateAnniversaire && this.form.value.dateAnniversaire.length != 10) ||(this.form.value.dateAnniversaire && this.form.value.dateAnniversaire > this.today)){
    this.errDate = true;
    return true;
  }
  if(!this.form.value.identifiant || this.form.value.identifiant == "" ||(this.form.value.identifiant && this.form.value.identifiant.length < 4) || (this.form.value.identifiant && this.form.value.identifiant.length > 25)){
    this.erridentifiant = true;
    return true;
  }
  this.errDate = false;
  this.erridentifiant = false;
  this.patients = []
  this.patientService.getAllComptePatientsByRecherche(this.form.value).subscribe((patients:any[]) => {
    this.patients = patients;
  },
    err => {
      console.log(err);
      return false;
    });
}
}

