<section id="cours" style="width: 85%;margin: 0 auto;margin-top: 14%;">
    <div id="demo">
      <div class="table-responsive-vertical shadow-z-1">
        <br>
        <h3 class="primary bold hliste">Liste des certificats</h3>
        <table  class="table">
          <thead>
            <tr>
              <th class=" gauche">Date</th>
              <th class=" gauche">Titre</th>
              <th class=" gauche">Nombre de jour</th>
              <th class=" gauche">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let certificat of certificats| paginate: { itemsPerPage: 25, currentPage: d }">
              <td data-title="" [routerLink]="['/certificat', certificat._id]">
                {{certificat.date}}
              </td>
              <td data-title="" [routerLink]="['/certificat', certificat._id]">
                {{certificat.titre}}
              </td>
              <td data-title="" [routerLink]="['/certificat', certificat._id]">
                {{certificat.nbreJours}}
              </td>
              <td data-title="" class="row gauche">
                <div class="col-1" style="margin-right: 5%"> <a [routerLink]="['/medecin/certificat', certificat._id]" title="Modifier" data-toggle="modal" data-target="#modifConsult">
                    <img src="assets/images/patsDetails/edit-2.png" class="iconParametres"></a>
                </div>
                <div class="col-1">
                  <a  (click)="selectedCertificatFunction(certificat)" title="Supprimer" data-toggle="modal" data-target="#myModal9"><img src="assets/images/patsDetails/delete-2.png" class="iconParametres"></a>
                </div>
              </td>
              <!---------------------------- Pop up supprimer CERTIFICAT ---------->
              <div class="modal fade" id="myModal9" tabindex="-1" role="dialog" aria-labelledby="myModal9" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content modal-content-scan">
                    <div class="modal-header d-flex justify-content-center">
                      <h3 class="titre-modal-statut">
                        SUPPRIMER CERTIFICAT
                      </h3>
                    </div>
                    <div class="modal-body">
                      <div class="row" style="margin-left: 6%;">
                        <div class="col-1" style="   margin-right: 4%;"><img src="assets/images/listePatsEspaceMed/doc1.png" class="imgPop"></div>
                        <div class="col-10">
                          <h4 class="textContenu">VOULEZ-VOUS SUPPRIMER CE CERTIFICAT?</h4>
                        </div>
                      </div>
                      <div class="modal-footer d-flex justify-content-center">
                        <div class=" col d-flex justify-content-center buttonsPopup">
                          <div class="col-auto nbr-dossier ">
                            <table>
                              <tr>
                                <td class="tdButt1 ">
                                  <button class="btn-valider" data-dismiss="modal" data-toggle="modal"  (click)="removeCertificat(certificat)"><img src="assets/images/listePatsEspaceMed/delete2.png" class="iconBoutton">Supprimer </button>
                                </td>
                                <td class="tdButt2 ">
                                  <button class="btn-annule" data-dismiss="modal"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col col-xs-6">
            <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="d = $event" #api></pagination-controls>
          </div>
        </div>
  
      </div>
      <div class="table-responsive-vertical shadow-z-1">
        <br>
        <h3 class="primary bold hliste">Liste des ordonnances</h3>
        <table id="table" class="table">
          <thead>
            <tr>
              <th class="gauche">Date</th>
              <th class="gauche">Titre</th>
              <th class="gauche">Description</th>
              <th class="gauche">Médicaments</th>
              <th class="gauche">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ordonnance of ordonnances| paginate: { itemsPerPage: 25, currentPage: dd }">
              <td style="color:black;font-size: 0.875em;vertical-align: middle;border-top: 1px solid #e7ebee;padding: 12px 8px;" [routerLink]="['/ordonnance', ordonnance._id]">{{ordonnance.date}}</td>
              <td style="color:black;" [routerLink]="['/ordonnance', ordonnance._id]">{{ordonnance.titre}}</td>
              <td style="color:black;" [routerLink]="['/ordonnance', ordonnance._id]">{{ordonnance.descriptif}}</td>
              <td style="color:black;" [routerLink]="['/ordonnance', ordonnance._id]">
                <div *ngFor="let medicament of ordonnance.medicaments">{{medicament}}</div>
              </td>
              <td class="row gauche">
                <div class="col-1" style="margin-right: 5%"> <a  [routerLink]="['/medecin/ordonnance', ordonnance._id]" title="Modifier" data-toggle="modal" data-target="#modifConsult">
                    <img src="assets/images/patsDetails/edit-2.png" class="iconParametres"></a>
                </div>
                <div class="col-1">
                  <a  (click)="selectedOrdonnaceFunction(ordonnance)" title="Supprimer" data-toggle="modal" data-target="#myModalOrdonnance"><img src="assets/images/patsDetails/delete-2.png" class="iconParametres"></a>
                </div>
              </td>
              <!---------------------------- Pop up supprimer ORDONNANCE ---------->
              <div class="modal fade" id="myModalOrdonnance" tabindex="-1" role="dialog" aria-labelledby="myModalOrdonnance" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content modal-content-scan">
                    <div class="modal-header d-flex justify-content-center">
                      <h3 class="titre-modal-statut">
                        SUPPRIMER ORDONNANCE
                      </h3>
                    </div>
                    <div class="modal-body">
                      <div class="row" style="margin-left: 6%;">
                        <div class="col-1" style="   margin-right: 4%;"><img src="assets/images/listePatsEspaceMed/doc1.png" class="imgPop"></div>
                        <div class="col-10">
                          <h4 class="textContenu">VOULEZ-VOUS SUPPRIMER CE ORDONNANCE?</h4>
                        </div>
                      </div>
                      <div class="modal-footer d-flex justify-content-center">
                        <div class=" col d-flex justify-content-center buttonsPopup">
                          <div class="col-auto nbr-dossier ">
                            <table>
                              <tr>
                                <td class="tdButt1 ">
                                  <button class="btn-valider" data-dismiss="modal" data-toggle="modal"  (click)="removeOrdonnance(ordonnance)"><img src="assets/images/listePatsEspaceMed/delete2.png" class="iconBoutton">Supprimer </button>
                                </td>
                                <td class="tdButt2 ">
                                  <button class="btn-annule" data-dismiss="modal"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col col-xs-6">
            <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="dd = $event" #api></pagination-controls>
          </div>
        </div>
  
      </div>
      <div class="table-responsive-vertical shadow-z-1">
        <br>
        <h3 class="primary bold hliste">Liste des documents</h3>
        <table id="table" class="table">
          <thead>
            <tr>
              <th class="gauche">
                Titre
              </th>
              <th class="gauche">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let document of documents| paginate: { itemsPerPage: 25, currentPage: p };index as i">
              <td data-title="">
                  <a [href]="(imgPath + document.titre )" Target="_blank">Fichier N° {{i+1}}</a>
              </td>
              <td data-title="" class="row gauche">
                <div class="col-1">
                  <a  (click)="selectedDocumentFunction(document)" title="Supprimer" data-toggle="modal" data-target="#myModalFichier"><img src="assets/images/patsDetails/delete-2.png" class="iconParametres"></a>
                </div>
              </td>
  
              <!---------------------------- Pop up supprimer FICHIER ---------->
              <div class="modal fade" id="myModalFichier" tabindex="-1" role="dialog" aria-labelledby="myModalFichier" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content modal-content-scan">
                    <div class="modal-header d-flex justify-content-center">
                      <h3 class="titre-modal-statut">
                        SUPPRIMER FICHIER
                      </h3>
                    </div>
                    <div class="modal-body">
                      <div class="row" style="margin-left: 6%;">
                        <div class="col-1" style="   margin-right: 4%;"><img src="assets/images/listePatsEspaceMed/doc1.png" class="imgPop"></div>
                        <div class="col-10">
                          <h4 class="textContenu">VOULEZ-VOUS SUPPRIMER CE FICHIER?</h4>
                        </div>
                      </div>
                      <div class="modal-footer d-flex justify-content-center">
                        <div class=" col d-flex justify-content-center buttonsPopup">
                          <div class="col-auto nbr-dossier ">
                            <table>
                              <tr>
                                <td class="tdButt1 ">
                                  <button class="btn-valider" data-dismiss="modal" data-toggle="modal"  (click)="removeDocumentPatient(document)"><img src="assets/images/listePatsEspaceMed/delete2.png" class="iconBoutton">Supprimer </button>
                                </td>
                                <td class="tdButt2 ">
                                  <button class="btn-annule" data-dismiss="modal"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col col-xs-6">
            <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
          </div>
        </div>
  
      </div>
    </div>
  
  </section>
  
  
  
  
  
  
  <!---------------------------- Pop up supprimé avec succés ---------->
  <div class="modal fade" id="deleted" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-scan">
        <div class="modal-header d-flex justify-content-center">
          <h3 class="titre-modal-statut">
            SUPPRIMÉ AVEC SUCCÉS !
          </h3>
        </div>
        <div class="modal-body">
          <div class="row" style="margin-left: 6%;">
            <div class="col-10 text-center"><img src="assets/images/patsDetails/delete-2.png" class="imgPop"></div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" data-dismiss="modal">Ok </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <br><br><br><br>
  