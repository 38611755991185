import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../../services/event/event.service';
import { AuthService } from '../../../../services/auth/auth.service';
import * as $ from 'jquery';
declare var jQuery: any;
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { Router } from '@angular/router';
import { RdvService } from '../../../../services/rdv/rdv.service';
import { CalendarComponent } from 'ng-fullcalendar';
import { times } from '../../../pays/pays';
import { ExamenService } from '../../../../services/examen/examen.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import * as moment from 'moment'
import { Ng2IzitoastService } from 'ng2-izitoast';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['../../../login/login-medecin/login-medecin.component.css', '../../../register/register/register.component.scss', '../../rdvs/listrdvs/listrdvs.component.css']
})
export class CalendarComponentt {

  medecin: any;
  secretaire: any;
  titre: any;
  form: any;
  start: any;
  allExamnes: any[] = [];
  event: any;
  public search: string = '';
  interval: any;
  messages: any[] = [];
  rdvsToday: any[];
  day: any;
  month: any;
  year: any;
  showErrorDate = false;
  medecinId: any;
  eventClicked: any;
  hours = [];
  optionsTimeFermeture = [];
  allData = []
  showTitle = true;
  showErrorExistingEvents = false
  p: any;
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  changeCalendarView(view) {
    this.calendar.fullCalendar('changeView', view);
  }
  motifList = ["Amputation du champ visuel progressive",
    "Amputation du champvisuel brutal",
    "BAV loin brutal les deux yeux",
    "BAV loin brutal œil droit",
    "BAV loin brutal œil gauche",
    "BAV loin progressive des deux yeux",
    "BAV loin progressive œil droit",
    "BAV loin progressive œil gauche",
    "BAV près brutal les deux yeux",
    "BAV près brutal œil droit",
    "BAV près brutal œil gauche",
    "BAV près progressive des deux yeux",
    "BAV près progressive œil droit",
    "BAV près progressive œil gauche",
    "Blepharoptosis",
    "Blépharospasme",
    "Brouillard visuel",
    "Chute des cils",
    "Correction optique",
    "Diminution vision contraste",
    "Diplopie brutal",
    "Diplopie progressive",
    "Douleur Oculaire",
    "Douleur oculaire majorée à la mobilisation du globe",
    "Douleur palpébrale bilatérale",
    "Douleur palpébrale œil droit",
    "Douleur palpébrale œil gauche",
    "Flou visuel loin brutal les deux yeux",
    "Flou visuel loin brutal œil droit",
    "Flou visuel loin brutal œil gauche",
    "Flou visuel loin progressive des deux yeux",
    "Flou visuel loin progressive œil droit",
    "Flou visuel loin progressive œil gauche",
    "Flou visuel près brutal les deux yeux",
    "Flou visuel près brutal œil droit",
    "Flou visuel près brutal œil gauche",
    "Flou visuel près progressive des deux yeux",
    "Flou visuel près progressive œil droit",
    "Flou visuel près progressive œil gauche",
    "Halos colorés",
    "Héméralopie",
    "Lagophtalmie",
    "Larmoiement",
    "Myodésopsie",
    "Phosphene",
    "Photophobie",
    "Photopsie",
    "Prurit oculaire",
    "Rougeur oculaire",
    "Rougeur palpébrale",
    "Sécrétion claires",
    "Sécrétion purulentes",
    "Sensation de corp étranger",
    "Sensation de fatigue visuelle",
    "Sensation de sécheresse oculaire",
    "Voile noir devant les yeux",
    "Voile rouge devant les yeux"
  ]
  showAndHideDelete = false
  @ViewChild('closeBtnRdvAdd') closeBtnRdvAdd: ElementRef;
  @ViewChild('closeBtnRdvUpdate') closeBtnRdvUpdate: ElementRef;
  @ViewChild('closeBtnRdvDemande') closeBtnRdvDemande: ElementRef;
  patients: any[] = []
  calendarOptions: any = {
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay'
    },
    minTime: '08:00',
    maxTime: '22:00',
    locale: 'fr',
    lang: 'fr',
    buttonText: {
      today: 'Aujourd\'hui',
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour'
    },
    allDayDefault: false,
    selectable: true,
    selectHelper: true,
    editable: true,
    height: 1200,
    timeFormat: 'H:mm',
    eventLimit: true, // allow "more" link when too many events
    slotDuration: '00:15:00',
    slotLabelInterval: 15,
    slotLabelFormat: 'HH:mm',
    allDaySlot: false
  };
  selectedMotifs: string[] = [];
  selectedMotif: any;
  newEventToaddList: any[] = []
  constructor(public iziToast: Ng2IzitoastService, private patientService: PatientService, private examenService: ExamenService, private rdvService: RdvService, private router: Router, private formBuilder: FormBuilder, private authService: AuthService,
    private eventService: EventService, private http: HttpClient) {
    this.hours = times
    window.scrollTo(0, 0);
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      this.medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        this.getExemens()
        if (!this.medecin.roles.gestionAgenda) {
          this.router.navigate(['/notFound']);
          return false;
        }
        this.patientService.getPatientsByDoctor(this.medecin._id)
          .subscribe((patients: any[]) => {
            this.patients = patients;
          },
            err => {
              return false;
            });
      },
        err => {
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      this.medecinId = JSON.parse(secretaireString).medecinId;

      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionAgenda) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          return false;
        });
    }
      
    this.form = this.formBuilder.group({
      motif: [''],
      patient: ['', [Validators.required]],
    });
    setTimeout(() => {
      this.initiliaseCalendar()
    }, 500);
  
  }
  getExemens(){
    this.examenService.getAllExamnByDoctor().subscribe((data: any) => {
      if (data && data.results) {
        this.allExamnes = data.results;
        for (var j = 0; j < this.allExamnes.length; j++) {
          this.allExamnes[j].backgroundColor = "#008000"
          this.allExamnes[j].borderColor = "#008000"
          this.allExamnes[j].textColor = "#FFFFFF"
          if (this.allExamnes[j].tags && this.allExamnes[j].tags.length > 0 && this.allExamnes[j].tags.indexOf('Prescrire une chirurgie') > -1) {
            this.allExamnes[j].backgroundColor = "#FF0000"
            this.allExamnes[j].borderColor = "#FF0000"
          }
          if (this.allExamnes[j].nextAppointment && this.allExamnes[j].nextAppointment.split("T")[0] > new Date().toISOString().split("T")[0]
          ) {
            var newEventToadd = {
              "backgroundColor": "#008000",
              "borderColor": "#008000",
              "textColor": "#FFFFFF",
              "id": this.allExamnes[j].id,
              "start": this.allExamnes[j].nextAppointment,
              "nextAppointmentDuration": this.allExamnes[j].nextAppointment,
              "nextMotif": this.allExamnes[j].nextMotif,
              "patient": this.allExamnes[j].patient,
              "type": "nextAppointment",
              "title": "Prochaine RDV"
            }
            this.newEventToaddList.push(newEventToadd)
          }
          if (this.allExamnes[j].patient)
            this.allExamnes[j].title = this.allExamnes[j].patient.nom + " " + this.allExamnes[j].patient.prenom
          this.allExamnes[j].start = new Date(this.allExamnes[j].start).toISOString()

        }

        this.calendarOptions.events = this.allExamnes
        this.calendarOptions.events.push(...this.newEventToaddList)
        $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);


      }
    },
      err => {
        return false;
      });
   }
  initiliaseCalendar() {
    $('#calendar').fullCalendar('changeView', 'agendaWeek');
  }
  eventClick(calEvent) {
    if(this.medecin){
      this.showAndHideDelete = false;
      this.showErrorDate = false;
      this.eventClicked = {
        "id": calEvent.id,
        "title": calEvent.title,
        "motif": calEvent.motif,
        "duration": calEvent.duration,
        "start": calEvent.start,
        "end": calEvent.end,
        "patient": calEvent.patient,
        "type": calEvent.type,
        "tags": calEvent.tags,
        "nextAppointmentDuration": calEvent.nextAppointment,
        "nextMotif": calEvent.nextMotif,
      }
      jQuery('#detailsEventModal').modal('show');
    }
    
  }
  dayClick(details) {
    if(this.medecin){
    var todayData = new Date().toISOString().split('T')[0]
    this.start = details.date.format();
    this.showTitle = true
    if (todayData > this.start) {
      return false;
    }
    jQuery('#createEventModal').modal('show');
  }
}

  saveEvent() {
    if(!this.medecin){
      return false;
    }
    this.showErrorExistingEvents = false
    this.eventService.getAllEventsByMedecinByDateBetweenTwoDates(this.medecin._id, this.start + 'T' + this.form.value.timeStart, this.start + 'T' + this.form.value.timeEnd).subscribe((data: any) => {
      if (data && data.existevents) {
        this.showErrorExistingEvents = true
        return false;
      } else {
        //this.poursuivrecreationEvents()
        return true;
      }
    },
      err => {
        return false;
      });

  }
  controlerTimeFermeture() {
    this.optionsTimeFermeture = []
    var numberDuration = Number(this.form.value.timing)
    for (var i = 1; i < this.hours.length; i++) {
      var date = moment(this.form.value.timeStart, 'HH:mm')
        .add(numberDuration * i, 'minutes')
        .format('HH:mm');
      if (this.hours.indexOf(date) >= this.hours.indexOf("23:45")) {
        return false;
      }
      if (this.optionsTimeFermeture.indexOf(date) === -1) {
        this.optionsTimeFermeture.push(date);
      }
    }
  }
  showAndHideTitle() {
    if (this.form.value.type == "Personnel") {
      this.showTitle = true;
      return true;
    }
    if (this.form.value.type == "Professionnel") {
      this.showTitle = false;
      return false;
    }
  }

  hideDelete() {
    this.showAndHideDelete = true;
  }
  geToUpdateConsultation(consultation) {
    window.open(`medecin/ajout/interrogatoire/${consultation.patient._id}/${consultation.id}`, '_blank')

  }
  createConsultation() {
    if(!this.medecin){
      return false;
    }
    if (!this.form.value.patient || (this.selectedMotifs && this.selectedMotifs.length === 0)) {
      this.iziToast.show({
        message: 'Tous les champs sont obligatoires',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    const consultationBody = {
      patientId: this.form.value.patient._id,
      createdByDoctor: this.medecin._id,
      motif: this.selectedMotifs,
      start: this.start
    };
    this.examenService
      .ajoutExamen(consultationBody)
      .subscribe((response: any) => {
        window.open(`medecin/ajout/interrogatoire/${this.form.value.patient._id}/${response._id}`, '_blank')
      });
    // close modal after selecting motif
    jQuery('#createEventModal').modal('hide');
    // 

  }
  addInputMotif($event) {
    if (this.motifList.indexOf($event.target.value) < 0 && $event && $event.target && $event.target.value && $event.target.value.trim().length && this.motifList.length < 20) {
      this.motifList.push($event.target.value)
    }
    if (this.selectedMotifs.indexOf($event.target.value) < 0 && $event && $event.target && $event.target.value && $event.target.value.trim().length && this.selectedMotifs.length < 20) {
      this.selectedMotifs.push($event.target.value);
      this.selectedMotif = ""
    }
    return true
  }
  removeMotif(mot) {
    this.selectedMotifs.splice(this.selectedMotifs.indexOf(mot), 1);
    return true;
  }
}
