import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ValidateService } from 'src/app/services/validate/validate.service';
import { LoginMedecinComponent } from './login-medecin/login-medecin.component';
import { LogoutComponent } from './logout/logout.component';


const routes: Routes = [
  { path: 'redirect', component: LoginMedecinComponent},
  {path:'logout', component:LogoutComponent,canActivate:[AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers:[AuthService,ValidateService],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
