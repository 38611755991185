<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Macula" (click)="displayAndHideMacula()">Macula </h4>
            <div class="arrow arrow-up btnMacula" *ngIf="displayMacula" title="Cacher Macula" (click)="displayAndHideMacula()">▲</div>
            <div class="arrow arrow-up btnMacula" *ngIf="!displayMacula" title="Afficher Macula" (click)="displayAndHideMacula()">▼</div>
        </div>

        <div *ngIf="displayMacula" @fade>
            <div class="row">
            </div>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.normale.present==true}" (click)=" addRemoveFromMaculaRight('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.aspectenoeildeboeuf.present==true}" (click)="addRemoveFromMaculaRight('Aspect en oeil de boeuf')">
                                    Aspect en oeil de boeuf
                                </li>
                                <div *ngIf="showMoreMacula">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.aspectjaunedoeufauplat.present==true}" (click)="addRemoveFromMaculaRight('Aspect jaune d’oeuf au plat')">
                                        Aspect jaune d’oeuf au plat
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.aspectpoivreetsel.present==true}" (click)="addRemoveFromMaculaRight('Aspect poivre et sel')">
                                        Aspect poivre et sel
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.couronneexcsudatif.present==true}" (click)="addRemoveFromMaculaRight('Couronne exsudative')">
                                        Couronne exsudative
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.DEP.present==true}" (click)="addRemoveFromMaculaRight('DEP')">
                                        DEP </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.dreusenmaculaire.present==true}" (click)="addRemoveFromMaculaRight('Dreusen maculaire')">
                                        Dreusen maculaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.DSR.present==true}" (click)="addRemoveFromMaculaRight('DSR')">
                                        DSR
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.eLogettemaculaire.present==true}" (click)="addRemoveFromMaculaRight('Logette maculaire')">
                                        Logette maculaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.etoilemaculaire.present==true}" (click)="addRemoveFromMaculaRight('Etoile maculaire')">
                                        Etoile maculaire
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.macule.oielDroit.hematomemaculaire.present==true}" (click)="addRemoveFromMaculaRight('Hématome maculaire')">
                                    Hématome maculaire
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.hemorragiemaculaire.present==true}" (click)="addRemoveFromMaculaRight('Hémorragie maculaire')">
                                    Hémorragie maculaire
                                </li>
                                <div *ngIf="showMoreMacula">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.hemorragieretrohyaloidienne.present==true}" (click)="addRemoveFromMaculaRight('Hémorragie rétrohyaloïdienne' )">
                                        Hémorragie rétrohyaloïdienne
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.macroanevrysme.present==true}" (click)="addRemoveFromMaculaRight('Macroanévrisme')">
                                        Macroanévrisme
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.macularougecerise.present==true}" (click)="addRemoveFromMaculaRight('Macula rouge cerise')">
                                        Macula rouge cerise
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.macule.oielDroit.membraneepimaculairen.present==true}" (click)=" addRemoveFromMaculaRight('Membrane épimaculaire')">
                                        Membrane épimaculaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.perterefletfoveolaire.present==true}" (click)=" addRemoveFromMaculaRight('Perte du reflet fovéolaire')">
                                        Perte du reflet fovéolaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.pigmentationanormale.present==true}" (click)="addRemoveFromMaculaRight('Pigmentation anormale')">
                                        Pigmentation anormale
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.plagedatrophie.present==true}" (click)="addRemoveFromMaculaRight('Plage d’atrophie')">Plage d’atrophie
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.remaniementmicorkystique.present==true}" (click)="addRemoveFromMaculaRight('Remaniement micorkystique')">
                                        Remaniement micorkystique
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielDroit.trousmaculaire.present==true}" (click)="addRemoveFromMaculaRight('Trous maculaire')">
                                        Trous maculaire
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorMacula" [ngClass]="{'selectedLi': checkExistAnomalieMaculaRight(anomaly.id)}">
                                        <span (click)="addRemoveFromMaculaRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieMaculaRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-macula')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieMacula" (keyup.enter)="addNewAnomalyMacula($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreMaculaSection() *ngIf="!showMoreMacula">Voir plus</span>
                            <span (click)=showMoreMaculaSection() *ngIf="showMoreMacula">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.normale.present==true}" (click)=" addRemoveFromMaculaLeft('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.aspectenoeildeboeuf.present==true}" (click)="addRemoveFromMaculaLeft('Aspect en oeil de boeuf')">
                                    Aspect en oeil de boeuf
                                </li>
                                <div *ngIf="showMoreMacula">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.aspectjaunedoeufauplat.present==true}" (click)="addRemoveFromMaculaLeft('Aspect jaune d’oeuf au plat')">
                                        Aspect jaune d’oeuf au plat
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.aspectpoivreetsel.present==true}" (click)="addRemoveFromMaculaLeft('Aspect poivre et sel')">
                                        Aspect poivre et sel
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.couronneexcsudatif.present==true}" (click)="addRemoveFromMaculaLeft('Couronne exsudative')">
                                        Couronne exsudative
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.DEP.present==true}" (click)="addRemoveFromMaculaLeft('DEP')">
                                        DEP </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.dreusenmaculaire.present==true}" (click)="addRemoveFromMaculaLeft('Dreusen maculaire')">
                                        Dreusen maculaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.DSR.present==true}" (click)="addRemoveFromMaculaLeft('DSR')">
                                        DSR
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.eLogettemaculaire.present==true}" (click)="addRemoveFromMaculaLeft('Logette maculaire')">
                                        Logette maculaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.etoilemaculaire.present==true}" (click)="addRemoveFromMaculaLeft('Etoile maculaire')">
                                        Etoile maculaire
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.macule.oielGauche.hematomemaculaire.present==true}" (click)="addRemoveFromMaculaLeft('Hématome maculaire')">
                                    Hématome maculaire
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.hemorragiemaculaire.present==true}" (click)="addRemoveFromMaculaLeft('Hémorragie maculaire')">
                                    Hémorragie maculaire
                                </li>
                                <div *ngIf="showMoreMacula">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.hemorragieretrohyaloidienne.present==true}" (click)="addRemoveFromMaculaLeft('Hémorragie rétrohyaloïdienne' )">
                                        Hémorragie rétrohyaloïdienne
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.macroanevrysme.present==true}" (click)="addRemoveFromMaculaLeft('Macroanévrisme')">
                                        Macroanévrisme
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.macularougecerise.present==true}" (click)="addRemoveFromMaculaLeft('Macula rouge cerise')">
                                        Macula rouge cerise
                                    </li>
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.macule.oielGauche.membraneepimaculairen.present==true}" (click)=" addRemoveFromMaculaLeft('Membrane épimaculaire')">
                                        Membrane épimaculaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.perterefletfoveolaire.present==true}" (click)=" addRemoveFromMaculaLeft('Perte du reflet fovéolaire')">
                                        Perte du reflet fovéolaire
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.pigmentationanormale.present==true}" (click)="addRemoveFromMaculaLeft('Pigmentation anormale')">
                                        Pigmentation anormale
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.plagedatrophie.present==true}" (click)="addRemoveFromMaculaLeft('Plage d’atrophie')">Plage d’atrophie
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.remaniementmicorkystique.present==true}" (click)="addRemoveFromMaculaLeft('Remaniement micorkystique')">
                                        Remaniement micorkystique
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.macule.oielGauche.trousmaculaire.present==true}" (click)="addRemoveFromMaculaLeft('Trous maculaire')">
                                        Trous maculaire
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorMacula" [ngClass]="{'selectedLi': checkExistAnomalieMaculaLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromMaculaLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieMaculaLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-macula')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieMacula" (keyup.enter)="addNewAnomalyMacula($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreMaculaSection() *ngIf="!showMoreMacula">Voir plus</span>
                            <span (click)=showMoreMaculaSection() *ngIf="showMoreMacula">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.macule.oielDroit.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.perterefletfoveolaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Perte du reflet fovéolaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.perterefletfoveolaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.hemorragiemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.hemorragiemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.hematomemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hématome maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.hematomemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.hemorragieretrohyaloidienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie rétrohyaloïdienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.hemorragieretrohyaloidienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.trousmaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Trous maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.trousmaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.membraneepimaculairen.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Membrane épimaculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.membraneepimaculairen.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.dreusenmaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Dreusen maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.dreusenmaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.etoilemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Etoile maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.etoilemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.plagedatrophie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Plage d’atrophie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.plagedatrophie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.eLogettemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Logette maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.eLogettemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.aspectenoeildeboeuf.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aspect en oeil de boeuf </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.aspectenoeildeboeuf.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.pigmentationanormale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigmentation anormale </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.pigmentationanormale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.macularougecerise.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Macula rouge cerise </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.macularougecerise.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.aspectpoivreetsel.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aspect poivre et sel </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.aspectpoivreetsel.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.couronneexcsudatif.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Couronne exsudative </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.couronneexcsudatif.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.macroanevrysme.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Macroanévrisme </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.macroanevrysme.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.remaniementmicorkystique.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Remaniement micorkystique </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.remaniementmicorkystique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.DEP.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">DEP </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.DEP.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.DSR.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">DSR </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.DSR.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielDroit.aspectjaunedoeufauplat.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aspect jaune d’oeuf au plat </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielDroit.aspectjaunedoeufauplat.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.macule.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.macule.oielGauche.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.perterefletfoveolaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Perte du reflet fovéolaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.perterefletfoveolaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.hemorragiemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.hemorragiemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.hematomemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hématome maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.hematomemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.hemorragieretrohyaloidienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie rétrohyaloïdienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.hemorragieretrohyaloidienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.trousmaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Trous maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.trousmaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.membraneepimaculairen.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Membrane épimaculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.membraneepimaculairen.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.dreusenmaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Dreusen maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.dreusenmaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.etoilemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Etoile maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.etoilemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.plagedatrophie.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Plage d’atrophie </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.plagedatrophie.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.eLogettemaculaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Logette maculaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.eLogettemaculaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.aspectenoeildeboeuf.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aspect en oeil de boeuf </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.aspectenoeildeboeuf.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.pigmentationanormale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigmentation anormale </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.pigmentationanormale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.macularougecerise.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Macula rouge cerise </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.macularougecerise.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.aspectpoivreetsel.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aspect poivre et sel </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.aspectpoivreetsel.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.couronneexcsudatif.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Couronne exsudative </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.couronneexcsudatif.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.macroanevrysme.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Macroanévrisme </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.macroanevrysme.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.remaniementmicorkystique.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Remaniement micorkystique </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.remaniementmicorkystique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.DEP.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">DEP </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.DEP.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.DSR.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">DSR </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.DSR.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.macule.oielGauche.aspectjaunedoeufauplat.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Aspect jaune d’oeuf au plat </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.macule.oielGauche.aspectjaunedoeufauplat.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.macule.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>