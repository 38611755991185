<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Dilatation" (click)="displayAndHideDilatation()">Dilatation</h4>
            <div class="arrow arrow-up btnDilalation" *ngIf="displayDilatation" title="Cacher Dilatation" (click)="displayAndHideDilatation()">▲</div>
            <div class="arrow arrow-up btnDilalation" *ngIf="!displayDilatation" title="Afficher Dilatation" (click)="displayAndHideDilatation()">▼</div>
        </div>
        <div *ngIf="displayDilatation" @fade>
            <div class="col-12 sansCyclo">
                <div class=" cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.dilatation.oielDroit.bonne.present==true}" (click)="addRemoveFromDilatationRight('Bonne')">
                                    Bonne
                                </li>
                                <li [ngClass]="{ selectedLi: examLaf?.laf.dilatation.oielDroit.mauvaise.present==true }" (click)="addRemoveFromDilatationRight('Mauvaise')">
                                    Mauvaise
                                </li>
                                <div *ngIf="showMoreDilatation"></div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.dilatation.oielDroit.moyenne.present==true}" (click)="addRemoveFromDilatationRight('Moyenne')">
                                    Moyenne
                                </li>
                                <div *ngIf="showMoreDilatation">
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorDilatation" [ngClass]="{'selectedLi': checkExistAnomalieDilatationRight(anomaly.id)}">
                                        <span (click)="addRemoveFromDilatationRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieDilatationRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-dilatation')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieDilatation" (keyup.enter)="addNewAnomalyDilatation($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>

                            </ul>
                        </div>

                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreDilatationSection() *ngIf="!showMoreDilatation">Voir plus</span>
                            <span (click)=showMoreDilatationSection() *ngIf="showMoreDilatation">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.dilatation.oielGauche.bonne.present==true}" (click)="addRemoveFromDilatationLeft('Bonne')">
                                    Bonne
                                </li>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.dilatation.oielGauche.mauvaise.present==true }" (click)="addRemoveFromDilatationLeft('Mauvaise')">
                                    Mauvaise
                                </li>
                                <div *ngIf="showMoreDilatation"></div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.dilatation.oielGauche.moyenne.present==true}" (click)="addRemoveFromDilatationLeft('Moyenne')">
                                    Moyenne
                                </li>
                                <!-- dynamic anamaly -->
                                <div *ngIf="showMoreDilatation">
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorDilatation" [ngClass]="{'selectedLi': checkExistAnomalieDilatationLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromDilatationLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieDilatationLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-dilatation')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieDilatation" (keyup.enter)="addNewAnomalyDilatation($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreDilatationSection() *ngIf="!showMoreDilatation">Voir plus</span>
                            <span (click)=showMoreDilatationSection() *ngIf="showMoreDilatation">Voir moins</span>
                        </div>
                    </div>
                </div>

            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.dilatation.oielDroit.bonne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Bonne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.dilatation.oielDroit.bonne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.dilatation.oielDroit.mauvaise.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mauvaise </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.dilatation.oielDroit.mauvaise.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.dilatation.oielDroit.moyenne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Moyenne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.dilatation.oielDroit.moyenne.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.dilatation.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.dilatation.oielGauche.bonne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Bonne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.dilatation.oielGauche.bonne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.dilatation.oielGauche.mauvaise.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mauvaise </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.dilatation.oielGauche.mauvaise.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.dilatation.oielGauche.moyenne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Moyenne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.dilatation.oielGauche.moyenne.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.dilatation.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>