import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'globalSearch'
})
export class GlobalSearchPipe implements PipeTransform {
  transform(value, args: string[]){
    if (!value) return value;
    if (value.filter((item) => item.nom_patient.toLowerCase().includes(args)) != '') {
      return value.filter((item) => item.nom_patient.toLowerCase().includes(args));}
      if (value.filter((item) => item.prenom_patient.toLowerCase().includes(args)) != '') {
        return value.filter((item) => item.prenom_patient.toLowerCase().includes(args));}
      if (value.filter((item) => item.motif.includes(args)) != '') {
      return value.filter((item) => item.motif.includes(args));}
      if (value.filter((item) => item.email.includes(args)) != '') {
        return value.filter((item) => item.email.includes(args));}
        if (value.filter((item) => item.tel.includes(args)) != '') {
          return value.filter((item) => item.tel.includes(args));}
  }
}
