import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { OrderByPipe } from '../../search/order-by.pipe';
import { Observable } from 'rxjs';
import { SharedDataService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-salle-attente',
  templateUrl: './salle-attente.component.html',
  styleUrls: ['./salle-attente.component.css'],
  providers: [OrderByPipe]
})
export class SalleAttenteComponent implements OnInit {

  currentDate: string;

  medcin: any = {
    nom: 'BEN HADJ SAÏD',
    prenom: 'OMAR',
    nomAr: 'بن حاج سعيد',
    prenomAr: 'عمر',
    email: 'omarbenhajsaid@gmail.com',
    tel: '+216 55 180 065',
    adresseAr: '8 شارع الحبيب بورقيبة باردو-مركز مدرادا الطابق الرابع مكتب عدد 19 قبالة مونوبري',
    adresseCabinet: {
      adresse: '8,Avenu Habib Bourgiba - Bardo - Centre Medrara, 4ème Étage App 19 (En face de Monoprix)',
      codePostal: '',
      ville: '',
      localite: '',
      pays: '',
      latitude: '',
      longitude: '',
    },
    specialite: 'OPHTALMOLOGISTE',
    specialiteAr: 'أخصائي طب و جراحة العيون و العلاج بالليزر',
    telFixe: '+216 71 507 753',
    colorTemplates: '#BD6127',
  };

  patients: any[] = [
    {
      id: 'MEDIV-M1-5',
      nom: 'Jemni',
      prenom: 'Baha',
      tempsRV: '11:26',
      resTime: '',
      patLine: '',
      status: 1,
    },
    {
      id: 'MEDIV-M1-6',
      nom: 'Jaalouli',
      prenom: 'Hedi',
      tempsRV: '13:15',
      resTime: '',
      patLine: '',
      status: 3,
    },
    {
      id: 'MEDIV-M1-7',
      nom: 'Galaaoui',
      prenom: 'Moncef',
      tempsRV: '15:04',
      resTime: '',
      patLine: '',
      status: 2,
    },
    {
      id: 'MEDIV-M1-8',
      nom: 'Handouba',
      prenom: 'Mehdia',
      tempsRV: '14:50',
      resTime: '',
      patLine: '',
      status: 3,
    },
    {
      id: 'MEDIV-M1-9',
      nom: 'Arfaoui',
      prenom: 'Nidhal',
      tempsRV: '10:58',
      resTime: '',
      patLine: '',
      status: 1,
    },
    {
      id: 'MEDIV-M1-10',
      nom: 'Jemai',
      prenom: 'Ridha',
      tempsRV: '16:00',
      resTime: '',
      patLine: '',
      status: 3,
    },
    {
      id: 'MEDIV-M1-11',
      nom: 'Masmoudi',
      prenom: 'Ines',
      tempsRV: '16:30',
      resTime: '',
      patLine: '',
      status: 3,
    }
  ];

  runText: string;
  patients$: Observable<any[]>;

  statusOptions = {
    1: 'En attente',
    2: 'En cours',
    3: 'Terminé'
  };

  constructor(private orderByPipe: OrderByPipe, private cdr: ChangeDetectorRef,private renderer: Renderer2, private el: ElementRef,private sharedDataService:SharedDataService) {
    this.runText = `Bienvenue au cabinet Dr. ${this.medcin.prenom} ${this.medcin.nom}`;
    this.runText += ` ----- ${this.medcin.adresseCabinet.adresse} ----- ${this.medcin.telFixe} ----- ${this.medcin.tel}`;
  }

  ngOnInit(): void {
    this.updateCurrentDate();
    this.attachFullscreenListener();

    setInterval(() => {
      this.updatePatLine();
      this.updateCurrentDate();
      this.updateRemainingTime();
      this.updatePatients();
    }, 1000);
  }

  toggleFullScreen() {
    const elem = this.el.nativeElement.ownerDocument.documentElement;

    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  private attachFullscreenListener() {
    document.addEventListener('fullscreenchange', () => {
      this.handleFullscreenChange();
    });
  }

  private handleFullscreenChange() {
    const isFullscreen = !!document.fullscreenElement;
    const header = this.el.nativeElement.querySelector('.class-to-hide-on-full-screen');

    if (header) {
      if (isFullscreen) {
        this.renderer.setStyle(header, 'display', 'none');
      } else {
        this.renderer.setStyle(header, 'display', 'flex');
      }
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  formatCurrentDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const locale = 'fr-FR';
    const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);
    const parts = formattedDate.split(' ');
    const capitalizedParts = parts.map((part) =>
      this.capitalizeFirstLetter(part)
    );
    const finalDate = capitalizedParts.join(' ').replace('À', '-');

    return finalDate;
  }

  updateCurrentDate() {
    const currentDateObj = new Date();
    this.currentDate = this.formatCurrentDate(currentDateObj);
  }

  updateRemainingTime() {
    const currentDate = new Date();
    this.patients.forEach(patient => {
      const tempsRVTime = new Date();
      const [hours, minutes] = patient.tempsRV.split(':').map(Number);
      tempsRVTime.setHours(hours);
      tempsRVTime.setMinutes(minutes);

      const timeDifference = tempsRVTime.getTime() - currentDate.getTime();
      let hoursRemaining = Math.floor(timeDifference / (1000 * 60 * 60));
      let minutesRemaining = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      if (hoursRemaining < 0 || minutesRemaining < 0) {
        hoursRemaining = 0;
        minutesRemaining = 0;
      }

      patient.resTime = `${this.padZero(hoursRemaining)}:${this.padZero(minutesRemaining)}`;
      this.isCriticalTime(patient.resTime);
      this.isDoneTime(patient.resTime);
    });
  }

  isCriticalTime(resTime: string): boolean {
    const [hours, minutes] = resTime.split(':').map(Number);
    const totalMinutesRemaining = hours * 60 + minutes;
    return totalMinutesRemaining <= 5 && totalMinutesRemaining > 0;
  }

  isDoneTime(resTime: string): boolean {
    const [hours, minutes] = resTime.split(':').map(Number);
    const totalMinutesRemaining = hours * 60 + minutes;
    return totalMinutesRemaining == 0;
  }

  updatePatients() {
    const sortedPatients = this.orderByPipe.transform(this.patients, 'tempsRV');
    this.patients = sortedPatients;
    this.cdr.detectChanges();
  }

  updatePatLine() {
    this.patients.forEach(patient => {
      if (patient.status === 3) {
        patient.patLine = '0';
      } else {
        let patientsBefore = this.patients.filter(p => {
          return (p.tempsRV < patient.tempsRV && (p.status === 1 || p.status === 2));
        }).length;
    
        patient.patLine = `${patientsBefore}`;
      }
    });
  }

  padZero(num: number) {
    return num < 10 ? `0${num}` : num;
  }
}
