import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { API_CONFIG, loginPatient, _getURL, redirectNotAuthenticated } from '../../../../../config/API_CONFIG';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login-medecin',
  templateUrl: './login-medecin.component.html',
  styleUrls: ['./login-medecin.component.css']
})
export class LoginMedecinComponent implements OnInit {

  email: String;

  constructor(
    private route: ActivatedRoute,
    private authService:AuthService,
    private router:Router
  ) {}
  ngOnInit() {
    let params = this.route.snapshot.queryParams
    if (!params) {
      window.location.href = redirectNotAuthenticated
      return false;
    }
    if (params && (!params.redirect || params.redirect == null || params.redirect == "null" || params.redirect == undefined || params.redirect == "undefined" || params.redirect.indexOf("redirection") === -1)) {
      window.location.href = redirectNotAuthenticated
      return false;
    }
    let jwtToken = params.redirect.split("redirection ")
    if (!jwtToken || (jwtToken && jwtToken.length < 1)) {
      window.location.href = redirectNotAuthenticated
      return false;
    }
    let jwtTokenSplit = jwtToken[1]
    if (!jwtTokenSplit || (jwtTokenSplit && !this.isValidToken(jwtTokenSplit))) {
      window.location.href = redirectNotAuthenticated
      return false;
    }
     this.authService.authMede({token:jwtTokenSplit}).subscribe((data:any) => {
      if(data && data.success && data.medecin){
       this.authService.storeMedecinData(JSON.stringify(data.token), JSON.stringify(data.medecin));
       this.router.navigate(['/medecin/accueil']);
      } else if (data && data.success && data.secretaire){
        this.authService.storeSecretaireData(JSON.stringify(data.token), JSON.stringify(data.secretaire));
        this.router.navigate(['/medecin/patients-liste']);
       }
      else {
        window.location.href = redirectNotAuthenticated
        return false;
        }
       },
        err => {
          window.location.href = redirectNotAuthenticated
          return false;
        });

  }
  // check if token is valid
  isValidToken(token: string): boolean {
    const tokenRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return tokenRegex.test(token);
  }
}
