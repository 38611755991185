<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Sclère" (click)="displayAndHideSclere()">Sclère</h4>
            <div class="arrow arrow-up btnSclere" *ngIf="displaySclere" title="Cacher Sclère" (click)="displayAndHideSclere()">▲</div>
            <div class="arrow arrow-up btnSclere" *ngIf="!displaySclere" title="Afficher Sclère" (click)="displayAndHideSclere()">▼</div>
        </div>
        <div *ngIf="displaySclere" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroitSclere">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.sclere.oielDroit.blancheEtIntacte.present==true}" (click)="addRemoveFromSclereRight('Blanche et intacte')">
                                    Blanche et intacte
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.sclere.oielDroit.episclerite.present ==true}" (click)="addRemoveFromSclereRight('Episclérite')">
                                    Episclérite
                                </li>
                                <div *ngIf="showMoreSclere">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.sclere.oielDroit.plaie.present==true}" (click)="addRemoveFromSclereRight('Plaie')">
                                        Plaie
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.sclere.oielDroit.scleromalacie.present ==true}" (click)="addRemoveFromSclereRight('Scléromalacie')">
                                    Scléromalacie
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.sclere.oielDroit.sclerite.present ==true}" (click)="addRemoveFromSclereRight('Sclérite')">
                                    Sclérite
                                </li>
                                <div *ngIf="showMoreSclere">
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorSclere" [ngClass]="{'selectedLi': checkExistAnomalieSclereRight(anomaly.id)}">
                                        <span (click)="addRemoveFromSclereRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieSclereRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-sclere')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieSclere" (keyup.enter)="addNewAnomalySclere($event)" [disabled]="isToastVisible">
                                    </div>
                                    <!-- dynamic anamaly -->
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreSclereSection() *ngIf="!showMoreSclere">Voir plus</span>
                            <span (click)=showMoreSclereSection() *ngIf="showMoreSclere">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGaucheSclere">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>

                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.sclere.oielGauche.blancheEtIntacte.present==true}" (click)="addRemoveFromSclereLeft('Blanche et intacte')">
                                    Blanche et intacte
                                </li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.sclere.oielGauche.episclerite.present ==true}" (click)="addRemoveFromSclereLeft('Episclérite')">
                                    Episclérite
                                </li>
                                <div *ngIf="showMoreSclere">
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.sclere.oielGauche.plaie.present==true}" (click)="addRemoveFromSclereLeft('Plaie')">
                                        Plaie
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.sclere.oielGauche.scleromalacie.present ==true}" (click)="addRemoveFromSclereLeft('Scléromalacie')">
                                    Scléromalacie
                                </li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.sclere.oielGauche.sclerite.present ==true}" (click)="addRemoveFromSclereLeft('Sclérite')">
                                    Sclérite
                                </li>
                                <div *ngIf="showMoreSclere">
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorSclere" [ngClass]="{'selectedLi': checkExistAnomalieSclereLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromSclereLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieSclereLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieSclere" (keyup.enter)="addNewAnomalySclere($event)" [disabled]="isToastVisible">
                                    </div>
                                    <!-- dynamic anamaly -->
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreSclereSection() *ngIf="!showMoreSclere">Voir plus</span>
                            <span (click)=showMoreSclereSection() *ngIf="showMoreSclere">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div class="row" *ngIf="examLaf?.laf.sclere.oielDroit.blancheEtIntacte.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Blanche et intacte</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielDroit.blancheEtIntacte.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.sclere.oielDroit.episclerite.present ==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Episclérite</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielDroit.episclerite.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.sclere.oielDroit.plaie.present ==true">
                        <h5 class="titleDetails">Plaie</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.sclere.oielDroit.plaie.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.sclere.oielDroit.plaie.taille" (change)="onChangetaillePlaieRight()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielDroit.plaie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.sclere.oielDroit.scleromalacie.present ==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Scléromalacie</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielDroit.scleromalacie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.sclere.oielDroit.sclerite.present==true ">
                        <h5 class="titleDetails">Sclérite</h5>
                        <div class="col-lg-4">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.sclere.oielDroit.sclerite.localisation">
                        </div>

                        <div class="col-lg-4">
                            <label>Nodulaire</label><br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielDroit.sclerite.nodulaire=='Oui'" (change)="selectSclerescleritenodulaireRight('Oui')">Oui <br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielDroit.sclerite.nodulaire=='Non'" (change)="selectSclerescleritenodulaireRight('Non')">Non

                        </div>
                        <div class="col-lg-4">
                            <label>Nécrosante</label><br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielDroit.sclerite.necrosante=='Oui'" (change)="selectSclerescleritenecrosanteRight('Oui')">Oui <br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielDroit.sclerite.necrosante=='Non'" (change)="selectSclerescleritenecrosanteRight('Non')">Non
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielDroit.sclerite.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.sclere.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div class="row" *ngIf="examLaf?.laf.sclere.oielGauche.blancheEtIntacte.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Blanche et intacte</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielGauche.blancheEtIntacte.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.sclere.oielGauche.episclerite.present ==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Episclérite</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielGauche.episclerite.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.sclere.oielGauche.plaie.present ==true">
                        <h5 class="titleDetails">Plaie</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.sclere.oielGauche.plaie.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.sclere.oielGauche.plaie.taille" (change)="onChangetaillePlaieLeft()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielGauche.plaie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.sclere.oielGauche.scleromalacie.present ==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Scléromalacie</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielGauche.scleromalacie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.sclere.oielGauche.sclerite.present==true ">
                        <h5 class="titleDetails">Sclérite</h5>
                        <div class="col-lg-4">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.sclere.oielGauche.sclerite.localisation">
                        </div>

                        <div class="col-lg-4">
                            <label>Nodulaire</label><br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielGauche.sclerite.nodulaire=='Oui'" (change)="selectSclerescleritenodulaireLeft('Oui')">Oui <br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielGauche.sclerite.nodulaire=='Non'" (change)="selectSclerescleritenodulaireLeft('Non')">Non

                        </div>
                        <div class="col-lg-4">
                            <label>Nécrosante</label><br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielGauche.sclerite.necrosante=='Oui'" (change)="selectSclerescleritenecrosanteLeft('Oui')">Oui <br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.sclere.oielGauche.sclerite.necrosante=='Non'" (change)="selectSclerescleritenecrosanteLeft('Non')">Non
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.sclere.oielGauche.sclerite.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.sclere.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>