<div *ngIf="myExamen">
    <div class="col-md-12">
        <h6 id="color-red">TO</h6>
        <div class="row">
            <div class="col-md-4">
                <label for="" *ngIf="myExamen?.laf.toAic.oielDroit.mm">{{myExamen?.laf.toAic.oielDroit.mm}} mm</label>
            </div>
            <div class="col-md-4">
                <label for="" *ngIf="myExamen?.laf.toAic.oielGauche.mm">{{myExamen?.laf.toAic.oielGauche.mm}} mm</label>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <h6 id="color-red">Conduite à tenir</h6>
        <ul id="ul-pos">
            <li *ngFor="let tag of myExamen?.tags">
                <div *ngIf="tag === 'Prescrire une chirurgie'">Prescrire une chirurgie pour <div *ngIf="myExamen?.cat && myExamen?.cat.chirurgie && myExamen?.cat.chirurgie.length >0">{{myExamen?.cat.chirurgie[0].oeil}}</div>
                </div>
                <div *ngIf="tag === 'Prescrire un laser'">
                    Prescrire un laser pour
                    <div *ngIf="myExamen?.cat && myExamen?.cat.prescrireLaser && myExamen?.cat.prescrireLaser.length >0">{{myExamen?.cat.prescrireLaser[0].oeil}}</div>
                </div>
                <div *ngIf="tag != 'Prescrire une chirurgie' && tag != 'Prescrire un laser'">{{tag}}</div>
            </li>
        </ul>
    </div>
</div>