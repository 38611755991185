<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Anomalie Vasculaire" (click)="displayAndHideAnomalieVasculaire()">Anomalie vasculaire</h4>
            <div class="arrow arrow-up btnAnomalieVasculaire" *ngIf="displayAnomalieVasculaire" title="Cacher Anomalie Vasculaire" (click)="displayAndHideAnomalieVasculaire()">▲</div>
            <div class="arrow arrow-up btnAnomalieVasculaire" *ngIf="!displayAnomalieVasculaire" title="Afficher Anomalie Vasculaire" (click)="displayAndHideAnomalieVasculaire()">▼</div>
        </div>

        <div *ngIf="displayAnomalieVasculaire" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielDroit.normale.present==true}" (click)="addRemoveFromanomaliVasculRight('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielDroit.arteriosclerose.present==true}" (click)="addRemoveFromanomaliVasculRight('Artériosclérose')">
                                    Artériosclérose
                                </li>
                                <div *ngIf="showMoreAnomalieVasculaire">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielDroit.signedeRH.present==true}" (click)="addRemoveFromanomaliVasculRight('Signe de rétinopathie hypertensive')">
                                        Signe de rétinopathie hypertensive
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.present==true}" (click)="addRemoveFromanomaliVasculRight('Signe de rétinopathie diabétique')">
                                        Signe de rétinopathie diabétique
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.present==true}" (click)="addRemoveFromanomaliVasculRight('Signe d’Occlusion Veineuse')">
                                    Signe d’Occlusion Veineuse
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.present==true}" (click)="addRemoveFromanomaliVasculRight('Signe d’Occlusion Artérielle')">
                                    Signe d’Occlusion Artérielle
                                </li>
                                <div *ngIf="showMoreAnomalieVasculaire">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielDroit.vasculite.present==true}" (click)="addRemoveFromanomaliVasculRight('Vasculite')">
                                        Vasculite
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorAnomalie" [ngClass]="{'selectedLi': checkExistAnomalieRight(anomaly.id)}">
                                        <span (click)="addRemoveFromAnomalieRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-anomalieVasculaire')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieAnomalie" (keyup.enter)="addNewAnomalyAnomalie($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreAnomalieVasculaireSection() *ngIf="!showMoreAnomalieVasculaire">Voir plus</span>
                            <span (click)=showMoreAnomalieVasculaireSection() *ngIf="showMoreAnomalieVasculaire">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielGauche.normale.present==true}" (click)="addRemoveFromanomaliVasculLeft('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielGauche.arteriosclerose.present==true}" (click)="addRemoveFromanomaliVasculLeft('Artériosclérose')">
                                    Artériosclérose
                                </li>
                                <div *ngIf="showMoreAnomalieVasculaire">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielGauche.signedeRH.present==true}" (click)="addRemoveFromanomaliVasculLeft('Signe de rétinopathie hypertensive')">
                                        Signe de rétinopathie hypertensive
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.present==true}" (click)="addRemoveFromanomaliVasculLeft('Signe de rétinopathie diabétique')">
                                        Signe de rétinopathie diabétique
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.present==true}" (click)="addRemoveFromanomaliVasculLeft('Signe d’Occlusion Veineuse')">
                                    Signe d’Occlusion Veineuse
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.present==true}" (click)="addRemoveFromanomaliVasculLeft('Signe d’Occlusion Artérielle')">
                                    Signe d’Occlusion Artérielle
                                </li>
                                <div *ngIf="showMoreAnomalieVasculaire">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.anomalieVasculaire.oielGauche.vasculite.present==true}" (click)="addRemoveFromanomaliVasculLeft('Vasculite')">
                                        Vasculite
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorAnomalie" [ngClass]="{'selectedLi': checkExistAnomalieLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromAnomalieLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-anomalieVasculaire')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieAnomalie" (keyup.enter)="addNewAnomalyAnomalie($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreAnomalieVasculaireSection() *ngIf="!showMoreAnomalieVasculaire">Voir plus</span>
                            <span (click)=showMoreAnomalieVasculaireSection() *ngIf="showMoreAnomalieVasculaire">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielDroit.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielDroit.arteriosclerose.present==true">
                        <h5>Artériosclérose</h5>
                        <div class="row ">
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.arteriosclerose.valeur=='STADE I : Signe du croisement artério-veineux,'" (change)="selectarterioscleroseRight('STADE I : Signe du croisement artério-veineux,')" />
                                    <span>STADE I : Signe du croisement artério-veineux,
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.arteriosclerose.valeur=='STADE II : Signe du croisement artério-veineux marqué, associé en regard à un rétrécissement artériolaire localisé'" (change)="selectarterioscleroseRight('STADE II : Signe du croisement artério-veineux marqué, associé en regard à un rétrécissement artériolaire localisé')" />
                                    <span>STADE II : Signe du croisement artério-veineux marqué, associé en regard à un rétrécissement artériolaire localisé
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.arteriosclerose.valeur=='STADE III : En plus des modifications du stade II, présence d’engainements vasculaires ou d’occlusion de branche veineuse au niveau d’un croisement artério-veineux.'" (change)="selectarterioscleroseRight('STADE III : En plus des modifications du stade II, présence d’engainements vasculaires ou d’occlusion de branche veineuse au niveau d’un croisement artério-veineux.')" />
                                    <span>
                                        STADE III : En plus des modifications du stade II, présence d’engainements vasculaires ou d’occlusion de branche veineuse au niveau d’un croisement artério-veineux.
                                    </span>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.arteriosclerose.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRH.present==true">
                        <h5>Signe de rétinopathie hypertensive</h5>
                        <div class="row">
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRH.valeur=='STADE I : Rétrécissement artériel sévère etdisséminé'" (change)="selectsignedeRHRight('STADE I : Rétrécissement artériel sévère etdisséminé')" />
                                    <span>STADE I : Rétrécissement artériel sévère etdisséminé
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRH.valeur=='STADE II : En plus des modifications du stade I, présence d’hémorragies rétiniennes, d’exsudats secs et de nodules cotonneux,'" (change)="selectsignedeRHRight('STADE II : En plus des modifications du stade I, présence d’hémorragies rétiniennes, d’exsudats secs et de nodules cotonneux,')" />
                                    <span>STADE II : En plus des modifications du stade I, présence d’hémorragies rétiniennes, d’exsudats secs et de nodules cotonneux,
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRH.valeur=='STADE III : En plus des modifications du stade II, présence d’un oedème papillaire.'" (change)="selectsignedeRHRight('STADE III : En plus des modifications du stade II, présence d’un oedème papillaire.')" />
                                    <span>
                                        STADE III : En plus des modifications du stade II, présence d’un oedème papillaire.
                                    </span>
                                </div>
                            </div>

                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRH.description">
                            </div>

                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.present==true">
                        <h5>Signe de rétinopathie diabétique</h5>
                        <div class="row ">
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.valeur=='RD non proliférante minime (quelques microanévrismes ou hémorragies punctiformes)'" (change)="selectsignedeRDRight('RD non proliférante minime (quelques microanévrismes ou hémorragies punctiformes)')" />
                                    <span>RD non proliférante minime (quelques microanévrismes ou hémorragies punctiformes)
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.valeur=='RD non proliférante modérée (entre minime et severe)'" (change)="selectsignedeRDRight('RD non proliférante modérée (entre minime et severe)')" />
                                    <span>RD non proliférante modérée (entre minime et severe)
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.valeur=='RD non proliférante sévère (ou RD préproliférante) : Hémorragies rétiniennes dans les 4 quadrants du fond d’œil et/ou dilatations veineuses dans 2 quadrants et/ou AMIR dans 1 quadrant'" (change)="selectsignedeRDRight('RD non proliférante sévère (ou RD préproliférante) : Hémorragies rétiniennes dans les 4 quadrants du fond d’œil et/ou dilatations veineuses dans 2 quadrants et/ou AMIR dans 1 quadrant')" />
                                    <span>
                                        RD non proliférante sévère (ou RD préproliférante) : Hémorragies rétiniennes dans les 4 quadrants du fond d’œil et/ou dilatations veineuses dans 2 quadrants et/ou AMIR dans 1 quadrant
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.valeur=='RD proliférante'" (change)="selectsignedeRDRight('RD proliférante')" />
                                    <span>
                                        RD proliférante
                                    </span>
                                    <br>
                                    <span class="m-l-10">
                                        RD compliquée
                                    </span>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.RDcompliquee=='Oui'" (change)="selectsignedeRDCompliqueRight('Oui')" />
                                    <span>
                                        Oui
                                    </span>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.RDcompliquee=='Non'" (change)="selectsignedeRDCompliqueRight('Non')" />
                                    <span>
                                        Non
                                    </span>
                                </div>
                            </div>
                            <label id="signeRD">Type</label>
                            <div id="checkboxesRD">
                                <div class="checkBoxRD">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.type=='Hémorragie intravitréenne'" (change)="selectsignedeRDTypeRight('Hémorragie intravitréenne')" /><label class="labelRd">
                                        Hémorragie intravitréenne</label>
                                </div>
                                <div class="checkBoxRD">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.type=='Décollement de rétine'" (change)="selectsignedeRDTypeRight('Décollement de rétine')" /><label class="labelRd">Décollement de rétine
                                        tractionnel</label>
                                </div>
                                <div class="checkBoxRD">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.type=='Glaucome néovasculaire'" (change)="selectsignedeRDTypeRight('Glaucome néovasculaire')" /><label class="labelRd">Glaucome néovasculaire
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedeRD.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.present==true">
                        <h5>Signe d’Occlusion Veineuse</h5>
                        <div class="row">
                            <div class="col-md-6">
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.valeur=='OVCR'" (change)="selectsignedOcclusionVeineuseRight('OVCR')" />
                                <label for="roundedCheckbox10">OVCR </label>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.valeur=='OBVR'" (change)="selectsignedOcclusionVeineuseRight('OBVR')" />
                                    <label for="roundedCheckbox10">OBVR </label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.typeOBVR">
                                        <option value="Nasal supérieure">Nasal supérieure</option>
                                        <option value="Nasal inférieure">Nasal inférieure</option>
                                        <option value="Temporal supérieure">Temporal supérieure</option>
                                        <option value="Temporal inférieure">Temporal inférieure</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 ">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.valeur=='Occlusion veineuse hemirétinienne'" (change)="selectsignedOcclusionVeineuseRight('Occlusion veineuse hemirétinienne')" />
                                    <label for="roundedCheckbox12">Occlusion veineuse hemirétinienne
                                    </label>
                                </div>
                                <div class="col-lg-6 col-md-6  margin-t-10">
                                    <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.typeOcclusionveineusehemiretinienne">
                                        <option value="Inférieure">Inférieure</option>
                                        <option value="Supérieure">Supérieure</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <label>Description</label>
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.description">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.present==true">
                        <h5>Signe d’Occlusion Artérielle</h5>
                        <div class="row">
                            <div class="col-md-6">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.valeur=='OACR isolé'" (change)="selectsignedOcclusionARTERIELLERight('OACR isolé')" />
                                    <label for="roundedCheckbox14">OACR isolé</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.valeur=='Occlusion de branche artérielle'" (change)="selectsignedOcclusionARTERIELLERight('Occlusion de branche artérielle')" />
                                    <label for="roundedCheckbox10">Occlusion de branche artérielle</label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.typeOBAR">
                                        <option value="Nasal supérieure">Nasal supérieure</option>
                                        <option value="Nasal inférieure">Nasal inférieure</option>
                                        <option value="Temporal supérieure">Temporal supérieure</option>
                                        <option value="Temporal inférieure">Temporal inférieure</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div>
                                        <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.valeur=='Occlusion artérielle hemirétinienne'" (change)="selectsignedOcclusionARTERIELLERight('Occlusion artérielle hemirétinienne')" />
                                        <label for="roundedCheckbox13">Occlusion artérielle hemirétinienne
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <select class="form-control input-sm-new margin-t-10" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.typeOcclusionarteriellehemiretinienne">
                                        <option value="Inférieure">Inférieure</option>
                                        <option value="Supérieure">Supérieure</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.description">
                            </div>

                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielDroit.vasculite.present==true">
                        <h5>Vasculite</h5>
                        <div class="row">

                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.vasculite.type">
                                    <option value="Artérite">Artérite</option>
                                    <option value="Anévrysme rétinien">Anévrysme rétinien</option>
                                    <option value="Néovaisseaux rétiniens">Néovaisseaux rétiniens</option>
                                    <option value="Périphlébite">Périphlébite</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielDroit.vasculite.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.anomalieVasculaire.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielGauche.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielGauche.arteriosclerose.present==true">
                        <h5>Artériosclérose</h5>
                        <div class="row ">
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.arteriosclerose.valeur=='STADE I : Signe du croisement artério-veineux,'" (change)="selectarterioscleroseLeft('STADE I : Signe du croisement artério-veineux,')" />
                                    <span>STADE I : Signe du croisement artério-veineux,
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.arteriosclerose.valeur=='STADE II : Signe du croisement artério-veineux marqué, associé en regard à un rétrécissement artériolaire localisé'" (change)="selectarterioscleroseLeft('STADE II : Signe du croisement artério-veineux marqué, associé en regard à un rétrécissement artériolaire localisé')" />
                                    <span>STADE II : Signe du croisement artério-veineux marqué, associé en regard à un rétrécissement artériolaire localisé
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.arteriosclerose.valeur=='STADE III : En plus des modifications du stade II, présence d’engainements vasculaires ou d’occlusion de branche veineuse au niveau d’un croisement artério-veineux.'" (change)="selectarterioscleroseLeft('STADE III : En plus des modifications du stade II, présence d’engainements vasculaires ou d’occlusion de branche veineuse au niveau d’un croisement artério-veineux.')" />
                                    <span>
                                        STADE III : En plus des modifications du stade II, présence d’engainements vasculaires ou d’occlusion de branche veineuse au niveau d’un croisement artério-veineux.
                                    </span>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.arteriosclerose.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRH.present==true">
                        <h5>Signe de rétinopathie hypertensive</h5>
                        <div class="row">
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRH.valeur=='STADE I : Rétrécissement artériel sévère etdisséminé'" (change)="selectsignedeRHLeft('STADE I : Rétrécissement artériel sévère etdisséminé')" />
                                    <span>STADE I : Rétrécissement artériel sévère etdisséminé
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRH.valeur=='STADE II : En plus des modifications du stade I, présence d’hémorragies rétiniennes, d’exsudats secs et de nodules cotonneux,'" (change)="selectsignedeRHLeft('STADE II : En plus des modifications du stade I, présence d’hémorragies rétiniennes, d’exsudats secs et de nodules cotonneux,')" />
                                    <span>STADE II : En plus des modifications du stade I, présence d’hémorragies rétiniennes, d’exsudats secs et de nodules cotonneux,
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRH.valeur=='STADE III : En plus des modifications du stade II, présence d’un oedème papillaire.'" (change)="selectsignedeRHLeft('STADE III : En plus des modifications du stade II, présence d’un oedème papillaire.')" />
                                    <span>
                                        STADE III : En plus des modifications du stade II, présence d’un oedème papillaire.
                                    </span>
                                </div>
                            </div>

                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRH.description">
                            </div>

                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.present==true">
                        <h5>Signe de rétinopathie diabétique</h5>
                        <div class="row ">
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.valeur=='RD non proliférante minime (quelques microanévrismes ou hémorragies punctiformes)'" (change)="selectsignedeRDLeft('RD non proliférante minime (quelques microanévrismes ou hémorragies punctiformes)')" />
                                    <span>RD non proliférante minime (quelques microanévrismes ou hémorragies punctiformes)
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.valeur=='RD non proliférante modérée (entre minime et severe)'" (change)="selectsignedeRDLeft('RD non proliférante modérée (entre minime et severe)')" />
                                    <span>RD non proliférante modérée (entre minime et severe)
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.valeur=='RD non proliférante sévère (ou RD préproliférante) : Hémorragies rétiniennes dans les 4 quadrants du fond d’œil et/ou dilatations veineuses dans 2 quadrants et/ou AMIR dans 1 quadrant'" (change)="selectsignedeRDLeft('RD non proliférante sévère (ou RD préproliférante) : Hémorragies rétiniennes dans les 4 quadrants du fond d’œil et/ou dilatations veineuses dans 2 quadrants et/ou AMIR dans 1 quadrant')" />
                                    <span>
                                        RD non proliférante sévère (ou RD préproliférante) : Hémorragies rétiniennes dans les 4 quadrants du fond d’œil et/ou dilatations veineuses dans 2 quadrants et/ou AMIR dans 1 quadrant
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.valeur=='RD proliférante'" (change)="selectsignedeRDLeft('RD proliférante')" />
                                    <span>
                                        RD proliférante
                                    </span>
                                    <br>
                                    <span class="m-l-10">
                                        RD compliquée
                                    </span>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.RDcompliquee=='Oui'" (change)="selectsignedeRDCompliqueLeft('Oui')" />
                                    <span>
                                        Oui
                                    </span>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.RDcompliquee=='Non'" (change)="selectsignedeRDCompliqueLeft('Non')" />
                                    <span>
                                        Non
                                    </span>
                                </div>
                            </div>
                            <label id="signeRD">Type</label>
                            <div id="checkboxesRD">
                                <div class="checkBoxRD">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.type=='Hémorragie intravitréenne'" (change)="selectsignedeRDTypeLeft('Hémorragie intravitréenne')" /><label class="labelRd">
                                        Hémorragie intravitréenne</label>
                                </div>
                                <div class="checkBoxRD">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.type=='Décollement de rétine'" (change)="selectsignedeRDTypeLeft('Décollement de rétine')" /><label class="labelRd">Décollement de rétine
                                        tractionnel</label>
                                </div>
                                <div class="checkBoxRD">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.type=='Glaucome néovasculaire'" (change)="selectsignedeRDTypeLeft('Glaucome néovasculaire')" /><label class="labelRd">Glaucome néovasculaire
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedeRD.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.present==true">
                        <h5>Signe d’Occlusion Veineuse</h5>
                        <div class="row">
                            <div class="col-md-6">
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.valeur=='OVCR'" (change)="selectsignedOcclusionVeineuseLeft('OVCR')" />
                                <label for="roundedCheckbox10">OVCR </label>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.valeur=='OBVR'" (change)="selectsignedOcclusionVeineuseLeft('OBVR')" />
                                    <label for="roundedCheckbox10">OBVR </label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.typeOBVR">
                                        <option value="Nasal supérieure">Nasal supérieure</option>
                                        <option value="Nasal inférieure">Nasal inférieure</option>
                                        <option value="Temporal supérieure">Temporal supérieure</option>
                                        <option value="Temporal inférieure">Temporal inférieure</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 ">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.valeur=='Occlusion veineuse hemirétinienne'" (change)="selectsignedOcclusionVeineuseLeft('Occlusion veineuse hemirétinienne')" />
                                    <label for="roundedCheckbox12">Occlusion veineuse hemirétinienne
                                    </label>
                                </div>
                                <div class="col-lg-6 col-md-6  margin-t-10">
                                    <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.typeOcclusionveineusehemiretinienne">
                                        <option value="Inférieure">Inférieure</option>
                                        <option value="Supérieure">Supérieure</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <label>Description</label>
                                    <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.description">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.present==true">
                        <h5>Signe d’Occlusion Artérielle</h5>
                        <div class="row">
                            <div class="col-md-6">
                                <div>
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.valeur=='OACR isolé'" (change)="selectsignedOcclusionARTERIELLELeft('OACR isolé')" />
                                    <label for="roundedCheckbox14">OACR isolé</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.valeur=='Occlusion de branche artérielle'" (change)="selectsignedOcclusionARTERIELLELeft('Occlusion de branche artérielle')" />
                                    <label for="roundedCheckbox10">Occlusion de branche artérielle</label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.typeOBAR">
                                        <option value="Nasal supérieure">Nasal supérieure</option>
                                        <option value="Nasal inférieure">Nasal inférieure</option>
                                        <option value="Temporal supérieure">Temporal supérieure</option>
                                        <option value="Temporal inférieure">Temporal inférieure</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div>
                                        <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.valeur=='Occlusion artérielle hemirétinienne'" (change)="selectsignedOcclusionARTERIELLELeft('Occlusion artérielle hemirétinienne')" />
                                        <label for="roundedCheckbox13">Occlusion artérielle hemirétinienne
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <select class="form-control input-sm-new margin-t-10" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.typeOcclusionarteriellehemiretinienne">
                                        <option value="Inférieure">Inférieure</option>
                                        <option value="Supérieure">Supérieure</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.description">
                            </div>

                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.anomalieVasculaire.oielGauche.vasculite.present==true">
                        <h5>Vasculite</h5>
                        <div class="row">

                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.vasculite.type">
                                    <option value="Artérite">Artérite</option>
                                    <option value="Anévrysme rétinien">Anévrysme rétinien</option>
                                    <option value="Néovaisseaux rétiniens">Néovaisseaux rétiniens</option>
                                    <option value="Périphlébite">Périphlébite</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.anomalieVasculaire.oielGauche.vasculite.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.anomalieVasculaire.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>

                </div>

            </div>
        </div>
    </div>