<!-----------      LAF       ----->
<div class="col-md-12 " *ngIf="examLaf">

  <div>

    <div *ngIf="!LAFanomalieAnterieur">
      <h3>Segment antérieur</h3>
      <div class="arrow1  btnSegementAnt" title="Afficher segment antérieur" (click)="displayAndHideSegementAnt()">▼</div>
    </div>
    <div *ngIf="LAFanomalieAnterieur">
      <h3>Segment antérieur (par défaut normal) </h3>
      <div class="arrow1  btnSegementAntAfter" title="Cacher segment antérieur" (click)="displayAndHideSegementAnt()">▲</div>
    </div>

    <div *ngIf="LAFanomalieAnterieur">
      <br>
      <!-- sclere  -->
      <app-sclere></app-sclere>
      <!-- sclere  -->
      <br>
      <!-- CONJ-->
      <app-conj></app-conj>
      <!-- CONJ-->
      <br>
      <!-- start CORNEE-->
      <app-cornee></app-cornee>
      <!-- end CORNEE-->
      <br>
      <!--   start Chambre antérieure : Calme et profonde -->
      <app-chambre-anterieure></app-chambre-anterieure>
      <!--   end Chambre antérieure : Calme et profonde -->
      <br>
      <!--  IRIS : Normale -->
      <app-iris></app-iris>
      <!-- end IRis :Normale -->
      <br>
      <!-----start RPM -->
      <app-rpm></app-rpm>
      <!-----end RPM -->
      <br>
      <!-- start Dilatation -->
      <app-dilatation></app-dilatation>
      <!-- end Dilatation -->
      <br>
      <!--  start Cristallin : Transparent -->
      <app-cristallin></app-cristallin>
      <!--  end Cristallin : Transparent -->
      <div class="text-center mt-2" *ngIf="LAFanomalieAnterieur">
        <h6 class="showmore" title="Cacher segment antérieur" (click)="displayAndHideSegementAnt()">Cacher segment antérieur ▲</h6>
      </div>
    </div>
    <!-- END Anomalie à l'examen à lampe à fente -->
    <br>
    <!-- BUT SHIMER-->
    <h3>Pachymétrie/ BUT / SHIRMER / Tonus oculaire</h3>
    <div class="arrow arrow-up btnButShirmer" *ngIf="displayButShirmer" title="Cacher Pachymétrie/ BUT / SHIRMER / Tonus oculaire" (click)="showHideshowButShirmer()">▲</div>
    <div class="arrow arrow-up btnButShirmer" *ngIf="!displayButShirmer" title="Afficher Pachymétrie/ BUT / SHIRMER / Tonus oculaire" (click)="showHideshowButShirmer()">▼</div>
    <div *ngIf="displayButShirmer" @fade>
      <div class="card mt-3">
        <div class="card-body">
          <div class="col-md-12">
            <!-- Pachymétrie -->
            <div class="row">
              <div class="col-md-2">
                <label for="">Pachymétrie</label>
              </div>
              <div class="col-md-3">
                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.pachymtrie.oielDroit.value" (change)="onchangePachymetrieOD()">
              </div>
              <div class="col-md-1">
                <label for="">μm</label>
              </div>
              <div class="col-md-3">
                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.pachymtrie.oielGauche.value" (change)="onchangePachymetrieOG()">
              </div>
              <div class="col-md-1">
                <label for="">μm</label>
              </div>
            </div>
            <!-- But -->
            <div class="row mt-3">
              <div class="col-md-2">
                <label for="">BUT</label>
              </div>
              <div class="col-md-3">
                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.butShirmer.oielDroit.secondes" (change)="onChangebutODSeconde()">
              </div>
              <div class="col-md-1">
                <label for="">s</label>
              </div>
              <div class="col-md-3">
                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.butShirmer.oielGauche.secondes" (change)="onChangebutOGSeconde()">
              </div>
              <div class="col-md-1">
                <label for="">s</label>
              </div>
            </div>
            <!--SHIRMER  -->
            <div class="row mt-3">
              <div class="col-md-2">
                <label for="">SHIRMER</label>
              </div>
              <div class="col-md-3">
                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.butShirmer.oielDroit.mm" (change)="onChangebutODmm()">
              </div>
              <div class="col-md-1">
                <label for="">mm</label>
              </div>
              <div class="col-md-3">
                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.butShirmer.oielGauche.mm" (change)="onChangebutOGmm()">
              </div>
              <div class="col-md-1">
                <label for="">mm</label>
              </div>
            </div>
            <!-- Tonus oculaire -->
            <div>
              <div class="row mt-3">
                <div class="col-md-2">
                  <label for="">Tonus oculaire</label>
                </div>
                <div class="col-md-3">
                  <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.toAic.oielDroit.mm" (change)="onChangetoODmm()">
                </div>
                <div class="col-md-1">
                  <label for="">mmHg</label>
                </div>
                <div class="col-md-3">
                  <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.toAic.oielGauche.mm" (change)="onChangetoOGmm()">
                </div>
                <div class="col-md-1">
                  <label for="">mmHg</label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="text-center mt-2" *ngIf="displayButShirmer">
        <h6 class="showmore" title="Cacher Pachymétrie/ BUT / SHIRMER / Tonus oculaire" (click)="showHideshowButShirmer()">Cacher Pachymétrie/ BUT / SHIRMER / Tonus oculaire ▲</h6>
      </div>
    </div>



    <!-- END TO -->
    <br>
    <!--start ANGLE IRIDO CORNÉEN -->

    <!--end angle irido cornée-->
    <app-angle-irido></app-angle-irido>
    <!--end angle irido cornée-->
    <br>
    <!-- SEGEMENT POSTÉRIEUR -->
    <div *ngIf="segmentPosterieur">
      <h3>Segment postérieur (par défaut normal, compléter le rapport C/D)</h3>
      <div class="arrow1  btnSegementPostAfter" title="Cacher segment postérieur" (click)="displayAndHideSegementPost()">▲</div>
    </div>
    <div *ngIf="!segmentPosterieur">
      <h3>Segment postérieur</h3>
      <div class="arrow1  btnSegementPost" title="Afficher segment postérieur" (click)="displayAndHideSegementPost()">▼</div>
    </div>

    <div *ngIf="segmentPosterieur">
      <!-- Vitres-->
      <app-vitre></app-vitre>
      <!-- end Vitres-->
      <br>
      <!-- Papille -->
      <app-papille></app-papille>
      <!-- end papille-->
      <br>
      <!----------- macula secition-------------------------------------- -->
      <app-macula></app-macula>
      <!-- end of macula section -------------------- -->
      <br>
      <!-- anomalie vasculaire---------------------- -->
      <app-anomalie-vasculaire></app-anomalie-vasculaire>
      <!-- ------end on anomalie vascualaire -->
      <br>
      <!-- debut retine------------------- -->
      <app-retine></app-retine>
      <!-- end retine ------------------ -->
      <div class="text-center mt-2" *ngIf="segmentPosterieur">
        <h6 class="showmore" title="Cacher segment postérieur" (click)="displayAndHideSegementPost()">Cacher segment postérieur ▲</h6>
      </div>

    </div>
    <!-- END  SEGEMENT POSTÉRIEUR -->
  </div>

</div>
<!-----------    End  LAF       ----->