<!-----------      Annexe       ----->
<div class="col-md-12" *ngIf="examAnexe">

  <!-- historique -->
  <div class="card">
    <div class="card-body-historique">
      <h3 class="card-title-historique">Historique annexe</h3>
      <div class="arrow arrowBlack btnhistoriquesAnnexe mb-3" *ngIf="displayHistorique" title="Cacher la liste des historiques" (click)="displayAndHideHistorique()">▲</div>
      <div class="arrow arrowBlack btnhistoriquesAnnexe mb-3" *ngIf="!displayHistorique" title="Afficher la liste des historiques" (click)="displayAndHideHistorique()">▼</div>
      <div class="menu1" *ngIf="displayHistorique">
        <div *ngIf="oldListConsultations.length==0">
          <h6 class="badge-info-dates mt-2">Aucun historique n'est trouvé</h6>
        </div>
        <div class="pagination-container" *ngIf="oldListConsultations.length>0">
          <button class="pagination-arrow" (click)="previousPage()" [disabled]="p === 1">&#8249;</button>
          <ul class="date-list">
            <li class="badge-info-dates" (click)=" openCons(paginatedList[0])" title="Voir détaille de la consultation  {{  paginatedList[0]?.start | date:'dd/MM/yyyy' }}">
              {{ paginatedList[0]?.start | date:'dd/MM/yyyy' }}
            </li>
          </ul>
          <button class="pagination-arrow" (click)="nextPage()" [disabled]="p === oldListConsultations.length">&#8250;</button>
        </div>
        <div class="info mb-3" *ngIf="selectedHistorique">
          <div class="card1">
            <div class="card-body-historique">
              <!-- <h5 class="card-title text-center cursor-pointer mb-3" title="Voir détaille de la consultation  {{ selectedHistorique.start | date:'dd/MM/yyyy' }}" (click)=" openCons(selectedHistorique)">{{selectedHistorique.start | date:'dd/MM/yyyy'}}</h5> -->
              <div *ngIf="selectedHistorique.annexe && selectedHistorique.annexe.anomaly && selectedHistorique.annexe.anomaly.present" class="mb-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="cadreOeilDroitHistory">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                      </div>
                      <ul id="suboeilHistorySection" *ngIf="selectedHistorique.annexe && selectedHistorique.annexe.anomaly && selectedHistorique.annexe.anomaly.present">
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.normale.present">Normale</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.chalazion.present">Chalazion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.dca.present">DCA</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.dcc.present">DCC</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.dermabrasion.present">Dermabrasion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.diplopie.present">Diplopie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.distichoasis.present">Distichoasis</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.dgm.present">DGM</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.ecoulementlacrymal.present">Écoulement lacrymal</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.entropion.present">Entropion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.ectropion.present">Ectropion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.fistule.present">Fistule</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.insuffisancedeconvergence.present">Insuffisance de convergence</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.massedelalogelacrymal.present">Masse de la loge lacrymal</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.meeibomite.present">Meeibomite</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.ophtalmologie.present">Ophtalmologie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.orgelet.present">Orgelet</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.paralysieocculomotrice.present">Paralysie occulomotrice</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.plaie.present">Plaie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.presencedestrabisme.present">Présence de strabisme</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.ptosis.present">Ptosis</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.refletcorneendecentre.present">Réflet cornéen décentré</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.stenoselacrymal.present">Sténose lacrymal</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.sondedeDCRenplace.present">Sonde de DCR en place</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.telangiectasie.present">Telangiectasie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielDroit.tumeurpaplepbrale.present">Tumeur paplepbrale</li>
                        <li class="badge badge-info-anomalies mb-1" *ngFor="let anomaly of selectedHistorique.annexe.anomaly.oielDroit.allAnomalies">{{anomaly.titre}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="cadreOeilGaucheHistory">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                      </div>
                      <ul id="suboeilHistorySection" *ngIf="selectedHistorique.annexe && selectedHistorique.annexe.anomaly && selectedHistorique.annexe.anomaly.present">
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.normale.present">Normale</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.chalazion.present">Chalazion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.dca.present">DCA</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.dcc.present">DCC</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.dermabrasion.present">Dermabrasion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.diplopie.present">Diplopie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.distichoasis.present">Distichoasis</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.dgm.present">DGM</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.ecoulementlacrymal.present">Écoulement lacrymal</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.entropion.present">Entropion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.ectropion.present">Ectropion</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.fistule.present">Fistule</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.insuffisancedeconvergence.present">Insuffisance de convergence</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.massedelalogelacrymal.present">Masse de la loge lacrymal</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.meeibomite.present">Meeibomite</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.ophtalmologie.present">Ophtalmologie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.orgelet.present">Orgelet</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.paralysieocculomotrice.present">Paralysie occulomotrice</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.plaie.present">Plaie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.presencedestrabisme.present">Présence de strabisme</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.ptosis.present">Ptosis</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.refletcorneendecentre.present">Réflet cornéen décentré</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.stenoselacrymal.present">Sténose lacrymal</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.sondedeDCRenplace.present">Sonde de DCR en place</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.telangiectasie.present">Telangiectasie</li>
                        <li class="badge badge-info-anomalies mb-1" *ngIf="selectedHistorique.annexe.anomaly.oielGauche.tumeurpaplepbrale.present">Tumeur paplepbrale</li>
                        <li class="badge badge-info-anomalies mb-1" *ngFor="let anomaly of selectedHistorique.annexe.anomaly.oielGauche.allAnomalies">{{anomaly.titre}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of historique -->
  <br>
  <div class="card">
    <div class="card-body">
      <div *ngIf="todayExamen">
        <h3 class="card-title-historique">Examen d'aujourd'hui (par défaut normal)</h3>
        <div class="arrow arrowBlack btnhistoriquesRefraction-open mb-3" *ngIf="todayExamen" title="Cacher examen d'aujourd'hui" (click)="displayAndHidetodayExamen()">▲</div>
      </div>
      <div *ngIf="!todayExamen">
        <h3 class="card-title-historique">Examen d'aujourd'hui</h3>
        <div class="arrow arrowBlack btnhistoriquesRefraction mb-3" *ngIf="!todayExamen" title="Afficher examen d'aujourd'hui" (click)="displayAndHidetodayExamen()">▼</div>
      </div>
      <br />
      <div *ngIf="todayExamen">
        <!-- <h3 class="card-title-historique">Annexe</h3> -->
        <div class="col-12 sansCyclo">
          <div class="cadreOeilDroit2">
            <div class="oeilTitreSection">
              <img src="assets/images/oeil.png" style="position: relative;top: 1px;" alt="" class="img-oeil">
              <h5 id="OeilTitle" style="cursor: pointer;" title="Basculer vers l'oeil droit">Oeil droit</h5>
            </div>
            <div class="row" style="margin-top: 8px;">
              <br>
              <div class="col-md-6">
                <ul>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.normale.present==true}" (click)="addRemoveFromAnnexeExamenRight('Normale') ">Normale</li>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.chalazion.present==true}" (click)="addRemoveFromAnnexeExamenRight('Chalazion')">Chalazion</li>
                  <div *ngIf="showMore">
                    <li [ngClass]="{'selectedLi': examAnexe.annexe.anomaly.oielDroit.dca.present==true}" (click)="addRemoveFromAnnexeExamenRight('DCA')">DCA</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.dcc.present==true}" (click)="addRemoveFromAnnexeExamenRight('DCC')">DCC</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.dermabrasion.present==true}" (click)="addRemoveFromAnnexeExamenRight('Dermabrasion')">Dermabrasion</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.dgm.present==true}" (click)="addRemoveFromAnnexeExamenRight('DGM')">DGM</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.diplopie.present==true}" (click)="addRemoveFromAnnexeExamenRight('Diplopie')">Diplopie</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.distichoasis.present==true}" (click)="addRemoveFromAnnexeExamenRight('Distichoasis')">Distichoasis</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.ecoulementlacrymal.present==true}" (click)="addRemoveFromAnnexeExamenRight('Écoulement lacrymal')">Écoulement lacrymal</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.entropion.present==true}" (click)="addRemoveFromAnnexeExamenRight('Entropion')">Entropion</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.ectropion.present==true}" (click)="addRemoveFromAnnexeExamenRight('Ectropion')">Ectropion</li>
                    <li [ngClass]="{'selectedLi': examAnexe.annexe.anomaly.oielDroit.fistule.present==true}" (click)="addRemoveFromAnnexeExamenRight('Fistule')">Fistule</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.insuffisancedeconvergence.present==true}" (click)="addRemoveFromAnnexeExamenRight('Insuffisance de convergence')">Insuffisance de convergence
                    </li>
                  </div>
                </ul>
              </div>
              <div class="col-md-6">
                <ul>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.present==true}" (click)="addRemoveFromAnnexeExamenRight('Masse de la loge lacrymal')">Masse de la loge lacrymal</li>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.meeibomite.present==true}" (click)="addRemoveFromAnnexeExamenRight('Meeibomite')">Meeibomite</li>
                  <div *ngIf="showMore">
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.ophtalmologie.present==true}" (click)="addRemoveFromAnnexeExamenRight('Ophtalmologie')">Ophtalmologie</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.orgelet.present==true}" (click)="addRemoveFromAnnexeExamenRight('Orgelet')">Orgelet</li>

                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.present==true}" (click)="addRemoveFromAnnexeExamenRight('Paralysie occulomotrice') ">Paralysie occulomotrice</li>
                    <li [ngClass]="{'selectedLi':examAnexe.annexe.anomaly.oielDroit.plaie.present==true}" (click)="addRemoveFromAnnexeExamenRight('Plaie')">Plaie</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.present==true}" (click)="addRemoveFromAnnexeExamenRight('Présence de strabisme')">Présence de strabisme</li>
                    <li [ngClass]="{'selectedLi': examAnexe.annexe.anomaly.oielDroit.ptosis.present==true}" (click)="addRemoveFromAnnexeExamenRight('Ptosis')">Ptosis</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.refletcorneendecentre.present==true}" (click)="addRemoveFromAnnexeExamenRight('Réflet cornéen décentré')">Réflet cornéen décentré</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.stenoselacrymal.present==true}" (click)="addRemoveFromAnnexeExamenRight('Sténose lacrymal')">Sténose lacrymal</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.sondedeDCRenplace.present==true}" (click)="addRemoveFromAnnexeExamenRight('Sonde de DCR en place')">Sonde de DCR en place</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielDroit.telangiectasie.present==true}" (click)="addRemoveFromAnnexeExamenRight('Telangiectasie')">Telangiectasie</li>
                    <li [ngClass]="{'selectedLi': examAnexe.annexe.anomaly.oielDroit.tumeurpaplepbrale.present==true}" (click)="addRemoveFromAnnexeExamenRight('Tumeur paplepbrale')">Tumeur paplepbrale</li>
                    <!-- dynamic anamaly -->
                    <li *ngFor="let anomaly of anomaliesByDoctorAnnexe" [ngClass]="{'selectedLi': checkExistAnmalieAnnexeRight(anomaly.id)}">
                      <span (click)="addRemoveFromAnnexeExamenRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                      <img src="assets/images/delete.png" *ngIf="!checkExistAnmalieAnnexeRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'annexe')" title="Supprimer l'anomalie" class="del-anomalie">
                    </li>
                    <div>
                      <input type="text" placeholder="Ajouter une autre anomalie" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieAnnexe" (keyup.enter)="addNewAnomalyAnnexe($event)" [disabled]="isToastVisible">
                    </div>
                    <!-- dynamic anamaly -->
                  </div>
                </ul>
              </div>
              <br><br>
            </div>
            <div class="showmore">
              <span (click)=showMoreAnomalies() *ngIf="!showMore">Voir plus</span>
              <span (click)=showMoreAnomalies() *ngIf="showMore">Voir moins</span>
            </div>
          </div>
          <div class="cadreOeilGauche2">
            <div class="oeilTitreSection">
              <img src="assets/images/oeil.png" style="position: relative;top: 1px;" class="img-oeil">
              <h5 id="OeilTitle" style="cursor: pointer;" title="Basculer vers l'oeil gauche">Oeil gauche</h5><br>
            </div>
            <div class="row" style="margin-top: 8px;">
              <br>
              <div class="col-md-6">
                <ul>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.normale.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Normale') ">Normale</li>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.chalazion.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Chalazion')">Chalazion</li>
                  <div *ngIf="showMore">
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.dca.present==true}" (click)="addRemoveFromAnnexeExamenLeft('DCA')">DCA</li>
                    <li [ngClass]="{'selectedLi': examAnexe.annexe.anomaly.oielGauche.dcc.present==true}" (click)="addRemoveFromAnnexeExamenLeft('DCC')">DCC</li>

                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.dermabrasion.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Dermabrasion')">Dermabrasion</li>
                    <li [ngClass]="{'selectedLi': examAnexe.annexe.anomaly.oielGauche.dgm.present==true}" (click)="addRemoveFromAnnexeExamenLeft('DGM')">DGM</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.diplopie.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Diplopie')">Diplopie</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.distichoasis.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Distichoasis')">Distichoasis</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.ecoulementlacrymal.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Écoulement lacrymal')">Écoulement lacrymal</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.entropion.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Entropion')">Entropion</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.ectropion.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Ectropion')">Ectropion</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.fistule.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Fistule')">Fistule</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.insuffisancedeconvergence.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Insuffisance de convergence')">Insuffisance de convergence
                    </li>
                  </div>
                </ul>
              </div>
              <div class="col-md-6">
                <ul>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Masse de la loge lacrymal')">Masse de la loge lacrymal</li>
                  <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.meeibomite.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Meeibomite')">Meeibomite</li>
                  <div *ngIf="showMore">
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.ophtalmologie.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Ophtalmologie')">Ophtalmologie</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.orgelet.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Orgelet')">Orgelet</li>

                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Paralysie occulomotrice') ">Paralysie occulomotrice</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.plaie.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Plaie')">Plaie</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Présence de strabisme')">Présence de strabisme</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.ptosis.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Ptosis')">Ptosis</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.refletcorneendecentre.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Réflet cornéen décentré')">Réflet cornéen décentré</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.stenoselacrymal.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Sténose lacrymal')">Sténose lacrymal</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.sondedeDCRenplace.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Sonde de DCR en place')">Sonde de DCR en place</li>
                    <li [ngClass]="{'selectedLi': examAnexe.annexe.anomaly.oielGauche.telangiectasie.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Telangiectasie')">Telangiectasie</li>
                    <li [ngClass]="{'selectedLi':  examAnexe.annexe.anomaly.oielGauche.tumeurpaplepbrale.present==true}" (click)="addRemoveFromAnnexeExamenLeft('Tumeur paplepbrale')">Tumeur paplepbrale</li>
                    <!-- dynamic anamaly -->
                    <li *ngFor="let anomaly of anomaliesByDoctorAnnexe" [ngClass]="{'selectedLi': checkExistAnmalieAnnexeLeft(anomaly.id)}">
                      <span (click)="addRemoveFromAnnexeExamenLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                      <img src="assets/images/delete.png" *ngIf="!checkExistAnmalieAnnexeLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'annexe')" title="Supprimer l'anomalie" class="del-anomalie">
                    </li>
                    <div>
                      <input type="text" placeholder="Ajouter une autre anomalie" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieAnnexeGauche" (keyup.enter)="addNewAnomalyAnnexe($event)" [disabled]="isToastVisible">
                    </div>
                    <!-- dynamic anamaly -->
                  </div>
                </ul>
              </div>
              <br><br>
            </div>
            <div class="showmore">
              <span (click)=showMoreAnomalies() *ngIf="!showMore">Voir plus</span>
              <span (click)=showMoreAnomalies() *ngIf="showMore">Voir moins</span>
            </div>
          </div>
        </div>
        <br>
        <div class="row blocinputs" *ngIf="examAnexe.annexe.anomaly.present"><br>
          <div class="col-md-6">
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.normale.present==true">
              <div class="col-lg-4 col-md-4">
                <h5 class="titleDetails">Normale</h5>
              </div>
              <div class="col-lg-8 col-md-8">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.normale.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.chalazion.present==true">
              <h5 class="titleDetails">Chalazion</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Localisation</label>
                <input type="text" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.chalazion.localisation">
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Taille</label>
                <div class="d-flex">
                  <input type="number" class="form-control  input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.chalazion.taille" (change)="checkAnnexechalaziontailleRight()">
                  <p class="ml-2">mm</p>
                </div>
              </div>
              <div class="col-12">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.chalazion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.dca.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">DCA</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.dca.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.dcc.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">DCC</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.dcc.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.dermabrasion.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Dermabrasion</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.dermabrasion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.dgm.present==true">
              <h5 class="titleDetails">DGM</h5>
              <div class="col-12">
                <label for="titre">Grade</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.dgm.grade">
                  <option>Grade 1= Inflammation du bord libre de la paupière + Télangiectasie</option>
                  <option>Grade 2 = Inflammation du bord libre de la paupière + Kératinisation</option>
                  <option>Grade 3 = Atrophie partielle des glandes de Meibomius</option>
                  <option>Grade 4 = Atrophie totale des glandes de Meibomius</option>
                </select>
              </div>
              <div class="col-12 mt-3">
                <label for="titre">Description</label>
                <div><input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.dgm.description"></div>
              </div>
              <br>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.diplopie.present==true">
              <h5 class="titleDetails">Diplopie</h5>
              <div class="col-lg-6 col-md-12">
                <div>
                  <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.diplopie.type=='Binoculaire'" (click)="selectanexeDiploieDroit('Binoculaire')">
                  <label for="titre">Binoculaire</label>
                </div>
                <div>
                  <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.diplopie.type=='Monoculaire'" (click)="selectanexeDiploieDroit('Monoculaire')">
                  <label for="titre">Monoculaire</label>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <label>Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.diplopie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.distichoasis.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Distichoasis</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.distichoasis.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.ecoulementlacrymal.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Écoulement lacrymal</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.ecoulementlacrymal.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.entropion.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Entropion</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.entropion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.ectropion.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Ectropion</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.ectropion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.fistule.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Fistule</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.fistule.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.insuffisancedeconvergence.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Insuffisance de convergence</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.insuffisancedeconvergence.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.present==true">
              <h5 class="titleDetails">Masse de la loge lacrymal</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Localisation</label>
                <input type="text" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.localisation">
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Taille</label>
                <div class="d-flex">
                  <input type="number" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.taille" (change)="checkAnnexeMassetailleRight()">
                  <p class="ml-2">mm</p>
                </div>
              </div>
              <div class="col-12">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.massedelalogelacrymal.description">
              </div>
              <br>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.meeibomite.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Meeibomite</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.meeibomite.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="  examAnexe.annexe.anomaly.oielDroit.ophtalmologie.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Ophtalmologie</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.ophtalmologie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.orgelet.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Orgelet</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.orgelet.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.present==true">
              <h5 class="titleDetails">Paralysie occulomotrice</h5>
              <div class="col-lg-6 col-md-12">
                <label for="titre">Muscle</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.indexOf('Droit interne') >-1 " (click)="selectparalysieOcculomotriceMuscleDroit('Droit interne')"><label>Droit interne</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.indexOf('Droit externe') >-1 " (click)="selectparalysieOcculomotriceMuscleDroit('Droit externe')"><label>Droit externe</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.indexOf('Oblique supérieur') >-1 " (click)="selectparalysieOcculomotriceMuscleDroit('Oblique supérieur')"><label>Oblique supérieur</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.indexOf('Oblique inférieur') >-1 " (click)="selectparalysieOcculomotriceMuscleDroit('Oblique inférieur')"><label>Oblique inférieur</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.indexOf('Droit supérieur') >-1 " (click)="selectparalysieOcculomotriceMuscleDroit('Droit supérieur')"><label>Droit supérieur</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.muscle.indexOf('Droit inférieur') >-1 " (click)="selectparalysieOcculomotriceMuscleDroit('Droit inférieur')"><label>Droit inférieur</label>
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="titre">Nerf</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.nerf.indexOf('III') >-1 " (click)="selectparalysieOcculomotriceNerfDroit('III')"><label>III</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.nerf.indexOf('IV') >-1 " (click)="selectparalysieOcculomotriceNerfDroit('IV')"><label>IV</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.nerf.indexOf('VI') >-1 " (click)="selectparalysieOcculomotriceNerfDroit('VI')"><label>VI</label>
                <div class="col-12">
                  <label for="titre">Description</label>
                  <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.paralysieocculomotrice.description">
                </div>
              </div>

              <br>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.present==true">
              <h5 class="titleDetails">Présence de strabisme</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Type</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.type">
                  <option>Exotropie</option>
                  <option>Esotropie</option>
                  <option>Hyperopie</option>
                  <option>Hypotropie</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Angle de déviation</label>
                <input type="number" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.angleDeDeviation">
              </div>
              <br>
              <div class="col-lg-4 col-md-6 mt-3">
                <label for="titre">Alternance</label>
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.alternance">Oui
                </div>
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.alternance" (change)="selectAlternanceRight()"> Non
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-3">
                <div>
                  <label for="titre">Nistagmus</label>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.nistagmus">Oui
                  </div>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.nistagmus" (change)="selectNistagmusRight()"> Non
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-3">
                <div>
                  <label for="titre">Oeil Fixateur</label>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.oielFixateurDroit=='Oeil droit'" (click)="selectOeilFixateurDroit('Oeil droit')">Oeil droit
                  </div>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.oielFixateurDroit=='Oeil gauche'" (click)="selectOeilFixateurDroit('Oeil gauche')"> Oeil gauche
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-3">
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.nml">NML
                </div>
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.dvd"> DVD
                </div>
              </div>
              <div class="col-lg-9 mt-3">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.presencedestrabisme.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="  examAnexe.annexe.anomaly.oielDroit.plaie.present==true">
              <h5 class="titleDetails">Plaie</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Localisation</label>
                <input type="text" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.plaie.localisation">
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Longueur</label>
                <div class="d-flex">
                  <input type="number" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.plaie.longueur" (change)="checkAnnexePlaielongueurRight()">
                  <label class="ml-2">mm</label>
                </div>
              </div>
              <div class="col-12 mt-3">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.plaie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.ptosis.present==true">
              <h5 class="titleDetails">Ptosis</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Type</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.ptosis.type">
                  <option>Congenitate</option>
                  <option>Traumatique</option>
                  <option>Sènile</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Grade</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.ptosis.grade">
                  <option>Minime (2mm)</option>
                  <option>Modérée (3mm)</option>
                  <option>Sévère ( ≥ 4mm)</option>
                </select>
              </div>
              <div class="col-12 mt-3">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.ptosis.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.refletcorneendecentre.present==true">
              <h5 class="titleDetails">Réflet cornéen décentré</h5>
              <div class="col-lg-3 col-md-3">
                <label for="titre">Degré</label>
                <input type="number" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.refletcorneendecentre.degre">
              </div>
              <div class="col-lg-9 col-md-9">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.refletcorneendecentre.description">
              </div>
              <br>
            </div>
            <div class="row margin-t-10" *ngIf="  examAnexe.annexe.anomaly.oielDroit.stenoselacrymal.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Sténose lacrymal</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.stenoselacrymal.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.sondedeDCRenplace.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Sonde de DCR en place</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.sondedeDCRenplace.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.telangiectasie.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Telangiectasie</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.telangiectasie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielDroit.tumeurpaplepbrale.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Tumeur paplepbrale</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielDroit.tumeurpaplepbrale.description">
              </div>
            </div>
            <!-- dynamique anomalies-->
            <div class="row margin-t-10" *ngFor="let anomaly of examAnexe.annexe.anomaly.oielDroit.allAnomalies">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">{{anomaly.titre}}</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
              </div>
            </div>
            <!-- dynamique anomalies-->
          </div>
          <div class="col-md-6">
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.normale.present==true">
              <div class="col-lg-4 col-md-4">
                <h5 class="titleDetails">Normale</h5>
              </div>
              <div class="col-lg-8 col-md-8">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.normale.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.chalazion.present==true">
              <h5 class="titleDetails">Chalazion</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Localisation</label>
                <input type="text" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.chalazion.localisation">
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Taille</label>
                <div class="d-flex">
                  <input type="number" class="form-control  input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.chalazion.taille" (change)="checkAnnexechalaziontailleLeft()">
                  <p class="ml-2">mm</p>
                </div>

              </div>
              <div class="col-12">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.chalazion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.dca.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">DCA</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.dca.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.dcc.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">DCC</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.dcc.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.dermabrasion.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Dermabrasion</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.dermabrasion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.dgm.present==true">
              <h5 class="titleDetails">DGM</h5>
              <div class="col-12">
                <label for="titre">Grade</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.dgm.grade">
                  <option>Grade 1= Inflammation du bord libre de la paupière + Télangiectasie</option>
                  <option>Grade 2 = Inflammation du bord libre de la paupière + Kératinisation</option>
                  <option>Grade 3 = Atrophie partielle des glandes de Meibomius</option>
                  <option>Grade 4 = Atrophie totale des glandes de Meibomius</option>
                </select>
              </div>
              <div class="col-12 mt-3">
                <label for="titre">Description</label>
                <div><input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.dgm.description"></div>
              </div>
              <br>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.diplopie.present==true">
              <h5 class="titleDetails">Diplopie</h5>
              <div class="col-lg-6 col-md-12">
                <div>
                  <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.diplopie.type=='Binoculaire'" (click)="selectanexeDiploieGauche('Binoculaire')">
                  <label for="titre">Binoculaire</label>
                </div>
                <div>
                  <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.diplopie.type=='Monoculaire'" (click)="selectanexeDiploieGauche('Monoculaire')">
                  <label for="titre">Monoculaire</label>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <label>Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.diplopie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.distichoasis.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Distichoasis</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.distichoasis.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.ecoulementlacrymal.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Écoulement lacrymal</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.ecoulementlacrymal.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.entropion.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Entropion</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.entropion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.ectropion.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Ectropion</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.ectropion.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.fistule.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Fistule</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.fistule.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.insuffisancedeconvergence.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Insuffisance de convergence</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.insuffisancedeconvergence.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.present==true">
              <h5 class="titleDetails">Masse de la loge lacrymal</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Localisation</label>
                <input type="text" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.localisation">
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Taille</label>
                <div class="d-flex">
                  <input type="number" class="form-control input-sm-new col-lg-10 col-md-10" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.taille" (change)="checkAnnexeMassetailleLeft()">
                  <p class="ml-2">mm</p>
                </div>
              </div>
              <div class="col-12">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.massedelalogelacrymal.description">
              </div>
              <br>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.meeibomite.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Meeibomite</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.meeibomite.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="  examAnexe.annexe.anomaly.oielGauche.ophtalmologie.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Ophtalmologie</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.ophtalmologie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.orgelet.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Orgelet</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.orgelet.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.present==true">
              <h5 class="titleDetails">Paralysie occulomotrice</h5>
              <div class="col-lg-6 col-md-12">
                <label for="titre">Muscle</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.indexOf('Droit interne') >-1 " (click)="selectparalysieOcculomotriceMuscleGauche('Droit interne')"><label>Droit interne</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.indexOf('Droit externe') >-1 " (click)="selectparalysieOcculomotriceMuscleGauche('Droit externe')"><label>Droit externe</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.indexOf('Oblique supérieur') >-1 " (click)="selectparalysieOcculomotriceMuscleGauche('Oblique supérieur')"><label>Oblique supérieur</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.indexOf('Oblique inférieur') >-1 " (click)="selectparalysieOcculomotriceMuscleGauche('Oblique inférieur')"><label>Oblique inférieur</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.indexOf('Droit supérieur') >-1 " (click)="selectparalysieOcculomotriceMuscleGauche('Droit supérieur')"><label>Droit supérieur</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.muscle.indexOf('Droit inférieur') >-1 " (click)="selectparalysieOcculomotriceMuscleGauche('Droit inférieur')"><label>Droit inférieur</label>
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="titre">Nerf</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.nerf.indexOf('III') >-1 " (click)="selectparalysieOcculomotriceNerfGauche('III')"><label>III</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.nerf.indexOf('IV') >-1 " (click)="selectparalysieOcculomotriceNerfGauche('IV')"><label>IV</label>
                <br>
                <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.nerf.indexOf('VI') >-1 " (click)="selectparalysieOcculomotriceNerfGauche('VI')"><label>VI</label>
                <div class="col-12">
                  <label for="titre">Description</label>
                  <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.paralysieocculomotrice.description">
                </div>
              </div>

              <br>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.present==true">
              <h5 class="titleDetails">Présence de strabisme</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Type</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.type">
                  <option>Exotropie</option>
                  <option>Esotropie</option>
                  <option>Hyperopie</option>
                  <option>Hypotropie</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Angle de déviation</label>
                <input type="number" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.angleDeDeviation">
              </div>
              <br>
              <div class="col-lg-4 col-md-6 mt-3">
                <label for="titre">Alternance</label>
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.alternance">Oui
                </div>
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.alternance" (change)="selectAlternanceLeft()"> Non
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-3">
                <div>
                  <label for="titre">Nistagmus</label>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.nistagmus">Oui
                  </div>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [(ngModel)]="!examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.nistagmus" (change)="selectNistagmusLeft()"> Non
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-3">
                <div>
                  <label for="titre">Oeil Fixateur</label>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.oielFixateurDroit=='Oeil droit'" (click)="selectOeilFixateurGauche('Oeil droit')">Oeil droit
                  </div>
                  <div>
                    <input type="checkbox" class="radiotypeinput" [checked]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.oielFixateurDroit=='Oeil gauche'" (click)="selectOeilFixateurGauche('Oeil gauche')"> Oeil gauche
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-3">
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.nml">NML
                </div>
                <div>
                  <input type="checkbox" class="radiotypeinput" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.dvd"> DVD
                </div>
              </div>
              <div class="col-lg-9 mt-3">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.presencedestrabisme.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf="  examAnexe.annexe.anomaly.oielGauche.plaie.present==true">
              <h5 class="titleDetails">Plaie</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Localisation</label>
                <input type="text" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.plaie.localisation">
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Longueur</label>
                <div class="d-flex">
                  <input type="number" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.plaie.longueur" (change)="checkAnnexePlaielongueurLeft()">
                  <label class="ml-2">mm</label>
                </div>
              </div>
              <div class="col-12 mt-3">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.plaie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.ptosis.present==true">
              <h5 class="titleDetails">Ptosis</h5>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Type</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.ptosis.type">
                  <option>Congenitate</option>
                  <option>Traumatique</option>
                  <option>Sènile</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="titre">Grade</label>
                <select class="form-control input-sm-new" name="titre" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.ptosis.grade">
                  <option>Minime (2mm)</option>
                  <option>Modérée (3mm)</option>
                  <option>Sévère ( ≥ 4mm)</option>
                </select>
              </div>
              <div class="col-12 mt-3">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.ptosis.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.refletcorneendecentre.present==true">
              <h5 class="titleDetails">Réflet cornéen décentré</h5>
              <div class="col-lg-3 col-md-3">
                <label for="titre">Degré</label>
                <input type="number" class="form-control input-sm-new" [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.refletcorneendecentre.degre">
              </div>
              <div class="col-lg-9 col-md-9">
                <label for="titre">Description</label>
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.refletcorneendecentre.description">
              </div>
              <br>
            </div>
            <div class="row margin-t-10" *ngIf="  examAnexe.annexe.anomaly.oielGauche.stenoselacrymal.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Sténose lacrymal</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.stenoselacrymal.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.sondedeDCRenplace.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Sonde de DCR en place</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.sondedeDCRenplace.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.telangiectasie.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Telangiectasie</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.telangiectasie.description">
              </div>
            </div>
            <div class="row margin-t-10" *ngIf=" examAnexe.annexe.anomaly.oielGauche.tumeurpaplepbrale.present==true">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">Tumeur paplepbrale</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examAnexe.annexe.anomaly.oielGauche.tumeurpaplepbrale.description">
              </div>
            </div>
            <!-- dynamique anomalies-->
            <div class="row margin-t-10" *ngFor="let anomaly of examAnexe.annexe.anomaly.oielGauche.allAnomalies">
              <div class="col-lg-4 col-md-12">
                <h5 class="titleDetails">{{anomaly.titre}}</h5>
              </div>
              <div class="col-lg-8 col-md-12">
                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
              </div>
            </div>
            <!-- dynamique anomalies-->
          </div>
          <div class="row emptyBloc">
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
<!-----------    End  Annexe       ----->