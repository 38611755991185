import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { RateLimiteAuthInterceptor } from 'src/app/services/interceptor/interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OphtalmoAuthGuard } from 'src/app/guards/auth-ophtalmo.guard';
import { ImpressionCatComponent } from './dashboard/backoffice/examen/conclusion/impression-cat/impression-cat.component';
import { ImpressionConclusionComponent } from './dashboard/backoffice/examen/conclusion/impression-conclusion/impression-conclusion.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./dashboard/home/home.module')
      .then(mod => mod.HomeModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/login/login.module')
      .then(mod => mod.LoginModule)
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/backoffice/backoffice.module')
      .then(mod => mod.BackofficeModule)
  },
  {path:'medecin/impression/:idExamen/:idCat',component:ImpressionCatComponent,canActivate:[OphtalmoAuthGuard]},
  {path:'medecin/impression/conclusion/examen/:idExamen',component:ImpressionConclusionComponent,canActivate:[OphtalmoAuthGuard]},
  { path: '**', redirectTo: '/medecin/accueil' } // Wildcard route for unknown paths

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled' //scroll to the top
})],
  // providers: [
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: RateLimiteAuthInterceptor,
  //     multi: true
  //   }
  // ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
