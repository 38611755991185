<section id="cours" style="width: 85%;margin: 0 auto;margin-top: 8%;">

    <div id="demo" *ngIf="patient">
      <form [formGroup]="form" class="form-horizontal" role="form">
        <fieldset>
          <!-- Form Name -->
          <div class="row">
            <legend id="lengend-regle">Informations</legend>
            <div class="form-check" style="margin-left: 15px;margin-bottom: 10px;">
              <input type="checkbox" class="form-check-input" name="patientList" formControlName="patientList" [(ngModel)]="patientList" title="A partir de ma liste des patients" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1" style="position: absolute;top: -55px;">A partir de la liste des médecins de Medivisto</label>
            </div>
            <p style="color:green" *ngIf="successRegister">Le médecin est ajouté avec succès</p>
          </div>
          <!-- Text input-->
          <div class="" *ngIf="!patientList">
            <div class="row">
              <div class="col-6">
                <label for="nom">Nom* </label>
                <input type="text" formControlName="nom" name="nom" class="form-control input-sm" id="prenom" placeholder="Nom">
                <control-messages [control]="form.controls.nom"></control-messages>
              </div>
              <div class="col-6">
                <label for="prenom">Prénom*</label>
                <input type="text" formControlName="prenom" name="prenom" class="form-control input-sm" id="prenom" placeholder="Prénom">
                <control-messages [control]="form.controls.prenom"></control-messages>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="tel">Email*</label>
                <input type="text" formControlName="email" name="email" class="form-control input-sm" id="email" placeholder="Email">
                <control-messages [control]="form.controls.email"></control-messages>
              </div>
              <div class="col-6">
                <label for="numInscription">N°d'inscription au conseil de l'ordre</label>
                <input type="text" formControlName="numInscription" name="numInscription" class="form-control input-sm" id="numInscription" placeholder="N°d'inscription au conseil de l'ordre">
                <control-messages [control]="form.controls.numInscription"></control-messages>
                <p style="color:red;font-size: 14px;" *ngIf="errorNumeroInscription">Le numéro d'inscription est déjà utilisé</p>
              </div>
            </div>
  
            <div class="row">
              <div class="col-6">
                <label for="telMobile">Contact 1*</label>
                <international-phone-number class="mystyle" formControlName="tel" placeholder="Contact 1" [maxlength]="13" name="tel"></international-phone-number>
                <control-messages [control]="form.controls.tel"></control-messages>
  
              </div>
              <div class="col-6">
                <label for="telFixe">Contact 2</label>
                <international-phone-number class="mystyle" formControlName="telFixe" placeholder="Contact 1" [maxlength]="13" name="telFixe"></international-phone-number>
                <control-messages [control]="form.controls.telFixe"></control-messages>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="telPerso">Contact 3</label>
                <international-phone-number class="mystyle" formControlName="telPerso" placeholder="Contact 1" [maxlength]="13" name="telPerso"></international-phone-number>
                <control-messages [control]="form.controls.telPerso"></control-messages>
              </div>
              <div class="col-6">
                <label for="diplome">Diplôme</label>
                <input type="text" formControlName="diplome" name="diplome" class="form-control input-sm" id="diplome" placeholder="Diplôme">
                <control-messages [control]="form.controls.diplome"></control-messages>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="specialite">Spécialité*</label>
                <select class="form-control input-sm" id="specialite" formControlName="specialite" name="specialite">
                  <option>Acupuncture</option>
                  <option>Allergologue</option>
                  <option>Anatomo-Cyto-Pathologiste</option>
                  <option>Anesthésiste-Réanimateur</option>
                  <option>Angiologue</option>
                  <option>Audioprothésiste</option>
                  <option>Audioprothésiste</option>
                  <option>Biochimiste</option>
                  <option>Biochimiste Clinique</option>
                  <option>Biologiste Medicale</option>
                  <option>Biophysique</option>
                  <option>Cancérologue</option>
                  <option>Cardiologue</option>
                  <option>Chirurgien</option>
                  <option>Chirurgien Cancérologue</option>
                  <option>Chirurgien Cardio-Vasculaire</option>
                  <option>Chirurgien Cardio-Vasculaire Thoracique</option>
                  <option>Chirurgien Esthétique</option>
                  <option>Chirurgien Généraliste</option>
                  <option>Chirurgien Maxillo Facial Stomatologue</option>
                  <option>Chirurgien Orthopédiste</option>
                  <option>Chirurgien Orthopédiste Traumatologue</option>
                  <option>Chirurgien Pédiatrique</option>
                  <option>Chirurgien Plasticien</option>
                  <option>Chirurgien Thoracique</option>
                  <option>Chirurgien Urologue</option>
                  <option>Dentiste</option>
                  <option>Dermatologue</option>
                  <option>Diabétologue</option>
                  <option>Diététicien</option>
                  <option>Embryologiste</option>
                  <option>Endocrinologue</option>
                  <option>Endocrinologue Diabétologue</option>
                  <option>Gastro-entérologue</option>
                  <option>Gériatre</option>
                  <option>Généraliste</option>
                  <option>Gynécologue</option>
                  <option>Gynécologue Obstétricien</option>
                  <option>Hématologue</option>
                  <option>Hématologue Clinique</option>
                  <option>Hématopathologiste</option>
                  <option>Hépatologue</option>
                  <option>Hypnothérapeute</option>
                  <option>Imagerie Médicale</option>
                  <option>Immunologiste</option>
                  <option>Immunopathologiste</option>
                  <option>Interniste</option>
                  <option>Interniste Maladies Infectieuses</option>
                  <option>Interniste Réanimation Médicale</option>
                  <option>Kinésithérapeute</option>
                  <option>Maladies Infectieuses</option>
                  <option>Médecin Biologiste</option>
                  <option>Médecin du Travail</option>
                  <option>Médecin Esthétique</option>
                  <option>Médecin Légiste</option>
                  <option>Médecin Nucléaire</option>
                  <option>Médecin Physique</option>
                  <option>Médecin Physique Réadaptateur</option>
                  <option>Médecine Préventive</option>
                  <option>Microbiologiste</option>
                  <option>Néonatologiste</option>
                  <option>Néphrologue</option>
                  <option>Neurochirurgien</option>
                  <option>Neurologue</option>
                  <option>Nutritionniste</option>
                  <option>Oncologue-Radiothérapeute</option>
                  <option>Ophtalmologiste</option>
                  <option>Orthodontiste</option>
                  <option>Orthopédiste</option>
                  <option>Orthopédiste Traumatologue</option>
                  <option>Orthophoniste</option>
                  <option>Orthoptiste</option>
                  <option>Ostéopathe</option>
                  <option>Parasitologiste</option>
                  <option>Pédiatre</option>
                  <option>Pédodontiste</option>
                  <option>Pédopsychiatre</option>
                  <option>Pharmacien Biologiste</option>
                  <option>Phlébologue</option>
                  <option>Physiologiste</option>
                  <option>Physiothérapeute</option>
                  <option>Pneumologue</option>
                  <option>Podologue</option>
                  <option>Proctologue</option>
                  <option>Proctologue</option>
                  <option>Prothésiste dentaire</option>
                  <option>Psychanalyste</option>
                  <option>Psychiatre</option>
                  <option>Psychologue</option>
                  <option>Psychomotricien</option>
                  <option>Psychothérapeute</option>
                  <option>Radiologue</option>
                  <option>Radiothérapeute</option>
                  <option>Réanimateur Médical</option>
                  <option>Rhumatologue</option>
                  <option>Sexologue</option>
                  <option>Stomatologue</option>
                  <option>Urologue</option>
                </select>
                <control-messages [control]="form.controls.specialite"></control-messages>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="telMobile">Date de naissance</label>
                <input placeholder="Date de naissance" [max]="getToday()" formControlName="dateAnniversaire" name="dateAnniversaire" type="date" id="date-picker-example" class="form-control input-sm">
                <control-messages [control]="form.controls.dateAnniversaire"></control-messages>
              </div>
              <div class="col-6">
                <label for="tel">Sexe</label>
                <select class="form-control input-sm" id="sexe" formControlName="sexe" name="sexe">
                  <option>Masculin</option>
                  <option>Féminin </option>
                </select>
                <control-messages [control]="form.controls.sexe"></control-messages>
              </div>
            </div>
            <!-- Address Section -->
            <!-- Form Name -->
            <br>
            <div formGroupName="adresseCabinet">
              <div class="row">
                <legend id="lengend-regle">Adresse cabinet</legend>
              </div>
              <div class="row">
                <div class="col-6">
                  <label for="pays">Pays</label>
                  <select class="form-control input-sm" id="pays" formControlName="pays" name="pays">
                    <option value="" selected>Tunisie</option>
                    <option *ngFor="let py of pays">{{py.name}}</option>
                  </select>
                  <control-messages [control]="form.controls.adresseCabinet.controls.pays"></control-messages>
  
                </div>
                <div class="col-6">
                  <label for="ville">Ville</label>
                  <input type="text" formControlName="ville" name="ville" class="form-control input-sm" id="ville" placeholder="Ville">
                  <control-messages [control]="form.controls.adresseCabinet.controls.ville"></control-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label for="codePostal">Code postal</label>
                  <input type="text" formControlName="codePostal" name="codePostal" class="form-control input-sm" id="codePostal" placeholder="Code postal">
                  <control-messages [control]="form.controls.adresseCabinet.controls.codePostal"></control-messages>
                </div>
                <div class="col-6">
                  <label for="numero">Adresse</label>
                  <input type="text" formControlName="adresse" name="adresse" class="form-control input-sm" id="adresse" placeholder="Adresse">
                  <control-messages [control]="form.controls.adresseCabinet.controls.adresse"></control-messages>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <legend id="lengend-regle">Autres informations</legend>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="codeCNAM">Code CNAM</label>
                <input type="text" formControlName="codeCNAM" name="codeCNAM" class="form-control input-sm" id="codeCNAM" placeholder="Code CNAM">
                <control-messages [control]="form.controls.codeCNAM"></control-messages>
              </div>
              <div class="col-6">
                <label for="centreCNAM">Centre CNAM</label>
                <input type="text" formControlName="centreCNAM" name="centreCNAM" class="form-control input-sm" id="centreCNAM" placeholder="Centre CNAM">
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="dateDebutFonction">Date debut fonction</label>
                <input type="date" formControlName="dateDebutFonction" name="dateDebutFonction" class="form-control input-sm" id="dateDebutFonction">
              </div>
            </div>
            <br><br>
          </div>
          <div class="modal-footer d-flex justify-content-center" *ngIf="!patientList">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" (click)="onRegisterMedecin()" [disabled]="!form.valid"><img src="assets/images/listePatsEspaceMed/valider.png" class="iconBoutton">Enregistrer </button>
                    </td>
                    <td class="tdButt2 ">
                      <button class="btn-annule" [routerLink]="['/medecin/consultation-liste',patient._id]"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br><br>
        </fieldset>
      </form>
      <form [formGroup]="formm" class="form-horizontal" role="form" *ngIf="patientList">
        <div class="">
          <div class="form-group col-md-6">
            <label for="titre">Médecin*</label>
            <input type="text" style="width:100%" formControlName="selectedMedecin" autocomplete="off" name="selectedMedecin" [(ngModel)]="showedValue" (valueChanged)="myCallback($event)" ngui-auto-complete [source]="medecins"
              [list-formatter]="autocompleListFormatterPrenom" min-chars="4" max-num-list="10" no-match-found-text="Aucun élément" select-value-of="selectedMedecin" display-property-name="selectedMedecin" class="form-control" placeholder=" Médecin">
            <control-messages [control]="formm.controls.selectedMedecin"></control-messages>
            <p *ngIf="errorPatient" style="color:red">Ajouter un médecin !</p>
          </div>
        </div>
        <div class="col-sm-10">
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="onRegisterMedecinn()" [disabled]="!formm.valid"><img src="assets/images/listePatsEspaceMed/valider.png" class="iconBoutton">Enregistrer </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" [routerLink]="['/medecin/consultation-liste',patient._id]"><img src="assets/images/listePatsEspaceMed/annuler.png" class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <br><br><br><br><br><br><br><br><br><br>
      </form>
    </div>
  </section>
  