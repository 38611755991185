<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher  Vitre" (click)="displayAndHideVitre()">Vitre</h4>
            <div class="arrow arrow-up btnVitre" *ngIf="displayVitre" title="Cacher Vitre" (click)="displayAndHideVitre()">▲</div>
            <div class="arrow arrow-up btnVitre" *ngIf="!displayVitre" title="Afficher Vitre" (click)="displayAndHideVitre()">▼</div>
        </div>

        <div *ngIf="displayVitre" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6 col-sm-12">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.calmeEtAcellulaire.present==true}" (click)="addRemoveFromVitreRight('Calme et acellulaire')">Calme et acellulaire</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.banquise.present==true}" (click)="addRemoveFromVitreRight('Banquise')">Banquise</li>
                                <div *ngIf="showMoreVitre">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.brides.present==true}" (click)="addRemoveFromVitreRight('Brides')">Brides</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.cordage.present==true}" (click)="addRemoveFromVitreRight('Cordage')">Cordage</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.corpsasterode.present==true}" (click)="addRemoveFromVitreRight('Corps astéroïde')">Corps astéroïde</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.corpsetrangers.present==true}" (click)="addRemoveFromVitreRight('Corps étrangers')">Corps étrangers
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.DPV.present==true}" (click)="addRemoveFromVitreRight('DPV')">DPV</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.essaimagetumoral.present==true}" (click)="addRemoveFromVitreRight('Essaimage tumoral')">Essaimage tumoral</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.fragmentcristallinien.present==true}" (click)="addRemoveFromVitreRight('Fragment cristallinien')">Fragment cristallinien</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.hematique.present==true}" (click)="addRemoveFromVitreRight('Hématique')">Hématique</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.hemorragiepreretinienne.present==true}" (click)="addRemoveFromVitreRight('Hémorragie prérétinienne')">Hémorragie prérétinienne</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.vitre.oielDroit.hemorragievitreene.present==true}" (click)="addRemoveFromVitreRight('Hémorragie vitréene')">Hémorragie vitréene</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.Lacune.present==true}" (click)="addRemoveFromVitreRight('Lacune')">Lacune</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.liquefaction.present==true}" (click)="addRemoveFromVitreRight('Liquéfaction')">Liquéfaction</li>
                                <div *ngIf="showMoreVitre">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.massecristallinienne.present==true}" (click)="addRemoveFromVitreRight('Masse cristallinienne')">Masse cristallinienne</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.oeufdefourmis.present==true}" (click)="addRemoveFromVitreRight('Oeuf de fourmis')">Oeuf de fourmis</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.vitre.oielDroit.persistanceduvitreprimitif.present==true}" (click)="addRemoveFromVitreRight('Persistance du vitré primitif')">Persistance du vitré primitif</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.pigmentaire.present==true}" (click)="addRemoveFromVitreRight('Pigmentaire')">Pigmentaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.precipiteencolierdeperle.present==true}" (click)="addRemoveFromVitreRight('Précipités en colier de perles')">Précipités en colier de perles
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.proteique.present==true}" (click)="addRemoveFromVitreRight('Protéique')">Protéique</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.synchesisetincelant.present==true}" (click)="addRemoveFromVitreRight('Synchisis étincelant')">Synchisis étincelant</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.tyndallcellulaire.present==true}" (click)="addRemoveFromVitreRight('Tyndall cellulaire')">Tyndall cellulaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielDroit.voilevitreen.present==true}" (click)="addRemoveFromVitreRight('Voile vitréen')">Voile vitréen</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorVitre" [ngClass]="{'selectedLi': checkExistAnomalieVitreRight(anomaly.id)}">
                                        <span (click)="addRemoveFromVitreRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieVitreRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-vitre')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieVitre" (keyup.enter)="addNewAnomalyVitre($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>

                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreVitreSection() *ngIf="!showMoreVitre">Voir plus</span>
                            <span (click)=showMoreVitreSection() *ngIf="showMoreVitre">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil gauche</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6 col-sm-12">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.calmeEtAcellulaire.present==true}" (click)="addRemoveFromVitreLeft('Calme et acellulaire')">Calme et acellulaire</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.banquise.present==true}" (click)="addRemoveFromVitreLeft('Banquise')">Banquise</li>
                                <div *ngIf="showMoreVitre">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.brides.present==true}" (click)="addRemoveFromVitreLeft('Brides')">Brides</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.cordage.present==true}" (click)="addRemoveFromVitreLeft('Cordage')">Cordage</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.vitre.oielGauche.corpsasterode.present==true}" (click)="addRemoveFromVitreLeft('Corps astéroïde')">Corps astéroïde</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.corpsetrangers.present==true}" (click)="addRemoveFromVitreLeft('Corps étrangers')">Corps étrangers
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.DPV.present==true}" (click)="addRemoveFromVitreLeft('DPV')">DPV</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.essaimagetumoral.present==true}" (click)="addRemoveFromVitreLeft('Essaimage tumoral')">Essaimage tumoral</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.fragmentcristallinien.present==true}" (click)="addRemoveFromVitreLeft('Fragment cristallinien')">Fragment cristallinien</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.hematique.present==true}" (click)="addRemoveFromVitreLeft('Hématique')">Hématique</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.hemorragiepreretinienne.present==true}" (click)="addRemoveFromVitreLeft('Hémorragie prérétinienne')">Hémorragie prérétinienne</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.hemorragievitreene.present==true}" (click)="addRemoveFromVitreLeft('Hémorragie vitréene')">Hémorragie vitréene</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.Lacune.present==true}" (click)="addRemoveFromVitreLeft('Lacune')">Lacune</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.liquefaction.present==true}" (click)="addRemoveFromVitreLeft('Liquéfaction')">Liquéfaction</li>
                                <div *ngIf="showMoreVitre">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.massecristallinienne.present==true}" (click)="addRemoveFromVitreLeft('Masse cristallinienne')">Masse cristallinienne</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.oeufdefourmis.present==true}" (click)="addRemoveFromVitreLeft('Oeuf de fourmis')">Oeuf de fourmis</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.vitre.oielGauche.persistanceduvitreprimitif.present==true}" (click)="addRemoveFromVitreLeft('Persistance du vitré primitif')">Persistance du vitré primitif</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.pigmentaire.present==true}" (click)="addRemoveFromVitreLeft('Pigmentaire')">Pigmentaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.precipiteencolierdeperle.present==true}" (click)="addRemoveFromVitreLeft('Précipités en colier de perles')">Précipités en colier de perles
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.proteique.present==true}" (click)="addRemoveFromVitreLeft('Protéique')">Protéique</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.synchesisetincelant.present==true}" (click)="addRemoveFromVitreLeft('Synchisis étincelant')">Synchisis étincelant</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.tyndallcellulaire.present==true}" (click)="addRemoveFromVitreLeft('Tyndall cellulaire')">Tyndall cellulaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.vitre.oielGauche.voilevitreen.present==true}" (click)="addRemoveFromVitreLeft('Voile vitréen')">Voile vitréen</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorVitre" [ngClass]="{'selectedLi': checkExistAnomalieVitreLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromVitreLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieVitreLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-vitre')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieVitre" (keyup.enter)="addNewAnomalyVitre($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>

                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreVitreSection() *ngIf="!showMoreVitre">Voir plus</span>
                            <span (click)=showMoreVitreSection() *ngIf="showMoreVitre">Voir moins</span>
                        </div>
                    </div>
                </div>

            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.vitre.oielDroit.calmeEtAcellulaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Calme et acellulaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.calmeEtAcellulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.tyndallcellulaire.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Tyndall cellulaire</h5>
                        <div class="row">
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.tyndallcellulaire.valeur=='0.5: Trace (2-20 cellules)'" (change)="selectVitretyndallcellulaireRight('0.5: Trace (2-20 cellules)')">0.5: Trace (2-20 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.tyndallcellulaire.valeur=='1+: Minime (21-50 cellules)'" (change)="selectVitretyndallcellulaireRight('1+: Minime (21-50 cellules)')">
                                1+: Minime (21-50 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.tyndallcellulaire.valeur=='2+: Modéré (51-100 cellules)'" (change)="selectVitretyndallcellulaireRight('2+: Modéré (51-100 cellules)')">
                                2+: Modéré (51-100 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.tyndallcellulaire.valeur=='3+: Marqué (101-250 cellules)'" (change)="selectVitretyndallcellulaireRight('3+: Marqué (101-250 cellules)')">
                                3+: Marqué (101-250 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.tyndallcellulaire.valeur=='4+: Intense (> 250 cellules)'" (change)="selectVitretyndallcellulaireRight('4+: Intense (> 250 cellules)')">
                                4+: Intense (> 250 cellules)
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.tyndallcellulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class=" margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.proteique.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Protéique</h5>
                        <div class="row">
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.proteique.valeur=='1+: Minime (seules les fibres optiques sont flous)'" (change)="selectVitreproteiqueRight('1+: Minime (seules les fibres optiques sont flous)')">
                                1+: Minime (seules les fibres optiques sont flous)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.proteique.valeur=='2+: Modéré (vaisseauxet nerf optiques visiblement flous)'" (change)="selectVitreproteiqueRight('2+: Modéré (vaisseauxet nerf optiques visiblement flous)')">
                                2+: Modéré (vaisseauxet nerf optiques visiblement flous)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.proteique.valeur=='3+: Marqué (seul le nerf optique est visible)'" (change)="selectVitreproteiqueRight('3+: Marqué (seul le nerf optique est visible)')">
                                3+: Marqué (seul le nerf optique est visible)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.proteique.valeur=='4+: Font d oeil non visible'" (change)="selectVitreproteiqueRight('4+: Font d oeil non visible')">
                                4+: Font d'oeil non visible
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.proteique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.DPV.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">DPV</h5>
                        <div class="col-md-4 col-lg-4">
                            <label for="">Type</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.DPV.valeur=='Partiel'" (change)="selectVitreDPVRight('Partiel')">Partiel
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielDroit.DPV.valeur=='Totale'" (change)="selectVitreDPVRight('Totale')">Totale
                            </div>


                        </div>
                        <div class="col-md-8 col-lg-8">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.DPV.description">
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.hematique.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hématique </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.hematique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.hemorragievitreene.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie vitréene </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.hemorragievitreene.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.pigmentaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigmentaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.pigmentaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.essaimagetumoral.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Essaimage tumoral </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.essaimagetumoral.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.banquise.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Banquise </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.banquise.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.oeufdefourmis.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Oeuf de fourmis </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.oeufdefourmis.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.Lacune.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Lacune </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.Lacune.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.massecristallinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails"> Masse cristallinienne</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.massecristallinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.persistanceduvitreprimitif.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Persistance du vitré primitif </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.persistanceduvitreprimitif.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.corpsetrangers.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps étrangers </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.corpsetrangers.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.fragmentcristallinien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fragment cristallinien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.fragmentcristallinien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.voilevitreen.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Voile vitréen </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.voilevitreen.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.cordage.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Cordage </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.cordage.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.brides.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Brides </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.brides.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.synchesisetincelant.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Synchisis étincelant </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.synchesisetincelant.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.corpsasterode.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps astéroïde </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.corpsasterode.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.hemorragiepreretinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie prérétinienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.hemorragiepreretinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.liquefaction.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Liquéfaction </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.liquefaction.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielDroit.precipiteencolierdeperle.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Précipités en colier de perles </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielDroit.precipiteencolierdeperle.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.vitre.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails"> {{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.vitre.oielGauche.calmeEtAcellulaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Calme et acellulaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.calmeEtAcellulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.tyndallcellulaire.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Tyndall cellulaire</h5>
                        <div class="row">
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.tyndallcellulaire.valeur=='0.5: Trace (2-20 cellules)'" (change)="selectVitretyndallcellulaireLeft('0.5: Trace (2-20 cellules)')">0.5: Trace (2-20 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.tyndallcellulaire.valeur=='1+: Minime (21-50 cellules)'" (change)="selectVitretyndallcellulaireLeft('1+: Minime (21-50 cellules)')">
                                1+: Minime (21-50 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.tyndallcellulaire.valeur=='2+: Modéré (51-100 cellules)'" (change)="selectVitretyndallcellulaireLeft('2+: Modéré (51-100 cellules)')">
                                2+: Modéré (51-100 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.tyndallcellulaire.valeur=='3+: Marqué (101-250 cellules)'" (change)="selectVitretyndallcellulaireLeft('3+: Marqué (101-250 cellules)')">
                                3+: Marqué (101-250 cellules)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.tyndallcellulaire.valeur=='4+: Intense (> 250 cellules)'" (change)="selectVitretyndallcellulaireLeft('4+: Intense (> 250 cellules)')">
                                4+: Intense (> 250 cellules)
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.tyndallcellulaire.description">
                            </div>
                        </div>
                    </div>
                    <div class=" margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.proteique.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Protéique</h5>
                        <div class="row">
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.proteique.valeur=='1+: Minime (seules les fibres optiques sont flous)'" (change)="selectVitreproteiqueLeft('1+: Minime (seules les fibres optiques sont flous)')">
                                1+: Minime (seules les fibres optiques sont flous)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.proteique.valeur=='2+: Modéré (vaisseauxet nerf optiques visiblement flous)'" (change)="selectVitreproteiqueLeft('2+: Modéré (vaisseauxet nerf optiques visiblement flous)')">
                                2+: Modéré (vaisseauxet nerf optiques visiblement flous)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.proteique.valeur=='3+: Marqué (seul le nerf optique est visible)'" (change)="selectVitreproteiqueLeft('3+: Marqué (seul le nerf optique est visible)')">
                                3+: Marqué (seul le nerf optique est visible)
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.proteique.valeur=='4+: Font d oeil non visible'" (change)="selectVitreproteiqueLeft('4+: Font d oeil non visible')">
                                4+: Font d'oeil non visible
                            </div>
                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.proteique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.DPV.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">DPV</h5>
                        <div class="col-md-4 col-lg-4">
                            <label for="">Type</label>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.DPV.valeur=='Partiel'" (change)="selectVitreDPVLeft('Partiel')">Partiel
                            </div>
                            <div>
                                <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.vitre.oielGauche.DPV.valeur=='Totale'" (change)="selectVitreDPVLeft('Totale')">Totale
                            </div>
                        </div>
                        <div class="col-md-8 col-lg-8">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.DPV.description">
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.hematique.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hématique </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.hematique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.hemorragievitreene.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie vitréene </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.hemorragievitreene.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.pigmentaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigmentaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.pigmentaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.essaimagetumoral.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Essaimage tumoral </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.essaimagetumoral.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.banquise.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Banquise </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.banquise.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.oeufdefourmis.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Oeuf de fourmis </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.oeufdefourmis.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.Lacune.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Lacune </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.Lacune.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.massecristallinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails"> Masse cristallinienne</h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.massecristallinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.persistanceduvitreprimitif.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Persistance du vitré primitif </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.persistanceduvitreprimitif.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.corpsetrangers.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps étrangers </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.corpsetrangers.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.fragmentcristallinien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fragment cristallinien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.fragmentcristallinien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.voilevitreen.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Voile vitréen </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.voilevitreen.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.cordage.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Cordage </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.cordage.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.brides.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Brides </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.brides.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.synchesisetincelant.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Synchisis étincelant </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.synchesisetincelant.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.corpsasterode.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Corps astéroïde </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.corpsasterode.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.hemorragiepreretinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie prérétinienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.hemorragiepreretinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.liquefaction.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Liquéfaction </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.liquefaction.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.vitre.oielGauche.precipiteencolierdeperle.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Précipités en colier de perles </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.vitre.oielGauche.precipiteencolierdeperle.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.vitre.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails"> {{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>