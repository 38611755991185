import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-laf',
  templateUrl: './laf.component.html',
  styleUrls: ['./laf.component.css', '../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class LafComponent implements OnInit {
  // LAF
  debutLAF = true;
  LAFanomalie = false;
  LAFanomalieAnterieur = false;
  LAFanomaliePosterieur = false;

  //----------------------------------  BUT/SHIRMER
  displayButShirmer = false
  butODSeconde: number
  butOGSeconde: number
  butODmm: number
  butOGmm: number

  //------------------------------------  TO/AIC
  displayTO = false;
  toODmm: number
  toOGmm: number

  //--------------------------------------------------- segement posterieur
  segmentPosterieur = false;

  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  // send data 
  objecttoSend = {
    pachymtrieOD: 0,
    pachymtrieOG: 0,
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "",
    nextAppointmentDuration: "",
    nextMotif: ""
  };







  constructor(private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-butShirmerTo').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    // save data
    this.sharedDataService.dataBtn$.subscribe((data) => {
      if (data && data.type && data.type == 'laf-butShirmerTo') {
        this.updateExamen(this.examLaf)
        return true
      }
    });
  }
  // Show grand section
  displayAndHideSegementAnt() {
    this.LAFanomalieAnterieur = !this.LAFanomalieAnterieur
  }
  displayAndHideSegementPost() {
    this.segmentPosterieur = !this.segmentPosterieur
  }
  showHideshowButShirmer() {
    this.displayButShirmer = !this.displayButShirmer
  }
  showHideTO() {
    this.displayTO = !this.displayTO
  }
  configureDataExamen(idPatient) {
    // !Historique LAF
    this.examenService.getInspectionHistoriques(idPatient, "laf").subscribe((data: any) => {
      this.oldListConsultations = data
    },
      err => {
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }

  // ------------start code LAF
  showDebutLAF() {
    this.debutLAF = !this.debutLAF;
    this.LAFanomalie = false;
  }
  showLAFanomalie() {
    this.LAFanomalie = !this.LAFanomalie;
    this.debutLAF = false;
    this.LAFanomalieAnterieur = true;
    this.LAFanomaliePosterieur = false;
    window.scrollTo(0, 0)
  }
  showLAFanomalieAnterieur() {
    this.LAFanomalieAnterieur = !this.LAFanomalieAnterieur;
  }
  showLAFanomaliePosterieur() {
    this.LAFanomaliePosterieur = !this.LAFanomaliePosterieur;
  }

  //------------------------------------------------------------------- segement posterieur
  //-------------------------------- BUT/Shirmer
  //  BUT/SHIRMER
  onChangebutODSeconde() {
    if (this.examLaf.laf.butShirmer.oielDroit.secondes <= 0) {
      this.examLaf.laf.butShirmer.oielDroit.secondes = 0
    }
    this.objecttoSend.butOD = this.examLaf.laf.butShirmer.oielDroit.secondes
    this.objecttoSend.source = "butOD"

    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-butShirmer", 'value': this.examLaf.laf.butShirmer });
  }
  onChangebutOGSeconde() {
    if (this.examLaf.laf.butShirmer.oielGauche.secondes <= 0) {
      this.examLaf.laf.butShirmer.oielGauche.secondes = 0
    }
    this.objecttoSend.butOG = this.examLaf.laf.butShirmer.oielGauche.secondes
    this.objecttoSend.source = "butOG"
    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-butShirmer", 'value': this.examLaf.laf.butShirmer });
  }
  onChangebutODmm() {
    if (this.examLaf.laf.butShirmer.oielDroit.mm <= 0) {
      this.examLaf.laf.butShirmer.oielDroit.mm = 0
    }
    this.objecttoSend.shirmerOD = this.examLaf.laf.butShirmer.oielDroit.mm
    this.objecttoSend.source = "shirmerOD"
    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-butShirmer", 'value': this.examLaf.laf.butShirmer });
  }
  onChangebutOGmm() {
    if (this.examLaf.laf.butShirmer.oielGauche.mm <= 0) {
      this.examLaf.laf.butShirmer.oielGauche.mm = 0
    }
    this.objecttoSend.shirmerOG = this.examLaf.laf.butShirmer.oielGauche.mm
    this.objecttoSend.source = "shirmerOG"
    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-butShirmer", 'value': this.examLaf.laf.butShirmer });
  }

  //-------------------------------- TO/AIC
  //  TO/AIC
  onChangetoODmm() {
    if (this.examLaf.laf.toAic.oielDroit.mm <= 0) {
      this.examLaf.laf.toAic.oielDroit.mm = 0
    }
    this.objecttoSend.TOOD = this.examLaf.laf.toAic.oielDroit.mm
    this.objecttoSend.source = "TOOD"
    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-toAic", 'value': this.examLaf.laf.toAic });
  }
  onChangetoOGmm() {
    if (this.examLaf.laf.toAic.oielGauche.mm <= 0) {
      this.examLaf.laf.toAic.oielGauche.mm = 0
    }
    this.objecttoSend.TOOG = this.examLaf.laf.toAic.oielGauche.mm
    this.objecttoSend.source = "TOOG"
    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-toAic", 'value': this.examLaf.laf.toAic });

  }
  //  ----------------------------------pachymétrie
  onchangePachymetrieOD() {
    if (this.examLaf.laf.pachymtrie.oielDroit.value < 0) {
      this.examLaf.laf.pachymtrie.oielDroit.value = 0
    }
    this.objecttoSend.pachymtrieOD = this.examLaf.laf.pachymtrie.oielDroit.value
    this.objecttoSend.source = "pachymtrieOD"
    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-Pachymetrie", 'value': this.examLaf.laf.pachymtrie });
  }
  onchangePachymetrieOG() {
    if (this.examLaf.laf.pachymtrie.oielGauche.value < 0) {
      this.examLaf.laf.pachymtrie.oielGauche.value = 0
    }
    this.objecttoSend.pachymtrieOG = this.examLaf.laf.pachymtrie.oielGauche.value
    this.objecttoSend.source = "pachymtrieOG"
    this.sharedDataService.updateData(this.objecttoSend);
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-Pachymetrie", 'value': this.examLaf.laf.pachymtrie });
  }

}
