import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable} from 'rxjs';
import {redirectNotAuthenticated } from 'config/API_CONFIG';

import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  medecin: any;
  secretaire:any;
  constructor(private router: Router, private authService: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(!localStorage.getItem('id_token')){
        localStorage.clear()
        window.location.href = redirectNotAuthenticated
        return false;
    }
    if(!localStorage.getItem('medecin') && !localStorage.getItem('secretaire')){
      localStorage.clear()
      window.location.href = redirectNotAuthenticated
      return false;
    }
    if(localStorage.getItem('medecin') && localStorage.getItem('secretaire')){
      localStorage.clear()
      window.location.href = redirectNotAuthenticated
      return false;
    }
    if(localStorage.getItem('medecin')){
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin) {
        localStorage.clear()
        window.location.href = redirectNotAuthenticated
        return false;
        }
        if (this.medecin && this.medecin.specialite != 'Ophtalmologiste' && this.medecin.specialite != 'Ophtalmologie'){
        localStorage.clear()
        window.location.href = redirectNotAuthenticated
        return false;
        }
        // if (this.medecin && this.medecin.specialite != 'Ophtalmologiste') 
        if (this.medecin && !this.medecin.specialite ) {
            this.router.navigate(["medecin/patients-liste"]);
            return false;
        }
        return true
      },
        err => {
            return false
        });
    }else{
      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire) {
        localStorage.clear()
        window.location.href = redirectNotAuthenticated
        return false;
        }
        if (this.secretaire && this.secretaire.specialite != 'Ophtalmologiste' && this.secretaire.specialite != 'Ophtalmologie'){
        localStorage.clear()
        window.location.href = redirectNotAuthenticated
        return false;
        }
        // if (this.medecin && this.medecin.specialite != 'Ophtalmologiste') 
        if (this.secretaire && !this.secretaire.specialite ) {
            this.router.navigate(["medecin/patients-liste"]);
            return false;
        }
        return true
      },
        err => {
            return false
        });
    } 
    return true
  }
}
