<div id="divToExport" *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'lunettes') > -1 &&medcin">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
    <style>
        .bold-line {
            border-top: 3px solid;
        }

        .letter-spacing {
            letter-spacing: 3px;
        }

        ul {
            list-style-type: none;
        }

        .main-contenu {
            padding-left: 3.2rem !important;
            height: 800px;
        }

        .contenu {
            font-size: 1rem;
            font-weight: 600;
        }

        #footer-cont {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            height: 150px;
        }

        .header {
            height: 200px;
        }

        .flex-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100vh;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    </style>
    <div class="flex-wrapper">
        <!-- En-tête -->
        <app-header-template></app-header-template>
        <!-- Date & informations -->
        <div class=" container mt-4 ml-7 main-content" id="main-cont">
            <h2 class="text-center" [style.color]="medcin.colorTemplates"><strong>Ordonnance de lunettes </strong></h2>
            <br>
            <p class="contenu" *ngIf="myExamen && myExamen.patient">Madame/Monsieur
                {{myExamen.patient.nom}} {{myExamen.patient.prenom}}</p>
            <p class="contenu">Date
                {{today | date:'dd/MM/yyyy'}}
            </p>
            <!-- vision de loin et vision de prés -->
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="row align-items-center">
                                <div class="col-lg-3 text-center">
                                    <label class="contenu mt-5">Vision de loin</label>
                                </div>
                                <div class="col-lg-9">
                                    <div class="row align-items-center">
                                        <div class="col-lg-2 text-center">
                                            <label class="contenu">O.D</label>
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <label class="contenu">Sphère</label>
                                            <input type="text" value="{{myExamen.foundImpression.deLoin.oielDroit.sphere}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <label class="contenu">Cylindre</label>
                                            <input type="text" value="{{myExamen.foundImpression.deLoin.oielDroit.cylindre}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center">
                                            <label class="contenu">Axe</label>
                                            <input type="text" value="{{myExamen.foundImpression.deLoin.oielDroit.axe}}" class="form-control">
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 align-items-center">
                        <div class="col-lg-12">
                            <div class="row align-items-center">
                                <div class="col-lg-3 text-center">
                                </div>
                                <div class="col-lg-9">
                                    <div class="row align-items-center">
                                        <div class="col-lg-2 text-center">
                                            <label class="contenu">O.G</label>
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <input type="text" value="{{myExamen.foundImpression.deLoin.oielGauche.sphere}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <input type="text" value="{{myExamen.foundImpression.deLoin.oielGauche.cylindre}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center">
                                            <input type="text" value="{{myExamen.foundImpression.deLoin.oielGauche.axe}}" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center mt-3">
                <div class="col-lg-12">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="row align-items-center">
                                <div class="col-lg-3 text-center">
                                    <label class="contenu mt-5">Vision de près</label>
                                </div>
                                <div class="col-lg-9">
                                    <div class="row align-items-center">
                                        <div class="col-lg-2 text-center">
                                            <label class="contenu">O.D</label>
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <input type="text" value="{{myExamen.foundImpression.dePres.oielDroit.sphere}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <input type="text" value="{{myExamen.foundImpression.dePres.oielDroit.cylindre}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center">
                                            <input type="text" value="{{myExamen.foundImpression.dePres.oielDroit.axe}}" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 align-items-center">
                        <div class="col-lg-12">
                            <div class="row align-items-center">
                                <div class="col-lg-3 text-center">
                                </div>
                                <div class="col-lg-9">
                                    <div class="row align-items-center">
                                        <div class="col-lg-2 text-center">
                                            <label class="contenu">O.G</label>
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <input type="text" value="{{myExamen.foundImpression.dePres.oielGauche.sphere}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center ">
                                            <input type="text" value="{{myExamen.foundImpression.dePres.oielGauche.cylindre}}" class="form-control">
                                        </div>
                                        <div class="col-lg-3 text-center">
                                            <input type="text" value="{{myExamen.foundImpression.dePres.oielGauche.axe}}" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-lg-12">
                    <div class="row align-items-center">
                        <div class="col-lg-3 text-center">
                            <label class="contenu">Marques des verres:</label>
                        </div>
                        <div class="col-lg-9">
                            <div class="row align-items-center">
                                <div class="col">
                                    <label class="contenu">{{myExamen.foundImpression.marqueVerre}}</label>
                                    <input type="checkbox" id="marque1" disabled checked>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 text-center">
                            <label class="contenu"></label>
                        </div>
                        <div class="col-lg-9">
                            <div class="row align-items-center">
                                <div class="col" *ngFor="let type of myExamen.foundImpression.typeVerre">
                                    <label class="contenu">{{type}}</label>
                                    <input type="checkbox" id="marque1" checked disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 text-center">
                            <label class="contenu"></label>
                        </div>
                        <div class="col-lg-9">
                            <div class="row align-items-center">
                                <div class="col" *ngFor="let fil of myExamen.foundImpression.filtre">
                                    <label class="contenu">{{fil}}</label>
                                    <input type="checkbox" id="marque1" checked disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Pied de page  -->
        <app-footer-template></app-footer-template>

        <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    </div>
</div>