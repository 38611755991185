import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-chambre-anterieure',
  templateUrl: './chambre-anterieure.component.html',
  styleUrls: ['./chambre-anterieure.component.css', '../laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class ChambreAnterieureComponent implements OnInit {
  // ------------------------------------------------------chambre anterieur
  chambreAnt = false;
  chambreAntListLeft = [
    "Calme et profonde",
    "Tyndall CELLULAIRE",
    "Tyndall proteique",
    "Hémorragie sous conjonctivale",
    "Hypopion",
    "Hyphema",
    "masse cristallinienne",
    "récession",
    "cyclodialyse",
    "Signe du limbe +",
    "mèche de vitre",
    "pigments",
    "ICA",
    "CA étroite",
    "corps etranger",
    "IP",
    "test de van herick",
  ];
  chambreAntListRight = [
    "Calme et profonde",
    "Tyndall CELLULAIRE",
    "Tyndall proteique",
    "Hémorragie sous conjonctivale",
    "Hypopion",
    "Hyphema",
    "masse cristallinienne",
    "récession",
    "cyclodialyse",
    "Signe du limbe +",
    "mèche de vitre",
    "pigments",
    "ICA",
    "CA étroite",
    "corps etranger",
    "IP",
    "test de van herick",
  ];

  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;

  //!----- anomalies by a doctor
  // Chambre antérieure : Calme et profonde
  anomaliesByDoctorChambre: any[] = []
  oldlistAnomaliesChambre = this.chambreAntListLeft
  newAnomalieChambre = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreChambre = false
  //end of Show More Variables
  displayChambre = true
  // send object
  objecttoSend = {
    chambreOD: {},
    chambreOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }

  isToastVisible = false
  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-chambreAnt').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-chambreanterieurealmeetprofonde", 'value': this.examLaf.laf.chambreanterieurealmeetprofonde });
  }


  showMoreChambreSection() {
    this.showMoreChambre = !this.showMoreChambre
  }

  configureDataExamen(idPatient) {
    // !Historique LAF
    // this.examenService.getInspectionHistoriques(idPatient,"laf").subscribe((data:any) => {
    //  this.oldListConsultations=data
    //  },
    //    err => {
    //      return false;
    //    });
    //! Chambre antérieure : Calme et profonde
    this.anomalieService.getAnomalieByDoctor("laf-chambreAnt").subscribe(data => {
      this.anomaliesByDoctorChambre = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }

  displayAndHideChambre() {
    this.displayChambre = !this.displayChambre
  }
  //-------------------------------------------------------------------- chambre antérieur
  showchambreAnt() {
    this.examLaf.laf.chambreanterieurealmeetprofonde.present = false;
  }

  addRemoveFromchambreAntRight(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present = false
    switch (value) {
      case 'Calme et profonde': this.setAllValueToFlase(this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit); this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present = true
        break
      case 'Tyndall cellulaire': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.present
        break
      case 'Tyndall proteique': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.present
        break
      case 'Hémorragie sous conjonctivale': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.present
        break
      case 'Hypopion': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.present
        break
      case 'Hyphema': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.present
        break
      case 'Masse cristallinienne': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.present
        break
      case 'Récession': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.recession.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.recession.present
        break
      case 'Cyclodialyse': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.present
        break
      case 'Signe du limbe +': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.present
        break
      case 'Mèche de vitre': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.present
        break
      case 'Pigments': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.present
        break
      case 'ICA': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.present
        break
      case 'CA étroite': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.present
        break
      case 'Corps étranger': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.present
        break
      case 'IP': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.IP.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.IP.present
        break
      case 'Test de van herick': this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.present
        break
    }
    this.setChambreToTrue(this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit)
    this.objecttoSend.chambreOD = this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit
    this.objecttoSend.source = "chambreOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromchambreAntLeft(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present = false
    switch (value) {
      case 'Calme et profonde': this.setAllValueToFlase(this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche); this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present = true
        break
      case 'Tyndall cellulaire': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.present
        break
      case 'Tyndall proteique': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.present
        break
      case 'Hémorragie sous conjonctivale': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.present
        break
      case 'Hypopion': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.present
        break
      case 'Hyphema': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.present
        break
      case 'Masse cristallinienne': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.present
        break
      case 'Récession': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.recession.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.recession.present
        break
      case 'Cyclodialyse': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.present
        break
      case 'Signe du limbe +': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.present
        break
      case 'Mèche de vitre': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.present
        break
      case 'Pigments': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.present
        break
      case 'ICA': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.present
        break
      case 'CA étroite': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.present
        break
      case 'Corps étranger': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.present
        break
      case 'IP': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.IP.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.IP.present
        break
      case 'Test de van herick': this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.present = !this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.present
        console.log("hello")
        break
    }
    this.setChambreToTrue(this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche)
    this.objecttoSend.chambreOG = this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche
    this.objecttoSend.source = "chambreOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }

  selecttyndallcellulaireRight(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.valeur = value
  }
  selecttyndallcellulaireLeft(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.valeur = value
  }
  selecttyndallproteiqueRight(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.valeur = value
  }
  selecttyndallproteiqueLeft(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.valeur = value
  }
  selecthypopionRight(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.valeur = value
  }
  selecthypopionLeft(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.valeur = value
  }
  selectCAetroiteRight(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.testdevanherick = value
  }
  selectCAetroiteLeft(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.testdevanherick = value
  }
  selectIPRight(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.IP.valeur = value
  }
  selectIPLeft(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.IP.valeur = value
  }
  selecthyphemaRight(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.valeur = value
  }
  selecthyphemaLeft(value) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.valeur = value
  }





  //!--------------------Chambre antérieure : Calme et profonde
  addNewAnomalyChambre(event) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,

          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorChambre?.length > 4) {
      showToast("Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieChambre = "";
      return false;
    }

    const value = event?.target?.value;
    if (value && value.length > 1 && value.length < 100) {
      const anomalyExists = this.anomaliesByDoctorChambre.some(x => x.titre?.toLowerCase() === value.toLowerCase());
      const oldAnomalyExists = this.oldlistAnomaliesChambre.some(x => x.toLowerCase() === value.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          "titre": value[0].toUpperCase() + value.slice(1),
          "description": "",
          "zone": "laf-chambreAnt"
        }).subscribe((data) => {
          this.newAnomalieChambre = "";
          this.anomaliesByDoctorChambre.push(data);
          showToast('Bien enregistré', '#386641', '#386641', '#6a994e', '#dde5b6');
        });
        return true;
      } else {
        showToast("L'anomalie existe dans la liste", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
        this.newAnomalieChambre = "";
        return false;
      }
    } else {
      showToast('Merci de saisir au moins 2 caractères', '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieChambre = "";
      return false;
    }
  }

  // Right Chambre
  addRemoveFromChambreRightNew(value, id) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present = false
    let truefalseexist = this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.chambreOD = this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit
      this.objecttoSend.source = "chambreOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies = this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.chambreOD = this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit
      this.objecttoSend.source = "chambreOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setChambreToTrue(this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit)
      return true;
    }

  }
  checkExistAnomalieChambreRight(id) {
    return this.examLaf.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Chambre dyna
  addRemoveFromChambreLeftNew(value, id) {
    this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present = false
    let truefalseexist = this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.chambreOG = this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche
      this.objecttoSend.source = "chambreOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies = this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.chambreOG = this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche
      this.objecttoSend.source = "chambreOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setChambreToTrue(this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche)
      return true;
    }

  }
  checkExistAnomalieChambreLeft(id) {
    return this.examLaf.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !-----------------end of Chambre antérieure : Calme et profonde
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-chambreAnt') {
        this.anomaliesByDoctorChambre.splice(this.anomaliesByDoctorChambre.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    })
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }

  // Chambre ant
  setChambreToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "calmeEtProfonde") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.calmeEtProfonde.present = true;
    }
    return myObject
  }
}