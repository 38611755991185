import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { API_CONFIG } from '../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../config/API_CONFIG';
@Injectable()
export class SecretaireService {
  constructor(private http: HttpClient) {
  }

  //get secretaires qui travaillent chez un médecin bien specifié
  getSecretairesByMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.secretaire) + '/idMedecin/' + id, { headers: headers })
      
  }
  //save secretaire
  registerSecretaire(secretaire) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.secretaire), (secretaire), { headers: headers })
      
  }

  //update secretaire
  updateSecretaire(newSecretaire) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.secretaire), (newSecretaire), { headers: headers })
      
  }
  getSecretairesById(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.secretaire) + '/' + id, { headers: headers })
      
  }
  //verifier l'ancien mot de passe d'une secretaire

  verifyPassword(id, secretaire) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.secretaire) + '/verifiedPassword/' + id, (secretaire), { headers: headers })
      

  }

  editProfil(secretaire) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.secretaire) + '/password/' + secretaire._id, (secretaire), { headers: headers })
      
  }
  //verifier  email d'une secrétaire est utilisé ou non
  verifyEmail(secretaire) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.secretaire) + '/verifiedEmail', (secretaire), { headers: headers })
      
  }
  verifytokenAuthentification(medecin, token) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.secretaire) + '/authentificate/' + token, (medecin), { headers: headers })
      
  }
  // verify if token authentification is valide
  verifytokenAuthentificationValidation(token){
      var headers = new HttpHeaders();
      return this.http.get(_getURL(API_CONFIG.secretaire) + '/verify/token/validation/authentification/' + token,{ headers: headers })
        
  }
  getSecretaireByID(id){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.secretaire) + '/' + id, { headers: headers })
      
  }
  getAllSecretairesByMedecin(){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.secretaire) + '/all/by/medecin/connected', { headers: headers })
      
  }
}
