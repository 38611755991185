import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

//import { tokenNotExpired } from 'angular2-jwt';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class AuthService {
  authToken: any;
  patient: any;
  medecin: any;
  user: any;
  secretaire: any;
  constructor(private http: HttpClient) {
  }
  //authentication d'un patient
  authenticatePatient(patient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.authenticate), patient, { headers: headers })
      
  }
  //get medecin by id
  getMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/' + id, { headers: headers })
      
  }
  //authentication d'un medecin
  authenticateMedecin(medecin) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.authenticateMedecin), medecin, { headers: headers })
      
  }
  authMede(medecin) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.checkValidation), medecin, { headers: headers })
      
  }
  //get profile patient
  getProfileMedecin() {
    this.loadToken();
    const headers = new HttpHeaders({
      'Authorization': this.authToken,
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.profilMedecin), { headers: headers })
      
  }
  getProfileSecretaire() {
    this.loadToken();
    const headers = new HttpHeaders({
      'Authorization': this.authToken,
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.profilSecretaire), { headers: headers })
      
  }
  

  // get a medecin by its id
  getMedecinById(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/' + id, { headers: headers })
      
  }
  loadToken() {
    const token = JSON.parse(localStorage.getItem('id_token'));
    this.authToken = token;
  }
  loggedIn() {
    // return tokenNotExpired();
    return true;
  }
  // get patient with his email
  getPatient(patient) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.patientByEmail), patient, { headers: headers })
      
  }
  //save data in localstorage:token+patient data
  storePatientData(token, patient) {
    localStorage.setItem('id_token', token);
    localStorage.setItem('patient', patient);
    this.authToken = token;
    this.patient = patient;
  }

  //save data in localstorage:token+medecin data
  storeMedecinData(token, medecin) {
    localStorage.setItem('id_token', token);
    localStorage.setItem('medecin', medecin);
    this.authToken = token;
    this.medecin = medecin;
  }
  // save data in localStorage: token+secretaire data
  storeSecretaireData(token, secretaire) {
    localStorage.setItem('id_token', token);
    localStorage.setItem('secretaire', secretaire);
    this.authToken = token;
    this.secretaire = secretaire;
  }
  //logout
  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  //logout medecin
  logoutMedecin() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

}
