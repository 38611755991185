export const titreConsultations = [
    {
      specialite: 'Généraliste',
      titres: [
        'Allergie',
        'Attestation médicale',
        'Consultation du samedi',
        'Constitution dossier SPF ou assurances',
        'Consultation en semaine',
        'Consultation forem',
        'Consultation imprévue',
        'Consultation générale',
        'Consultation générale et d\'orthodontie pour enfants et adultes.',
        'Consultation psychologique',
        'Consultations de suivi d\'arrêt du tabac (2e à la 8e séance)',
        'Contrôle annuel',
        'Contrôle d\'orthodontie Personnalisé',
        'Contrôle post-intervention',
        'Cryothérapie',
        'Dépose appareil fixe, polissage et consignes',
        'EXTRACTION',
        'Électrocardiogramme',
        'Facettes',
        'Implants',
        'Infiltrations, petite chirurgie',
        'Médecin du sport ',
        'Médecine préventive',
        'Pathologies aigues',
        'Pose appareil amovible',
        'Première consultation d\'aide à l\'arrêt du tabac',
        'Première visite',
        'Prise de sang',
        'Rendez-vous délégués médicaux',
        'Renouvellement de traitement',
        'Suivi pathologies chroniques',
        'Surveillance de la glycémie',
        'Traitement des caries',
        'Vaccination',
        'Visite préliminaire à appareillage',
        'Visite à domicile',
        'Autre',
      ]
},
{
  specialite: 'Dentiste',
  titres: [
    'Bilan bucco-dentaire',
    'Blanchiment dentaire',
    'Bridges',
    'Consultation générale',
    'Couronnes',
    'Couronnes sur implants',
    'Détartrage',
    'Dévitalisation',
    'Obturation / Plombage Dentaire',
    'Prothèse',
    'Prothèses fixes et amovibles',
    'Radiographie',
    'Remplacement plombage',
    'Urgence dentaire',
    'Soins dentaires enfants/adultes',
    'Traitement des caries',
    'Extraction dentaire',
    'Prothèse pedo ( Interception)',
    'Aligneur',
    'Douleur dentaire',
    'Autre',
  ]
},
{
  specialite: 'Psychiatre',
  titres: [
    'Dépression',
    'Névrose',
    'Psychanalyse',
    'Thérapie de Couple',
    'Thérapie individuelle',
    'Troubles Mentaux',
    'Autre',
  ]
},
{
  specialite: 'Pédiatre',
  titres: [
    'Consultation générale',
    'Consultation générale - Première visite',
    'Consultation nouveau né - Première visite',
    'Consultation Nouveau-né',
    'Troubles de l\'alimentation',
    'Urgence',
    'Autre',
  ]
}
]


export const allTitreConsultations = [
  'Allergie',
  'Attestation médicale',
  'Bilan bucco-dentaire',
  'Blanchiment dentaire',
  'Bridges',
  'Consultation du samedi',
  'Constitution dossier SPF ou assurances',
  'Consultation en semaine',
  'Consultation forem',
  'Consultation imprévue',
  'Consultation générale',
  'Consultation générale et d\'orthodontie pour enfants et adultes.',
  'Consultation psychologique',
  'Consultations de suivi d\'arrêt du tabac (2e à la 8e séance)',
  'Contrôle annuel',
  'Contrôle d\'orthodontie Personnalisé',
  'Contrôle post-intervention',
  'Couronnes',
  'Cryothérapie',
  'Dépose appareil fixe, polissage et consignes',
  'Détartrage',
  'Dévitalisation',
  'EXTRACTION',
  'Électrocardiogramme',
  'Facettes',
  'Implants',
  'Infiltrations, petite chirurgie',
  'Médecin du sport ',
  'Médecine préventive',
  'Obturation / Plombage Dentaire',
  'Pathologies aigues',
  'Pose appareil amovible',
  'Première consultation d\'aide à l\'arrêt du tabac',
  'Première visite',
  'Prise de sang',
  'Prothèse',
  'Radiographie',
  'Remplacement plombage',
  'Rendez-vous délégués médicaux',
  'Renouvellement de traitement',
  'Suivi pathologies chroniques',
  'Surveillance de la glycémie',
  'Traitement des caries',
  'Urgence dentaire',
  'Vaccination',
  'Visite préliminaire à appareillage',
  'Visite à domicile',
  'Autre',

]
