import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-angle-irido',
  templateUrl: './angle-irido.component.html',
  styleUrls: ['./angle-irido.component.css', '../laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class AngleIridoComponent implements OnInit {
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  //!----- anomalies by a doctor

  //Angle irido cornéen: Ouvert sur 360 degrés
  anomaliesByDoctorAngle: any[] = []
  oldlistAnomaliesAngle = ["Ouvert sur 360 degrés", "Apposition", "Corps étranger", "Fermé", "Hyphema", "Hypopion", "Occludable", "Pigmenté", "Rubéose", "Synéchie",]
  newAnomalieAngle = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreAngle = false
  //end of Show More Variables
  displayAngle = false

  // send object
  objecttoSend = {
    angleIridoOD: {},
    angleIridoOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false
  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }
  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, "laf-angleIridoOuvert").subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-angleiridocornee", 'value': this.examLaf.laf.angleiridocornee });
  }

  configureDataExamen(idPatient) {
    // !Historique LAF
    // this.examenService.getInspectionHistoriques(idPatient,"laf").subscribe((data:any) => {
    //  this.oldListConsultations=data
    //  },
    //    err => {
    //      return false;
    //    });
    // !Angle irido cornéen: Ouvert sur 360 degrés
    this.anomalieService.getAnomalieByDoctor("laf-angleIridoOuvert").subscribe(data => {
      this.anomaliesByDoctorAngle = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }


  showMoreAngleSection() {
    this.showMoreAngle = !this.showMoreAngle
  }
  displayAndHideAngle() {
    this.displayAngle = !this.displayAngle
  }

  // -------------------------------------------------------- angle Irido
  changeangleiridocorneenAE() {
    this.examLaf.laf.angleiridocornee.present = !this.examLaf.laf.angleiridocornee.present
  }
  addRemoveFromAngleIridoRight(value) {
    this.examLaf.laf.angleiridocornee.oielDroit.ouvertSur360.present = false
    switch (value) {
      case 'Ouvert sur 360 degrés': this.setAllValueToFlase(this.examLaf.laf.angleiridocornee.oielDroit); this.examLaf.laf.angleiridocornee.oielDroit.ouvertSur360.present = true
        break
      case 'Fermé': this.examLaf.laf.angleiridocornee.oielDroit.ferme.present = !this.examLaf.laf.angleiridocornee.oielDroit.ferme.present
        break
      case 'Occludable': this.examLaf.laf.angleiridocornee.oielDroit.occludable.present = !this.examLaf.laf.angleiridocornee.oielDroit.occludable.present
        break
      case 'Pigmenté': this.examLaf.laf.angleiridocornee.oielDroit.pigmente.present = !this.examLaf.laf.angleiridocornee.oielDroit.pigmente.present
        break
      case 'Synéchie': this.examLaf.laf.angleiridocornee.oielDroit.synechie.present = !this.examLaf.laf.angleiridocornee.oielDroit.synechie.present
        break
      case 'Hyphema': this.examLaf.laf.angleiridocornee.oielDroit.hyphema.present = !this.examLaf.laf.angleiridocornee.oielDroit.hyphema.present
        break
      case 'Rubéose': this.examLaf.laf.angleiridocornee.oielDroit.rubeose.present = !this.examLaf.laf.angleiridocornee.oielDroit.rubeose.present
        break
      case 'corps étranger': this.examLaf.laf.angleiridocornee.oielDroit.corpsetranger.present = !this.examLaf.laf.angleiridocornee.oielDroit.corpsetranger.present
        break
      case 'Hypopion': this.examLaf.laf.angleiridocornee.oielDroit.hypopion.present = !this.examLaf.laf.angleiridocornee.oielDroit.hypopion.present
        break
      case 'Apposition': this.examLaf.laf.angleiridocornee.oielDroit.apposition.present = !this.examLaf.laf.angleiridocornee.oielDroit.apposition.present
        break
    }
    this.setAngleToTrue(this.examLaf.laf.angleiridocornee.oielDroit)
    this.objecttoSend.angleIridoOD = this.examLaf.laf.angleiridocornee.oielDroit
    this.objecttoSend.source = "angleIridoOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromAngleIridoLeft(value) {
    this.examLaf.laf.angleiridocornee.oielGauche.ouvertSur360.present = false
    switch (value) {
      case 'Ouvert sur 360 degrés': this.setAllValueToFlase(this.examLaf.laf.angleiridocornee.oielGauche); this.examLaf.laf.angleiridocornee.oielGauche.ouvertSur360.present = true
        break
      case 'Fermé': this.examLaf.laf.angleiridocornee.oielGauche.ferme.present = !this.examLaf.laf.angleiridocornee.oielGauche.ferme.present
        break
      case 'Occludable': this.examLaf.laf.angleiridocornee.oielGauche.occludable.present = !this.examLaf.laf.angleiridocornee.oielGauche.occludable.present
        break
      case 'Pigmenté': this.examLaf.laf.angleiridocornee.oielGauche.pigmente.present = !this.examLaf.laf.angleiridocornee.oielGauche.pigmente.present
        break
      case 'Synéchie': this.examLaf.laf.angleiridocornee.oielGauche.synechie.present = !this.examLaf.laf.angleiridocornee.oielGauche.synechie.present
        break
      case 'Hyphema': this.examLaf.laf.angleiridocornee.oielGauche.hyphema.present = !this.examLaf.laf.angleiridocornee.oielGauche.hyphema.present
        break
      case 'Rubéose': this.examLaf.laf.angleiridocornee.oielGauche.rubeose.present = !this.examLaf.laf.angleiridocornee.oielGauche.rubeose.present
        break
      case 'corps étranger': this.examLaf.laf.angleiridocornee.oielGauche.corpsetranger.present = !this.examLaf.laf.angleiridocornee.oielGauche.corpsetranger.present
        break
      case 'Hypopion': this.examLaf.laf.angleiridocornee.oielGauche.hypopion.present = !this.examLaf.laf.angleiridocornee.oielGauche.hypopion.present
        break
      case 'Apposition': this.examLaf.laf.angleiridocornee.oielGauche.apposition.present = !this.examLaf.laf.angleiridocornee.oielGauche.apposition.present
        break
    }
    this.setAngleToTrue(this.examLaf.laf.angleiridocornee.oielGauche)
    this.objecttoSend.angleIridoOG = this.examLaf.laf.angleiridocornee.oielGauche
    this.objecttoSend.source = "angleIridoOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }

  selectangleiridocorneeRight(value) {
    this.examLaf.laf.angleiridocornee.oielDroit.occludable.valeur = value
  }
  selectangleiridocorneeLeft(value) {
    this.examLaf.laf.angleiridocornee.oielGauche.occludable.valeur = value
  }

  //!--------------------Angle irido cornéen: Ouvert sur 360 degrés
  addNewAnomalyAngle(event) {
    const maxAnomalies = 5;
    const inputValue = event?.target?.value;

    const showToast = (message, messageColor, backgroundColor, progressBarColor,) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        const self = this;
        this.iziToast.show({
          message,
          messageColor,
          titleColor: messageColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: function () {
            self.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorAngle && this.anomaliesByDoctorAngle.length >= maxAnomalies) {
      showToast(
        "Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies",
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieAngle = "";
      return false;
    }

    if (inputValue && inputValue.length > 1 && inputValue.length < 100) {
      const anomalyExists = this.anomaliesByDoctorAngle.some(x => x.titre?.toLowerCase() === inputValue.toLowerCase());
      const oldAnomalyExists = this.oldlistAnomaliesAngle.some(x => x.toLowerCase() === inputValue.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          titre: inputValue[0].toUpperCase() + inputValue.slice(1),
          description: "",
          zone: "laf-angleIridoOuvert",
        }).subscribe((data) => {
          this.newAnomalieAngle = "";
          this.anomaliesByDoctorAngle.push(data);
          showToast(
            'Bien enregistré',
            '#386641',
            '#dde5b6',
            '#6a994e',
          );
        });
        return true;
      } else {
        showToast(
          "L'anomalie existe dans la liste",
          '#800f2f',
          '#ff8fa3',
          '#c9184a'
        );
        this.newAnomalieAngle = "";
        return false;
      }
    } else {
      showToast(
        'Merci de saisir au moins 2 caractères',
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieAngle = "";
      return false;
    }
  }

  // Right 
  addRemoveFromAngleRightNew(value, id) {
    this.examLaf.laf.angleiridocornee.oielDroit.ouvertSur360.present = false
    let truefalseexist = this.examLaf.laf.angleiridocornee.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.angleiridocornee.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.angleIridoOD = this.examLaf.laf.angleiridocornee.oielDroit
      this.objecttoSend.source = "angleIridoOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.angleiridocornee.oielDroit.allAnomalies = this.examLaf.laf.angleiridocornee.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.angleIridoOD = this.examLaf.laf.angleiridocornee.oielDroit
      this.objecttoSend.source = "angleIridoOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setAngleToTrue(this.examLaf.laf.angleiridocornee.oielDroit)
      return true;
    }

  }
  checkExistAnomalieAngleRight(id) {
    return this.examLaf.laf.angleiridocornee.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Cristallin dyna
  addRemoveFromAngleLeftNew(value, id) {
    this.examLaf.laf.angleiridocornee.oielGauche.ouvertSur360.present = false
    let truefalseexist = this.examLaf.laf.angleiridocornee.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.angleiridocornee.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.angleIridoOG = this.examLaf.laf.angleiridocornee.oielGauche
      this.objecttoSend.source = "angleIridoOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.angleiridocornee.oielGauche.allAnomalies = this.examLaf.laf.angleiridocornee.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.angleIridoOG = this.examLaf.laf.angleiridocornee.oielGauche
      this.objecttoSend.source = "angleIridoOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setAngleToTrue(this.examLaf.laf.angleiridocornee.oielGauche)
      return true;
    }

  }
  checkExistAnomalieAngleLeft(id) {
    return this.examLaf.laf.angleiridocornee.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of Angle irido cornéen: Ouvert sur 360 degrés
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-angleIridoOuvert') {
        this.anomaliesByDoctorAngle.splice(this.anomaliesByDoctorAngle.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }

  // angle Irido
  setAngleToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "ouvertSur360") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.ouvertSur360.present = true;
    }
    return myObject
  }


}
