import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SecretaireService } from 'src/app/services/profils/secretaire/secretaire.service';

@Component({
  selector: 'app-secretaire',
  templateUrl: './secretaire.component.html',
  styleUrls: ['../patient/list-patients/list-patients.component.css']
})
export class SecretaireComponent implements OnInit {
  secretaires: any;
  page: number = 1;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  count: number = 0;
  medecin: any;
  constructor(private secretaireService: SecretaireService, private authService: AuthService) { }
  ngOnInit(): void {
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (this.medecin) {
          this.secretaireService.getAllSecretairesByMedecin()
            .subscribe((secretaires: any[]) => {
              this.secretaires = secretaires;
            });
        }
      },
        err => {
          return false;
        });
    }

  }
  onTableDataChange(event: any) {
    this.page = event;

  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }
}
