import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms'
import { PatientService } from '../../../../services/profils/patient/patient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidateService } from '../../../../services/validate/validate.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { ValidationService } from '../../../../services/validate/validateError.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { villes } from '../../../pays/villes';
import { codepostale } from '../../../pays/villes';
import { professions } from '../../../pays/professions';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { MedecinService } from '../../../../services/profils/medecin/medecin.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { champsObligatoire } from 'src/app/services/constants/messages';
import { Ng2IzitoastService } from 'ng2-izitoast';
@Component({
  selector: 'app-update-patient',
  templateUrl: './update-patient.component.html',
  styleUrls: ['../patient/patient.component.scss']
})
export class UpdatePatientComponent implements OnInit {

  patients: any[] = [];
  form: any;
  patient: any;
  medecin: any;
  interval: any;
  messages: any[] = [];
  secretaire: any;
  erroNumeroDeFiche = false;
  showAutres = false;
  showOrg = false;
  private value: any = ['Athens'];
  public items: Array<any> = [];
  pays: any[] = []
  selectedpays = "Tunisie"
  selectedville = ''
  listePostal = [];
  listefiltrecode = ['2001', '2002', '2020', '2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058', '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094', '2095']
  listefiltrecodeEtab = ['2001', '2002', '2020', '2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058', '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094', '2095']
  listeVilles: any[] = [];
  isTunisie = true
  listefiltreville = [];
  selectedpostal = ''
  professions: any[] = []
  showProessionAutre = false
  showMyContainer = 1;
  medecins: any[] = [];
  public optionsss: any;
  optionss: any;
  sizeLimitOne = 5000000; //5MB
  filterMedecins: any[] = []
  mymedcins: Array<any> = [];
  specialiteSelected: any;
  mydate = new Date().toISOString().split('T')[0]
  showedValue: any;
  selectedMedecinn: any;
  listePostalEtablissement = [];
  showerrorligne = ""
  screenWidth: any
  ng2slectwidh: any
  //
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  apcis = [
    'Affections coronariennes et leurs complications', 'Affections hypophysaires', 'Affections surrénaliennes', 'Cardiopathies congénitales et valvulopathies',
    'Cirrhoses et insuffisance hépatique', 'Diabète insulinodépendant ou non insulinodépendant', 'Dysthyroïdies', 'Epilepsie', 'Glaucome', 'HTA sévère', 'Hépatites chroniques actives',
    'Insuffisance cardiaque et troubles du rythme', 'Insuffisance respiratoire chronique', 'Insuffisance rénale chronique', 'Les maladies inflammatoires chroniques de l\'intestin',
    'Maladie de Parkinson', 'Maladies auto-immunes', 'Mucoviscidose', 'Phlébites', 'Psychoses et névroses', 'Rhumatismes inflammatoires chroniques', 'Sclérose en plaques',
    'Tuberculose active', 'Tumeurs et hémopathies malignes'
  ]
  age: any;
  champsObligatoire = champsObligatoire
  constructor(private medecinService: MedecinService, private validateService: ValidateService, private router: Router, private patientService: PatientService,
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private responsiveService: ResponsiveService,
    private authService: AuthService, public iziToast: Ng2IzitoastService) {
    let params: any = this.activatedRoute.snapshot.params;
    this.professions = professions
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
      if (this.patient && this.patient.tel) {
        this.patient.tel = this.patient?.tel.replace(/\s/g, '').replace('+216', '')
      }
      if (this.patient && this.professions.indexOf(this.patient?.profession) === -1) {
        this.showProessionAutre = true;
        this.patient.autreProfession = this.patient?.profession
        this.patient.profession = "Autre"
      }
      if (this.patient?.adresse && this.patient?.adresse.pays === 'Tunisie' || this.patient?.adresse.pays === '') {
        this.isTunisie = true;
        this.listeVilles = villes.filter(x => x.pays == this.patient?.adresse.pays);
        this.listeVilles.forEach(index =>
          this.listefiltreville = index.ville);
      } else {
        this.selectedville = undefined
        this.selectedpostal = undefined
        this.isTunisie = false;
      }
    },
      err => {
        this.router.navigate(['/medecin/patients-liste'])
        return false;
      });

    this.form = new FormGroup({});
    this.form = this.formBuilder.group({
      identifiantMedivisto: [''],
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25), ValidationService.validateChamps]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25), ValidationService.validateChamps]],
      profession: ['', [Validators.minLength(3), Validators.maxLength(75), ValidationService.validateChamps]],
      autreProfession: ['', [Validators.minLength(3), Validators.maxLength(75), ValidationService.validateChamps]],
      dateAnniversaire: ['', [Validators.required, ValidationService.dateValidator]],
      numFiche: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25), ValidationService.validateChampsNumFiche]],
      statusFamilial: [''],
      groupeSanguin: [''],
      email: ['', [ValidationService.emailValidator(this.validateService), Validators.maxLength(50)]],
      tel: ['', [Validators.required]],
      sexe: ['', [Validators.required]],
      apci: this.form.addControl('apci', new FormControl('')),
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        nomEtablissement: ['', [Validators.maxLength(25), ValidationService.validateChamps]],
        email: ['', [ValidationService.emailValidator(this.validateService), Validators.maxLength(50)]],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: ['', [Validators.maxLength(25), ValidationService.validateChamps]],
          codePostal: ['', [ValidationService.numberValidator, Validators.maxLength(4)]],
          adresse: ['', Validators.maxLength(500)],
        }),
      }),
      adresse: this.formBuilder.group({
        adresse: ['', [Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [Validators.minLength(3), Validators.maxLength(25), ValidationService.validateChamps]],
        pays: ['Tunisie'],
        codePostal: ['', [ValidationService.numberValidator, Validators.maxLength(4)]],
      })

    });

  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    // Subscribe to screen width changes
    this.responsiveService.getScreenWidth().subscribe((width) => {
      this.screenWidth = width;
      if (this.screenWidth < 768) {
        // Handle small screens
        this.ng2slectwidh = 500
        return true;
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        // Handle medium screens
        this.ng2slectwidh = 1000
        return true;
      } else {
        // Handle large screens
        this.ng2slectwidh = 1400
        return true;
      }
    });

    this.optionsss = {
      multiple: true,
      maximumSelectionLength: 5
    }

    this.pays = pays.sort(tri);
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
      },
        err => {
          return false;
        });
    }

  }
  get f() { return this.form.controls; }
  onSelectville(selectedville) {
    this.listePostal = codepostale.filter(x => x.ville == selectedville);
    this.listePostal.forEach(index => this.listefiltrecode = index.code);
  }

  onSelectpays(selectedpays) {
    //selectedpays = this.patient.adresse.pays
    if (selectedpays === 'Tunisie' || selectedpays === '') {
      this.isTunisie = true;
      this.listeVilles = villes.filter(x => x.pays == selectedpays);
      this.listeVilles.forEach(index =>
        this.listefiltreville = index.ville);
    } else {
      this.selectedville = undefined
      this.selectedpostal = undefined
      this.form.controls['adresse'].controls['ville'].setValue('');
      this.form.controls['adresse'].controls['codePostal'].setValue('');
      this.isTunisie = false;
    }
  }
  //modifier un patient
  onUpdatePatient(patient) {
    // stop here if form is invalid

    if (this.patient.tel) {
      this.patient.tel = this.patient.tel.internationalNumber
    }
    if (!this.patient.nom || this.patient.nom.length < 3 || this.patient.nom.length > 25 || !this.patient.prenom || this.patient.prenom.length < 3 || this.patient.prenom.length > 25) {
      this.showerrorligne = "Merci de vérifier les champs 'nom et prénom' (minimum 3, maximum 25)"
      return false;
    }
    if (!this.patient.sexe) {
      this.showerrorligne = "Merci de vérifier le champ 'sexe'"
      return false;
    }
    if (!this.patient.dateAnniversaire || this.patient.dateAnniversaire > this.mydate) {
      this.showerrorligne = "Merci de vérifier le champ 'date de naissance'"
      return false;
    }
    this.showerrorligne = ""
    if (this.showProessionAutre) {
      patient.profession = patient.autreProfession
    }
    var today = new Date();
    var birthDate = new Date(this.patient.dateAnniversaire);
    this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age--;
    }
    this.patient.age = this.age;
    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return false;
    } else {
      this.patientService.editProfil(patient).subscribe((_patient: any) => {
        this.iziToast.show({
          message: 'Bien modifié',
          messageColor: '#386641',
          progressBarColor: '#6a994e',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: false,
        });
        this.router.navigate(['/medecin/consultation-liste', patient._id]);
      });
    }
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  public selected(value: any): void { }
  public removed(value: any): void { }
  public typed(value: any): void { }
  public refreshValue(value: any): void {
    this.value = value;
  }

  markFormGroupTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
