import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControleMessagesRoutingModule } from './controle-messages-routing.module';
import { MessageComponent } from '../message/message.component';

@NgModule({
  declarations: [MessageComponent],
  imports: [
    CommonModule,
    ControleMessagesRoutingModule
  ],
  exports: [
    MessageComponent
]
})
export class ControleMessagesModule { }
