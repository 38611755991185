import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-vitre',
  templateUrl: './vitre.component.html',
  styleUrls: ['./vitre.component.css', '../../laf/laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class VitreComponent implements OnInit {
  displayVitre = true
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  //!----- anomalies by a doctor

  //Vitre : Calme et acellulaire
  anomaliesByDoctorVitre: any[] = []
  oldlistAnomaliesVitre = ["Calme et acellulaire", "Banquise", "Brides", "Cordage", "Corps astéroïde", "Corps étrangers", "DPV", "Essaimage tumoral", "Fragment cristallinien", "Hématique", "Hémorragie prérétinienne", "Hémorragie vitréene",
    "Lacune",
    "Liquéfaction",
    "Masse cristallinienne",
    "Oeuf de fourmis",
    "Persistance du vitré primitif",
    "Pigmentaire",
    "Précipités en colier de perles",
    "Protéique",
    "Synchisis étincelant",
    "Tyndall cellulaire",
    "Voile vitréen",
  ]
  newAnomalieVitre = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreVitre = false
  //end of Show More Variables

  // send object
  objecttoSend = {
    vitreOD: {},
    vitreOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false

  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, "laf-vitre").subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-vitre", 'value': this.examLaf.laf.vitre });
  }
  configureDataExamen(idPatient) {
    // !Historique LAF
    this.examenService.getInspectionHistoriques(idPatient, "laf").subscribe((data: any) => {
      this.oldListConsultations = data
    },
      err => {
        return false;
      });
    // !Vitre : Calme et acellulaire
    this.anomalieService.getAnomalieByDoctor("laf-vitre").subscribe(data => {
      this.anomaliesByDoctorVitre = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }
  showMoreVitreSection() {
    this.showMoreVitre = !this.showMoreVitre
  }
  displayAndHideVitre() {
    this.displayVitre = !this.displayVitre
  }
  //-----------------------------------------------------------------------vitre section

  showsegmentPosterieurAE() {
    this.examLaf.laf.vitre.present = false
  }

  addRemoveFromVitreRight(value) {
    this.examLaf.laf.vitre.oielDroit.calmeEtAcellulaire.present = false
    switch (value) {
      case 'Calme et acellulaire': this.setAllValueToFlase(this.examLaf.laf.vitre.oielDroit); this.examLaf.laf.vitre.oielDroit.calmeEtAcellulaire.present = true
        break
      case 'Tyndall cellulaire': this.examLaf.laf.vitre.oielDroit.tyndallcellulaire.present = !this.examLaf.laf.vitre.oielDroit.tyndallcellulaire.present
        break
      case 'Protéique': this.examLaf.laf.vitre.oielDroit.proteique.present = !this.examLaf.laf.vitre.oielDroit.proteique.present
        break
      case 'Hématique': this.examLaf.laf.vitre.oielDroit.hematique.present = !this.examLaf.laf.vitre.oielDroit.hematique.present
        break
      case 'Hémorragie vitréene': this.examLaf.laf.vitre.oielDroit.hemorragievitreene.present = !this.examLaf.laf.vitre.oielDroit.hemorragievitreene.present
        break
      case 'Pigmentaire': this.examLaf.laf.vitre.oielDroit.pigmentaire.present = !this.examLaf.laf.vitre.oielDroit.pigmentaire.present
        break
      case 'Essaimage tumoral': this.examLaf.laf.vitre.oielDroit.essaimagetumoral.present = !this.examLaf.laf.vitre.oielDroit.essaimagetumoral.present
        break
      case 'Banquise': this.examLaf.laf.vitre.oielDroit.banquise.present = !this.examLaf.laf.vitre.oielDroit.banquise.present
        break
      case 'Oeuf de fourmis': this.examLaf.laf.vitre.oielDroit.oeufdefourmis.present = !this.examLaf.laf.vitre.oielDroit.oeufdefourmis.present
        break
      case 'DPV': this.examLaf.laf.vitre.oielDroit.DPV.present = !this.examLaf.laf.vitre.oielDroit.DPV.present
        break
      case 'Lacune': this.examLaf.laf.vitre.oielDroit.Lacune.present = !this.examLaf.laf.vitre.oielDroit.Lacune.present
        break
      case 'Masse cristallinienne': this.examLaf.laf.vitre.oielDroit.massecristallinienne.present = !this.examLaf.laf.vitre.oielDroit.massecristallinienne.present
        break
      case 'Persistance du vitré primitif': this.examLaf.laf.vitre.oielDroit.persistanceduvitreprimitif.present = !this.examLaf.laf.vitre.oielDroit.persistanceduvitreprimitif.present
        break
      case 'Corps étrangers': this.examLaf.laf.vitre.oielDroit.corpsetrangers.present = !this.examLaf.laf.vitre.oielDroit.corpsetrangers.present
        break
      case 'Fragment cristallinien': this.examLaf.laf.vitre.oielDroit.fragmentcristallinien.present = !this.examLaf.laf.vitre.oielDroit.fragmentcristallinien.present
        break
      case 'Voile vitréen': this.examLaf.laf.vitre.oielDroit.voilevitreen.present = !this.examLaf.laf.vitre.oielDroit.voilevitreen.present
        break
      case 'Cordage': this.examLaf.laf.vitre.oielDroit.cordage.present = !this.examLaf.laf.vitre.oielDroit.cordage.present
        break
      case 'Brides': this.examLaf.laf.vitre.oielDroit.brides.present = !this.examLaf.laf.vitre.oielDroit.brides.present
        break
      case 'Synchisis étincelant': this.examLaf.laf.vitre.oielDroit.synchesisetincelant.present = !this.examLaf.laf.vitre.oielDroit.synchesisetincelant.present
        break
      case 'Corps astéroïde': this.examLaf.laf.vitre.oielDroit.corpsasterode.present = !this.examLaf.laf.vitre.oielDroit.corpsasterode.present
        break
      case 'Hémorragie prérétinienne': this.examLaf.laf.vitre.oielDroit.hemorragiepreretinienne.present = !this.examLaf.laf.vitre.oielDroit.hemorragiepreretinienne.present
        break
      case 'Liquéfaction': this.examLaf.laf.vitre.oielDroit.liquefaction.present = !this.examLaf.laf.vitre.oielDroit.liquefaction.present
        break
      case 'Précipités en colier de perles': this.examLaf.laf.vitre.oielDroit.precipiteencolierdeperle.present = !this.examLaf.laf.vitre.oielDroit.precipiteencolierdeperle.present
        break
    }
    this.setVitreToTrue(this.examLaf.laf.vitre.oielDroit)
    this.objecttoSend.vitreOD = this.examLaf.laf.vitre.oielDroit
    this.objecttoSend.source = "vitreOD"
    this.sharedDataService.updateData(this.objecttoSend);

    return true;
  }
  addRemoveFromVitreLeft(value) {
    this.examLaf.laf.vitre.oielGauche.calmeEtAcellulaire.present = false
    switch (value) {
      case 'Calme et acellulaire': this.setAllValueToFlase(this.examLaf.laf.vitre.oielGauche); this.examLaf.laf.vitre.oielGauche.calmeEtAcellulaire.present = true
        break
      case 'Tyndall cellulaire': this.examLaf.laf.vitre.oielGauche.tyndallcellulaire.present = !this.examLaf.laf.vitre.oielGauche.tyndallcellulaire.present
        break
      case 'Protéique': this.examLaf.laf.vitre.oielGauche.proteique.present = !this.examLaf.laf.vitre.oielGauche.proteique.present
        break
      case 'Hématique': this.examLaf.laf.vitre.oielGauche.hematique.present = !this.examLaf.laf.vitre.oielGauche.hematique.present
        break
      case 'Hémorragie vitréene': this.examLaf.laf.vitre.oielGauche.hemorragievitreene.present = !this.examLaf.laf.vitre.oielGauche.hemorragievitreene.present
        break
      case 'Pigmentaire': this.examLaf.laf.vitre.oielGauche.pigmentaire.present = !this.examLaf.laf.vitre.oielGauche.pigmentaire.present
        break
      case 'Essaimage tumoral': this.examLaf.laf.vitre.oielGauche.essaimagetumoral.present = !this.examLaf.laf.vitre.oielGauche.essaimagetumoral.present
        break
      case 'Banquise': this.examLaf.laf.vitre.oielGauche.banquise.present = !this.examLaf.laf.vitre.oielGauche.banquise.present
        break
      case 'Oeuf de fourmis': this.examLaf.laf.vitre.oielGauche.oeufdefourmis.present = !this.examLaf.laf.vitre.oielGauche.oeufdefourmis.present
        break
      case 'DPV': this.examLaf.laf.vitre.oielGauche.DPV.present = !this.examLaf.laf.vitre.oielGauche.DPV.present
        break
      case 'Lacune': this.examLaf.laf.vitre.oielGauche.Lacune.present = !this.examLaf.laf.vitre.oielGauche.Lacune.present
        break
      case 'Masse cristallinienne': this.examLaf.laf.vitre.oielGauche.massecristallinienne.present = !this.examLaf.laf.vitre.oielGauche.massecristallinienne.present
        break
      case 'Persistance du vitré primitif': this.examLaf.laf.vitre.oielGauche.persistanceduvitreprimitif.present = !this.examLaf.laf.vitre.oielGauche.persistanceduvitreprimitif.present
        break
      case 'Corps étrangers': this.examLaf.laf.vitre.oielGauche.corpsetrangers.present = !this.examLaf.laf.vitre.oielGauche.corpsetrangers.present
        break
      case 'Fragment cristallinien': this.examLaf.laf.vitre.oielGauche.fragmentcristallinien.present = !this.examLaf.laf.vitre.oielGauche.fragmentcristallinien.present
        break
      case 'Voile vitréen': this.examLaf.laf.vitre.oielGauche.voilevitreen.present = !this.examLaf.laf.vitre.oielGauche.voilevitreen.present
        break
      case 'Cordage': this.examLaf.laf.vitre.oielGauche.cordage.present = !this.examLaf.laf.vitre.oielGauche.cordage.present
        break
      case 'Brides': this.examLaf.laf.vitre.oielGauche.brides.present = !this.examLaf.laf.vitre.oielGauche.brides.present
        break
      case 'Synchisis étincelant': this.examLaf.laf.vitre.oielGauche.synchesisetincelant.present = !this.examLaf.laf.vitre.oielGauche.synchesisetincelant.present
        break
      case 'Corps astéroïde': this.examLaf.laf.vitre.oielGauche.corpsasterode.present = !this.examLaf.laf.vitre.oielGauche.corpsasterode.present
        break
      case 'Hémorragie prérétinienne': this.examLaf.laf.vitre.oielGauche.hemorragiepreretinienne.present = !this.examLaf.laf.vitre.oielGauche.hemorragiepreretinienne.present
        break
      case 'Liquéfaction': this.examLaf.laf.vitre.oielGauche.liquefaction.present = !this.examLaf.laf.vitre.oielGauche.liquefaction.present
        break
      case 'Précipités en colier de perles': this.examLaf.laf.vitre.oielGauche.precipiteencolierdeperle.present = !this.examLaf.laf.vitre.oielGauche.precipiteencolierdeperle.present
        break
    }
    this.setVitreToTrue(this.examLaf.laf.vitre.oielGauche)
    this.objecttoSend.vitreOG = this.examLaf.laf.vitre.oielGauche
    this.objecttoSend.source = "vitreOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  selectVitretyndallcellulaireRight(value) {
    this.examLaf.laf.vitre.oielDroit.tyndallcellulaire.valeur = value
  }
  selectVitretyndallcellulaireLeft(value) {
    this.examLaf.laf.vitre.oielGauche.tyndallcellulaire.valeur = value
  }
  selectVitreproteiqueRight(value) {
    this.examLaf.laf.vitre.oielDroit.proteique.valeur = value
  }
  selectVitreproteiqueLeft(value) {
    this.examLaf.laf.vitre.oielGauche.proteique.valeur = value
  }
  selectVitreDPVRight(value) {
    this.examLaf.laf.vitre.oielDroit.DPV.valeur = value
  }
  selectVitreDPVLeft(value) {
    this.examLaf.laf.vitre.oielGauche.DPV.valeur = value
  }
  //!--------------------Vitre : Calme et acellulaire
  addNewAnomalyVitre(event) {
    const maxAnomalies = 5;
    const inputValue = event?.target?.value;

    const showToast = (message, messageColor, backgroundColor, progressBarColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        const self = this;
        this.iziToast.show({
          message,
          messageColor,
          titleColor: messageColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: function () {
            self.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorVitre && this.anomaliesByDoctorVitre.length >= maxAnomalies) {
      showToast(
        "Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies",
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieVitre = "";
      return false;
    }

    if (inputValue && inputValue.length > 1 && inputValue.length < 100) {
      const anomalyExisted = this.anomaliesByDoctorVitre.some(x => x.titre?.toLowerCase() === inputValue.toLowerCase());
      const anomalyExistedOld = this.oldlistAnomaliesVitre.some(x => x.toLowerCase() === inputValue.toLowerCase());

      if (!anomalyExisted && !anomalyExistedOld) {
        this.anomalieService.ajoutAnomalie({
          titre: inputValue[0].toUpperCase() + inputValue.slice(1),
          description: "",
          zone: "laf-vitre",
        }).subscribe((data) => {
          this.newAnomalieVitre = "";
          this.anomaliesByDoctorVitre.push(data);
          showToast(
            'Bien enregistré',
            '#386641',
            '#dde5b6',
            '#6a994e',
          );
        });
        return true;
      } else {
        showToast(
          "L'anomalie existe dans la liste",
          '#800f2f',
          '#ff8fa3',
          '#c9184a'
        );
        this.newAnomalieVitre = "";
        return false;
      }
    } else {
      showToast(
        'Merci de saisir au moins 2 caractères',
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieVitre = "";
      return false;
    }
  }

  // Right Vitre
  addRemoveFromVitreRightNew(value, id) {
    this.examLaf.laf.vitre.oielDroit.calmeEtAcellulaire.present = false
    let truefalseexist = this.examLaf.laf.vitre.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.vitre.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.vitreOD = this.examLaf.laf.vitre.oielDroit
      this.objecttoSend.source = "vitreOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.vitre.oielDroit.allAnomalies = this.examLaf.laf.vitre.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.vitreOD = this.examLaf.laf.vitre.oielDroit
      this.objecttoSend.source = "vitreOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setVitreToTrue(this.examLaf.laf.vitre.oielDroit)
      return true;
    }

  }
  checkExistAnomalieVitreRight(id) {
    return this.examLaf.laf.vitre.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Vitre dyna
  addRemoveFromVitreLeftNew(value, id) {
    this.examLaf.laf.vitre.oielGauche.calmeEtAcellulaire.present = false
    let truefalseexist = this.examLaf.laf.vitre.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.vitre.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.vitreOG = this.examLaf.laf.vitre.oielGauche
      this.objecttoSend.source = "vitreOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.vitre.oielGauche.allAnomalies = this.examLaf.laf.vitre.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.vitreOG = this.examLaf.laf.vitre.oielGauche
      this.objecttoSend.source = "vitreOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setVitreToTrue(this.examLaf.laf.vitre.oielGauche)
      return true;
    }

  }
  checkExistAnomalieVitreLeft(id) {
    return this.examLaf.laf.vitre.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of Vitre : Calme et acellulaire
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-vitre') {
        this.anomaliesByDoctorVitre.splice(this.anomaliesByDoctorVitre.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }
  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }

  // Vitre
  setVitreToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "calmeEtAcellulaire") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.calmeEtAcellulaire.present = true;
    }
    return myObject
  }



}
