
import { Component, OnInit } from '@angular/core';
import { throttleTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { _getURL } from 'config/API_CONFIG';
import { ExamenService } from './../../../../../services/examen/examen.service';


@Component({
  selector: 'app-side-bar-gauche',
  templateUrl: './side-bar-gauche.component.html',
  styleUrls: ['./side-bar-gauche.component.css', '../../interrogatoire/interrogatoire.component.css']
})
export class SideBarGaucheComponent implements OnInit {
  patient: any;
  historiquesConsultations: any[] = []
  selectedExamen: any
  selectedExamenIndex: number = null;
  private readonly rightEdgeThreshold = window.innerWidth - 10;
  private readonly mouseMoveSubject = new Subject<MouseEvent>();
  constructor(private route: ActivatedRoute, private examenService: ExamenService, private patientService: PatientService,
    private router: Router, private _sanitizer: DomSanitizer, private medecinService: MedecinService, public iziToast: Ng2IzitoastService,
    private sharedDataService: SharedDataService,
  ) {
    this.mouseMoveSubject.pipe(throttleTime(500)).subscribe((event) => this.handleMouseMove(event));
  }
  ngOnInit(): void {

    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    const idPatient = this.route.snapshot.paramMap.get('id');
    // get examen
    if (idExamen && idExamen != undefined && idExamen != "undefined" && idExamen != null) {



      // get patient
      if (idPatient && idPatient != "undefined" && idPatient != undefined && idPatient != null && idExamen && idExamen != undefined && idExamen != null && idExamen != "undefined") {
        this.patientService.getPatientByIDNewFunction(idPatient).subscribe(patient => {
          this.patient = patient;
          if (this.patient && this.patient._id != null && this.patient._id != undefined) {
            // get number of old consultations  
            this.examenService.hasOldConsultation(this.patient._id, idExamen).subscribe((data: any) => {
              if (data && data.hasOldConsultation) {
                this.historiquesConsultations = data.previousConsultation.reverse()
              }

            },
              err => {
                this.router.navigate(['/medecin/patients-liste']);
                return false;
              });
          } else {
            this.router.navigate(['/medecin/patients-liste']);
          }
        },
          err => {
            this.router.navigate(['/medecin/patients-liste']);
            return false;
          });
      } else {
        this.router.navigate(['/medecin/patients-liste']);
      }

    }
  }



  showDataInfo(i) {
   
    if (this.selectedExamenIndex === i) {
      this.selectedExamenIndex = null; 
    } else {
      this.selectedExamenIndex = i; 
      this.selectedExamen = this.historiquesConsultations[i]
    }
  }

  handleMouseMove(event: MouseEvent) {
    // if (event.clientX >= this.rightEdgeThreshold) {
    //   // Call your function or perform any action here
    //   document.getElementById('btn-siderbar').click();
    //   return true;
    // }
    // if (event.clientX <  200) {
    //   document.getElementById('btn-siderbar-left').click();
    //   return true;
    // }
  }

}
