export const prescrireVerreModel = {
  date: "",
  deLoin: {
    present: true,
    oielDroit: {
      sphere: "",
      cylindre: "",
      axe: "",
    },
    oielGauche: {
      sphere: "",
      cylindre: "",
      axe: "",
    },
    dp: ""
  },
  dePres: {
    present: true,
    oielDroit: {
      sphere: "",
      cylindre: "",
      axe: "",
      add: 0,
    },
    oielGauche: {
      sphere: "",
      cylindre: "",
      axe: "",
      add: 0,
    },
    dp: ""
  },
  typeVerre: [], // list choice
  filtre: [],
  marqueVerre: ""

};

export const prescrireLentilleModel = {
  date: "",
  type: "",
  torique: true,
  oielDroit: {
    sphere: "",
    cylindre: "",
    axe: "",
  },
  oielGauche: {
    sphere: "",
    cylindre: "",
    axe: "",
  },
  modeDeRenouvellement: "",
  solution: "",
}

export const chirurgieModel = {
  date: "",
  types: "",
  oeil: "",
  dateOperation: "",
  clinique: "",
  forfait: "",
  precaution: "",
  operateur: "",
  informationAdditionnel: "",
}
export const examenComplementaireModel = {
  date: "",
  types: [],
  oeil: "",
  dateOperation: "",
  clinique: "",
  forfait: "",
  precaution: "",
  operateur: "",
  informationAdditionnel: "",
};
export const prescrireLaserModel = {
  date: "",
  types: "",
  oeil: "",
  dateOperation: "",
  clinique: "",
  forfait: "",
  precaution: "",
  operateur: "",
  informationAdditionnel: "",
}

export const certificataptitudepermisModel = {
  date: "",
  informationAdditionnel: "",
  oeilDroit: {
    acuiteVisuelSansCorrection: "",
    acuiteVisuelAvecCorrection: "",
    acuiteVisuelBinoculaire: "",
  },
  oeilGauche: {
    acuiteVisuelSansCorrection: "",
    acuiteVisuelAvecCorrection: "",
    acuiteVisuelBinoculaire: "",
  },
}
