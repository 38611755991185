import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-examens',
  templateUrl: './list-examens.component.html',
  styleUrls: ['./list-examens.component.css','../../rdvs/listrdvs/listrdvs.component.css']
})
export class ListExamensComponent implements OnInit {
  examens:any[]
  public search: any = '';
  p=1;
  constructor() { }

  ngOnInit(): void {
  }
  setVariabletoDelet(examen){
    localStorage.setItem('element-to-delete', JSON.stringify(examen._id));
  }
  supprimerExamen(){
    var examenId = JSON.parse(localStorage.getItem('element-to-delete'));
    var foundRdv = this.examens.find(x => x._id == examenId);
    // const headers = new HttpHeaders({
    //   'Authorization': JSON.parse(localStorage.getItem('id_token')),
    // });
    //   this.http.delete(_getURL(API_CONFIG.rdvs) + '/' + foundRdv._id, { headers: headers }).subscribe((res: any) => {
    //     this.rdvs.splice(this.rdvs.indexOf(foundRdv), 1);
    //     Swal.fire( {
    //        imageUrl: 'assets/images/patsDetails/delete-2.png',
    //        imageWidth: 100,
    //        imageHeight: 100,
    //        imageAlt: 'Custom image',
    //        width: 600,
    //        title: 'BIEN SUPPRIMÉ',
    //        timer: 1500,
    //        showConfirmButton: false
    //      }
    //    );
    //   })

  }
}
