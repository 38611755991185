<div id="secretairesidposition">
</div>
<br><br><br><br>
<div class="container-fluid p-sm-5 bg-light">
  <div class="row">
    <div class="col-lg-5 col-md-6 col-sm-12">
      <h4>Liste des secrétaires</h4>
    </div>
  </div>

  <div class="col-md-12">
    <div class="row justify-content-end">
      <div class="col-auto">
      </div>
      <div class="col-auto">
      </div>
    </div>
  </div>
  
  <div class="row mt-4">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="ligne-paire text-center">
              <th scope="col" style="color:#589590;">Nom</th>
              <th scope="col" style="color:#589590;">Prénom</th>
              <th scope="col" style="color:#589590;">Email</th>
              <th scope="col" style="color:#589590;">N° inscription</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let i of secretaires | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                        }; let k = index">
              <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center" style="cursor: pointer;">
                <td >{{i.nom}}</td>
                <td >{{i.prenom}}</td>
                <td >{{i.email}}</td>
                <td >{{i.numInscription}}</td>
              </tr>

            </ng-container>

          </tbody>
        </table>
        <div *ngIf="secretaires && secretaires.length > 10 " class=" border-0 mt-4">
          <div class="d-flex justify-content-center">
            <pagination-controls class="my-pagination" autoHide="true" previousLabel="Précédent" nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
            </pagination-controls>
          </div>
        </div>
        <div *ngIf="secretaires && secretaires.length == 0" class="table-responsive" style="text-align: center">
          <p style="padding-bottom: 9rem; padding-top: 9rem ">
            Aucun patient n'est trouvé
          </p>
        </div>

      </div>
    </div>
  </div>
</div>
