<div id="divToExport" *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire un certificat médical') > -1&& medcin">
    <meta charset="UTF-8" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" />
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet" />
    <style>
        .bold-line {
            border-top: 3px solid;
        }

        .letter-spacing {
            letter-spacing: 3px;
        }

        ul {
            list-style-type: none;
        }

        .main-contenu {
            padding-left: 3.2rem !important;
            height: 800px;
        }

        .contenu {
            font-size: 1rem;
            font-weight: 600;
        }

        #footer-cont {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            height: 150px;
        }

        .header {
            height: 200px;
        }

        .flex-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100vh;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    </style>
    <div class="flex-wrapper">
        <!-- En-tête -->
        <app-header-template></app-header-template>
        <!-- content -->
        <div class="container mt-5 ml-7 main-contenu" id="main-cont">
            <!-- Certificat médical de repos -->
            <div *ngIf="myExamen.foundImpression.types=='Certificat médical de repos'">
                <h2 class="text-center" [style.color]="medcin.colorTemplates">
                    <strong>Certificat médical de repos</strong>
                </h2>
                <p class="text-end contenu">
                    Tunis le {{ today | date : "dd/MM/yyyy" }}
                </p>
                <p class="contenu">
                    Je soussigné <strong>Docteur {{medcin.prenom}} {{medcin.nom}} </strong> certifie que
                    l'état de santé de :
                </p>
                <p class="contenu" *ngIf="myExamen && myExamen.patient">Mme/Mr
                    {{myExamen.patient.nom}} {{myExamen.patient.prenom}} nécessite un arrêt
                    de travail de {{myExamen.foundImpression.nombre}} jours à dater du {{myExamen.foundImpression.compterDe | date :'dd/MM/yyyy'}} sauf complications.</p>
                <p class="text-right contenu">Cordialement</p>
            </div>
            <!-- Certificat médical de présence  -->
            <div *ngIf="myExamen.foundImpression.types=='Certificat médical de présence'">
                <h2 class="text-center" [style.color]="medcin.colorTemplates">
                    <strong>Certificat médical de présence</strong>
                </h2>
                <p class="text-end contenu">Tunis le {{myExamen.foundImpression.dateCertificat | date:'dd/MM/yyyy'}}
                </p>
                <p class="contenu">Je soussigné <strong>Docteur {{medcin.prenom}} {{medcin.nom}} </strong> certifie que {{myExamen.patient.nom}} {{myExamen.patient.prenom}} , a été vu ce jour à la consultation.</p>
                <p class="contenu">
                    Ce certificat a été établi à la demande de l'intéresse pour faire
                    valoir ce que droit .
                </p>
                <p class="text-right contenu">Cordialement</p>
            </div>
            <!-- Certificat d’aptitude a la conduite -->
            <div *ngIf="myExamen.foundImpression.types=='Certificat d’aptitude a la conduite'">
                <h2 class="text-center" [style.color]="medcin.colorTemplates">
                    <strong>Certificat d’aptitude à la conduite</strong>
                </h2>
                <p class="text-end contenu">Tunis le {{myExamen.foundImpression.dateCertificat | date:'dd/MM/yyyy'}}
                </p>
                <p class="contenu">Je soussigné <strong>Docteur {{medcin.prenom}} {{medcin.nom}} </strong>et j'autorise,mme/mr{{myExamen.patient.nom}} {{myExamen.patient.prenom}} à conduire les véhicule(s).</p>
                <p class="text-right contenu mt-2">Cordialement</p>
            </div>
            <!-- Certificat d’accompagnement -->
            <div *ngIf="myExamen.foundImpression.types=='Certificat d’accompagnement'">
                <h2 class="text-center" [style.color]="medcin.colorTemplates">
                    <strong>Certificat d’accompagnement</strong>
                </h2>
                <p class="text-end contenu">
                    Tunis le {{ today | date : "dd/MM/yyyy" }}
                </p>
                <p class="contenu">Je soussigné <strong>Docteur {{medcin.prenom}} {{medcin.nom}} </strong> certifie que mme/mr
                    {{myExamen.patient.nom}} {{myExamen.patient.prenom}} nécessite un accompagnement
                    de {{myExamen.foundImpression.nombre}} jours à dater du {{myExamen.foundImpression.compterDe | date :'dd/MM/yyyy'}}.</p>
                <p class="text-right contenu">Cordialement</p>
            </div>
        </div>
        <!-- Pied de page  -->
        <app-footer-template></app-footer-template>


        <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    </div>
</div>