import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import { AuthService } from 'src/app/services/auth/auth.service';
import { _getURL } from 'config/API_CONFIG';

@Component({
  selector: 'app-impression-conclusion',
  templateUrl: './impression-conclusion.component.html',
  styleUrls: ['./impression-conclusion.component.css']
})
export class ImpressionConclusionComponent implements OnInit {
  today = new Date();
  myExamen: any;
  motif: any;
  selectedMotifs: any;

  medcin: any;
  myId: any
  unSpec: boolean = false
  pathImageLogo: any
  constructor(private examenService: ExamenService, private router: Router,
    private route: ActivatedRoute, public iziToast: Ng2IzitoastService, private authService: AuthService) {

  }

  ngOnInit() {
    this.today = new Date()
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getExamenByIdForConclusion(idExamen).subscribe((data: any) => {
        this.myExamen = data
        if (this.myExamen) {
          this.configureDataExamen()
          return true;
        }
        // else{
        // this.router.navigate(['/medecin/patients-liste']);
        // return false;
        // }
      },
        err => {
          // this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      // this.router.navigate(['/medecin/patients-liste']);
      return false;
    }
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.myId = medecinId
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medcin = profile.medecin;
        if (this.medcin.prenom.trim() === "Omar" && this.medcin.nom.trim() === "Ben Haj Said" && this.medcin.specialite == "Ophtalmologiste") {
          this.unSpec = true
        } else {
          this.unSpec = false
        }
        if (this.medcin.logo) {
          this.pathImageLogo = _getURL("") + "data/files/cabinet/" + this.medcin.logo
        }
        if (!this.medcin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
  }
  configureDataExamen() {
    this.motif = this.myExamen.motif
    this.selectedMotifs = this.myExamen.nextMotif
    if (this.myExamen.refraction && !this.myExamen.refraction.sansCycloplegie) {
      this.myExamen.refraction.sansCycloplegie = {
        oielDroit: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        },
        oielGauche: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        }
      }
    }
    if (this.myExamen.refraction && !this.myExamen.refraction.avecCycloplegie) {
      this.myExamen.refraction.avecCycloplegie = {
        type: "",
        oielDroit: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        },
        oielGauche: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        },
      }
    }

    if (this.myExamen.refraction && this.myExamen.refraction.avecCycloplegie && !this.myExamen.refraction.avecCycloplegie.oielDroit) {
      this.myExamen.refraction.avecCycloplegie.oielDroit = {
        sphere: "",
        cylindre: "",
        axe: "",
        keratometrie: "",
      }
    }
    if (this.myExamen.refraction && this.myExamen.refraction.avecCycloplegie && !this.myExamen.refraction.avecCycloplegie.oielGauche) {
      this.myExamen.refraction.avecCycloplegie.oielGauche = {
        sphere: "",
        cylindre: "",
        axe: "",
        keratometrie: "",
      }
    }



  }

  imprimerConclusion() {
    const today = new Date();

    // Get day, month, and year
    const day = String(today.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = today.getFullYear();

    // const element = document.getElementById('divToExport'); // Replace 'yourComponentId' with the id of the element containing your component's HTML

    // let namefile = "Conclusion-" + day + month + year;
    // if (this.myExamen && this.myExamen.patient) {
    //   namefile = 'Conclusion-' + this.myExamen.patient.nom + '-' + this.myExamen.patient.prenom + '-' + day + month + year + '.pdf'
    // }
    // const options = {
    //   filename: namefile,
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { dpi: 192, letterRendering: true , scale: 2},
    //   jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    // };

    // html2pdf().from(element).set(options).save();
    const htmlWidth = $("#divToExport").width();
    const htmlHeight = $("#divToExport").height();

    const topLeftMargin = 3;

    let pdfWidth = htmlWidth + (topLeftMargin * 2);
    let pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2);

    const canvasImageWidth = htmlWidth;
    const canvasImageHeight = htmlHeight;

    const totalPDFPages = Math.ceil(htmlHeight / pdfHeight);

    const data = document.getElementById('divToExport');
    html2canvas(data, { allowTaint: true }).then(canvas => {

      canvas.getContext('2d');
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
      pdf.addImage(imgData, 'png', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight);

      for (let i = 1; i < totalPDFPages; i++) {
        pdf.addPage([pdfWidth, pdfHeight], 'p');
        pdf.addImage(imgData, 'png', topLeftMargin, -(pdfHeight * i) + topLeftMargin, canvasImageWidth, canvasImageHeight);
      }
      let namefile = "Conclusion-" + day + month + year;
      if (this.myExamen && this.myExamen.patient) {
        namefile = 'Conclusion-' + this.myExamen.patient.nom + '-' + this.myExamen.patient.prenom + '-' + day + month + year + '.pdf'
      }
      pdf.save(namefile);
    });
  }

}
