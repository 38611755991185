<div id="top">

</div>
<div class="responsive" style="max-width: 100% !important; overflow-x: hidden !important;overflow-y: hidden !important;">
  <div class="fixed-top" *ngIf="!isFullscreen">
    <div class="menu">
      <nav class="navbar navbar-expand-lg navbar-light bg-light menu nav2">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon navbar-toggler-icon2"></span>
        </button>
        <div class="collapse navbar-collapse navbar-collapse2 " id="navbarSupportedContent">
          <a class="navbar-brand logo-image" *ngIf="medecin" [routerLink]="['/medecin/accueil']"><img src="assets/images/medivisto-blanc.png" class="img-med-header" alt="alternative"></a>
          <ul class="navbar-nav ml-auto" *ngIf="medecin">
            <li class="nav-item" *ngIf="medecin.roles && medecin.roles.gestionRdvs">
              <a class="nav-link nav-link-3 page-scroll navLin" [routerLinkActive]="'is-active'" [routerLink]="['/medecin/accueil']">Accueil</a>
            </li>
            <li class="nav-item" *ngIf="medecin.roles && medecin.roles.gestionPatient">
              <a class="nav-link nav-link-3 page-scroll" [routerLinkActive]="'is-active'" [routerLink]="['/medecin/patients-liste']">Patient</a>
            </li>
            <li class="nav-item" *ngIf="medecin.roles &&  medecin.roles.gestionAgenda">
              <a class="nav-link nav-link-3 page-scroll" [routerLinkActive]="'is-active'" [routerLink]="['/medecin/calendar']">Agenda</a>
            </li>
            <li class="nav-item" *ngIf="medecin.roles && medecin.roles.gestionMedecins">
              <a class="nav-link nav-link-3 page-scroll" [routerLinkActive]="'is-active'" [routerLink]="['/medecin/recherche']">Médecins</a>
            </li>
            <li class="nav-item dropdown" *ngIf="medecin">
              <a class="nav-link nav-link-new page-scroll" href="#" id="dropdownMenuButton" data-toggle="dropdown"><img src="assets/images/user2.png" class="iconUser">Dr {{medecin.nom}} {{medecin.prenom}}</a>
              <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item drop-dup-option" [routerLink]="['/medecin/profil']">Profil</a>
                <a class="dropdown-item drop-dup-option" [routerLink]="['/medecin/secretaires']">Mes secrétaires</a>
                <a class="dropdown-item drop-dup-option-b" [routerLink]="['/logout']">Se déconnecter</a>
              </div>
            </li>
          </ul>
          <a class="navbar-brand logo-image" *ngIf="secretaire" [routerLink]="['/medecin/patients-liste']"><img src="assets/images/medivisto-blanc.png" class="img-med-header" alt="alternative"></a>
          <ul class="navbar-nav ml-auto" *ngIf="secretaire">
            <li class="nav-item" *ngIf="secretaire.roles && secretaire.roles.gestionSalleAttente">
              <a class="nav-link nav-link-3 page-scroll" [routerLinkActive]="'is-active'" [routerLink]="['/medecin/salle-attente']">Salle d'attente</a>
            </li>
            <li class="nav-item" *ngIf="secretaire.roles && secretaire.roles.gestionPatient">
              <a class="nav-link nav-link-3 page-scroll" [routerLinkActive]="'is-active'" [routerLink]="['/medecin/patients-liste']">Patient</a>
            </li>
            <li class="nav-item" *ngIf="secretaire.roles &&  secretaire.roles.gestionAgenda">
              <a class="nav-link nav-link-3 page-scroll" [routerLinkActive]="'is-active'" [routerLink]="['/medecin/calendar']">Agenda</a>
            </li>
            <li class="nav-item dropdown" *ngIf="secretaire">
              <a class="nav-link nav-link-new page-scroll" href="#" id="dropdownMenuButton" data-toggle="dropdown"><img src="assets/images/user2.png" class="iconUser">{{secretaire.nom}} {{secretaire.prenom}}</a>
              <div class="dropdown-menu show-catalogue" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item drop-dup-option-b" [routerLink]="['/logout']">Se déconnecter</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <router-outlet></router-outlet>
  
</div>