import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { _getURL } from 'config/API_CONFIG';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExamenService } from 'src/app/services/examen/examen.service';

@Component({
  selector: 'app-footer-template',
  templateUrl: './footer-template.component.html',
  styleUrls: ['./footer-template.component.css']
})
export class FooterTemplateComponent implements OnInit {
  myExamen: any;
  medcin: any;
  myId: any
  pathImageLogo: any
  constructor(private examenService: ExamenService, private router: Router, private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit(): void {
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    const idcat = this.route.snapshot.paramMap.get('idCat');
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getExamenByIdForCatImpression(idExamen, idcat).subscribe((data: any) => {
        this.myExamen = data
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    }
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.myId = medecinId
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medcin = profile.medecin;
        if (!this.medcin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
  }
}
