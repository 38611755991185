import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-lispatient',
  templateUrl: './program-lispatient.component.html',
  styleUrls: ['./program-lispatient.component.css', '../list-patients.component.css']
})
export class ProgramLispatientComponent implements OnInit {
  @Input() myExamen: any
  constructor() { }

  ngOnInit(): void {
  }

}
