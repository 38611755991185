import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { champSpecialCaracters } from "../constants/messages";

export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'Ce champ est obligatoire',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Cet e-mail est invalide.',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'minlength': `Ce champ doit avoir minimum ${validatorValue.requiredLength} caractères.`,
      'maxlength': `Ce champ ne doit pas dépasser ${validatorValue.requiredLength}.`,
      'invalidNumber': 'Ce champ est obligatoire',
      'invalidate': 'Ce champ est obligatoire',
      'invalidMotif': champSpecialCaracters,
      'invalidChamps': champSpecialCaracters
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static numberValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (isNaN(control.value)) {
      return { 'invalidNumber': true };
    } else {
      return null;
    }
  }
  static dateValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    var today = new Date().toISOString().split('T')[0]
    if (control.value > today) {
      return { 'invalidate': true };
    } else {
      return null;
    }
  }
  // static emailValidator(control) {
  //   // RFC 2822 compliant regex
  //   if (control.value) {
  //     if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
  //       return null;
  //     } else {
  //       return { 'invalidEmailAddress': true };
  //     }
  //   }
  // }

  static emailValidator(validateService?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;

      // Check if email length is valid
      if (email && email.length > 1 && !validateService.validateEmail(email)) {
        return { invalidEmailAddress: true };
      }

      if (email && email.length > 50) {
        return { invalidEmailAddress: true };
      }

      // RFC 2822 compliant regex
      if (email && !email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
        return { invalidEmailAddress: true };
      }

      return null;
    };
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }
  static phonenumber(control) {
    if (control.value) {
      if (control.value[0] === '+' && control.value.length > 10) {
        return null;
      }
      else {
        return { 'invalidNumber': true };
      }
    }

  }

  static validateMotif(control: AbstractControl): ValidationErrors | null {
    const re = /^[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]+$/;
    if (!control.value) {
      return null; // No value means no error, you may change this based on requirements
    }
    if (!re.test(control.value)) {
      return { 'invalidMotif': true };
    }
    return null;
  }
  static validateChamps(control: AbstractControl): ValidationErrors | null {
    const re = /^[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]+$/;
    if (!control.value) {
      return null; // No value means no error, you may change this based on requirements
    }
    if (!re.test(control.value)) {
      return { 'invalidChamps': true };
    }
    return null;
  }
  static validateChampsNumFiche(control: AbstractControl): ValidationErrors | null {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (!control.value) {
      return null; // No value means no error, you may change this based on requirements
    }
    if (!re.test(control.value)) {
      return { 'invalidChamps': true };
    }
    return null;
  }

}
