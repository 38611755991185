<div id="divToExport" *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'conduite') > -1 && medcin">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
    <style>
        .bold-line {
            border-top: 3px solid;
        }

        .letter-spacing {
            letter-spacing: 3px;
        }

        ul {
            list-style-type: none;
        }

        .main-contenu {
            padding-left: 3.2rem !important;
            height: 800px;
        }

        .contenu {
            font-size: 1rem;
            font-weight: 600;
        }

        #footer-cont {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            height: 150px;
        }

        .header {
            height: 200px;
        }

        .flex-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100vh;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    </style>
    <div class="flex-wrapper">
        <!-- En-tête -->
        <app-header-template></app-header-template>
        <!-- content -->
        <div class=" container mt-4 ml-7 main-contenu" id="main-cont" *ngIf="myExamen && myExamen.foundImpression">
            <h2 class="text-center" [style.color]="medcin.colorTemplates"><strong>CERTIFICAT D'ADAPTATION A LA CONDUITE</strong></h2>
            <p class="text-end contenu">Tunis le {{today | date:'dd/MM/yyyy'}}
            </p>
            <p class="contenu">Je soussigné <strong>Docteur Omar Ben Haj Said </strong> certifie avoir examiner</p>
            <p class="contenu" *ngIf="myExamen && myExamen.patient">Mme/Mr
                {{myExamen.patient.nom}} {{myExamen.patient.prenom}} et que son examen
                ophtalmologique montre :</p>
            <p class="contenu"> *Oeil droit: Acuité visiuelle sans correction
                {{myExamen.foundImpression.oeilDroit.acuiteVisuelSansCorrection}}</p>
            <p class="contenu"> &thinsp; &thinsp; &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp;
                &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp; &thinsp;&thinsp;&thinsp;
                Acuité visiuelle avec correction {{myExamen.foundImpression.oeilDroit.acuiteVisuelAvecCorrection}}</p>
            <p class="contenu"> *Oeil gauche: Acuité visiuelle sans correction
                {{myExamen.foundImpression.oeilGauche.acuiteVisuelSansCorrection}}</p>
            <p class="contenu"> &thinsp; &thinsp; &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp;
                &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp; &thinsp;&thinsp;&thinsp;
                Acuité visiuelle sans correction {{myExamen.foundImpression.oeilGauche.acuiteVisuelAvecCorrection}}</p>
            <p class="contenu"> &thinsp; &thinsp; &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp;
                &thinsp;&thinsp;&thinsp;&thinsp;&thinsp; &thinsp; &thinsp;&thinsp;&thinsp;
                Acuité visiuelle en binoculaire {{myExamen.foundImpression.oeilGauche.acuiteVisuelBinoculaire}}</p>
            <p class="contenu">Par concéuence Il/Elle est apte/Inapte à conduire tous type de véhicule</p>
            <p class="text-right contenu">Cordialement</p>
        </div>
        <!-- Pied de page  -->
        <app-footer-template></app-footer-template>


        <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    </div>
</div>