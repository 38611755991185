import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-refraction-patient',
  templateUrl: './refraction-patient.component.html',
  styleUrls: ['./refraction-patient.component.css', '../list-patients.component.css']
})
export class RefractionPatientComponent implements OnInit {
  @Input() myExamen: any;
  echelleMonoyerList = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "1/10", "2/10", "3/10", "4/10", "5/10", "6/10", "7/10", "8/10", "9/10", "10/10"]
  echelleSnellenList = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "20/400", "20/200", "20/80", "20/40", "20/25", "20/20", "20/15", "20/10"]
  echelleParinaudList = ["P1", "P2", "P3", "P4", "P5"]
  constructor() { }

  ngOnInit(): void { }

}
