import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-iris',
  templateUrl: './iris.component.html',
  styleUrls: ['./iris.component.css', '../laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class IrisComponent implements OnInit {
  // --------------------------------------------------------------IRIS
  iris = false;
  irisListLeft = [
    "Normale",
    "Hétérochromie",
    "Rubéose",
    "SPE irien",
    "Mélanome/masse suspecte",
    "Granulome irien",
    "Iris concave",
    "Convexe",
    "Iridodialyse",
    "Plaie irienne",
    "Atrophie irienne",
    "Synéchie irido-cristallienne",
    "Naevus irien",
    "Cristaux irien",
  ];
  irisListRight = [
    "Normale",
    "Hétérochromie",
    "Rubéose",
    "SPE irien",
    "Mélanome/masse suspecte",
    "Granulome irien",
    "Iris concave",
    "Convexe",
    "Iridodialyse",
    "Plaie irienne",
    "Atrophie irienne",
    "Synéchie irido-cristallienne",
    "Naevus irien",
    "Cristaux irien",
  ];
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;
  //!----- anomalies by a doctor
  // IRIS : Normale
  anomaliesByDoctorIris: any[] = []
  oldlistAnomaliesIris = this.irisListLeft
  newAnomalieIris = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreIris = false
  //end of Show More Variables
  displayIris = true

  // send object
  objecttoSend = {
    irisOD: {},
    irisOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false

  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }
  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-iris').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }

    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-irisNormale", 'value': this.examLaf.laf.irisNormale });
  }
  showMoreIrisSection() {
    this.showMoreIris = !this.showMoreIris
  }
  displayAndHideIris() {
    this.displayIris = !this.displayIris
  }
  configureDataExamen(idPatient) {
    // !Historique LAF
    // this.examenService.getInspectionHistoriques(idPatient,"laf").subscribe((data:any) => {
    //  this.oldListConsultations=data
    //  },
    //    err => {
    //      return false;
    //    });

    // !IRIS : Normale
    this.anomalieService.getAnomalieByDoctor("laf-iris").subscribe(data => {
      this.anomaliesByDoctorIris = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }


  //------------------------------------------------------------------------------- IRIS
  showIris() {
    this.examLaf.laf.irisNormale.present = false
  }


  addRemoveFromIrisRight(value) {
    this.examLaf.laf.irisNormale.oielDroit.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.irisNormale.oielDroit); this.examLaf.laf.irisNormale.oielDroit.normale.present = true
        break
      case 'Nodule irien': this.examLaf.laf.irisNormale.oielDroit.noduleirien.present = !this.examLaf.laf.irisNormale.oielDroit.noduleirien.present
        break
      case 'Hétérochromie': this.examLaf.laf.irisNormale.oielDroit.heterochromie.present = !this.examLaf.laf.irisNormale.oielDroit.heterochromie.present
        break
      case 'Rubéose': this.examLaf.laf.irisNormale.oielDroit.rubeose.present = !this.examLaf.laf.irisNormale.oielDroit.rubeose.present
        break
      case 'SPE irien': this.examLaf.laf.irisNormale.oielDroit.SPEirien.present = !this.examLaf.laf.irisNormale.oielDroit.SPEirien.present
        break
      case 'Hyphema': this.examLaf.laf.irisNormale.oielDroit.hyphema.present = !this.examLaf.laf.irisNormale.oielDroit.hyphema.present
        break
      case 'Mélanome/masse suspecte': this.examLaf.laf.irisNormale.oielDroit.melanomeMassesuspecte.present = !this.examLaf.laf.irisNormale.oielDroit.melanomeMassesuspecte.present
        break
      case 'Granulome irien': this.examLaf.laf.irisNormale.oielDroit.granulomeirien.present = !this.examLaf.laf.irisNormale.oielDroit.granulomeirien.present
        break
      case 'Iris concave': this.examLaf.laf.irisNormale.oielDroit.irisconcave.present = !this.examLaf.laf.irisNormale.oielDroit.irisconcave.present
        break
      case 'Convexe': this.examLaf.laf.irisNormale.oielDroit.convexe.present = !this.examLaf.laf.irisNormale.oielDroit.convexe.present
        break
      case 'Plaie irienne': this.examLaf.laf.irisNormale.oielDroit.plaieirienne.present = !this.examLaf.laf.irisNormale.oielDroit.plaieirienne.present
        break
      case 'Atrophie irienne': this.examLaf.laf.irisNormale.oielDroit.atrophieirienne.present = !this.examLaf.laf.irisNormale.oielDroit.atrophieirienne.present
        break
      case 'Synéchie irido-cristallienne': this.examLaf.laf.irisNormale.oielDroit.synechieiridocristalienne.present = !this.examLaf.laf.irisNormale.oielDroit.synechieiridocristalienne.present
        break
      case 'Naevus irien': this.examLaf.laf.irisNormale.oielDroit.naevusirien.present = !this.examLaf.laf.irisNormale.oielDroit.naevusirien.present
        break
      case 'Cristaux irien': this.examLaf.laf.irisNormale.oielDroit.cristauxirien.present = !this.examLaf.laf.irisNormale.oielDroit.cristauxirien.present
        break
    }
    this.setToTrue(this.examLaf.laf.irisNormale.oielDroit)
    this.objecttoSend.irisOD = this.examLaf.laf.irisNormale.oielDroit
    this.objecttoSend.source = "irisOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromIrisLeft(value) {
    this.examLaf.laf.irisNormale.oielGauche.normale.present = false
    switch (value) {
      case 'Normale': this.setAllValueToFlase(this.examLaf.laf.irisNormale.oielGauche); this.examLaf.laf.irisNormale.oielGauche.normale.present = true
        break
      case 'Nodule irien': this.examLaf.laf.irisNormale.oielGauche.noduleirien.present = !this.examLaf.laf.irisNormale.oielGauche.noduleirien.present
        break
      case 'Hétérochromie': this.examLaf.laf.irisNormale.oielGauche.heterochromie.present = !this.examLaf.laf.irisNormale.oielGauche.heterochromie.present
        break
      case 'Rubéose': this.examLaf.laf.irisNormale.oielGauche.rubeose.present = !this.examLaf.laf.irisNormale.oielGauche.rubeose.present
        break
      case 'SPE irien': this.examLaf.laf.irisNormale.oielGauche.SPEirien.present = !this.examLaf.laf.irisNormale.oielGauche.SPEirien.present
        break
      case 'Hyphema': this.examLaf.laf.irisNormale.oielGauche.hyphema.present = !this.examLaf.laf.irisNormale.oielGauche.hyphema.present
        break
      case 'Mélanome/masse suspecte': this.examLaf.laf.irisNormale.oielGauche.melanomeMassesuspecte.present = !this.examLaf.laf.irisNormale.oielGauche.melanomeMassesuspecte.present
        break
      case 'Granulome irien': this.examLaf.laf.irisNormale.oielGauche.granulomeirien.present = !this.examLaf.laf.irisNormale.oielGauche.granulomeirien.present
        break
      case 'Iris concave': this.examLaf.laf.irisNormale.oielGauche.irisconcave.present = !this.examLaf.laf.irisNormale.oielGauche.irisconcave.present
        break
      case 'Convexe': this.examLaf.laf.irisNormale.oielGauche.convexe.present = !this.examLaf.laf.irisNormale.oielGauche.convexe.present
        break
      case 'Plaie irienne': this.examLaf.laf.irisNormale.oielGauche.plaieirienne.present = !this.examLaf.laf.irisNormale.oielGauche.plaieirienne.present
        break
      case 'Atrophie irienne': this.examLaf.laf.irisNormale.oielGauche.atrophieirienne.present = !this.examLaf.laf.irisNormale.oielGauche.atrophieirienne.present
        break
      case 'Synéchie irido-cristallienne': this.examLaf.laf.irisNormale.oielGauche.synechieiridocristalienne.present = !this.examLaf.laf.irisNormale.oielGauche.synechieiridocristalienne.present
        break
      case 'Naevus irien': this.examLaf.laf.irisNormale.oielGauche.naevusirien.present = !this.examLaf.laf.irisNormale.oielGauche.naevusirien.present
        break
      case 'Cristaux irien': this.examLaf.laf.irisNormale.oielGauche.cristauxirien.present = !this.examLaf.laf.irisNormale.oielGauche.cristauxirien.present
        break
    }
    this.setToTrue(this.examLaf.laf.irisNormale.oielGauche)
    this.objecttoSend.irisOG = this.examLaf.laf.irisNormale.oielGauche
    this.objecttoSend.source = "irisOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }


  checkSign(event) {
    if (event.target.value <= 0) {
      event.target.value = 0
    }
  }

  // !--------------IRIS : Normale
  //!--------------------IRIS : Normale
  addNewAnomalyIris(event) {
    const maxAnomalies = 5;
    const inputValue = event?.target?.value;

    const showToast = (message, messageColor, backgroundColor, progressBarColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        const self = this;
        this.iziToast.show({
          message,
          messageColor,
          titleColor: messageColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: function () {
            self.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorIris && this.anomaliesByDoctorIris.length >= maxAnomalies) {
      showToast(
        "Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies",
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieIris = "";
      return false;
    }

    if (inputValue && inputValue.length > 1 && inputValue.length < 100) {
      const anomalyExisted = this.anomaliesByDoctorIris.some(x => x.titre?.toLowerCase() === inputValue.toLowerCase());
      const anomalyExistedOld = this.oldlistAnomaliesIris.some(x => x.toLowerCase() === inputValue.toLowerCase());

      if (!anomalyExisted && !anomalyExistedOld) {
        this.anomalieService.ajoutAnomalie({
          titre: inputValue[0].toUpperCase() + inputValue.slice(1),
          description: "",
          zone: "laf-iris",
        }).subscribe((data) => {
          this.newAnomalieIris = "";
          this.anomaliesByDoctorIris.push(data);
          showToast(
            'Bien enregistré',
            '#386641',
            '#dde5b6',
            '#6a994e',
          );
        });
        return true;
      } else {
        showToast(
          "L'anomalie existe dans la liste",
          '#800f2f',
          '#ff8fa3',
          '#c9184a'
        );
        this.newAnomalieIris = "";
        return false;
      }
    } else {
      showToast(
        'Merci de saisir au moins 2 caractères',
        '#800f2f',
        '#ff8fa3',
        '#c9184a'
      );
      this.newAnomalieIris = "";
      return false;
    }
  }

  // Right Iris
  addRemoveFromIrisRightNew(value, id) {
    this.examLaf.laf.irisNormale.oielDroit.normale.present = false
    let truefalseexist = this.examLaf.laf.irisNormale.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.irisNormale.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.irisOD = this.examLaf.laf.irisNormale.oielDroit
      this.objecttoSend.source = "irisOD"
      this.sharedDataService.updateData(this.objecttoSend)
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.irisNormale.oielDroit.allAnomalies = this.examLaf.laf.irisNormale.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.irisOD = this.examLaf.laf.irisNormale.oielDroit
      this.objecttoSend.source = "irisOD"
      this.sharedDataService.updateData(this.objecttoSend)
      this.setToTrue(this.examLaf.laf.irisNormale.oielDroit)
      return true;
    }

  }
  checkExistAnomalieIrisRight(id) {
    return this.examLaf.laf.irisNormale.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Chambre dyna
  addRemoveFromIrisLeftNew(value, id) {
    this.examLaf.laf.irisNormale.oielGauche.normale.present = false
    let truefalseexist = this.examLaf.laf.irisNormale.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.irisNormale.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.irisOG = this.examLaf.laf.irisNormale.oielGauche
      this.objecttoSend.source = "irisOG"
      this.sharedDataService.updateData(this.objecttoSend)
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.irisNormale.oielGauche.allAnomalies = this.examLaf.laf.irisNormale.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.irisOG = this.examLaf.laf.irisNormale.oielGauche
      this.objecttoSend.source = "irisOG"
      this.sharedDataService.updateData(this.objecttoSend)
      this.setToTrue(this.examLaf.laf.irisNormale.oielGauche)
      return true;
    }

  }
  checkExistAnomalieIrisLeft(id) {
    return this.examLaf.laf.irisNormale.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !--------------end of IRIS : Normale
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-iris') {
        this.anomaliesByDoctorIris.splice(this.anomaliesByDoctorIris.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  setToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "normale") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.normale.present = true;
    }
    return myObject
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }

}
