<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Papille" (click)="displayAndHidePapille()"> Papille</h4>
            <div class="arrow arrow-up btnPapille" *ngIf="displayPapille" title="Cacher Papille" (click)="displayAndHidePapille()">▲</div>
            <div class="arrow arrow-up btnPapille" *ngIf="!displayPapille" title="Afficher Papille" (click)="displayAndHidePapille()">▼</div>
        </div>
        <div *ngIf="displayPapille" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.normaleRapport.present==true}" (click)="addRemoveFromPapilleRight('Normale')">Normale</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.atrophiepupillaire.present==true}" (click)="addRemoveFromPapilleRight('Atrophie pupillaire')">Atrophie pupillaire</li>
                                <div *ngIf="showMorePapille">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.bouclesasculairesprepapillaires.present==true}" (click)="addRemoveFromPapilleRight('Boucles vasculaires prépapillaires')">Boucles vasculaires
                                        prépapillaires</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.drusen.present==true}" (click)="addRemoveFromPapilleRight('Drusen')">Drusen</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.dysversion.present==true}" (click)="addRemoveFromPapilleRight('Dysversion')">Dysversion</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.ElargissementdelazoneB.present==true}" (click)="addRemoveFromPapilleRight('Élargissement de la zone B')">Élargissement de la zone B
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.Excavationpathologique.present==true}" (click)="addRemoveFromPapilleRight('Excavation pathologique')">Excavation pathologique
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.ExclusiondUnvaisseauxcircumlineaire.present==true}" (click)="addRemoveFromPapilleRight('Exclusion d’un vaisseaux circum linéaire')">Exclusion d’un vaisseaux circum linéaire
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.fibreamyeline.present==true}" (click)="addRemoveFromPapilleRight('Fibre à myéline')">Fibre à myéline</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.papille.oielDroit.fossettecolobomateuse.present==true}" (click)="addRemoveFromPapilleRight('Fossette colobomateuse')">Fossette colobomateuse</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.hemorragieperipapillaire.present==true}" (click)="addRemoveFromPapilleRight('Hémorragie peripapillaire')">Hémorragie peripapillaire
                                </li>
                                <div *ngIf="showMorePapille">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.isntnonrespecte.present==true}" (click)="addRemoveFromPapilleRight('ISNT non respecté')">ISNT non respecté</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.papille.oielDroit.Morningglory.present==true}" (click)="addRemoveFromPapilleRight('Morning glory')">Morning glory</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.nevoxpapillaire.present==true}" (click)="addRemoveFromPapilleRight('Néo-vaisseaux papillaire')">Néo-vaisseaux papillaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.oedemepapillaire.present==true}" (click)="addRemoveFromPapilleRight('Oedème papillaire')">Oedème papillaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.papilleenfleurdeliseron.present==true}" (click)="addRemoveFromPapilleRight('Papille en fleur de liseron')">Papille en fleur de liseron
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.papille.oielDroit.paleurpapillaire.present==true}" (click)="addRemoveFromPapilleRight('Paleur papillaire')">Paleur papillaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielDroit.sriesangoide.present==true}" (click)="addRemoveFromPapilleRight('Stries angioïdes')">Stries angioïdes</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorPapille" [ngClass]="{'selectedLi': checkExistAnomaliePapilleRight(anomaly.id)}">
                                        <span (click)="addRemoveFromPapilleRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomaliePapilleRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-papille')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomaliePapille" (keyup.enter)="addNewAnomalyPapille($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>

                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMorePapilleSection() *ngIf="!showMorePapille">Voir plus</span>
                            <span (click)=showMorePapilleSection() *ngIf="showMorePapille">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle" style="cursor: pointer;">Oeil gauche</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.papille.oielGauche.normaleRapport.present==true}" (click)="addRemoveFromPapilleLeft('Normale')">Normale</li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.atrophiepupillaire.present==true}" (click)="addRemoveFromPapilleLeft('Atrophie pupillaire')">Atrophie pupillaire</li>
                                <div *ngIf="showMorePapille">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.bouclesasculairesprepapillaires.present==true}" (click)="addRemoveFromPapilleLeft('Boucles vasculaires prépapillaires')">Boucles vasculaires
                                        prépapillaires</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.drusen.present==true}" (click)="addRemoveFromPapilleLeft('Drusen')">Drusen</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.dysversion.present==true}" (click)="addRemoveFromPapilleLeft('Dysversion')">Dysversion</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.ElargissementdelazoneB.present==true}" (click)="addRemoveFromPapilleLeft('Élargissement de la zone B')">Élargissement de la zone B
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.Excavationpathologique.present==true}" (click)="addRemoveFromPapilleLeft('Excavation pathologique')">Excavation pathologique
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.papille.oielGauche.ExclusiondUnvaisseauxcircumlineaire.present==true}" (click)="addRemoveFromPapilleLeft('Exclusion d’un vaisseaux circum linéaire')">Exclusion d’un vaisseaux circum linéaire
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.fibreamyeline.present==true}" (click)="addRemoveFromPapilleLeft('Fibre à myéline')">Fibre à myéline</li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.fossettecolobomateuse.present==true}" (click)="addRemoveFromPapilleLeft('Fossette colobomateuse')">Fossette colobomateuse</li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.papille.oielGauche.hemorragieperipapillaire.present==true}" (click)="addRemoveFromPapilleLeft('Hémorragie peripapillaire')">Hémorragie peripapillaire
                                </li>
                                <div *ngIf="showMorePapille">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.isntnonrespecte.present==true}" (click)="addRemoveFromPapilleLeft('ISNT non respecté')">ISNT non respecté</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.Morningglory.present==true}" (click)="addRemoveFromPapilleLeft('Morning glory')">Morning glory</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.nevoxpapillaire.present==true}" (click)="addRemoveFromPapilleLeft('Néo-vaisseaux papillaire')">Néo-vaisseaux papillaire</li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.papille.oielGauche.oedemepapillaire.present==true}" (click)="addRemoveFromPapilleLeft('Oedème papillaire')">Oedème papillaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.papilleenfleurdeliseron.present==true}" (click)="addRemoveFromPapilleLeft('Papille en fleur de liseron')">Papille en fleur de liseron
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.paleurpapillaire.present==true}" (click)="addRemoveFromPapilleLeft('Paleur papillaire')">Paleur papillaire</li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.papille.oielGauche.sriesangoide.present==true}" (click)="addRemoveFromPapilleLeft('Stries angioïdes')">Stries angioïdes</li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorPapille" [ngClass]="{'selectedLi': checkExistAnomaliePapilleLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromPapilleLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomaliePapilleLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-papille')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomaliePapille" (keyup.enter)="addNewAnomalyPapille($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>

                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMorePapilleSection() *ngIf="!showMorePapille">Voir plus</span>
                            <span (click)=showMorePapilleSection() *ngIf="showMorePapille">Voir moins</span>
                        </div>
                    </div>
                </div>

            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.papille.oielDroit.normaleRapport.present==true">
                        <div class="row">
                            <h5 class="titleDetails">Normale</h5>
                            <div class="col-lg-6 col-md-6">
                                <label>Rapport C/D</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.papille.oielDroit.normaleRapport.rapportCD">
                                    <option value="1/10">1/10</option>
                                    <option value="2/10">2/10</option>
                                    <option value="3/10">3/10</option>
                                    <option value="4/10">4/10</option>
                                    <option value="5/10">5/10</option>
                                    <option value="6/10">6/10</option>
                                    <option value="7/10">7/10</option>
                                    <option value="8/10">8/10</option>
                                    <option value="9/10">9/10</option>
                                    <option value="10/10">10/10</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <label>Description</label>

                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.normaleRapport.description">
                            </div>

                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.oedemepapillaire.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Oedème papillaire</h5>
                        <div class="col-12">
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielDroit.oedemepapillaire.valeur=='Stade 1: Flous des bords papillaire'" (change)="selectoedemepapillaireRight('Stade 1: Flous des bords papillaire')">
                            Stade 1: Flous des bords papillaire<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielDroit.oedemepapillaire.valeur=='Stade 2: Oedème papillaire constitué'" (change)="selectoedemepapillaireRight('Stade 2: Oedème papillaire constitué')">
                            Stade 2: Oedème papillaire constitué<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielDroit.oedemepapillaire.valeur=='Stade 3: Atrophie optique débutante'" (change)="selectoedemepapillaireRight('Stade 3: Atrophie optique débutante')">
                            Stade 3: Atrophie optique débutante<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielDroit.oedemepapillaire.valeur=='Stade 4: Atrophie optique totale'" (change)="selectoedemepapillaireRight('Stade 4: Atrophie optique totale')">
                            Stade 4: Atrophie optique totale<br>
                        </div>

                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.oedemepapillaire.description">
                        </div>


                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.Excavationpathologique.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Excavation pathologique</h5>
                        <div class="col-md-6 col-lg-6">
                            <label for="">Rapport C/D</label>
                            <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.papille.oielDroit.Excavationpathologique.rapportCD">
                                <option value="1/10">1/10</option>
                                <option value="2/10">2/10</option>
                                <option value="3/10">3/10</option>
                                <option value="4/10">4/10</option>
                                <option value="5/10">5/10</option>
                                <option value="6/10">6/10</option>
                                <option value="7/10">7/10</option>
                                <option value="8/10">8/10</option>
                                <option value="9/10">9/10</option>
                                <option value="1">1</option>
                            </select>
                        </div>

                        <div class="col-md-6 col-lg-6">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.Excavationpathologique.description">
                        </div>

                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.dysversion.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Dysversion </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.dysversion.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.drusen.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Drusen </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.drusen.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.papilleenfleurdeliseron.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Papille en fleur de liseron </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.papilleenfleurdeliseron.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.fossettecolobomateuse.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fossette colobomateuse </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.fossettecolobomateuse.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.Morningglory.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Morning glory </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.Morningglory.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.paleurpapillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Paleur papillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.paleurpapillaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.atrophiepupillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Atrophie pupillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.atrophiepupillaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.fibreamyeline.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fibre à myéline </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.fibreamyeline.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.ElargissementdelazoneB.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Élargissement de la zone B </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.ElargissementdelazoneB.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.hemorragieperipapillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie peripapillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.hemorragieperipapillaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.isntnonrespecte.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">ISNT non respecté </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.isntnonrespecte.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.sriesangoide.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Stries angioïdes </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.sriesangoide.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.bouclesasculairesprepapillaires.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Boucles vasculaires prépapillaires </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.bouclesasculairesprepapillaires.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielDroit.nevoxpapillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Néo-vaisseaux papillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielDroit.nevoxpapillaire.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.papille.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->

                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.papille.oielGauche.normaleRapport.present==true">
                        <div class="row">
                            <h5 class="titleDetails">Normale</h5>
                            <div class="col-lg-6 col-md-6">
                                <label>Rapport C/D</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.papille.oielGauche.normaleRapport.rapportCD">
                                    <option value="1/10">1/10</option>
                                    <option value="2/10">2/10</option>
                                    <option value="3/10">3/10</option>
                                    <option value="4/10">4/10</option>
                                    <option value="5/10">5/10</option>
                                    <option value="6/10">6/10</option>
                                    <option value="7/10">7/10</option>
                                    <option value="8/10">8/10</option>
                                    <option value="9/10">9/10</option>
                                    <option value="10/10">10/10</option>
                                </select>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <label>Description</label>

                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.normaleRapport.description">
                            </div>

                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.oedemepapillaire.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Oedème papillaire</h5>
                        <div class="col-12">
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielGauche.oedemepapillaire.valeur=='Stade 1: Flous des bords papillaire'" (change)="selectoedemepapillaireRight('Stade 1: Flous des bords papillaire')">
                            Stade 1: Flous des bords papillaire<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielGauche.oedemepapillaire.valeur=='Stade 2: Oedème papillaire constitué'" (change)="selectoedemepapillaireRight('Stade 2: Oedème papillaire constitué')">
                            Stade 2: Oedème papillaire constitué<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielGauche.oedemepapillaire.valeur=='Stade 3: Atrophie optique débutante'" (change)="selectoedemepapillaireRight('Stade 3: Atrophie optique débutante')">
                            Stade 3: Atrophie optique débutante<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.papille.oielGauche.oedemepapillaire.valeur=='Stade 4: Atrophie optique totale'" (change)="selectoedemepapillaireRight('Stade 4: Atrophie optique totale')">
                            Stade 4: Atrophie optique totale<br>
                        </div>

                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.oedemepapillaire.description">
                        </div>


                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.Excavationpathologique.present==true" class="row p-r-t-15">
                        <h5 class="titleDetails">Excavation pathologique</h5>
                        <div class="col-md-6 col-lg-6">
                            <label for="">Rapport C/D</label>
                            <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.papille.oielGauche.Excavationpathologique.rapportCD">
                                <option value="1/10">1/10</option>
                                <option value="2/10">2/10</option>
                                <option value="3/10">3/10</option>
                                <option value="4/10">4/10</option>
                                <option value="5/10">5/10</option>
                                <option value="6/10">6/10</option>
                                <option value="7/10">7/10</option>
                                <option value="8/10">8/10</option>
                                <option value="9/10">9/10</option>
                                <option value="1">1</option>
                            </select>
                        </div>

                        <div class="col-md-6 col-lg-6">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.Excavationpathologique.description">
                        </div>

                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.dysversion.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Dysversion </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.dysversion.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.drusen.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Drusen </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.drusen.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.papilleenfleurdeliseron.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Papille en fleur de liseron </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.papilleenfleurdeliseron.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.fossettecolobomateuse.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fossette colobomateuse </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.fossettecolobomateuse.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.Morningglory.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Morning glory </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.Morningglory.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.paleurpapillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Paleur papillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.paleurpapillaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.atrophiepupillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Atrophie pupillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.atrophiepupillaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.fibreamyeline.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Fibre à myéline </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.fibreamyeline.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.ElargissementdelazoneB.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Élargissement de la zone B </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.ElargissementdelazoneB.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.hemorragieperipapillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie peripapillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.hemorragieperipapillaire.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.isntnonrespecte.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">ISNT non respecté </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.isntnonrespecte.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.sriesangoide.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Stries angioïdes </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.sriesangoide.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.bouclesasculairesprepapillaires.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Boucles vasculaires prépapillaires </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.bouclesasculairesprepapillaires.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.papille.oielGauche.nevoxpapillaire.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Néo-vaisseaux papillaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.papille.oielGauche.nevoxpapillaire.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.papille.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->

                    <div class="row emptyBloc"></div>
                </div>

            </div>
        </div>
    </div>
</div>