import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    // Separate patients reaching resTime 00:00
    const patientsWithResTimeZero = array.filter(patient => {
      const [hours, minutes] = patient.tempsRV.split(':').map(Number);
      const rvTime = new Date();
      rvTime.setHours(hours);
      rvTime.setMinutes(minutes);
      const currentTime = new Date();
      const timeDifference = rvTime.getTime() - currentTime.getTime();
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      return minutesDifference < 0;
    });

    // Sort patients by tempsRV
    array.sort((a: any, b: any) => {
      const [aHours, aMinutes] = a.tempsRV.split(':').map(Number);
      const [bHours, bMinutes] = b.tempsRV.split(':').map(Number);

      // Compare the hours first
      if (aHours !== bHours) {
        return aHours - bHours;
      }

      // If hours are equal, compare the minutes
      return aMinutes - bMinutes;
    });

    // Move patients with resTime 00:00 to the end
    array = array.filter(patient => !patientsWithResTimeZero.includes(patient));
    array = array.concat(patientsWithResTimeZero);

    return array;
  }
}
