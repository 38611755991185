<div class="footer">
    <div class="row justify-content-center row-newsletter">
        <div class="col-md-3">
            <h4><b>Inscrivez-vous à notre newsletter</b></h4>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-7">
            <div class="row">
                <div class="col-8 newsletter-col-10">
                    <input type="email" name="mail" id="newsletter" placeholder="E-mail"
                        style="padding: 0% 8% !important;height: 60px;">
                </div>
                <div class="col-4 newsletter-col-1">
                    <button class="btn btn-newsletter text-ippercase">S'inscrire</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" style="padding: 1% 0% !important; margin: 0% 0% !important;">
        <div class="col-5" style="margin-top: 0.5%;padding: 0% 0% !important;">
            <hr class="hr-footer">
        </div>
        <div class="col-2 socials text-center" style="position: relative;top: -9px;">
            <img src="assets/images/facebook.png" class="img-social-media" alt="Facebook">
            <img src="assets/images/instagram.png" class="img-social-media" alt="Instagram">
            <img src="assets/images/youtube.png" class="img-social-media" alt="Youtube">
        </div>
        <div class="col-5" style="margin-top: 0.5%;padding: 0% 0% !important;">
            <hr class="hr-footer">
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-auto" style="margin-top: 3%;">
            <br>
            <img src="assets/images/medivisto-blanc.png" alt="alternative">
        </div>
        <div class="col-auto info p-5">
            <h6>SPÉCIALITÉS</h6>
            <p style="cursor:pointer" (click)="setSpecialite('Cardiologue')">Cardiologue</p>
            <p style="cursor:pointer" (click)="setSpecialite('Pédiatre')">Pédiatre</p>
            <p style="cursor:pointer" (click)="setSpecialite('Généraliste')">Généraliste</p>
            <p style="cursor:pointer" (click)="setSpecialite('Dentiste')">Dentiste</p>
            <p style="cursor:pointer" (click)="setSpecialite('Chirurgien')">Chirurgien</p>
        </div>
        <div class="col-auto socials p-5">
            <h6>SUPPORT</h6>
            <p>A propos de nous</p>
            <p>Politique de Confidentialité</p>
            <p>Conditions générales d'utilisation</p>
        </div>
    </div>
    <div class="copyrights d-flex text-center">
        <p>
            &copy; Tout droits réservés 2021 · Conception & Développement par VOGUEL CONSULTING
        </p>
    </div>
</div>