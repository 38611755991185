import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, ElementRef, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { throttleTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { _getURL } from 'config/API_CONFIG';
import { ExamenService } from 'src/app/services/examen/examen.service';


@Component({
  selector: 'app-side-bar-droit',
  templateUrl: './side-bar-droit.component.html',
  styleUrls: ['./side-bar-droit.component.css','../../interrogatoire/interrogatoire.component.css']
})
export class SideBarDroitComponent implements OnInit {
  selectedMedecinn:any
  @Input() myExamen: any;
  patient: any;
  //side bar variables
  inspectionModel= {
    allAnomalies: [],
    attitudedemalvoyance: { present: false },
    buphtalmie: { present: false },
    enophtalmie: { present: false },
    exphtalmie: { present: false },
    leucocorie: { present: false },
    microphtalmie: { present: false },
    normale: { present: true },
    paralysiefaciale: { present: false },
    plaies: { present: false },
    protheseoculaire: { present: false },
    ptosis: { present: false },
    strabisme: { present: false }
  };
  annexeModel={
    allAnomalies: [],
    sondedeDCRenplace: { present: false},
    ecoulementlacrymal: {present: false},
    fistule: { present: false },
    massedelalogelacrymal: { present: false},
    stenoselacrymal: { present: false},
    dcc: {  present: false},
    dca: { present: false},
    tumeurpaplepbrale: { present: false},
    telangiectasie: { present: false},
    dgm: { present: false },
    meeibomite: { present: false},
    distichoasis: { present: false },
    diplopie: { present: false},
    ectropion: { present: false},
    entropion: {present: false},
    orgelet: { present: false},
    chalazion: { present: false },
    ptosis: { present: false},
    dermabrasion: { present: false},
    plaie: {present: false},
    presencedestrabisme: {present: false},
    refletcorneendecentre: { present: false},
    insuffisancedeconvergence: {present: false },
    ophtalmologie: {present: false},
    paralysieocculomotrice: {
        nerf: [],
        muscle: [],
        present: false
    },
    normale: {present: true}
}
private readonly rightEdgeThreshold = window.innerWidth - 10;
  private readonly mouseMoveSubject = new Subject < MouseEvent > ();
  receivedData = {
    inspectionOD:this.inspectionModel,
    inspectionOG:this.inspectionModel,
    annexeOD:this.annexeModel,
    annexeOG:this.annexeModel,
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    additionalConclusion: "",
    haveSpecificDiagnostic: false,
    source: "",
    sansCycloSphereDroit: 0,
    sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0,
    sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0,
    avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0,
    avecCycloCylindreGauche: 0,
    addOD: 0,
    addOG: 0,
    nextAppointment: "",
    nextAppointmentDuration: "",
    nextMotif: ""
  };
  constructor(private route: ActivatedRoute, private examenService: ExamenService, private patientService: PatientService,
    private router: Router, private _sanitizer: DomSanitizer, private medecinService: MedecinService, public iziToast: Ng2IzitoastService,
    private sharedDataService: SharedDataService,
  ) {
    this.mouseMoveSubject.pipe(throttleTime(500)).subscribe((event) => this.handleMouseMove(event));
  }
  ngOnInit(){
    this.sharedDataService.data$.subscribe((data) => {
      if (data) {
        if(data.source="annexeOD"){
          this.receivedData.annexeOD={...this.receivedData.annexeOD,...data.annexeOD}
        }
        if(data.source="annexeOG"){
          this.receivedData.annexeOG={...this.receivedData.annexeOG,...data.annexeOG}
        }
        if(data.source="inspectionOD"){
          this.receivedData.inspectionOD={...this.receivedData.inspectionOD,...data.inspectionOD}
        }
        if(data.source="inspectionOG"){
          this.receivedData.inspectionOG={...this.receivedData.inspectionOG,...data.inspectionOG}
        }

        if (data.source === "tags") {
          this.receivedData.cat = data.cat
        }
        if (data.source === "haveSpecificDiagnostic") {
          this.receivedData.haveSpecificDiagnostic = data.haveSpecificDiagnostic
          this.receivedData.additionalConclusion = data.additionalConclusion
        }
        if (data.source === "TOOD") {
          this.receivedData.TOOD = data.TOOD
        }
        if (data.source === "TOOG") {
          this.receivedData.TOOG = data.TOOG
        }
        if (data.source === "butOD") {
          this.receivedData.butOD = data.butOD
        }
        if (data.source === "butOG") {
          this.receivedData.butOG = data.butOG
        }
        if (data.source === "shirmerOD") {
          this.receivedData.shirmerOD = data.shirmerOD
        }
        if (data.source === "shirmerOG") {
          this.receivedData.shirmerOG = data.shirmerOG
        }
        if (data.source === "sansCycloSphereDroit") {
          this.receivedData.sansCycloSphereDroit = data.sansCycloSphereDroit
        }
        if (data.source === "sansCycloSphereGauche") {
          this.receivedData.sansCycloSphereGauche = data.sansCycloSphereGauche
        }
        if (data.source === "sansCycloCylindreDroit") {
          this.receivedData.sansCycloCylindreDroit = data.sansCycloCylindreDroit
        }
        if (data.source === "sansCycloCylindreGauche") {
          this.receivedData.sansCycloCylindreGauche = data.sansCycloCylindreGauche
        }
        if (data.source === "avecCycloSphereDroit") {
          this.receivedData.avecCycloSphereDroit = data.avecCycloSphereDroit
        }
        if (data.source === "avecCycloSphereGauche") {
          this.receivedData.avecCycloSphereGauche = data.avecCycloSphereGauche
        }
        if (data.source === "avecCycloCylindreDroit") {
          this.receivedData.avecCycloCylindreDroit = data.avecCycloCylindreDroit
        }
        if (data.source === "avecCycloCylindreGauche") {
          this.receivedData.avecCycloCylindreGauche = data.avecCycloCylindreGauche
        }
        if (data.source === "addOD") {
          this.receivedData.addOD = data.addOD
        }
        if (data.source === "addOG") {
          this.receivedData.addOG = data.addOG
        }
        if (data.source === "prochaineRdv") {
          this.receivedData.nextMotif = data.nextMotif
          this.receivedData.nextAppointmentDuration = data.nextAppointmentDuration
          this.receivedData.nextAppointment = data.nextAppointment
        }
      }
    });
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    const idPatient = this.route.snapshot.paramMap.get('id');
    // get examen
    // if (idExamen && idExamen != undefined && idExamen != "undefined" && idExamen != null) {

    //   this.examenService.getExamenById(idExamen).subscribe((data: any) => {
    //       this.myExamen = data
    //       if (this.myExamen) {
    //         this.configureDataExamen()
            
    //       } 
    //     },
    //     err => {
    //       this.router.navigate(['/medecin/patients-liste']);
    //     });
    // } else {
    //   this.router.navigate(['/medecin/patients-liste']);
    // }
    
  }
  configureDataExamen() {
    console.log("🚀 ~ SideBarDroitComponent ~ this.sharedDataService.data$.subscribe ~ myexamen:", this.myExamen)
    // get old consultations
    if (this.myExamen.interrogation && this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique && this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy) {
      this.selectedMedecinn = this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy
    }
    this.receivedData.inspectionOD=this.myExamen.inspection.anomaly.oielDroit
    this.receivedData.inspectionOG=this.myExamen.inspection.anomaly.oielGauche
    this.receivedData.annexeOD=this.myExamen.annexe.anomaly.oielDroit
    this.receivedData.annexeOG=this.myExamen.annexe.anomaly.oielGauche
    this.receivedData.cat = this.myExamen.tags
    this.receivedData.haveSpecificDiagnostic = this.myExamen.haveSpecificDiagnostic
    this.receivedData.additionalConclusion = this.myExamen.additionalConclusion
    this.receivedData.TOOD = this.myExamen.laf.toAic.oielDroit.mm
    this.receivedData.TOOG = this.myExamen.laf.toAic.oielGauche.mm
    this.receivedData.butOD = this.myExamen.laf.butShirmer.oielDroit.secondes
    this.receivedData.butOG = this.myExamen.laf.butShirmer.oielGauche.secondes
    this.receivedData.shirmerOD = this.myExamen.laf.butShirmer.oielDroit.mm
    this.receivedData.shirmerOG = this.myExamen.laf.butShirmer.oielGauche.mm
    if (this.myExamen.refraction.sansCycloplegie.present) {
      this.receivedData.sansCycloSphereDroit = this.myExamen.refraction.sansCycloplegie.oielDroit.sphere;
      this.receivedData.sansCycloSphereGauche = this.myExamen.refraction.sansCycloplegie.oielGauche.sphere;
      this.receivedData.sansCycloCylindreDroit = this.myExamen.refraction.sansCycloplegie.oielDroit.cylindre;
      this.receivedData.sansCycloCylindreGauche = this.myExamen.refraction.sansCycloplegie.oielGauche.cylindre;
    }
    if (this.myExamen.refraction.avecCycloplegie.present) {
      this.receivedData.avecCycloSphereDroit = this.myExamen.refraction.avecCycloplegie.oielDroit.sphere;
      this.receivedData.avecCycloSphereGauche = this.myExamen.refraction.avecCycloplegie.oielGauche.sphere;
      this.receivedData.avecCycloCylindreDroit = this.myExamen.refraction.avecCycloplegie.oielDroit.cylindre;
      this.receivedData.avecCycloCylindreGauche = this.myExamen.refraction.avecCycloplegie.oielGauche.cylindre;
    }
    if (this.myExamen.refraction.acuitevisueldepres.present) {
      this.receivedData.addOD = this.myExamen.refraction.acuitevisueldepres.oielDroit.add;
      this.receivedData.addOG = this.myExamen.refraction.acuitevisueldepres.oielGauche.add;
    }
    this.receivedData.nextMotif = this.myExamen.nextMotif
    this.receivedData.nextAppointmentDuration = this.myExamen.nextAppointmentDuration
    this.receivedData.nextAppointment = this.myExamen.nextAppointment
  }

  handleMouseMove(event: MouseEvent) {
    // if (event.clientX >= this.rightEdgeThreshold) {
    //   // Call your function or perform any action here
    //   document.getElementById('btn-siderbar').click();
    //   return true;
    // }
    // if (event.clientX <  200) {
    //   document.getElementById('btn-siderbar-left').click();
    //   return true;
    // }
  }

}
