import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms'
import { ConsultationService } from '../../../../services/consultation/consultation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidateService } from '../../../../services/validate/validate.service';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { ValidationService } from '../../../../services/validate/validateError.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { MedicamentsService } from '../../../../services/medicaments/medicaments.service';
import { allTitreConsultations } from '../../../pays/consultation';
import { titreConsultations } from '../../../pays/consultation';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { Select2OptionData } from 'ng-select2';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
@Component({
  selector: 'app-update-consultation',
  templateUrl: './update-consultation.component.html',
  styleUrls: ['../../../login/login-medecin/login-medecin.component.css','../../../register/register/register.component.scss','./update-consultation.component.css']
})
export class UpdateConsultationComponent implements OnInit {

  medecin: any;
  form: any;
  secretaire: any;
  consultation:any;
  public items: any[]=[]
  private value: any = ['FLUOROURACIL'];
  completeItems: Array<string> = [];
  showautre=false
  titres: any[] = [];
  public medicamentsData: Array<any>;
  public options: any;
  startValue:any;
  screenWidth:any
  ng2slectwidh:any
  constructor(private authService: AuthService,  private router: Router,  private consultationService: ConsultationService,private medicamentsService:MedicamentsService,
     private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,private responsiveService:ResponsiveService,) {
      this.form = new FormGroup({});
      this.form = this.formBuilder.group({
        titre: ['', [Validators.minLength(3), Validators.maxLength(90)]],
        autre: ['', [ Validators.minLength(3), Validators.maxLength(25)]],
        date: ['', Validators.required],
        descriptif: ['',Validators.maxLength(500)],
        medicaments: this.form.addControl('medicaments', new FormControl('')),

      });
      let params: any = this.activatedRoute.snapshot.params;
      this.consultationService.geetConsultationById(params.id).subscribe(consultation => {
        this.consultation = consultation;
        if(this.consultation && this.consultation.titre === 'Autre'){
          this.showautre = true;
        }
      },
        err => {
          return false;
        });
  }

  ngOnInit(): void {
    let el = document.getElementById('demo');
    el.scrollIntoView({ behavior: "smooth" });
    this.screenWidth = window.innerWidth;
// Subscribe to screen width changes
this.responsiveService.getScreenWidth().subscribe((width) => {
  this.screenWidth = width;
  if (this.screenWidth < 768) {
    // Handle small screens
    this.ng2slectwidh=500
    return true;
  } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
    // Handle medium screens
    this.ng2slectwidh=1000
    return true;
  } else {
    // Handle large screens
    this.ng2slectwidh=1400
    return true;
  }
});
    this.medicamentsData = [
      {
        id: 'multiple1',
        text: 'Multiple 1'
      }
    ];
    this.options = {
      multiple: true,
      maximumSelectionLength: 20
    }
    this.titres = allTitreConsultations
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile:any) => {
        this.medecin = profile.medecin;
        this.getTitresConsultations(this.medecin.specialite)
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
        this.medicamentsService.getAlluniqueNomMedicaments(this.medecin._id).subscribe((medcments:any[]) => {
          this.items = medcments;
          this.medicamentsData = this.items
          var myData = []
          if(this.consultation && this.consultation.medicaments && this.consultation.medicaments.length > 0){
              for(var t = 0;t<this.consultation.medicaments.length;t++){
                var found = this.items.find(x => x == this.consultation.medicaments[t])
                myData.push(found)
                this.startValue = myData
              }
            }
        },
          err => {
            return false;
          });
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;

      this.authService.getProfileSecretaire().subscribe((profile:any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }




  }
  public selected(value: any): void {
  }
  public removed(value: any): void {
  }
  public typed(value: any): void {
  }
  public refreshValue(value: any): void {
    this.value = value;
  }
  getTitresConsultations(specialite){
    if(specialite === "Généraliste"){
      this.titres = titreConsultations[0].titres
    }
    if(specialite === "Dentiste"){
      this.titres = titreConsultations[1].titres
    }
  }
  onUpdateconsultation(consultation) {
    this.consultationService.onUpdateConsultation(consultation)
      .subscribe(data => {
        this.router.navigate(['/medecin/consultation-liste',consultation.patientId])
        Swal.fire( {
            imageUrl: 'assets/images/patsDetails/edit-2.png',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            width: 600,
            title: 'BIEN MODIFIÉ',
            timer: 1500,
            showConfirmButton: false
          }
        );
      });
  }
  // filter des medicaments
  public searchMed(searchString: string) {
    if (searchString.length > 2) { //3 character minimum
      this.completeItems = this.items
    }
    else {
      this.completeItems = [];
    }
  }

  selectTitre(){
    this.showautre = false;
    if(this.form.value.titre === 'Autre'){
      this.showautre = true;
    }
  }
}

