import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { throttleTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from '../../../../services/examen/examen.service';
import * as moment from 'moment';
import 'moment-timezone';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { WebcamImage } from 'ngx-webcam';
import { _getURL } from 'config/API_CONFIG';
import { medicaments } from './../../medicaments/allMedicaments';
import { motifMinLength, motifMaxLength, champSpecialCaracters, champMinLength, descriptionMaxLength, examChampMaxLength } from '../../../../services/constants/messages';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { AntecedentsGeneraux } from 'src/app/services/interface/AntecedentsGeneraux';


@Component({
  selector: 'app-interrogatoire',
  templateUrl: './interrogatoire.component.html',
  styleUrls: ['./interrogatoire.component.css'],

})
export class InterrogatoireComponent implements OnInit, OnDestroy {
  timer: string = "";
  minutes = 0;
  hour = 0;
  seconds = 0;
  setTimer: string = "";
  isStart: boolean = false;
  timerInterval: any;
  showAndHidePlaytimer = false
  isActiveInterrogatoire = true;
  isActiveExamen = false;
  isActiveConduite = false;
  isActiveDocuments = false;
  isActivgene = false;
  isActiveOphtaPerso = true;
  isActiveConclusion = false;
  isActiveAnteFamiOpht = false
  // list type antécédent 
  listtypeAnteFamiOpht = []
  typeAnteFamiOpht = ""
  habitudeDeVie = ""
  listhabitudeDeVie = []
  terrainatopique = false;
  terrainatopiqueInput = ""
  terrainatopiqueInputGauche = ""
  listterrainOptique = []
  terrainvasculaireparticulier = false;
  diabete = false;
  hta = false;
  terrainvasculaireparticulierautre = false;
  terrainimmunodepression = false;
  maladiedysimunitaire = false;
  maladieneurologique = false;
  terrainimmunodepressionInput: any = "";
  listterrainimmunodepression = []
  maladiedysimunitaireInput: any = "";
  maladiedysimunitaireInputGauche: any;
  listmaladiedysimunitaire = []
  listmaladieneurologique = []
  maladieneurologiqueInput: any = "";
  maladieneurologiqueInputGauche: any;
  notionvaccinationrecente = false;
  notionanesthesierecente = false;
  traitementencours = false
  casparticuliernourrissonenfant = false;
  listCasParticulierNourissonEnfant = []
  premireconsultationophtalmologie = false;
  lastvistwithus = false;
  lastvistwithotherdoctor = false;
  maladiedysimunitairelist: any[] = ['SEP', 'SPA', 'LUPUS']
  maladieNeurologiqueList: any[] = ['AVC', 'NMO', 'SUSAC']
  terrainAtopiqueList: any[] = ['Acarien', 'Humidité', 'Poils d’animaux']
  terrainimmunodepressionList: any[] = ['Chimiothérapie', 'VIH', 'Tumeur']
  typeAntecedFamilauxList: any[] = ['None', 'Glaucome', 'DMLA', 'Dystrophie cornéenne', 'Cataracte']
  HabitudedeVideList: any[] = ['None', 'Sports', 'Alimentation équilibrée', 'Hydratation', 'Activité physique', 'Sommeil adéquat', 'Gestion du stress', 'Apprentissage continu']
  // motif
  selectedMotif: any
  selectedMotifs: string[] = [];
  motifList = ["Amputation du champ visuel progressive",
    "Amputation du champvisuel brutal",
    "BAV loin brutal les deux yeux",
    "BAV loin brutal œil droit",
    "BAV loin brutal œil gauche",
    "BAV loin progressive des deux yeux",
    "BAV loin progressive œil droit",
    "BAV loin progressive œil gauche",
    "BAV près brutal les deux yeux",
    "BAV près brutal œil droit",
    "BAV près brutal œil gauche",
    "BAV près progressive des deux yeux",
    "BAV près progressive œil droit",
    "BAV près progressive œil gauche",
    "Blepharoptosis",
    "Blépharospasme",
    "Brouillard visuel",
    "Chute des cils",
    "Correction optique",
    "Diminution vision contraste",
    "Diplopie brutal",
    "Diplopie progressive",
    "Douleur Oculaire",
    "Douleur oculaire majorée à la mobilisation du globe",
    "Douleur palpébrale bilatérale",
    "Douleur palpébrale œil droit",
    "Douleur palpébrale œil gauche",
    "Flou visuel loin brutal les deux yeux",
    "Flou visuel loin brutal œil droit",
    "Flou visuel loin brutal œil gauche",
    "Flou visuel loin progressive des deux yeux",
    "Flou visuel loin progressive œil droit",
    "Flou visuel loin progressive œil gauche",
    "Flou visuel près brutal les deux yeux",
    "Flou visuel près brutal œil droit",
    "Flou visuel près brutal œil gauche",
    "Flou visuel près progressive des deux yeux",
    "Flou visuel près progressive œil droit",
    "Flou visuel près progressive œil gauche",
    "Halos colorés",
    "Héméralopie",
    "Lagophtalmie",
    "Larmoiement",
    "Myodésopsie",
    "Phosphene",
    "Photophobie",
    "Photopsie",
    "Prurit oculaire",
    "Rougeur oculaire",
    "Rougeur palpébrale",
    "Sécrétion claires",
    "Sécrétion purulentes",
    "Sensation de corp étranger",
    "Sensation de fatigue visuelle",
    "Sensation de sécheresse oculaire",
    "Voile noir devant les yeux",
    "Voile rouge devant les yeux"
  ]
  isHovered = false;
  myExamen: any;
  patient: any;
  messageError: string = ''
  //side bar variables
  inspectionModel = {
    allAnomalies: [],
    attitudedemalvoyance: { present: false },
    buphtalmie: { present: false },
    enophtalmie: { present: false },
    exphtalmie: { present: false },
    leucocorie: { present: false },
    microphtalmie: { present: false },
    normale: { present: true },
    paralysiefaciale: { present: false },
    plaies: { present: false },
    protheseoculaire: { present: false },
    ptosis: { present: false },
    strabisme: { present: false }
  };
  annexeModel = {
    allAnomalies: [],
    sondedeDCRenplace: { present: false },
    ecoulementlacrymal: { present: false },
    fistule: { present: false },
    massedelalogelacrymal: { present: false },
    stenoselacrymal: { present: false },
    dcc: { present: false },
    dca: { present: false },
    tumeurpaplepbrale: { present: false },
    telangiectasie: { present: false },
    dgm: { present: false },
    meeibomite: { present: false },
    distichoasis: { present: false },
    diplopie: { present: false },
    ectropion: { present: false },
    entropion: { present: false },
    orgelet: { present: false },
    chalazion: { present: false },
    ptosis: { present: false },
    dermabrasion: { present: false },
    plaie: { present: false },
    presencedestrabisme: { present: false },
    refletcorneendecentre: { present: false },
    insuffisancedeconvergence: { present: false },
    ophtalmologie: { present: false },
    paralysieocculomotrice: {
      nerf: [],
      muscle: [],
      present: false
    },
    normale: { present: true }
  }
  sclereModel = {
    allAnomalies: [],
    sclerite: { present: false },
    episclerite: { present: false },
    scleromalacie: { present: false },
    plaie: { present: false },
    blancheEtIntacte: { present: true }
  }
  conjModel = {
    normocoloreEtIntacte: { present: true },
    plaie: { present: false },
    Chemosis: { present: false },
    hemorragiesousconjonctival: { present: false },
    secretion: { present: false },
    micropapille: { present: false },
    macropapille: { present: false },
    papillegeante: { present: false },
    follicule: { present: false },
    faussemembrane: { present: false },
    Pemphigoide: { present: false },
    hyperhemieconj: { present: false },
    fibroseconjonctival: { present: false },
    icterique: { present: false },
    symblepharon: { present: false },
    ankyloblepharon: { present: false },
    cercleperikeratique: { present: false },
    ptgStade: { present: false },
    tumeurconjonctivale: { present: false },
    granulomeconjonctivale: {
      present: false
    },
    allAnomalies: []
  }
  corneeModel = {
    allAnomalies: [],
    anneuximmunitaire: { present: false },
    buphtalmie: { present: false },
    gerontoxon: { present: false },
    leucome: { present: false },
    neovascularisationcorneenne: { present: false },
    noduledetrantas: { present: false },
    plaquevernale: { present: false },
    ulcerevernal: { present: false },
    hypoesthesie: { present: false },
    epaississementepithelialeStromale: { present: false },
    hypoesthesiecorneenne: { present: false },
    irregularitePlisdeladescemet: { present: false },
    visibiliteanormaledesnerfscorneen: { present: false },
    striesdevogt: { present: false },
    KB: { present: false },
    Keratopathieenbandelette: { present: false },
    microkysteEpitheliaux: { present: false },
    bullesousepitheliale: { present: false },
    bueeEpitheliale: { present: false },
    endothelite: { present: false },
    perforationcorneenne: { present: false },
    KPS: { present: false },
    oedemecorneen: { present: false },
    PRD: { present: false },
    NSE: { present: false },
    pointcorneen: { present: false },
    plaiescorneenne: { present: false },
    microkystique: { present: false },
    hematocornee: { present: false },
    hydrops: { present: false },
    abces: { present: false },
    ulcerationcorneenne: { present: false },
    corneaguttata: { present: false },
    opacite: { present: false },
    opaque: { present: false },
    transparente: { present: true }
  }

  chambreModel = {
    allAnomalies: [],
    testdevanherick: { present: false },
    IP: { present: false },
    corpsetranger: { present: false },
    CAetroite: { present: false },
    ICA: { present: false },
    pigments: { present: false },
    mechedevitre: { present: false },
    signedulimbe: { present: false },
    cyclodialyse: { present: false },
    recession: { present: false },
    massecristallinienne: { present: false },
    hyphema: { present: false },
    hypopion: { present: false },
    hemorragiesousconjonctival: { present: false },
    tyndallproteique: { present: false },
    tyndallcellulaire: { present: false },
    calmeEtProfonde: { present: true }
  }

  irisModel = {
    allAnomalies: [],
    cristauxirien: { present: false },
    naevusirien: { present: false },
    synechieiridocristalienne: { present: false },
    atrophieirienne: { present: false },
    plaieirienne: { present: false },
    convexe: { present: false },
    irisconcave: { present: false },
    granulomeirien: { present: false },
    melanomeMassesuspecte: { present: false },
    SPEirien: { present: false },
    rubeose: { present: false },
    heterochromie: { present: false },
    noduleirien: { present: false },
    normale: { present: true }
  }
  rpmModel = {
    allAnomalies: [],
    marcusgun: { present: false },
    semimydriase: { present: false },
    mydriase: { present: false },
    myosis: { present: false },
    presentEtSymetrique: { present: true }
  }
  dilatationModel = {
    allAnomalies: [],
    moyenne: { present: false },
    mauvaise: { present: false },
    bonne: { present: true }
  }
  cristallinModel = {
    allAnomalies: [],
    cataracte: { present: false },
    phacosclerose: { present: false },
    capsuleantrompu: { present: false },
    corpsetranger: { present: false },
    ectopie: { present: false },
    luxationSubluxation: { present: false },
    phacodonesis: { present: false },
    glaucomeflecken: { present: false },
    fragilitezonulaire: { present: false },
    capsuleantcalcifie: { present: false },
    SPE: { present: false },
    microspheiohaquie: { present: false },
    pseudophaque: { present: false },
    aphaquie: { present: false },
    transparent: { present: true }
  }
  angleIridoModel = {
    allAnomalies: [],
    apposition: { present: false },
    hypopion: { present: false },
    corpsetranger: { present: false },
    rubeose: { present: false },
    hyphema: { present: false },
    synechie: { present: false },
    pigmente: { present: false },
    occludable: { present: false },
    ferme: { present: false },
    ouvertSur360: { present: true }
  }
  vitreModel = {
    allAnomalies: [],
    precipiteencolierdeperle: { present: false },
    liquefaction: { present: false },
    hemorragiepreretinienne: { present: false },
    corpsasterode: { present: false },
    synchesisetincelant: { present: false },
    brides: { present: false },
    LACUNE: { present: false },
    cordage: { present: false },
    voilevitreen: { present: false },
    fragmentcristallinien: { present: false },
    corpsetrangers: { present: false },
    persistanceduvitreprimitif: { present: false },
    massecristallinienne: { present: false },
    Lacune: { present: false },
    DPV: { present: false },
    oeufdefourmis: { present: false },
    banquise: { present: false },
    essaimagetumoral: { present: false },
    pigmentaire: { present: false },
    hemorragievitreene: { present: false },
    hematique: { present: false },
    proteique: { present: false },
    tyndallcellulaire: { present: false },
    calmeEtAcellulaire: { present: true }
  }
  papilleModel = {
    allAnomalies: [],
    nevoxpapillaire: { present: false },
    bouclesasculairesprepapillaires: { present: false },
    sriesangoide: { present: false },
    isntnonrespecte: { present: false },
    hemorragieperipapillaire: { present: false },
    ExclusiondUnvaisseauxcircumlineaire: { present: false },
    ElargissementdelazoneB: { present: false },
    Excavationpathologique: { present: false },
    fibreamyeline: { present: false },
    atrophiepupillaire: { present: false },
    paleurpapillaire: { present: false },
    Morningglory: { present: false },
    fossettecolobomateuse: { present: false },
    papilleenfleurdeliseron: { present: false },
    drusen: { present: false },
    dysversion: { present: false },
    oedemepapillaire: { present: false },
    normaleRapport: { present: true }
  }
  maculaModel = {
    allAnomalies: [],
    aspectjaunedoeufauplat: { present: false },
    DSR: { present: false },
    DEP: { present: false },
    remaniementmicorkystique: { present: false },
    macroanevrysme: { present: false },
    couronneexcsudatif: { present: false },
    aspectpoivreetsel: { present: false },
    macularougecerise: { present: false },
    pigmentationanormale: { present: false },
    aspectenoeildeboeuf: { present: false },
    eLogettemaculaire: { present: false },
    plagedatrophie: { present: false },
    etoilemaculaire: { present: false },
    dreusenmaculaire: { present: false },
    membraneepimaculairen: { present: false },
    trousmaculaire: { present: false },
    hemorragieretrohyaloidienne: { present: false },
    hematomemaculaire: { present: false },
    hemorragiemaculaire: { present: false },
    perterefletfoveolaire: { present: false },
    normale: { present: true }
  }
  anomalieVasculaireModel = {
    allAnomalies: [],
    vasculite: { present: false },
    signedOcclusionARTERIELLE: { present: false },
    signedOcclusionVeineuse: { present: false },
    signedeRD: { present: false },
    signedeRH: { present: false },
    arteriosclerose: { present: false },
    normale: { present: true }
  }
  retineModel = {
    allAnomalies: [],
    tachederp: { present: false },
    pigmentation: { present: false },
    exsudat: { present: false },
    macroanevrisme: { present: false },
    microanevrisme: { present: false },
    nodulecotonneux: { present: false },
    hemorragieretinienne: { present: false },
    blancavecpression: { present: false },
    blancsanspression: { present: false },
    givre: { present: false },
    palissade: { present: false },
    DSR: { present: false },
    DEP: { present: false },
    masseretinochoroidienne: { present: false },
    retinoschisis: { present: false },
    foyer: { present: false },
    degenerescencelattice: { present: false },
    decollementchorodien: { present: false },
    dehiscenceretinienne: { present: false },
    decollementretine: { present: false },
    normale: { present: true }
  }

  //---------------------------------start médicaux variables
  medicauxList = [
    "None",
    "Glaucome primitif à angle ouvert",
    "Glaucome primitif à angle fermé",
    "Glaucome exfoliatif",
    "Glaucome cortisonique",
    "Strabisme",
    "Amblyopie",
    "Conjonctivite",
    "Chalazion",
    "Orgelet",
    "Pterygion",
    "Retinopathie diabetique",
    "Oedeme maculaire diabetique",
    "Uveite",
    "Myopie",
    "Hypermetropie",
    "Presbytie",
    "Astigmatisme",
    "Neuropathie optique",
    "Occlusion veine centrale de la rétine",
    "Occlusion d'une branche veineuse rétinienne",
    "Occlusion artère centrale de la rétine",
    "Dégénérescence maculaire liée à l'âge",
    "Maculopathie liée à l'âge",
    "Trous maculaire",
    "Membrane épimaculaire",
    "Cataracte",
    "Insuffisance de convergence",
    "Toxoplasmose oculaire",
    "Toxocarose",
    "Keratocone",
    "Degenerence pellucide marginale",
    "Dystrophie corneenne",
    "Retinoblastome",
    "Sécheresse oculaire"
  ].sort((a, b) => {
    if (a === "None") return -1;
    if (b === "None") return 1;
    return a.localeCompare(b);
  }).map((str) => {
    return {
      name: str,
      date: undefined
    };
  });

  medicauxListt = this.medicauxList.map(el => el.name);
  options

  selectedMedical: any = ''
  selectedMedicalGauche: any = "";
  //---------------------------------------end médicaux variables
  // ------------------------------------- start traitement en cours variables
  selectedTraitement: any = ""
  selectedTraitementGauche: any = ""

  // -------------------------------------- end traitement en cours variables
  // -------------------------------------start Antécédents de traitement par laser variable
  listAntecdantLaser = ["None", "Capsulotomie au laser ND YAG", "Iridotomie au laser ND YAG", "Iridotomie au laser Argon", "Photo coagulation par laser PASCAL", "Photo coagulation par laser ARGON", "Traitement focale pour œdème maculaire", "Traitement par laser micropulse"].sort((a, b) => {
    if (a === "None") return -1;
    if (b === "None") return 1;
    return a.localeCompare(b);
  }).map((str) => {
    return {
      name: str,
      date: undefined
    };
  });
  listAntLAser = this.listAntecdantLaser.map(el => el.name)

  selectedAntecdantLaser: any

  // -------------------------------------end Antécédents de traitement par laser vraiable
  // -------------------------------------start Chirigaux vraiable
  listChurigieType = [
    "None",
    "Chirurgie de la cataracte par phacoémulsification",
    "Chirurgie de la cataracte  par voie extra capsulaire",
    "Chirurgie de la cataracte par voie intra capsulaire",
    "Chirurgie du strabisme",
    "Chirurgie du ptosis",
    "Chirurgie du décollement de la rétine",
    "Chirurgie de trous maculaire",
    "Chirurgie de Membrane épimaculaire",
    "Chirurgie du glaucome",
    "Chirurgie de pterygion",
    "Chirurgie de chalazion",
    "Chirurgie refractive",
    "Cross linking du collagene corneen",
    "Blepharoplastie",
    "Enucleation",
    "Evisceration",
    "Greffe de cornée transfixiante",
    "Greffe de cornée lamellaire",
    "Greffe de cornée endothéliale",
    "Injection intra oculaire d'anti VEGF",
    "Anneux intracorneen",
    "Implantation phaque ICL",
    "Implantation secondaire avec un implant de chambre antérieure",
    "Implantation secondaire avec un implant Artisan"
  ].sort((a, b) => {
    if (a === "None") return -1;
    if (b === "None") return 1;
    return a.localeCompare(b);
  }).map((str) => {
    return {
      name: str,
      date: undefined
    };
  });
  listChirugie = this.listChurigieType.map(el => el.name)
  selectedChirugieType: any = ""
  selectedChirugieTypeGauche: any = ""
  // -------------------------------------end   Chirigaux vraiable
  // -------------------------------------start   Correction optique vraiable
  listCorrectionOptique = ["None", "Correction lunette", "Correction lentille"].sort((a, b) => {
    if (a === "None") return -1;
    if (b === "None") return 1;
    return a.localeCompare(b);
  }).map((str) => {
    return {
      name: str,
      date: undefined
    };
  });


  selectedCorrectionOptique: any;
  selectedCorrectionOptiqueGauche: any;
  listAntecedent = ["None", "Grossesse en cours", "Terrain atopique", "Terrain vasculaire particulier", "Terrain d'immunodepression", "Maladie dysimmunitaire", "Maladie neurologique", "Notion de vaccination récente", "Notion d'anesthésie récente", "Traitement général en cours", "Cas particulier nourrisson enfant"].sort((a, b) => {
    if (a === "None") return -1;
    if (b === "None") return 1;
    return a.localeCompare(b);
  })
  selectedantecedentsGeneraux: any;
  selectedantecedentsGenerauxGauche: any;
  medicaments = ["None", ...medicaments]
  // -------------------------------------end   Correction optique vraiable
  //liste des médecins 
  mesMedecins: any[] = []
  staticMedecins: any[] = []
  selectedMedecinn: any;
  nom: any
  historiquesConsultations: any[] = []
  isToastVisible = false
  pathImage: any;
  private readonly rightEdgeThreshold = window.innerWidth - 10;
  private readonly mouseMoveSubject? = new Subject<MouseEvent>();
  receivedData = {
    inspectionOD: this.inspectionModel,
    inspectionOG: this.inspectionModel,
    annexeOD: this.annexeModel,
    annexeOG: this.annexeModel,
    sclereOD: this.sclereModel,
    sclereOG: this.sclereModel,
    conjOD: this.conjModel,
    conjOG: this.conjModel,
    corneeOD: this.corneeModel,
    corneeOG: this.corneeModel,
    chambreOD: this.chambreModel,
    chambreOG: this.chambreModel,
    irisOD: this.irisModel,
    irisOG: this.irisModel,
    rpmOD: this.rpmModel,
    rpmOG: this.rpmModel,
    dilatationOD: this.dilatationModel,
    dilatationOG: this.dilatationModel,
    cristallinOD: this.cristallinModel,
    cristallinOG: this.cristallinModel,
    angleIridoOD: this.angleIridoModel,
    angleIridoOG: this.angleIridoModel,
    vitreOD: this.vitreModel,
    vitreOG: this.vitreModel,
    papilleOD: this.papilleModel,
    papilleOG: this.papilleModel,
    maculaOD: this.maculaModel,
    maculaOG: this.maculaModel,
    anomalieVasculaireOD: this.anomalieVasculaireModel,
    anomalieVasculaireOG: this.anomalieVasculaireModel,
    retineOD: this.retineModel,
    retineOG: this.retineModel,
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    pachymtrieOD: 0,
    pachymtrieOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    additionalConclusion: "",
    haveSpecificDiagnostic: false,
    source: "",
    sansCycloSphereDroit: 0,
    sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0,
    sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0,
    avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0,
    avecCycloCylindreGauche: 0,
    addOD: 0,
    addOG: 0,
    nextAppointment: "",
    nextAppointmentDuration: "",
    nextMotif: [],
    rvLoinD: "",
    rvLoinG: "",
    rvPresD: "",
    rvPresG: ""
  };
  files: any;
  // prise de photo
  filesToUploaded: any[] = [];
  @ViewChild("video")
  public video: ElementRef;
  @ViewChild("canvas")
  public canvas: ElementRef;
  public captures: any[] = [];
  isCameraAccessGranted: any;
  isMicrophoneAccessGranted: any;
  authorized = true;
  // prise de photo
  ng2slectwidh: any
  screenWidth: any
  selectetedAntecedentsdetraitementparlaser: any = ''
  selectetedAntecedentsdetraitementparlaserGauche: any = ''
  formMotif: any;
  motifMinLength = motifMinLength
  motifMaxLength = motifMaxLength
  champSpecialCaracters = champSpecialCaracters
  today: Date = new Date()
  idExamen: any
  idPatient: any
  constructor(private route: ActivatedRoute, private examenService: ExamenService, private patientService: PatientService,
    private router: Router, private _sanitizer: DomSanitizer, private medecinService: MedecinService, public iziToast: Ng2IzitoastService,
    private sharedDataService: SharedDataService, private formBuilder: FormBuilder,
  ) {
    this.mouseMoveSubject?.pipe(throttleTime(500)).subscribe((event) => this.handleMouseMove(event));
    this.formMotif = this.formBuilder.group({
      motif: ['', [Validators.minLength(3), Validators.maxLength(100), ValidationService.validateMotif]]
    })
  }
  ngOnInit(): void {
    this.sharedDataService.data$.subscribe((data) => {
      if (data) {
        if (data.source === "addOD") {
          this.receivedData.addOD = data.addOD
        }
        if (data.source === "addOG") {
          this.receivedData.addOG = data.addOG
        }
        if (data.source === "rvLoinD") {
          this.receivedData.rvLoinD = data.rvLoinD
        }
        if (data.source === "rvLoinG") {
          this.receivedData.rvLoinG = data.rvLoinG
        }
        if (data.source === "rvPresD") {
          this.receivedData.rvPresD = data.rvPresD
        }
        if (data.source === "rvPresG") {
          this.receivedData.rvPresG = data.rvPresG
        }
        if (data.source === "sansCycloSphereDroit") {
          this.receivedData.sansCycloSphereDroit = data.sansCycloSphereDroit
        }
        if (data.source === "sansCycloSphereGauche") {
          this.receivedData.sansCycloSphereGauche = data.sansCycloSphereGauche
        }
        if (data.source === "sansCycloCylindreDroit") {
          this.receivedData.sansCycloCylindreDroit = data.sansCycloCylindreDroit
        }
        if (data.source === "sansCycloCylindreGauche") {
          this.receivedData.sansCycloCylindreGauche = data.sansCycloCylindreGauche
        }
        if (data.source === "avecCycloSphereDroit") {
          this.receivedData.avecCycloSphereDroit = data.avecCycloSphereDroit
        }
        if (data.source === "avecCycloSphereGauche") {
          this.receivedData.avecCycloSphereGauche = data.avecCycloSphereGauche
        }
        if (data.source === "avecCycloCylindreDroit") {
          this.receivedData.avecCycloCylindreDroit = data.avecCycloCylindreDroit
        }
        if (data.source === "avecCycloCylindreGauche") {
          this.receivedData.avecCycloCylindreGauche = data.avecCycloCylindreGauche
        }

        if (data.source === "prochaineRdv") {
          this.receivedData.nextMotif = data.nextMotif
          this.receivedData.nextAppointmentDuration = data.nextAppointmentDuration
          this.receivedData.nextAppointment = data.nextAppointment
        }
        if (data.source === "tags") {
          this.receivedData.cat = data.cat
        }
        if (data.source === "haveSpecificDiagnostic") {
          this.receivedData.haveSpecificDiagnostic = data.haveSpecificDiagnostic
          this.receivedData.additionalConclusion = data.additionalConclusion
        }
        if (data.source === "TOOD") {
          this.receivedData.TOOD = data.TOOD
        }
        if (data.source === "TOOG") {
          this.receivedData.TOOG = data.TOOG
        }
        if (data.source === "butOD") {
          this.receivedData.butOD = data.butOD
        }
        if (data.source === "butOG") {
          this.receivedData.butOG = data.butOG
        }
        if (data.source === "pachymtrieOD") {
          this.receivedData.pachymtrieOD = data.pachymtrieOD
        }
        if (data.source === "pachymtrieOG") {
          this.receivedData.pachymtrieOG = data.pachymtrieOG
        }
        if (data.source === "shirmerOD") {
          this.receivedData.shirmerOD = data.shirmerOD
        }
        if (data.source === "shirmerOG") {
          this.receivedData.shirmerOG = data.shirmerOG
        }

        if (data.source = "annexeOD") {
          this.receivedData.annexeOD = { ...this.receivedData.annexeOD, ...data.annexeOD }
        }
        if (data.source = "annexeOG") {
          this.receivedData.annexeOG = { ...this.receivedData.annexeOG, ...data.annexeOG }
        }
        if (data.source = "inspectionOD") {
          this.receivedData.inspectionOD = { ...this.receivedData.inspectionOD, ...data.inspectionOD }
        }
        if (data.source = "inspectionOG") {
          this.receivedData.inspectionOG = { ...this.receivedData.inspectionOG, ...data.inspectionOG }
        }
        if (data.source = "sclereOD") {
          this.receivedData.sclereOD = { ...this.receivedData.sclereOD, ...data.sclereOD }
        }
        if (data.source = "sclereOG") {
          this.receivedData.sclereOG = { ...this.receivedData.sclereOG, ...data.sclereOG }
        }
        if (data.source = "conjOD") {
          this.receivedData.conjOD = { ...this.receivedData.conjOD, ...data.conjOD }
        }
        if (data.source = "conjOG") {
          this.receivedData.conjOG = { ...this.receivedData.conjOG, ...data.conjOG }
        }
        if (data.source = "corneeOD") {
          this.receivedData.corneeOD = { ...this.receivedData.corneeOD, ...data.corneeOD }
        }
        if (data.source = "corneeOG") {
          this.receivedData.corneeOG = { ...this.receivedData.corneeOG, ...data.corneeOG }
        }
        if (data.source = "chambreOD") {
          this.receivedData.chambreOD = { ...this.receivedData.chambreOD, ...data.chambreOD }
        }
        if (data.source = "chambreOG") {
          this.receivedData.chambreOG = { ...this.receivedData.chambreOG, ...data.chambreOG }
        }
        if (data.source = "irisOD") {
          this.receivedData.irisOD = { ...this.receivedData.irisOD, ...data.irisOD }
        }
        if (data.source = "irisOG") {
          this.receivedData.irisOG = { ...this.receivedData.irisOG, ...data.irisOG }
        }
        if (data.source = "rpmOD") {
          this.receivedData.rpmOD = { ...this.receivedData.rpmOD, ...data.rpmOD }
        }
        if (data.source = "rpmOG") {
          this.receivedData.rpmOG = { ...this.receivedData.rpmOG, ...data.rpmOG }
        }
        if (data.source = "dilatationOD") {
          this.receivedData.dilatationOD = { ...this.receivedData.dilatationOD, ...data.dilatationOD }
        }
        if (data.source = "dilatationOG") {
          this.receivedData.dilatationOG = { ...this.receivedData.dilatationOG, ...data.dilatationOG }
        }
        if (data.source = "cristallinOD") {
          this.receivedData.cristallinOD = { ...this.receivedData.cristallinOD, ...data.cristallinOD }
        }
        if (data.source = "cristallinOG") {
          this.receivedData.cristallinOG = { ...this.receivedData.cristallinOG, ...data.cristallinOG }
        }
        if (data.source = "angleIridoOD") {
          this.receivedData.angleIridoOD = { ...this.receivedData.angleIridoOD, ...data.angleIridoOD }
        }
        if (data.source = "angleIridoOG") {
          this.receivedData.angleIridoOG = { ...this.receivedData.angleIridoOG, ...data.angleIridoOG }
        }
        if (data.source = "vitreOD") {
          this.receivedData.vitreOD = { ...this.receivedData.vitreOD, ...data.vitreOD }
        }
        if (data.source = "vitreOG") {
          this.receivedData.vitreOG = { ...this.receivedData.vitreOG, ...data.vitreOG }
        }
        if (data.source = "papilleOD") {
          this.receivedData.papilleOD = { ...this.receivedData.papilleOD, ...data.papilleOD }
        }
        if (data.source = "papilleOG") {
          this.receivedData.papilleOG = { ...this.receivedData.papilleOG, ...data.papilleOG }
        }
        if (data.source = "maculaOD") {
          this.receivedData.maculaOD = { ...this.receivedData.maculaOD, ...data.maculaOD }
        }
        if (data.source = "maculaOG") {
          this.receivedData.maculaOG = { ...this.receivedData.maculaOG, ...data.maculaOG }
        }
        if (data.source = "anomalieVasculaireOD") {
          this.receivedData.anomalieVasculaireOD = { ...this.receivedData.anomalieVasculaireOD, ...data.anomalieVasculaireOD }
        }
        if (data.source = "anomalieVasculaireOG") {
          this.receivedData.anomalieVasculaireOG = { ...this.receivedData.anomalieVasculaireOG, ...data.anomalieVasculaireOG }
        }
        if (data.source = "retineOD") {
          this.receivedData.retineOD = { ...this.receivedData.retineOD, ...data.retineOD }
        }
        if (data.source = "retineOG") {
          this.receivedData.retineOG = { ...this.receivedData.retineOG, ...data.retineOG }
        }
      }
    });
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    const idPatient = this.route.snapshot.paramMap.get('id');
    this.idExamen = idExamen
    // get examen
    this.getExamenData()

    // get patient
    if (idPatient && idPatient != "undefined" && idPatient != undefined && idPatient != null && idExamen && idExamen != undefined && idExamen != null && idExamen != "undefined") {
      this.patientService.getPatientByIDNewFunction(idPatient).subscribe(patient => {
        this.patient = patient;
        if (this.patient && this.patient._id != null && this.patient._id != undefined) {
          // get number of old consultations  
          if (this.patient.image) {
            this.pathImage = _getURL("") + "data/files/imagePatient/" + this.patient.image
          }
          this.examenService.hasOldConsultation(this.patient._id, idExamen).subscribe((data: any) => {
            if (data && data.hasOldConsultation) {
              this.historiquesConsultations = data.previousConsultation
            }
          },
            err => {
              this.router.navigate(['/medecin/patients-liste']);
              return false;
            });
        } else {
          this.router.navigate(['/medecin/patients-liste']);
        }
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
    // get all médecins 
    this.medecinService.getMedecinAccepted().subscribe((medecins: any[]) => {
      this.medecinService.getMedecinScapedBypage(0, 20).subscribe((medecinsss: any[]) => {
        this.mesMedecins = medecins.concat(medecinsss);
        this.staticMedecins = medecins.concat(medecinsss);
      },
        err => {
          return false;
        });

    },
      err => {
        return false;
      });

  }

  validateFields(event, name?: any, type?: any) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,
          position: 'topRight',
          timeout: 3000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    let value = event?.target?.value

    if (value && value.length <= 2) {
      showToast(`<b>${name}</b> : ${champMinLength}`, '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      event.target.value = null
      return false;
    } else if (value && value.length >= 250 && type === "description") {
      showToast(`<b>${name}</b> : ${descriptionMaxLength}`, '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      return false;
    } else if (value && value.length >= 100 && type === "name") {
      showToast(`<b>${name}</b> : ${examChampMaxLength}`, '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      return false;
    }
  }

  getExamenData() {
    if (this.idExamen && this.idExamen != undefined && this.idExamen != "undefined" && this.idExamen != null) {

      this.examenService.getExamenById(this.idExamen).subscribe((data: any) => {
        this.myExamen = data
        if (this.myExamen) {
          // !----------start of initialisation
          // todo-----------Medicaux initialisation
          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux.length == 0) {
            this.selectedMedical = 'None'
          } else {
            this.selectedMedical = null
          }

          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.length == 0) {
            this.selectedMedicalGauche = 'None'
          } else {
            this.selectedMedicalGauche = null
          }
          // todo---------- Traitement en cours initialisation
          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours.length == 0) {
            this.selectedTraitement = "None"
          } else {
            this.selectedTraitementGauche = null
          }

          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours.length == 0) {
            this.selectedTraitementGauche = 'None'
          } else {
            this.selectedTraitementGauche = null
          }
          // todo---------- Antécédents de traitement par laser

          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name == undefined || null) {
            this.selectetedAntecedentsdetraitementparlaser = 'None'
          } else {
            this.selectetedAntecedentsdetraitementparlaser = null
          }
          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name == undefined || null) {
            this.selectetedAntecedentsdetraitementparlaserGauche = 'None'
          } else {
            this.selectetedAntecedentsdetraitementparlaserGauche = null
          }
          // todo---------- Chirurgicaux
          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.length == 0) {
            this.selectedChirugieType = "None"
          } else {
            this.selectedChirugieType = null
          }

          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.length == 0) {
            this.selectedChirugieTypeGauche = 'None'
          } else {
            this.selectedChirugieTypeGauche = null
          }
          // todo---------- Correction optique

          if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length == 0 && this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length == 0) {
            this.selectedCorrectionOptique = 'None'
          } else {
            this.selectedCorrectionOptique = null
          }
          // todo--------------- antécedent généraux
          const antecedents = this.myExamen.interrogation.antecedentsGeneraux;
          if ([
            antecedents.grossesseenCours.present,
            antecedents.terrainatopique.present,
            antecedents.terrainvasculaireparticulier.present,
            antecedents.terrainimmunodepression.present,
            antecedents.maladiedysimunitaire.present,
            antecedents.maladieneurologique.present,
            antecedents.notionvaccinationrecente.present,
            antecedents.notionanesthesierecente.present,
            antecedents.traitementencours.present,
            antecedents.casparticuliernourrissonenfant.present
          ].every(prop => !prop)) {
            this.selectedantecedentsGeneraux = "None";
          } else {
            return false;
          }
          // todo ----------------- type d'natécédent familiaux ophtalmologiques
          if (this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.length == 0) {
            this.typeAnteFamiOpht = "None"
          }
          // todo ----------------- habitude de vie
          if (this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie.length == 0) {
            this.habitudeDeVie = "None"
          }
          // !---------end of initialisation
          this.configureDataExamen()
          this.selectedMotifs = this.myExamen.motif
          if (this.myExamen.duration) {
            this.timer = this.myExamen.duration
            this.minutes = Number(this.myExamen.duration.split(':')[1]);
            this.hour = Number(this.myExamen.duration.split(':')[0]);
            this.seconds = Number(this.myExamen.duration.split(':')[2]);
            //this.start()
            this.showAndHidePlaytimer = true
            return true;
          } else {
            this.start()
            return true;
          }
        } else {
          this.selectedMotifs = JSON.parse(localStorage.getItem('motif'))
          this.router.navigate(['/medecin/patients-liste']);
        }

      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }


  configureDataExamen() {
    // get old consultations
    if (this.myExamen.interrogation && this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique && this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy) {
      this.selectedMedecinn = this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy
    }
    this.receivedData.inspectionOD = this.myExamen.inspection.anomaly.oielDroit
    this.receivedData.inspectionOG = this.myExamen.inspection.anomaly.oielGauche
    this.receivedData.annexeOD = this.myExamen.annexe.anomaly.oielDroit
    this.receivedData.annexeOG = this.myExamen.annexe.anomaly.oielGauche
    this.receivedData.sclereOD = this.myExamen.laf.sclere.oielDroit
    this.receivedData.sclereOG = this.myExamen.laf.sclere.oielGauche
    this.receivedData.conjOD = this.myExamen.laf.conj.oielDroit
    this.receivedData.conjOG = this.myExamen.laf.conj.oielGauche
    this.receivedData.corneeOD = this.myExamen.laf.corneetransparente.oielDroit
    this.receivedData.corneeOG = this.myExamen.laf.corneetransparente.oielGauche
    this.receivedData.chambreOD = this.myExamen.laf.chambreanterieurealmeetprofonde.oielDroit
    this.receivedData.chambreOG = this.myExamen.laf.chambreanterieurealmeetprofonde.oielGauche
    this.receivedData.irisOD = this.myExamen.laf.irisNormale.oielDroit
    this.receivedData.irisOG = this.myExamen.laf.irisNormale.oielGauche
    this.receivedData.rpmOD = this.myExamen.laf.rpmpresentetsymetrique.oielDroit
    this.receivedData.rpmOG = this.myExamen.laf.rpmpresentetsymetrique.oielGauche
    this.receivedData.dilatationOD = this.myExamen.laf.dilatation.oielDroit
    this.receivedData.dilatationOG = this.myExamen.laf.dilatation.oielGauche
    this.receivedData.cristallinOD = this.myExamen.laf.cristallinTransparent.oielDroit
    this.receivedData.cristallinOG = this.myExamen.laf.cristallinTransparent.oielGauche
    this.receivedData.angleIridoOD = this.myExamen.laf.angleiridocornee.oielDroit
    this.receivedData.angleIridoOG = this.myExamen.laf.angleiridocornee.oielGauche
    this.receivedData.vitreOD = this.myExamen.laf.vitre.oielDroit
    this.receivedData.vitreOG = this.myExamen.laf.vitre.oielGauche
    this.receivedData.papilleOD = this.myExamen.laf.papille.oielDroit
    this.receivedData.papilleOG = this.myExamen.laf.papille.oielGauche
    this.receivedData.maculaOD = this.myExamen.laf.macule.oielDroit
    this.receivedData.maculaOG = this.myExamen.laf.macule.oielGauche
    this.receivedData.anomalieVasculaireOD = this.myExamen.laf.anomalieVasculaire.oielDroit
    this.receivedData.anomalieVasculaireOG = this.myExamen.laf.anomalieVasculaire.oielGauche
    this.receivedData.retineOD = this.myExamen.laf.retine.oielDroit
    this.receivedData.retineOG = this.myExamen.laf.retine.oielGauche
    this.receivedData.cat = this.myExamen.tags
    this.receivedData.haveSpecificDiagnostic = this.myExamen.haveSpecificDiagnostic
    this.receivedData.additionalConclusion = this.myExamen.additionalConclusion
    this.receivedData.TOOD = this.myExamen.laf.toAic.oielDroit.mm
    this.receivedData.TOOG = this.myExamen.laf.toAic.oielGauche.mm
    this.receivedData.butOD = this.myExamen.laf.butShirmer.oielDroit.secondes
    this.receivedData.butOG = this.myExamen.laf.butShirmer.oielGauche.secondes
    this.receivedData.pachymtrieOD = this.myExamen.laf.pachymtrie.oielDroit.value
    this.receivedData.pachymtrieOG = this.myExamen.laf.pachymtrie.oielGauche.value
    this.receivedData.shirmerOD = this.myExamen.laf.butShirmer.oielDroit.mm
    this.receivedData.shirmerOG = this.myExamen.laf.butShirmer.oielGauche.mm
    if (this.myExamen.refraction.sansCycloplegie.present) {
      this.receivedData.sansCycloSphereDroit = this.myExamen.refraction.sansCycloplegie.oielDroit.sphere;
      this.receivedData.sansCycloSphereGauche = this.myExamen.refraction.sansCycloplegie.oielGauche.sphere;
      this.receivedData.sansCycloCylindreDroit = this.myExamen.refraction.sansCycloplegie.oielDroit.cylindre;
      this.receivedData.sansCycloCylindreGauche = this.myExamen.refraction.sansCycloplegie.oielGauche.cylindre;

    }
    if (this.myExamen.refraction.avecCycloplegie.present) {
      this.receivedData.avecCycloSphereDroit = this.myExamen.refraction.avecCycloplegie.oielDroit.sphere;
      this.receivedData.avecCycloSphereGauche = this.myExamen.refraction.avecCycloplegie.oielGauche.sphere;
      this.receivedData.avecCycloCylindreDroit = this.myExamen.refraction.avecCycloplegie.oielDroit.cylindre;
      this.receivedData.avecCycloCylindreGauche = this.myExamen.refraction.avecCycloplegie.oielGauche.cylindre;
    }
    if (this.myExamen.refraction.acuitevisueldepres.present) {
      this.receivedData.addOD = this.myExamen.refraction.acuitevisueldepres.oielDroit.add;
      this.receivedData.addOG = this.myExamen.refraction.acuitevisueldepres.oielGauche.add;
      this.receivedData.rvPresD = this.myExamen.refraction.acuitevisueldepres.oielDroit.rv
      this.receivedData.rvPresG = this.myExamen.refraction.acuitevisueldepres.oielGauche.rv
    }
    if (this.myExamen.refraction.acuitevisueldeloin.present) {
      this.receivedData.rvLoinD = this.myExamen.refraction.acuitevisueldeloin.oielDroit.rv
      this.receivedData.rvLoinG = this.myExamen.refraction.acuitevisueldeloin.oielGauche.rv
    }

    this.receivedData.nextMotif = this.myExamen.nextMotif
    this.receivedData.nextAppointmentDuration = this.myExamen.nextAppointmentDuration
    this.receivedData.nextAppointment = this.myExamen.nextAppointment
    let zone = localStorage.getItem("ZoneToGo");
    if (zone === "document") {
      this.clickisActiveDocuments();
      localStorage.removeItem("ZoneToGo")
    }
  }
  //prise de photo
  public async ngAfterViewInit() {
    this.authorized = true;
    try {
      const cameraResult = await navigator.permissions.query({ name: 'camera' });
      // The state property may be 'denied', 'prompt' and 'granted'
      this.isCameraAccessGranted = cameraResult.state !== 'denied';
      // const microphoneResult = await navigator.permissions.query({ name: 'microphone' });
      // this.isMicrophoneAccessGranted = false;
    } catch (e) {
      console.error('An error occurred while checking the site permissions', e);
    }
    navigator.mediaDevices.getUserMedia({
      video: {
        width: 280,
        height: 280,
        facingMode: "user"
      },
      audio: false
    }).then(stream => {
      this.video.nativeElement.srcObject = stream;
      this.video.nativeElement.play();
    }, error => {
      this.authorized = false;
      return false;
    });
  }
  public capture() {
    if (this.filesToUploaded && this.filesToUploaded.length > 1) {
      this.iziToast.show({
        message: 'Vous pouvez ajouter une seule photo.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    var context = this.canvas.nativeElement
      .getContext("2d")
      .drawImage(this.video.nativeElement, 0, 0, 280, 280);

    this.captures[0] = this.canvas.nativeElement.toDataURL("image/png");
    let identifiant = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    let file = this.dataURLtoFile(this.canvas.nativeElement.toDataURL("image/png"), identifiant + '.png');
    this.filesToUploaded[0] = file;

  }

  public dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  saveImages() {
    if ((this.filesToUploaded && this.filesToUploaded.length < 1) || (!this.filesToUploaded)) {
      this.iziToast.show({
        message: 'Il faut prendre au moins une photo.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    let formData = new FormData();
    for (var i = 0; i < this.filesToUploaded.length; i++) {
      formData.append("file", this.filesToUploaded[i], this.filesToUploaded[i].name);

    }

    this.patientService.updatePhoto(this.patient._id, formData).subscribe((_patient: any) => {
      this.canvas = undefined
      this.patientService.getPatientByIDNewFunction(this.patient._id).subscribe(patient => {
        this.patient = patient;
        if (this.patient.image) {
          this.pathImage = _getURL("") + "data/files/imagePatient/" + this.patient.image
        }
      });
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  // end prise de photo
  removeAdressedBy() {
    this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy = undefined
    this.selectedMedecinn = undefined
  }
  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }
  start() {
    this.isStart = true;
    this.showAndHidePlaytimer = false
    this.timerInterval = setInterval(() => {

      if (this.minutes > 59) { // to increment the hour value
        this.hour = this.hour + 1;
        this.seconds = 0;
        this.minutes = 0;
      }

      if (this.seconds > 59) { // to calculate minutes
        this.minutes = this.minutes + 1;
        this.seconds = 0;
      }

      this.seconds = this.seconds + 1;
      var mysecond: any = this.seconds
      var myminute: any = this.minutes
      var myhour: any = this.hour
      if (mysecond < 10) {
        mysecond = '0' + mysecond
      }
      if (myminute < 10) {
        myminute = '0' + myminute
      }
      if (myhour < 10) {
        myhour = '0' + myhour
      }
      this.timer = `${myhour}:${myminute}:${mysecond}`

    }, 1000)

  }
  restart() {
    this.myExamen.lastResumeDate = moment().tz('Africa/Tunis').format()
    this.updateExamen(this.myExamen)
    this.start()
    return true;
  }
  // resetTimer(){
  //   this.hour=0
  //   this.seconds=0;
  //   this.minutes=0;
  //   this.timer = `${this.hour}:${this.minutes}:${this.seconds}`
  //   clearInterval(this.timerInterval);
  //   this.start()
  // }
  pauseTimer() {
    // need to implement logic
    this.isStart = false;
    this.setTimer = this.timer;
    clearInterval(this.timerInterval);
    this.showAndHidePlaytimer = true
    this.myExamen.duration = this.timer
    const startTime = new Date(this.myExamen.start);
    const timerParts = this.myExamen.duration.split(':');
    const hours = parseInt(timerParts[0]);
    const minutes = parseInt(timerParts[1]);
    const seconds = parseInt(timerParts[2]);
    const endTime = new Date(startTime.getTime() + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000));
    this.myExamen.end = moment(endTime)
    this.myExamen.lastPauseDate = moment().tz('Africa/Tunis').format()
    this.updateExamen(this.myExamen)

  }
  clickisActiveInterrogatoire() {
    this.isActiveInterrogatoire = true;
    this.isActiveExamen = false;
    this.isActiveConduite = false;
    this.isActiveDocuments = false;
    this.isActiveConclusion = false;
  }
  clickisActiveExamen() {
    this.isActiveInterrogatoire = false;
    this.isActiveExamen = true;
    this.isActiveConduite = false;
    this.isActiveDocuments = false;
    this.isActiveConclusion = false;
  }
  clickisActiveConduite() {
    this.isActiveInterrogatoire = false;
    this.isActiveExamen = false;
    this.isActiveConduite = true;
    this.isActiveDocuments = false;
    this.isActiveConclusion = false;
  }
  clickisActiveDocuments() {
    this.isActiveInterrogatoire = false;
    this.isActiveExamen = false;
    this.isActiveConduite = false;
    this.isActiveDocuments = true;
    this.isActiveConclusion = false;
  }
  clickisActiveConclusion() {
    this.isActiveInterrogatoire = false;
    this.isActiveExamen = false;
    this.isActiveConduite = false;
    this.isActiveDocuments = false;
    this.isActiveConclusion = true;
  }
  // activate and hide left sections

  showAnteOphtaPerso() {
    window.scroll(0, 0)
    this.isActivgene = false;
    this.isActiveOphtaPerso = true;
    this.isActiveAnteFamiOpht = false
  }
  showAnteFamiOpht() {

    this.isActivgene = false;
    this.isActiveOphtaPerso = false;
    this.isActiveAnteFamiOpht = true
    let el = document.getElementById('antFamOpht')
    if (el) {
      el.scrollIntoView();
    }
  }
  showAntegene() {
    window.scroll(0, 0)
    this.isActivgene = true;
    this.isActiveOphtaPerso = false;
    this.isActiveAnteFamiOpht = false
  }
  addInputtypeAnteFamiOpht(value) {
    let foundValue = this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.find(x => x && x.name && x.name.toLowerCase() === value.toLowerCase())
    if (!foundValue && value && value.trim().length > 2 && value !== "None") {
      this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.push({ name: value, date: undefined })
      this.typeAnteFamiOpht = null
      return true;
    }
    if (value && value == 'None') {
      this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht = []
    }
  }
  removelisttypeAnteFamiOpht(typeselect) {
    if (this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.indexOf(typeselect) > -1) {
      this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.splice(this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.indexOf(typeselect), 1)
      if (this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.length == 0) {
        this.typeAnteFamiOpht = "None"
        return true
      }
      return true;
    }
  }
  addInputhabitudeDeVie(value) {
    let foundValue = this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie.find(x => x && x.name && x.name.toLowerCase() === value.toLowerCase())

    if (!foundValue && value && value.trim().length > 0 && this.habitudeDeVie !== 'None') {
      this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie.push({ name: value, date: undefined })
      this.habitudeDeVie = null
      return true;
    }
    if (this.habitudeDeVie && this.habitudeDeVie == 'None') {
      this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie = []
    }
  }
  removelisthabitudeDeVie(typeselect) {
    if (this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie.indexOf(typeselect) > -1) {
      this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie.splice(this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie.indexOf(typeselect), 1)
      if (this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie.length == 0) {
        this.habitudeDeVie = "None"
        return true
      }
      return true;
    }
  }

  addInputterrainOptique(value) {
    let foundValue = this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs.find(x => x && x.name && x.name.toLowerCase() === value.toLowerCase())

    if (!foundValue && value && value.trim().length > 0) {
      this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs.push({ name: value, date: undefined })
      this.terrainatopiqueInput = null
      value = null
      return true;
    }
  }
  removelistterrainOptique(typeselect) {
    if (this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs.indexOf(typeselect) > -1) {
      this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs.splice(this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs.indexOf(typeselect), 1)
      return true;
    }
  }

  // terrain d'immo
  addInputterrainimmunodepression(value) {
    let foundValue = this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs.find(x => x && x.name && x.name.toLowerCase() === value.toLowerCase())
    if (!foundValue && value && value.trim().length > 0) {
      this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs.push({ name: value, date: undefined })
      this.terrainimmunodepressionInput = null
      value = undefined
      return true;
    }
  }


  removelistterrainimmunodepression(typeselect) {
    if (this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs.indexOf(typeselect) > -1) {
      this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs.splice(this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs.indexOf(typeselect), 1)
      return true;
    }
  }


  // maladie dysimmuniatre
  addInputmaladiedysimunitaire(value) {

    let foundValue = this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs.find(x => x && x.name && x.name.toLowerCase() === value.toLowerCase())
    if (this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs.indexOf(value) < 0 && value && value.trim().length > 0 && !foundValue) {
      this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs.push({ name: value, date: undefined })
      this.maladiedysimunitaireInput = null
      value = ""
      return true;
    }
  }

  // maladie dysimmuniatre
  removelistmaladiedysimunitaire(typeselect) {
    if (this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs.indexOf(typeselect) > -1) {
      this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs.splice(this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs.indexOf(typeselect), 1)
      return true;
    }
  }
  // maladie neurologique 
  addInputmaladieneurologique(value) {
    let foundValue = this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs.find(x => x && x.name && x.name.toLowerCase() === value.toLowerCase())
    if (this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs.indexOf(value) < 0 && value && value.trim().length > 0 && !foundValue) {
      this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs.push({ name: value, date: undefined })
      this.maladieneurologiqueInput = null
      value = ""
      return true;
    }
  }

  removelistmaladieneurologique(typeselect) {
    if (this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs.indexOf(typeselect) > -1) {
      this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs.splice(this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs.indexOf(typeselect), 1)
      return true;
    }
  }
  //Cas particulier nourrisson enfant:
  addCasParticulierNourissonEnfant($event) {
    if ($event && $event.target && $event.target.value && $event.target.value.trim().length > 0) {
      if ($event.target.value === "Consanguinité") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.consanguinite.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.consanguinite.date = new Date().toISOString()
      }
      if ($event.target.value === "Séroconversion pendant la grossesse") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.date = new Date().toISOString()
      }
      if ($event.target.value === "Prématurité") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.date = new Date().toISOString()
      }
      if ($event.target.value === "Accouchement voie basse") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementvoiebasse.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementvoiebasse.date = new Date().toISOString()
      }
      if ($event.target.value === "Accouchement césarienne") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementcesarienne.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementcesarienne.date = new Date().toISOString()
      }
      if ($event.target.value === "Faible poids de la naissance") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.faiblepoidsdelanaissance.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.faiblepoidsdelanaissance.date = new Date().toISOString()
      }
      if ($event.target.value === "Souffrance foetale aigue") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.souffrancefoetaleaigue.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.souffrancefoetaleaigue.date = new Date().toISOString()
      }
      if ($event.target.value === "Cas de rétinoblastorne dans la famille") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.casderetinoblastornedanslafamille.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.casderetinoblastornedanslafamille.date = new Date().toISOString()
      }
      if ($event.target.value === "Retard scolaire") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.retardscolaire.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.retardscolaire.date = new Date().toISOString()
      }
      if ($event.target.value === "Comportement de malvoyance") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.comportementdemalvoyance.present = true
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.comportementdemalvoyance.date = new Date().toISOString()
      }
      return true;
    }
  }
  addRemoveCasParticulierNourissonEnfant($event) {
    if ($event && $event.trim().length > 0) {
      if ($event === "Consanguinité") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.consanguinite.present = false
      }
      if ($event === "Séroconversion pendant la grossesse") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.present = false
      }
      if ($event === "Prématurité") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.present = false
      }
      if ($event === "Accouchement voie basse") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementvoiebasse.present = false
      }
      if ($event === "Accouchement césarienne") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementcesarienne.present = false
      }
      if ($event === "Faible poids de la naissance") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.faiblepoidsdelanaissance.present = false
      }
      if ($event === "Souffrance foetale aigue") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.souffrancefoetaleaigue.present = false
      }
      if ($event === "Cas de rétinoblastorne dans la famille") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.casderetinoblastornedanslafamille.present = false
      }
      if ($event === "Retard scolaire") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.retardscolaire.present = false
      }
      if ($event === "Comportement de malvoyance") {
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.comportementdemalvoyance.present = false
      }
      return true;
    }
  }



  //-----------------------------------------------------------start médicaux functions

  onMedicalChange() {
    if (this.selectedMedical && this.selectedMedical !== "None" && !this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux.find(x => x.name === this.selectedMedical)) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux.push({ name: this.selectedMedical });
      this.selectedMedical = null
    }
    if (this.selectedMedical && this.selectedMedical === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux = []
    }
  }


  onMedicalChangeGauche() {
    if (this.selectedMedicalGauche && this.selectedMedicalGauche !== "None" && !this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.find(x => x.name === this.selectedMedicalGauche)) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.push({ name: this.selectedMedicalGauche });
      this.selectedMedicalGauche = null
    }
    if (this.selectedMedicalGauche && this.selectedMedicalGauche === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux = []
    }
    // if (this.selectedMedicalGauche && !this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.find(x => x.name === this.selectedMedicalGauche)) {
    //   this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.push({ name: this.selectedMedicalGauche });
    //   this.selectedMedicalGauche = null
    // }
  }
  removeMedical(item) {
    const index = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux.indexOf(item);
    if (index !== -1) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux.splice(index, 1);
      this.selectedMedical = null;
    } else {
      return false
    }
    if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.medicaux.length == 0) {
      this.selectedMedical = 'None'
    } else {
      return false
    }
  }
  removeMedicalGauche(item) {
    const index = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.indexOf(item);
    if (index !== -1) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.splice(index, 1);
      this.selectedMedicalGauche = null; // Clear selected item after removal
    }
    if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.medicaux.length == 0) {
      this.selectedMedicalGauche = 'None'
    } else {
      return false
    }
  }

  //  --------------------------------------------------------end médicaux functions

  //  ---------------------------start Traitement en cours functions
  onTraitementChange() {
    if (this.selectedTraitement && this.selectedTraitement !== "None" && !this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours.find(x => x.name === this.selectedTraitement)) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours.push({ name: this.selectedTraitement });
      this.selectedTraitement = null;
    }
    if (this.selectedTraitement && this.selectedTraitement === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours = []
    }
  }
  onTraitementChangeGauche() {
    if (this.selectedTraitementGauche && this.selectedTraitementGauche !== "None" && !this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours.find(x => x.name === this.selectedTraitementGauche)) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours.push({ name: this.selectedTraitementGauche });
      this.selectedTraitementGauche = null
    }
    if (this.selectedTraitementGauche && this.selectedTraitementGauche === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours = []
    }
  }
  removeTraitement(item) {
    const index = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours.indexOf(item);
    if (index !== -1) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours.splice(index, 1);
      this.selectedTraitement = null; // Clear selected item after removal
    }
    if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.traitementEnCours.length == 0) {
      this.selectedTraitement = "None"
    }
  }
  removeTraitementGauche(item) {
    const index = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours.indexOf(item);
    if (index !== -1) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours.splice(index, 1);
      this.selectedTraitementGauche = null; // Clear selected item after removal
    }
    if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.traitementEnCours.length == 0) {
      this.selectedTraitementGauche = "None"
    }
  }

  //  ----------------------------end Traitement en cours functions

  //  ----------------------------start chirigue functions

  onChirurgieTypeChange() {
    if (this.selectedChirugieType && this.selectedChirugieType !== "None" && !this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.find(x => x.name === this.selectedChirugieType)) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.push({ name: this.selectedChirugieType });
      this.selectedChirugieType = null
    }
    if (this.selectedChirugieType === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux = []
    }

  }
  onChirurgieTypeChangeGauche() {
    if (this.selectedChirugieTypeGauche && this.selectedChirugieTypeGauche !== "None" && !this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.find(x => x.name === this.selectedChirugieTypeGauche)) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.push({ name: this.selectedChirugieTypeGauche });
      this.selectedChirugieTypeGauche = null
    }
    if (this.selectedChirugieTypeGauche === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux = []
    }
  }
  removeChirurgieType(item) {
    const index = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.indexOf(item);
    if (index !== -1) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.splice(index, 1);
      this.selectedChirugieType = null; // Clear selected item after removal
    }
    if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.chirurgicaux.length === 0) {
      this.selectedChirugieType = "None";
    }
  }

  removeChirurgieTypeGauche(item) {
    const index = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.indexOf(item);
    if (index !== -1) {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.splice(index, 1);
      this.selectedChirugieTypeGauche = null; // Clear selected item after removal
    }
    if (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.chirurgicaux.length === 0) {
      this.selectedChirugieTypeGauche = "None";
    }
  }

  //  ----------------------------end chirigue functions
  // -----------------------------start antécedent par laser
  onAntLaserChange() {
    if (this.selectetedAntecedentsdetraitementparlaser !== "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name = this.selectetedAntecedentsdetraitementparlaser
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.description = null
      this.selectetedAntecedentsdetraitementparlaser = null
    }
    if (this.selectetedAntecedentsdetraitementparlaser === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.antecedentsdetraitementparlaser.name = undefined
      this.selectetedAntecedentsdetraitementparlaser = "None"
    }
  }
  onAntLaserChangeGauche() {
    if (this.selectetedAntecedentsdetraitementparlaserGauche !== "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name = this.selectetedAntecedentsdetraitementparlaserGauche
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.description = null
      this.selectetedAntecedentsdetraitementparlaserGauche = null
    }
    if (this.selectetedAntecedentsdetraitementparlaserGauche === "None") {
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.antecedentsdetraitementparlaser.name = undefined
    }
  }
  // -----------------------------end antécedent par laser

  //  ----------------------------satrt Correction optique functions

  onCorrectionOptiqueChange() {
    this.addToCorrectionOptiqueArray(this.selectedCorrectionOptique, this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit);
    this.addToCorrectionOptiqueArray(this.selectedCorrectionOptique, this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche);
  }

  addToCorrectionOptiqueArray(selectedCorrectionOptique, targetEye) {
    if (selectedCorrectionOptique && selectedCorrectionOptique.name !== "None") {
      const existingOptique = targetEye.correctionoptique.find(x => x.name === selectedCorrectionOptique.name);
      if (!existingOptique) {
        targetEye.correctionoptique.push({
          name: selectedCorrectionOptique.name,
          date: new Date(),
          axe: selectedCorrectionOptique.axe,
          sphere: selectedCorrectionOptique.sphere,
          cylindre: selectedCorrectionOptique.cylindre
        });
      }
    } else {
      targetEye.correctionoptique = []
    }
  }
  removeCorrectionOptique(name) {
    // Delete from oielDroit
    this.deleteCorrectionOptiqueByNameFromEye(name, this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit);

    // Delete from oielGauche
    this.deleteCorrectionOptiqueByNameFromEye(name, this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche);
  }

  deleteCorrectionOptiqueByNameFromEye(name, targetEye) {
    for (let i = targetEye.correctionoptique.length - 1; i >= 0; i--) {
      if (targetEye.correctionoptique[i].name === name) {
        targetEye.correctionoptique.splice(i, 1);
      }
    }
    if (targetEye.correctionoptique.length == 0) {
      this.selectedCorrectionOptique = "None"
    }
  }

  checkSigne(event) {
    if (event.target.value <= 0) {
      event.target.value = 0
    }
  }
  //  ----------------------------end Correction optique functions
  // Antécédents ophtalmologique personnels

  lastvistwithusFunction() {
    this.lastvistwithotherdoctor = false
  }
  lastvistwithotherdoctorFunction() {
    this.lastvistwithus = false
  }
  //  Motif
  addInputMotif($event) {
    if (this.selectedMotifs.indexOf($event) < 0 && $event && $event.length >= 3 && this.selectedMotifs.length < 20 && this.formMotif.valid) {
      this.selectedMotifs.push($event);
      this.selectedMotif = ""
      this.updateExamen(this.myExamen)
    }

    if (this.motifList.indexOf($event) < 0 && $event && $event.length >= 3 && this.formMotif.valid) {
      this.motifList.push($event)
      return true;
    }
  }
  // show and hide sidebar histories
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouseMoveSubject.next(event);
  }

  handleMouseMove(event: MouseEvent) {
    // if (event.clientX >= this.rightEdgeThreshold) {
    //   // Call your function or perform any action here
    //   document.getElementById('btn-siderbar').click();
    //   return true;
    // }
    // if (event.clientX <  200) {
    //   document.getElementById('btn-siderbar-left').click();
    //   return true;
    // }
  }
  // saving data for interrogatoire 
  saveDataInterrogatoire() {
    if (this.selectedMedecinn) {
      this.myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy = this.selectedMedecinn._id
    }
    this.updateExamen(this.myExamen)
    return true;
  }
  // update examen
  updateExamen(myExamen) {
    if (this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.present && this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs.length === 0) {
      this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.present = false;
    }
    if (this.myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present && !this.myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.present &&
      !this.myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.present && !this.myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present) {
      this.myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present = false;
    }
    if (this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present && this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs.length === 0) {
      this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present = false;
    }
    if (this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present && this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs.length === 0) {
      this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present = false;
    }
    if (this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present && this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs.length === 0) {
      this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present = false;
    }

    if (this.myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present && !this.myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.date && !this.myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.type) {
      this.myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present = false;
    }
    if (this.myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present && !this.myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.date && !this.myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.type) {
      this.myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present = false;
    }
    if (this.myExamen.interrogation.antecedentsGeneraux.traitementencours.present && !this.myExamen.interrogation.antecedentsGeneraux.traitementencours.dose && !this.myExamen.interrogation.antecedentsGeneraux.traitementencours.type) {
      this.myExamen.interrogation.antecedentsGeneraux.traitementencours.present = false;
    }

    if (this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.present && this.areAllAttributesPresentFalse(this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant)) {
      this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.present = false;
    }
    this.myExamen.motif = this.selectedMotifs
    this.examenService.updateExamen(this.myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });

    });
    return true;
  }
  autocompleListFormatterPrenom = (data: any): SafeHtml => {
    if (data.nom != undefined && data.prenom != undefined) {
      var html = `<span>${data.nom} ${data.prenom} ${data.specialite}</span>`;
    }
    if (data.prenom == undefined) {
      var html = `<span>${data.nom} ${data.specialite}</span>`;
    }
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  myCallback(event) {
    this.selectedMedecinn = undefined
    if (event._id) {
      this.selectedMedecinn = event
      if (!event.prenom) {
        this.nom = event.nom + ' ' + event.specialite
      }
      if (event.prenom) {
        this.nom = event.nom + ' ' + event.prenom + ' ' + event.specialite
      }
    }
  }
  onKey($event) {
    this.selectedMedecinn = undefined
  }

  gotoOldConsultation(historique) {
    window.open(`medecin/ajout/interrogatoire/${historique.patient}/${historique.id}`, '_blank')
  }


  ngOnDestroy() {
    this.isCameraAccessGranted = false;
    this.isMicrophoneAccessGranted = false;
  }
  fermeConsultation() {
    this.myExamen.duration = this.timer
    const startTime = new Date(this.myExamen.start);
    const timerParts = this.myExamen.duration.split(':');
    const hours = parseInt(timerParts[0]);
    const minutes = parseInt(timerParts[1]);
    const seconds = parseInt(timerParts[2]);
    const endTime = new Date(startTime.getTime() + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000));
    this.myExamen.end = moment(endTime)
    this.myExamen.lastPauseDate = moment().tz('Africa/Tunis').format()
    this.updateExamen(this.myExamen)
    this.router.navigate(['/medecin/consultation-liste', this.patient._id])
  }

  areAllAttributesPresentFalse(obj, isRoot = true) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'present' && isRoot) {
          continue; // Skip the root 'present' attribute
        }

        if (typeof obj[key] === 'object') {
          // Recursively check sub-objects
          if (!this.areAllAttributesPresentFalse(obj[key], false)) {
            return false;
          }
        } else if (key === 'present' && obj[key] !== false) {
          return false;
        }
      }
    }
    return true;
  }
  scrollLeft() {
    document.getElementById('btn-siderbar-left').click();
    return true;
  }
  scrollRight() {
    document.getElementById('btn-siderbar').click();
    return true;
  }

  initializeAntecedentsGeneraux(): AntecedentsGeneraux {
    const defaultDate = new Date();
    return this.myExamen.interrogation.antecedentsGeneraux = {
      grossesseenCours: {
        present: false,
        date: defaultDate,
      },
      terrainatopique: {
        present: false,
        valeurs: [],
      },
      terrainvasculaireparticulier: {
        present: false,
        diabete: {
          present: false,
          type: '',
          traitement: '',
          anciennete: '',
          derniereHBAIC: '',
          derniereFO: '',
          resultatHBAIC: '',
          resultatderniereFO: '',
        },
        hta: {
          present: false,
          type: '',
          traitement: '',
          anciennete: '',
          derniereFO: '',
          resultatderniereFO: '',
        },
        terrainvasculaireparticulierautre: {
          present: false,
          valeurs: '',
        },
      },
      terrainimmunodepression: {
        present: false,
        valeurs: [],
      },
      maladiedysimunitaire: {
        present: false,
        valeurs: [],
      },
      maladieneurologique: {
        present: false,
        valeurs: [],
      },
      notionvaccinationrecente: {
        present: false,
        date: '',
        type: '',
      },
      notionanesthesierecente: {
        present: false,
        date: '',
        type: '',
      },
      traitementencours: {
        present: false,
        dose: '',
        type: '',
      },
      casparticuliernourrissonenfant: {
        present: false,
        consanguinite: {
          present: false,
          date: defaultDate,
        },
        seroconversionpendantlagrossesse: {
          present: false,
          valeur: '',
          type: '',
          date: defaultDate,
        },
        prematurite: {
          present: false,
          valeur: '',
          cesarienne: false,
          voiebasse: false,
          date: defaultDate,
        },
        accouchementvoiebasse: {
          present: false,
          date: defaultDate,
        },
        accouchementcesarienne: {
          present: false,
          date: defaultDate,
        },
        faiblepoidsdelanaissance: {
          present: false,
          valeur: 0,
          date: defaultDate,
        },
        souffrancefoetaleaigue: {
          present: false,
          valeur: 0,
          date: defaultDate,
        },
        casderetinoblastornedanslafamille: {
          present: false,
          date: defaultDate,
        },
        retardscolaire: {
          present: false,
          date: defaultDate,
        },
        comportementdemalvoyance: {
          present: false,
          date: defaultDate,
        },
      },
    };
  }



  onantecedentsGeneraux() {
    switch (this.selectedantecedentsGeneraux) {
      case 'None':
        this.initializeAntecedentsGeneraux()
        break
      case 'Grossesse en cours':
        this.myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present = true
        break
      case 'Terrain atopique':
        this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.present = true
        break
      case 'Terrain vasculaire particulier':
        this.myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present = true
        break
      case "Terrain d'immunodepression":
        this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present = true
        break
      case 'Maladie dysimmunitaire':
        this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present = true
        break
      case 'Maladie neurologique':
        this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present = true
        break
      case 'Notion de vaccination récente':
        this.myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present = true
        break
      case "Notion d'anesthésie récente":
        this.myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present = true
        break
      case 'Traitement général en cours':
        this.myExamen.interrogation.antecedentsGeneraux.traitementencours.present = true
        break
      case 'Cas particulier nourrisson enfant':
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.present = true
        break

    }
  }

  removeonantecedentsGeneraux(value) {
    switch (value) {
      case 'Grossesse en cours':
        this.myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present = false
        break
      case 'Terrain atopique':
        this.myExamen.interrogation.antecedentsGeneraux.terrainatopique.present = false
        break
      case 'Terrain vasculaire particulier':
        this.myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present = false
        break
      case "Terrain dimmunodepression":
        this.myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present = false
        break
      case 'Maladie dysimmunitaire':
        this.myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present = false
        break
      case 'Maladie neurologique':
        this.myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present = false
        break
      case 'Notion de vaccination récente':
        this.myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present = false
        break
      case "Notion danesthésie récente":
        this.myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present = false
        break
      case 'Traitement général en cours':
        this.myExamen.interrogation.antecedentsGeneraux.traitementencours.present = false
        break
      case 'Cas particulier nourrisson enfant':
        this.myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.present = false
        break
    }
    const antecedents = this.myExamen.interrogation.antecedentsGeneraux;
    if ([
      antecedents.grossesseenCours.present,
      antecedents.terrainatopique.present,
      antecedents.terrainvasculaireparticulier.present,
      antecedents.terrainimmunodepression.present,
      antecedents.maladiedysimunitaire.present,
      antecedents.maladieneurologique.present,
      antecedents.notionvaccinationrecente.present,
      antecedents.notionanesthesierecente.present,
      antecedents.traitementencours.present,
      antecedents.casparticuliernourrissonenfant.present
    ].every(prop => !prop)) {
      this.selectedantecedentsGeneraux = "None";
    } else {
      return false;
    }
  }

  removeMotif(mot) {
    this.selectedMotifs.splice(this.selectedMotifs.indexOf(mot), 1);
    this.updateExamen(this.myExamen)
    return true;
  }

  //------------ Sphere 
  changeSphDroit(i, value) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique;
    variable[i].sphere = parseFloat(value);

    if (isNaN(variable[i].sphere) || variable[i].sphere === undefined) {
      variable[i].sphere = 0;
    }

    if (variable[i].sphere === 0) {
      variable[i].sphere = "Plan";
    } else if (variable[i].sphere > 0) {
      variable[i].sphere = "+" + variable[i].sphere;
    } else {
      variable[i].sphere = variable[i].sphere.toString();
    }
  }
  incrementSphDroit(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique;

    // Check if the current value is "Plan" and treat it as 0
    if (variable[i].sphere === "Plan") {
      variable[i].sphere = 0;
    } else {
      variable[i].sphere = parseFloat(variable[i].sphere);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].sphere) || variable[i].sphere === undefined) {
      variable[i].sphere = 0;
    }

    // Increment the sphere value
    variable[i].sphere += 0.25;

    // Handle the special case for 0 and format positive values
    if (variable[i].sphere === 0) {
      variable[i].sphere = "Plan";
    } else if (variable[i].sphere > 0) {
      variable[i].sphere = "+" + variable[i].sphere;
    } else {
      variable[i].sphere = variable[i].sphere.toString();
    }
  }
  decrementSphDroit(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique;

    // Check if the current value is "Plan" and treat it as 0
    if (variable[i].sphere === "Plan") {
      variable[i].sphere = 0;
    } else {
      variable[i].sphere = parseFloat(variable[i].sphere);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].sphere) || variable[i].sphere === undefined) {
      variable[i].sphere = 0;
    } else {
      // Decrement the sphere value
      variable[i].sphere -= 0.25;
    }

    // Handle the special case for 0 and format positive values
    if (variable[i].sphere === 0) {
      variable[i].sphere = "Plan";
    } else if (variable[i].sphere > 0) {
      variable[i].sphere = "+" + variable[i].sphere;
    } else {
      variable[i].sphere = variable[i].sphere.toString();
    }
  }


  changeSphGauche(i, value) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique;
    variable[i].sphere = parseFloat(value);

    if (isNaN(variable[i].sphere) || variable[i].sphere === undefined) {
      variable[i].sphere = 0;
    }

    if (variable[i].sphere === 0) {
      variable[i].sphere = "Plan";
    } else if (variable[i].sphere > 0) {
      variable[i].sphere = "+" + variable[i].sphere;
    } else {
      variable[i].sphere = variable[i].sphere.toString();
    }
  }
  incrementSphGauche(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique;

    // Check if the current value is "Plan" and treat it as 0
    if (variable[i].sphere === "Plan") {
      variable[i].sphere = 0;
    } else {
      variable[i].sphere = parseFloat(variable[i].sphere);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].sphere) || variable[i].sphere === undefined) {
      variable[i].sphere = 0;
    }

    // Increment the sphere value
    variable[i].sphere += 0.25;

    // Handle the special case for 0 and format positive values
    if (variable[i].sphere === 0) {
      variable[i].sphere = "Plan";
    } else if (variable[i].sphere > 0) {
      variable[i].sphere = "+" + variable[i].sphere;
    } else {
      variable[i].sphere = variable[i].sphere.toString();
    }
  }
  decrementSphGauche(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique;

    // Check if the current value is "Plan" and treat it as 0
    if (variable[i].sphere === "Plan") {
      variable[i].sphere = 0;
    } else {
      variable[i].sphere = parseFloat(variable[i].sphere);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].sphere) || variable[i].sphere === undefined) {
      variable[i].sphere = 0;
    } else {
      // Decrement the sphere value
      variable[i].sphere -= 0.25;
    }

    // Handle the special case for 0 and format positive values
    if (variable[i].sphere === 0) {
      variable[i].sphere = "Plan";
    } else if (variable[i].sphere > 0) {
      variable[i].sphere = "+" + variable[i].sphere;
    } else {
      variable[i].sphere = variable[i].sphere.toString();
    }
  }
  //------------ Sphere 
  //------------ cylindre 
  changeCylDroit(i, value) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique;
    variable[i].cylindre = parseFloat(value);

    if (isNaN(variable[i].cylindre) || variable[i].cylindre === undefined) {
      variable[i].cylindre = 0;
    }

    if (variable[i].cylindre === 0) {
      variable[i].cylindre = "Sph";
    } else if (variable[i].cylindre > 0) {
      variable[i].cylindre = "+" + variable[i].cylindre;
    } else {
      variable[i].cylindre = variable[i].cylindre.toString();
    }
  }
  incrementCylDroit(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique;

    // Check if the current value is "Sph" and treat it as 0
    if (variable[i].cylindre === "Sph") {
      variable[i].cylindre = 0;
    } else {
      variable[i].cylindre = parseFloat(variable[i].cylindre);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].cylindre) || variable[i].cylindre === undefined) {
      variable[i].cylindre = 0;
    }

    // Increment the cylindre value
    variable[i].cylindre += 0.25;

    // Handle the special case for 0 and format positive values
    if (variable[i].cylindre === 0) {
      variable[i].cylindre = "Sph";
    } else if (variable[i].cylindre > 0) {
      variable[i].cylindre = "+" + variable[i].cylindre;
    } else {
      variable[i].cylindre = variable[i].cylindre.toString();
    }
  }
  decrementCylDroit(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique;

    // Check if the current value is "Sph" and treat it as 0
    if (variable[i].cylindre === "Sph") {
      variable[i].cylindre = 0;
    } else {
      variable[i].cylindre = parseFloat(variable[i].cylindre);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].cylindre) || variable[i].cylindre === undefined) {
      variable[i].cylindre = 0;
    } else {
      // Decrement the cylindre value
      variable[i].cylindre -= 0.25;
    }

    // Handle the special case for 0 and format positive values
    if (variable[i].cylindre === 0) {
      variable[i].cylindre = "Sph";
    } else if (variable[i].cylindre > 0) {
      variable[i].cylindre = "+" + variable[i].cylindre;
    } else {
      variable[i].cylindre = variable[i].cylindre.toString();
    }
  }
  changeCylGauche(i, value) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique;
    variable[i].cylindre = parseFloat(value);

    if (isNaN(variable[i].cylindre) || variable[i].cylindre === undefined) {
      variable[i].cylindre = 0;
    }

    if (variable[i].cylindre === 0) {
      variable[i].cylindre = "Sph";
    } else if (variable[i].cylindre > 0) {
      variable[i].cylindre = "+" + variable[i].cylindre;
    } else {
      variable[i].cylindre = variable[i].cylindre.toString();
    }
  }
  incrementCylGauche(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique;

    // Check if the current value is "Sph" and treat it as 0
    if (variable[i].cylindre === "Sph") {
      variable[i].cylindre = 0;
    } else {
      variable[i].cylindre = parseFloat(variable[i].cylindre);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].cylindre) || variable[i].cylindre === undefined) {
      variable[i].cylindre = 0;
    }

    // Increment the cylindre value
    variable[i].cylindre += 0.25;

    // Handle the special case for 0 and format positive values
    if (variable[i].cylindre === 0) {
      variable[i].cylindre = "Sph";
    } else if (variable[i].cylindre > 0) {
      variable[i].cylindre = "+" + variable[i].cylindre;
    } else {
      variable[i].cylindre = variable[i].cylindre.toString();
    }
  }
  decrementCylGauche(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique;

    // Check if the current value is "Sph" and treat it as 0
    if (variable[i].cylindre === "Sph") {
      variable[i].cylindre = 0;
    } else {
      variable[i].cylindre = parseFloat(variable[i].cylindre);
    }

    // Check for NaN or undefined values and set to 0 if necessary
    if (isNaN(variable[i].cylindre) || variable[i].cylindre === undefined) {
      variable[i].cylindre = 0;
    } else {
      // Decrement the cylindre value
      variable[i].cylindre -= 0.25;
    }

    // Handle the special case for 0 and format positive values
    if (variable[i].cylindre === 0) {
      variable[i].cylindre = "Sph";
    } else if (variable[i].cylindre > 0) {
      variable[i].cylindre = "+" + variable[i].cylindre;
    } else {
      variable[i].cylindre = variable[i].cylindre.toString();
    }
  }

  //------------ cylindre 
  //------------ axe 
  changeAxeDroit(i, value) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique
    variable[i].axe = parseFloat(value);
    if (!isNaN(value)) {
      if (variable[i].axe < 0) {
        variable[i].axe = 0;
      } else {
        variable[i].axe
      }
    } else {
      variable[i].axe = value;
    }
  }

  incrementAxeDroit(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique
    variable[i].axe = parseFloat(variable[i].axe);
    if (isNaN(variable[i].axe) || variable[i].axe === undefined) {
      variable[i].axe = 0;
    } else if (variable[i].axe >= 0) {
      variable[i].axe += 5;
    }
  }

  decrementAxeDroit(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique
    variable[i].axe = parseFloat(variable[i].axe);
    if (isNaN(variable[i].axe) || variable[i].axe === undefined) {
      variable[i].axe = 0;
    }
    else if (variable[i].axe <= 1) {
      variable[i].axe = 0;
    }
    else {
      variable[i].axe -= 5;
      if (variable[i].axe < 0) {
        variable[i].axe = 0;
      }
    }
  }


  changeAxeGauche(i, value) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique
    variable[i].axe = parseFloat(value);
    if (!isNaN(value)) {
      if (variable[i].axe < 0) {
        variable[i].axe = 0;
      } else {
        variable[i].axe
      }
    } else {
      variable[i].axe = value;
    }
  }

  incrementAxeGauche(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique
    variable[i].axe = parseFloat(variable[i].axe);
    if (isNaN(variable[i].axe) || variable[i].axe === undefined) {
      variable[i].axe = 0;
    } else if (variable[i].axe >= 0) {
      variable[i].axe += 5;
    }
  }

  decrementAxeGauche(i) {
    let variable = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique
    variable[i].axe = parseFloat(variable[i].axe);
    if (isNaN(variable[i].axe) || variable[i].axe === undefined) {
      variable[i].axe = 0;
    }
    else if (variable[i].axe <= 1) {
      variable[i].axe = 0;
    }
    else {
      variable[i].axe -= 5;
      if (variable[i].axe < 0) {
        variable[i].axe = 0;
      }
    }
  }
  //------------ axe 
}
