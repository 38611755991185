import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searchCertificats'
})
export class SearchCertificatsPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.date.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.date.toLowerCase().includes(args));}
     if (value.filter((item) => item.titre.toLowerCase().includes(args)) != '') {
       return value.filter((item) => item.titre.toLowerCase().includes(args));}
       if (value.filter((item) => item.nbreJours.toLowerCase().includes(args)) != '') {
        return value.filter((item) => item.nbreJours.toLowerCase().includes(args));}
        if (value.filter((item) => item.dateConsultation.toLowerCase().includes(args)) != '') {
            return value.filter((item) => item.dateConsultation.toLowerCase().includes(args));}
 }
}

