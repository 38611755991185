<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Rpm" (click)="displayAndHideRpm()">RPM</h4>
            <div class="arrow arrow-up btnRpm" *ngIf="displayRpm" title="Cacher Rpm" (click)="displayAndHideRpm()">▲</div>
            <div class="arrow arrow-up btnRpm" *ngIf="!displayRpm" title="Afficher Rpm" (click)="displayAndHideRpm()">▼</div>
        </div>
        <div *ngIf="displayRpm" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{  selectedLi: examLaf?.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present==true}" (click)="addRemoveFromRpmRight('Présent et symétrique')">
                                    Présent et symétrique
                                </li>
                                <li [ngClass]="{ selectedLi: examLaf?.laf.rpmpresentetsymetrique.oielDroit.marcusgun.present==true}" (click)="addRemoveFromRpmRight('Marcus gun +')">
                                    Marcus gun +
                                </li>
                                <div *ngIf="showMoreRpm">
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.rpmpresentetsymetrique.oielDroit.mydriase.present==true}" (click)="addRemoveFromRpmRight('Mydriase')">
                                        Mydriase
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{  selectedLi: examLaf?.laf.rpmpresentetsymetrique.oielDroit.myosis.present==true}" (click)="addRemoveFromRpmRight('Myosis')">
                                    Myosis
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.rpmpresentetsymetrique.oielDroit.semimydriase.present==true}" (click)="addRemoveFromRpmRight('Semi mydriase')">
                                    Semi mydriase
                                </li>
                                <div *ngIf="showMoreRpm">
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorRpm" [ngClass]="{'selectedLi': checkExistAnomalieRpmRight(anomaly.id)}">
                                        <span (click)="addRemoveFromRpmRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieRpmRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-rpm')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieRpm" (keyup.enter)="addNewAnomalyRpm($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreRpmSection() *ngIf="!showMoreRpm">Voir plus</span>
                            <span (click)=showMoreRpmSection() *ngIf="showMoreRpm">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{  selectedLi:examLaf?.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present==true}" (click)="addRemoveFromRpmLeft('Présent et symétrique')">
                                    Présent et symétrique
                                </li>
                                <li [ngClass]="{ selectedLi:examLaf?.laf.rpmpresentetsymetrique.oielGauche.marcusgun.present==true}" (click)="addRemoveFromRpmLeft('Marcus gun +')">
                                    Marcus gun +
                                </li>
                                <div *ngIf="showMoreRpm">
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.rpmpresentetsymetrique.oielGauche.mydriase.present==true}" (click)="addRemoveFromRpmLeft('Mydriase')">
                                        Mydriase
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{  selectedLi:examLaf?.laf.rpmpresentetsymetrique.oielGauche.myosis.present==true}" (click)="addRemoveFromRpmLeft('Myosis')">
                                    Myosis
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.rpmpresentetsymetrique.oielGauche.semimydriase.present==true}" (click)="addRemoveFromRpmLeft('Semi mydriase')">
                                    Semi mydriase
                                </li>
                                <div *ngIf="showMoreRpm">
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorRpm" [ngClass]="{'selectedLi': checkExistAnomalieRpmLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromRpmLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieRpmLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-rpm')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieRpm" (keyup.enter)="addNewAnomalyRpm($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreRpmSection() *ngIf="!showMoreRpm">Voir plus</span>
                            <span (click)=showMoreRpmSection() *ngIf="showMoreRpm">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Présent et symétrique </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielDroit.marcusgun.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Marcus gun + </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielDroit.marcusgun.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielDroit.mydriase.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mydriase </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielDroit.mydriase.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielDroit.myosis.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Myosis </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielDroit.myosis.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielDroit.semimydriase.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Semi mydriase </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielDroit.semimydriase.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.rpmpresentetsymetrique.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Présent et symétrique </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielGauche.marcusgun.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Marcus gun + </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielGauche.marcusgun.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielGauche.mydriase.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Mydriase </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielGauche.mydriase.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielGauche.myosis.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Myosis </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielGauche.myosis.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.rpmpresentetsymetrique.oielGauche.semimydriase.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Semi mydriase </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.rpmpresentetsymetrique.oielGauche.semimydriase.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.rpmpresentetsymetrique.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>