import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-interrogatoire-patient',
  templateUrl: './interrogatoire-patient.component.html',
  styleUrls: ['./interrogatoire-patient.component.css', '../list-patients.component.css']
})
export class InterrogatoirePatientComponent implements OnInit {
  @Input() myExamen: any;

  constructor() { }
  ngOnInit() { }

}
