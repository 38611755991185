import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'
import { ConsultationService } from '../../../../services/consultation/consultation.service';
import { ExamenService } from '../../../../services/examen/examen.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { AuthService } from '../../../../services/auth/auth.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { PatientService } from '../../../../services/profils/patient/patient.service';
import { MedecinService } from '../../../../services/profils/medecin/medecin.service';
declare var jQuery: any;
import { CertificatService } from '../../../../services/certificat/certificat.service';
import { OrdonnanceService } from '../../../../services/ordonnance/ordonnance.service';
declare let jsPDF;
import * as XLSX from 'xlsx';
import { Ng2IzitoastService } from 'ng2-izitoast';

import { _getURL } from 'config/API_CONFIG';
import { motifMaxLength, motifMinLength, champSpecialCaracters } from 'src/app/services/constants/messages';
import { ValidationService } from 'src/app/services/validate/validateError.service';

@Component({
  selector: 'app-list-patients',
  templateUrl: './list-patients.component.html',
  styleUrls: ['./list-patients.component.css']
})
export class ListPatientsComponent implements OnInit {

  notifications: any[] = [];
  notification: any;
  notificationsPatient: any[] = [];
  patients: any[] = [];
  public search: any = '';
  medecin: any;
  form: any;
  patient: any;
  interval: any;
  messages: any[] = [];
  certificats: any[] = [];
  newCertificats: any[] = [];
  ordonnances: any[] = [];
  newOrdonnances: any[] = [];
  formDossier: any;
  hideModal: boolean = false;
  formNot: any;
  formPDFExcel: any;
  idmedecinTraitant: any;
  notificationsEmail: any[] = [];
  secretaire: any;
  medecinUsedId: any;
  errDate = false
  today = new Date().toISOString().split('T')[0]
  selectedPatient: any;
  erridentifiant = false
  p: any
  selectedMotif: String = "";
  selectedMotifs: string[] = [];
  motifList = ["Amputation du champ visuel progressive",
    "Amputation du champvisuel brutal",
    "BAV loin brutal les deux yeux",
    "BAV loin brutal œil droit",
    "BAV loin brutal œil gauche",
    "BAV loin progressive des deux yeux",
    "BAV loin progressive œil droit",
    "BAV loin progressive œil gauche",
    "BAV près brutal les deux yeux",
    "BAV près brutal œil droit",
    "BAV près brutal œil gauche",
    "BAV près progressive des deux yeux",
    "BAV près progressive œil droit",
    "BAV près progressive œil gauche",
    "Blepharoptosis",
    "Blépharospasme",
    "Brouillard visuel",
    "Chute des cils",
    "Correction optique",
    "Diminution vision contraste",
    "Diplopie brutal",
    "Diplopie progressive",
    "Douleur Oculaire",
    "Douleur oculaire majorée à la mobilisation du globe",
    "Douleur palpébrale bilatérale",
    "Douleur palpébrale œil droit",
    "Douleur palpébrale œil gauche",
    "Flou visuel loin brutal les deux yeux",
    "Flou visuel loin brutal œil droit",
    "Flou visuel loin brutal œil gauche",
    "Flou visuel loin progressive des deux yeux",
    "Flou visuel loin progressive œil droit",
    "Flou visuel loin progressive œil gauche",
    "Flou visuel près brutal les deux yeux",
    "Flou visuel près brutal œil droit",
    "Flou visuel près brutal œil gauche",
    "Flou visuel près progressive des deux yeux",
    "Flou visuel près progressive œil droit",
    "Flou visuel près progressive œil gauche",
    "Halos colorés",
    "Héméralopie",
    "Lagophtalmie",
    "Larmoiement",
    "Myodésopsie",
    "Phosphene",
    "Photophobie",
    "Photopsie",
    "Prurit oculaire",
    "Rougeur oculaire",
    "Rougeur palpébrale",
    "Sécrétion claires",
    "Sécrétion purulentes",
    "Sensation de corp étranger",
    "Sensation de fatigue visuelle",
    "Sensation de sécheresse oculaire",
    "Voile noir devant les yeux",
    "Voile rouge devant les yeux"]
  formMotif: any
  listpreviousconsultations: any[] = []
  allExamnes: any[] = []
  pathImage = _getURL("") + "data/files/imagePatient/"
  myExamen: any;
  isActiveAnomalies = true;
  isActiveRefraction = false;
  isActiveToCat = false;
  isActiveRdv = false;
  echelleMonoyerList = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "1/10", "2/10", "3/10", "4/10", "5/10", "6/10", "7/10", "8/10", "9/10", "10/10"]
  echelleSnellenList = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "20/400", "20/200", "20/80", "20/40", "20/25", "20/20", "20/15", "20/10"]
  echelleParinaudList = ["P1", "P2", "P3", "P4", "P5"]
  page: number = 1;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  count: number = 0;
  copyListPatients: any[];

  prenomToFiltred: any;
  nomToFiltred: any;
  professionToFiltred: any;
  numFicheToFiltred: any;
  ageToFiltred: any;
  motifMinLength = motifMinLength
  motifMaxLength = motifMaxLength
  champSpecialCaracters = champSpecialCaracters
  constructor(private certificatService: CertificatService, private ordonnanceService: OrdonnanceService, private medecinService: MedecinService, private formBuilder: FormBuilder, private router: Router, private notificationService: NotificationService, private consultationService: ConsultationService, public http: HttpClient,
    private authService: AuthService,
    private patientService: PatientService,
    public iziToast: Ng2IzitoastService,
    private examenService: ExamenService
  ) {

    this.form = this.formBuilder.group({
      identifiant: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      dateAnniversaire: ['', [Validators.required]]
    })
    this.formDossier = this.formBuilder.group({
      medecinTransfert: ['', Validators.required]
    })
    this.formNot = this.formBuilder.group({
      choixPersonne: ['', Validators.required]
    })
    this.formPDFExcel = this.formBuilder.group({
      choixPDFEXCEL: ['', Validators.required]
    })
    this.formMotif = this.formBuilder.group({
      motif: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), ValidationService.validateMotif]]
    })
  }
  ngOnInit() {
    if (localStorage.getItem('medecin')) {

      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
        var medecinId = this.medecin._id;
        this.medecinUsedId = medecinId
        this.getPatients()
      },
        err => {
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
        var medecinId = this.secretaire.medecinId;
        this.medecinUsedId = medecinId
        this.getPatients()
      },
        err => {
          return false;
        });
    }
    //this.idmedecinTraitant = medecinId;

  }
  getPatients() {
    this.consultationService.getAllPatientsByMedecin(this.medecinUsedId)
      .subscribe((patients: any[]) => {
        this.patients = patients;
        if (this.patients) {
          this.patients = patients.sort(this.triapatients);
          this.copyListPatients = this.patients
        }
      });
  }
  //supprimer un patient
  removePatient(patient) {
    patient = this.selectedPatient
    var medecinId = this.medecinUsedId
    if (patient && patient.listMedecinsId && patient.listMedecinsId.length > 0) {
      patient.listMedecinsId.splice(patient.listMedecinsId.indexOf(medecinId), 1);
    }
    this.patients.splice(this.patients.indexOf(patient), 1);
    this.patientService.editlistMedecinsPatient(patient).subscribe(patient => {
      this.patient = patient
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    })

  }



  // check previes consultations
  getPreviousConsultation(id) {
    this.selectedMotif = ""
    while (this.selectedMotifs.length > 0) {
      this.selectedMotifs.pop();
    }
    localStorage.setItem('element-to-delete', JSON.stringify(id));
    this.selectedPatient = this.patients.find(x => x._id == id);
    this.listpreviousconsultations = []
    this.examenService.getPreviousConsultation(id).subscribe((data: any) => {
      if (data && data.needToUpdate && data.result && data.result.results && data.result.results.length > 0) {
        this.listpreviousconsultations = data.result.results
      }
      if (data && !data.needToUpdate && data.result && data.result.results && data.result.results.length > 0 && data.result.results[0].nextMotif) {
        this.selectedMotifs = data.result.results[0].nextMotif
      }
    },
      err => {
        return false;
      });
  }

  generateEXCELpatient() {
    if (this.medecinUsedId) {
      var currentDate = new Date()
      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1
      var year = currentDate.getFullYear()

      this.medecinService.getAllpatientsPDFExcel(this.medecinUsedId).subscribe((data: any) => {
        var readyToExport = data
        const workBook = XLSX.utils.book_new(); // create a new blank book
        const workSheet = XLSX.utils.json_to_sheet(readyToExport);

        XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
        XLSX.writeFile(workBook, 'Listes_patients_' + year + month + day + '.xlsx'); // initiate a file download in browser
      }, err => {
        return false;
      });
    } else {
      this.iziToast.show({
        message: 'Erreur !.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
  }
  triapatients(a, b) {
    if (a.created_at > b.created_at) return -1;
    else if (a.created_at == b.created_at) return 0;
    else return 1;
  }
  setVariabletoDelet(patient) {
    localStorage.setItem('element-to-delete', JSON.stringify(patient._id));
    this.selectedPatient = this.patients.find(x => x._id == patient._id);
  }
  //  motif 
  addInputMotif($event) {
    if (this.selectedMotifs.indexOf($event) < 0 && $event && $event.length >= 3 && this.selectedMotifs.length < 20 && this.formMotif.valid) {
      this.selectedMotifs.push($event);
      this.selectedMotif = ""
    }

    if (this.motifList.indexOf($event) < 0 && $event && $event.length >= 3 && this.formMotif.valid) {
      this.motifList.push($event)
      return true;
    }
  }
  saveMotif(patient) {
    patient = this.selectedPatient
    if (this.selectedMotifs && this.selectedMotifs.length > 0) {
      localStorage.setItem("motif", JSON.stringify(this.selectedMotifs))
      const consultationBody = {
        createdByDoctor: this.medecinUsedId,
        patientId: patient._id,
        motif: this.selectedMotifs,
      };
      this.examenService
        .ajoutExamen(consultationBody)
        .subscribe((response: any) => {
          window.open(`medecin/ajout/interrogatoire/${patient._id}/${response._id}`, '_blank')
        });
      // close modal after selecting motif
      let motifModal = $('#myModal7') as any
      motifModal.modal('hide')
      // 
      this.selectedMotif = undefined
      while (this.selectedMotifs.length > 0) {
        this.selectedMotifs.pop();
      }
    }
  }
  checkFormAndSave(i) {
    if (this.formMotif.valid || this.selectedMotifs && this.selectedMotifs.length > 0) {
      this.saveMotif(i);
    } else {
      this.formMotif.markAllAsTouched();
    }
  }
  geToUpdateConsultation() {
    let patient = this.selectedPatient
    if (this.listpreviousconsultations && this.listpreviousconsultations.length > 0) {
      window.open(`medecin/ajout/interrogatoire/${patient._id}/${this.listpreviousconsultations[0].id}`, '_blank')

    }
  }
  deleteSelectedMotif(event) {
    if (event && event.pointerType && event.pointerType == "mouse") {
      this.formMotif.reset()
      this.selectedMotif = undefined
      this.selectedMotifs = []
      while (this.selectedMotifs.length > 0) {
        this.selectedMotifs.pop();
      }
      let motifModal = $('#myModal7') as any
      motifModal.modal('hide')
    } else {
      return false
    }
  }
  // dispaly consultations zone
  openZoneConsultion(j, idPatient) {
    this.allExamnes = []
    while (this.allExamnes.length > 0) {
      this.allExamnes.pop()
    }
    for (var i = 0; i < this.patients.length; i++) {
      if (i != j) {
        this['demandeUpdate' + i] = false;
      }
    }
    this['demandeUpdate' + j] = !this['demandeUpdate' + j]
    this.examenService.getAllExamnByPatientLastTen(idPatient).subscribe((data: any) => {
      if (data && data.results && data.results.length > 0) {
        this.allExamnes = data.results
      }
    },
      err => {
        return false;
      });
    return true;
  }

  // hide consultations zone
  closeZoneConsultion(j) {
    for (var i = 0; i < this.patients.length; i++) {
      this['demandeUpdate' + i] = false
    }
    return true;
  }
  selectExamen(examen) {
    this.myExamen = examen
    this.isActiveAnomalies = true;
    this.isActiveRefraction = false;
    this.isActiveToCat = false;
    this.isActiveRdv = false;
  }
  //showAnomalies()
  showAnomalies() {
    this.isActiveAnomalies = true;
    this.isActiveRefraction = false;
    this.isActiveToCat = false;
    this.isActiveRdv = false;
  }
  //showAnomalies()
  showRdv() {
    this.isActiveAnomalies = false;
    this.isActiveRefraction = false;
    this.isActiveToCat = false;
    this.isActiveRdv = true;
  }
  //showAnomalies()
  showRefraction() {
    this.isActiveAnomalies = false;
    this.isActiveRefraction = true;
    this.isActiveToCat = false;
    this.isActiveRdv = false;
  }
  //showAnomalies()
  showToCat() {
    this.isActiveAnomalies = false;
    this.isActiveRefraction = false;
    this.isActiveToCat = true;
    this.isActiveRdv = false;
  }
  // go To Examen Details
  goToExamenDetails(patient, examen) {
    localStorage.setItem("ZoneToGo", "document");
    this.router.navigate(['/medecin/ajout/interrogatoire', patient, examen]);
    return false
  }
  removeMotif(mot) {
    this.selectedMotifs.splice(this.selectedMotifs.indexOf(mot), 1);
    return true;
  }


  onTableDataChange(event: any) {
    this.page = event;

  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  update(id) {
    this.router.navigate(['/update-patient', id]);
  }


  filter() {
    this.patients = this.copyListPatients;
    let filteredPatients = [...this.copyListPatients];

    // Convert filter criteria to lowercase
    const numFicheilterLower = this.numFicheToFiltred ? this.numFicheToFiltred.toLowerCase() : '';
    const prenomFilterLower = this.prenomToFiltred ? this.prenomToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const professionFilterLower = this.professionToFiltred ? this.professionToFiltred.toLowerCase() : '';
    const ageFilterLower = this.ageToFiltred ? this.ageToFiltred.toLowerCase() : '';

    filteredPatients = this.patients.filter(item => {
      const numFicheFilter = numFicheilterLower ? (item.numFiche ? item.numFiche.toLowerCase().includes(numFicheilterLower) : false) : true;
      const nomFilter = nomFilterLower ? (item.nom ? item.nom.toLowerCase().includes(nomFilterLower) : false) : true;
      const prenomFilter = prenomFilterLower ? (item.prenom ? item.prenom.toLowerCase().includes(prenomFilterLower) : false) : true;
      const professionFilter = professionFilterLower ? (item.profession ? item.profession.toLowerCase().includes(professionFilterLower) : false) : true;
      const ageFilter = ageFilterLower ? ((item.age ? item.age.toLowerCase() : '').replace(/\s/g, '')).includes(ageFilterLower) : true;

      return numFicheFilter && nomFilter && prenomFilter && professionFilter && ageFilter
    });

    this.patients = filteredPatients;

    // Reset pagination
    this.page = 1;
  }
  resetFilters() {
    this.nomToFiltred = "";
    this.prenomToFiltred = "";
    this.numFicheToFiltred = "";
    this.professionToFiltred = "";
    this.ageToFiltred = "";

    this.filter();
  }

}

