import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class DocumentService {

  constructor(private http: HttpClient) {
  }
  //get documents
  getDocuments() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document), { headers: headers })
      
  }
  getDocumentsObecjtsPatient(id,idMedecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document)+"/patient/documents/objects/"+id+"/"+idMedecin, { headers: headers })
      
  }
  saveDocumentByPatient(file) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.document) + '/patient', file, { headers: headers })
      
  }
  saveDocumentByPatientDocuement(file) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.document) + '/add/new/patient/document/objects', file, { headers: headers })
      
  }
  getDocumentsPDF(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document) + '/medecin/' + id, { headers: headers })
      
  }
  getDocumentMedPatient(idpatient,idMedecin){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document) + '/medecin/patient/' + idpatient+'/'+idMedecin, { headers: headers })
      
  }
  onUpdateDocuementPatient(document) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.document) + '/' + document._id, document, { headers: headers })
      
  }
  getByPatientDocuement(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document) + '/medecin/patient/details/document/' + id, { headers: headers })
      
  }
}
