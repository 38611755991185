import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-cornee',
  templateUrl: './cornee.component.html',
  styleUrls: ['./cornee.component.css', '../laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class CorneeComponent implements OnInit {



  //  opacite
  corneOpaciteTailleLeft: number
  corneOpaciteTailleRight: number
  corneOpaciteNombreLeft: number
  corneOpaciteNombreRight: number
  corneOpaciteTestFluoLeft = true
  corneOpaciteTestFluoRight = true
  // Ulcération cornéenne
  corneUlcerationTailleLeft: number
  corneUlcerationTailleRight: number
  corneUlcerationNombreLeft: number
  corneUlcerationNombreRight: number
  corneUlcerationTestFluoLeft = true
  corneUlcerationTestFluoRight = true
  // Abcès
  corneAbcesTailleLeft: number
  corneAbcesTailleRight: number
  corneAbcesNombreLeft: number
  corneAbcesNombreRight: number
  corneAbcesTestFluoLeft = true
  corneAbcesTestFluoRight = true
  // Plaie cornéenne
  cornePlaieCorneTailleLeft: number
  cornePlaieCorneTailleRight: number
  // Point cornéen
  cornePointCorneTailleLeft: number
  cornePointCorneTailleRight: number
  cornePointCorneNombreLeft: number
  cornePointCorneNombreRight: number
  cornePointCorneInfiltrationstromaleLeft = true
  cornePointCorneInfiltrationstromaleRight = true
  cornePointCorneSeidelLeft = true
  cornePointCorneSeidelRight = true
  // PRD
  corneRpdTailleLeft: number
  corneRpdTailleRight: number
  corneRpdNombreLeft: number
  corneRpdNombreRight: number
  // Perforation cornéenne
  cornePerforationCorneNombreLeft: number
  cornePerforationCorneNombreRight: number
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;

  //!----- anomalies by a doctor
  // Cornee : Transparente
  anomaliesByDoctorCornee: any[] = []
  oldlistAnomaliesCornee = ["Transparente", "Abcès", "Anneux immunitaire", "Buée épithéliale", "Bulle sous épithéliale", "Buphtalmie", "Cornea guttata", "Endothélite", "Épaississement épithéliale/stromale", "Gérontoxon",
    "Hématocornée",
    "Hydrops",
    "Hypoesthésie",
    "Hypoesthésie cornéenne",
    "Irrégularité/plis de la descemet",
    "KB",
    "Kératopathie en bandelette",
    "KPS",
    "Leucome",
    "Microkyste épithéliaux",
    "Microkystique",
    "Néovascularisation cornéenne",
    "NSE",
    "Oedème cornéen",
    "Opaque",
    "Opacité",
    "Perforation cornéenne",
    "Plaie cornéenne",
    "Plaque vernale",
    "Point cornéen",
    "PRD",
    "Stries de vogt",
    "Ulcération cornéenne",
    "Ulcère vernal",
    "Visibilité anormale des nerfs cornéen",
    "Ulcère vernal",
    "Nodule de trantas"
  ]
  newAnomalieCornee = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreCornee = false
  //end of Show More Variables
  displayCornee = true

  // send object
  objecttoSend = {
    corneeOD: {},
    corneeOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false


  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-cornee').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-corneetransparente", 'value': this.examLaf.laf.corneetransparente });
  }


  showMoreCorneeSection() {
    this.showMoreCornee = !this.showMoreCornee
  }

  configureDataExamen(idPatient) {
    // !Historique LAF
    // this.examenService.getInspectionHistoriques(idPatient,"laf").subscribe((data:any) => {
    //  this.oldListConsultations=data
    //  },
    //    err => {
    //      return false;
    //    });
    //! Cornee : Transparente
    this.anomalieService.getAnomalieByDoctor("laf-cornee").subscribe(data => {
      this.anomaliesByDoctorCornee = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });

  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }

  displayAndHideCornee() {
    this.displayCornee = !this.displayCornee
  }



  //--------------------------------------------------------------------------cornee section

  clickcorneeinTransparentAE() {
    this.examLaf.laf.corneetransparente.present = false
  }
  addRemoveFromCorneeLeft(value) {
    this.examLaf.laf.corneetransparente.oielGauche.transparente.present = false
    switch (value) {
      case 'Transparente': this.setAllValueToFlase(this.examLaf.laf.corneetransparente.oielGauche); this.examLaf.laf.corneetransparente.oielGauche.transparente.present = true
        break
      case 'Opaque': this.examLaf.laf.corneetransparente.oielGauche.opaque.present = !this.examLaf.laf.corneetransparente.oielGauche.opaque.present
        break
      case 'Opacité': this.examLaf.laf.corneetransparente.oielGauche.opacite.present = !this.examLaf.laf.corneetransparente.oielGauche.opacite.present
        break
      case 'Cornea guttata': this.examLaf.laf.corneetransparente.oielGauche.corneaguttata.present = !this.examLaf.laf.corneetransparente.oielGauche.corneaguttata.present
        break
      case 'Ulcération cornéenne': this.examLaf.laf.corneetransparente.oielGauche.ulcerationcorneenne.present = !this.examLaf.laf.corneetransparente.oielGauche.ulcerationcorneenne.present
        break
      case 'Abcès': this.examLaf.laf.corneetransparente.oielGauche.abces.present = !this.examLaf.laf.corneetransparente.oielGauche.abces.present
        break
      case 'Hydrops': this.examLaf.laf.corneetransparente.oielGauche.hydrops.present = !this.examLaf.laf.corneetransparente.oielGauche.hydrops.present
        break
      case 'Hématocornée': this.examLaf.laf.corneetransparente.oielGauche.hematocornee.present = !this.examLaf.laf.corneetransparente.oielGauche.hematocornee.present
        break
      case 'Microkystique': this.examLaf.laf.corneetransparente.oielGauche.microkystique.present = !this.examLaf.laf.corneetransparente.oielGauche.microkystique.present
        break
      case 'Plaie cornéenne': this.examLaf.laf.corneetransparente.oielGauche.plaiescorneenne.present = !this.examLaf.laf.corneetransparente.oielGauche.plaiescorneenne.present
        break
      case 'Point cornéen': this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.present = !this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.present
        break
      case 'NSE': this.examLaf.laf.corneetransparente.oielGauche.NSE.present = !this.examLaf.laf.corneetransparente.oielGauche.NSE.present
        break
      case 'PRD': this.examLaf.laf.corneetransparente.oielGauche.PRD.present = !this.examLaf.laf.corneetransparente.oielGauche.PRD.present
        break
      case 'Oedème cornéen': this.examLaf.laf.corneetransparente.oielGauche.oedemecorneen.present = !this.examLaf.laf.corneetransparente.oielGauche.oedemecorneen.present
        break
      case 'KPS': this.examLaf.laf.corneetransparente.oielGauche.KPS.present = !this.examLaf.laf.corneetransparente.oielGauche.KPS.present
        break
      case 'Perforation cornéenne': this.examLaf.laf.corneetransparente.oielGauche.perforationcorneenne.present = !this.examLaf.laf.corneetransparente.oielGauche.perforationcorneenne.present
        break
      case 'Endothélite': this.examLaf.laf.corneetransparente.oielGauche.endothelite.present = !this.examLaf.laf.corneetransparente.oielGauche.endothelite.present
        break
      case 'Buée épithéliale': this.examLaf.laf.corneetransparente.oielGauche.bueeEpitheliale.present = !this.examLaf.laf.corneetransparente.oielGauche.bueeEpitheliale.present
        break
      case 'Bulle sous épithéliale': this.examLaf.laf.corneetransparente.oielGauche.bullesousepitheliale.present = !this.examLaf.laf.corneetransparente.oielGauche.bullesousepitheliale.present
        break
      case 'Microkyste épithéliaux': this.examLaf.laf.corneetransparente.oielGauche.microkysteEpitheliaux.present = !this.examLaf.laf.corneetransparente.oielGauche.microkysteEpitheliaux.present
        break
      case 'Kératopathie en bandelette': this.examLaf.laf.corneetransparente.oielGauche.Keratopathieenbandelette.present = !this.examLaf.laf.corneetransparente.oielGauche.Keratopathieenbandelette.present
        break
      case 'KB': this.examLaf.laf.corneetransparente.oielGauche.KB.present = !this.examLaf.laf.corneetransparente.oielGauche.KB.present
        break
      case 'Stries de vogt': this.examLaf.laf.corneetransparente.oielGauche.striesdevogt.present = !this.examLaf.laf.corneetransparente.oielGauche.striesdevogt.present
        break
      case 'Visibilité anormale des nerfs cornéen': this.examLaf.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.present = !this.examLaf.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.present
        break
      case 'Irrégularité/plis de la descemet': this.examLaf.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.present = !this.examLaf.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.present
        break
      case 'Hypoesthésie cornéenne': this.examLaf.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.present = !this.examLaf.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.present
        break
      case 'Épaississement épithéliale/stromale': this.examLaf.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.present = !this.examLaf.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.present
        break
      case 'Hypoesthésie': this.examLaf.laf.corneetransparente.oielGauche.hypoesthesie.present = !this.examLaf.laf.corneetransparente.oielGauche.hypoesthesie.present
        break
      case 'Ulcère vernal': this.examLaf.laf.corneetransparente.oielGauche.ulcerevernal.present = !this.examLaf.laf.corneetransparente.oielGauche.ulcerevernal.present
        break
      case 'Plaque vernale': this.examLaf.laf.corneetransparente.oielGauche.plaquevernale.present = !this.examLaf.laf.corneetransparente.oielGauche.plaquevernale.present
        break
      case 'Nodule de trantas': this.examLaf.laf.corneetransparente.oielGauche.noduledetrantas.present = !this.examLaf.laf.corneetransparente.oielGauche.noduledetrantas.present
        break
      case 'Néovascularisation cornéenne': this.examLaf.laf.corneetransparente.oielGauche.neovascularisationcorneenne.present = !this.examLaf.laf.corneetransparente.oielGauche.neovascularisationcorneenne.present
        break
      case 'Leucome': this.examLaf.laf.corneetransparente.oielGauche.leucome.present = !this.examLaf.laf.corneetransparente.oielGauche.leucome.present
        break
      case 'Gérontoxon': this.examLaf.laf.corneetransparente.oielGauche.gerontoxon.present = !this.examLaf.laf.corneetransparente.oielGauche.gerontoxon.present
        break
      case 'Buphtalmie': this.examLaf.laf.corneetransparente.oielGauche.buphtalmie.present = !this.examLaf.laf.corneetransparente.oielGauche.buphtalmie.present
        break
      case 'Anneux immunitaire': this.examLaf.laf.corneetransparente.oielGauche.anneuximmunitaire.present = !this.examLaf.laf.corneetransparente.oielGauche.anneuximmunitaire.present
        break
    }
    this.setCorneeToTrue(this.examLaf.laf.corneetransparente.oielGauche)
    this.objecttoSend.corneeOG = this.examLaf.laf.corneetransparente.oielGauche
    this.objecttoSend.source = "corneeOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  addRemoveFromCorneeRight(value) {
    this.examLaf.laf.corneetransparente.oielDroit.transparente.present = false
    switch (value) {
      case 'Transparente': this.setAllValueToFlase(this.examLaf.laf.corneetransparente.oielDroit); this.examLaf.laf.corneetransparente.oielDroit.transparente.present = true
        break
      case 'Opaque': this.examLaf.laf.corneetransparente.oielDroit.opaque.present = !this.examLaf.laf.corneetransparente.oielDroit.opaque.present
        break
      case 'Opacité': this.examLaf.laf.corneetransparente.oielDroit.opacite.present = !this.examLaf.laf.corneetransparente.oielDroit.opacite.present
        break
      case 'Cornea guttata': this.examLaf.laf.corneetransparente.oielDroit.corneaguttata.present = !this.examLaf.laf.corneetransparente.oielDroit.corneaguttata.present
        break
      case 'Ulcération cornéenne': this.examLaf.laf.corneetransparente.oielDroit.ulcerationcorneenne.present = !this.examLaf.laf.corneetransparente.oielDroit.ulcerationcorneenne.present
        break
      case 'Abcès': this.examLaf.laf.corneetransparente.oielDroit.abces.present = !this.examLaf.laf.corneetransparente.oielDroit.abces.present
        break
      case 'Hydrops': this.examLaf.laf.corneetransparente.oielDroit.hydrops.present = !this.examLaf.laf.corneetransparente.oielDroit.hydrops.present
        break
      case 'Hématocornée': this.examLaf.laf.corneetransparente.oielDroit.hematocornee.present = !this.examLaf.laf.corneetransparente.oielDroit.hematocornee.present
        break
      case 'Microkystique': this.examLaf.laf.corneetransparente.oielDroit.microkystique.present = !this.examLaf.laf.corneetransparente.oielDroit.microkystique.present
        break
      case 'Plaie cornéenne': this.examLaf.laf.corneetransparente.oielDroit.plaiescorneenne.present = !this.examLaf.laf.corneetransparente.oielDroit.plaiescorneenne.present
        break
      case 'Point cornéen': this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.present = !this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.present
        break
      case 'NSE': this.examLaf.laf.corneetransparente.oielDroit.NSE.present = !this.examLaf.laf.corneetransparente.oielDroit.NSE.present
        break
      case 'PRD': this.examLaf.laf.corneetransparente.oielDroit.PRD.present = !this.examLaf.laf.corneetransparente.oielDroit.PRD.present
        break
      case 'Oedème cornéen': this.examLaf.laf.corneetransparente.oielDroit.oedemecorneen.present = !this.examLaf.laf.corneetransparente.oielDroit.oedemecorneen.present
        break
      case 'KPS': this.examLaf.laf.corneetransparente.oielDroit.KPS.present = !this.examLaf.laf.corneetransparente.oielDroit.KPS.present
        break
      case 'Perforation cornéenne': this.examLaf.laf.corneetransparente.oielDroit.perforationcorneenne.present = !this.examLaf.laf.corneetransparente.oielDroit.perforationcorneenne.present
        break
      case 'Endothélite': this.examLaf.laf.corneetransparente.oielDroit.endothelite.present = !this.examLaf.laf.corneetransparente.oielDroit.endothelite.present
        break
      case 'Buée épithéliale': this.examLaf.laf.corneetransparente.oielDroit.bueeEpitheliale.present = !this.examLaf.laf.corneetransparente.oielDroit.bueeEpitheliale.present
        break
      case 'Bulle sous épithéliale': this.examLaf.laf.corneetransparente.oielDroit.bullesousepitheliale.present = !this.examLaf.laf.corneetransparente.oielDroit.bullesousepitheliale.present
        break
      case 'Microkyste épithéliaux': this.examLaf.laf.corneetransparente.oielDroit.microkysteEpitheliaux.present = !this.examLaf.laf.corneetransparente.oielDroit.microkysteEpitheliaux.present
        break
      case 'Kératopathie en bandelette': this.examLaf.laf.corneetransparente.oielDroit.Keratopathieenbandelette.present = !this.examLaf.laf.corneetransparente.oielDroit.Keratopathieenbandelette.present
        break
      case 'KB': this.examLaf.laf.corneetransparente.oielDroit.KB.present = !this.examLaf.laf.corneetransparente.oielDroit.KB.present
        break
      case 'Stries de vogt': this.examLaf.laf.corneetransparente.oielDroit.striesdevogt.present = !this.examLaf.laf.corneetransparente.oielDroit.striesdevogt.present
        break
      case 'Visibilité anormale des nerfs cornéen': this.examLaf.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.present = !this.examLaf.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.present
        break
      case 'Irrégularité/plis de la descemet': this.examLaf.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.present = !this.examLaf.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.present
        break
      case 'Hypoesthésie cornéenne': this.examLaf.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.present = !this.examLaf.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.present
        break
      case 'Épaississement épithéliale/stromale': this.examLaf.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.present = !this.examLaf.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.present
        break
      case 'Hypoesthésie': this.examLaf.laf.corneetransparente.oielDroit.hypoesthesie.present = !this.examLaf.laf.corneetransparente.oielDroit.hypoesthesie.present
        break
      case 'Ulcère vernal': this.examLaf.laf.corneetransparente.oielDroit.ulcerevernal.present = !this.examLaf.laf.corneetransparente.oielDroit.ulcerevernal.present
        break
      case 'Plaque vernale': this.examLaf.laf.corneetransparente.oielDroit.plaquevernale.present = !this.examLaf.laf.corneetransparente.oielDroit.plaquevernale.present
        break
      case 'Nodule de trantas': this.examLaf.laf.corneetransparente.oielDroit.noduledetrantas.present = !this.examLaf.laf.corneetransparente.oielDroit.noduledetrantas.present
        break
      case 'Néovascularisation cornéenne': this.examLaf.laf.corneetransparente.oielDroit.neovascularisationcorneenne.present = !this.examLaf.laf.corneetransparente.oielDroit.neovascularisationcorneenne.present
        break
      case 'Leucome': this.examLaf.laf.corneetransparente.oielDroit.leucome.present = !this.examLaf.laf.corneetransparente.oielDroit.leucome.present
        break
      case 'Gérontoxon': this.examLaf.laf.corneetransparente.oielDroit.gerontoxon.present = !this.examLaf.laf.corneetransparente.oielDroit.gerontoxon.present
        break
      case 'Buphtalmie': this.examLaf.laf.corneetransparente.oielDroit.buphtalmie.present = !this.examLaf.laf.corneetransparente.oielDroit.buphtalmie.present
        break
      case 'Anneux immunitaire': this.examLaf.laf.corneetransparente.oielDroit.anneuximmunitaire.present = !this.examLaf.laf.corneetransparente.oielDroit.anneuximmunitaire.present
        break
    }
    this.setCorneeToTrue(this.examLaf.laf.corneetransparente.oielDroit)
    this.objecttoSend.corneeOD = this.examLaf.laf.corneetransparente.oielDroit
    this.objecttoSend.source = "corneeOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }


  //  opacite
  onChangecorneOpaciteTailleLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.opacite.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.opacite.taille = 0
    }
  }
  onChangecorneOpaciteTailleRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.opacite.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.opacite.taille = 0
    }
  }
  onChangecorneOpaciteNombreLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.opacite.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.opacite.nombre = 0
    }
  }
  onChangecorneOpaciteNombreRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.opacite.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.opacite.nombre = 0
    }
  }
  onChangecorneOpaciteTestFluoLeft(value) {
    this.examLaf.laf.corneetransparente.oielGauche.opacite.testfluo = value
  }
  onChangecorneOpaciteTestFluoRight(value) {
    this.examLaf.laf.corneetransparente.oielDroit.opacite.testfluo = value
  }
  // Ulcération cornéenne
  onChangecorneUlcerationTailleLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.ulcerationcorneenne.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.ulcerationcorneenne.taille = 0
    }
  }
  onChangecorneUlcerationTailleRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.ulcerationcorneenne.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.ulcerationcorneenne.taille = 0
    }
  }
  onChangecorneUlcerationNombreLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.ulcerationcorneenne.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.ulcerationcorneenne.nombre = 0
    }
  }
  onChangecorneUlcerationNombreRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.ulcerationcorneenne.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.ulcerationcorneenne.nombre = 0
    }
  }
  onChangecorneUlcerationTestFluoLeft(value) {
    this.examLaf.laf.corneetransparente.oielGauche.ulcerationcorneenne.testfluo = value
  }
  onChangecorneUlcerationTestFluoRight(value) {
    this.examLaf.laf.corneetransparente.oielDroit.ulcerationcorneenne.testfluo = value
  }

  // Abcès
  onChangecorneAbcesTailleLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.abces.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.abces.taille = 0
    }
  }
  onChangecorneAbcesTailleRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.abces.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.abces.taille = 0
    }
  }
  onChangecorneAbcesNombreLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.abces.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.abces.nombre = 0
    }
  }
  onChangecorneAbcesNombreRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.abces.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.abces.nombre = 0
    }
  }
  onChangecorneAbcesTestFluoLeft(value) {
    this.examLaf.laf.corneetransparente.oielGauche.abces.testfluo = value
  }
  onChangecorneAbcesTestFluoRight(value) {
    this.examLaf.laf.corneetransparente.oielDroit.abces.testfluo = value
  }
  // Plaie cornéenne
  onChangecornePlaieCorneTailleLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.plaiescorneenne.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.plaiescorneenne.taille = 0
    }
  }
  onChangecornePlaieCorneTailleRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.plaiescorneenne.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.plaiescorneenne.taille = 0
    }
  }
  // Point cornéen

  onChangecornePointCorneTailleLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.taille = 0
    }
  }
  onChangecornePointCorneTailleRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.taille = 0
    }
  }
  onChangecornePointCorneNombreLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.nombre = 0
    }
  }
  onChangecornePointCorneNombreRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.nombre = 0
    }
  }
  onChangecornePointCorneInfiltrationstromaleLeft() {
    this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.infiltrationstromale = !this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.infiltrationstromale
  }
  onChangecornePointCorneInfiltrationstromaleRight() {
    this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.infiltrationstromale = !this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.infiltrationstromale
  }
  onChangecornePointCorneSeidelLeft() {
    this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.seidel = !this.examLaf.laf.corneetransparente.oielGauche.pointcorneen.seidel
  }
  onChangecornePointCorneSeidelRight() {
    this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.seidel = !this.examLaf.laf.corneetransparente.oielDroit.pointcorneen.seidel
  }
  // PRD
  onChangecorneRpdTailleLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.PRD.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.PRD.taille = 0
    }
  }
  onChangecorneRpdTailleRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.PRD.taille <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.PRD.taille = 0
    }
  }
  onChangecorneRpdNombreLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.PRD.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.PRD.nombre = 0
    }
  }
  onChangecorneRpdNombreRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.PRD.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.PRD.nombre = 0
    }
  }
  selectPRDpigmenteRight(value) {
    this.examLaf.laf.corneetransparente.oielDroit.PRD.pigmente = value
  }
  selectPRDpigmenteLeft(value) {
    this.examLaf.laf.corneetransparente.oielGauche.PRD.pigmente = value
  }

  // Oedème cornéen
  chooseOedemecorneenDroit(value) {
    this.examLaf.laf.corneetransparente.oielDroit.oedemecorneen.grade = value
  }
  chooseOedemecorneenGauche(value) {
    this.examLaf.laf.corneetransparente.oielGauche.oedemecorneen.grade = value
  }
  // Perforation cornéenne
  onChangecornePerforationCorneNombreLeft() {
    if (this.examLaf.laf.corneetransparente.oielGauche.perforationcorneenne.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielGauche.perforationcorneenne.nombre = 0
    }
  }
  onChangecornePerforationCorneNombreRight() {
    if (this.examLaf.laf.corneetransparente.oielDroit.perforationcorneenne.nombre <= 0) {
      this.examLaf.laf.corneetransparente.oielDroit.perforationcorneenne.nombre = 0
    }
  }

  //!--------------------Cornee : Transparente
  // Conj dynamic anomaly
  addNewAnomalyCornee(event) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorCornee?.length > 4) {
      showToast("Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieCornee = "";
      return false;
    }

    const value = event?.target?.value;
    if (value && value.length > 1 && value.length < 100) {
      const anomalyExists = this.anomaliesByDoctorCornee.some(x => x.titre?.toLowerCase() === value.toLowerCase());
      const oldAnomalyExists = this.oldlistAnomaliesCornee.some(x => x.toLowerCase() === value.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          "titre": value[0].toUpperCase() + value.slice(1),
          "description": "",
          "zone": "laf-cornee"
        }).subscribe((data) => {
          this.newAnomalieCornee = "";
          this.anomaliesByDoctorCornee.push(data);
          showToast('Bien enregistré', '#386641', '#386641', '#6a994e', '#dde5b6');
        });
        return true;
      } else {
        showToast("L'anomalie existe dans la liste", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
        this.newAnomalieCornee = "";
        return false;
      }
    } else {
      showToast('Merci de saisir au moins 2 caractères', '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieCornee = "";
      return false;
    }
  }


  // Right Cornee
  addRemoveFromCorneeRightNew(value, id) {
    this.examLaf.laf.corneetransparente.oielDroit.transparente.present = false
    let truefalseexist = this.examLaf.laf.corneetransparente.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.corneetransparente.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.corneeOD = this.examLaf.laf.corneetransparente.oielDroit
      this.objecttoSend.source = "corneeOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.corneetransparente.oielDroit.allAnomalies = this.examLaf.laf.corneetransparente.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.corneeOD = this.examLaf.laf.corneetransparente.oielDroit
      this.objecttoSend.source = "corneeOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setCorneeToTrue(this.examLaf.laf.corneetransparente.oielDroit)
      return true;
    }

  }
  checkExistAnomalieCorneeRight(id) {
    return this.examLaf.laf.corneetransparente.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Cornee dyna
  addRemoveFromCorneeLeftNew(value, id) {
    this.examLaf.laf.corneetransparente.oielGauche.transparente.present = false
    let truefalseexist = this.examLaf.laf.corneetransparente.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.corneetransparente.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.corneeOG = this.examLaf.laf.corneetransparente.oielGauche
      this.objecttoSend.source = "corneeOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.corneetransparente.oielGauche.allAnomalies = this.examLaf.laf.corneetransparente.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.corneeOG = this.examLaf.laf.corneetransparente.oielGauche
      this.objecttoSend.source = "corneeOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setCorneeToTrue(this.examLaf.laf.corneetransparente.oielGauche)
      return true;
    }

  }
  checkExistAnomalieCorneeLeft(id) {
    return this.examLaf.laf.corneetransparente.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !------------------end of Cornee : Transparente
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-cornee') {
        this.anomaliesByDoctorCornee.splice(this.anomaliesByDoctorCornee.indexOf(anomaly), 1)
      }
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }
  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }


  // Cornee : transparente

  setCorneeToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "transparente") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.transparente.present = true;
    }
    return myObject
  }

}
